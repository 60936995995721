import React, {Component} from 'react';

import "./GroupSetting.scss";
import {
  getGroupList,
  changeGroupUser,
  addGroup,
  deleteGroup,
  changeGroupName,
  addEditAdminLog
} from "../../../interface/api";
import {now, randomStr} from "../../../interface/utils";

class GroupSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupList: [],
      userEdit: {
        groupNum: -1,
        userNum: -1,
        value: "",
      },
      groupEdit: {
        groupNum: -1,
        value: "",
      }
    }

    this.groupList();
  }

  // DB에서 그룹목록 불러오기
  groupList = async () => {
    const groupList = await getGroupList() || [];
    return this.setGroupListToState(groupList.data);
  };

  // 그룹목록 현재 state에 저장
  setGroupListToState(groupList) {
    this.setState({
      ...this.state,
      groupList: groupList,
    })
  }

  // DB변경 후 현재 state 그룹 목록 업데이트
  updateGroupListState(groupData) {
    const {groupList} = this.state;
    const groupIdx = groupList.findIndex(group => group._id === groupData._id);
    groupList[groupIdx] = groupData;
    this.setGroupListToState(groupList);
  }

  // DB에 그룹 추가
  async addGroup() {
    const groupId = randomStr(8);
    const result = await addGroup(groupId);
    const currentTime = now();
    await addEditAdminLog({regDate:`${currentTime}`, userId:``, groupId:`${groupId}`, action: `グループ追加`});
    console.log(result);
    if (result.success && result.permission) {
      this.groupList();
    }
  }

  // DB에 그룹 제거
  async deleteGroup(groupId) {
    const result = await deleteGroup(groupId);
    const currentTime = now();
    await addEditAdminLog({regDate:`${currentTime}`, userId:``, groupId:`${groupId}`, action: `グループ削除`});
    console.log(result);
    if (result.success && result.permission) {
      this.groupList();
    }
  }

  // DB에 그룹 유저 제거
  async deleteGroupUser(groupId, groupUser, userName) {
    const idx = groupUser.findIndex(user => user === userName);
    groupUser.splice(idx, 1);
    const result = await changeGroupUser(groupId, groupUser);
    const currentTime = now();
    await addEditAdminLog({regDate:`${currentTime}`, userId:``, groupId:`${groupId}`, action: `ユーザー削除`});
    console.log(result);
    if (result.success && result.permission) {
      this.updateGroupListState(result.group);
    }
  }

  // DB에 그룹 유저 추가
  async addGroupUser(groupId, groupUser, idx) {
    const users = groupUser.concat(["USER_" + idx]);
    const result = await changeGroupUser(groupId, users);
    const currentTime = now();
    await addEditAdminLog({regDate:`${currentTime}`, userId:``, groupId:`${groupId}`, action: `ユーザー追加`});
    console.log(result);
    if (result.success && result.permission) {
      this.updateGroupListState(result.group);
    }
  }

  // 그룹 유저명 편집모드 on/off
  editStateUserEdit(groupNum, userNum, value) {
    this.setState({
      userEdit: {groupNum, userNum, value}
    });
  }

  // 그룹명 편집모드 on/off
  editStateGroupEdit(groupNum, value) {
    this.setState({
      groupEdit: {groupNum, value}
    });
  }

  // DB에 그룹명 변경
  async changeGroupName(groupId) {
    this.editStateGroupEdit(-1, "");
    const result = await changeGroupName(groupId, this.state.groupEdit.value);
    console.log(result);
    if (result.success && result.permission) {
      this.updateGroupListState(result.group);
    }
  }

  // DB에 그룹 유저명 변경
  async changeUserName(groupId, groupUser, userIdx) {
    this.editStateUserEdit(-1, -1, "");
    groupUser.splice(userIdx, 1, this.state.userEdit.value);
    const result = await changeGroupUser(groupId, groupUser);
    console.log(result);
    if (result.success && result.permission) {
      this.updateGroupListState(result.group);
    }
  }

  // 그룹 목록 영역
  getGroupElem(group, groupIdx) {
    const {groupNum, userNum, value} = this.state.userEdit;
    const userLength = group.users.length;
    if (userLength > 0) {
      return group.users.map((user, userIdx) => {
        return (
          <tr key={groupIdx + "-" + userIdx}>
            <td>{userIdx === 0 ? groupIdx : ""}</td>
            <td
              onClick={userIdx === 0 ? () => this.editStateGroupEdit(groupIdx, group.group_name) : null}
              className={userIdx === 0 ? "td-click-area" : ""}
            >
              {userIdx === 0 ? this.getGroupNameElem(group, groupIdx) : ""}
            </td>
            <td className={"td-click-area"} onClick={() => this.editStateUserEdit(groupIdx, userIdx, user)}>
              {groupNum === groupIdx && userNum === userIdx ?
                <input
                  type={"text"}
                  className={"td-in-input-text"}
                  value={value}
                  onChange={(e) => this.editStateUserEdit(groupIdx, userIdx, e.target.value)}
                  onBlur={() => this.changeUserName(group._id, group.users, userIdx)}
                /> : user
              }
            </td>
            <td>
              <input
                type={"button"}
                className={"btn_gray "}
                name={"delete"}
                onClick={() => this.deleteGroupUser(group._id, group.users, user)}
                value={"削除"}
              />
            </td>
            <td>
              {userIdx === 0 ? this.getGroupBtnElem(group, userLength) : ""}
            </td>
          </tr>
        )
      })
    } else {
      return (
        <tr key={groupIdx + "-0"}>
          <td>{groupIdx}</td>
          <td
            onClick={() => this.editStateGroupEdit(groupIdx, group.group_name)}
            className={"td-click-area"}
          >
            {this.getGroupNameElem(group, groupIdx)}
          </td>
          <td></td>
          <td></td>
          <td>{this.getGroupBtnElem(group, userLength)}</td>
        </tr>
      )
    }
  }

  // 그룹명 영역
  getGroupNameElem(group, groupIdx) {
    const {groupNum, value} = this.state.groupEdit;
    if (groupNum === groupIdx) {
      return (
        <>
          <input
            type={"text"}
            className={"td-in-input-text"}
            value={value}
            onChange={(e) => this.editStateGroupEdit(groupIdx, e.target.value)}
            onBlur={() => this.changeGroupName(group._id)}
          />
        </>
      )
    } else {
      return (<>{group.group_name}</>)
    }
  }

  // 추가, 삭제버튼 영역
  getGroupBtnElem(group, userLength) {
    return (
      <>
        <input
          type={"button"}
          className={"btn_gray "}
          name={"add"}
          onClick={() => this.addGroupUser(group._id, group.users, userLength)}
          value={"ユーザー追加"}
        />
        <input
          type={"button"}
          className={"btn_gray "}
          name={"add"}
          onClick={() => this.deleteGroup(group._id)}
          value={"グループ削除"}
        />
      </>
    )
  }

  render() {
    const {groupList} = this.state;
    return (
      <table className={"group-list-table"}>
        <tbody>
        <tr>
          <td colSpan={5} className={"td-background"}>
            <input
              type={"button"}
              value={"グループ追加"}
              className={"btn_gray add-group-btn"}
              onClick={this.addGroup.bind(this)}
            />
          </td>
        </tr>
        <tr>
          <th className={"idx"}></th>
          <th className={"groupName"}>グループ名</th>
          <th className={"user"}>ユーザー</th>
          <th className={"controller"}>操作</th>
          <th className={"addGroup"}>追加/削除</th>
        </tr>
        {groupList.map((group, groupIdx) => {
          return this.getGroupElem(group, groupIdx);
        })}
        </tbody>
      </table>
    )
  }
}

export default GroupSetting;