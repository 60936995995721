import React, { Component } from 'react';
import PropTypes from "prop-types";
import DropZone from "../DropZone";
import GroupItem from "./GroupItem";
import {dragType} from "../../../../../interface/constant";

export default class GroupEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hide: false,
		};
	}

	getObjectElem() {
		const groupItem = this.props.item;
		const groupPage = this.props.page;
		return (
			<DropZone
				accept={dragType.GROUP}
				className={this.props.hide ? "" : "hide"}
				onDrop={this.onDrop.bind(this)}
			>   {groupItem.length <= 0 ? (<span>ドラッグしてください。</span>) : ""}
				{groupItem.map((item, idx) => {
					let deleteBtn="disabled";
					if(groupItem.length === item.idx){
						if(item.page === groupPage.left || item.page === groupPage.right) deleteBtn="";
					}
					let groupDrag = "";
					let groupCSS = "group-item";
					if(item.page !== groupPage.left && item.page !== groupPage.right){
						groupDrag = false;
						groupCSS = "group-item-false";
					}

					return (
						<GroupItem
							key={item.id}
							index={idx}
							groupIndex={this.props.index}
							originId={item.originId}
							id={item.id}
							moveItem={this.moveItem.bind(this)}
							deleteItem={this.deleteItem.bind(this)}
							deleteBtn={deleteBtn}
							groupDrag={groupDrag}
							groupCSS={groupCSS}
						>
							{item.id}
						</GroupItem>
					)
				})}
			</DropZone>
		)
	}

	moveItem(dragIndex, hoverIndex) {
		this.props.swapGroup(this.props.id, dragIndex, hoverIndex);
	}


	deleteItem(originId) {
		this.props.deleteItem(this.props.id, originId);
	}

	onDrop(item) {
		let groupItem = {
			originId: item.originId,
			id: item.id,
			page: item.page,
		};
		this.props.addGroupOnList(this.props.id, groupItem)
	}

	groupToggle() {
		this.setState({hide: !this.state.hide})
	}

	deleteGroup() {
		this.props.deleteGroup(this.props.id);
	}

	changingGroupName(e) {
		const groupItem = this.props.item;
		if(groupItem.length <= 0) {
			this.props.changingGroupName(this.props.id, e.target.value);
		}
	}

	changedGroupName(e) {
		this.props.changedGroupName(e.target.value);
	}

	render() {
		const groupItem = this.props.item;
		let deleteBtn="disabled";
		if(groupItem.length <= 0){
			deleteBtn="";
		}
		return (
			<div className="group-edit-container">
				<div className={"group-header"}>
					<div className={"group-toggle"} onClick={this.groupToggle.bind(this)}>
						{this.state.hide ? "▼" : "▶"}
					</div>
					<div className={"group-name"}>
						<input
							type={"text"}
							name={"groupName"}
							value={this.props.id}
							onChange={this.changingGroupName.bind(this)}
							onBlur={this.changedGroupName.bind(this)}
						/>
					</div>
					<div className={"group-delete-x " + deleteBtn } onClick={this.deleteGroup.bind(this)}>
						X
					</div>
				</div>
				<div className={"group-contents" + (this.state.hide ? " hide" : "")}>
					{this.getObjectElem()}
				</div>
			</div>
		)
	}
}


GroupEdit.defaultProps = {
	id: "",
	item: [],
};
GroupEdit.propTypes = {
	id: PropTypes.string.isRequired,
	item: PropTypes.array.isRequired,
	addGroupOnList: PropTypes.func.isRequired,
	swapGroup: PropTypes.func.isRequired,
	swapEnd: PropTypes.func.isRequired,
	deleteGroup: PropTypes.func.isRequired,
	deleteItem: PropTypes.func.isRequired,
	changingGroupName: PropTypes.func.isRequired,
	changedGroupName: PropTypes.func.isRequired,
};