import {deepClone, makeErrorMsg, stringParser} from "../../../interface/utils";
import {
	csvColumnsName,
	csvConst,
	csvModalMsg,
	csvTypeComment, displayMode, iconForm,
	subViewComment,
	svColumn,
	vColumn
} from "../../../interface/csvConstant";
import {grade, semester, subject} from "../../../interface/constant";


/**
 * objArr 의 속성명에 해당하는 obj 의 속성의 값이 중복되는 경우 에러를 던지는 함수
 * @param {object} obj - 검사 대상 객체
 * @param {number} idx - obj 가 csv 에서 위치한 라인넘버
 * @param {object} objArr - 속성명은 문자열 속성값은 배열로 이루어진 객체   ex) { id: [], originId: [] }
 */
export const inputDuplicateCheck = (obj, idx, objArr) => {
	const objectKey =  Object.keys(objArr);
	for (const key of objectKey) {
		if (obj[key] !== "" && objArr[key].includes(obj[key])) {
			throw new Error(makeErrorMsg('inputUnique', idx, csvColumnsName[key]));
		}
		objArr[key].push(obj[key]);
	}
};

/**
 * obj 의 prop 속성에 접근해서 보호문자열을 substring 으로 제거하는 함수
 * @param obj - subView 객체
 * @param prop - 변환할 객체의 속성
 * @returns {*} - excel 에서 id 자동변환을 방지하는 문자열을 제거한 id 를 가진 객체
 */
export const idSubString = (obj, prop) => {
	let csvId = obj[prop];
	if (csvId.substring(0, 4) === `"=""`) {
		csvId = csvId.substring(4, csvId.length - 3);
		obj[prop] = csvId;
	}
	return obj;
};

export const propValueFix = (target, obj, prop) => {
	if (obj.hasOwnProperty(target)) {
		let csvId = obj[prop];
		if (csvId.substring(0, 4) === `"=""`) {
			csvId = csvId.substring(4, csvId.length - 3);
			obj[prop] = csvId;
		}
	}
};

/**
 * flat 한 속성명을 가진 객체의 속성명 from 을 to 로 바꾸는 객체 ex) obj.a ===> obj.c
 * @param {object} obj - flat 한 속성을 갖는 audio 객체 또는 audioText 객체
 * @param {string} from - obj 의 변경할 기존 속성
 * @param {string} to - obj 의 변경할 속성
 * @returns {object} - obj 객체의 from 속성명을 to 속성명으로 바꾼 obj 객체
 */
export const propNameChange = (obj, from, to, type) => {
	if (obj.hasOwnProperty(from) && obj.type === type) {
		obj[to] = obj[from];
		delete obj[from];
	}
	return obj;
};

/**
 * 編集 추가,삭제 시에는 page 가 필수 입력으로 csv 에 입력한 값이 숫자인지 아닌지, 음수인지 최대페이지를 넘는지 검사한다.
 * @param {object} obj - type 이 audio 인 검사 대상 객체
 * @param {number} idx - csv 상에서 obj 가 위치한 라인 넘버
 * @param {number} totalPages - this.props.metaData.totalPages 책의 최대 페이지 수
 * @param {object} constObject - 상수 객체
 */
export const pageInvalidCheck = (obj, idx, totalPages, constObject = csvColumnsName) => {
	const page = stringParser(obj.page); // ページ
	const flag = stringParser(obj.flag); // 編集
	if (flag !== 3) { // 삭제는 page 정보가 필요 없다. 삭제를 제외한 추가/수정 시
		if (typeof page !== 'number') { // 숫자 외의 값을 입력시 에러를 던진다.
			throw new Error(makeErrorMsg('onlyNumber', idx, constObject.page));
		}
		if (page < 1 || page > totalPages) { // page 가 음수이거나 totalPages 을 초과한경우
			throw new Error(makeErrorMsg('overRange', idx, constObject.page));
		}
	}
};


/**
 * 입력값이 Y 또는 N 이 기대되는 경우이면서, 사용자가 값을 입력을 했는데 Y 나 N 이 아닌 경우에 에러를 던지는 함수
 * @param {object} obj - 검사 대상 객체
 * @param {number} idx - csv 상에서 obj 의 위치 라인넘버
 * @param {string} prop - 검사하려는 속성 이름
 */
export const yesOrNoCheck = (obj, idx, prop) => {
	if (obj[prop] !== "") { // 사용자가 굳이 입력했다면
		const inputValue = obj[prop].toUpperCase(); // y ===> Y , n ===> N
		const range = ['Y', "N"];
		const match = range.some(v => v === inputValue); // 입력값이 Y 또는 N 이 아니면 false
		if (!match) {
			throw new Error(makeErrorMsg('chooseOne', idx, csvColumnsName[prop], range));
		}
	}
};


/**
 * props 배열안의 prop 속성들로 obj 에 접근해서 해당 속성의 값이 숫자가 아니면 에러를 던지는 함수
 * @param {object} obj - 검사 대상 객체
 * @param {number} idx - csv 상에서 obj 의 위치 라인넘버
 * @param {array} checkProps - 객체에서 검사할 대상 속성들의 이름 문자열을 담은 배열
 */
export const onlyNumberCheck = (obj, idx, checkProps, constObject) => {
	for (const prop of checkProps) {
		if (obj[prop] !== "") {
			const inputValue = stringParser(obj[prop]);
			if (typeof inputValue !== 'number') {
				throw new Error(makeErrorMsg('onlyNumber', idx, constObject[prop]));
			}
		}
	}
};

/**
 * obj 객체에 속성은 존재하나 값 입력이 불필요한 경우 , 입력했을 때 에러를 던지는 함수
 * @param {object} obj - 검사 대상 객체
 * @param {number} idx - csv 상에서 obj 의 위치 라인넘버
 * @param {string} props - 인자로 받은 값들을 전개해서 배열로 받음
 */
export const unnecessaryInputCheck = (obj, idx, ...props) => {
	for (const prop of props) {
		if (obj.hasOwnProperty(prop) && obj[prop] !== "") {
			throw new Error(makeErrorMsg('noInput', idx, csvColumnsName[prop]));
		}
	}
};

/**
 * csv 에 입력한 key 가 유효한 key 인가 검사하는 함수, 존재하지 않는 키를 입력하면 에러를 던진다.
 * @param {object} csvObj - 검사할 객체
 * @param {string} prop - 객체에서 검사할 속성 이름
 * @param {number} idx - csv 상에서 객체가 위치한 라인넘버
 * @param {object} repo - 레포지토리 키들이 보관된 repo 객체
 * @param {string} target - repo 객체에 접근할 속성 이름
 */
export const checkPropsKeyExist = (csvObj, prop, idx, repo, target) => {
	const repoKey = csvObj[prop];
	if (csvObj.hasOwnProperty(prop) && repoKey !== "") { // 객체에 prop 속성이 존재하고 입력된 값이 존재할 경우
		const targetRepo = repo[target]; // target 에 해당하는 key 정보들이 담긴 객체에 접근
		const isNotExistKey = !(targetRepo.hasOwnProperty(repoKey)); // 해당 키의 존재 여부
		if (isNotExistKey) { // repo 에 해당 key 가 존재하지 않을 때
			console.log(`prop `, prop);
			console.log(`csvColumnsName[prop] `, csvColumnsName[prop]);
			console.log(`idx: `, idx);
			console.log(`makeErrorMsg('isNotExists', idx, csvColumnsName[prop]) `, makeErrorMsg('isNotExists', idx, csvColumnsName[prop]));
			throw new Error(makeErrorMsg('isNotExists', idx, csvColumnsName[prop]));
		}
	}
};

/**
 * obj 객체에 prop 속성이 있고 속성값이 "" 빈값이 아니면 setValue 값을 해당 속성의 값으로 넣어준다.
 * @param {object} obj - 접근할 객체
 * @param {string} prop - 객체에 접근할 속성 이름
 * @param {string|number|false} setValue - 객체의 속성에 접근해서 넣어줄 값
 */
export const setDefaultValue = (obj, prop, setValue) => {
	if (obj.hasOwnProperty(prop) && obj[prop] === "") {
		obj[prop] = setValue;
	}
};

/**
 * import 를 위해서 redux 내의 객체 구조를 갖고있는 객체모델에
 * 속성 값을 가진 object 의 속성 값을 넣어서 반환하는 함수
 * @param {object} model - import 를 하기 위한 객체 모델
 * @param {object} object - model 에 넣어줄 속성값을 가진 객체
 * @returns {object} - model 객체에 object 의 속성값을 넣은 객체
 */
export const getImportModel = (model, object) => {
	for (const [prop, value] of Object.entries(object)) {
		const p = prop.split('.');
		if (p.length === 1) {
			model[p[0]] = value;
		} else if (p.length === 2) {
			model[p[0]][p[1]] = value;
		}
	}
	return model;
};

/**
 * obj 의 prop 로 접근해서 대상 속성의 속성값이 존재하지 않는 "" 일 경우 에러를 던지는 함수
 * @param {object} obj - 검사 대상 객체
 * @param {string} prop - 객체로 접근할 속성 이름
 * @param {number} idx - CSV 내에서 해당 객체가 위치한 라인넘버
 * @param {string} csv - 어떤 csv 를 검사하는지에 대한 문자열 값
 */
export const requirePropCheck = (obj, prop, idx, csv) => {
	const csvColNames = getColumnNameByCsvType(csv);
	if(obj.hasOwnProperty(prop)) {
		if (obj[prop] === "") {
			throw new Error(makeErrorMsg('required', idx, csvColNames[prop]));
		}
	}
};

/**
 * type 에 해당하는 csv 의 컬럼 상수 객체를 반환하는 함수
 * @param {string} type - 가져올 csv 컬럼 상수 종류를 구분하는 문자열
 */
const getColumnNameByCsvType = (type) => {
	if (type === "video") {
		return vColumn;
	} else if (type === "subview") {
		return svColumn;
	} else {
		return csvColumnsName;
	}
};

/**
 * redux 에 반영할 clonedData 에서 변경전 객체를 제거하고 변경후 객체를 추가하는 함수
 * @param {object} clonedData - pageData 딥클론한 리덕스에 반영할 객체
 * @param {object} flatReduxObj - flat 한 상태의 import 반영되기 전의 객체
 * @param {object} importObj - import 시켜 바꿀 정보를 가진 객체
 * @param {string} arrType - import 될 객체가 위치하는 모드 배열 이름 ex) videoLink, pageLink, subView
 */
export const modifyClonedData = (clonedData, flatReduxObj, importObj, arrType) => {
	const prevPage = flatReduxObj.page; // 변경 전 객체의 페이지
	const newPage = importObj.page; // 변경 후 객체의 페이지
	const stageArr = clonedData[prevPage][csvConst.objects][arrType]; // 변경전 객체가 위치한 배열
	const removedData = stageArr.filter(e => e.originId !== importObj.originId); // 변경 전 객체 제거한 배열
	clonedData[flatReduxObj.page][csvConst.objects][arrType] = removedData; // 배열 덮어 씌우기

	if (!clonedData[newPage][csvConst.objects][arrType]) { // arrType 이 없으면
		clonedData[newPage][csvConst.objects][arrType] = []; // 생성
	}
	clonedData[newPage][csvConst.objects][arrType].push(importObj); // 수정 완료
};

/**
 * redux pageData 에서 modeTypeArr 에 해당하는 객체들만 모두 담은 배열을 반환하는 함수
 * 객체가 위치한 배열의 이름을 type 으로 지정하고 flag 속성의 기본값으로 2를 준다.
 * @param {object} clonedData - clone 한 pageData
 * @param {string[]} modeTypeArr - pageData 내에 접근할 모드 이름을 담은 배열
 * @returns {array} - clonedData 에서 modeTypeArr 의 배열 속성값 문자열에 해당하는 객체들을 모두 담은 배열
 */
export const getReduxModeObject = (clonedData, modeTypeArr) => {
	const arr = [];
	for (const pageData of Object.values(clonedData)) {
		const objects = pageData.objects;
		for (const mode of modeTypeArr) {
			if (objects.hasOwnProperty(mode)) {
				for (const obj of objects[mode]) {
					obj.type = mode;
					obj.flag = 2;
					arr.push(obj);
				}
			}
		}
	}
	return arr;
};

/**
 * import 할 csv 대상이 무엇인지에 따라 그에 맞는 주석 부분을 반환하는 함수
 * 확대화면 csv 의 경우, 교과서 별로 또 한번 코멘트가 나뉜다.
 * @param {object} meta - 책 사이즈, 교과종류, 학년, 학기 등의 정보가 담긴 객체
 * @param {string} name - csv 종류 이름 : subView, editor, chapter, subView, editor, audio, html, video
 * @returns {string} - csv 상에서 보여지는 주석 문자열
 */
export const getCommentByCsvType = (meta, name) => {
	const eachComment = (name === 'subView') ? subViewComment[meta.subject] : csvTypeComment[name];
	const utilComment = getUtilComment(meta, name); // 모든 csv 에 공통적으로 보여질 주석 가져오기
	const fullComment = [...eachComment, utilComment]; // 개별 주석과 공통 주석을 합친 완전한 주석
	return createComment(fullComment); // 교과에 맞는 주석 배열을 가져다가 주석화 시켜줌
};

/**
 * 모든 종류의 csv 에서 공통 사용 될 csv 주석을 가져오는 함수
 * @param {object} meta - 현재 csv 가 어떤 교과의 몇학년 몇학기의 csv 인지 나타내기 위한 상수 객체
 * @param {string} name - 현재 csv 가 어떤 종류의 csv 인지 나타내기 위한 문자열
 * @returns {string} - csv 종류 구분없이 공통적으로 보여질 주석 부분
 */
const getUtilComment = (meta, name) => {
	return `${subject[meta.subject]} ${grade[meta.grade]} ${semester[meta.semester]} ${csvModalMsg[name]}情報`
};

/**
 * comment 배열을 순회하면서 앞에 주석 표시 <!-- 을 달아주고
 * 뒤에 \r\n 을 달아서 문자열에 추가한뒤 문자열을 리턴하는 함수
 * @param {array} comment - 주석 정보가 적힌 배열 [[], [], [], []] 의 형태를 하고 있음
 * @returns {string} - 주석 형태를 갖춘 문자열
 */
const createComment = (comment) => {
	let commentText = "";
	comment.forEach(e => {
		commentText += `<!-- `.concat(e).concat('\r\n');
	});
	return commentText;
};


/**
 * 해당 객체의 flag 編集 값이 1, 2, 3 셋 중 하나를 입력받는지 검사하는 함수
 * @param obj - 검사 대상 객체
 * @param idx - csv 상에서 검사 대상 객체의 라인넘버
 * @param constObj - 일본어 속성 이름이 다른 경우 속성이름 상수 객체를 다르게 받아서 적용, 기본값은 csvColumnsName 사용
 */
export const flagChecker = (obj, idx, constObj = csvColumnsName) => {
	const flagValue = stringParser(obj.flag); // 문자열로 받은 編集 값을 숫자로 바꿈
	const isFlag = (flagValue === 1 || flagValue === 2 || flagValue === 3); // flag 값은 1 또는 2 또는 3 셋중 하나여야한다.
	if (!isFlag) { // 1,2,3 셋중 하나가 아니면
		throw new Error(makeErrorMsg('incorrect', idx, constObj.flag));
	}
};

/**
 * 현재 교과서가 무엇이냐에 따라서 csv 다루는 컨텐츠가 다르므로 csv 에서 입력받는 값도 그와 똑같은지 검사하는 함수
 * @param obj - 검사 대상 객체
 * @param idx - csv 상에서 검사 대상 객체의 라인넘버
 * @param subject - 교과 종류 ) kokugo, rika, sansu etc...
 * @param constObj - 상수 객체, 기본값으로 csvColumnsName 사용, 입력값 있으면 입력값의 상수 객체 사용
 */
export const subViewTypeChecker = (obj, idx, subject, constObj = csvColumnsName) => {
	const TypeValue = obj.type;    // subView CSV - 表示モード컬럼값
	const typeRangeArr = displayMode[subject]; // 값을 제한하기 위한 배열
	const isTypeRight = typeRangeArr.some(type => type === TypeValue); // 제한된 배열안의 속성과 값이 하나라도 일치하면 true
	if (TypeValue === "") throw new Error(makeErrorMsg('required', idx, constObj.type)); // 값이 없으면 에러
	if (!isTypeRight) throw new Error(makeErrorMsg('incorrect', idx, constObj.type)); // 일치하는게 없으면 에러
};

/**
 * subView csv import 를 받아서 들어온 객체가 pageLink 면 클릭하면 이동하는 페이지의 값이
 * 0보다 작거나 현존 하는 최대 페이지 수를 넘어버리면 에러를 던지는 함수
 * @param {object} obj - subView csv import 시에 들어올 객체 정보
 * @param {number} idx - CSV 파일에서 현재 검사 객체가 위치한 라인 넘버
 * @param {number} totalPages - 현재 책의 최대 페이지 수
 * @param {object} constObj - Error 낼 때 어느 컬럼이 문제인지 나타내는 상수 객체
 */
export const subViewPageLinkChecker = (obj, idx, totalPages, constObj = csvColumnsName) => {
	if (obj.type === 'pageLink') { // pageLink 의 경우
		const jumpPage = obj['data.page']; // リンク先ページ 값이
		if (jumpPage === "") { // 입력값 없으면
			if (obj.flag === 2) {
				throw new Error(makeErrorMsg('requiredUpdate', idx, constObj['data.page'])); // 에러
			}
		} else { // 입력값이 있으면
			if (jumpPage <= 0 || jumpPage > totalPages) { // 0보다 낮거나 최대 페이지를 초과하면
				throw new Error(makeErrorMsg('overRange', idx, constObj['data.page'])); // 에러
			}
		}

	}
};

/**
 * subView csv import 받을 때 객체가 icon 이면 class 로 받은 값이 실존하는 class 인지 검사하는 함수
 * @param {object} obj - subView csv import 시에 들어올 객체 정보
 * @param {number} idx - CSV 파일에서 현재 검사 객체가 위치한 라인 넘버
 * @param {array} iconFormArr - 교과에 따른 아이콘 종류 배열, ex) 국어일 경우 ['action', 'audio', 'document']
 * @param {object} constObj - Error 낼 때 어느 컬럼이 문제인지 나타내는 상수 객체
 */
export const iconClassChecker = (obj, idx, subject, constObj = csvColumnsName) => {
	if (obj.type === 'icon') {
		const iconFormArr = iconForm[subject]; // 교과별 아이콘의 종류를 문자열로 담은 배열을 가져옴
		const iconClass = obj[csvConst.dataClass];
		const iconClassArr = iconClass.split(' ');
		const isIcon = (iconClassArr[0] === 'icon') ? true : false;
		if (isIcon) {
			const iconClass = iconClassArr[1];
			if (!iconClass) { // 값이 존재하지 않으면
				if (obj.flag === 2) { // 수정이면 에러
					throw new Error(makeErrorMsg('requiredUpdate', idx, constObj[csvConst.dataClass]));
				}
			} else { // 값이 존재하면
				const isMatch = iconFormArr.some(form => form === iconClass); // 올바른 입력인가?
				if (!isMatch) throw new Error(makeErrorMsg('incorrect', idx, constObj[csvConst.dataClass]));
			}
		}
	}
};

/**
 * obj.target 속성의 값이 min 보다 낮거나 max 보다 높으면 에러를 던지는 함수
 * @param {object} obj - type 이 audio 인 검사 대상 객체
 * @param {string} target - obj 의 검사 대상 속성
 * @param {number} idx - csv 상에서 obj 가 위치한 라인 넘버
 * @param {number} min - obj.target 의 최소값 범위
 * @param {number} max - obj.target 의 최대값 범위
 * @param {object} constObj - Error 낼 때 어느 컬럼이 문제인지 나타내는 상수 객체
 */
export const overRangeDetailCheck = (obj, target, idx, min, max, constObj = csvColumnsName) => {
	const value = stringParser(obj[target]);
	if (value !== "") { // 값을 입력한 경우
		if (typeof value !== 'number') { // 입력값이 숫자가 아닌 경우
			throw new Error(makeErrorMsg('onlyNumber', idx, constObj[target]));
		}
		if (value < min - 10 || value > max + 10) { // 범위를 벗어나는 경우(조금은 벗어나도 괜찮지 않을까...)
			throw new Error(makeErrorMsg('overRangeDetail', idx, constObj[target], min-10, max+10));
		}
	} else { // 값을 입력하지 않은 경우
		if (obj.flag === 2) { // 수정인 경우
			throw new Error(makeErrorMsg('onlyNumber', idx, constObj[target]));
		}
	}
};

/**
 * direction 에 따른 obj.page 와 obj.width 정보에 따라서
 * obj.left 의 제한된 범위가 있는데 이를 넘어서면 에러를 던지는 함수
 * @param {object} obj - type 이 audio 인 검사 대상 객체
 * @param {number} idx - csv 상에서 obj 가 위치한 라인 넘버
 * @param {number} width - this.props.metaData.view.width  ex) width: 952
 * @param {string} direction - [rtl | ltr] - 책의 읽는 방향
 * @param {object} constObj - Error 낼 때 어느 컬럼이 문제인지 나타내는 상수 객체
 * @param {number} cssWidth - CSV 내에서 오브젝트의 width 를 다루지 않을 경우, 임의로 넣어주는 width 값
 */
export const leftInfoCheck = (obj, idx, width, direction, constObj, cssWidth) => {
	// 함수 재활용을 위해서 CSV 내에서 width 값을 다루지 않는 동영상의 width 값을 임의로 넣어둠
	const objectWidth = (obj.hasOwnProperty('css.width')) ? obj['css.width'] : cssWidth;
	if (direction === 'rtl') { // rtl
		if (obj.page % 2 === 0) { // page 짝수
			const min = 0;   const max = (width - objectWidth);
			overRangeDetailCheck(obj, 'css.left', idx, min, max, constObj);
		} else { // page 홀수
			const min = (width / 2) * -1;  const max = (width / 2) - objectWidth;
			overRangeDetailCheck(obj, 'css.left', idx, min, max, constObj);
		}
	} else { // ltr
		if (obj.page % 2 === 0) { // page 짝수
			const min = (width / 2) * -1;  const max = (width / 2) - objectWidth;
			overRangeDetailCheck(obj, 'css.left', idx, min, max, constObj);
		} else { // page 홀수
			const min = 0;   const max = (width - objectWidth);
			overRangeDetailCheck(obj, 'css.left', idx, min, max, constObj);
		}
	}
};

/**
 * 編集2 인데, 객체의 prop 속성값이 입력되지 않았다면 에러를 던지는 함수
 * @param {object} obj - csv import 시에 들어올 객체 정보
 * @param {number} idx - CSV 파일에서 현재 검사 객체가 위치한 라인 넘버
 * @param {string} prop - obj 에 접근할 속성 이름
 * @param {object} constObj - Error 낼 때 어느 컬럼이 문제인지 나타내는 상수 객체
 */
export const idNullCheck = (obj, idx, prop, constObj = csvColumnsName) => {
	if (obj[prop] === "") { // 값없음
		// 수정이면 에러 던짐
		if (obj.flag === 2) throw new Error(makeErrorMsg('requiredUpdate', idx, constObj[prop]));
	}
};


/**
 * 추가/수정시 입력한 originId가 기존의 것과 중복되는지 확인하기 위한 배열을 가져오는 함수
 * @param {object} clonedData - clone 한 pageData
 * @returns {Array} - redux 내에 존재하는 모든 객체의 originId를 담은 배열
 */
export const getAllOriginId = (clonedData) => {
	const originIdArr = [];
	const allObjectArr = [];
	const typeChainObject = {};
	for (const objects of Object.values(clonedData)) {
		const pageData = objects['objects'];
		for (const [arrType, arr] of Object.entries(pageData)) {
			for (const obj of arr) {
				originIdArr.push(obj.originId);
				typeChainObject[obj.originId] = arrType;
				allObjectArr.push(obj);
			}
		}
	}
	return [originIdArr, allObjectArr, typeChainObject];
};

/**
 * 추가 시, originId 중복 검사,  수정, 삭제 시 originId 존재하는지 검사 하는 함수
 * @param {object} obj - csv import 시에 들어올 객체 정보
 * @param {number} idx - CSV 파일에서 현재 검사 객체가 위치한 라인 넘버
 * @param {array} originIdArr - redux 내의 모든 객체의 originId 가 담긴 배열
 */
export const originIdChecker = (obj, idx, originIdArr) => {
	const originId = stringParser(obj.originId);
	const flag = stringParser(obj.flag);
	const isMatch = originIdArr.some(id => id === originId); // 같은게 있습니까?
	if (flag === 1) {
		if (isMatch) { // flag1 추가는 기존의 ID가 있으면 중복되버린다.
			throw new Error(makeErrorMsg('unique', idx, svColumn.originId));
		}
	} else { // 2, 3
		if (!isMatch) {// flag2 수정/삭제는 기존의 ID가 없으면 누굴 수정/삭제할지 모른다.
			throw new Error(makeErrorMsg('isNotExists', idx, svColumn.originId));
		}
	}
};

/**
 * export, import 시의 리덕스의 원본 객체에 접근/가공 할 때 보호를 위해 딥클론한 객체를 반환해주는 함수
 * @param {object} reduxData - 신성한 리덕스를 보호하기 위해 딥클론 할 리덕스 정보들
 * @returns {Array} - 딥클론 된 리덕스 정보들을 담은 배열
 */
export const getClonedData = (...reduxData) => {
	const cloneDataObject = [];
	for (const data of reduxData) {
		const cloneData = deepClone(data);  // 딥클론
		cloneDataObject.push(cloneData);
	}
	return cloneDataObject;
};

/**
 * 객체의 모든 속성 값이 "" 빈값이면 의미없는 빈 Row 가 CSV 파일에 들어갔다는 것이므로 에러를 던지는 함수
 * @param {object} obj - 검사 대상 객체
 * @param {number} idx - CSV 파일에서 검사 대상 객체가 위치한 라인넘버
 */
export const emptyRowChecker = (obj, idx) => {
	const isEmpty = Object.values(obj).every(propValue => propValue === "");
	if (isEmpty) {
		throw new Error(makeErrorMsg('incorrect', idx, 'ロー'));
	}
};


/**
 * 문자열로된 객체들의 속성값을 받아서 본연의 type 으로 되돌린 속성값들로 바꿔주는 함수
 * @param {object} obj - 모든 속성의 값이 문자열인 subView 객체
 * @returns {object} - 속성 값이 파싱된 객체
 */
export const propValueParsing = (obj) => {
	console.log(`obj: `, obj);
	for (const [prop, value] of Object.entries(obj)) {
		obj[prop] = stringParser(value);
	}
	return obj;
};