import React, {Component} from 'react';
import {connect} from "react-redux";
import {ShakaiRedLineTable} from "../../../components/Contents/Editor/Panels/SubviewPanel/innerSubview";
import {changeRectData, changeRectDataBlock} from "../../../reducers/CombineReducers/pageData";
import {objectModeConst, subViewRect} from "../../../interface/constant";
import {randomStr} from "../../../interface/utils";
import {getObjects} from "../Editor/EditorLayers/ObjectLayer";

const mapStateToProps = state => {
    const {id, page, blockId} = state.current.editor.selected;
    const {editor} = state.current;
    const objMode = objectModeConst[editor.objectMode];
    let props = {};
    if (id && page) {
        let objectList = state.pageData[page].objects[objMode];
        const idx = objectList.findIndex((item) => item.originId === id);
        const subViewSettings = editor.objectMode === "subView" ? objectList[idx].data.subViewSettings : null;

        // 지면 redLine을 양면페이지에서 가져옴
        const page2 = page % 2 === 0 ? page -1 : page + 1;
        let pagesData = [page, page2];
        let redLineObj = [];
        for(let page of pagesData) {
            let pageObj = state.pageData[page].objects["redLine"];
            if (pageObj) {
                redLineObj = redLineObj.concat(getObjects(pageObj));
            }
        }

        props = {
            selectedId: id,
            selectedPage: page,
            selectedBlockId: blockId,
            redLineObj,
            subViewSettings,
        }

    }
    return props;
};

const mapDispatchToProps = {
    changeRectData,
    changeRectDataBlock,
};

const ADD_OFFSET = 10;
let redLineOffsetX = ADD_OFFSET;
let redLineOffsetY = ADD_OFFSET;

class ShakaiRedLine extends Component {
    subViewSettingEvent() {
        return {
            addRedLine: () => {
                const {subViewSettings} = this.props;
                let redLine = subViewSettings.options.redLine || [];
                const defaultRedLine = {
                    id: "redLine-" + randomStr(8),
                    rectId: "",
                    group: 0,
                    width: 80,
                    height: 30,
                    left: redLineOffsetX,
                    top: redLineOffsetY,
                };

                if(redLineOffsetY < 580){
                    redLineOffsetX += ADD_OFFSET;
                    redLineOffsetY += ADD_OFFSET;
                }else{
                    redLineOffsetX = redLineOffsetX-560;
                    redLineOffsetY = redLineOffsetY-580;
                }

                const data = {
                    subViewSettings: {
                        ...subViewSettings,
                        options: {
                            ...subViewSettings.options,
                            redLine: redLine.concat(defaultRedLine),
                        },
                    }
                };

                this.props.changeRectData({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    data: data,
                    objMode: "subView",
                });
            },
            redLineInputChange: (e) => {
                const value = e.target.value.replace(/(^0+)/, ""); // 0으로 시작하는 값 받을시 0 제거(ex: 03 -> 3) (** 그냥 0만있어도 제거됨! **)
                const data = {
                    [e.target.name]: value || 0,
                };

                this.props.changeRectDataBlock({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    blockId: this.props.selectedBlockId,
                    data: data,
                    subViewType: subViewRect.shakaiRedLine,
                });
            },
            changeRedLineCss: (e) => {
                const value = Number(e.target.value);
                const data = {
                    [e.target.name]: value
                };

                this.props.changeRectDataBlock({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    blockId: this.props.selectedBlockId,
                    data: data,
                    subViewType: subViewRect.shakaiRedLine,
                });
            },
        }
    }

    render() {
        const {subViewSettings, redLineObj, selectedBlockId} = this.props;
        const redLine = subViewSettings.options.redLine;
        const redLineIdx = redLine ? redLine.findIndex((item) => item.id === selectedBlockId) : -1;
        const rectRedLines = getObjects(redLineObj, "redLine") || [];
        return (
            <ShakaiRedLineTable
                event={this.subViewSettingEvent()}
                rectRedLines={rectRedLines}
                subViewSettingsRedLine={ redLineIdx < 0 ? null : subViewSettings.options.redLine[redLineIdx]}
                selectedBlockId={selectedBlockId}
                tabMenu={this.props.tabMenu}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShakaiRedLine);