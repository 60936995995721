import React, { Component } from 'react';
import { subject, grade, semester, editingMsg } from "../../../interface/constant";
import noImage from "../../../images/no-image.jpg";

class EditingBook extends Component {
	constructor(props) {
		super(props);
		this.state = {
			EditingBookInfo: {}
		}
	}

	async componentDidMount() {
		const bookData = await this.props.getBookDataAll(this.props.info.bookId);
		this.setState({
			EditingBookInfo: bookData.data
		});
	}

	bookPropObj = {
		subject: subject,
		grade: grade,
		semester: semester,
	};

	getBookTitle = (...props) => {
		let bookTitle = "";
		for (const prop of props) {
			const bookPropValue = this.state.EditingBookInfo.metaData[prop];
			const bookPropObj = this.bookPropObj[prop];
			bookTitle += `${bookPropObj[bookPropValue]} `;
		}
		return bookTitle;
	};

	setEditingMsg() {
		if (!this.props.isMyBook) {
			return editingMsg.NOT_PERMISSION;
		}
		else if (this.props.isMaking) {
			return editingMsg.MAKING_BOOK;
		}
		return "";
	}

	render() {
		const localMeta = this.state.EditingBookInfo.metaData;
		const info = this.props.info;
		const selected = (this.props.selectedBookId === info.bookId);
		const disabled = this.props.isMyBook && !this.props.isMaking ? "" : "disabled";
		const editorMsg = this.setEditingMsg();
		const editorMsgHide = editorMsg ? "" : "hide";
		return (
			<div className={"editing-book"}>
				<div
					data-id={"editingBook"}
					id={info.bookId}
					className={"book" + (selected ? " selected" : "")}
					onClick={this.props.selectBook}
				>
					<div className={"book-image"}
					     style={{backgroundImage: `url(${info.coverPath || noImage})`}}
					>
					</div>
					<div className={"book-info"}>
						<div className={"info-area"}>
							<span className={"book-title"}>
								{localMeta && this.getBookTitle('subject', 'grade', 'semester')}
							</span>
							<br/>
							<span>{info.lastModified}</span>
							<br/>
							<span>{info.author}</span>
						</div>
						<div className={"btn-right"}>
							<input
								data-id={info.bookId}
								className={"btn-upload"}
								type={"button"}
								value={"パブリッシング"}
								onClick={this.props.uploadBook}
								disabled={disabled}
							/>
							<label
								className={"btn-upload btn_gray " + disabled}
								htmlFor={disabled ? "" : "changeCover" + info.bookId}
							>
								表紙変更
							</label>
							<form method={"post"} encType={"multipart/form-data"}>
								<input
									data-id={info.bookId}
									className={"hide"}
									type={"file"}
									id={"changeCover" + info.bookId}
									name={this.props.name}
									accept={".png"}
									onChange={this.props.changeCover}
								/>
								<input type={"hidden"} name={"bookId"} value={"cover"}/>
							</form>
						</div>
						<span className={"editor-msg " + editorMsgHide}>
							{editorMsg}
						</span>
					</div>
				</div>
			</div>
		)
	}
}

export default EditingBook;