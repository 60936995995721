import React, { Component } from 'react';
import Panel from "../Panel";
import './ListPanel.scss';
import ListLabel from "../../../../../containers/Contents/Editor/ListLabel";

const panelState = {
	id:  "ListPanel",
	label: "リスト",
	name: "list",
	style: {
		width: 220,
		height: 570,
		bottom: 10,
		right: 10,
	}
};

class ListPanel extends Component {
	render() {
		return (
			<div className="panel-object-list-inner inner-contents">
				<ListLabel pageType={"left"}/>
				<ListLabel pageType={"right"}/>
			</div>
		)
	}
}

export default Panel(ListPanel, panelState);