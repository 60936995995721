import React, { Component } from 'react';
import Panel from "../Panel";
import PropertyTable from "../../../../../containers/Contents/Editor/PropertyTable/PropertyTable"
import IconTable from "../../../../../containers/Contents/Editor/PropertyTable/IconTable"
import AudioLinkTable from "../../../../../containers/Contents/Editor/PropertyTable/AudioLinkTable"
import './PropertyPanel.scss';
import { objectModeConst, propertyInputName } from "../../../../../interface/constant";
import VideoLinkTable from "../../../../../containers/Contents/Editor/PropertyTable/VideoLinkTable";
import PageLinkTable from "../../../../../containers/Contents/Editor/PropertyTable/PageLinkTable";
import MeaningTable from "../../../../../containers/Contents/Editor/PropertyTable/MeaningTable";
import RedLineTable from "../../../../../containers/Contents/Editor/PropertyTable/RedLineTable";
import BlockTable from "../../../../../containers/Contents/Editor/PropertyTable/BlockTable";
import MaskTable from "../../../../../containers/Contents/Editor/PropertyTable/MaskTable";
import PostItTable from "../../../../../containers/Contents/Editor/PropertyTable/PostItTable";
import MyEditorTable from "../../../../../containers/Contents/Editor/PropertyTable/MyEditorTable";
import FormTable from "../../../../../containers/Contents/Editor/PropertyTable/FormTable";
import CheckTable from "../../../../../containers/Contents/Editor/PropertyTable/CheckTable";
import PopupTable from "../../../../../containers/Contents/Editor/PropertyTable/PopupTable";
import RestudyTable from "../../../../../containers/Contents/Editor/PropertyTable/RestudyTable";

const panelState = {
	id:  "PropertyPanel",
	label: "属性",
	name: "property",
	style: {
		width: 280,
		/*height: 500,*/
		left: 10,
		top: "20%",
	}
};

class PropertyPanel extends Component {
	setOptionsValue(target, propsOptions) {
		let name = target.name;
		let value = target.value;
		let isOptions = propsOptions;
		let options = {
			name: "",
			value: "",
		};
		if (name === propertyInputName.linkLevel) {
			options.name = "link";
			options.value = (isOptions) ? {...propsOptions.link, level: Number(value)} : {level: Number(value)};
		} else if (name === propertyInputName.zIndex) {
			options.name = "link";
			options.value = (isOptions) ? {...propsOptions.link, zIndex: Number(value)} : {zIndex: Number(value)};
		} else if (name === propertyInputName.iFrame || name === propertyInputName.htmlCheck) {
			options.name = "iFrame";
			options.value = target.checked;
		} else if (name === propertyInputName.groupName) {
			options.name = "group";
			options.value = (isOptions) ? {...propsOptions.group, name: value} : {name: value};
		} else if (name === propertyInputName.groupIndex) {
			options.name = "group";
			options.value = (isOptions) ? {...propsOptions.group, index: Number(value)} : {index: Number(value)};
		}
		return options;
	}

	tableElem(objMode) {
		switch (objMode) {
			case objectModeConst.subView: {
				return (<PropertyTable setOptionsValue={this.setOptionsValue}/>);
			}
			case "myEditor": {
				return (<MyEditorTable setOptionsValue={this.setOptionsValue}/>)
			}
			case "icon": {
				return (<IconTable setOptionsValue={this.setOptionsValue}/>);
			}
			case objectModeConst.audioLink: {
				return (<AudioLinkTable setOptionsValue={this.setOptionsValue}/>);
			}
			case objectModeConst.videoLink: {
				return (<VideoLinkTable setOptionsValue={this.setOptionsValue}/>);
			}
			case objectModeConst.pageLink: {
				return (<PageLinkTable setOptionsValue={this.setOptionsValue}/>);
			}
			case objectModeConst.meaning: {
				return (<MeaningTable setOptionsValue={this.setOptionsValue}/>);
			}
			case objectModeConst.form: {
				return (<FormTable setOptionsValue={this.setOptionsValue}/>);
			}
			case objectModeConst.redLine: {
				return (<RedLineTable/>);
			}
			case objectModeConst.mask: {
				return (<MaskTable setOptionsValue={this.setOptionsValue}/>);
			}
			case objectModeConst.block: {
				return (<BlockTable setOptionsValue={this.setOptionsValue}/>);
			}
			case objectModeConst.postIt: {
				return (<PostItTable setOptionsValue={this.setOptionsValue}/>);
			}
			case "check": {
				return (<CheckTable setOptionsValue={this.setOptionsValue}/>);
			}
			case objectModeConst.popupLink: {
				return (<PopupTable setOptionsValue={this.setOptionsValue}/>);
			}
			case objectModeConst.restudy: {
				return (<RestudyTable setOptionsValue={this.setOptionsValue}/>);
			}
			default: {
				return (<PropertyTable setOptionsValue={this.setOptionsValue}/>);
			}
		}
	}

	keyDown(e) {
		// ObjectLayerのkeydownイベントを実行しない
		e.nativeEvent.stopImmediatePropagation();
	}

	render() {
		const {objMode} = this.props;
		return (
			<div className="panel-property-inner inner-contents" onKeyDown={this.keyDown.bind(this)}>
				{this.tableElem(objMode)}
			</div>
		)
	}
}

export default Panel(PropertyPanel, panelState);