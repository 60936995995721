import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";

import {
	TextProperty,
	SelectProperty,
	NumberProperty,
	FileProperty,
} from "../../../../components/Contents/Editor/Panels/PropertyPanel/innerProperty";
import {
	changeBookData,
	changeRectId,
	changeRectPage,
	changeRectData,
	changeRectSVG,
	changeRectOptions,
} from '../../../../reducers/CombineReducers/pageData';
import { modifyRepoPath } from "../../../../reducers/CombineReducers/repo";
import {
	modalMsg, modalTitle,
	objectModeConst,
	propertyInputLabel,
	propertyInputName,
	propertyToolTip,
} from '../../../../interface/constant';
import { setSelectItem, changeEditorMode } from "../../../../reducers/CombineReducers/current";
import PosSizeProperty from "../../../../components/Contents/Editor/Panels/PropertyPanel/posSizeProperty";
import {checkNested} from "../../../../interface/utils";
import {togglePanel} from "../../../../reducers/CombineReducers/panel";
import SvgProperty from "../../../../components/Contents/Editor/Panels/PropertyPanel/SvgProperty";
import Modal from "../../../../components/Common/Modal";
import {fileUpload} from "../../../../components/Common/Upload";

const mapStateToProps = (state) => {
	const objMode = objectModeConst[state.current.editor.objectMode];
	let {id, page} = state.current.editor.selected;
	let prop = {};
	if (id && page) {
		let objectInfo = state.pageData[page].objects[objMode];
		let item = objectInfo.filter((item) => item.originId === id)[0];
		prop = {
			item,
			pages: state.current.editor.pages,
			bookChanged: state.current.book.changed,
			view: state.metaData.view,
			bookId: state.metaData.bookId,
			direction: state.metaData.direction,
			panel: state.panel,
			mode: state.current.editor.mode,
			repo: state.repo,
			id,
			page,
			objMode
		};
	}
	return prop;
};

const mapDispatchToProps = dispatch => {
	return {
		changeBookData: (state) => dispatch(changeBookData(state)),
		changeRectId: (state) => dispatch(changeRectId(state)),
		changeRectPage: (state) => dispatch(changeRectPage(state)),
		changeRectData: (state) => dispatch(changeRectData(state)),
		setSelectItem: (state) => dispatch(setSelectItem(state)),
		modifyRepoPath: (state) => dispatch(modifyRepoPath(state)),
		changeRectSVG: (state) => dispatch(changeRectSVG(state)),
		togglePanel: (state) => dispatch(togglePanel(state)),
		changeEditorMode: (state) => dispatch(changeEditorMode(state)),
		changeRectOptions: (state) => dispatch(changeRectOptions(state)),
	}
};

let property = {
	currentId: "",
	changedId: "",
	page: 0,
	objMode: "",
};

class AudioLinkTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: "",
			propertyCss: {
				width: "",
				height: "",
				top: "",
				left: "",
			},
			showModalError: false,
		}
		this.errorProcess = null;
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps === prevState.prevProps) return null;
		const propertyCss = AudioLinkTable.arrangeCss(prevState.propertyCss, nextProps.item.css);
		const svg = checkNested(nextProps, "item.svg.attr.points") ? nextProps.item.svg.attr.points : "";
		return {propertyCss, svg}
	}

	static arrangeCss(prevCss, nextCss) {
		return {
			...nextCss,
			width: prevCss.width === "" ? prevCss.width : nextCss.width,
			height: prevCss.height === "" ? prevCss.height : nextCss.height,
			top: prevCss.top === "" ? prevCss.top : nextCss.top,
			left: prevCss.left === "" ? prevCss.left : nextCss.left,
		}
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.props.item.id !== prevProps.item.id) {
			const id = this.props.item.id || "";
			const propertyCss = this.props.item.css;
			this.setState({id, propertyCss})
		}
	}

	eventHandler() {
		return {
			onChange: (e) => {
				this.setState({
					...this.state,
					propertyCss: {
						...this.state.propertyCss,
						[e.target.name]: e.target.value,
					}
				});

				const value = e.target.value || 0;

				this.props.changeBookData({
					id: this.props.id,
					page: this.props.page,
					css: {
						[e.target.name]: (e.target.name !== 'opacity' ? parseInt(value, 10) : Number(value))
					},
					objMode: this.props.objMode
				});
			},
			pageChange: (e) => {
				let page = Number(e.target.value);
				this.props.changeRectPage({
					id: this.props.id,
					page: page,
					width: this.props.view.width / 2,
					objMode: this.props.objMode,
					direction: this.props.direction,
				});
				this.props.setSelectItem({
					id: this.props.id,
					page: page
				});
			},
			optionsChange: (e) => {
				let {page, objMode, item} = property;
				const itemOptions = Object.keys(this.props.item).length !== 0 ? this.props.item.options : item.options;
				let options = this.props.setOptionsValue(e.target, itemOptions);
				this.props.changeRectOptions({
					id: this.props.id,
					page: this.props.page || page,
					options: {
						[options.name]: options.value
					},
					objMode: this.props.objMode || objMode,
				});
			},
			fileChange: (e) => {
				this.props.changeRectData({
					id: this.props.id,
					page: this.props.page,
					objMode: this.props.objMode,
					data: {
						fileKey: e.target.value,
					}
				});
			},
			dataChange: (e) => {
				let data = Number(e.target.value);
				this.props.changeRectData({
					id: this.props.id,
					page: this.props.page,
					objMode: this.props.objMode,
					data: {
						[e.target.name]: data,
					},
				});
			},
			changePlayAtMoveAt: (e) => {
				let data = e.target.value === "" ? e.target.value : Number(e.target.value);
				this.props.changeRectData({
					id: this.props.id,
					page: this.props.page,
					objMode: this.props.objMode,
					data: {
						[e.target.name]: data,
					},
				});
			}
		}
	}

	setPropertyId(e) {
		property.currentId = this.props.id;
		property.changedId = e.target.value;
		property.page = this.props.page;
		property.objMode = this.props.objMode;
	}

	changeId(e) {
		this.setState({
			id: e.target.value
		});
		property.changedId = e.target.value;
	}

	changePageDataId() {
		let {currentId, changedId, page, objMode} = property;
		this.props.changeRectId({
			currentId: currentId,
			changedId: changedId,
			page: page,
			objMode: objMode
		});
	}

	async audioUpload(e) {
		const result = await fileUpload(e, this.props.modifyRepoPath, this.props.bookId, "audio", this.props.repo);
		if (!result || !result.fileUpload) {
			this.showErrorModal();
			return;
		}
		await this.updateAfterUpload(result.data.key, result.data.path, result.data.originalname, "fileKey", "audio");
	}

	async updateAfterUpload(key, path, originalname, dataKey, kind) {
		this.props.changeRectData({
			id: this.props.id,
			page: this.props.page,
			objMode: this.props.objMode,
			data: {
				[dataKey]: key
			}
		});
	}

	showErrorModal() {
		this.errorProcess = (e) => {
			e.stopPropagation();
			this.toggleModal("Error", false);
			this.errorProcess = () => {};
		};
		this.toggleModal("Error", true);
	}

	toggleModal(type = "Error", show = true) {
		let modalType = `showModal${type}`;
		this.setState({
			...this.state,
			[modalType]: show,
		})
	}

	render() {
		const {id, page, item, pages} = this.props;
		const pageInfoOptions = this.props.pages ? [
			{val: pages.left, name: pages.left},
			{val: pages.right, name: pages.right}
		] : [];

		return (
			<div className="inner-table">
				<table>
					<tbody>
					<TextProperty
						label={propertyInputLabel.id}
						name={propertyInputName.id} val={this.state.id}
						readOnly={false}
						onFocus={this.setPropertyId.bind(this)}
						onBlur={this.changePageDataId.bind(this)}
						onChange={this.changeId.bind(this)}
						tooltipText={propertyToolTip.id}
					/>
					<SelectProperty
						label={propertyInputLabel.page}
						name={propertyInputName.page} options={pageInfoOptions}
						eventHandler={this.eventHandler().pageChange} val={page}
						tooltipText={propertyToolTip.page}
					/>

					<PosSizeProperty css={this.state.propertyCss} eventHandler={this.eventHandler()}/>

					<NumberProperty
						label={propertyInputLabel.audioIndex}
						name={propertyInputName.audioIndex} val={item.data.index || 0}
						onChange={this.eventHandler().dataChange}
					/>
					<NumberProperty
						label={propertyInputLabel.moveAt}
						name={propertyInputName.moveAt}
						val={item.data.moveAt === 0 || item.data.moveAt ? item.data.moveAt : ""}
						onChange={this.eventHandler().changePlayAtMoveAt}
					/>
					<NumberProperty
						label={propertyInputLabel.playAt}
						name={propertyInputName.playAt}
						val={item.data.playAt === 0 || item.data.playAt ? item.data.playAt : ""}
						onChange={this.eventHandler().changePlayAtMoveAt}
					/>
					<TextProperty
						label={propertyInputLabel.file}
						name={propertyInputName.file} val={item.data.fileKey || ""}
						readOnly={false}
						onChange={this.eventHandler().fileChange}
					/>
					<FileProperty
						label={""}
						htmlFor={"uploadAudioFile"}
						btnName={"アップロード"}
						accept={".mp3"}
						dataId={this.props.id}
						bookId={this.props.bookId}
						onChange={this.audioUpload.bind(this)}
					/>

					<SvgProperty
						changeRectSVG={this.props.changeRectSVG}
						changeEditorMode={this.props.changeEditorMode}
						togglePanel={this.props.togglePanel}
						item={item}
						id={this.props.id}
						page={this.props.page}
						objMode={this.props.objMode}
						panel={this.props.panel}
						mode={this.props.mode}
						svg={this.state.svg}
					/>
					</tbody>
				</table>
				<div className="inner-mask" style={{display: id ? "none" : ""}}/>
				{this.state.showModalError && <Modal
					onClickConfirm={(e) => this.errorProcess(e)}
					msg={modalMsg.IMAGE_EXIST}
					title={modalTitle.ERROR}
				/>}
			</div>
		)
	}
}

AudioLinkTable.defaultProps = {
	item: {
		originId: "",
		id: "",
		css: {},
		options: {},
		page: 0,
		data: {},
	},
	page: 0,
	id: ""
};

AudioLinkTable.propTypes = {
	item: PropTypes.shape({
		originId: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		css: PropTypes.object.isRequired,
		options: PropTypes.object.isRequired,
		data: PropTypes.object.isRequired,
		page: PropTypes.number.isRequired
	}).isRequired,
	page: PropTypes.number.isRequired,
	id: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(AudioLinkTable);