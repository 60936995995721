import React from 'react';
import Select from "react-select";
import AddToolTip from "../../../../Common/AddToolTip";

export function TextProperty(props) {
	const {label, name, val, readOnly, onChange, onBlur, onFocus, tooltipText="", color=""} = props;
	return (
		<tr>
			<td className="table-key">{label}</td>
			<td className="table-question">
				<AddToolTip text={tooltipText} direction={"right"}/>
			</td>
			<td className="table-value">
				<label>
					<input type="text" name={name} value={val} readOnly={readOnly} onChange={onChange}
					       onBlur={onBlur} onFocus={onFocus}/>
				</label>
				<span className={color ? "color-sample" : "hide"} style={{backgroundColor: color}}/>
			</td>
		</tr>
	)
}

export function TextBoxProperty(props) {
	const {label, name, val, readOnly, onChange, onBlur, onFocus, tooltipText="", className=""} = props;
	return (
		<tr>
			<td className="table-key">{label}</td>
			<td className="table-question">
				<AddToolTip text={tooltipText} direction={"right"}/>
			</td>
			<td className="table-value">
				<label>
					<textarea name={name} value={val} readOnly={readOnly} onChange={onChange}
					       onBlur={onBlur} onFocus={onFocus} className={className}/>
				</label>
			</td>
		</tr>
	)
}

export function CheckBoxProperty(props) {
	const {label, name, eventHandler, checked, tooltipText=""} = props;
	return (
		<tr>
			<td className="table-key">{label}</td>
			<td className="table-question">
				<AddToolTip text={tooltipText} direction={"right"}/>
			</td>
			<td className="table-value">
				<div className="switch">
					<label className="switch_label">
						<input type="checkbox" className="switch_input" name={name}
						       onChange={eventHandler}
						       checked={checked}
						/>
						<span className="switch_content"/>
						<span className="switch_circle"/>
					</label>
				</div>
			</td>
		</tr>
	)
}

export function SelectProperty(props) {
	const {label, name, eventHandler, options, tooltipText="", hide="", size=1} = props;
	return (
		<tr className={hide}>
			<td className="table-key">{label}</td>
			<td className="table-question">
				<AddToolTip text={tooltipText} direction={"right"}/>
			</td>
			<td className="table-value">
				<label>
					<select id="linkLevel" name={name} onChange={eventHandler} value={props.val} size={size}>
						{options.map((item) => {
							return (<option value={item.val} key={item.key || item.name}>{item.name}</option>)
						})}
					</select>
				</label>
			</td>
		</tr>
	);
}

export function NumberProperty(props) {
	const {label, eventHandler, max, min, name, step, suffix, val, onBlur, onFocus, onChange, tooltipText=""} = props;
	return (
		<tr>
			<td className="table-key">{label}</td>
			<td className="table-question">
				<AddToolTip text={tooltipText} direction={"right"}/>
			</td>
			<td className="table-value">
				<label>
					<input
						type="number" name={name}
						value={val} min={min}
						step={step} max={max}
						onChange={onChange || eventHandler.onChange}
						onBlur={onBlur}
						onFocus={onFocus}
					/>
				</label>
				<div className="unit">{suffix}</div>
			</td>
		</tr>
	)
}

export function CheckBoxesProperty(props) {
	const {label, checkArrays, onChange, tooltipText=""} = props;
	return (
		<tr>
			<td className="table-key">{label}</td>
			<td className="table-question">
				<AddToolTip text={tooltipText} direction={"right"}/>
			</td>
			<td className="table-value">
				{checkArrays.map((item, i) => {
					return (
						<div key={item.name}>
							<label>
								<input type="checkbox" name={item.name} value={item.val}
								       onChange={onChange} checked={item.checked}
								/>
								{item.name}
							</label>
						</div>
					)
				})}
			</td>
		</tr>
	)
}

export function FileProperty(props) {
	const {label, htmlFor, btnName, tooltipText=""} = props;
	return (
		<tr>
			<td className="table-key">{label}</td>
			<td className="table-question">
				<AddToolTip text={tooltipText} direction={"right"}/>
			</td>
			<td className="table-value">
				<label className={"btn-label"} htmlFor={htmlFor}>
					<div className={"upload-btn"}>{btnName}</div>
				</label>
				<div className={"hide"}>
					<form method={"post"} encType={"multipart/form-data"}>
						<input
							type={"file"}
							id={htmlFor}
							accept={props.accept}
							data-id={props.id}
							onChange={props.onChange}
							onBlur={props.onBlur}
						/>
						<input type={"hidden"} name={"bookId"} defaultValue={props.bookId}/>
					</form>
				</div>
			</td>
		</tr>
	)
}

export function ButtonProperty(props) {
	const {label, btnName, onClick, tooltipText=""} = props;
	return (
		<tr>
			<td className="table-key">{label}</td>
			<td className="table-question">
				<AddToolTip text={tooltipText} direction={"right"}/>
			</td>
			<td className="table-value">
				<label className={"btn-label"}>
					<div className={"upload-btn"} onClick={onClick}>{btnName}</div>
				</label>
			</td>
		</tr>
	)
}

export function SelectImageProperty(props) {
	const {label, eventHandler, options, tooltipText=""} = props;

	const styles = {
		// selectBox
		control: styles => ({
			...styles,
			backgroundColor: "#454545",
			border: "1px solid #676767",
			width: "84%",
		}),
		// select 영역과 화살표 영역 분리해주는 style 선
		indicatorSeparator : () => ({
			display: "none",
		}),
		// select 메뉴영역
		menu: styles => ({
			...styles,
			width: 100,
			backgroundColor: "#454545",
			border: "1px solid #676767",
			position: "relative",
		}),
		// select 메뉴아이템(?) 영역
		menuList: styles => ({
			...styles,
			maxHeight: 170,
		}),
	};

	return (
		<tr>
			<td className="table-key">{label}</td>
			<td className="table-question">
				<AddToolTip text={tooltipText} direction={"right"}/>
			</td>
			<td className="table-value">
				<label>
					<Select
						options={options}
						onChange={eventHandler}
						value={props.val}
						styles={styles}
					/>
				</label>
			</td>
		</tr>
	);
}
