import React, { Component } from 'react';

import "./ServerFileInfo.scss";
import {deleteRecycleFile, moveRecycleFile, getServerFileSize} from "../../../interface/api";
import Modal from "../../Common/Modal";
import {modalMsg, modalTitle, loadingMsg} from "../../../interface/constant";

class UserList extends Component{
    constructor(props) {
        super(props);
        this.state = {
            fileSize: {
                all: "",
                used: "",
                available: "",
                recycle: "",
            },
            percent: {
                all: "",
                used: "",
                available: "",
            },
            showModalRecycleDelete: false,
            showModalRecycleMove: false,
            loading: false,
        };
        this.changeRecycleDeleteProcess = null;
        this.changeRecycleMoveProcess = null;
        this.setStateFileSize();
    }

    // 완전삭제버튼 기능
    recycleFileDelete () {
        this.setState({showModalRecycleDelete: true});
        this.changeRecycleDeleteProcess = async () => {
            this.closeModal();
            this.props.loadingStart(loadingMsg.DELETING);
            let response = await deleteRecycleFile();
            this.props.loadingEnd();
            if (response.success) {
                this.setStateFileSize();
            }
        };
    }

    // 정리버튼 기능
    recycleFileMove() {
        this.setState({showModalRecycleMove: true});
        this.changeRecycleMoveProcess = async () => {
            this.closeModal();
            this.props.loadingStart(loadingMsg.CLEAN);
            let response = await moveRecycleFile();
            this.props.loadingEnd();
            if (response.success) {
                this.setStateFileSize();
            }
        };
    }

    // 모달닫기
    closeModal = () => {
        this.setState({
            showModalRecycleDelete: false,
            showModalRecycleMove: false,
        });
    };

    // 서버 용량 받아서 현재 state에 설정
    async setStateFileSize() {
        let response = await getServerFileSize();
        if (!response || !response.all) return;
        const fileSize = response.all[0];
        let recycle = response.recycle || 0;
        recycle = this.byteChange(recycle);

        const used = Math.round((fileSize.capacity) * 100);
        const available = Math.round((1 - fileSize.capacity) * 100);

        const percent = {
            all: 100 + "%",
            used: used + "%",
            available: available + "%",
        };

        this.setState({
            fileSize: {
                all: fileSize.size + " GB",
                used: fileSize.used + " GB",
                available: fileSize.available + " GB",
                recycle: recycle,
            },
            percent : percent,
        });
    }

    // byte로 받은 용량크기에 따라 KB, MB, GB로 변환
    byteChange(byte, fixed = 2) {
        const GB = 1024 * 1024 * 1024;
        const MB = 1024 * 1024;
        const KB = 1024;

        let result = byte + " B";

        if (byte >= GB) {
            result = (byte / GB).toFixed(fixed) + " GB";
        } else if (byte >= MB) {
            result = (byte / MB).toFixed(fixed) + " MB";
        } else {
            result = (byte / KB).toFixed(fixed) + " KB";
        }

        return result;
    }

    render() {
        const {fileSize, percent} = this.state;
        return (
            <div className={"file-info-area"}>
                <div className={"all-file-info"}>
                    <div>
                        <span className={"file-info-title"}>総容量</span>
                        <span className={"file-info-value"}>{fileSize.all} ({percent.all})</span>
                    </div>
                    <div>
                        <span className={"file-info-title"}>使用容量</span>
                        <span className={"file-info-value"}>{fileSize.used} ({percent.used})</span>
                    </div>
                    <div>
                        <span className={"file-info-title"}>残り容量</span>
                        <span className={"file-info-value"}>{fileSize.available} ({percent.available})</span>
                    </div>
                </div>
                <div className={"clear-file-info"}>
                    <div>
                        <span className={"file-info-title"}>整理ファイル</span>
                        <span className={"file-info-value"}>{fileSize.recycle}</span>
                    </div>
                    <div>
                        <input
                            type={"button"}
                            className={"btn_gray file-btn"}
                            name={"move"}
                            value={"整理"}
                            onClick={this.recycleFileMove.bind(this)}
                        />
                        <input
                            type={"button"}
                            className={"btn_gray file-btn"}
                            name={"delete"}
                            value={"完全削除"}
                            onClick={this.recycleFileDelete.bind(this)}
                        />
                    </div>
                </div>
                {this.state.showModalRecycleDelete &&
                <Modal
                    onClickConfirm={this.changeRecycleDeleteProcess}
                    onClickCancel={this.closeModal}
                    msg={modalMsg.DELETE}
                    title={modalTitle.WARNING}
                />
                }
                {this.state.showModalRecycleMove &&
                <Modal
                    onClickConfirm={this.changeRecycleMoveProcess}
                    onClickCancel={this.closeModal}
                    msg={modalMsg.CLEAN}
                    title={modalTitle.ALERT}
                />
                }
            </div>
        )
    }
}

export default UserList;