import React, { Component } from 'react';
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip';
import Editor from "./Editor/Editor";

import Login from "./Login/Login";
import Home from "./Home/Home";
import Import from './Import/Import';
import Setting from './Setting/Setting';
import Account from './Account/Account';
import AdminSetting from './AdminSetting/AdminSetting';
import "./Contents.css";
import InitToolTip from "../../components/Common/InitToolTip";
import Receipt from "./Receipt/Receipt";
import Log from './Log/Log';

const mapStateToProps = ({current, auth, metaData}) => ({current, auth, metaData});

class Contents extends Component {
	componentDidUpdate(prevProps, prevState, snapshot) {
		ReactTooltip.rebuild();
	}

	moveContentsPage() {
		let contents = this.props.current.contents;
		if (!this.props.auth.info) {
			return (
				<Login/>
			)
		} else if(!this.props.current.book.ready) {
			return (
				<>
					<Home className={(contents === "home") ? "" : "hide"} socket={this.props.socket}/>
					<Receipt className={(contents === "receipt") ? "" : "hide"}/>
					<Account className={(contents === "account") ? "" : "hide"}/>
					<AdminSetting className={(contents === "adminSetting") ? "" : "hide"}/>
					<Log className={(contents === "log") ? "" : "hide"}/>
				</>
			)
		} else {
			return (
				<>
					<Home className={(contents === "home") ? "" : "hide"} socket={this.props.socket}/>
					<Editor className={(contents === "editor") ? "" : "hide"} socket={this.props.socket}/>
					<Import className={(contents === "import") ? "" : "hide"} socket={this.props.socket}/>
					<Setting className={(contents === "setting") ? "" : "hide"}/>
					<Receipt className={(contents === "receipt") ? "" : "hide"}/>
					<Account className={(contents === "account") ? "" : "hide"}/>
					<AdminSetting className={(contents === "adminSetting") ? "" : "hide"}/>
					<Log className={(contents === "log") ? "" : "hide"}/>
				</>
			)
		}
	}

	render() {
		let elem = this.moveContentsPage();
		return (
			<div id={"Contents"} className="contents">
				{elem}
				<InitToolTip/>
			</div>
		)
	}
}

export default connect(mapStateToProps, null)(Contents);