import React, {Component} from 'react';
import {connect} from "react-redux";
import {RikaBlockTable} from "../../../components/Contents/Editor/Panels/SubviewPanel/innerSubview";
import {changeRectData, changeRectDataBlock} from "../../../reducers/CombineReducers/pageData";
import {objectModeConst, subViewRect} from "../../../interface/constant";
import {randomStr} from "../../../interface/utils";
import {getObjects} from "../Editor/EditorLayers/ObjectLayer";

const mapStateToProps = state => {
    const {id, page, blockId} = state.current.editor.selected;
    const {editor} = state.current;
    const objMode = objectModeConst[editor.objectMode];
    let props = {};
    if (id && page) {
        let objectList = state.pageData[page].objects[objMode];
        const idx = objectList.findIndex((item) => item.originId === id);
        const subViewSettings = editor.objectMode === "subView" ? objectList[idx].data.subViewSettings : null;
        props = {
            selectedId: id,
            selectedPage: page,
            selectedBlockId: blockId,
            blockObj: state.pageData[page].objects["block"],
            subViewSettings,
        }

    }
    return props;
};

const mapDispatchToProps = {
    changeRectData,
    changeRectDataBlock,
};

const ADD_OFFSET = 10;
let blockOffsetX = ADD_OFFSET;
let blockOffsetY = ADD_OFFSET;

class RikaBlock extends Component {
    subViewSettingEvent() {
        return {
            addBlock: () => {
                const {subViewSettings} = this.props;
                let block = subViewSettings.options.block || [];
                const defaultBlock = {
                    id: "block-" + randomStr(8),
                    rectId: "",
                    width: 80,
                    height: 30,
                    left: blockOffsetX,
                    top: blockOffsetY,
                };

                if(blockOffsetY < 580){
                    blockOffsetX += ADD_OFFSET;
                    blockOffsetY += ADD_OFFSET;
                }else{
                    blockOffsetX = blockOffsetX-560;
                    blockOffsetY = blockOffsetY-580;
                }

                const data = {
                    subViewSettings: {
                        ...subViewSettings,
                        options: {
                            ...subViewSettings.options,
                            block: block.concat(defaultBlock),
                        },
                    }
                };

                this.props.changeRectData({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    data: data,
                    objMode: "subView",
                });
            },
            changeBlockCss: (e) => {
                const value = Number(e.target.value);
                const data = {
                    [e.target.name]: value
                };

                this.props.changeRectDataBlock({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    blockId: this.props.selectedBlockId,
                    data: data,
                    subViewType: subViewRect.rikaBlock,
                });
            },
            blockInputChange: (e) => {
                let value = e.target.name === "group" ? Number(e.target.value) : e.target.value;
                const data = {
                    [e.target.name]: value,
                };

                this.props.changeRectDataBlock({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    blockId: this.props.selectedBlockId,
                    data: data,
                    subViewType: subViewRect.rikaBlock,
                });
            }
        }
    }

    render() {
        const {subViewSettings, blockObj, selectedBlockId} = this.props;
        const block = subViewSettings.options.block;
        const blockIdx = block ? block.findIndex((item) => item.id === selectedBlockId) : -1;
        const menuHide = selectedBlockId ? "" : "hide";
        const rectBlocks = getObjects(blockObj, "block") || [];
        return (
            <RikaBlockTable
                event={this.subViewSettingEvent()}
                rectBlocks={rectBlocks}
                subViewSettingsBlock={ blockIdx < 0 ? null : subViewSettings.options.block[blockIdx]}
                selectedBlockId={selectedBlockId}
                menuHide={menuHide}
                tabMenu={this.props.tabMenu}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RikaBlock);