import React, {Component} from 'react';
import PropTypes from "prop-types";

class SVGColumn extends Component {
    render() {
        return (
            <div className="svg-pointer-container">
                <div className="svg-pointer-input">
                    <div className="svg-point-color" style={{
                        backgroundColor: this.props.color
                    }}/>
                    <select
                        className="svg-column-select"
                        data-idx={this.props.index}
                        value={this.props.command}
                        onChange={this.props.onChangePathCommand}
                        disabled={(this.props.index === 0)}
                    >
                        <option value={"M"}>M</option>
                        <option value={"L"}>L</option>
                    </select>
                    <input
                        type="text"
                        className="svg-column-input"
                        data-idx={this.props.index}
                        value={this.props.value}
                        onChange={this.props.onChangePathD}
                    />
                </div>
                <div className="svg-pointer-btn-container">
                    {this.props.length <= 25 && (
                        <div className="svg-btn btn-add"
                             data-idx={this.props.index}
                             onClick={this.props.addPathD}
                        >+</div>
                    )}
                    <div className="svg-btn btn-delete"
                         data-idx={this.props.index}
                         onClick={this.props.deletePathD}
                    >X
                    </div>
                </div>
            </div>
        )
    }
}

SVGColumn.defaultProps = {
    color: "#ffffff",
    value: "",
    index: 0,
    length: 0,
    onChangePoint: null,
    addPoint: null,
    deletePoint: null,
};

SVGColumn.propTypes = {
    color: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    length: PropTypes.number.isRequired,
    onChangePathCommand: PropTypes.func.isRequired,
    onChangePathD: PropTypes.func.isRequired,
    addPathD: PropTypes.func.isRequired,
    deletePathD: PropTypes.func.isRequired,
};

export default SVGColumn;