import React, { Component } from 'react';
import Panel from "../Panel";
import './PopupPanel.scss';
import PopupTargetEdit from "../../../../../containers/Contents/PopupTargetEdit";

const panelState = {
	id:  "PopupPanel",
	label: "ポップアップ",
	name: "popup",
	style: {
		width: 200,
		top: 100,
		left: 110,
	}
};

class PopupPanel extends Component {
	keyDown(e) {
		// ObjectLayerのkeydownイベントを実行しない
		e.nativeEvent.stopImmediatePropagation();
	}

	render() {
		return (
			<div className="panel-object-list-inner inner-contents" onKeyDown={this.keyDown.bind(this)}>
				<PopupTargetEdit/>
			</div>
		)
	}
}

export default Panel(PopupPanel, panelState);