import React, {Component} from 'react';
import {connect} from "react-redux";
import {RikaMaskTable} from "../../../components/Contents/Editor/Panels/SubviewPanel/innerSubview";
import {changeRectData, changeRectDataBlock, changeRectDataSvg} from "../../../reducers/CombineReducers/pageData";
import {modifyRepoPath} from "../../../reducers/CombineReducers/repo";
import {togglePanel} from "../../../reducers/CombineReducers/panel";
import {
	maskColorOptions,
	objectModeConst,
	subViewRect,
	subjectKeyName,
	defaultMaskColor,
} from "../../../interface/constant";
import {randomStr} from "../../../interface/utils";
import {getObjects} from "../Editor/EditorLayers/ObjectLayer";
import {fileUpload} from "../../../components/Common/Upload";

const mapStateToProps = state => {
	const {id, page, blockId} = state.current.editor.selected;
	const {editor} = state.current;
	const {subject} = state.metaData;
	const objMode = objectModeConst[editor.objectMode];
	let props = {};
	if (id && page) {
		let objectList = state.pageData[page].objects[objMode];
		const idx = objectList.findIndex((item) => item.originId === id);
		const subViewSettings = editor.objectMode === "subView" ? objectList[idx].data.subViewSettings : null;

		// 지면 mask를 양면페이지에서 가져옴
		const page2 = page % 2 === 0 ? page -1 : page + 1;
		let pagesData = [page, page2];
		let maskObj = [];
		for(let page of pagesData) {
			let pageObj = state.pageData[page].objects["mask"];
			if (pageObj) {
				maskObj = maskObj.concat(getObjects(pageObj));
			}
		}

		props = {
			selectedId: id,
			selectedPage: page,
			selectedBlockId: blockId,
			bookId: state.metaData.bookId,
			subject,
			mode: state.current.editor.mode,
			panel: state.panel,
			repo: state.repo,
			maskObj,
			subViewSettings,
		}
	}
	return props;
};

const mapDispatchToProps = {
	changeRectData,
	changeRectDataBlock,
	changeRectDataSvg,
	togglePanel,
	modifyRepoPath,
};

const ADD_OFFSET = 10;
let maskOffsetX = ADD_OFFSET;
let maskOffsetY = ADD_OFFSET;

class RikaMask extends Component {
	async maskImageUpload(e, idx) {
		const result = await fileUpload(e, this.props.modifyRepoPath, this.props.bookId, "image", this.props.repo);
		if (!result || !result.fileUpload) {
			this.props.showErrorModal();
			return;
		}

		const {subViewSettings} = this.props;
		let subViewMask = subViewSettings.options.mask || [];
		if (subViewMask.length === 0) return;

		subViewMask[idx].image = result.data.key;

		let data = {
			subViewSettings: {
				...subViewSettings,
				options: {
					...subViewSettings.options,
					mask: subViewMask,
				}
			}
		};

		this.props.changeRectData({
			id: this.props.selectedId,
			page: this.props.selectedPage,
			data: data,
			objMode: "subView",
		});

	}

	subViewSettingEvent() {
		return {
			addMask: () => {
				const {subViewSettings, subject} = this.props;
				let mask = subViewSettings.options.mask || [];
				const fill = subject === subjectKeyName.sansu ? defaultMaskColor.sansu : "";
				const defaultMask = {
					id: "mask-" + randomStr(8),
					rectId: "",
					width: 200,
					height: 100,
					left: maskOffsetX,
					top: maskOffsetY,
					fill: fill,
				};

				if(maskOffsetY < 580){
					maskOffsetX += ADD_OFFSET;
					maskOffsetY += ADD_OFFSET;
				}else{
					maskOffsetX = maskOffsetX-560;
					maskOffsetY = maskOffsetY-580;
				}

				const data = {
					subViewSettings: {
						...subViewSettings,
						options: {
							...subViewSettings.options,
							mask: mask.concat(defaultMask),
						},
					}
				};

				this.props.changeRectData({
					id: this.props.selectedId,
					page: this.props.selectedPage,
					data: data,
					objMode: "subView",
				});
			},
			changeMaskCss: (e) => {
				const value = Number(e.target.value);
				const data = {
					[e.target.name]: value
				};

				this.props.changeRectDataBlock({
					id: this.props.selectedId,
					page: this.props.selectedPage,
					blockId: this.props.selectedBlockId,
					data: data,
					subViewType: subViewRect.rikaMask,
				});
			},
			maskConnectChange: (e) => {
				const rectId = e.target.value;
				const data = {
					rectId: rectId,
				};

				this.props.changeRectDataBlock({
					id: this.props.selectedId,
					page: this.props.selectedPage,
					blockId: this.props.selectedBlockId,
					data: data,
					subViewType: subViewRect.rikaMask,
				});
			},
			svgCopy: (selectedMask) => {
				if (!selectedMask) return;
				const rectId = selectedMask.rectId;
				const data = {};

				if (rectId) {
					const idx = this.props.maskObj.findIndex((mask) => mask.originId === rectId);
					if (idx >= 0 && this.props.maskObj[idx] && this.props.maskObj[idx].svg) {
						data.svg = this.props.maskObj[idx].svg;
					}
				}

				this.props.changeRectDataSvg({
					id: this.props.selectedId,
					page: this.props.selectedPage,
					blockId: this.props.selectedBlockId,
					data: data,
					subViewType: subViewRect.rikaMask,
				});
			},
			colorTextChange: (e) => {
				const {subViewSettings, selectedBlockId} = this.props;
				// 컬러코드 맨앞에 # 넣어주기
				const value = e.target.value ? e.target.value[0] !== "#" ? "#" + e.target.value : e.target.value : "";

				const mask = subViewSettings.options.mask;
				const maskIdx = mask ? mask.findIndex((item) => item.id === selectedBlockId) : -1;
				mask[maskIdx].fill = value;

				const data = {
					subViewSettings: {
						...subViewSettings,
						options: {
							...subViewSettings.options,
							mask: mask,
						},
					}
				};
				this.props.changeRectData({
					id: this.props.selectedId,
					page: this.props.selectedPage,
					data: data,
					objMode: "subView",
				});
			},
			colorSelect: (color) => {
				const {subViewSettings, selectedBlockId} = this.props;
				const value = color.value;

				const mask = subViewSettings.options.mask;
				const maskIdx = mask ? mask.findIndex((item) => item.id === selectedBlockId) : -1;
				mask[maskIdx].fill = value;

				const data = {
					subViewSettings: {
						...subViewSettings,
						options: {
							...subViewSettings.options,
							mask: mask,
						},
					}
				};
				this.props.changeRectData({
					id: this.props.selectedId,
					page: this.props.selectedPage,
					data: data,
					objMode: "subView",
				});
			},
			deleteMaskImage: (idx) => {
				if (idx < 0) return;
				const {subViewSettings} = this.props;
				let subViewMask = subViewSettings.options.mask || [];
				if (subViewMask.length === 0) return;
				if (!subViewMask[idx].image) return;

				delete subViewMask[idx].image;

				let data = {
					subViewSettings: {
						...subViewSettings,
						options: {
							...subViewSettings.options,
							mask: subViewMask,
						}
					}
				};

				this.props.changeRectData({
					id: this.props.selectedId,
					page: this.props.selectedPage,
					data: data,
					objMode: "subView",
				});
			},
		}
	}

	setLabel(color) {
		if(color.value === "") {
			return (
				<div>
					<div className={"mask-color-select-contents"}>
						<span className={"mask-color-select-text"}>{color.name}</span>
					</div>
				</div>
			);
		}
		return (
			<div>
				<div className={"mask-color-select-contents"} style={{backgroundColor: color.value}}>
					<span className={"mask-color-select-text"}>{color.value}</span>
				</div>
			</div>
		)
	}

	render() {
		const {subViewSettings, maskObj, selectedBlockId} = this.props;
		const mask = subViewSettings.options.mask;
		const maskIdx = mask ? mask.findIndex((item) => item.id === selectedBlockId) : -1;
		const rectMask = getObjects(maskObj, "mask") || [];
		const svgData = {
			changeRectDataSVG: this.props.changeRectDataSvg,
			togglePanel: this.props.togglePanel,
			item: maskIdx >= 0 ? mask[maskIdx] : {},
			panel: this.props.panel,
			id: this.props.selectedId,
			page: this.props.selectedPage,
			blockId: this.props.selectedBlockId,
			objMode: "subview",
			mode: this.props.mode,
			svg: maskIdx >= 0 && mask[maskIdx].svg ? mask[maskIdx].svg.attr.points : "",
			subViewType: subViewRect.rikaMask
		};


		maskColorOptions.map((color) => {
			color.label = this.setLabel(color);
			return color;
		});

		const existMask = maskIdx >= 0 && mask[maskIdx];
		const selectedColorValue = existMask ? maskColorOptions.filter((color) => color.value === mask[maskIdx].fill) : null;
		const colorSelectValue = selectedColorValue && selectedColorValue.length <= 0 ? maskColorOptions[0] : selectedColorValue;

		const colorTextValue = (existMask && mask[maskIdx].fill) ? mask[maskIdx].fill : "";
		const colorCheck = /^#[0-9a-f]{3,6}$/i;	// 컬러코드 유효성 체크 정규식
		const isValidColor = colorCheck.test(colorTextValue) ? colorTextValue : "";

		const maskSettingsHide = existMask ? "" : "hide";
		const imageDeleteHide = maskIdx >= 0 && mask[maskIdx] ? mask[maskIdx].image ? "" : "hide" : "hide";
		return (
			<RikaMaskTable
				event={this.subViewSettingEvent()}
				rectMask={rectMask}
				subViewSettingsMask={ maskIdx < 0 ? null : subViewSettings.options.mask[maskIdx]}
				selectedMaskId={selectedBlockId}
				maskIdx={maskIdx}
				imageDeleteHide={imageDeleteHide}
				maskColorOptions={maskColorOptions}
				colorSelectValue={colorSelectValue}
				colorTextValue={colorTextValue || ""}
				color={isValidColor || ""}
				maskSettingsHide={maskSettingsHide}
				svgData={svgData}
				upload={(e, idx) => this.maskImageUpload(e, idx)}
				bookId={this.props.bookId}
				tabMenu={this.props.tabMenu}
			/>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(RikaMask);