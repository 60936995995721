import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { dragType } from "../../../../../interface/constant";
import defaultPageImagePath from "../../../../../images/no-image.jpg";

const LinkPageItem = ({item, thumbUrl, index, moveItem, moveEnd, deleteItem, uploadImg, bookId}) => {
	const ref = useRef(null);
	const [, drop] = useDrop({
		accept: dragType.LINK_PAGES_ITEM,
		hover: (item, monitor) => {
			if (!ref.current) return;

			const dragIndex = item.index;
			const hoverIndex = index;
			if (dragIndex === hoverIndex) return;

			const hoverBoundingRect = ref.current.getBoundingClientRect();
			const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
			const clientOffset = monitor.getClientOffset();
			const hoverClientX = clientOffset.x - hoverBoundingRect.left;

			if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) return;
			if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) return;
			moveItem(dragIndex, hoverIndex);
			item.index = hoverIndex;
		},
		drop: moveEnd,
	});

	const deleteFunc = () => deleteItem(item);

	const uploadImgFunc = (e) => uploadImg(e, index, item);

	const [{isDragging}, drag] = useDrag({
		item: {type: dragType.LINK_PAGES_ITEM, item, index},
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
	});
	const opacity = isDragging ? 0 : 1;
	drag(drop(ref));
	return (
		<div className={"link-page-item"} ref={ref} style={{opacity}} data-id={item.originId}>
			<div className={"link-page-thumb"}>
				<div className={"add-thumb"}>
					<label htmlFor={"changeThumb_" + item.originId}>+</label>
				</div>
				<div className={"delete-link-page"} onClick={deleteFunc}>X</div>
				<div className={"thumb"} style={{backgroundImage: `url("${thumbUrl || defaultPageImagePath}")`}}/>

				<div className={"hidden-forms hide"}>
					<form method={"post"} encType={"multipart/form-data"}>
						<input
							type={"file"}
							id={"changeThumb_" + item.originId}
							accept={".png"}
							onChange={uploadImgFunc}
						/>
						<input type={"hidden"} name={"bookId"} defaultValue={bookId}/>
					</form>
				</div>
			</div>
			<div className={"link-page-label"}>
				<span>{item.id}</span>
			</div>
		</div>
	)
};
export default LinkPageItem
