import {imageUpload, videoUpload, audioUpload, upload, addRepo} from "../../interface/api";
import {now, randomStr} from "../../interface/utils";

export async function fileUpload(e, modifyRepoPath, bookId, kind, repo) {
    let form = e.target.parentElement;
    let file = e.target.files[0];
    e.target.value = "";
    let pathInfo;
    if (kind === "image" || kind === "popup") {
      pathInfo =  await imageUpload(form, file);
    } else if (kind === "video") {
      pathInfo = await videoUpload(form, file);
    } else if (kind === "srt" || kind === "pdf" || kind === "html") {
      pathInfo = await upload(form, file);
    } else if (kind === "audio") {
      pathInfo = await audioUpload(form, file);
    }

    // let key = pathInfo.originalname.split(".")[0];
    let key = randomStr(12);

    if (repo[kind][key]) return false;

    let repoData = {
      key,
      kind,
      path: pathInfo.path,
      originalname: pathInfo.originalname,
    };
    modifyRepoPath(repoData);
    repoData.regDate = now();

    return await addRepo(bookId, repoData);
}

