import React, {Component} from 'react';
import {connect} from 'react-redux'
import Draggable from 'react-draggable';

import {objectModeConst} from "../../../../interface/constant";
import {checkNested, makeColorCodeHSL, clamp} from "../../../../interface/utils";
import {changeRectSVG} from "../../../../reducers/CombineReducers/pageData";

const mapStateToProps = (state) => {
    const {id, page} = state.current.editor.selected;
    const {editor} = state.current;
    const objMode = objectModeConst[editor.objectMode];
    let props = {
        scale: editor.scale,
        mode: editor.mode,
        pages: editor.pages,
        view: state.metaData.view,
        direction: state.metaData.direction,
        objMode: objMode,
    };
    if (id && page) {
        let objectInfo = state.pageData[page].objects[objMode];
        props.item = objectInfo.filter((item) => item.originId === id)[0];
        props.id = id;
        props.page = page;
    }
    return props;
};

const mapDispatchToProps = {
    changeRectSVG
};

class SVGEditLayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            style: {},
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps === prevState.prevProps) return null;
        if(!nextProps.item) return null;
        let points = [];
        let d = [];
        const item = nextProps.item;
        const scale = nextProps.scale;
        let width = 0;
        const widthHalf = nextProps.view.width * scale / 2;
        const isRightInLtR = (nextProps.direction === "ltr" && nextProps.page % 2 === 0);
        const isLeftInRtL = (nextProps.direction === "rtl" && nextProps.page % 2 === 1);
        if (isRightInLtR || isLeftInRtL) width = widthHalf;

        let style = {
            width: item.css.width * scale,
            height: item.css.height * scale,
            top: item.css.top * scale,
            left: item.css.left * scale + width,
        };
        if (checkNested(nextProps, "item.svg.attr.points")) {
            points = nextProps.item.svg.attr.points.split(" ");
        } else if (checkNested(nextProps, "item.svg.attr.d")) {
            d = nextProps.item.svg.attr.d.match(/([a-yA-Y]+\s?[-]?\d+(\.\d+)?\s?[-]?\d+(\.\d+)?)/g);
        }
        const isPolygon = (item.svg && item.svg.type === "polygon");

        return {
            style: style,
            points: points,
            d: d,
            isPolygon: isPolygon,
            prevProps: nextProps,
        };
    }

    handleStart(e) {
        e.stopPropagation();
    }

    handleDrag(e) {
        e.stopPropagation();
    }

    handleStop(e, data) {
        e.stopPropagation();
        const idx = parseInt(data.node.getAttribute("data-idx"));
        if (idx >= 0) {
            let x = clamp(1, 99, Math.floor((data.x) / this.state.style.width * 100));
            let y = clamp(1, 99, Math.floor((data.y) / this.state.style.height * 100));

            if (this.state.isPolygon) {
                let newPoints = [...this.state.points];
                newPoints[idx] = `${x},${y}`;
                this.changeRectPoint(newPoints, this.state.isPolygon);
            } else {
                let newD = [...this.state.d];
                let c = newD[idx].match(/[a-zA-Z]/g, '');
                newD[idx] = `${c} ${x} ${y}`;
                newD.push("Z");
                this.changeRectPoint(newD, this.state.isPolygon);
            }
        }
    }

    changeRectPoint(newArr, isPolygon) {
        let type = isPolygon ? "points" : "d";
        let options = {
            id: this.props.id,
            page: this.props.page,
            svg: {
                ...this.props.item.svg,
                attr: {[type]: newArr.join(" ")}
            },
            objMode: this.props.objMode,
        };
        this.props.changeRectSVG(options);
    }

    renderPolygonPoints() {
        let points = this.state.points;
        return points.map((point, idx) => {
            let [top, left] = point.split(",").map(i => parseFloat(i));
            return this.renderDraggable(top, left, idx);
        });
    }

    renderPathDPoints() {
        let d = this.state.d;
        return d.map((d, idx) => {
            let [top, left] = d.replace(/[a-zA-Z]\s?/g, '').split(" ").map(i => parseInt(i));
            return this.renderDraggable(top, left, idx);
        })
    }

    renderDraggable(top, left, idx) {
        return (
            <Draggable
                position={{
                    x: this.state.style.width * top / 100,
                    y: this.state.style.height * left / 100,
                }}
                onStart={this.handleStart.bind(this)}
                onDrag={this.handleDrag.bind(this)}
                onStop={this.handleStop.bind(this)}
                key={idx}
                bounds="parent"
            >
                <div
                    className="svg-points"
                    data-idx={idx}
                    style={{backgroundColor: makeColorCodeHSL(idx)}}
                />
            </Draggable>
        )
    }

    render() {
        return (
            <div id="SVGEditLayer">
                <div className="selected-object" style={this.state.style}>
                    {this.state.isPolygon ? this.renderPolygonPoints() : this.renderPathDPoints()}
                    <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
                        <path stroke="#00000021" fillOpacity="0" vectorEffect="non-scaling-stroke" d="
							M10 0 L10 100 M20 0 L20 100 M30 0 L30 100 M40 0 L40 100 M50 0 L50 100
							M60 0 L60 100 M70 0 L70 100 M80 0 L80 100 M90 0 L90 100"
                        />
                        <path stroke="#00000021" fillOpacity="0" vectorEffect="non-scaling-stroke" d="
							M0 10 L100 10 M0 20 L100 20 M0 30 L100 30 M0 40 L100 40 M0 50 L100 50
							M0 60 L100 60 M0 70 L100 70 M0 80 L100 80 M0 90 L100 90"
                        />
                    </svg>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SVGEditLayer);
