import React, {Component} from 'react';
import './AddToolTip.scss';
import question from "../../images/icon-question.png";

/**
 * tooltip 추가 기능
 * props요소
 *  text: 툴팁에 나타낼 문자(없거나 빈값이면 툴팁영역 hide 처리)
 *  direction: 툴팁방향(right, left, bottom, top)
 *  isQuestion: ? 버튼 이미지를 사용 할 것인지 여부(true라면 사용함. 기본값 = true)
 */
class AddToolTip extends Component {
	render() {
		const {text, direction, isQuestion=true} = this.props;
		const textHide = text ? "" : " hide";
		const noQuestion = isQuestion ? "" : " no-question";
		const questionHide = isQuestion ? "" : " hide";
		return (
			<div className={"tooltip-area" + textHide + noQuestion} data-tip={text} data-for={direction}>
				<img src={question} alt={"question"} className={"question-image" + questionHide}/>
			</div>
		)
	}
}

export default AddToolTip;