import React, { Component } from 'react';
import { connect } from 'react-redux'
import "./Loading.scss";
import GrayScreen from "../../components/Common/GrayScreen";

const mapStateToProps = (state) => {
	return {
		loading: state.current.loading,
	}
};

class Loading extends Component {
	render() {
		return (
			<GrayScreen className={this.props.loading.isLoading ? "" : "hide"}>
				<div id={"Loading"}>
					<div className={"spinner-container"}>
						<div className={"spinner"}>
							<div className={"msg"}>
								<span>{this.props.loading.msg}</span>
							</div>
						</div>
					</div>
				</div>
			</GrayScreen>
		);
	}
}

export default connect(mapStateToProps, null)(Loading);