import React, { Component } from 'react';
import Panel from "../Panel";
import SubviewMenu from "../../../../../containers/Contents/Editor/SubviewMenu";

const panelState = {
	id: "SubViewPanel",
	name: "screen",
	label: "拡大画面編集",
	style: {
		panelSize: {
			default: {
				width: 950,
				height: 570
			}, 
			large: {
				width: 1080,
				height: 570
			}
		},
		top: 50,
		left: 10,
	}
};

class SubviewPanel extends Component {

	onClickRepo = (e) => {
		console.log(`onClickRepo _ e.currentTarget.value: `, e.currentTarget.value);
	};

	onClickUpload = (e) => {
		console.log(`onClickUpload _ e.currentTarget.value: `, e.currentTarget.value);
	};

	keyDown(e) {
		// ObjectLayerのkeydownイベントを実行しない
		e.nativeEvent.stopImmediatePropagation();
	}

	render() {
		return (
			<SubviewMenu
				onClickRepo={this.onClickRepo}
				onClickUpload={this.onClickUpload}
				keyDown={this.keyDown}
			/>
		)
	}
}

export default Panel(SubviewPanel, panelState);