import React, { Component } from 'react';
import './EditorPanel.scss';
import PropTypes from "prop-types";

import ObjectLayer from '../../../../../containers/Contents/Editor/EditorLayers/ObjectLayer';
import DrawLayer from "../../../../../containers/Contents/Editor/EditorLayers/DrawLayer";
import DragLayer from "../../../../../containers/Contents/Editor/EditorLayers/DragLayer";
import MyEditorLayer from "../../../../../containers/Contents/Editor/EditorLayers/MyEditorLayer";
import EditorNavigation from "../../../../../containers/Contents/Editor/EditorLayers/EditorNavigation";
import BackgroundLayer from "../../../../../containers/Contents/Editor/EditorLayers/BackgroundLayer";
import { objectModeConst as mode } from "../../../../../interface/constant";
import SVGEditLayer from "../../../../../containers/Contents/Editor/EditorLayers/SVGEditLayer";
import PopupLayer from "../../../../../containers/Contents/Editor/EditorLayers/PopupLayer";

class EditorPanel extends Component {
	getLayerByMode() {
		const {objMode, mode} = this.props;
		if( ["group", "linkPages", "guideMode"].includes(mode) ) {
			return  <DragLayer/>;
		} else if ( objMode === "myEditor") {
			return <MyEditorLayer/>;
		} else if (mode === "popup") {
			return <PopupLayer/>
		}
		return <ObjectLayer/>;
	}

	render() {
		return (
			<div id="EditorPanel" style={{...this.props.editorSize}}>
				<BackgroundLayer/>
				{(this.props.mode === "draw") && <DrawLayer/>}
				{(this.props.mode === "svg") && <SVGEditLayer/>}
				{this.getLayerByMode()}
				<EditorNavigation socket={this.props.socket}/>
			</div>
		)
	}
}

EditorPanel.defaultProps = {
	objMode: mode.subView,
};

EditorPanel.propTypes = {
	objMode: PropTypes.string.isRequired,
};

export default EditorPanel;