const AUTH_LOGIN = "AUTH_LOGIN";

const initialState = {
	info: null,
	isLoggedIn: false,
	bookList: null,
};

//Reducer
export default function reducer(state = initialState, action) {
	if(action.type === AUTH_LOGIN) {
		return {
			...state,
			info: action.info,
			isLoggedIn: true
		};
	}
	return state;
};

//Action Generators

export const userLogin = state => ({
	type: AUTH_LOGIN,
	info: state.info,
});