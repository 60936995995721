import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";

import {
	TextProperty,
	SelectProperty,
	TextBoxProperty,
	ButtonProperty,
	NumberProperty,
	FileProperty,
} from "../../../../components/Contents/Editor/Panels/PropertyPanel/innerProperty";
import {
	changeBookData,
	changeRectId,
	changeRectPage,
	changeRectData,
	changeRectOptions,
} from '../../../../reducers/CombineReducers/pageData';
import {
	objectModeConst,
	propertyInputLabel,
	propertyInputName,
	propertyToolTip,
	popupPositionOptions,
	popupTypeOptions,
	dataModeConst,
	subjectKeyName, modalMsg, modalTitle,
} from '../../../../interface/constant';
import {togglePanel} from "../../../../reducers/CombineReducers/panel";
import { setSelectItem, changeEditorMode } from "../../../../reducers/CombineReducers/current";
import { modifyRepoPath } from "../../../../reducers/CombineReducers/repo";
import PosSizeProperty from "../../../../components/Contents/Editor/Panels/PropertyPanel/posSizeProperty";
import Modal from "../../../../components/Common/Modal";
import {fileUpload} from "../../../../components/Common/Upload";

const mapStateToProps = (state) => {
	const objMode = objectModeConst[state.current.editor.objectMode];
	let {id, page} = state.current.editor.selected;
	let prop = {};
	if (id && page) {
		let objectInfo = state.pageData[page].objects[objMode];
		let item = objectInfo.filter((item) => item.originId === id)[0];
		prop = {
			item,
			mode: state.current.editor.mode,
			pages: state.current.editor.pages,
			view: state.metaData.view,
			direction: state.metaData.direction,
			panel: state.panel,
			bookId: state.metaData.bookId,
			repo: state.repo,
			id,
			page,
			objMode
		};
	}
	prop.subject = state.metaData.subject;
	return prop;
};

const mapDispatchToProps = {
	changeBookData,
	changeRectId,
	changeRectPage,
	changeRectData,
	setSelectItem,
	changeRectOptions,
	changeEditorMode,
	togglePanel,
	modifyRepoPath,
};

let property = {
	currentId: "",
	changedId: "",
	page: 0,
	objMode: "",
};

class PopupTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: "",
			propertyCss: {
				width: "",
				height: "",
				top: "",
				left: "",
			},
			type: 0,
			showModalError: false,
		}
		this.errorProcess = null;
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps === prevState.prevProps) return null;
		const propertyCss = PopupTable.arrangeCss(prevState.propertyCss, nextProps.item.css);
		return {propertyCss}
	}

	static arrangeCss(prevCss, nextCss) {
		return {
			...nextCss,
			width: prevCss.width === "" ? prevCss.width : nextCss.width,
			height: prevCss.height === "" ? prevCss.height : nextCss.height,
			top: prevCss.top === "" ? prevCss.top : nextCss.top,
			left: prevCss.left === "" ? prevCss.left : nextCss.left,
		}
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.props.item.id !== prevProps.item.id) {
			const id = this.props.item.id || "";
			const propertyCss = this.props.item.css;
			this.setState({
				id: id,
				propertyCss,
			})
		}
	}

	async imageUpload(e) {
		const result = await fileUpload(e, this.props.modifyRepoPath, this.props.bookId, "popup", this.props.repo);
		if (!result || !result.fileUpload) {
			this.showErrorModal();
			return;
		}

		let data = {
			popup: {
				...this.props.item.data.popup,
				popupKey: result.data.key,
			}
		};
		this.props.changeRectData({
			id: this.props.id,
			page: this.props.page,
			data: data,
			objMode: this.props.objMode,
		});
	}

	showErrorModal() {
		this.errorProcess = (e) => {
			e.stopPropagation();
			this.toggleModal("Error", false);
			this.errorProcess = () => {};
		};
		this.toggleModal("Error", true);
	}

	toggleModal(type = "Error", show = true) {
		let modalType = `showModal${type}`;
		this.setState({
			...this.state,
			[modalType]: show,
		})
	}

	eventHandler() {
		return {
			onChange: (e) => {
				this.setState({
					...this.state,
					propertyCss: {
						...this.state.propertyCss,
						[e.target.name]: e.target.value,
					}
				});

				const value = e.target.value || 0;
				this.props.changeBookData({
					id: this.props.id,
					page: this.props.page,
					css: {
						[e.target.name]: (e.target.name !== 'opacity' ? parseInt(value, 10) : Number(value))
					},
					objMode: this.props.objMode
				});
			},
			optionsChange: (e) => {
				let {page, objMode, item} = property;
				const itemOptions = Object.keys(this.props.item).length !== 0 ? this.props.item.options : item.options;
				let options = this.props.setOptionsValue(e.target, itemOptions);
				this.props.changeRectOptions({
					id: this.props.id,
					page: this.props.page || page,
					options: {
						[options.name]: options.value
					},
					objMode: this.props.objMode || objMode,
				});
			},
			pageChange: (e) => {
				let page = Number(e.target.value);
				this.props.changeRectPage({
					id: this.props.id,
					page: page,
					width: this.props.view.width / 2,
					objMode: this.props.objMode,
					direction: this.props.direction,
				});
				this.props.setSelectItem({
					id: this.props.id,
					page: page
				});
			},
			dataChange: (e) => {
				let data = {
					popup: {
						...this.props.item.data.popup,
						[e.target.name]: e.target.value,
					}
				};
				this.props.changeRectData({
					id: this.props.id,
					page: this.props.page,
					data: data,
					objMode: this.props.objMode,
				});
			},
			togglePopupTargetLayer: () => {
				const mode = this.props.mode === "popup" ? "edit" : "popup";
				this.props.changeEditorMode({mode});
				this.props.togglePanel({
					name: dataModeConst.POPUP,
					isOpen: !this.props.panel[dataModeConst.POPUP],
				});

			},
		}
	}

	setPropertyId(e) {
		property.currentId = this.props.id;
		property.changedId = e.target.value;
		property.page = this.props.page;
		property.objMode = this.props.objMode;
	}

	changeId(e) {
		this.setState({
			id: e.target.value
		});
		property.changedId = e.target.value;
	}

	changePageDataId() {
		let {currentId, changedId, page, objMode} = property;
		this.props.changeRectId({
			currentId: currentId,
			changedId: changedId,
			page: page,
			objMode: objMode
		});
	}

	kokugoPopupElem() {
		const {item, pages} = this.props;
		return (
			<>
				<SelectProperty
					label={propertyInputLabel.popupType}
					name={propertyInputName.popupType}
					options={pages ? popupTypeOptions : []}
					val={item.data.popup ? item.data.popup.type || "" : ""}
					eventHandler={this.eventHandler().dataChange}
				/>
				<SelectProperty
					label={propertyInputLabel.popupPosition}
					name={propertyInputName.popupPosition}
					options={pages ? popupPositionOptions : []}
					val={item.data.popup ? item.data.popup.position || "" : ""}
					eventHandler={this.eventHandler().dataChange}
					hide={item.data.popup ? item.data.popup.type === "word" ? "" : "hide" : "hide"}
				/>
				<TextProperty
					label={propertyInputLabel.popupKey}
					name={propertyInputName.popupKey}
					val={item.data.popup ? item.data.popup.popupKey || "" : ""}
					onChange={this.eventHandler().dataChange}
				/>
				<FileProperty
					label={propertyInputLabel.popupKey}
					htmlFor={"popupImage"}
					btnName={"アップロード"}
					accept={".png"}
					bookId={this.props.bookId}
					onChange={this.imageUpload.bind(this)}
				/>
				<ButtonProperty
					label={propertyInputLabel.popupTarget}
					name={propertyInputName.popupTarget}
					btnName={"編集"}
					onClick={this.eventHandler().togglePopupTargetLayer}
					tooltipText={propertyToolTip.popupTarget}
				/>
			</>
		)
	}

	tumazukiElem() {
		const {item} = this.props;
		return (
			<>
				<TextProperty
					label={propertyInputLabel.popupTitle}
					name={propertyInputName.popupTitle}
					val={item.data.popup ? item.data.popup.title || "" : ""}
					onChange={this.eventHandler().dataChange}
				/>
				<NumberProperty
					label={propertyInputLabel.answerRate}
					name={propertyInputName.answerRate}
					val={item.data.popup ? item.data.popup.desc || "" : ""}
					onChange={this.eventHandler().dataChange}
				/>
				<TextBoxProperty
					label={propertyInputLabel.popupComment}
					name={propertyInputName.popupComment}
					val={item.data.popup ? item.data.popup.comment || "" : ""}
					readOnly={false}
					onChange={this.eventHandler().dataChange}
					className={"popupEditTextBox"}
				/>
			</>
		)
	}

	render() {
		const {id, page, pages, subject, item} = this.props;
		const pageInfoOptions = this.props.pages ? [
			{val: pages.left, name: pages.left},
			{val: pages.right, name: pages.right}
		] : [];

		const isTumazuki = subject === subjectKeyName.sansu || subject === subjectKeyName.rika;
		const propertyElem = isTumazuki ? this.tumazukiElem() : this.kokugoPopupElem();
		return (
			<div className="inner-table">
				<table>
					<tbody>
					<TextProperty
						label={propertyInputLabel.id}
						name={propertyInputName.id} val={this.state.id}
						readOnly={false}
						onFocus={this.setPropertyId.bind(this)}
						onBlur={this.changePageDataId.bind(this)}
						onChange={this.changeId.bind(this)}
						tooltipText={propertyToolTip.id}
					/>
					<SelectProperty
						label={propertyInputLabel.page}
						name={propertyInputName.page} options={pageInfoOptions}
						eventHandler={this.eventHandler().pageChange} val={page}
						tooltipText={propertyToolTip.page}
					/>
					<NumberProperty
						label={propertyInputLabel.zIndex}
						name={propertyInputName.zIndex}
						val={item.options.link ? item.options.link.zIndex || "" : ""}
						onChange={this.eventHandler().optionsChange}
						tooltipText={propertyToolTip.zIndex}
					/>
					<PosSizeProperty css={this.state.propertyCss} eventHandler={this.eventHandler()}/>
					{propertyElem}
					</tbody>
				</table>
				<div className="inner-mask" style={{display: id ? "none" : ""}}/>
				{this.state.showModalError && <Modal
					onClickConfirm={(e) => this.errorProcess(e)}
					msg={modalMsg.IMAGE_EXIST}
					title={modalTitle.ERROR}
				/>}
			</div>
		)
	}
}

PopupTable.defaultProps = {
	item: {
		originId: "",
		id: "",
		css: {},
		options: {},
		page: 0,
		data: {},
	},
	page: 0,
	id: ""
};

PopupTable.propTypes = {
	item: PropTypes.shape({
		originId: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		css: PropTypes.object.isRequired,
		options: PropTypes.object.isRequired,
		data: PropTypes.object.isRequired,
		page: PropTypes.number.isRequired
	}).isRequired,
	page: PropTypes.number.isRequired,
	id: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupTable);