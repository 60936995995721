import {chapterConst, chapterPropEn, chapterPropJp, csvModalMsg} from "../../../interface/csvConstant";
import {getNumberLine, makeErrorMsg, stringParser} from "../../../interface/utils";
import {onlyNumberCheck} from "./csvUtils";

/**
 * redux chapter 배열을 받아서 clone 한 배열을 반환해주는 함수
 * @param {array} metaChapter - this.props.metaData.chapter
 * @returns {Array} - cloned this.props.metaData.chapter 배열
 */
export const DeepCloneChapter = (metaChapter) => {
	const chapterMetaArr = [...metaChapter];
	const newArr = [];
	for (const obj of chapterMetaArr) {
		const newObj = {};
		for (const [k, v] of Object.entries(obj)) {
			newObj[k] = (Array.isArray(v)) ? [...v] : v
		}
		newArr.push(newObj);
	}
	return newArr;
};

/**
 * cloneChapterArr 를 map 하면서 다루기 쉽게 객체 구조 변경
 * @param {array} cloneChapterArr - this.props.metaData.chapter 를 clone 한 배열
 * @returns {array} - idx, name, firstPage, lastPage 속성을 갖는 객체들의 배열
 */
export const getProcessedData = (cloneChapterArr) => {
	return cloneChapterArr.map((obj, idx) => {
		const index = idx + 1;
		const name = obj['name'];
		const firstPage = obj['pages'][0];
		const lastPage = obj['pages'][obj['pages'].length-1];
		return {
			idx: index,
			name: name,
			firstPage,
			lastPage
		}
	});
};

/**
 * chapterObjArr 을 순회하면서 csv 에서 보여줄 row 문자열을 생성하여 리턴하는 함수
 * @param {array} chapterObjArr - idx, name, firstPage, lastPage 속성을 갖는 객체들의 배열
 * @returns {string} - csv row 문자열
 */
export const getRows = (chapterObjArr) => {
	let rows = '';
	chapterObjArr.forEach(e => {
		rows +=  e.name + ','; // タイトル
		rows +=  e.firstPage + ','; // 始め
		rows +=  e.lastPage + '\r\n'; // 終わり
	});
	return rows;
};


/**
 * csvColumns 와 지정해둔 csv 컬럼 배열 importChapterColumn 가 다른 경우 에러를 던지는 함수
 * @param {array} csvColumns - csv 로 입력받은 chapter csv 의 컬럼 정보
 */
export const importColumnMatchedCheck = (csvColumns) => {
	const importChapterColumn = ['タイトル',　'始め',　'終わり'];
	if (csvColumns.toString() !==  importChapterColumn.toString()) {
		throw new Error(makeErrorMsg('unmatchedColumn', csvModalMsg.chapter));
	}
};

/**
 * CSV 로 받은 컬럼 이름을 원래의 속성 이름과 속성값으로 되돌리는 함수
 * @param {array} rowData - 입력받은 csv 파일의 rowData
 * @returns {array} - 속성명은 영어, 속성값은 parse 된 객체들의 배열
 */
export const getPropNameValueChange = (rowData) => {
	return rowData.map(obj => {                     // 배열 순회
		const newObj = {};                                    // 새 객체 생성
		for (const [k, v] of Object.entries(obj)) {           // 객체내를 순환
			newObj[chapterPropJp[k]] = stringParser(v);       // 객체 속성 이름을 바꾸고 거기에 속성값을 파싱해서 넣음
		}
		return newObj;                                        // 바뀐 객체를 반환
	});
};

/**
 * csv 이전 라인의 idx 가 현재 입력되는 값이 현재 값보다 큰 경우 에러를 던지는 함수
 * @param idxBox - idx 들을 담아놓은 배열
 * @param prop - 객체 속성의 이름
 * @param csvIdx - csv 상에서 문제가 되는 객체의 라인넘버수
 * @param val - 객체 속성의 값
 */
/*const orderAscendingCheck = (idxBox, prop, csvIdx, val) => {
	if (idxBox.length !== 0) {
		const prevIdx = idxBox[idxBox.length-1];
		if (prevIdx > val) {
			throw new Error(makeErrorMsg('chapterOrder', csvIdx, chapterPropEn[prop]));
		}
	}
};*/

/**
 * idx 속성의 값이 중복되는 경우 에러를 던지는 함수,
 * 중복되지 아니하면 idx 가 오름차순인지 하고 아니면 에러를 던지는 함수
 * @param {number[]}idxBox
 * @param {number} val - 객체 속성의 값
 * @param {string} prop - 객체 속성의 이름
 * @param {number} csvIdx - csv 상에서 문제가 되는 객체의 라인넘버수
 */
/*const orderDuplicateAscCheck = (idxBox, val, prop, csvIdx) => {
	if (prop === chapterConst.idx) {
		if (!idxBox.includes(val)) {
			orderAscendingCheck(idxBox, prop, csvIdx, val);
			idxBox.push(val);
		} else {
			throw new Error(makeErrorMsg('unique', csvIdx, chapterPropEn[prop]));
		}
	}
};*/

/**
 * 객체의 속성이 검사 대상 속성 이면서 입력한 값이 숫자가 아닌 경우 에러를 던지는 함수
 * @param {number} val - 객체 속성의 값
 * @param {string} prop - 객체 속성의 이름
 * @param {number} csvIdx - csv 상에서 문제가 되는 객체의 라인넘버수
 * @param {string} target - 검사 대상 속성
 */
/*const isNumberCheck = (val, prop, csvIdx, target) => {
	const isTarget = prop === target;
	if (isTarget && isNaN(val)) {
		throw new Error(makeErrorMsg('onlyNumber', csvIdx, chapterPropEn[prop]));
	}
};*/

/**
 * 속성이 name 이면서 속성 값의 length 가 50을 초과하는 경우 에러를 던지는 함수
 * @param {string} val - 객체 속성의 값
 * @param {string} prop - 객체 속성의 이름
 * @param {number} csvIdx - csv 상에서 문제가 되는 객체의 라인넘버수
 */
const nameTooLongCheck = (val, prop, csvIdx) => {
	if (prop === chapterConst.name && val.length > 50) {
		throw new Error(makeErrorMsg('tooLong', csvIdx, chapterPropEn[prop]));
	}
};

/**
 * prop 가 firstPage 이거나 last Page 이면서 속성의 값이 전체 페이지 수 보다 큰 경우 에러를 던지는 함수
 * @param {number} val - 객체 속성의 값
 * @param {string} prop - 객체 속성의 이름
 * @param {number} csvIdx - csv 상에서 문제가 되는 객체의 라인넘버수
 * @param {number} totalPage - 전체 페이지 수
 */
const pageOverTotalCheck = (val, prop, csvIdx, totalPage) => {
	if (prop !== chapterConst.name && (val < 1 || val > totalPage)) { // page 입력값이 1보다 작은 음수거나 totalPage 를 초과한 경우
		throw new Error(makeErrorMsg('overRange', csvIdx, chapterPropEn[prop]));
	}
};

/**
 * 속성이 firstPage 인 경우, 속성 값이 lastPage 보다 낮으면 에러를 던지고
 * 속성이 lastPage 인 경우, 속성 값이 firstPage 보다 낮으면 에러를 던지는 함수
 * @param {number} val - 객체 속성의 값
 * @param {string} prop - 객체 속성의 이름
 * @param {number} csvIdx - csv 상에서 문제가 되는 객체의 라인넘버수
 * @param {array} firstPageArr - 검사를 순회하면서 문제 없는 경우 배열에 넣은 firstPage 가 들어간 배열
 * @param {array} lastPageArr - 검사를 순회하면서 문제 없는 경우 배열에 넣은 lastPageArr 가 들어간 배열
 */
const pagesRuleCheck = (val, prop, csvIdx, firstPageArr, lastPageArr) => {
	if (prop === chapterConst.firstPage) { // firstPage Rule Check
		if (firstPageArr.length === 0) { // 처음 입력하는 개시 페이지일 때
			firstPageArr.push(val); // 개시 페이지 배열에 넣음
		} else { /// 처음 입력하는 개시페이지 가 아닌 경우
			const lastPage = lastPageArr[lastPageArr.length-1]; // 이전 ROW 의 종료 페이지를 가져옴
			if (val <= lastPage) { // 개시 페이지는 이전 ROW 의 종료 페이지보다 높은 수여야 한다. 그렇지 않으면
				throw new Error(makeErrorMsg('gt', csvIdx, chapterPropEn[prop], lastPage)); // 에러
			} else {
				firstPageArr.push(val); // 개시 페이지가 종료 페이지보다 높은 경우
			}
		}
	}
	if (prop === chapterConst.lastPage) { // lastPage Rule Check
		const firstPage = firstPageArr[firstPageArr.length-1]; // 개시 페이지 값을 가져온다.
		if (val <= firstPage) { // 개시 페이지가 종료 페이지 보다 높은 경우 5페이지 시작 4페이지 종료는 말이 안되므로 에러
			throw new Error(makeErrorMsg('gt', csvIdx, chapterPropEn[prop], firstPage));
		} else {
			lastPageArr.push(val);// 개시페이지 5, 종료페이지 10 처럼 정상적인 경우
		}
	}
};

/**
 * userInputData 의 각 객체의 각 속성을 순회하면서 Error 검사를 하는 함수
 * @param {object[]} userInputData - csv chapter 객체 들의 배열
 * @param {number} commentLength - 주석의 길이
 * @param {number} totalPage - 전체 페이지 수
 */
export const csvErrorCheck = (importFormData, commentLength, totalPage) => {
	let firstPageArr = [], lastPageArr = [];
	for (const row of importFormData) {
		const csvIdx = getNumberLine(importFormData, row, commentLength);
		const chapterCheckProps = [chapterConst.firstPage, chapterConst.lastPage];
		onlyNumberCheck(row, csvIdx, chapterCheckProps, chapterPropEn); // 始め와 終わり가 숫자인지 체크
		for (const [prop, val] of Object.entries(row)) {
			nameTooLongCheck(val, prop, csvIdx);                   // name 너무 긴거 아닌제 체크
			pageOverTotalCheck(val, prop, csvIdx, totalPage);     // 始め와 終わり 입력된 페이지 확인
			pagesRuleCheck(val, prop, csvIdx, firstPageArr, lastPageArr); // 始め와 終わり 규칙 체크
		}
	}
};

/**
 * 개시가 1 종료가 4면 [1,2,3,4] 페이지 배열 정보를 만들어서 반환해주는 함수
 * @param {object} obj - {idx: number, name: string, firstPage: number, lastPage: number}
 * @returns {Array} - obj.firstPage 가 1이고 obj.lastPage 가 3이면 배열 [1, 2, 3]인 배열
 */
const chapterPagesMake = (obj) => {
	const pages = [];
	for (let idx = obj.firstPage; idx < obj.lastPage + 1; idx++) {
		pages.push(idx);
	}
	return pages;
};

/**
 * userInputData 을 map 하면서 각 객체에서 pages 배열을 추출해서 { name: string, pages: []: number } 형태의
 * 객체들의 배열을 반환하는 함수
 * @param {array} userInputData - {idx: number, name: string, firstPage: number, lastPage: number} 객체들의 배열
 * @returns {array} - 리덕스에 반영할 chapter 배열 정보
 */
export const csvParseChapterMeta = (userInputData) => {
	return userInputData.map(obj => {
		const pages = chapterPagesMake(obj);
		return {
			name: obj.name + "",
			pages
		}
	});
};