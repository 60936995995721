import {objectModeConst} from "../../interface/constant";

const SET_SELECT_ITEM = "SET_ITEM";
const UNSET_SELECT_ITEM = "UNSET_ITEM";
const SET_CONTENTS = "SET_CONTENTS";
const CHANGE_MODAL_STATE = "CHANGE_MODAL_STATE";
const CLOSE_MODAL = "CLOSE_MODAL";
const SET_ZOOM = "SET_ZOOM";
const CHANGE_EDITOR_MODE = "CHANGE_EDITOR_MODE";
const SET_PAGES = "SET_PAGES";
const SET_BOOK_CHANGED = "SET_BOOK_CHANGED";
const SET_BOOK_SAVED = "SET_BOOK_SAVED";
const CHANGE_OBJECT_MODE = "CHANGE_OBJECT_MODE";
const BOOK_READY = "BOOK_READY";
const BOOK_NOT_READY = "BOOK_NOT_READY";
const START_LOADING = "START_LOADING";
const END_LOADING = "END_LOADING";
const CHANGE_PAGE_VIEW_TYPE = "CHANGE_PAGE_VIEW_TYPE";
const CSV_IMPORTING = "CSV_IMPORTING";
const CHANGE_IS_IMAGE_BG = "CHANGE_IS_IMAGE_BG";
const CHANGE_IS_TEXT_MODE = "CHANGE_IS_TEXT_MODE";
const SET_SELECT_TEXT_ID = "SET_SELECT_TEXT_ID";
const SET_SELECT_SUB_BLOCK_ID = "SET_SELECT_SUB_BLOCK_ID";
const UNSET_SELECT_SUB_BLOCK_ID = "UNSET_SELECT_SUB_BLOCK_ID";
const TOGGLE_OBJ_ID = "TOGGLE_OJB_ID";
const TOGGLE_BG = "TOGGLE_BG";
const SET_SELECT_POPUP_TARGET = "SET_SELECT_POPUP_TARGET";
const UNSET_SELECT_POPUP_TARGET = "UNSET_SELECT_POPUP_TARGET";
const ADD_LOG = "ADD_LOG";
const ADD_ADMIN_LOG  = "ADD_ADMIN_LOG";
const REMOVE_LOG = "REMOVE_LOG";

//Reducer
const defaultState = {
	contents: "home", // ["login"|"editor"|"setting"|"account"|"import"|"home"]
	loading: {
		isLoading: false,
		msg: "",
	},
	modal: {
		isOpen: false,
		style: "", // ["alert"|"warning"|"error"],
		title: "",
		msg: "",
	},
	editor: {
		mode: "edit", // ["edit"|"draw"|"linkPages"|"group"]
		scale: 1,
		objectMode: objectModeConst.subView,
		pageViewType: "all",	// ["all"|"left"|"right"]
		isImageBg: false,
		isTextMode: false,
		isIdOn: false,
		isBgOn: true,
		selected: {
			id: "",
			page: 0,
			textId: "",
		},
		pages: {
			left: 0,
			right: 0,
		},
		groupTarget: "",
	},
	book: {
		changed: 0,
		ready: 0,
		saved: 0,
	},
	log: [],
	adminLog: [],
	csvImport: false,
};

export default function reducer(state = defaultState, action = {}) {
	switch (action.type) {
		case SET_SELECT_ITEM: {
			let {id, page} = action;
			return {
				...state,
				editor : {
					...state.editor,
					selected: {id, page}
				},
			};
		}
		case UNSET_SELECT_ITEM: {
			return {
				...state,
				editor: {
					...state.editor,
					selected: {
						id: "",
						page: 0,
					},
				}
			};
		}
		case CHANGE_MODAL_STATE: {
			return {
				...state,
				modal: {
					isOpen: action.isOpen,
					style: action.style,
					title: action.title,
					msg: action.msg,
				}
			};
		}
		case CLOSE_MODAL: {
			return {
				...state,
				modal: defaultState.modal
			}
		}
		case SET_CONTENTS: {
			return {
				...state,
				contents: action.contents,
			}
		}
		case SET_ZOOM: {
			return {
				...state,
				editor: {
					...state.editor,
					scale: action.scale,
				}
			}
		}
		case CHANGE_EDITOR_MODE: {
			return {
				...state,
				editor: {
					...state.editor,
					mode: action.mode,
				}
			}
		}
		case SET_PAGES: {
			return {
				...state,
				editor: {
					...state.editor,
					pages: {
						left: action.pages[0],
						right: action.pages[1]
					}
				},
			}
		}
		case CHANGE_OBJECT_MODE: {
			return {
				...state,
				editor: {
					...state.editor,
					objectMode: action.mode,
				}
			}
		}
		case SET_BOOK_CHANGED: {
			return {
				...state,
				book: {
					...state.book,
					changed: true,
				}
			}
		}
		case SET_BOOK_SAVED: {
			return {
				...state,
				book: {
					...state.book,
					changed: false,
					saved: state.book.saved + 1,
				}
			}
		}
		case BOOK_READY: {
			return {
				...state,
				contents: "editor",
				book: {
					...state.book,
					ready: state.book.ready + 1,
				}
			}
		}
		case BOOK_NOT_READY: {
			return {
				...state,
				contents: "home",
				book: {
					...state.book,
					ready: 0,
				}
			}
		}
		case START_LOADING: {
			return {
				...state,
				loading: {
					isLoading: true,
					msg: action.msg,
				},
			}
		}
		case END_LOADING: {
			return {
				...state,
				loading: {
					isLoading: false,
					msg: "",
				},
			}
		}
		case CHANGE_PAGE_VIEW_TYPE: {
			return {
				...state,
				editor: {
					...state.editor,
					pageViewType: action.pageViewType
				},
			}
		}
		case CSV_IMPORTING: {
			return {
				...state,
				csvImport: action.csvImport
			}
		}
		case CHANGE_IS_IMAGE_BG: {
			return {
				...state,
				editor: {
					...state.editor,
					isImageBg: !state.editor.isImageBg
				},
			}
		}
		case CHANGE_IS_TEXT_MODE: {
			return {
				...state,
				editor: {
					...state.editor,
					isTextMode: !state.editor.isTextMode
				},
			}
		}
		case SET_SELECT_TEXT_ID: {
			return {
				...state,
				editor : {
					...state.editor,
					selected: {
						...state.editor.selected,
						textId: action.textId,
					}
				},
			}
		}
		case SET_SELECT_SUB_BLOCK_ID: {
			return {
				...state,
				editor: {
					...state.editor,
					selected: {
						...state.editor.selected,
						blockId: action.blockId,
					}
				}
			}
		}
		case UNSET_SELECT_SUB_BLOCK_ID: {
			return {
				...state,
				editor: {
					...state.editor,
					selected: {
						...state.editor.selected,
						blockId: "",
					},
				}
			}
		}
		case TOGGLE_OBJ_ID: {
			return {
				...state,
				editor: {
					...state.editor,
					isIdOn: !state.editor.isIdOn,
				}
			}
		}
		case TOGGLE_BG: {
			return {
				...state,
				editor: {
					...state.editor,
					isBgOn: !state.editor.isBgOn,
				}
			}
		}
		case SET_SELECT_POPUP_TARGET: {
			return {
				...state,
				editor: {
					...state.editor,
					selected: {
						...state.editor.selected,
						popupId: action.popupId,
					}
				}
			}
		}
		case UNSET_SELECT_POPUP_TARGET: {
			return {
				...state,
				editor: {
					...state.editor,
					selected: {
						...state.editor.selected,
						popupId: "",
					}
				}
			}
		}
		case ADD_LOG: {
			let log = state.log;
			log.push(action.msg);
			return {
				...state,
				log: log,
			}
		}
		case ADD_ADMIN_LOG: {
			let log = state.adminLog;
			log.push(action.msg);
			return {
				...state,
				adminLog: log,
			}
		}
		case REMOVE_LOG: {
			return {
				...state,
				log: [],
			}
		}
		default: {
			return {...state};
		}
	}
}

//Action Generators

export function unsetSelectItem(state) {
	return {
		type: UNSET_SELECT_ITEM
	}
}

export function setSelectItem(state) {
	return {
		type: SET_SELECT_ITEM,
		id: state.id,
		page: state.page,
	}
}

export function closeModal() {
	return {
		type: CLOSE_MODAL,
	}
}

export function changeModalState(state) {
	return {
		type: CHANGE_MODAL_STATE,
		isOpen: state.isOpen,
		style: state.style,
		title: state.title,
		msg: state.msg,
	}
}

export function setContents(state) {
	return {
		type: SET_CONTENTS,
		contents: state.contents,
	}
}

export function setZoom(state) {
	return {
		type: SET_ZOOM,
		scale: state.scale
	}
}

export function changeEditorMode(state) {
	return {
		type: CHANGE_EDITOR_MODE,
		mode: state.mode,
	}
}

export function setPages(state) {
	return {
		type: SET_PAGES,
		pages: state.pages,
	}
}

export function setBookChanged() {
	return {type: SET_BOOK_CHANGED}
}

export function setBookSaved() {
	return {type:SET_BOOK_SAVED}
}

export function changeObjectMode(state) {
	return {
		type: CHANGE_OBJECT_MODE,
		mode: state.mode,
	}
}

export function bookReady() {
	return {type: BOOK_READY}
}

export function bookNotReady() {
	return {type:BOOK_NOT_READY}
}

export function startLoading(state) {
	return {
		type: START_LOADING,
		msg: state.msg,
	}
}

export function endLoading() {
	return {type: END_LOADING}
}

export function changePageViewType(state) {
	return {
		type: CHANGE_PAGE_VIEW_TYPE,
		pageViewType: state.pageViewType,
	}
}

export function csvImporting(state) {
	return {
		type: CSV_IMPORTING,
		csvImport: state
	}
}

export function changeIsImageBg() {
	return {
		type: CHANGE_IS_IMAGE_BG,
	}
}

export function changeIsTextMode() {
	return {
		type: CHANGE_IS_TEXT_MODE,
	}
}

export function setSelectedTextId(state) {
	return {
		type: SET_SELECT_TEXT_ID,
		textId: state.textId,
	}
}

export function setSelectSubBlockId(state) {
	return {
		type: SET_SELECT_SUB_BLOCK_ID,
		blockId: state.blockId,
	}
}

export function unsetSelectSubBlockId() {
	return {
		type: SET_SELECT_SUB_BLOCK_ID,
	}
}

export function toggleObjectIdShow() {
	return {
		type: TOGGLE_OBJ_ID,
	}
}

export function toggleBgShow() {
	return {
		type: TOGGLE_BG,
	}
}

export function setSelectPopupTarget(state) {
	return {
		type: SET_SELECT_POPUP_TARGET,
		popupId: state.popupId,
	}
}

export function unsetSelectPopupTarget() {
	return {
		type: UNSET_SELECT_POPUP_TARGET,
	}
}

export function addLog(state) {
	return {
		type: ADD_LOG,
		msg: state.msg,
	}
}

export function addAdminLog(state) {
	return {
		type: ADD_ADMIN_LOG,
		msg: state.msg,
	}
}

export function removeLog() {
	return {
		type: REMOVE_LOG,
	}
}