import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";

import {
	TextProperty,
	SelectProperty,
	NumberProperty,
	FileProperty,
	CheckBoxProperty,
	SelectImageProperty,
} from "../../../../components/Contents/Editor/Panels/PropertyPanel/innerProperty";
import PosSizeProperty from "../../../../components/Contents/Editor/Panels/PropertyPanel/posSizeProperty";
import {
	changeBookData,
	changeRectId,
	changeRectPage,
	changeRectOptions,
	changeRectData,
	changeRectSVG,
	changeRectThumbKey,
	changeRectEditor,
	changeRectEditorIgnore,
} from '../../../../reducers/CombineReducers/pageData';
import {
	objectModeConst,
	propertyInputLabel,
	propertyInputName,
	propertyToolTip,
	videoIconOptions,
	defaultVideoIcon,
	subjectKeyName,
	editorOptions, modalMsg, modalTitle,
} from '../../../../interface/constant';
import {togglePanel} from "../../../../reducers/CombineReducers/panel";
import { setSelectItem, changeEditorMode } from "../../../../reducers/CombineReducers/current";
import { modifyRepoPath } from "../../../../reducers/CombineReducers/repo";
import { checkNested } from "../../../../interface/utils";
import SvgProperty from "../../../../components/Contents/Editor/Panels/PropertyPanel/SvgProperty";
import {fileUpload} from "../../../../components/Common/Upload";
import Modal from "../../../../components/Common/Modal";

const mapStateToProps = (state) => {
	const objMode = objectModeConst[state.current.editor.objectMode];
	let prop = {
		objMode,
		pages: state.current.editor.pages,
		mode: state.current.editor.mode,
		view: state.metaData.view,
		bookId: state.metaData.bookId,
		panel: state.panel,
		direction: state.metaData.direction,
		subject: state.metaData.subject,
		repo: state.repo,
	};
	let {id, page} = state.current.editor.selected;
	if (id && page) {
		let objectInfo = state.pageData[page].objects[objMode];
		prop.item =objectInfo.filter((item) => item.originId === id)[0];
		prop.id = id;
		prop.page = page;
	}
	return prop;
};

const mapDispatchToProps = {
	changeBookData,
	changeRectId,
	changeRectPage,
	changeRectOptions,
	changeRectData,
	setSelectItem,
	modifyRepoPath,
	changeRectSVG,
	togglePanel,
	changeEditorMode,
	changeRectThumbKey,
	changeRectEditor,
	changeRectEditorIgnore,
};

let property = {
	currentId: "",
	changedId: "",
	page: 0,
	objMode: "",
	item: {}
};

class VideoLinkTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: "",
			zIndex: 0,
			propertyCss: {
				width: "",
				height: "",
				top: "",
				left: "",
			},
			type: 0,
			editorUse: 0,
			showModalError: false,
		}
		this.errorProcess = null;
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps === prevState.prevProps) return null;
		const propertyCss = VideoLinkTable.arrangeCss(prevState.propertyCss, nextProps.item.css);
		const svg = checkNested(nextProps, "item.svg.attr.points") ? nextProps.item.svg.attr.points : "";
		return {propertyCss, svg}
	}

	static arrangeCss(prevCss, nextCss) {
		return {
			...nextCss,
			width: prevCss.width === "" ? prevCss.width : nextCss.width,
			height: prevCss.height === "" ? prevCss.height : nextCss.height,
			top: prevCss.top === "" ? prevCss.top : nextCss.top,
			left: prevCss.left === "" ? prevCss.left : nextCss.left,
		}
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.props.item.id !== prevProps.item.id) {
			const {item} = this.props;
			const id = this.props.item.id || "";
			const propertyCss = this.props.item.css;
			const zIndex = checkNested(item, "options.link.zIndex") ? item.options.link.zIndex : 0;
			const type = this.iconTypeValue(this.props.item);

			let editorUse = 1;
			if (item.editor) {
				editorUse = 0;
				if (item.data && item.data.class) {
					editorUse = item.data.class.includes("editor-only") ? 2 : 0;
				}
			}

			this.setState({id, zIndex, propertyCss, type, editorUse});
		}
	}

	eventHandler() {
		return {
			onChange: (e) => {
				this.setState({
					...this.state,
					propertyCss: {
						...this.state.propertyCss,
						[e.target.name]: e.target.value,
					}
				});

				const value = e.target.value || 0;
				this.props.changeBookData({
					id: this.props.id,
					page: this.props.page,
					css: {
						[e.target.name]: (e.target.name !== 'opacity' ? parseInt(value, 10) : Number(value))
					},
					objMode: this.props.objMode
				});
			},
			pageChange: (e) => {
				let page = Number(e.target.value);
				this.props.changeRectPage({
					id: this.props.id,
					page: page,
					width: this.props.view.width / 2,
					objMode: this.props.objMode,
					direction: this.props.direction,
				});
				this.props.setSelectItem({
					id: this.props.id,
					page: page
				});
			},
			optionsChange: (e) => {
				let {page, objMode, item} = property;
				const itemOptions = Object.keys(this.props.item).length !== 0 ? this.props.item.options : item.options;
				let options = this.props.setOptionsValue(e.target, itemOptions);
				this.props.changeRectOptions({
					id: this.props.id,
					page: this.props.page || page,
					options: {
						[options.name]: options.value
					},
					objMode: this.props.objMode || objMode,
				});
			},
			dataChange: (e) => {
				let data = e.target.value;
				this.props.changeRectData({
					id: this.props.id,
					page: this.props.page,
					data: {
						[e.target.name]: data
					},
					objMode: this.props.objMode,
				});
			},
			thumbKeyChange: (e) => {
				this.props.changeRectThumbKey({
					id: this.props.id,
					page: this.props.page,
					objMode: this.props.objMode,
					thumbKey: e.target.value,
				})
			},
			editorUseChange: (e) => {
				const value = Number(e.target.value);
				this.setState({
					...this.state,
					editorUse: value,
				});
				this.props.changeRectEditor({
					id: this.props.id,
					page: this.props.page,
					editorUse: value,
					objMode: this.props.objMode
				});
			},
			useIconChange: (e) => {
				const editorOnly = this.state.editorUse === 2 ? "editor-only " : "";
				let data = e.target.checked ? "icon " + defaultVideoIcon.className : "";
				this.props.changeRectData({
					id: this.props.id,
					page: this.props.page,
					data: {
						[e.target.name]: editorOnly + data
					},
					objMode: this.props.objMode,
				});

				if (e.target.checked) {
					this.setState({
						...this.state,
						type: defaultVideoIcon.val,
					});
					this.props.changeBookData({
						id: this.props.id,
						page: this.props.page,
						css: {
							width: defaultVideoIcon.width,
							height: defaultVideoIcon.height,
						},
						objMode: this.props.objMode
					});
				}
			},
		}
	}

	setPropertyId(e) {
		property.currentId = this.props.id;
		property.changedId = e.target.value;
		property.page = this.props.page;
		property.objMode = this.props.objMode;
		property.item = this.props.item;
	}

	changeId(e) {
		this.setState({
			id: e.target.value
		});
		property.changedId = e.target.value;
	}

	changePageDataId() {
		let {currentId, changedId, page, objMode} = property;
		this.props.changeRectId({
			currentId: currentId,
			changedId: changedId,
			page: page,
			objMode: objMode
		});
	}

	async videoUpload(e) {
		const result = await fileUpload(e, this.props.modifyRepoPath, this.props.bookId, "video", this.props.repo);
		if (!result || !result.fileUpload) {
			this.showErrorModal();
			return;
		}
		this.updateAfterUpload(result.data.key, result.data.path, result.data.originalname, "fileKey", "video");
	}

	async subtitleUpload(e, keyName = "subtitleKey") {
		const result = await fileUpload(e, this.props.modifyRepoPath, this.props.bookId, "srt", this.props.repo);
		if (!result || !result.fileUpload) {
			this.showErrorModal();
			return;
		}
		await this.updateAfterUpload(result.data.key, result.data.path, result.data.originalname,keyName, "srt");
	}

	async updateAfterUpload(key, path, originalname, dataKey, kind) {
		this.props.changeRectData({
			id: this.props.id,
			page: this.props.page,
			objMode: this.props.objMode,
			data: {
				[dataKey]: key
			}
		});
	}

	showErrorModal() {
		this.errorProcess = (e) => {
			e.stopPropagation();
			this.toggleModal("Error", false);
			this.errorProcess = () => {};
		};
		this.toggleModal("Error", true);
	}

	toggleModal(type = "Error", show = true) {
		let modalType = `showModal${type}`;
		this.setState({
			...this.state,
			[modalType]: show,
		})
	}

	setLabel(icon) {
		if(icon.value === 0) {
			return (
				<div className={"no-icon"}>
					{icon.name}
				</div>
			);
		}
		return (
			<div>
				<div className={"icon " + icon.className}/>
			</div>
		)
	}


	iconTypeElem() {
		const {item} = this.props;
		const useIconChecked = item.data.class ? item.data.class.includes("icon") || false : false;
		if (useIconChecked) {

			let iconType = videoIconOptions.filter((item) => item.value !== 0);
			iconType.map((icon) => {
				icon.label = this.setLabel(icon);
				return icon;
			});

			const iconValue = iconType.filter((icon) => icon.value === this.state.type);

			return (
				<SelectImageProperty
					label={propertyInputLabel.iconType}
					name={propertyInputName.iconType}
					options={iconType}
					val={iconValue}
					eventHandler={this.iconChange}
				/>
			)
		}
	}

	iconTypeValue(item) {
		if (item.data && item.data.class) {
			for (let iconType of videoIconOptions) {
				const regExp = `${iconType.className}$`; // 문자열 끝에 iconType.className 이 들어가는지 체크하는 정규식
				if (item.data.class.match(new RegExp(regExp))) {
					return iconType.value;
				}
			}
		}
		return 0;
	}

	changeRectData(data) {
		this.props.changeRectData({
			id: this.props.id,
			page: this.props.page,
			objMode: this.props.objMode,
			data,
		})
	}

	iconChange = (icon) => {
		console.log(icon);
		let type = Number(icon.value);
		if (type === 0) return;
		this.setState({
			...this.state,
			type: type
		});
		let idx = videoIconOptions.findIndex((item) => item.value === type);

		const editorOnly = this.state.editorUse === 2 ? "editor-only " : "";

		let className = "icon " + videoIconOptions[idx].className;
		this.changeRectData({
			...this.props.item.data,
			class: editorOnly + className,
		});
		this.props.changeBookData({
			id: this.props.id,
			page: this.props.page,
			css: {
				width: videoIconOptions[idx].width,
				height: videoIconOptions[idx].height,
			},
			objMode: this.props.objMode
		});
	};


	kakezuCheck(e) {
		let data = this.props.item.data;
		if (e.target.checked) {
			data.kakezu = "";
		} else {
			delete data.kakezu;
		}
		this.changeRectData({...data});
	}

	kakezuElem() {
		const item = this.props.item;
		if (item.data && item.data.hasOwnProperty("kakezu")) {
			return (
				<NumberProperty
					label={propertyInputLabel.kakezu}
					name={propertyInputName.kakezu}
					val={item.data.kakezu || ""}
					onChange={this.eventHandler().dataChange}
				/>
			)
		}
	}

	engSubtitleElem() {
		const item = this.props.item;
		return (
			<>
				<TextProperty
					label={propertyInputLabel.subtitleEng}
					name={propertyInputName.subtitleEng}
					val={item.data ? (item.data.subtitleEngKey || "") : ""}
					onChange={this.eventHandler().dataChange}
				/>
				<FileProperty
					label={""}
					htmlFor={"uploadSubtitleEng"}
					btnName={"アップロード"}
					accept={".srt"}
					dataId={this.props.id}
					bookId={this.props.bookId}
					onChange={(e) => this.subtitleUpload(e, "subtitleEngKey")}
				/>
			</>
		)
	}

	render() {
		const {id, page, item, pages, subject} = this.props;
		const myEditorOptions = this.props.id ? editorOptions : [];

		const pageInfoOptions = this.props.pages ? [
			{val: pages.left, name: pages.left},
			{val: pages.right, name: pages.right}
		] : [];

		const useIconChecked = item.data.class ? item.data.class.includes("icon") || false : false;
		return (
			<div className="inner-table">
				<table>
					<tbody>
					<TextProperty
						label={propertyInputLabel.id}
						name={propertyInputName.id} val={this.state.id}
						readOnly={false}
						onFocus={this.setPropertyId.bind(this)}
						onBlur={this.changePageDataId.bind(this)}
						onChange={this.changeId.bind(this)}
						tooltipText={propertyToolTip.id}
					/>
					<SelectProperty
						label={propertyInputLabel.page}
						name={propertyInputName.page} options={pageInfoOptions}
						eventHandler={this.eventHandler().pageChange} val={page}
						tooltipText={propertyToolTip.page}
					/>
					<NumberProperty
						label={propertyInputLabel.zIndex}
						name={propertyInputName.zIndex}
						val={item.options.link ? item.options.link.zIndex || "" : ""}
						onChange={this.eventHandler().optionsChange}
						tooltipText={propertyToolTip.zIndex}
					/>

					<PosSizeProperty
						css={this.state.propertyCss}
						eventHandler={this.eventHandler()}
					/>

					<TextProperty
						label={propertyInputLabel.tooltip}
						name={propertyInputName.tooltip}
						val={item.data ? (item.data.tooltip || "") : ""}
						onChange={this.eventHandler().dataChange}
					/>
					<TextProperty
						label={propertyInputLabel.subtitle}
						name={propertyInputName.subtitle}
						val={item.data ? (item.data.subtitleKey || "") : ""}
						onChange={this.eventHandler().dataChange}
					/>
					<FileProperty
						label={""}
						htmlFor={"uploadSubtitle"}
						btnName={"アップロード"}
						accept={".srt"}
						dataId={this.props.id}
						bookId={this.props.bookId}
						onChange={this.subtitleUpload.bind(this)}
					/>
					{subject === subjectKeyName.eigo ? this.engSubtitleElem() : ""}
					<NumberProperty
						label={propertyInputLabel.startTime}
						name={propertyInputName.startTime}
						val={item.data ? (item.data.startTime || "") : ""}
						step={"0.1"}
						onChange={this.eventHandler().dataChange}
					/>
					<NumberProperty
						label={propertyInputLabel.endTime}
						name={propertyInputName.endTime}
						val={item.data ? (item.data.endTime || "") : ""}
						step={"0.1"}
						onChange={this.eventHandler().dataChange}
					/>
					<TextProperty
						label={propertyInputLabel.file}
						name={propertyInputName.file}
						val={item.data ? (item.data.fileKey || "") : ""}
						readOnly={false}
						onChange={this.eventHandler().dataChange}
					/>
					<FileProperty
						label={""}
						htmlFor={"uploadVideoFile"}
						btnName={"アップロード"}
						accept={".mp4"}
						dataId={this.props.id}
						bookId={this.props.bookId}
						onChange={this.videoUpload.bind(this)}
					/>
					<TextProperty
						label={propertyInputLabel.thumbKey}
						name={propertyInputName.thumbKey}
						val={item.thumbKey || ""}
						onChange={this.eventHandler().thumbKeyChange}
						tooltipText={propertyToolTip.thumbKey}
					/>
					<SelectProperty
						label={propertyInputLabel.editor}
						name={propertyInputName.editor}
						options={myEditorOptions}
						val={this.state.editorUse}
						eventHandler={this.eventHandler().editorUseChange}
						tooltipText={propertyToolTip.editor}
					/>
					<CheckBoxProperty
						label={propertyInputLabel.useIcon}
						name={propertyInputName.useIcon} suffix={""}
						checked={useIconChecked}
						eventHandler={this.eventHandler().useIconChange}
					/>
					{this.iconTypeElem()}

					<CheckBoxProperty
						label={propertyInputLabel.kakezuCheck}
						name={propertyInputName.kakezuCheck} suffix={""}
						checked={item.data && item.data.hasOwnProperty("kakezu")}
						eventHandler={this.kakezuCheck.bind(this)}
						tooltipText={propertyToolTip.kakezuCheck}
					/>
					{this.kakezuElem()}

					<SvgProperty
						changeRectSVG={this.props.changeRectSVG}
						changeEditorMode={this.props.changeEditorMode}
						togglePanel={this.props.togglePanel}
						item={item}
						id={this.props.id}
						page={this.props.page}
						objMode={this.props.objMode}
						panel={this.props.panel}
						mode={this.props.mode}
						svg={this.state.svg}
					/>
					</tbody>
				</table>
				<div className="inner-mask" style={{display: id ? "none" : ""}}/>
				{this.state.showModalError && <Modal
					onClickConfirm={(e) => this.errorProcess(e)}
					msg={modalMsg.IMAGE_EXIST}
					title={modalTitle.ERROR}
				/>}
			</div>
		)
	}
}

VideoLinkTable.defaultProps = {
	item: {
		originId: "",
		id: "",
		css: {},
		options: {},
		page: 0,
		data: {},
	},
	page: 0,
	id: ""
};

VideoLinkTable.propTypes = {
	item: PropTypes.shape({
		originId: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		css: PropTypes.object.isRequired,
		options: PropTypes.object.isRequired,
		data: PropTypes.object.isRequired,
		page: PropTypes.number.isRequired
	}).isRequired,
	page: PropTypes.number.isRequired,
	id: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoLinkTable);