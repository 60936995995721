import React from 'react'

const CheckSheetItem = (props) => {
	return (
		<div className={"inner-list"}>
			<div className="check-sheet-area">
				<div className={"check-sheet-item"}>
					<div
						className={'check-item icon_Check2'}
						name="check2"
						onClick={props.onClick}
					/>
				</div>
			</div>
		</div>
	)
};
export default CheckSheetItem
