import React, {Component} from 'react';
import {connect} from "react-redux";
import {SubPopupTable} from "../../../components/Contents/Editor/Panels/SubviewPanel/innerSubview";
import {objectModeConst, subViewRect} from "../../../interface/constant";
import {changeRectData, changeRectDataSvg} from "../../../reducers/CombineReducers/pageData";
import {togglePanel} from "../../../reducers/CombineReducers/panel";
import {modifyRepoPath} from "../../../reducers/CombineReducers/repo";
import {randomStr} from "../../../interface/utils";
import {fileUpload} from "../../../components/Common/Upload";

const mapStateToProps = state => {
	const {id, page, blockId} = state.current.editor.selected;
	const {editor} = state.current;
	const objMode = objectModeConst[editor.objectMode];
	let props = {};
	if (id && page) {
		let objectList = state.pageData[page].objects[objMode];
		const idx = objectList.findIndex((item) => item.originId === id);
		const subViewSettings = editor.objectMode === "subView" ? objectList[idx].data.subViewSettings : null;
		props = {
			selectedId: id,
			selectedPage: page,
			bookId: state.metaData.bookId,
			subViewSettings,
			idx,
			repo: state.repo,
			view: state.metaData.view,
			selectedBlockId: blockId
		}
	}

	return props;
};

const mapDispatchToProps = {
    changeRectData,
    changeRectDataSvg,
    togglePanel,
	modifyRepoPath,
};

const ADD_OFFSET = 10;
let subViewLinkOffsetX = ADD_OFFSET;
let subViewLinkOffsetY = ADD_OFFSET;

class SubPopup extends Component {
	subViewSettingEvent() {
		return {
            addLink: () => {
                const {subViewSettings} = this.props;
                let subPopup = subViewSettings.options.subPopup || [];
                const defaultBlock = {
                    id: randomStr(8),
                    rectId: "",
                    width: 100,
                    height: 100,
                    left: subViewLinkOffsetX,
                    top: subViewLinkOffsetY
                };

				if(subViewLinkOffsetY < 580){
					subViewLinkOffsetX += ADD_OFFSET;
					subViewLinkOffsetY += ADD_OFFSET;
				}else{
					subViewLinkOffsetX = subViewLinkOffsetX-560;
					subViewLinkOffsetY = subViewLinkOffsetY-580;
				}

                const data = {
                    subViewSettings: {
                        ...subViewSettings,
                        options: {
                            ...subViewSettings.options,
                            subPopup: subPopup.concat(defaultBlock),
                        },
                    }
                };

                this.props.changeRectData({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    data: data,
                    objMode: "subView",
                });
            },
			update: (e, idx) => {
                let subPopup = this.props.subViewSettings.options.subPopup;
                subPopup[idx][e.target.name] = e.target.value;

                const data = {
                    subViewSettings: {
                        ...this.props.subViewSettings,
                        options: {
                            ...this.props.subViewSettings.options,
                            subPopup: subPopup,
                        },
                    }
                };

				this.props.changeRectData({
					id: this.props.selectedId,
					page: this.props.selectedPage,
					data: data,
					objMode: "subView",
				});
			}
		}
	}

	async imageUpload(e, idx) {
		const result = await fileUpload(e, this.props.modifyRepoPath, this.props.bookId, "popup", this.props.repo);
		if (!result || !result.fileUpload) {
			this.props.showErrorModal();
			return;
		}

        let subPopup = this.props.subViewSettings.options.subPopup || [];
		if (subPopup.length === 0) return;
        subPopup[idx].popupKey = result.data.key;

        const data = {
            subViewSettings: {
                ...this.props.subViewSettings,
                options: {
                    ...this.props.subViewSettings.options,
                    subPopup: subPopup,
                },
            }
        };

		this.props.changeRectData({
			id: this.props.selectedId,
			page: this.props.selectedPage,
			data: data,
			objMode: "subView",
		});
	}

	render() {
		const {subViewSettings, selectedBlockId} = this.props;
		const subPopup = subViewSettings.options.subPopup;
		const subViewLinkIdx = subPopup ? subPopup.findIndex((item) => item.id === selectedBlockId) : -1;
		let menuHide = "hide", data = {};

		if (subViewLinkIdx > -1) {
			data = subPopup[subViewLinkIdx];
			menuHide = "";
		}

        const svgData = {
            changeRectDataSVG: this.props.changeRectDataSvg,
            togglePanel: this.props.togglePanel,
            item: subViewLinkIdx >= 0 ? subPopup[subViewLinkIdx] : {},
            panel: this.props.panel,
            id: this.props.selectedId,
            page: this.props.selectedPage,
            blockId: selectedBlockId,
            objMode: "subview",
            mode: this.props.mode,
            svg: subViewLinkIdx >= 0 && subPopup[subViewLinkIdx].svg ? subPopup[subViewLinkIdx].svg.attr.points : "",
            subViewType: subViewRect.subPopup
        };

		return (
			<SubPopupTable
				event={this.subViewSettingEvent()}
				data={data}
				imageUpload={this.imageUpload.bind(this)}
				bookId={this.props.bookId}
				svgData={svgData}
				menuHide={menuHide}
				subViewLinkIdx={subViewLinkIdx}
			/>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SubPopup);