import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import { Rnd } from 'react-rnd';

import {
	changeBookDataKey,
	changeBookData,
	removeRect,
	copyRect,
	pasteRect,
	answerCopyPaste,
	cutRect,
	changeRectEditorText,
	changeRectEditorTextCss,
	changeRectData,
	undo
} from '../../../../reducers/CombineReducers/pageData';
import { setSelectItem, unsetSelectItem, setSelectedTextId } from '../../../../reducers/CombineReducers/current';
import defaultPageImagePath from "../../../../images/no-image.jpg";

import Rect from '../../../../components/Contents/Editor/Panels/EditorPanel/Rect';
import {contents, keyAction, keyModel, modalMsg, modalTitle, subjectKeyName} from "../../../../interface/constant";
import { objectModeConst } from '../../../../interface/constant';
import Modal from "../../../../components/Common/Modal";

const {ORDER_CHANGE, ORDER_CANCEL, ORDER_COPY, ORDER_CUT, ORDER_DELETE, ORDER_PASTE, ORDER_PASTE_ANSWER_ICON, ORDER_UNDO} = keyAction;

const mapStateToProps = (state) => {
	const {editor, book} = state.current;
	const objMode = objectModeConst[editor.objectMode];
	let item = null;
	if (editor.selected.page && editor.selected.id) {
		let objectInfo = state.pageData[editor.selected.page].objects[objMode];
		item = objectInfo.filter((item) => item.originId === editor.selected.id)[0];
	}
	return {
		item,
		selectedId: editor.selected.id,
		selectedPage: editor.selected.page,
		selectedTextId: editor.selected.textId,
		pages: editor.pages,
		bookReady: book.ready,
		scale: editor.scale,
		leftObj: state.pageData[editor.pages.left].objects[objMode] || [],
		rightObj: state.pageData[editor.pages.right].objects[objMode] || [],
		view: state.metaData.view || {width: 0, height: 0},
		repoPage: state.repo.page,
		repoImage: state.repo.image,
		objectMode: editor.objectMode,
		subject: state.metaData.subject,
		contents: state.current.contents,
		isImageBg: state.current.editor.isImageBg,
		isTextMode: state.current.editor.isTextMode,
		direction: state.metaData.direction,
		objMode
	}
};

const mapDispatchToProps = dispatch => {
	return {
		changeBookData: (state) => dispatch(changeBookData(state)),
		changeBookDataKey: (state) => dispatch(changeBookDataKey(state)),
		setSelectItem: (state) => dispatch(setSelectItem(state)),
		unsetSelectItem: (state) => dispatch(unsetSelectItem(state)),
		removeRect: (state) => dispatch(removeRect(state)),
		copyRect: (state) => dispatch(copyRect(state)),
		pasteRect: (state) => dispatch(pasteRect(state)),
		answerCopyPaste: (state) => dispatch(answerCopyPaste(state)),
		cutRect: (state) => dispatch(cutRect(state)),
		changeRectEditorText: (state) => dispatch(changeRectEditorText(state)),
		changeRectEditorTextCss: (state) => dispatch(changeRectEditorTextCss(state)),
		setSelectedTextId: (state) => dispatch(setSelectedTextId(state)),
		changeRectData: (state) => dispatch(changeRectData(state)),
		undo: (state) => dispatch(undo(state)),
	}
};

export function getObjects(obj, objectMode) {
	switch (objectMode) {
		case "myEditor": {
			return obj.filter(item => item.hasOwnProperty("editor"));
		}
		default: {
			return obj;
		}
	}
}

let keyDownFunc = null;
let keyUpFunc = null;

const deleteTagList = ["span", "p", "pre", "table", "tbody", "tr", "td"];
const textDragSubject = [subjectKeyName.sansu];

class MyEditorLayer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModalDelete: false,
			isResizing: false,
			id: "",
			page: "",
			text: "",
			textDragMode: false,
		};
		this.dragStartData = null;
		this.deleteProcess = null;
		this.rerender = false;
	}

	shouldComponentUpdate(nextProps, nextState) {
		return nextProps.bookReady;
	}

	componentDidMount() {
		keyDownFunc = (e) => this.keyEvent(this.props.selectedPage, this.props.selectedId).onKeyDown(e);
		keyUpFunc = (e) => this.keyEvent(this.props.selectedPage, this.props.selectedId).onKeyUp(e);
		document.addEventListener(
			"keydown",
			keyDownFunc,
			false
		);
		document.addEventListener(
			"keyup",
			keyUpFunc,
			false
		);
	}

	componentWillUnmount() {
		document.removeEventListener(
			"keydown",
			keyDownFunc,
			false
		);
		document.removeEventListener(
			"keyup",
			keyUpFunc,
			false
		);
	}

	// MyEditorLayer 키 이벤트
	keyEvent(page, id) {
		const {objMode, direction} = this.props;
		return {
			onKeyDown: (e) => {
				if (this.props.contents !== contents.editor) return;
				if (e.keyCode >= 112 && e.keyCode <= 123) return;	//「F1～F12」KeyならEventを実行しない
				e.preventDefault();
				if (id && this.props.isTextMode && e.keyCode === 18 && !this.state.textDragMode && textDragSubject.includes(this.props.subject)) {
					// dragMode 시작
					this.setState({
						textDragMode: true,
					})
				}
				let which = e.keyCode + (e.shiftKey ? "+shift" : (e.ctrlKey ? "+ctrl" : (e.altKey ? "+alt" : "")));
				if (!Object.keys(keyModel).includes(which)) return;
				let {order, target, val} = keyModel[which];
				if (order === ORDER_PASTE) {
					this.props.pasteRect({page: this.props.pages.left, objMode, direction});
				} else if (order === ORDER_UNDO) {
					this.rerender = true;
					if (this.props.selectedId) this.props.unsetSelectItem();
					this.props.undo();
					this.rerender = false;
				}

				if (!id) return;	// PASTE以外は全部selectedIdがないなら実行しない

				if (order === ORDER_CHANGE) {
					this.props.changeBookDataKey({id, page, target, val, objMode});
					//this.props.setSelectItem({id});
				} else if (order === ORDER_CANCEL) {
					this.props.unsetSelectItem();
				} else if (order === ORDER_DELETE) {
					this.showDeleteModal(id, page, objMode);
				} else if (order === ORDER_COPY) {
					this.props.copyRect({id, page, objMode, direction});
				} else if (order === ORDER_CUT) {
					this.props.cutRect({id, page, objMode, direction});
				} else if (order === ORDER_PASTE_ANSWER_ICON && this.props.subject === subjectKeyName.sansu) {
					this.props.answerCopyPaste({id, page, objMode});
				}
			},
			onKeyUp: (e) => {
				e.preventDefault();
				if (e.keyCode === 18 && this.state.textDragMode && textDragSubject.includes(this.props.subject)) {
					// dragMode 종료
					this.setState({
						textDragMode: false,
					});
					this.rerender = false;
				}
			}
		}
	}

	dragEvent(page, id, width, scale) {
		const {objMode} = this.props;
		return {
			onDragStart: (e, data) => {
				e.stopPropagation();
				this.dragStartData = data;
				if (id !== this.props.selectedId) this.props.setSelectItem({page, id});
			},
			onDrag: (e, data) => {
				e.stopPropagation();
			},
			onDragStop: (e, data) => {
				e.stopPropagation();
				const xDiff = Math.abs(this.dragStartData.x - data.x);
				const yDiff = Math.abs(this.dragStartData.y - data.y);
				if (xDiff < 1 && yDiff < 1) return;
				let css = {
					top: Math.round(data.y / scale * 100) / 100,
					left: Math.round((data.x - width) / scale * 100) / 100,
				};
				if (this.props.item.data && this.props.item.data.myeditorCssOn) {
					this.props.changeRectData({
						id,
						page,
						data: {
							myeditorCss: {
								...this.props.item.data.myeditorCss,
								top: css.top,
								left: css.left,
							},
						},
						objMode: this.props.objMode,
					});
				} else {
					this.props.changeBookData({id, page, css, objMode});
				}


				this.dragStartData = {};
			},
		}
	};

	resizeEvent(page, id, scale) {
		const {objMode} = this.props;
		return {
			onResizeStart: e => {
				e.stopPropagation();
				this.setState({
					...this.state,
					isResizing: true,
				})
			},
			onResize: (e, dir, ref) => {
				e.stopPropagation();
			},
			onResizeStop: (e, dir, ref, delta, position) => {
				e.stopPropagation();
				let {width, height} = ref.style;
				const isRightPage = this.props.selectedPage === this.props.pages.right;
				const addWidth = isRightPage ? this.props.view.width / 2 : 0;
				let css = {
					width: Math.round(parseFloat(width) / scale * 100) / 100,
					height: Math.round(parseFloat(height) / scale * 100) / 100,
					top: Math.round(parseFloat(position.y) / scale * 100) / 100,
					left: Math.round(parseFloat(position.x) / scale * 100) / 100 - addWidth,
				};

				if (this.props.item.data && this.props.item.data.myeditorCssOn) {
					this.props.changeRectData({
						id,
						page,
						data: {
							myeditorCss: {
								...this.props.item.data.myeditorCss,
								top: css.top,
								left: css.left,
								width: css.width,
								height: css.height,
							},
						},
						objMode: this.props.objMode,
					});
				} else {
					this.props.changeBookData({id, page, css, objMode});
				}

				this.setState({
					...this.state,
					isResizing: false,
				})
			},
		};
	}

	editTextEvent(page, id) {
		return {
			editTextSelect: (e) => {
				// Contenteditable 영역 select 이벤트
				e.stopPropagation();
				if (this.state.textDragMode) return;
				this.addDiv(e.currentTarget);

				this.setState({
					...this.state,
					id: id,
					page: page,
				});
				if (id !== this.props.selectedId) this.props.setSelectItem({page, id});

				let textId = this.getDataId(e.currentTarget);
				if (!textId) return;
				this.props.setSelectedTextId({textId});
			},
			editText: (e) => {
				// 편집한 텍스트 redux에 저장
				let html = e.target.innerHTML;
				let regExp = this.setHtmlTagRegExp("div", false);
				let values = html.replace(regExp, "<div>").split("<div>");

				values = values.map((obj) => obj.replace(/<\/div>/g, "")); // </div> 을 찾는 정규식

				for(let tags of deleteTagList) {
					let reg = this.setHtmlTagRegExp(tags);
					values = values.map((obj) => obj.replace(reg, ""));
				}

				let text = values.filter((obj) => obj !== "");
				const isVertical = [subjectKeyName.kokugo, subjectKeyName.shosha, subjectKeyName.doutoku].includes(this.props.subject);

				this.props.changeRectEditorText({
					id: this.state.id,
					page: this.state.page,
					textArr: text,
					isVertical,
				});

			},
			mouseDown: (e) => {
				// Contenteditable 클릭 시 unsetSelectItem 이벤트 방지용
				e.stopPropagation();
			},
			keyDown(e) {
				// Contenteditable 영역 키이벤트
				// ObjectLayerのkeydownイベントを実行しない
				e.nativeEvent.stopImmediatePropagation();

				if (!this.state.textDragMode && e.altKey && this.props.selectedId && textDragSubject.includes(this.props.subject)) {
					// dragMode 시작
					this.setState({
						textDragMode: true,
					});
					e.target.blur(); // 편집중이던 텍스트 redux에 저장을 위한 blur이벤트 실행
					this.rerender = true; // 재랜더링
					return;
				}

				// absolute 편집모드일때에는 Enter키 누를시 br생성(원래는 div생성됨)
				if (this.absoluteModeCheck()) this.enterToBr(e);
			},
			keyUp(e) {
				// Contenteditable 영역 키이벤트
				// Contenteditable 에서 keyUp할 때 마다 textId 추적
				const isAbsolute = this.absoluteModeCheck();
				this.addDiv(e.currentTarget);
				let textId = this.getDataId(e.currentTarget, e.keyCode, isAbsolute);
				if(textId && this.props.selectedTextId !== textId) this.props.setSelectedTextId({textId});
				if (e.keyCode === 13) return false;
			},
		}
	};

	absoluteModeCheck() {
		if (!this.props.item || !this.props.item.editor) return false;
		if (!this.props.item.editor.text || !this.props.item.editor.text.contents) return false;
		const idx = this.props.item.editor.text.contents.findIndex((contents) => contents.id === this.props.selectedTextId);
		if (idx >= 0) {
			if (this.props.item.editor.text.contents[idx].css.position) return true;
		} else {
			return false;
		}
	}

	/**
	 * <tagName ... > 과 </tagName> 을 찾는 정규식을 반환 ( ex: <span style="font-size:19px"></span> )
	 * useCloseTag가 false라면 닫는 tag는 찾지않음
	 *
	 * @param tagName
	 * @param useCloseTag
	 * @returns {RegExp}
	 *
	 */
	setHtmlTagRegExp(tagName, useCloseTag = true) {
		let pattern = `<${tagName}([\\s.'"=a-zA-Z0-9:;%#\\/\\-_()]*)>`;
		pattern += useCloseTag ? `|<\\/${tagName}>` : "";
		return new RegExp(pattern, "gi");
	}

	// Contenteditable에 div 영역이 없을 경우 div 추가
	addDiv(target) {
		let editor = ReactDOM.findDOMNode(target);
		let html = editor.innerHTML;

		if(html.indexOf("</div>") < 0){
			let div = document.createElement('div');
			div.innerHTML= "<br>";
			editor.innerHTML = "";
			editor.insertBefore(div, null);

			let selection = window.getSelection();
			selection.collapse(editor, 1);
		}
	}

	// 현재 편집 중 이었던 위치를 파악하여 해당 div 영역의 data-id 추출
	getDataId(element, keyCode, isAbsolute) {
		let selection = window.getSelection();

		if (!selection || selection.type === "None") return "";
		let range = selection.getRangeAt(0);

		let preCaretRange = range.cloneRange();
		preCaretRange.selectNodeContents(element);
		preCaretRange.setEnd(range.endContainer, range.endOffset);

		let endContainer = preCaretRange.endContainer;
		const isEndContainer = endContainer.length;

		if(!isAbsolute && keyCode && keyCode === 13) {
			/* Contenteditable에서 Enter키를 눌러서 새로운 div가 생성되었을때,
			   예전 div설정값도 그대로 가져오기 때문에 값 초기화 */
			const target = (!isEndContainer) ? endContainer : endContainer.parentElement;
			target.setAttribute("data-id", "");
			target.setAttribute("style", "");
		}

		let dataId = endContainer.parentElement.getAttribute("data-id") || "";
		if(!isEndContainer && !dataId) dataId = endContainer.getAttribute("data-id");
		return dataId;
	}

	enterToBr(e) {
		if(e.keyCode === 13){ //enter && shift
			e.preventDefault();
			let selection = window.getSelection();
			if (selection) {
				let range = selection.getRangeAt(0);
				let br = document.createElement("br");
				range.deleteContents();
				range.insertNode(br);
				range.setStartAfter(br);
				selection.removeAllRanges();
				selection.addRange(range);
			}
		}
	}

	rectElem(width, page, item) {
		const scale = this.props.scale;
		const isRectInActivePage = !this.props.isTextMode;
		const canDragging = !this.props.isTextMode;
		const itemHasClass = (item.hasOwnProperty("data") && item.data.hasOwnProperty("class"));
		let addClass = ` ${this.props.subject}` + (itemHasClass ? ` ${item.data.class}` : "");
		const selected = (this.props.selectedId === item.originId) ? " selected" : "";
		const zIndex = !!(item.options.link.zIndex) ? item.options.link.zIndex : 0;
		const textContents = item.editor.text ? item.editor.text.contents : [];
		const text = this.getMyEditorText(textContents);
		const dragText = this.state.textDragMode ? this.getDragText(textContents, item) : ""; // dragMode용 텍스트영역 생성

		const isDragMode = (!this.state.textDragMode || (this.state.textDragMode && !selected));
		let editTextClass = this.props.isTextMode && isDragMode ? "" : "hide ";
		editTextClass += [subjectKeyName.kokugo, subjectKeyName.shosha, subjectKeyName.doutoku].includes(this.props.subject) ? "vertical-write " : "";

		const repoKey = this.props.isImageBg ? item.editor.myEditorImage_bg : item.editor.myEditorImage;
		const canResizing = !!selected;

		let rectLeft = item.css.left;
		let rectTop = item.css.top;
		let rectWidth = item.css.width;
		let rectHeight = item.css.height;

		if (item.data && item.data.myeditorCssOn && item.data.myeditorCss && selected) {
			rectLeft = item.data.myeditorCss.left || 0;
			rectTop = item.data.myeditorCss.top || 0;
			rectWidth = item.data.myeditorCss.width || 0;
			rectHeight = item.data.myeditorCss.height || 0;
			addClass = ` ${this.props.subject}`;
		}

		return (
			<Rect
				key={`${page}_${item.id}`}
				dragEvent={this.dragEvent(page, item.originId, width, scale)}
				resizeEvent={this.resizeEvent(page, item.originId, scale)}
				item={item}
				bounds={"#MyEditorLayer"}
				contents={this.props.contents}
				isRectInActivePage={isRectInActivePage}
				position={{
					x: rectLeft * scale + width,
					y: rectTop * scale,
				}}
				size={{
					width: rectWidth * scale,
					height: rectHeight * scale
				}}
				scale={scale}
				canDragging={canDragging}
				canResizing={canResizing}
				addClass={addClass}
				selected={selected}
				zIndex={zIndex}
				editorImage={this.props.repoImage[repoKey]}
				isTextMode={this.props.isTextMode}
				text={text}
				editTextClass={editTextClass}
				editTextEvent={this.editTextEvent(page, item.originId)}
				keyUp={this.editTextEvent().keyUp.bind(this)}
				keyDown={this.editTextEvent().keyDown.bind(this)}
				isResizing={this.state.isResizing}
				dragText={dragText}
				isTextDragMode={this.state.textDragMode}
				rerender={this.rerender}
			/>
		);
	}

	getImagePath(pages) {
		let {right, left} = pages;
		let leftPath = this.getPageImagePath(left);
		let rightPath = this.getPageImagePath(right);
		return `url("${leftPath}"),  url("${rightPath}")`;
	}

	getPageImagePath(pageNum) {
		let path = this.props.repoPage[pageNum];
		return path || defaultPageImagePath;
	};

	// Contenteditable 영역에 넣어줄 html 값 설정 후 return
	getMyEditorText(textContents) {
		let text = "";
		if(textContents.length > 0) {
			textContents.forEach((item) => {
				let css = "";
				if(item.css) {
					const textCss = this.textCssStringToNumber(item.css);
					if (item.css.position) {
						const cssObj = {
							fontSize: (textCss.fontSize * this.props.scale) + "em",
							lineHeight: item.css.lineHeight,
							left: item.css.left || 0,
							top: item.css.top || 0,
							position: item.css.position,
							fontWeight: item.css.fontWeight,
							color: item.css.color,
						};
						if (textCss.width && textCss.width !== "0") cssObj.width = item.css.width;
						css = this.setHtmlStyle(cssObj);
					} else {
						css = this.setHtmlStyle({
							fontSize: (textCss.fontSize * this.props.scale) + "em",
							lineHeight: item.css.lineHeight,
							marginLeft: item.css.marginLeft,
							marginTop: item.css.marginTop,
							fontWeight: item.css.fontWeight,
							color: item.css.color,
						});
					}
				}
				text += `<div style="${css}" data-id="${item.id}">${item.value}</div>`;
			})
		}
		return text;
	}

	// My에디터 텍스트 drag를 위한 영역 생성
	getDragText(textContents, objItem) {
		let text = [];
		if(textContents.length > 0) {
			text = textContents.map((item, idx) => {
				let css = {};
				let style = {};
				if(item.css) {
					const textCss = this.textCssStringToNumber(item.css);
					const cssObj = {
						fontSize: (textCss.fontSize * this.props.scale) + "em",
						lineHeight: item.css.lineHeight,
						left: item.css.left || 0,
						top: item.css.top || 0,
						position: item.css.position,
						fontWeight: item.css.fontWeight,
						color: item.css.color,
					};
					if (textCss.width && textCss.width !== "0") cssObj.width = item.css.width;
					css = textCss;
					style = cssObj;
				}

				const {selectedPage, selectedId, selectedTextId} = this.props;

				// Rnd에서 x, y좌표는 px단위만 들어가므로 %를 px 수치로 계산해서 변환
				const left = objItem.css.width * (css.left / 100) * this.props.scale;
				const top = objItem.css.height * (css.top / 100) * this.props.scale;

				const width = item.css.width || "auto";
				let value = item.value ? item.value.replace(/<br>/g, "\n") : "";
				value = value.replace(/&nbsp;/g, " ");

				return (
					<Rnd
						key={idx}
						position={{x: left, y: top}}
						size={{width: width, height: "auto"}}
						enableResizing={false}
						onDragStart={this.textDragEvent(selectedPage, selectedId, selectedTextId).onDragStart}
						onDragStop={this.textDragEvent(selectedPage, selectedId, selectedTextId).onDragStop}
						style={style}
						id={item.id}
						className={"editorDragText"}
					>
						{value}
					</Rnd>
				);
			})
		}
		return text;
	}

	// text영역 드래그용 이벤트
	textDragEvent(page, id, textId) {
		return {
			onDragStart: (e, data) => {
				e.stopPropagation();
				this.dragStartData = data;
				const dragId = e.currentTarget.id;
				if (dragId !== this.props.selectedTextId) this.props.setSelectedTextId({textId: dragId});
			},
			onDrag: (e, data) => {
				e.stopPropagation();
			},
			onDragStop: (e, data) => {
				e.stopPropagation();
				const xDiff = Math.abs(this.dragStartData.x - data.x);
				const yDiff = Math.abs(this.dragStartData.y - data.y);
				if (xDiff < 1 && yDiff < 1) return;

				// 변환했던 px 수치를 다시 %로 계산해서 변환
				let css = {
					top: Math.round(data.y / (this.props.item.css.height * this.props.scale) * 100) + "%",
					left: Math.round(data.x / (this.props.item.css.width * this.props.scale) * 100 ) + "%",
				};

				this.props.changeRectEditorTextCss({id, page, css, textId});
				this.dragStartData = {};

				this.props.setSelectedTextId({textId: ""});
			},
		}
	};

	 setHtmlStyle(values) {
		let style = "";
		for (let key of Object.keys(values)) {
			const attr = this.camelToBar(key);
			style += `${attr}:${values[key]};`
		}
		return style;
	};

	camelToBar(str) {
		return str.replace(/([A-Z])/g, function (arg) {
			return "-" + arg.toLowerCase();
		});
	};

	toggleModal(type = "Delete", show = true) {
		let modalType = `showModal${type}`;
		this.setState({
			...this.state,
			[modalType]: show,
		})
	}

	showDeleteModal(id, page, objMode) {
		this.deleteProcess = () => {
			this.props.removeRect({id, page, objMode});
			this.toggleModal("Delete", false);
			this.deleteProcess = () => {};
		};
		this.toggleModal("Delete", true);
	}

	modalCancel() {
		this.toggleModal("Delete", false);
		this.deleteProcess = () => {};
	}

	textCssStringToNumber(css) {

		return {
			fontSize: css.fontSize.replace(/[^0-9.]/g,""),
			lineHeight: css.lineHeight.replace(/[^0-9.]/g,""),
			marginLeft: css.marginLeft ? css.marginLeft.replace(/[^0-9.]/g,"") : 0,
			marginTop: css.marginTop ? css.marginTop.replace(/[^0-9.]/g,"") : 0,
			left: css.left ? css.left.replace(/[^-0-9.]/g, "") : 0,
			top: css.top ? css.top.replace(/[^-0-9.]/g, "") : 0,
			width: css.width ? css.width.replace(/[^0-9.]/g, "") : 0,
		}
	}

	render() {
		const {leftObj, objectMode, rightObj} = this.props;
		const scale = this.props.scale;
		const widthHalf = this.props.view.width * scale / 2;
		const {left: leftPage, right: rightPage} = this.props.pages;
		const leftObjects = getObjects(leftObj, objectMode);
		const rightObjects = getObjects(rightObj, objectMode);
		return (
			<div id="MyEditorLayer" onMouseDown={this.state.textDragMode ? null : this.props.unsetSelectItem} >
				{leftObjects.map(item => this.rectElem(0, leftPage, item))}
				{rightObjects.map(item => this.rectElem(widthHalf, rightPage, item))}
				{this.state.showModalDelete && <Modal
					onClickCancel={this.modalCancel.bind(this)}
					onClickConfirm={(e) => this.deleteProcess(e)}
					msg={modalMsg.DELETE}
					title={modalTitle.WARNING}
				/>}
			</div>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MyEditorLayer);
