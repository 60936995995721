import React, {Component} from 'react';
import {connect} from "react-redux";
import {SansuAnswerTable} from "../../../components/Contents/Editor/Panels/SubviewPanel/innerSubview";
import {changeRectData, changeRectDataBlock} from "../../../reducers/CombineReducers/pageData";
import {modifyRepoPath} from "../../../reducers/CombineReducers/repo";
import {
    objectModeConst,
    subViewRect,
    sansuAnswerType,
    defaultSubViewAnswerCss,
} from "../../../interface/constant";
import {randomStr} from "../../../interface/utils";
import {getObjects} from "../Editor/EditorLayers/ObjectLayer";
import {fileUpload} from "../../../components/Common/Upload";

const mapStateToProps = state => {
    const {id, page, blockId} = state.current.editor.selected;
    const {editor} = state.current;
    const objMode = objectModeConst[editor.objectMode];
    let props = {};
    if (id && page) {
        let objectList = state.pageData[page].objects[objMode];
        const idx = objectList.findIndex((item) => item.originId === id);
        const subViewSettings = editor.objectMode === "subView" ? objectList[idx].data.subViewSettings : null;

        // 지면 mask를 양면페이지에서 가져옴
        const page2 = page % 2 === 0 ? page -1 : page + 1;
        let pagesData = [page, page2];
        let maskObj = [];
        for(let page of pagesData) {
            let pageObj = state.pageData[page].objects["mask"];
            if (pageObj) {
                maskObj = maskObj.concat(getObjects(pageObj));
            }
        }

        props = {
            selectedId: id,
            selectedPage: page,
            selectedBlockId: blockId,
            grade: state.metaData.grade,
            bookId: state.metaData.bookId,
            repo: state.repo,
            maskObj,
            subViewSettings,
        }

    }
    return props;
};

const mapDispatchToProps = {
    changeRectData,
    changeRectDataBlock,
    modifyRepoPath,
};

const ADD_OFFSET = 10;
let answerOffsetX = ADD_OFFSET;
let answerOffsetY = ADD_OFFSET;

const useTextInput = [
    sansuAnswerType.answer.contents[1],
    sansuAnswerType.toggle.contents[1],
];

const useMaskInput = [
    sansuAnswerType.answer.contents[0],
    sansuAnswerType.toggle.contents[0],
];

const useGroupInput = [
    sansuAnswerType.answer.button,
    sansuAnswerType.answer.contents[0],
    sansuAnswerType.toggle.contents[0],
];

const noUseDataNumInput = [
    sansuAnswerType.answer.button,
    sansuAnswerType.answer.contents[0],
    sansuAnswerType.answer.contents[1],
    sansuAnswerType.toggle.contents[0],
    sansuAnswerType.toggle.contents[1],
];

class SansuAnswer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expressionType: "expression",
        };
        noUseDataNumInput.concat(sansuAnswerType.answer.contents);
        noUseDataNumInput.concat(sansuAnswerType.toggle.contents);
    }

    async AnswerMaskImageUpload(e, idx) {
        const result = await fileUpload(e, this.props.modifyRepoPath, this.props.bookId, "image", this.props.repo);
        if (!result || !result.fileUpload) {
            this.props.showErrorModal();
            return;
        }

        const {subViewSettings} = this.props;
        let subViewAnswer = subViewSettings.options.answer || [];
        if (subViewAnswer.length === 0) return;

        subViewAnswer[idx].image = result.data.key;

        let data = {
            subViewSettings: {
                ...subViewSettings,
                options: {
                    ...subViewSettings.options,
                    answer: subViewAnswer,
                }
            }
        };

        this.props.changeRectData({
            id: this.props.selectedId,
            page: this.props.selectedPage,
            data: data,
            objMode: "subView",
        });
    }

    subViewSettingEvent() {
        return {
            addAnswer: (e) => {
                const type = e.target.name;
                this.answerTypeAdd(type);
            },
            addToggle: () => {
                this.toggleTypeAdd();
            },
            addExpression: () => {
                this.expressionTypeAdd();
            },
            changeAnswerCss: (e) => {
                const value = Number(e.target.value);
                const data = {
                    [e.target.name]: value
                };

                this.props.changeRectDataBlock({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    blockId: this.props.selectedBlockId,
                    data: data,
                    subViewType: subViewRect.rikaAnswer,
                });
            },
            changeAnswerValue: (e) => {
                const value = e.target.name === "maskOn" ? e.target.checked : e.target.value;
                const data = {
                    [e.target.name]: value
                };

                this.props.changeRectDataBlock({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    blockId: this.props.selectedBlockId,
                    data: data,
                    subViewType: subViewRect.sansuAnswer,
                });
            },
            changeAnswerType: (e) => {
                const {subViewSettings, selectedBlockId} = this.props;
                let answer = subViewSettings.options.answer || [];
                const idx = answer.findIndex((item) => item.id === selectedBlockId);
                answer[idx].type = e.target.value;
                answer[idx].text = "";

                const data = {
                    subViewSettings: {
                        ...subViewSettings,
                        options: {
                            ...subViewSettings.options,
                            answer: answer,
                        },
                    }
                };

                this.props.changeRectData({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    data: data,
                    objMode: "subView",
                });

            },
            deleteAnswerImage: (idx) => {
                if (idx < 0) return;
                const {subViewSettings} = this.props;
                let subViewAnswer = subViewSettings.options.answer || [];
                if (subViewAnswer.length === 0) return;
                if (!subViewAnswer[idx].image) return;

                delete subViewAnswer[idx].image;

                let data = {
                    subViewSettings: {
                        ...subViewSettings,
                        options: {
                            ...subViewSettings.options,
                            answer: subViewAnswer,
                        }
                    }
                };

                this.props.changeRectData({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    data: data,
                    objMode: "subView",
                });
            },
            changeExpressionType: (e) => {
                this.setState({
                    ...this.state,
                    expressionType: e.target.value,
                })
            },
            maskConnectChange: (e) => {
                const rectId = e.target.value;
                const data = {
                    rectId: rectId,
                };

                this.props.changeRectDataBlock({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    blockId: this.props.selectedBlockId,
                    data: data,
                    subViewType: subViewRect.sansuAnswer,
                });
            },
        }
    }

    // 정답버튼+정답영역 생성
    answerTypeAdd(type) {
        const {grade} = this.props;
        const answerId = "answer-" + randomStr(8);
        let defaultAnswer = [];
        if (type === "answerButton") {
            defaultAnswer = [{
                id: answerId,
                group: 1,
                type: sansuAnswerType.answer.button,
                width: grade > 1 ? defaultSubViewAnswerCss.width.answerButton : defaultSubViewAnswerCss.width.answerButton_1,
                height: grade > 1 ? defaultSubViewAnswerCss.height.answerButton : defaultSubViewAnswerCss.height.answerButton_1,
                left: answerOffsetX,
                top: answerOffsetY,
                sizeChanged: true,
            }];
        } else if (type === "answerContents") {
            defaultAnswer = [{
                id: answerId,
                group: 1,
                type: sansuAnswerType.answer.contents[0],
                width: 110,
                height: 47,
                left: answerOffsetX + 200,
                top: answerOffsetY,
                text: "",
                fontSize: 20,
                color: "#FFFFFF",
                maskOn: true,
            }];
        }

        this.addRect(defaultAnswer);
    }

    // 클릭시 정답 보이기/숨기기 영역 생성
    toggleTypeAdd() {
        const defaultAnswer = [
            {
                id: "toggle-" + randomStr(8),
                type: sansuAnswerType.toggle.contents[0],
                width: defaultSubViewAnswerCss.width.toggleContents,
                height: defaultSubViewAnswerCss.height.toggleContents,
                left: answerOffsetX,
                top: answerOffsetY,
                text: "",
                fontSize: 20,
                color: "#FFFFFF",
                maskOn: true,
            },
        ];

        this.addRect(defaultAnswer);
    }

    // 표현식 생성
    expressionTypeAdd() {
        const buttonId = "expression-" + randomStr(8);
        const contentsId = "expression-" + randomStr(8);
        const defaultAnswer = [
            {
                id: buttonId,
                connectId: contentsId,
                type: sansuAnswerType[this.state.expressionType].button,
                width: defaultSubViewAnswerCss.width.expressionButton,
                height: defaultSubViewAnswerCss.height.expressionButton,
                left: answerOffsetX,
                top: answerOffsetY,
            },
            {
                id: contentsId,
                connectId: buttonId,
                type: sansuAnswerType[this.state.expressionType].contents,
                width: defaultSubViewAnswerCss.width.expressionContents,
                height: defaultSubViewAnswerCss.height.expressionContents,
                left: answerOffsetX,
                top: answerOffsetY + 100,
            }
        ];

        this.addRect(defaultAnswer);
    }

    addRect(defaultAnswer) {
        const {subViewSettings} = this.props;
        let answer = subViewSettings.options.answer || [];
        const data = {
            subViewSettings: {
                ...subViewSettings,
                options: {
                    ...subViewSettings.options,
                    answer: answer.concat(defaultAnswer),
                },
            }
        };

        this.props.changeRectData({
            id: this.props.selectedId,
            page: this.props.selectedPage,
            data: data,
            objMode: "subView",
        });

        if(answerOffsetY < 580){
            answerOffsetX += ADD_OFFSET;
            answerOffsetY += ADD_OFFSET;
        }else{
            answerOffsetX = answerOffsetX-560;
            answerOffsetY = answerOffsetY-580;
        }

    }

    render() {
        const {subViewSettings, selectedBlockId, maskObj} = this.props;
        const answers = subViewSettings.options.answer || [];
        const selectedAnswerIdx = answers.findIndex((item) => item.id === selectedBlockId);
        const selectedAnswer = selectedAnswerIdx < 0 ? null : answers[selectedAnswerIdx];
        const textInputHide = selectedAnswer ? useTextInput.findIndex((item) => item === selectedAnswer.type) >= 0 ? "" : "hide" : "hide";
        const maskInputHide = selectedAnswer ? useMaskInput.findIndex((item) => item === selectedAnswer.type) >= 0 ? "" : "hide" : "hide";
        const dataNumHide = selectedAnswer ? noUseDataNumInput.findIndex((item) => item === selectedAnswer.type) < 0 ? "" : "hide" : "hide";
        const groupInputHide = selectedAnswer ? useGroupInput.findIndex((item) => item === selectedAnswer.type) >= 0 ? "" : "hide" : "hide";
        const connectRectHide = selectedAnswer ? sansuAnswerType.toggle.contents.findIndex((item) => item === selectedAnswer.type) >= 0 ? "" : "hide" : "hide";
        const selectExpressionOptions = Object.keys(sansuAnswerType).filter((key) => sansuAnswerType[key].selectBoxText);
        let selectAnswerOptions = [];

        if (selectedAnswer && sansuAnswerType.answer.contents.includes(selectedAnswer.type)) {
            selectAnswerOptions = sansuAnswerType.answer.contents;
        } else if (selectedAnswer && sansuAnswerType.toggle.contents.includes(selectedAnswer.type)) {
            selectAnswerOptions = sansuAnswerType.toggle.contents;
        }

        const answerTypeHide = selectAnswerOptions.length > 0 ? "" : "hide";
        const imageDeleteHide = selectedAnswer ? selectedAnswer.image ? "" : "hide" : "hide";

        const rectMask = getObjects(maskObj, "mask") || [];

        return (
            <SansuAnswerTable
                event={this.subViewSettingEvent()}
                selectedAnswer={selectedAnswer}
                selectedAnswerIdx={selectedAnswerIdx}
                textInputHide={textInputHide}
                maskInputHide={maskInputHide}
                imageDeleteHide={imageDeleteHide}
                answerTypeHide={answerTypeHide}
                dataNumHide={dataNumHide}
                groupInputHide={groupInputHide}
                connectRectHide={connectRectHide}
                sansuAnswerType={sansuAnswerType}
                selectExpressionOptions={selectExpressionOptions}
                selectAnswerOptions={selectAnswerOptions}
                upload={(e, idx) => this.AnswerMaskImageUpload(e, idx)}
                bookId={this.props.bookId}
                rectMask={rectMask}
                tabMenu={this.props.tabMenu}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SansuAnswer);