import * as React from 'react';
import ColumnBtn from './ColumnBtn';
// @ts-ignore
import defaultIcon from "../../../../../../images/no-image.jpg";

type RepoData = {
	[key: string]: {
		path: string,
		originalname: string,
		regDate: string,
	}
}

interface Props {
	data: RepoData,

	bookId: string,

	type: string,

	hideDelete : string,

	onChange(type: string, id: string, path: string): void,

	onDelete(type: string, id: string,): void,
}

interface State {

}

class Contents extends React.Component<Props, State> {
	public static defaultProps = {
		bookId: "",
		data: {}
	};

	hasThumbNail() {
		return ["image", "thumb", "page", "popup", "kakezu"].includes(this.props.type);
	}

	clipboardCopy(e:React.MouseEvent<HTMLInputElement>) {
		const target:HTMLInputElement = e.currentTarget;
		const key:string = target.getAttribute("data-key");
		if(!key) return;
		if(navigator && navigator.clipboard) {
			navigator.clipboard.writeText(key);
		} else {
			const tempElem:HTMLInputElement = document.createElement('input');
			document.body.appendChild(tempElem);
			tempElem.value = key;
			tempElem.select();
			document.execCommand("copy");
			document.body.removeChild(tempElem);
		}
	}

	getColumnContents() {
		const data:RepoData = this.props.data;
		return (
			Object.keys(data).map(key => (
				<div className="column-contents-block" key={key}>
					<div className="repo-column-text repo-page-number">
						<span>{key}</span>
						{this.props.type !== "page" && <input
							className="clipboard-copy repo-column-button"
							type="button" value="コピー"
							data-key={key}
							onClick={this.clipboardCopy}
						/>}
					</div>

					<div className="repo-column-text repo-thumb">
						<a href={data[key].path} target={"_blank"}>
							<img className="repo-image"
							     src={this.hasThumbNail() ? "/image/thumb" + data[key].path : defaultIcon}
							     alt="repo thumbnail"
							/>
						</a>
					</div>
					<div className="repo-column-text repo-original-name">
						{data[key].originalname}
					</div>
					<div className="repo-column-text repo-reg-date">
						{data[key].regDate}
					</div>
					<ColumnBtn
						onChange={this.props.onChange}
						onDelete={this.props.onDelete}
						hideDelete={this.props.hideDelete}
						type={this.props.type}
						bookId={this.props.bookId}
						id={key}
					/>
				</div>
			))
		);
	}

	render(): React.ReactElement<React.JSXElementConstructor<any>> {
		return (
			<div className="content-center-area">
				<div className="column-title-area">
					<div className="column-title-name repo-page-number">
						{this.props.type === "page" ? "ページ" : "ID"}
					</div>
					<div className="column-title-name repo-thumb">
						{this.hasThumbNail() ? "サムネール" : "プレビュー"}
					</div>
					<div className="column-title-name repo-reg-date">ファイル名</div>
					<div className="column-title-name repo-reg-date">日時</div>
					<div className="column-title-name repo-column-buttons-are">操作</div>
				</div>
				<div className="column-content-area">
					{this.getColumnContents()}
				</div>
			</div>
		);
	}
}

export default Contents;