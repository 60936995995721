import React, { Component } from 'react';
import { connect } from 'react-redux';

import { InnerPageList } from "../../../components/Contents/Editor/Panels/PagePanel/innerPage";
import { addTotalPage, removeTotalPage } from "../../../reducers/CombineReducers/metaData";
import {
	setPages,
	startLoading,
	endLoading,
	setBookSaved,
	unsetSelectItem,
	addLog
} from "../../../reducers/CombineReducers/current";
import { addPageTwo } from "../../../reducers/CombineReducers/pageData";
import { modifyRepoPath, removeFromRepo } from "../../../reducers/CombineReducers/repo";
import {imageUpload, saveBook, addPage, deletePage, addRepo, getBookData, setPageLock} from "../../../interface/api";
import defaultPageImagePath from "../../../images/no-image.jpg";
import Modal from "../../../components/Common/Modal";
import {modalMsg, modalTitle, directionName, loadingMsg} from "../../../interface/constant";
import {setData} from "../../../interface/db";
import {now} from "../../../interface/utils";
import {getPageDataModel} from "../../../interface/dataModel";

const mapStateToProps = (state) => {
	return {
		meta: state.metaData,
		repoPage: state.repo.page,
		pages: state.current.editor.pages,
		bookChanged: state.current.book.changed,
		pageData: state.pageData,
		repo: state.repo,
		auth: state.auth,
	};
};

const mapDispatchToProps = {
	addTotalPage,
	removeTotalPage,
	setPages,
	addPageTwo,
	modifyRepoPath,
	removeFromRepo,
	startLoading,
	endLoading,
	setData,
	setBookSaved,
	unsetSelectItem,
	addLog,
};

class PageList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editingUserList: [],
			showModalDelete: false,
			showModalSave: false,
			showModalError: false,
			showModalLock: false,
			showModalUnlock: false,
			showModalLockError: false,
			showModalConflict: false,
			conflictMsg: "",
			startPage: 0,
			endPage: 0,
			lockErrorMsg: "",
		};
		this.deleteProcess = null;
		this.saveProcess = null;
		this.lockProcess = null;
		this.overwriteProcess = null;
		this.discardProcess = null;
		this.props.socket.getBookList(this.setStateEditingUserList.bind(this));
	}

	setStateEditingUserList(editingBookList) {
		const key = Object.keys(editingBookList).find(bookId => bookId === this.props.meta.bookId);
		if(editingBookList.hasOwnProperty(key)) {
			this.setState({
				editingUserList: editingBookList[key],
			});
		} else {
			console.error(editingBookList);
			console.error(this.props.meta.bookId);
		}
	}

	async selectPage(page) {
		const isCurrentPage = page === this.props.pages["left"] || page === this.props.pages["right"];
		if (!isCurrentPage) {
			if (this.props.bookChanged) {
				this.showSaveModal();
				return;
			}
			let pages = (this.props.meta.direction === directionName.rtl) ? [page, page - 1] : [page, page + 1];
			let bookData = await getBookData(this.props.meta.bookId, pages);
			this.props.setData(bookData.data);
			this.props.setPages({pages});

			const editingPage = this.props.meta.direction === directionName.rtl ? page - 1 : page;
			this.props.socket.setEditingPage(this.props.auth.info.id, editingPage, this.props.meta.bookId);
			this.props.setBookSaved();
		}
	}

	async addPageTwo() {
		if (this.props.bookChanged) {
			this.showSaveModal();
			return;
		}
		const {totalPages} = this.props.meta;
		const addPage1 = totalPages + 1;
		const addPage2 = totalPages + 2;

		this.props.startLoading({msg: loadingMsg.LOADING});
		let addPageData = {};
		addPageData[addPage1] = getPageDataModel();
		addPageData[addPage2] = getPageDataModel();
		let data = {
			pageData: addPageData,
			metaData: this.props.meta,
		};

		const result = await addPage(this.props.meta.bookId, data, [addPage1, addPage2]);
		if (result.success) {
			let resultData = result.resultData;
			resultData.repo = this.props.repo;
			await this.props.setData(resultData);
		}
		this.props.setBookSaved();
		this.props.endLoading();
		const currentTime = now();
		this.props.addLog({msg:{regDate:`${currentTime}`, userId:`${this.props.auth.info.id}`, page:`${addPage2}`, objectId:``, action: `追加`, type: "ページ"}});
	}

	async imageUpload(e) {
		let form = e.target.parentElement;
		let file = e.target.files[0];
		let page = e.target.getAttribute("data-page");
		e.target.value = "";
		let pathInfo = await imageUpload(form, file);
		let repoData = {
			key: page,
			kind: "page",
			path: pathInfo.path,
			originalname: pathInfo.originalname,
		};
		this.props.modifyRepoPath(repoData);
		repoData.regDate = now();

		await addRepo(this.props.meta.bookId, repoData);
	}

	async removePage() {
		const {totalPages} = this.props.meta;
		let selectedPage = this.props.pages["left"];
		if (totalPages > 0 && selectedPage !== 0) {
			this.props.startLoading({msg: loadingMsg.LOADING});
			const pages = [totalPages -1, totalPages];
			const result = await deletePage(this.props.meta.bookId, this.props.meta, pages);
			if (result.success) {
				let resultData = result.resultData;
				resultData.pageData = [];
				resultData.repo = this.props.repo;
				await this.props.setData(resultData);
			}
			this.props.setBookSaved();

			let upSelectedPage = selectedPage === pages[0] || selectedPage === pages[1] ? selectedPage - 2 : selectedPage;
			this.selectPage(upSelectedPage);
			this.props.endLoading();
			const currentTime = now();
			this.props.addLog({msg:{regDate:`${currentTime}`, userId:`${this.props.auth.info.id}`, page:`${totalPages}`, objectId:``, action: `削除`, type: "ページ"}});
		}
	}

	getBase64(file) {
		return new Promise((resolve, reject) => {
			let reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = function () {
				resolve(reader.result);
			};
			reader.onerror = function (error) {
				console.log('Error: ', error);
			};
		});
	}

	removeImageMap(key) {
		this.props.removeFromRepo({key, kind: "page"});
	}

	toggleModal(type = "Delete", show = true) {
		let modalType = `showModal${type}`;
		this.setState({
			...this.state,
			[modalType]: show,
		})
	}

	showDeleteModal() {
		if (this.props.bookChanged) {
			this.showSaveModal();
			return;
		}
		this.toggleModal("Delete", true);
		this.deleteProcess = (e) => {
			this.removePage();
			this.toggleModal("Delete", false);
			this.deleteProcess = () => {};
		}
	}

	showSaveModal() {
		this.toggleModal("Save", true);
		this.saveProcess = (e) => {
			this.saveFunc();
			this.toggleModal("Save", false);
			this.saveProcess = () => {};
		}
	}

	modalCancel(type) {
		this.toggleModal(type, false);
		this.deleteProcess = () => {};
		this.saveProcess = () => {};
		this.lockProcess = () => {};
		this.overwriteProcess = () => {};
		this.discardProcess = () => {};
	}

	async saveFunc() {
		if (!this.props.bookChanged) return;
		const {left, right} = this.props.pages;
		this.props.startLoading({msg: loadingMsg.LOADING});
		let savePageData = {};
		savePageData[left] = this.props.pageData[left];
		savePageData[right] = this.props.pageData[right];
		let data = {
			pageData: savePageData,
			metaData: this.props.meta,
		};

		const result = await saveBook(this.props.meta.bookId, data, [left, right]);
		if (result.success) {
			if (result.conflictInfo) {
				this.props.endLoading();
				this.setConflictMsg(result.conflictInfo);
				this.setConflictProcess([left, right], data);
				this.toggleModal("Conflict");
				return;
			}
			let resultData = result.resultData;
			resultData.repo = this.props.repo;
			await this.props.setData(resultData);
		}
		this.props.setBookSaved();
		this.props.endLoading();
	}

	setConflictMsg(conflictInfo) {
		const msg1 = `CONFLICTが発生しました。上書きしますか？<br>`;
		const msg2 = `　対象：${conflictInfo.page}.json<br>`;
		const msg3 = `revision:<br>`;
		const msg4 = `　${conflictInfo.page}.json: ${conflictInfo.myRev}(自分）/ ${conflictInfo.dbRev}(他人)<br>`;
		this.setState({
			conflictMsg: msg1 + msg2 + msg3 + msg4,
		});

	}

	setConflictProcess(pages, data) {
		this.overwriteProcess = async () => {
			this.props.unsetSelectItem();
			this.toggleModal("Conflict", false);
			this.props.startLoading({msg: loadingMsg.LOADING});

			const result = await saveBook(this.props.meta.bookId, data, pages, true);
			let resultData = result.resultData;
			resultData.repo = this.props.repo;
			await this.props.setData(resultData);
			this.props.setBookSaved();

			this.props.endLoading();
			this.overwriteProcess = () => {};
			this.discardProcess = () => {};
		};
		this.discardProcess = async () => {
			this.props.unsetSelectItem();
			this.toggleModal("Conflict", false);
			this.props.startLoading({msg: loadingMsg.LOADING});

			const bookData = await getBookData(this.props.meta.bookId, pages);
			this.props.setData(bookData.data);
			this.props.setBookSaved();

			this.props.endLoading();
			this.overwriteProcess = () => {};
			this.discardProcess = () => {};
		};
	}

	changeInput(e) {
		const value = Number(e.target.value);
		this.setState({
			[e.target.name]: value,
		})
	}

	async lockPage(type) {
		const {startPage, endPage} = this.state;
		this.toggleModal(type, false);
		if (startPage <= 0 || endPage <= 0 || startPage > endPage || startPage % 2 !== 1 || endPage % 2 !== 0) {
			// 입력한 페이지 범위가 맞지 않으면 에러
			// 0이하의 값 입력, 시작페이지가 끝페이지보다 크다, 시작페이지가 짝수, 끝페이지가 홀수 일 경우 에러
			this.toggleModal("LockError");
			return;
		}

		let pages = [];
		this.props.startLoading({msg: loadingMsg.LOADING});
		for (let i = startPage; i <= endPage; i++) {
			// 시작페이지부터 끝페이지까지의 페이지번호를 pages 배열에 저장
			// ex: 1 4 입력시 -> [1,2,3,4]
			pages.push(i);
		}
		const pageLockUser = type === "Lock" ? this.props.auth.info.id : "";
		const result = await setPageLock(this.props.meta.bookId, pages, pageLockUser); // DB에 페이지 잠금정보 저장
		this.props.endLoading({msg: loadingMsg.LOADING});
		this.lockProcess = () => {};

		if (result && result.success && !result.pageLock) {
			// 다른 유저가 잠근 페이지가 포함되어 있을경우 에러 (마스터 유저는 제외)
			this.toggleModal("Error");
		}
		else if (result && result.success && result.pageLock) {
			// 페이지 잠금(해제) 성공 시 작업
			const saved = this.props.bookChanged;
			await this.props.setData({
				pageData: result.pageData,
				metaData: this.props.meta,
				repo: this.props.repo,
			});
			if (!saved) {
				this.props.setBookSaved();
			}
		}

	}

	onClickPageLock(type) {
		this.toggleModal(type);
		this.setState({
			startPage: 0,
			endPage: 0,
		});
		this.lockProcess = async () => {
			await this.lockPage(type);
		}
	}

	pageEditElem() {
		const isMasterUser = this.props.meta.author === this.props.auth.info.id;
		if (isMasterUser) {
			return (
				<div className="inner-bottom">
					<div className="btn_left">
						<div
							className={"page-btn lockBtn"}
							onClick={() => this.onClickPageLock("Lock")}
						/>
						<div
							className={"page-btn unlockBtn"}
							onClick={() => this.onClickPageLock("Unlock")}
						/>
						<input
							type="button" className={"page-btn addBtn"}
							value="追加" name="addPage"
							onClick={this.addPageTwo.bind(this)}/>
					</div>
					<div className="btn_right">
						<input
							type="button" className={"page-btn removeBtn"}
							value="削除" name="removePage"
							onClick={this.showDeleteModal.bind(this)}/>
					</div>
				</div>
			)
		}
		else {
			return (
				<div className="inner-bottom">
					<div className="btn_left">
						<div
							className={"page-btn lockBtn"}
							onClick={() => this.onClickPageLock("Lock")}
						/>
						<div
							className={"page-btn unlockBtn"}
							onClick={() => this.onClickPageLock("Unlock")}
						/>
					</div>
				</div>
			)
		}
	}

	render() {
		const {pageData, auth} = this.props;
		const images = this.props.repoPage;
		const {rtl} = directionName;
		const {editingUserList} = this.state;
		return (
			<>
				<div className="inner-grid">
					{[...new Array(this.props.meta.totalPages / 2)].map((item, idx) => {
						const addIdx = (this.props.meta.direction === rtl) ? 2 : 1;
						const addPageIdx = (this.props.meta.direction === rtl) ? -1 : 1;
						item = idx * 2 + addIdx; // leftPage番号計算
						const leftPage = item;
						const rightPage = leftPage + addPageIdx;
						const selected = (this.props.pages["left"] === leftPage) ? "selected" : "";
						const editUserInfo = editingUserList.find(item => (item.page === leftPage || item.page === rightPage));
						const pageLockUser = (pageData[leftPage] && pageData[leftPage].pageLockUser && pageData[leftPage].pageLockUser !== auth.info.id) ? pageData[leftPage].pageLockUser : "";
						const noSelect = (editUserInfo && editUserInfo.id !== auth.info.id) || pageLockUser ? "no-select " : "";
						const myLockPage = pageData[leftPage] && pageData[leftPage].pageLockUser && pageData[leftPage].pageLockUser === auth.info.id;
						return (
							<InnerPageList
								key={"page" + leftPage}
								leftPage={leftPage}
								rightPage={rightPage}
								editUser={editUserInfo ? editUserInfo.id : ""}
								editUserIcon={editUserInfo ? editUserInfo.icon : ""}
								editUserClass={noSelect}
								pageLockUser={pageLockUser}
								myLockPage={myLockPage}
								img={[images[leftPage], images[rightPage], defaultPageImagePath]}
								onClick={noSelect ? () => {} : this.selectPage.bind(this)}
								selected={selected}
							/>
						)
					})}
				</div>
				<div className="inner-bottom">
					{this.pageEditElem()}
					<div className="inner-bottom box_marginspace">
						<label htmlFor={"leftPageImage"}>
							<span className={"page-btn leftImageBtn"}>左イメージ変更</span>
						</label>
						<label htmlFor={"rightPageImage"}>
							<span className={"page-btn rightImageBtn"}>右イメージ変更</span>
						</label>
					</div>
					<div className={"hidden-forms"} style={{display: "none"}}>
						<form method={"post"} encType={"multipart/form-data"}>
							<input
								type={"file"}
								id={"leftPageImage"}
								data-page={this.props.pages.left}
								onChange={this.imageUpload.bind(this)}
								accept={".png"}
							/>
							<input
								type={"file"}
								id={"rightPageImage"}
								data-page={this.props.pages.right}
								onChange={this.imageUpload.bind(this)}
								accept={".png"}
							/>
							<input type={"hidden"} name={"bookId"} value={this.props.meta.bookId}/>
						</form>
					</div>
				</div>
				{this.state.showModalDelete && <Modal
					onClickCancel={() => this.modalCancel("Delete")}
					onClickConfirm={(e) => this.deleteProcess(e)}
					msg={modalMsg.DELETE_PAGE}
					title={modalTitle.WARNING}
				/>}
				{this.state.showModalSave && <Modal
					onClickCancel={() => this.modalCancel("Save")}
					onClickConfirm={(e) => this.saveProcess(e)}
					msg={modalMsg.SAVE}
					title={modalTitle.ALERT}
				/>}
				{this.state.showModalError && <Modal
					onClickConfirm={() => this.toggleModal("Error", false)}
					msg={modalMsg.LOCK_FAIL_ERROR}
					title={modalTitle.ERROR}
				/>}
				{this.state.showModalLock && <Modal
					onClickCancel={() => this.modalCancel("Lock")}
					onClickConfirm={() => this.lockProcess()}
					setPageNum={{changeInput: (e) => this.changeInput(e)}}
					msg={"ロックする範囲を指定してください。"}
					title={modalTitle.ALERT}
				/>}
				{this.state.showModalUnlock && <Modal
					onClickCancel={() => this.modalCancel("Unlock")}
					onClickConfirm={() => this.lockProcess()}
					setPageNum={{changeInput: (e) => this.changeInput(e)}}
					msg={"アンロックする範囲を指定してください。"}
					title={modalTitle.ALERT}
				/>}
				{this.state.showModalLockError && <Modal
					onClickConfirm={() => this.toggleModal("LockError", false)}
					msg={modalMsg.LOCK_ERROR}
					title={modalTitle.ERROR}
				/>}
				{this.state.showModalConflict && <Modal
					conflictEvent={{ overwrite: () => this.overwriteProcess(), discard: () => this.discardProcess() }}
					onClickCancel={() => this.modalCancel("Conflict", false)}
					msg={this.state.conflictMsg}
					title={modalTitle.WARNING}
				/>}
			</>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PageList);