import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from "prop-types";
import {
	propertyInputLabel,
	propertyToolTip,
	iconTypeOptions,
	modalMsg,
	modalTitle
} from "../../../../interface/constant";
import * as Inner from "../../../../components/Contents/Editor/Panels/PropertyPanel/innerProperty";
import PosSizeProperty from "../../../../components/Contents/Editor/Panels/PropertyPanel/posSizeProperty";
import {
	changeBookData,
	changeRectId,
	changeRectPage,
	changeRectData,
	changeRectOptions,
} from '../../../../reducers/CombineReducers/pageData';
import {modifyRepoPath} from "../../../../reducers/CombineReducers/repo";
import {objectModeConst, propertyInputName} from '../../../../interface/constant';
import {setSelectItem} from "../../../../reducers/CombineReducers/current";
import Modal from "../../../../components/Common/Modal";
import {fileUpload} from "../../../../components/Common/Upload";

const mapStateToProps = (state) => {
	const objMode = objectModeConst[state.current.editor.objectMode];
	let props = {
		pages: state.current.editor.pages,
		view: state.metaData.view,
		bookId: state.metaData.bookId,
		subject: state.metaData.subject,
		direction: state.metaData.direction,
		repo: state.repo,
		objMode
	};
	let {id, page} = state.current.editor.selected;
	if (id && page) {
		let objectInfo = state.pageData[page].objects[objMode];
		props.item = objectInfo.filter((item) => item.originId === id)[0];
		props.id = id;
		props.page = page;
	}
	return props;
};

const mapDispatchToProps = {
	changeBookData,
	changeRectId,
	changeRectPage,
	changeRectData,
	setSelectItem,
	modifyRepoPath,
	changeRectOptions,
};

const PDF_TYPE_ARR = [7, 16];

const DEFAULT_STATE = {
	id: "",
	type: 0,
	propertyCss: {
		width: "",
		height: "",
		top: "",
		left: "",
	},
	zipKey: "",
	htmlFileName: "",
	pdfKey: "",
	originKey: "",
	showModalError: false,
};

let property = {
	currentId: "",
	changedId: "",
	page: 0,
	objMode: "",
};

class IconTable extends Component {
	constructor(props) {
		super(props);
		this.state = DEFAULT_STATE;
		this.errorProcess = null;
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps === prevState.prevProps) return null;
		const propertyCss = IconTable.arrangeCss(prevState.propertyCss, nextProps.item.css);
		return {
			propertyCss,
			zipKey: nextProps.item.data.zipKey || "",
			htmlFileName: nextProps.item.data.htmlFileName || "",
			pdfKey: nextProps.item.data.pdfKey || "",
			prevProps: nextProps,
		}
	}

	static arrangeCss(prevCss, nextCss) {
		return {
			...nextCss,
			width: prevCss.width === "" ? prevCss.width : nextCss.width,
			height: prevCss.height === "" ? prevCss.height : nextCss.height,
			top: prevCss.top === "" ? prevCss.top : nextCss.top,
			left: prevCss.left === "" ? prevCss.left : nextCss.left,
		}
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.props.item.id !== prevProps.item.id) {
			const id = this.props.item.id || "";
			const propertyCss = this.props.item.css;
			const type = this.iconTypeValue(this.props.item);
			this.setState({
				id: id,
				type: type,
				propertyCss
			})
		}
	}

	componentWillUnmount() {
		this.setState(DEFAULT_STATE);
	}

	onChange = (e) => {
		this.setState({
			...this.state,
			propertyCss: {
				...this.state.propertyCss,
				[e.target.name]: e.target.value,
			}
		});

		const value = e.target.value || 0;

		this.props.changeBookData({
			id: this.props.id,
			page: this.props.page,
			css: {
				[e.target.name]: (e.target.name !== 'opacity' ? parseInt(value, 10) : Number(value))
			},
			objMode: this.props.objMode
		});
	};

	pageChange = (e) => {
		let page = Number(e.target.value);
		this.props.changeRectPage({
			id: this.props.id,
			page: page,
			width: this.props.view.width / 2,
			objMode: this.props.objMode,
			direction: this.props.direction,
		});
		this.props.setSelectItem({
			id: this.props.id,
			page: page
		});
	};

	optionsChange = (e) => {
		let {page, objMode, item} = property;
		const itemOptions = Object.keys(this.props.item).length !== 0 ? this.props.item.options : item.options;
		let options = this.props.setOptionsValue(e.target, itemOptions);
		this.props.changeRectOptions({
			id: this.props.id,
			page: this.props.page || page,
			options: {
				[options.name]: options.value
			},
			objMode: this.props.objMode || objMode,
		});
	};

	iconChange = (icon) => {
		let type = Number(icon.value);
		if (type === 0) return;
		this.setState({
			...this.state,
			type: type
		});
		let idx = iconTypeOptions.findIndex((item) => item.value === type);
		let className = "icon " + iconTypeOptions[idx].className;
		this.changeRectData({
			...this.props.item.data,
			class: className,
			type: (PDF_TYPE_ARR.includes(type)) ? "pdf" : "contents",
		});
		this.props.changeBookData({
			id: this.props.id,
			page: this.props.page,
			css: {
				width: iconTypeOptions[idx].width,
				height: iconTypeOptions[idx].height,
			},
			objMode: this.props.objMode
		});
		this.props.changeRectOptions({
			id: this.props.id,
			page: this.props.page,
			options: {
				link: {
					...this.props.item.options.link,
					level: iconTypeOptions[idx].level,
				}
			},
			objMode: this.props.objMode,
		});
	};

	eventHandler() {
		return {
			onChange: this.onChange,
			pageChange: this.pageChange,
			optionsChange: this.optionsChange,
			iconChange: this.iconChange,
		}
	}

	setPropertyId(e) {
		property.currentId = this.props.id;
		property.changedId = e.target.value;
		property.page = this.props.page;
		property.objMode = this.props.objMode;
	}

	changeId(e) {
		this.setState({
			id: e.target.value
		});
		property.changedId = e.target.value;
	}

	changePageDataId() {
		let {currentId, changedId, page, objMode} = property;
		this.props.changeRectId({
			currentId: currentId,
			changedId: changedId,
			page: page,
			objMode: objMode
		});
	}

	iconTypeValue(item) {
		if (item.data && item.data.class) {
			for (let iconType of iconTypeOptions) {
				const regExp = `${iconType.className}$`; // 문자열 끝에 iconType.className 이 들어가는지 체크하는 정규식
				if (item.data.class.match(new RegExp(regExp))) {
					return iconType.value;
				}
			}
		}
		return 0;
	}

	setLabel(icon) {
		if(icon.value === 0) {
			return (
				<div className={"no-icon"}>
					{icon.name}
				</div>
			);
		}
		return (
			<div>
				<div className={`icon ${this.props.subject} ${icon.className}`}/>
			</div>
		)
	}

	getKindName() {
		return PDF_TYPE_ARR.includes(this.state.type) ? "pdf" : "html";
	}

	getKeyName() {
		return PDF_TYPE_ARR.includes(this.state.type) ? "pdfKey" : "zipKey";
	}

	async uploadFile(e) {
		let kind = this.getKeyName() === "pdfKey" ? "pdf" : "html";
		const result = await fileUpload(e, this.props.modifyRepoPath, this.props.bookId, kind, this.props.repo);
		if (!result || !result.fileUpload) {
			this.showErrorModal();
			return;
		}

		await this.updateAfterUpload(result.data.key, result.data.pathInfo, this.getKeyName());
	}

	async updateAfterUpload(key, pathInfo, dataKey) {
		let oppositeKey = (dataKey === "pdfKey") ? "zipKey" : "pdfKey";
		this.changeRectData({
			[dataKey]: key,
			[oppositeKey]: "",
		});
	}

	showErrorModal() {
		this.errorProcess = (e) => {
			e.stopPropagation();
			this.toggleModal("Error", false);
			this.errorProcess = () => {};
		};
		this.toggleModal("Error", true);
	}

	toggleModal(type = "Error", show = true) {
		let modalType = `showModal${type}`;
		this.setState({
			...this.state,
			[modalType]: show,
		})
	}

	changeRectData(data) {
		this.props.changeRectData({
			id: this.props.id,
			page: this.props.page,
			objMode: this.props.objMode,
			data,
		})
	}

	fileKeyChange(e) {
		const val = e.target.value;
		const dataKey = this.getKeyName();
		let oppositeKey = (dataKey === "pdfKey") ? "zipKey" : "pdfKey";
		this.setState({
			[dataKey]: val,
			[oppositeKey]: "",
		});
		this.changeRectData({
			[dataKey]: val,
			[oppositeKey]: "",
		});
	}

	htmlFileNameChange(e) {
		this.setState({
			htmlFileName: e.target.value,
		});
		this.changeRectData({
			htmlFileName: e.target.value,
		});
	}
	dataChange(e) {
	}

	getFileUploadElem() {
		if (this.state.type === 0) return null;
		let label, name, accept, textVal;
		if (PDF_TYPE_ARR.includes(this.state.type)) {
			label = propertyInputLabel.pdf;
			name = propertyInputName.pdf;
			accept = ".pdf";
			textVal = this.state.pdfKey;
			return (
				<>
					<Inner.TextProperty
						label={label}
						name={name}
						val={textVal}
						readOnly={false}
						onChange={this.fileKeyChange.bind(this)}
					/>
					<Inner.FileProperty
						label={""}
						htmlFor={"uploadFile"}
						btnName={"アップロード"}
						accept={accept}
						dataId={this.props.id}
						bookId={this.props.bookId}
						onChange={this.uploadFile.bind(this)}
					/>
				</>
			)
		} else {
			label = propertyInputLabel.htmlContent;
			name = propertyInputName.htmlContent;
			accept = ".zip";
			textVal = this.state.zipKey;
			return (
				<>
					<Inner.TextProperty
						label={label}
						name={name}
						val={textVal}
						readOnly={false}
						onChange={this.fileKeyChange.bind(this)}
					/>
					<Inner.FileProperty
						label={""}
						htmlFor={"uploadFile"}
						btnName={"アップロード"}
						accept={accept}
						dataId={this.props.id}
						bookId={this.props.bookId}
						onChange={this.uploadFile.bind(this)}
					/>
					<Inner.TextProperty
						label={propertyInputLabel.htmlFileName}
						name={propertyInputName.htmlFileName}
						val={this.state.htmlFileName}
						readOnly={false}
						onChange={this.htmlFileNameChange.bind(this)}
					/>
				</>
			)
		}
	}

	render() {
		const {id, page, pages, item} = this.props;
		const pageInfoOptions = this.props.pages ? [
			{val: pages.left, name: pages.left},
			{val: pages.right, name: pages.right}
		] : [];

		let iconType = iconTypeOptions.filter((item) => item.value !== 0);
		iconType.map((icon) => {
			icon.label = this.setLabel(icon);
			return icon;
		});

		const iconValue = iconType.filter((icon) => icon.value === this.state.type);

		return (
			<div className="inner-table">
				<table>
					<tbody>
					<Inner.TextProperty
						label={propertyInputLabel.id}
						name={propertyInputName.id}
						val={this.state.id}
						readOnly={false}
						onFocus={this.setPropertyId.bind(this)}
						onBlur={this.changePageDataId.bind(this)}
						onChange={this.changeId.bind(this)}
						tooltipText={propertyToolTip.id}
					/>
					<Inner.SelectProperty
						label={propertyInputLabel.page}
						name={propertyInputName.page}
						options={pageInfoOptions}
						eventHandler={this.pageChange} val={page}
						tooltipText={propertyToolTip.page}
					/>
					<Inner.NumberProperty
						label={propertyInputLabel.zIndex}
						name={propertyInputName.zIndex}
						val={item.options.link ? item.options.link.zIndex || "" : ""}
						onChange={this.eventHandler().optionsChange}
						tooltipText={propertyToolTip.zIndex}
					/>
					<PosSizeProperty
						css={this.state.propertyCss}
						eventHandler={this.eventHandler()}
						useSize={false}
					/>
					<Inner.SelectImageProperty
						label={propertyInputLabel.iconType}
						name={propertyInputName.iconType}
						options={iconType}
						val={iconValue}
						eventHandler={this.iconChange}
					/>
					{this.getFileUploadElem()}
					</tbody>
				</table>
				<div className="inner-mask" style={{display: id ? "none" : ""}}/>
				{this.state.showModalError && <Modal
					onClickConfirm={(e) => this.errorProcess(e)}
					msg={modalMsg.IMAGE_EXIST}
					title={modalTitle.ERROR}
				/>}
			</div>
		)
	}
}

IconTable.defaultProps = {
	item: {
		originId: "",
		id: "",
		css: {},
		options: {},
		page: 0,
		data: {},
	},
	page: 0,
	id: "",
	repo: {},
};

IconTable.propTypes = {
	item: PropTypes.shape({
		originId: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		css: PropTypes.object.isRequired,
		options: PropTypes.object.isRequired,
		data: PropTypes.object.isRequired,
		page: PropTypes.number.isRequired
	}).isRequired,
	page: PropTypes.number.isRequired,
	id: PropTypes.string.isRequired,
	repo: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(IconTable);