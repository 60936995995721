import produce from "immer/dist/immer";
import { randomStr } from "../../interface/utils";
import {
	getAudioLinkModel,
	getBlockModel,
	getDefaultDataModel,
	getIconDataModel,
	getPageDataModel,
	getPageLinkModel,
	getRedLineModel,
	getSubViewModel,
	getVideoLinkModel,
	getMaskModel,
	getMeaningModel,
	getFormModel,
	getPostItModel,
	getCheckModel,
	getPopupModel,
	getRestudyModel,
	getGuideModeModel,
} from "../../interface/dataModel";
import { defaultIcon, directionName, subViewRect, defaultVideoIcon, subjectKeyName, defaultMaskColor } from "../../interface/constant";
import { getLastUndoStack } from "../../interface/middleWare";

const SET_BOOK_DATA = "SET_BOOK_DATA";
const ADD_RECT = "ADD_RECT";
const REMOVE_RECT = "REMOVE_RECT";
const CHANGE_RECT = "CHANGE_RECT";
const CHANGE_RECT_KEY = "CHANGE_RECT_KEY";
const COPY_RECT = "COPY_RECT";
const PASTE_RECT = "PASTE_RECT";
const ANSWER_COPY_PASTE = "ANSWER_COPY_PASTE";
const CUT_RECT = "CUT_RECT";
const ADD_PAGE = "ADD_PAGE";
const ADD_PAGE_ALL = "ADD_PAGE_ALL";
const ADD_PAGE_TWO = "ADD_PAGE_TWO";
const CHANGE_RECT_ID = "CHANGE_RECT_ID";
const CHANGE_RECT_PAGE = "CHANGE_RECT_PAGE";
const CHANGE_RECT_OPTIONS = "CHANGE_RECT_OPTIONS";
const CHANGE_RECT_DATA = "CHANGE_RECT_DATA";
const CHANGE_RECT_SVG = "CHANGE_RECT_SVG";
const CHANGE_RECT_EDITOR = "CHANGE_RECT_EDITOR";
const CHANGE_RECT_EDITOR_IGNORE = "CHANGE_RECT_EDITOR_IGNORE";
const CHANGE_TWO_RECT_ORDER = "CHANGE_TWO_RECT_ORDER";
const CHANGE_RECT_EDITOR_BG = "CHANGE_RECT_EDITOR_BG";
const CHANGE_GROUP_ON_MULTI_ITEMS = "CHANGE_GROUP_MULTI_ITEMS";
const CHANGE_RECT_EDITOR_TEXT = "CHANGE_RECT_EDITOR_TEXT";
const CHANGE_RECT_EDITOR_TEXT_CSS = "CHANGE_RECT_EDITOR_TEXT_CSS";
const ADD_RECT_EDITOR_TEXT = "ADD_RECT_EDITOR_TEXT";
const CHANGE_EDITOR_TEXT_ORDER = "CHANGE_EDITOR_TEXT_ORDER";
const DELETE_EDITOR_TEXT = "DELETE_EDITOR_TEXT";
const UNDO = "UNDO";
const CHANGE_RECT_DATA_BLOCK = "CHANGE_RECT_DATA_BLOCK";
const CHANGE_RECT_DATA_BLOCK_KEY = "CHANGE_RECT_DATA_BLOCK_KEY";
const REMOVE_RECT_DATA_BLOCK = "REMOVE_RECT_DATA_BLOCK";
const REMOVE_SCROLL_IMAGE = "REMOVE_SCROLL_IMAGE";
const CHANGE_SCROLL_IMAGE_CSS = "CHANGE_SCROLL_IMAGE_CSS";
const CHANGE_SCROLL_RECT_ID = "CHANGE_SCROLL_RECT_ID";
const CHANGE_RECT_REFER_PARAM = "CHANGE_RECT_REFER_PARAM";
const DELETE_RECT_REFER_PARAM = "DELETE_RECT_REFER_PARAM";
const CHANGE_RECT_CLASS_AND_CSS = "CHANGE_RECT_CLASS_AND_CSS";
const CHANGE_RECT_DATA_SVG = "CHANGE_RECT_DATA_SVG";
const CHANGE_RECT_THUMBKEY = "CHANGE_RECT_THUMBKEY";
const ADD_GUIDE_MODE = "ADD_GUIDE_MODE";
const CHANGE_GUIDE_MODE_ON_MULTI_ITEMS = "CHANGE_GUIDE_MODE_ON_MULTI_ITEMS";

const pageDataModel = getPageDataModel();
const initialState = {
	"1": pageDataModel,
	"2": pageDataModel
};

const linkLevel = {
	subView: 2,
	myEditor: 2,
	icon: defaultIcon.level,
	meaning: 1,
	form: 1,
	pageLink: 2,
	videoLink: 3,
	redLine: 1,
	mask: 3,
	block: 1,
	postIt: 0,
	check: 1,
	popupLink: 2,
	restudy: 3,
};

let offsetX = 0;
let offsetY = 0;
const ADD_OFFSET = 10;
let copiedObj = {
	pageDirection: "",
	subView: [],
	videoLink: [],
	myEditor: [],
	icon: [],
	pageLink: [],
	audioLink: [],
	meaning: [],
	redLine: [],
	mask: [],
	block: [],
	postIt: [],
};
let copyOffsetX = ADD_OFFSET;
let copyOffsetY = ADD_OFFSET;

//Reducer
export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case ADD_RECT: {
			const {page, objMode} = action;
			let newObj = getNewObject(action);

			if (!action.css || !action.css.top) {
				if(offsetY < 580){
					offsetX += ADD_OFFSET;
					offsetY += ADD_OFFSET;
				}else{
					offsetX = offsetX-560;
					offsetY = offsetY-580;
				}
			}

			if (state[page].objects.hasOwnProperty(objMode)) {
				return produce(state, draft => {
					draft[page].objects[objMode].push(newObj);
				});
			} else {
				return produce(state, draft => {
					draft[page].objects[objMode] = [newObj];
				});
			}

		}
		case SET_BOOK_DATA: {
			const newState = action.state;
			return {
				...state,
				...newState
			}
		}
		case REMOVE_RECT: {
			const {id, page, objMode} = action;
			let idx = state[page].objects[objMode].findIndex((item) => item.originId === id);
			return produce(state, draft => {
				draft[page].objects[objMode].splice(idx, 1);
			});
		}
		case CHANGE_RECT: {
			const {id, page, css, objMode} = action;
			let idx = getIdx(state, page, id, objMode);
			if (idx < 0) return state;
			let newCss = Object.assign({}, state[page].objects[objMode][idx].css, css);
			return produce(state, draft => {
				draft[page].objects[objMode][idx].css = newCss;
			});
		}
		case CHANGE_RECT_KEY: {
			const {id, target, page, val, objMode} = action;
			let idx = getIdx(state, page, id, objMode);
			if (idx < 0) return state;

			action.css = {[target]: state[page].objects[objMode][idx].css[target] + val};
			action.type = CHANGE_RECT;
			return reducer(state, action);
		}
		case COPY_RECT: {
			copyObject(state, action);
			return state;
		}
		case PASTE_RECT: {
			const {objMode, direction} = action;
			if (copiedObj[objMode] < 1) return state;

			const {rtl} = directionName;

			const addPage = (direction === rtl) ? -1 : 1;
			let isRightPage = (copiedObj.pageDirection === "right");
			let copiedObjCss = copiedObj[objMode].css;
			let page = action.page + (isRightPage ? addPage : 0);
			const idNumber = randomStr(8);
			let pasteObj = {
				...copiedObj[objMode],
				originId: `${page}-${idNumber}`,
				id: `${page}-${idNumber}`,
				page: page,
				css: {
					...copiedObjCss,
					top: copiedObjCss.top + copyOffsetY,
					left: copiedObjCss.left + copyOffsetX
				},
			};

			copyOffsetX += ADD_OFFSET;
			copyOffsetY += ADD_OFFSET;

			if (state[page].objects.hasOwnProperty(objMode)) {
				return produce(state, draft => {
					draft[page].objects[objMode].push(pasteObj);
				});
			} else {
				return produce(state, draft => {
					draft[page].objects[objMode] = [pasteObj];
				});
			}
		}
		case ANSWER_COPY_PASTE: {
			const {id, page, objMode} = action;
			let idx = state[page].objects[objMode].findIndex((item) => item.originId === id);
			if (idx < 0) return state;

			const copiedObj = state[page].objects[objMode][idx];
			let pasteObj = null;
			if (copiedObj.data && copiedObj.data.class) {
				//복사할 object의 class에 editor-only가 있다면 제거
				copiedObj.data.class = copiedObj.data.class.replace("editor-only", "");
			}

			if (copiedObj.data && copiedObj.data.class.includes("icon answer")) {
				// 해답 아이콘
				const pasteObjOriginId = `${copiedObj.originId}-editor`;
				if (state[page].objects[objMode].find((item) => item.originId === pasteObjOriginId)) return state; // 이미 복사된게 있으면 안함
				pasteObj = {
					// level -> 3, 에디터전용, 에디터자동등록제외 지정
					...copiedObj,
					originId: pasteObjOriginId,
					id: `${copiedObj.id}-editor`,
					options: {
						...copiedObj.options,
						link: {
							...copiedObj.options.link,
							level: 3,
						}
					},
					data: {
						...copiedObj.data,
						class: copiedObj.data.class ? copiedObj.data.class + " editor-only" : "editor-only",
					},
					editor: {
						ignore: true,
					}
				};
			} else if (copiedObj.options && copiedObj.options.iFrame) {
				// HTML컨텐츠가 들어있는 클릭포인트
				const pasteObjOriginId = `${copiedObj.originId}-editor`;
				if (state[page].objects[objMode].find((item) => item.originId === pasteObjOriginId)) return state; // 이미 복사된게 있으면 안함
				pasteObj = {
					// iFrame해제, html파일제거, 에디터전용, editor가 있으면 복사, 없으면 만듦
					...copiedObj,
					originId: pasteObjOriginId,
					id: `${copiedObj.id}-editor`,
					options: {
						...copiedObj.options,
						iFrame: false,
					},
					data: {
						...copiedObj.data,
						class: copiedObj.data.class ? copiedObj.data.class + " editor-only" : "editor-only",
						zipKey: "",
						type: "",
					},
					editor: copiedObj.editor || {}
				};
			} else {
				// 그 외 일반 클릭포인트
				const pasteObjOriginId = `${copiedObj.originId}-editor`;
				if (state[page].objects[objMode].find((item) => item.originId === pasteObjOriginId)) return state; // 이미 복사된게 있으면 안함
				pasteObj = {
					// 에디터전용, editor가 있으면 복사, 없으면 만듦, identify가 있으면 제거
					...copiedObj,
					originId: pasteObjOriginId,
					id: `${copiedObj.id}-editor`,
					options: {
						...copiedObj.options,
						identify: "",
					},
					data: {
						...copiedObj.data,
						class: copiedObj.data.class ? copiedObj.data.class + " editor-only" : "editor-only",
					},
					editor: copiedObj.editor || {}
				};
			}

			if (!pasteObj) return state; // 복사된거 없으면 안함

			if (pasteObj && pasteObj.options.group) delete pasteObj.options.group; // 복사된 object는 그룹설정 제거

			return produce(state, draft => {
				draft[page].objects[objMode].push(pasteObj);
				delete draft[page].objects[objMode][idx].editor; // 원본 object 에디터제외 설정(editor 정보 모두 사라짐)
			});
		}
		case CUT_RECT: {
			copyObject(state, action);
			const {id, page, objMode} = action;
			const cutObjectList = state[page].objects[objMode].filter((obj, i) => obj.id !== id);
			state = produce(state, draft => {
				draft[page].object = cutObjectList;
			});

			action.type = REMOVE_RECT;
			return reducer(state, action);
		}
		case ADD_PAGE: {
			return produce(state, draft => {
				draft[action.pageNum] = action.data;
			});
		}
		case ADD_PAGE_ALL: {
			return produce(state, draft => {
				for (let [idx, page] of action.data.entries()) {
					page = page || pageDataModel;
					draft[page._id || idx + 1] = page;
				}
			});
		}
		case ADD_PAGE_TWO: {
			let firstP = action.newPageNum;
			let secondP = firstP + 1;
			return produce(state, draft => {
				draft[firstP] = pageDataModel;
				draft[secondP] = pageDataModel;
			});
		}
		case CHANGE_RECT_ID: {
			const {currentId, changedId, page, objMode} = action;
			let idx = getIdx(state, page, currentId, objMode);
			return produce(state, draft => {
				draft[page].objects[objMode][idx].id = changedId;
			});
		}
		case CHANGE_RECT_PAGE: {
			const {id, page, width, objMode, direction} = action;
			let currentPage = (page % 2 === 0) ? page - 1 : page + 1;
			let currentPageObjs = state[currentPage].objects[objMode].filter((item) => item.originId !== id);

			let idx = getIdx(state, currentPage, id, objMode);
			const directionAddWidth = direction === directionName.rtl ? -1 : 1;
			let changedPageObj = state[currentPage].objects[objMode][idx];
			changedPageObj.css.left += (page % 2 === 0) ? (-width * directionAddWidth) : (+width * directionAddWidth);
			changedPageObj = {
				...changedPageObj,
				page: page,
			};

			if (state[page].objects.hasOwnProperty(objMode)) {
				return produce(state, draft => {
					draft[currentPage].objects[objMode] = currentPageObjs;
					draft[page].objects[objMode].push(changedPageObj);
				});
			} else {
				return produce(state, draft => {
					draft[currentPage].objects[objMode] = currentPageObjs;
					draft[page].objects[objMode] = [changedPageObj];
				});
			}
		}
		case CHANGE_RECT_OPTIONS: {
			const {id, page, options, objMode} = action;
			let idx = getIdx(state, page, id, objMode);
			let changedOptions = Object.assign({}, state[page].objects[objMode][idx].options, options);
			return produce(state, draft => {
				draft[page].objects[objMode][idx].options = changedOptions;
			});
		}

		case CHANGE_RECT_DATA: {
			const {id, page, data, objMode} = action;
			let changedData;
			let idx = getIdx(state, page, id, objMode);
			if (typeof data === "number") {
				changedData = data;
			} else {
				changedData = Object.assign({}, state[page].objects[objMode][idx].data, data);
				if(data.param === 0) delete changedData.param;
			}
			if(data.hasOwnProperty("startTime")) {
				if (data.startTime === "") delete changedData.startTime;
				changedData.startTime = parseFloat(data.startTime);
			}
			if(data.hasOwnProperty("endTime")) {
				if (data.endTime === "") delete changedData.endTime;
				changedData.endTime = parseFloat(data.endTime);
			}
			if (data.tooltip === "") delete changedData.tooltip;
			if (data.moveAt === "") delete changedData.moveAt;
			if (data.playAt === "") delete changedData.playAt;
			return produce(state, draft => {
				draft[page].objects[objMode][idx].data = changedData;
			});
		}

		case CHANGE_RECT_SVG: {
			const {id, page, svg, objMode} = action;
			const idx = getIdx(state, page, id, objMode);
			if(svg) {
				const changedSVG = Object.assign({}, state[page].objects[objMode][idx].svg, svg);
				return produce(state, draft => {
					draft[page].objects[objMode][idx].svg = changedSVG;
				});
			} else {
				return produce(state, draft => {
					delete draft[page].objects[objMode][idx].svg;
				});
			}
		}

		case CHANGE_RECT_EDITOR: {
			const {id, page, editorUse, objMode} = action;
			let idx = getIdx(state, page, id, objMode);
			let editor = state[page].objects[objMode][idx].editor;
			let editorOnly = toggleEditorOnly(state[page].objects[objMode][idx], editorUse === 2);
			const defaultEditor = objMode === "videoLink" ? {ignore: true} : {};
			return produce(state, draft => {
				if (editorUse === 1) {
					delete draft[page].objects[objMode][idx].editor;
				} else {
					draft[page].objects[objMode][idx].editor = editor ? {...editor} : defaultEditor;
				}
				draft[page].objects[objMode][idx].data = editorOnly;
			});
		}
		case CHANGE_RECT_EDITOR_IGNORE: {
			const {id, page, ignore, objMode} = action;
			let idx = getIdx(state, page, id, objMode);
			return produce(state, draft => {
				draft[page].objects[objMode][idx].editor.ignore = ignore;
			});
		}
		case CHANGE_TWO_RECT_ORDER: {
			const {id, page, objMode, changeType} = action;
			let idx = getIdx(state, page, id, objMode);
			let addIdx = 0;
			if (changeType === "up") addIdx = -1;
			else if (changeType === "down") addIdx = 1;

			const changedObj = state[page].objects[objMode][idx + addIdx];
			if (!changedObj) return state;
			const currentObj = state[page].objects[objMode][idx];

			return produce(state, draft => {
				draft[page].objects[objMode][idx] = changedObj;
				draft[page].objects[objMode][idx + addIdx] = currentObj;
			});
		}
		case CHANGE_RECT_EDITOR_BG: {
			const {id, page, objMode, bgType, repoKey} = action;
			let idx = getIdx(state, page, id, objMode);
			return produce(state, draft => {
				draft[page].objects[objMode][idx].editor[bgType] = repoKey;
			});
		}
		case CHANGE_RECT_EDITOR_TEXT: {
			const defaultEditorTextObj = {
				defaultCss: {
					fontSize: "1em",
					position: "absolute"
				},
				contents: []
			};
			const defaultTextCss = {
				fontSize: "1em",
				lineHeight: "135%",
				marginLeft: "0%",
				marginTop: "0%",
				color: "",
				fontWeight: "",
			};

			const {id, page, textArr, isVertical} = action;
			const objMode = "subView";
			let idx = getIdx(state, page, id, objMode);
			let editorText = state[page].objects[objMode][idx].editor.text || defaultEditorTextObj;
			editorText.defaultCss.writingMode = isVertical ? "vertical-rl" : "";

			return produce(state, draft => {
				draft[page].objects[objMode][idx].editor.text = editorText;
				draft[page].objects[objMode][idx].editor.text.contents = textArr.map((obj, i) => {
					const idNumber = randomStr(8);
					return {
						...editorText.contents[i],
						value: obj,
						id: editorText.contents[i] ? editorText.contents[i].id : `t-${page}-${idNumber}`,
						css: editorText.contents[i] ? editorText.contents[i].css : defaultTextCss
					}
				});
			});
		}
		case CHANGE_RECT_EDITOR_TEXT_CSS: {
			const {id, page, css, textId} = action;
			const objMode = "subView";
			let idx = getIdx(state, page, id, objMode);
			const contents = state[page].objects[objMode][idx].editor.text.contents;
			let textIdx = getEditorTextIdx(contents, textId);

			if(textIdx < 0) return state;
			let absoluteChanged = state[page].objects[objMode][idx].editor.text.contents[textIdx].css.position !== css.position;
			let textCss = Object.assign({}, state[page].objects[objMode][idx].editor.text.contents[textIdx].css, css);


			return produce(state, draft => {
				draft[page].objects[objMode][idx].editor.text.contents[textIdx].css = textCss;

				if (absoluteChanged) {
					if (css.position === "absolute") {
						draft[page].objects[objMode][idx].editor.text.contents.map((item) => {
							item.css.position = "absolute";
							return item;
						})
					} else if (css.position === "") {
						draft[page].objects[objMode][idx].editor.text.contents.map((item) => {
							item.css.position = "";
							return item;
						})
					}
				}
			});
		}
		case ADD_RECT_EDITOR_TEXT: {
			const defaultEditorTextObj = {
				defaultCss: {
					fontSize: "1em",
					position: "absolute",
					writingMode: "",
				},
				contents: []
			};
			const defaultTextCss = {
				fontSize: "1em",
				lineHeight: "135%",
				marginLeft: "0%",
				marginTop: "0%",
				color: "",
				fontWeight: "",
				position: "absolute",
				left: "0%",
				top: "0%",
			};

			const {id, page} = action;
			const objMode = "subView";
			let idx = getIdx(state, page, id, objMode);
			let editorText = state[page].objects[objMode][idx].editor.text || defaultEditorTextObj;

			return produce(state, draft => {
				const idNumber = randomStr(8);
				const contents = {
					value: "TEXT",
					id: `t-${page}-${idNumber}`,
					css: defaultTextCss,
				};

				draft[page].objects[objMode][idx].editor.text.contents = editorText.contents.concat(contents);
			});
		}
		case CHANGE_EDITOR_TEXT_ORDER: {
			const {id, page, textIdx, addIdx} = action;
			const objMode = "subView";
			let idx = getIdx(state, page, id, objMode);

			const changedObj = state[page].objects[objMode][idx].editor.text.contents[textIdx + addIdx];
			if (!changedObj) return state;
			const currentObj = state[page].objects[objMode][idx].editor.text.contents[textIdx];

			return produce(state, draft => {
				draft[page].objects[objMode][idx].editor.text.contents[textIdx] = changedObj;
				draft[page].objects[objMode][idx].editor.text.contents[textIdx + addIdx] = currentObj;
			});
		}
		case DELETE_EDITOR_TEXT: {
			const {id, page, textIdx} = action;
			const objMode = "subView";
			let idx = getIdx(state, page, id, objMode);

			return produce(state, draft => {
				draft[page].objects[objMode][idx].editor.text.contents.splice(textIdx, 1);
				if (draft[page].objects[objMode][idx].editor.text.contents.length === 0) {
					delete draft[page].objects[objMode][idx].editor.text;
				}
			});
		}
		case CHANGE_GROUP_ON_MULTI_ITEMS: {
			const updated = action.updated;
			const deleted = action.deleted;
			return produce(state, draft => {
				if(updated.length >= 0) {
					for(let item of updated) {
						let idx = getIdx(state, item.page, item.originId, "subView");
						draft[item.page].objects.subView[idx].options.group = {
							name: item.groupName,
							index: item.groupIndex,
						}
					}
				}
				if(deleted.length >= 0) {
					for(let item of deleted) {
						let idx = getIdx(state, item.page, item.originId, "subView");
						delete draft[item.page].objects.subView[idx].options.group;
					}
				}
			});
		}

		case UNDO: {
			const undoPageData = getLastUndoStack();
			if(!undoPageData) return state;
			return { ...undoPageData };
		}

		case CHANGE_RECT_DATA_BLOCK: {
			const {id, page, data, blockId, subViewType} = action;
			const objMode = "subView";
			let idx = getIdx(state, page, id, objMode);
			if (idx < 0) return state;
			const blocks = state[page].objects[objMode][idx].data.subViewSettings.options[subViewType];
			if (!blocks) return state;
			const blockIdx = blocks.findIndex((item) => item.id === blockId);
			const block = Object.assign({}, blocks[blockIdx], data);

			return produce(state, draft => {
				draft[page].objects[objMode][idx].data.subViewSettings.options[subViewType][blockIdx] = block;
				if(data.dataNum && blocks[blockIdx].connectId) {
					const connectIdx = blocks.findIndex((item) => item.id === blocks[blockIdx].connectId);
					draft[page].objects[objMode][idx].data.subViewSettings.options[subViewType][connectIdx].dataNum = data.dataNum;
				}
			});
		}
		case CHANGE_RECT_DATA_BLOCK_KEY: {
			const {id, page, target, val, blockId, subViewType} = action;
			const objMode = "subView";
			let idx = getIdx(state, page, id, objMode);
			if (idx < 0) return state;
			const blocks = state[page].objects[objMode][idx].data.subViewSettings.options[subViewType];
			const blockIdx = blocks.findIndex((item) => item.id === blockId);

			action.data = {[target]: blocks[blockIdx][target] + val};
			action.type = CHANGE_RECT_DATA_BLOCK;
			return reducer(state, action);
		}
		case REMOVE_RECT_DATA_BLOCK: {
			const {id, page, blockId, subViewType} = action;
			const objMode = "subView";
			let idx = getIdx(state, page, id, objMode);
			const blocks = state[page].objects[objMode][idx].data.subViewSettings.options[subViewType];
			const blockIdx = blocks.findIndex((item) => item.id === blockId);

			return produce(state, draft => {
				if (blockIdx > -1) {
					draft[page].objects[objMode][idx].data.subViewSettings.options[subViewType].splice(blockIdx, 1);
					if(subViewType === subViewRect.sansuAnswer || subViewType === subViewRect.rikaCheck) {
						const answer = draft[page].objects[objMode][idx].data.subViewSettings.options[subViewType];
						const connectIdx = answer.findIndex((item) => item.id === blocks[blockIdx].connectId);
						if(connectIdx >= 0) answer.splice(connectIdx, 1);
					}
				}
			});
		}
		case REMOVE_SCROLL_IMAGE: {
			const {id, page, isTitle, scrollIdx} = action;
			const objMode = "subView";
			let idx = getIdx(state, page, id, objMode);
			const scrollImage = state[page].objects[objMode][idx].data.subViewSettings.options.scrollImage;

			return produce(state, draft => {
				if(isTitle) {
					delete draft[page].objects[objMode][idx].data.subViewSettings.options.titleImage;
				}
				else if(scrollIdx >= 0) {
					let rectIdx = getIdx(state, page, scrollImage[scrollIdx].rectId, objMode);
					if(rectIdx >= 0) {
						delete draft[page].objects[objMode][rectIdx].refer;
						delete draft[page].objects[objMode][rectIdx].data.param;
						draft[page].objects[objMode][rectIdx].data.path = "";
					}
					draft[page].objects[objMode][idx].data.subViewSettings.options.scrollImage.splice(scrollIdx, 1);
				}
				else if(scrollImage) {
					for(let scroll of scrollImage) {
						let rectIdx = getIdx(state, page, scroll.rectId, objMode);
						if(rectIdx >= 0) {
							delete draft[page].objects[objMode][rectIdx].refer;
							delete draft[page].objects[objMode][rectIdx].data.param;
							draft[page].objects[objMode][rectIdx].data.path = "";
						}
					}
					delete draft[page].objects[objMode][idx].data.subViewSettings.options.scrollImage;
				}
			});
		}
		case CHANGE_SCROLL_IMAGE_CSS: {
			const {id, page, scrollIdx, isTitle, target, value} = action;
			const objMode = "subView";
			let idx = getIdx(state, page, id, objMode);

			const isDelete = (target === "width" || target === "imageHeight") && value <= 0;
			return produce(state, draft => {
				if(isTitle) {
					if (isDelete) {
						delete draft[page].objects[objMode][idx].data.subViewSettings.options.titleImage[target];
					} else {
						draft[page].objects[objMode][idx].data.subViewSettings.options.titleImage[target] = value;
					}
				} else {
					if (isDelete) {
						delete draft[page].objects[objMode][idx].data.subViewSettings.options.scrollImage[scrollIdx][target];
					} else {
						draft[page].objects[objMode][idx].data.subViewSettings.options.scrollImage[scrollIdx][target] = value;
					}
				}
			});
		}
		case CHANGE_SCROLL_RECT_ID: {
			const {id, page, scrollIdx, rectId} = action;
			const objMode = "subView";
			let idx = getIdx(state, page, id, objMode);

			return produce(state, draft => {
				draft[page].objects[objMode][idx].data.subViewSettings.options.scrollImage[scrollIdx].rectId = rectId;
			});
		}
		case CHANGE_RECT_CLASS_AND_CSS: {
			const {id, page, newClass, newCss, objMode} = action;
			let idx = getIdx(state, page, id, objMode);
			return produce(state, draft => {
				draft[page].objects[objMode][idx].data.class = draft[page].objects[objMode][idx].data.class = newClass;
				draft[page].objects[objMode][idx].css = newCss;
			});
		}
		case CHANGE_RECT_REFER_PARAM: {
			const {id, page, referId, param, htmlPath, previousRectId} = action;
			const objMode = "subView";
			let idx = getIdx(state, page, id, objMode);
			let previousIdx = getIdx(state, page, previousRectId, objMode);

			return produce(state, draft => {
				if(idx >= 0) {
					draft[page].objects[objMode][idx].refer = referId;
					draft[page].objects[objMode][idx].data.param = param;
					draft[page].objects[objMode][idx].data.path = htmlPath;
				}

				if(previousIdx >= 0) {
					delete draft[page].objects[objMode][previousIdx].refer;
					delete draft[page].objects[objMode][previousIdx].data.param;
					draft[page].objects[objMode][previousIdx].data.path = "";
				}
			});
		}
		case DELETE_RECT_REFER_PARAM: {
			const {id, page} = action;
			const objMode = "subView";
			let idx = getIdx(state, page, id, objMode);

			return produce(state, draft => {
				if(idx >= 0) {
					delete draft[page].objects[objMode][idx].refer;
					delete draft[page].objects[objMode][idx].data.param;
				}
			});
		}
		case CHANGE_RECT_DATA_SVG: {
			const {id, page, data, blockId, subViewType} = action;
			const objMode = "subView";
			let idx = getIdx(state, page, id, objMode);
			const blocks = state[page].objects[objMode][idx].data.subViewSettings.options[subViewType];
			const blockIdx = blocks.findIndex((item) => item.id === blockId);
			const block = Object.assign({}, blocks[blockIdx], data);
			if (block.svg && !data.svg) delete block.svg;

			return produce(state, draft => {
				draft[page].objects[objMode][idx].data.subViewSettings.options[subViewType][blockIdx] = block;
			});
		}
		case CHANGE_RECT_THUMBKEY: {
			const {id, page, objMode, thumbKey} = action;
			let mode = objMode;
			let idx = getIdx(state, page, id, mode);
			if (objMode === "subView" && idx < 0) {
				mode = "videoLink";
				idx = getIdx(state, page, id, mode);
			}
			return produce(state, draft => {
				if (idx >= 0) draft[page].objects[mode][idx].thumbKey = thumbKey;
			});
		}
		case ADD_GUIDE_MODE: {
			const {id, page, options, subViewId} = action;
			const objMode = "guideMode";

			let newObj = getObjectDataModel(objMode);
			newObj.subViewId = subViewId;
			newObj.originId = id;
			newObj.id = id;
			newObj.page = page;
			newObj.options = options;

			if (state[page].objects.hasOwnProperty(objMode)) {
				return produce(state, draft => {
					draft[page].objects[objMode].push(newObj);
				});
			} else {
				return produce(state, draft => {
					draft[page].objects[objMode] = [newObj];
				});
			}
		}
		case CHANGE_GUIDE_MODE_ON_MULTI_ITEMS: {
			const updated = action.updated;
			const deleted = action.deleted;
			return produce(state, draft => {
				if(updated.length >= 0) {
					for(let item of updated) {
						let idx = getIdx(state, item.page, item.originId, "guideMode");
						draft[item.page].objects.guideMode[idx].options.group = {
							name: item.groupName,
							index: item.groupIndex,
						}
					}
				}
				if(deleted.length >= 0) {
					for(let item of deleted) {
						let idx = getIdx(state, item.page, item.originId, "guideMode");
						draft[item.page].objects.guideMode.splice(idx, 1);
					}
				}
			});
		}
		default: {
			return state;
		}
	}
}

function getIdx(list, page, id, objMode) {
	let objectList = list[page].objects[objMode];
	return objectList.findIndex((item) => item.originId === id);
}

function getEditorTextIdx(contents, id) {
	return contents.findIndex((item) => item.id === id);
}

function copyObject(state, action) {
	const {id, page, objMode, direction} = action;
	const {rtl, ltr} = directionName;
	let idx = state[page].objects[objMode].findIndex((item) => item.originId === id);
	if (idx < 0) return state;

	copiedObj[objMode] = state[page].objects[objMode][idx];
	if (direction === rtl) {
		copiedObj.pageDirection = (page % 2 === 0) ? "left" : "right";
	} else if (direction === ltr) {
		copiedObj.pageDirection = (page % 2 === 0) ? "right" : "left";
	}
	copyOffsetX = ADD_OFFSET;
	copyOffsetY = ADD_OFFSET;
}

function toggleEditorOnly(obj, useEditorOnly) {
	let data = {};
	if (useEditorOnly) {
		if (obj.data && obj.data.class) {
			let objClass = "editor-only " + obj.data.class ;
			data = {
				...obj.data,
				class: objClass
			}
		} else if (obj.data) {
			data = {
				...obj.data,
				class: "editor-only "
			}
		} else {
			data = {
				class: "editor-only "
			}
		}
	} else {
		if (obj.data && obj.data.class) {
			let objClass = obj.data.class.replace("editor-only", "");
			data = {
				...obj.data,
				class: objClass
			}
		} else if (obj.data) {
			data = obj.data;
		} else {
			data = {}
		}
	}
	return data;
}

function getNewObject(action) {
	const {page, objectMode, subject, id} = action;
	//const idNumber = randomStr(8);
	let model = getObjectDataModel(objectMode);
	model.originId = `${id}`;
	model.id = `${id}`;
	model.page = page;
	model.css = Object.assign(model.css, action.css || {
		width: 100,
		height: 100,
		top: offsetY,
		left: offsetX
	});

	if (objectMode === 'icon') {
		model.data.class = "icon " + defaultIcon.className;
	}
	if (objectMode !== 'audioLink') model.options.link.level = linkLevel[objectMode];
	if (objectMode === 'videoLink') {
		model.data.class = "icon " + defaultVideoIcon.className;
		model.css = {
			...model.css,
			width: defaultVideoIcon.width,
			height: defaultVideoIcon.height,
		}
	}

	if (objectMode === 'popupLink') {
		const isTumazuki = subject === subjectKeyName.sansu || subject === subjectKeyName.rika;
		if (isTumazuki) {
			model.data.popup.type = "tumazuki";
			model.options.link.level = 3;
			model.data.class = "icon stumble";
			model.css.width = 31;
			model.css.height = 30;
		} else {
			model.data.popup.position = model.page % 2 === 0 ? "right" : "left";
			model.data.popup.type = "word";
		}
	}

	if (objectMode === 'mask') {
		if (subject === subjectKeyName.sansu) {
			model.data.fill = defaultMaskColor.sansu;
		}
	}

	if (objectMode === 'restudy') {
		model.css = {
			...model.css,
			width: 37,
			height: 30,
		}
	}

	if (objectMode === 'block' && subject === "shakai") {
		delete model.css.outline;
		delete model.data.color;
	}
	return model;
}

function getObjectDataModel(type) {
	let func = {
		subView: getSubViewModel,
		icon: getIconDataModel,
		pageLink: getPageLinkModel,
		myEditor: getSubViewModel,
		videoLink: getVideoLinkModel,
		audioLink: getAudioLinkModel,
		meaning: getMeaningModel,
		form: getFormModel,
		redLine: getRedLineModel,
		block: getBlockModel,
		mask: getMaskModel,
		postIt: getPostItModel,
		check: getCheckModel,
		popupLink: getPopupModel,
		restudy: getRestudyModel,
		guideMode: getGuideModeModel,
	};
	return func.hasOwnProperty(type) ? func[type]() : getDefaultDataModel();
}

//Action Generators
export function changeBookData({id, page, css, objMode}) {
	return {type: CHANGE_RECT, id, page, css, objMode}
}

export function changeBookDataKey({id, page, target, val, objMode}) {
	return {type: CHANGE_RECT_KEY, id, page, target, val, objMode}
}

export function addRect(state) {
	return {
		type: ADD_RECT,
		id:state.id,
		page: state.page,
		css: state.css || null,
		objMode: state.objMode,
		objectMode: state.objectMode,
		subject: state.subject || null,
	}
}

export function removeRect(state) {
	return {
		type: REMOVE_RECT,
		page: state.page,
		id: state.id,
		objMode: state.objMode
	}
}

export function copyRect(state) {
	return {
		type: COPY_RECT,
		page: state.page,
		id: state.id,
		objMode: state.objMode,
		direction: state.direction,
	}
}

export function pasteRect(state) {
	return {
		type: PASTE_RECT,
		page: state.page,
		objMode: state.objMode,
		direction: state.direction,
	}
}

export function answerCopyPaste(state) {
	return {
		type: ANSWER_COPY_PASTE,
		page: state.page,
		id: state.id,
		objMode: state.objMode,
	}
}

export function cutRect({id, page, objMode, direction}) {
	return {
		type: CUT_RECT,
		id: id,
		page: page,
		objMode,
		direction: direction,
	}
}

export function addPage(state) {
	return {
		type: ADD_PAGE,
		pageNum: state.pageNum,
		data: state.data,
	}
}

export function addPageAll(state) {
	return {
		type: ADD_PAGE_ALL,
		data: state.data,
	}
}

export function addPageTwo(state) {
	return {
		type: ADD_PAGE_TWO,
		newPageNum: state.newPageNum,
	}
}

export function changeRectId(state) {
	return {
		type: CHANGE_RECT_ID,
		currentId: state.currentId,
		changedId: state.changedId,
		page: state.page,
		objMode: state.objMode
	}
}

export function changeRectPage(state) {
	return {
		type: CHANGE_RECT_PAGE,
		id: state.id,
		page: state.page,
		width: state.width,
		objMode: state.objMode,
		direction: state.direction,
	}
}

export function changeRectOptions(state) {
	return {
		type: CHANGE_RECT_OPTIONS,
		id: state.id,
		page: state.page,
		options: state.options,
		objMode: state.objMode
	}
}

export function changeRectData(state) {
	return {
		type: CHANGE_RECT_DATA,
		id: state.id,
		page: state.page,
		data: state.data,
		objMode: state.objMode
	}
}

export function changeRectEditor(state) {
	return {
		type: CHANGE_RECT_EDITOR,
		id: state.id,
		page: state.page,
		editorUse: state.editorUse,
		objMode: state.objMode
	}
}

export function changeRectEditorIgnore(state) {
	return {
		type: CHANGE_RECT_EDITOR_IGNORE,
		id: state.id,
		page: state.page,
		ignore: state.ignore,
		objMode: state.objMode
	}
}

export function changeTwoRectOrder(state) {
	return {
		type: CHANGE_TWO_RECT_ORDER,
		id: state.id,
		page: state.page,
		objMode: state.objMode,
		changeType: state.changeType
	}
}

export function changeRectEditorBG(state) {
	return {
		type: CHANGE_RECT_EDITOR_BG,
		id: state.id,
		page: state.page,
		objMode: state.objMode,
		bgType: state.bgType,
		repoKey: state.repoKey
	}
}

export function setBookData(state) {
	return {
		type: SET_BOOK_DATA,
		state
	}
}

export function changeRectEditorText(state) {
	return {
		type: CHANGE_RECT_EDITOR_TEXT,
		id: state.id,
		page: state.page,
		textArr: state.textArr,
		isVertical: state.isVertical,
	}
}

export function changeRectEditorTextCss(state) {
	return {
		type: CHANGE_RECT_EDITOR_TEXT_CSS,
		id: state.id,
		page: state.page,
		css: state.css,
		textId: state.textId,
	}
}

export function addRectEditorText(state) {
	return {
		type: ADD_RECT_EDITOR_TEXT,
		id: state.id,
		page: state.page,
	}
}

export function changeEditorTextOrder(state) {
	return {
		type: CHANGE_EDITOR_TEXT_ORDER,
		id: state.id,
		page: state.page,
		textIdx: state.textIdx,
		addIdx: state.addIdx,
	}
}

export function deleteEditorText(state) {
	return {
		type: DELETE_EDITOR_TEXT,
		id: state.id,
		page: state.page,
		textIdx: state.textIdx,
	}
}

export function changeGroupOnMultiItem(state) {
	return {
		type: CHANGE_GROUP_ON_MULTI_ITEMS,
		updated: state.updated,
		deleted: state.deleted,
	}
}

export function undo() {
	return {
		type: UNDO,
	}
}

export function changeRectDataBlock(state) {
	return {
		type: CHANGE_RECT_DATA_BLOCK,
		id: state.id,
		page: state.page,
		blockId: state.blockId,
		data: state.data,
		subViewType: state.subViewType,
	}
}

export function changeRectDataBlockKey(state) {
	return {
		type: CHANGE_RECT_DATA_BLOCK_KEY,
		id: state.id,
		page: state.page,
		target: state.target,
		val: state.val,
		blockId: state.blockId,
		subViewType: state.subViewType,
	}
}

export function removeRectDataBlock(state) {
	return {
		type: REMOVE_RECT_DATA_BLOCK,
		id: state.id,
		page: state.page,
		blockId: state.blockId,
		subViewType: state.subViewType,
	}
}

export function removeScrollImage(state) {
	return {
		type: REMOVE_SCROLL_IMAGE,
		id: state.id,
		page: state.page,
		isTitle: state.isTitle || false,
		scrollIdx: state.scrollIdx,
	}
}

export function changeScrollImageCss(state) {
	return {
		type: CHANGE_SCROLL_IMAGE_CSS,
		id: state.id,
		page: state.page,
		scrollIdx: state.scrollIdx,
		isTitle: state.isTitle,
		target: state.target,
		value: state.value,
	}
}

export function changeScrollRectId(state) {
	return {
		type: CHANGE_SCROLL_RECT_ID,
		id: state.id,
		page: state.page,
		scrollIdx: state.scrollIdx,
		rectId: state.rectId,
	}
}

export function changeRectReferParam(state) {
	return {
		type: CHANGE_RECT_REFER_PARAM,
		id: state.id,
		page: state.page,
		referId: state.referId,
		param: state.param,
		htmlPath: state.htmlPath,
		previousRectId: state.previousRectId,
	}
}

export function deleteRectReferParam(state) {
	return {
		type: DELETE_RECT_REFER_PARAM,
		id: state.id,
		page: state.page,
	}
}

export function changeRectClassAndCss(state) {
	return {
		type: CHANGE_RECT_CLASS_AND_CSS,
		id: state.id,
		page: state.page,
		newClass: state.newClass,
		newCss: state.newCss,
		objMode: state.objMode
	}
}

export function changeRectSVG(state) {
	return {
		type: CHANGE_RECT_SVG,
		id: state.id,
		page: state.page,
		svg: state.svg,
		objMode: state.objMode
	}
}

export function changeRectDataSvg(state) {
	return {
		type: CHANGE_RECT_DATA_SVG,
		id: state.id,
		page: state.page,
		blockId: state.blockId,
		data: state.data,
		subViewType: state.subViewType,
	}
}

export function changeRectThumbKey(state) {
	return {
		type: CHANGE_RECT_THUMBKEY,
		id: state.id,
		page: state.page,
		objMode: state.objMode,
		thumbKey: state.thumbKey,
	}
}

export function addGuideMode(state) {
	return {
		type: ADD_GUIDE_MODE,
		id: state.id,
		page: state.page,
		options: state.options,
		subViewId: state.subViewId,
	}
}

export function changeGuideModeOnMultiItems(state) {
	return {
		type: CHANGE_GUIDE_MODE_ON_MULTI_ITEMS,
		updated: state.updated,
		deleted: state.deleted,
	}
}