import React, {Component} from 'react';
import {connect} from "react-redux";
import {SubViewInSubViewTable} from "../../../components/Contents/Editor/Panels/SubviewPanel/innerSubview";
import {changeRectData, changeRectDataBlock, changeRectDataSvg} from "../../../reducers/CombineReducers/pageData";
import {togglePanel} from "../../../reducers/CombineReducers/panel";
import {objectModeConst, subViewRect} from "../../../interface/constant";
import {randomStr} from "../../../interface/utils";

const mapStateToProps = state => {
    const {id, page, blockId} = state.current.editor.selected;
    const {editor} = state.current;
    const objMode = objectModeConst[editor.objectMode];
    let props = {};
    if (id && page) {
        let objectList = state.pageData[page].objects[objMode];
        const idx = objectList.findIndex((item) => item.originId === id);
        const subViewSettings = editor.objectMode === "subView" ? objectList[idx].data.subViewSettings : null;
        props = {
            selectedId: id,
            selectedPage: page,
            selectedBlockId: blockId,
            view: state.metaData.view,
            subViewSettings,
        }

    }
    return props;
};

const mapDispatchToProps = {
    changeRectData,
    changeRectDataBlock,
    changeRectDataSvg,
    togglePanel,
};

const ADD_OFFSET = 10;
let subViewLinkOffsetX = ADD_OFFSET;
let subViewLinkOffsetY = ADD_OFFSET;

class SubViewInSubView extends Component {
    subViewSettingEvent() {
        return {
            addLink: () => {
                const {subViewSettings} = this.props;
                let subViewLink = subViewSettings.options.subViewLink || [];
                let scrollTop = 0;
                let scrollLeft = 0;
                if (this.props.scrollLevel) {
                    const isHorizon = subViewSettings.options.scrollType === "h";
                    scrollTop = isHorizon ? 0 : this.props.view.height * this.props.scrollLevel;
                    scrollLeft = isHorizon ? this.props.view.width * this.props.scrollLevel : 0;
                }
                const defaultBlock = {
                    id: "subViewLink-" + randomStr(8),
                    rectId: "",
                    width: 100,
                    height: 100,
                    left: subViewLinkOffsetX + scrollLeft,
                    top: subViewLinkOffsetY + scrollTop,
                };
                if(subViewLinkOffsetY < 580){
                    subViewLinkOffsetX += ADD_OFFSET;
                    subViewLinkOffsetY += ADD_OFFSET;
                }else{
                    subViewLinkOffsetX = subViewLinkOffsetX-560;
                    subViewLinkOffsetY = subViewLinkOffsetY-580;
                }

                const data = {
                    subViewSettings: {
                        ...subViewSettings,
                        options: {
                            ...subViewSettings.options,
                            subViewLink: subViewLink.concat(defaultBlock),
                        },
                    }
                };

                this.props.changeRectData({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    data: data,
                    objMode: "subView",
                });
            },
            changeSubViewLinkEvent: (e, idx) => {
                let subViewLink = this.props.subViewSettings.options.subViewLink;
                subViewLink[idx][e.target.name] = e.target.value;

                const data = {
                    subViewSettings: {
                        ...this.props.subViewSettings,
                        options: {
                            ...this.props.subViewSettings.options,
                            subViewLink: subViewLink,
                        },
                    }
                };

                this.props.changeRectData({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    data: data,
                    objMode: "subView",
                });
            },
        }
    }

    render() {
        const {subViewSettings, selectedBlockId} = this.props;
        const subViewLink = subViewSettings.options.subViewLink;
        const subViewLinkIdx = subViewLink ? subViewLink.findIndex((item) => item.id === selectedBlockId) : -1;
        const menuHide = subViewLinkIdx < 0 ? "hide" : "";
        const svgData = {
            changeRectDataSVG: this.props.changeRectDataSvg,
            togglePanel: this.props.togglePanel,
            item: subViewLinkIdx >= 0 ? subViewLink[subViewLinkIdx] : {},
            panel: this.props.panel,
            id: this.props.selectedId,
            page: this.props.selectedPage,
            blockId: this.props.selectedBlockId,
            objMode: "subview",
            mode: this.props.mode,
            svg: subViewLinkIdx >= 0 && subViewLink[subViewLinkIdx].svg ? subViewLink[subViewLinkIdx].svg.attr.points : "",
            subViewType: subViewRect.subViewInSubView
        };
        return (
            <SubViewInSubViewTable
                event={this.subViewSettingEvent()}
                selectedSubViewLink={ subViewLinkIdx < 0 ? null : subViewSettings.options.subViewLink[subViewLinkIdx]}
                subViewLinkIdx={subViewLinkIdx}
                menuHide={menuHide}
                svgData={svgData}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubViewInSubView);