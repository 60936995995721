import React from 'react';
import AddToolTip from "../../../../Common/AddToolTip";

export function InnerPageList(props) {
	const {leftPage, rightPage, editUser, editUserIcon, pageLockUser, myLockPage, editUserClass, img, onClick, selected} = props;
	const lockMsg = pageLockUser ? pageLockUser + "がロック" : "";
	return (
		<div className={"grid-wrapper " + selected} onClick={() => onClick(leftPage)}>
			<div className={"grid-menu " + editUserClass}>
				<div className={"pageEditUser"}>
					<div className={editUser ? "user-icon " + editUserIcon : "hide"}>
						<AddToolTip text={editUser} direction={"left"} isQuestion={false}/>
					</div>
				</div>
				<div className={myLockPage ? "lock-user my-lock " : "lock-user "}>
					{myLockPage ? "自分がロック" : lockMsg }
				</div>
				<div className={"pages"}>
					<div className={"pageLeft"}>
						<img src={img[0] ? ("/image/thumb" + img[0].path) : img[2]} alt={leftPage}/>
						<span className={"pageLabel"}>{leftPage}</span>
					</div>
					<div className={"pageRight"}>
						<img src={img[1] ? ("/image/thumb" + img[1].path) : img[2]} alt={(rightPage)}/>
						<span className={"pageLabel"}>{rightPage}</span>
					</div>
				</div>
			</div>
		</div>
	)
}