import React, {Component} from 'react';
import {connect} from 'react-redux'
import {objectModeConst} from "../../../interface/constant";
import SVGColumn from "../../../components/Contents/Editor/Panels/SVGPanel/SVGColumn";
import {makeColorCodeHSL, findSubViewType, isPointValidate, isPathDValidate} from "../../../interface/utils";
import {changeRectDataSvg} from "../../../reducers/CombineReducers/pageData";
import SVGPathColumn from "../../../components/Contents/Editor/Panels/SVGPanel/SVGPathColumn";

const mapStateToProps = state => {
    const {id, page, blockId} = state.current.editor.selected;
    const {editor} = state.current;
    const objMode = objectModeConst[editor.objectMode];
    let props = {
        objMode: objMode,
    };
    if (id && page) {
        let objectInfo = state.pageData[page].objects[objMode];
        const idx = objectInfo.findIndex((item) => item.originId === id);
        const subViewSettings = editor.objectMode === "subView" ? objectInfo[idx].data.subViewSettings : null;
        props.item = objectInfo.filter((item) => item.originId === id)[0];
        props.id = id;
        props.page = page;
        props.blockId = blockId;
        props.subViewSettings = subViewSettings;
    }
    return props;
};

const mapDispatchToProps = {
    changeRectDataSvg,
};

class SubviewEditSVG extends Component {
    constructor(props) {
        super(props);
        this.state = {
            points: [],
            d: [],
            type: "",
            svgType: "",
            selectedRectObj: null
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps === prevState.prevProps) return null;
        let data = {
            prevProps: nextProps,
        }

        const {subViewSettings, blockId} = nextProps;
        let {type, index} = findSubViewType(subViewSettings, blockId);
        data.type = type;
        const selectedRectObj = subViewSettings.options[type][index];

        if(selectedRectObj && selectedRectObj.svg) {
            data.selectedRectObj = selectedRectObj;
            data.svgType = selectedRectObj.svg.type;

            if(data.svgType === "polygon") {
                data.points = selectedRectObj.svg.attr.points.split(" ");
            } else {
                const d = selectedRectObj.svg.attr.d;
                let regexp = /([a-yA-Y]+\s?[-]?\d+(\.\d+)?\s?[-]?\d+(\.\d+)?)/g;
                data.d = d.match(regexp);
            }
        }
        return data;
    }

    changeRectSVG(newArray) {
        const key = (this.state.svgType === "polygon") ? "points" : "d";
        const data = {
            svg: {
                type: this.state.svgType,
                attr: {
                    [key]: newArray.join(" "),
                }
            }
        };

        this.props.changeRectDataSvg({
            id: this.props.id,
            page: this.props.page,
            blockId: this.props.blockId,
            data: data,
            subViewType: this.state.type,
        });
    }

    onChangePoint(e) {
        const points = this.state.points;
        const idx = e.currentTarget.getAttribute("data-idx");
        const value = e.currentTarget.value;
        const newPoints = points;
        newPoints[idx] = value;
        if (isPointValidate(value)) {
            this.changeRectSVG(newPoints);
        } else {
            // this.setState({points: newPoints})
        }
    };

    addPoint(e) {
        let x1, y1, x2, y2;
        const points = this.state.points;
        const idx = parseInt(e.currentTarget.getAttribute("data-idx"));
        if (typeof idx === "number") {
            [x1, y1] = points[idx].split(",").map(i => parseInt(i));
            let idx2 = this.getNextPoints(idx, true);
            [x2, y2] = points[idx2].split(",").map(i => parseInt(i));

            const middle = this.getMiddlePoints(x1, y1, x2, y2).join(",");
            const newPoints = [...points.slice(0, idx + 1), middle, ...points.slice(idx + 1)];
            this.changeRectSVG(newPoints);
        }
    };

    getNextPoints(idx, isPolygon) {
        const points = isPolygon ? this.state.points : this.state.d;
        let idx2 = idx + 1;
        if (idx === (points.length - 1)) idx2 = 0;
        return idx2;
    }

    getMiddlePoints(x1, y1, x2, y2) {
        return [Math.floor((x1 + x2) / 2), Math.floor((y1 + y2) / 2)];
    }

    deletePoint(e) {
        const points = this.state.points;
        const idx = parseInt(e.currentTarget.getAttribute("data-idx"));
        if (typeof idx === "number") {
            const newPoints = [...points.slice(0, idx), ...points.slice(idx + 1)];
            this.changeRectSVG(newPoints);
        }
    };

    onChangePathD(e) {
        const value = e.currentTarget.value;
        const commandValue = e.currentTarget.previousSibling.value;
        const idx = e.currentTarget.getAttribute("data-idx");
        this.onChangePath(idx, value, commandValue);
    }

    onChangePathCommand(e) {
        const idx = e.currentTarget.getAttribute("data-idx");
        const commandValue = e.currentTarget.value;
        const value = e.currentTarget.nextSibling.value;
        this.onChangePath(idx, value, commandValue);
    }

    onChangePath(idx, value, commandValue) {
        const newD = [...this.state.d];
        newD[idx] = commandValue + " " + value;
        if (isPathDValidate(newD[idx])) {
            this.changeRectSVG(newD);
        } else {
            this.setState({d: newD})
        }
    }

    addPathD(e) {
        let x1, y1, x2, y2;
        const d = this.state.d;
        const idx = parseInt(e.currentTarget.getAttribute("data-idx"));
        if (typeof idx === "number") {
            let itemArr = d[idx].replace(/[a-zA-Z]\s?/g, '');
            [x1, y1] = itemArr.split(" ").map(i => parseInt(i));
            let idx2 = this.getNextPoints(idx, false);
            let itemArr2 = d[idx2].replace(/[a-zA-Z]\s?/g, '');
            [x2, y2] = itemArr2.split(" ").map(i => parseInt(i));

            const middle = "L " + (this.getMiddlePoints(x1, y1, x2, y2).join(" "));
            const newPoints = [...d.slice(0, idx + 1), middle, ...d.slice(idx + 1)];
            this.changeRectSVG(newPoints);
        }
    }

    deletePathD(e) {
        const d = this.state.d;
        const idx = parseInt(e.currentTarget.getAttribute("data-idx"));
        if (typeof idx === "number") {
            const newD = [...d.slice(0, idx), ...d.slice(idx + 1)];
            this.changeRectSVG(newD);
        }
    }

    svgColumnMap() {
        return (
            <div>
                {this.state.points.map((item, idx) => {
                    return (
                        <SVGColumn
                            color={makeColorCodeHSL(idx)}
                            key={idx}
                            index={idx}
                            length={this.state.points.length}
                            onChangePoint={this.onChangePoint.bind(this)}
                            addPoint={this.addPoint.bind(this)}
                            deletePoint={this.deletePoint.bind(this)}
                            value={item}
                        />
                    )
                })}
            </div>
        );
    }

    svgPathColumnMap() {
        return (
            <div>
                {this.state.d.map((item, idx) => {
                    return (
                        <SVGPathColumn
                            color={makeColorCodeHSL(idx)}
                            key={idx}
                            index={idx}
                            length={this.state.d.length}
                            onChangePathD={this.onChangePathD.bind(this)}
                            onChangePathCommand={this.onChangePathCommand.bind(this)}
                            addPathD={this.addPathD.bind(this)}
                            deletePathD={this.deletePathD.bind(this)}
                            value={item.replace(/[a-zA-Z]\s?/g, '')}
                            command={item.replace(/\s?[-]?\d+(\.\d+)?\s?/g, '')}
                        />
                    );
                })}
            </div>
        )
    }

    render() {
        if(!this.state.selectedRectObj) {
            console.error("no selected object");
            return (<div/>)
        }
        return (this.state.svgType === "polygon") ? this.svgColumnMap() : this.svgPathColumnMap();
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubviewEditSVG);