import React, { Component } from 'react';
import PropTypes from 'prop-types';

class GrayScreen extends Component {
	onMousedown(e) {
		e.stopPropagation();
	}

	render() {
		return(
			<div onMouseDown={this.onMousedown} className={"gray-screen " + (this.props.className || "")}>{this.props.children}</div>
		);
	}
}

GrayScreen.propTypes ={
	children: PropTypes.element,
};

export default GrayScreen;