import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from "prop-types";

import DropZone from "../../../components/Contents/Editor/Panels/DropZone";
import LinkPageItem from "../../../components/Contents/Editor/Panels/LinkPagePanel/LinkPageItem";

import { modifyRepoPath } from "../../../reducers/CombineReducers/repo";
import { changeRectOptions, changeRectThumbKey } from "../../../reducers/CombineReducers/pageData";

import {imageUpload, addRepo} from "../../../interface/api";
import { objectModeConst } from "../../../interface/constant";
import { checkNested, now, randomStr } from "../../../interface/utils";
import { dragType } from "../../../interface/constant";

const mapStateToProps = state => {
	const objMode = objectModeConst[state.current.editor.objectMode];
	let {id, page} = state.current.editor.selected;
	let prop = {};
	if (id && page) {
		let objectInfo = state.pageData[page].objects[objMode];
		let objIndex = objectInfo.findIndex(item => item.originId === id);
		prop = {
			item: objectInfo[objIndex] || {},
			index: objIndex,
			mode: state.current.editor.mode,
			thumb: state.repo.thumb,
			bookId: state.metaData.bookId,
			pageData: state.pageData,
			repo: state.repo,
			pages: state.current.editor.pages,
			objMode,
		};
	}
	return prop;
};

const mapDispatchToProps = {
	changeRectOptions,
	modifyRepoPath,
	changeRectThumbKey,
};

class LinkPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			linkPageInfo: [],
			inputId: "",
			inputPage: 0,
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps === prevState.prevProps) return null;
		let linkPageInfo = [];
		if (checkNested(nextProps, "item.options.linkPageInfo")) {
			linkPageInfo = [...nextProps.item.options.linkPageInfo];
		}
		return {linkPageInfo ,prevProps: nextProps};
	}

	onDrop(item) {
		let linkPageInfo = [];
		if (checkNested(this.props, "item.options.linkPageInfo")) {
			linkPageInfo = [...this.props.item.options.linkPageInfo];
			if (linkPageInfo.findIndex((val) => val.originId === item.originId) !== -1) return;
			if (linkPageInfo.length > 9) return;
		}
		linkPageInfo.push({
			id: item.id,
			page: item.page,
			originId: item.originId,
			// thumb: item.thumbKey || "",
		});
		this.updateLinkPages(linkPageInfo);
	}

	moveItem(dragIndex, hoverIndex) {
		let linkPageInfo = [...this.props.item.options.linkPageInfo];
		[linkPageInfo[dragIndex], linkPageInfo[hoverIndex]] = [linkPageInfo[hoverIndex], linkPageInfo[dragIndex]]; //swap
		this.setState({linkPageInfo});
	}

	moveEnd() {
		let linkPageInfo = [...this.state.linkPageInfo];
		this.updateLinkPages(linkPageInfo);
	}

	deleteItem(item) {
		let linkPageInfo = this.state.linkPageInfo.filter((linkPage) => linkPage.originId !== item.originId);
		this.updateLinkPages(linkPageInfo);
	}

	async uploadImg(e, idx, item) {
		const form = e.target.parentElement;
		const file = e.target.files[0];

		const currentPages = [this.props.pages.left, this.props.pages.right];
		if (!currentPages.includes(item.page) && !item.thumb) {
			// 이미지 업로드 시, 다른페이지에 있는 링크페이지라면 현재 링크페이지정보에 thumb값을 저장(다른클릭포인트의 thumbKey 보다 나중적용)
			item.thumb = randomStr(12);
		}

		const pathInfo = await imageUpload(form, file);
		const isChange = !!this.getThumbKey(item);
		const thumbKey = isChange ? this.getThumbKey(item) : randomStr(12);
		let repoData = {
			kind: "thumb",
			key: thumbKey,
			path: pathInfo.path,
			originalname: pathInfo.originalname,
		};
		this.props.modifyRepoPath(repoData);
		this.props.changeRectThumbKey({
			id: item.originId,
			page: item.page,
			objMode: this.props.objMode,
			thumbKey: thumbKey,
		});
		repoData.regDate = now();
		console.log(repoData);
		await addRepo(this.props.bookId, repoData, isChange);
	}

	updateLinkPages(linkPageInfo) {
		this.props.changeRectOptions({
			id: this.props.item.originId,
			page: this.props.item.page,
			objMode: "subView",
			options: {
				...this.props.item.options,
				linkPages: linkPageInfo.map((item) => item.id),
				linkPageInfo: linkPageInfo,
			}
		});
	}

	getThumbKey(item) {
		let thumbKey = "";
		let objects = this.props.pageData[item.page].objects["subView"];
		let linkPageIdx = objects.findIndex((subView) => subView.originId === item.originId);
		if (linkPageIdx < 0) {
			objects = this.props.pageData[item.page].objects["videoLink"];
			linkPageIdx = objects.findIndex((videoLink) => videoLink.originId === item.originId);
		}
		if (linkPageIdx >= 0) thumbKey = objects[linkPageIdx].thumbKey || "";
		if (thumbKey === "" && item.thumb) {
			thumbKey = item.thumb;
		}
		return thumbKey;
	}

	getLinkPage(linkPageInfo) {
		if (linkPageInfo.length <= 0) {
			return (<div className={"drop-zone-empty"}>追加してください。</div>);
		}
		return linkPageInfo.map((item, idx) => {
			const thumbKey = this.getThumbKey(item);
			return (
				<LinkPageItem
					key={item.originId}
					item={item}
					thumbUrl={this.props.thumb[thumbKey] ? this.props.thumb[thumbKey].path : null}
					index={idx}
					moveItem={this.moveItem.bind(this)}
					moveEnd={this.moveEnd.bind(this)}
					deleteItem={this.deleteItem.bind(this)}
					uploadImg={this.uploadImg.bind(this)}
					bookId={this.props.bookId}
				/>
			)
		});
	}

	onChangeInput(e) {
		const value = e.target.name === "inputPage" ? Number(e.target.value) : e.target.value;
		this.setState({
			[e.target.name]: value,
		})
	}

	onClickAdd() {
		const {inputId, inputPage} = this.state;
		const {pageData} = this.props;
		const currentPageData = pageData[inputPage];
		if (!currentPageData || !currentPageData.objects) return;

		let data = currentPageData.objects.subView.find(item => item.id === inputId);
		if (!data) currentPageData.objects.videoLink.find(item => item.id === inputId);
		if (!data) return;

		let linkPageInfo = [];
		if (checkNested(this.props, "item.options.linkPageInfo")) {
			linkPageInfo = [...this.props.item.options.linkPageInfo];
			if (linkPageInfo.findIndex((val) => val.originId === data.originId) !== -1) return;
			if (linkPageInfo.length > 9) return;
		}
		linkPageInfo.push({
			id: inputId,
			page: inputPage,
			originId: data.originId,
			// thumb: item.thumbKey || "",
		});
		this.updateLinkPages(linkPageInfo);
	}

	render() {
		return (
			<>
				<div className={"inner-list"}>
					{/*
					<div className={"drop-zone"}>
						{this.getLinkPage(this.state.linkPageInfo)}
					</div>
					*/}
					<DropZone
						accept={dragType.LINK_PAGES}
						onDrop={this.onDrop.bind(this)}
					>
						{this.getLinkPage(this.state.linkPageInfo)}
					</DropZone>
				</div>
				<div className={"input-div"}>
					<div className={"input-contents"}>
						<span className={"input-title"}>ID</span>
						<input
							className={"input-text"}
							type={"text"}
							value={this.state.inputId}
							name={"inputId"}
							onChange={this.onChangeInput.bind(this)}
						/>
					</div>
					<div className={"input-contents"}>
						<span className={"input-title"}>ページ</span>
						<input
							className={"input-text"}
							type={"number"}
							value={this.state.inputPage}
							name={"inputPage"}
							onChange={this.onChangeInput.bind(this)}
						/>
					</div>
					<div className={"input-contents"}>
						<input
							className={"add-btn"}
							type={"button"}
							value={"追加"}
							onClick={this.onClickAdd.bind(this)}
						/>
					</div>
				</div>
			</>
		);
	}
}

LinkPage.defaultProps = {
	item: {},
	index: 0,
	mode: "linkPages",
	repo: {},
	thumb: {},
};

LinkPage.propTypes = {
	item: PropTypes.object.isRequired,
	index: PropTypes.number.isRequired,
	mode: PropTypes.string.isRequired,
	repo: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkPage);
