import React, { Component } from 'react';
import { connect } from 'react-redux'

import {
	changeBookDataKey,
	changeBookData,
	removeRect,
	copyRect,
	pasteRect,
	answerCopyPaste,
	cutRect,
	undo
} from '../../../../reducers/CombineReducers/pageData';
import {togglePanel} from "../../../../reducers/CombineReducers/panel";
import { setSelectItem, unsetSelectItem, addLog } from '../../../../reducers/CombineReducers/current';
import { saveLogFile } from "../../../../interface/api";
import Rect from '../../../../components/Contents/Editor/Panels/EditorPanel/Rect';
import {
	contents,
	modalMsg,
	modalTitle,
	keyAction,
	keyModel,
	dataModeConst, subjectKeyName,
} from "../../../../interface/constant";
import { objectModeConst } from '../../../../interface/constant';
import Modal from "../../../../components/Common/Modal";
import { checkNested, now } from "../../../../interface/utils";

const {ORDER_CHANGE, ORDER_CANCEL, ORDER_COPY, ORDER_CUT, ORDER_DELETE, ORDER_PASTE, ORDER_UNDO, ORDER_PASTE_ANSWER_ICON} = keyAction;

const mapStateToProps = (state) => {
	const {editor, book} = state.current;
	const objMode = objectModeConst[editor.objectMode];
	return {
		isIdOn: editor.isIdOn,
		mode: editor.mode,
		selectedId: editor.selected.id,
		selectedPage: editor.selected.page,
		pages: editor.pages,
		bookReady: book.ready,
		scale: editor.scale,
		leftObj: state.pageData[editor.pages.left].objects[objMode] || [],
		rightObj: state.pageData[editor.pages.right].objects[objMode] || [],
		view: state.metaData.view || {width: 0, height: 0},
		objectMode: editor.objectMode,
		subject: state.metaData.subject,
		contents: state.current.contents,
		pageViewType: state.current.editor.pageViewType,
		direction: state.metaData.direction,
		csvImport: state.current.csvImport,
		panel: state.panel,
		bookId: state.metaData.bookId,
		auth: state.auth,
		objMode
	}
};

const mapDispatchToProps = {
	changeBookData,
	changeBookDataKey,
	setSelectItem,
	unsetSelectItem,
	removeRect,
	copyRect,
	pasteRect,
	answerCopyPaste,
	cutRect,
	undo,
	togglePanel,
	addLog,
};

export function getObjects(obj, objectMode) {
	switch (objectMode) {
		case "icon": {
			return obj.filter(item => item.data && item.data.class && item.data.class.includes("icon"));
		}
		case "myEditor": {
			return obj.filter(item => item.hasOwnProperty("editor"));
		}
		case "check": {
			return obj.filter(item => item.data && item.data.class && item.data.class.includes("check1"));
		}
		case "subView": {
			return obj.filter(item => item.data && !item.data.class.includes("editor-only"));
		}
		default: {
			return obj;
		}
	}
}

let keyDownFunc = null;

class ObjectLayer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModalDelete: false,
			isResizing: false,
		};
		this.dragStartData = null;
		this.deleteProcess = null;
		this.rerender = false;
	}

	shouldComponentUpdate(nextProps, nextState) {
		return nextProps.bookReady;
	}

	componentDidMount() {
		keyDownFunc = (e) => this.keyEvent(this.props.selectedPage, this.props.selectedId).onKeyDown(e);
		document.addEventListener(
			"keydown",
			keyDownFunc,
			false
		);
	}

	componentWillUnmount() {
		document.removeEventListener(
			"keydown",
			keyDownFunc,
			false
		);
	}

	keyEvent(page, id) {
		const {objMode, direction} = this.props;
		return {
			onKeyDown: (e) => {
				if (this.props.panel.screen) return;
				if (this.props.contents !== contents.editor) return;
				if (e.keyCode >= 112 && e.keyCode <= 123) return;	//「F1～F12」KeyならEventを実行しない
				e.preventDefault();
				let which = e.keyCode + (e.shiftKey ? "+shift" : (e.ctrlKey ? "+ctrl" : (e.altKey ? "+alt" : "")));
				if (!Object.keys(keyModel).includes(which)) return;
				let {order, target, val} = keyModel[which];
				if (order === ORDER_PASTE) {
					this.props.pasteRect({page: this.props.pages.left, objMode, direction});
				} else if (order === ORDER_UNDO) {
					this.rerender = true;
					if (this.props.selectedId) this.props.unsetSelectItem();
					this.props.undo();
					this.rerender = false;
				}

				if (!id) return;	// UNDOとPASTE以外は全部selctedIdがないなら実行しない

				if (order === ORDER_CHANGE) {
					this.props.changeBookDataKey({id, page, target, val, objMode});
					//this.props.setSelectItem({id});
				} else if (order === ORDER_CANCEL) {
					this.props.unsetSelectItem();
				} else if (order === ORDER_DELETE) {
					this.showDeleteModal(id, page, objMode);
				} else if (order === ORDER_COPY) {
					this.props.copyRect({id, page, objMode, direction});
				} else if (order === ORDER_CUT) {
					this.props.cutRect({id, page, objMode, direction});
				} else if (order === ORDER_PASTE_ANSWER_ICON && this.props.subject === subjectKeyName.sansu) {
					this.props.answerCopyPaste({id, page, objMode});
				}
			}
		}
	}

	dragEvent(page, id, width, scale) {
		const {objMode} = this.props;
		return {
			onDragStart: (e, data) => {
				e.stopPropagation();
				if (this.props.panel[dataModeConst.EDIT_SUBVIEW_SVG]) return;
				this.dragStartData = data;
				if (id !== this.props.selectedId) this.props.setSelectItem({page, id});
			},
			onDrag: (e, data) => {
				e.stopPropagation();
				if (this.props.panel[dataModeConst.EDIT_SUBVIEW_SVG]) return;
			},
			onDragStop: (e, data) => {
				e.stopPropagation();
				if (this.props.panel[dataModeConst.EDIT_SUBVIEW_SVG]) return;
				const xDiff = Math.abs(this.dragStartData.x - data.x);
				const yDiff = Math.abs(this.dragStartData.y - data.y);
				if (xDiff < 1 && yDiff < 1) return;
				let css = {
					top: Math.round(data.y / scale * 100) / 100,
					left: Math.round((data.x - width) / scale * 100) / 100,
				};
				this.props.changeBookData({id, page, css, objMode});
				this.dragStartData = {};
			},
		}
	};

	resizeEvent(page, id, scale) {
		const {objMode} = this.props;
		return {
			onResizeStart: e => {
				e.stopPropagation();
				if (this.props.panel[dataModeConst.EDIT_SUBVIEW_SVG]) return;
				this.setState({
					...this.state,
					isResizing: true,
				});
			},
			onResize: (e, dir, ref) => {
				e.stopPropagation();
				if (this.props.panel[dataModeConst.EDIT_SUBVIEW_SVG]) return;
			},
			onResizeStop: (e, dir, ref, delta, position) => {
				e.stopPropagation();
				if (this.props.panel[dataModeConst.EDIT_SUBVIEW_SVG]) return;
				let {width, height} = ref.style;
				const isRightPage = this.props.selectedPage === this.props.pages.right;
				const addWidth = isRightPage ? this.props.view.width / 2 : 0;
				let css = {
					width: Math.round(parseFloat(width) / scale * 100) / 100,
					height: Math.round(parseFloat(height) / scale * 100) / 100,
					top: Math.round(parseFloat(position.y) / scale * 100 / 100),
					left: Math.round(parseFloat(position.x) / scale * 100 / 100) - addWidth,
				};
				this.props.changeBookData({id, page, css, objMode});
				this.setState({
					...this.state,
					isResizing: false,
				});
			},
		};
	}

	setObjectModeClass() {
		let addClass = "";
		if (this.props.objectMode === "redLine") {
			addClass = " redLine-small";
		} else if (this.props.objectMode === "form") {
			addClass = " form";
		}
		return addClass;
	}

	rectElem(width, page, item, currentViewPage) {
		const scale = this.props.scale;
		const isRectInActivePage = page === currentViewPage || currentViewPage === 0;
		const isForm = this.props.objectMode === "form";
		const canDragging = isRectInActivePage;
		const selected = (canDragging && this.props.selectedId === item.originId) ? " selected" : "";
		const canResizing = selected && !["icon", "videoLink", "pageLink"].includes(this.props.objectMode) && !isForm;
		const videoResizing = ["videoLink", "pageLink"].includes(this.props.objectMode) && (!item.data.class || !item.data.class.includes("icon"));
		const itemHasClass = (item.hasOwnProperty("data") && item.data.hasOwnProperty("class"));
		const objectModeClass = this.setObjectModeClass();
		const addClass = ` ${this.props.subject + objectModeClass}` + (itemHasClass ? ` ${item.data.class}` : "");
		const formValue = this.props.objectMode === "form" && item ? item.data.descr : "";
		let zIndex = 0;
		if (this.props.objectMode !== "audioLink") {
			let hasZIndex = checkNested(item, "options.link.zIndex");
			zIndex = hasZIndex ? item.options.link.zIndex : 0;
		}
		return (
			<Rect
				key={`${page}_${item.id}`}
				dragEvent={this.dragEvent(page, item.originId, width, scale)}
				resizeEvent={this.resizeEvent(page, item.originId, scale)}
				item={item}
				isIdOn={this.props.isIdOn}
				bounds={"#ObjectLayer"}
				contents={this.props.contents}
				isRectInActivePage={isRectInActivePage}
				position={{
					x: item.css.left * scale + width,
					y: item.css.top * scale,
				}}
				size={{
					width: item.css.width * scale,
					height: item.css.height * scale
				}}
				scale={scale}
				canDragging={canDragging}
				canResizing={canResizing || videoResizing}
				addClass={addClass}
				selected={selected}
				zIndex={zIndex}
				rerender={this.rerender}
				csvImport={this.props.csvImport}
				isForm={isForm}
				defaultFormSize={13}
				isResizing={this.state.isResizing}
				formValue={formValue}
			/>
		);
	}

	getCurrentViewPage() {
		const {pageViewType} = this.props;
		const {left: leftPage, right: rightPage} = this.props.pages;
		if (pageViewType === "left") {
			return leftPage;
		} else if (pageViewType === "right") {
			return rightPage;
		} else {
			return 0;
		}
	}

	toggleModal(type = "Delete", show = true) {
		let modalType = `showModal${type}`;
		this.setState({
			...this.state,
			[modalType]: show,
		})
	}

	showDeleteModal(id, page, objMode) {
		this.deleteProcess = (e) => {
			e.stopPropagation();
			this.props.unsetSelectItem();
			this.props.removeRect({id, page, objMode});
			this.props.addLog({msg:{regDate:`${now()}`, userId:`${this.props.auth.info.id}`, page:`${page}`, objectId:`${id}`, action: `削除`, type: "クリックポイント"}});
			this.toggleModal("Delete", false);
			this.deleteProcess = () => {};
		};
		this.toggleModal("Delete", true);
	}

	modalCancel() {
		this.toggleModal("Delete", false);
		this.deleteProcess = () => {};
	}

	onMouseDown() {
		if (this.props.panel[dataModeConst.EDIT_SUBVIEW_SVG]) {
			this.props.togglePanel({
				name: dataModeConst.EDIT_SUBVIEW_SVG,
				isOpen: !this.props.panel[dataModeConst.EDIT_SUBVIEW_SVG],
			});
		}
		this.props.unsetSelectItem();
	}

	render() {
		const {leftObj, objectMode, rightObj, pageViewType} = this.props;
		const widthHalf = this.props.view.width * this.props.scale / 2;

		const {left: leftPage, right: rightPage} = this.props.pages;
		const leftObjects = getObjects(leftObj, objectMode);
		const rightObjects = getObjects(rightObj, objectMode);
		const currentViewPage = this.getCurrentViewPage();
		return (
			<div
				id="ObjectLayer"
				className={this.props.mode !== "group" && this.props.mode !== "guideMode" ? "" : "hide"}
				onMouseDown={this.onMouseDown.bind(this)}
			>
				<div className={"leftHideLayer" + (pageViewType !== "right" ? " hide" : "")}/>
				<div className={"rightHideLayer" + (pageViewType !== "left" ? " hide" : "")}/>
				{leftObjects.map(item => this.rectElem(0, leftPage, item, currentViewPage))}
				{rightObjects.map(item => this.rectElem(widthHalf, rightPage, item, currentViewPage))}
				{this.state.showModalDelete && <Modal
					onClickCancel={this.modalCancel.bind(this)}
					onClickConfirm={(e) => this.deleteProcess(e)}
					msg={modalMsg.DELETE}
					title={modalTitle.WARNING}
				/>}
			</div>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ObjectLayer);
