import React, {Component} from 'react';
import {connect} from "react-redux";
import {SubScrollTable} from "../../../components/Contents/Editor/Panels/SubviewPanel/innerSubview";
import {objectModeConst} from "../../../interface/constant";
import {changeRectData, changeRectReferParam, deleteRectReferParam} from "../../../reducers/CombineReducers/pageData";
import ReactDOM from 'react-dom';

const mapStateToProps = state => {
	const {id, page} = state.current.editor.selected;
	const {editor} = state.current;
	const objMode = objectModeConst[editor.objectMode];
	let props = {};
	if (id && page) {
		let objectList = state.pageData[page].objects[objMode];
		const idx = objectList.findIndex((item) => item.originId === id);
		const subViewSettings = editor.objectMode === "subView" ? objectList[idx].data.subViewSettings : null;
		props = {
			selectedId: id,
			selectedPage: page,
			bookId: state.metaData.bookId,
			direction : state.metaData.direction,
			subject: state.metaData.subject,
			subViewObj: state.pageData[page].objects["subView"],
			subViewSettings,
		}
	}

	return props;
};

const mapDispatchToProps = {
	changeRectData,
	changeRectReferParam,
	deleteRectReferParam,
};

class SubScroll extends Component {
	constructor(props){
		super(props);
		this.state = {
			selectedValue: "",
		}
	}

	subViewSettingEvent() { 
		return {
			setScrollAlign: (e) => {
				//scorll 체크박스 체크 이벤트
				const {subViewSettings} = this.props;
				const scrollInfo = subViewSettings.options.scrollInfo || {};
				scrollInfo.align = e.target.name === scrollInfo.align ? "" : e.target.name;
				scrollInfo.position = "0";

				const data = {
					subViewSettings: {
						...subViewSettings,
						options: {
							...subViewSettings.options,
							scrollInfo: scrollInfo,
						},
					}
				};
				this.initScroll();
				this.props.changeRectData({
					id: this.props.selectedId,
					page: this.props.selectedPage,
					data: data,
					objMode: "subView",
				});
			},
			selectBoxChange: (e) => {
				// 지면 클릭포인트를 선택 할 selectBox 이벤트
				const value = e.target.value;
				this.setState({
					selectedValue: value,
				})
			},
			addScrollList: (e) => {
				// 지면 클릭포인트 추가버튼 이벤트
				const {subViewSettings, subViewObj} = this.props;
				if (!subViewSettings || !subViewSettings.options || !subViewSettings.options.scrollInfo || !this.state.selectedValue) return;
				const initPosition = 0;

				const selectedSubView = subViewObj.find((item) => item.originId === this.state.selectedValue);

				const scrollObj = {
					originId: this.state.selectedValue,
					id: selectedSubView.id,
					position: initPosition,
				};

				const scrollList = subViewSettings.options.scrollInfo.scrollList;
				let addScrollList = [scrollObj];
				if (scrollList) {
					if (scrollList.find((item) => item.originId === this.state.selectedValue)) return;

					addScrollList = scrollList.concat(scrollObj);
				}

				this.changeScrollList(addScrollList);

				this.props.changeRectReferParam({
					id: this.state.selectedValue,
					page: this.props.selectedPage,
					referId: this.props.selectedId,
					param: initPosition,
					htmlPath: "",
					previousRectId: "",
				});

			},
			onClickScrollInput: (e) => {
				// 추가한 지면 클릭포인트의 input창 클릭했을 때 이벤트
				const {subViewSettings} = this.props;
				if (!subViewSettings || !subViewSettings.options || !subViewSettings.options.scrollInfo) return;

				const type = subViewSettings.options.scrollInfo.align;
				const position = Number(e.target.value);
				this.setScrollNode(type, position);
			},
			onChangeScrollInput: (e, idx) => {
				// 추가한 지면 클릭포인트의 input창 수치 변경 시 이벤트
				const {subViewSettings} = this.props;
				if (!subViewSettings || !subViewSettings.options || !subViewSettings.options.scrollInfo) return;

				const scrollList = subViewSettings.options.scrollInfo.scrollList;
				if (!scrollList[idx]) return;

				const position = Number(e.target.value);

				this.props.changeRectReferParam({
					id: scrollList[idx].originId,
					page: this.props.selectedPage,
					referId: this.props.selectedId,
					param: position,
					htmlPath: "",
					previousRectId: "",
				});

				scrollList[idx].position = position;

				this.changeScrollList(scrollList);

				const type = subViewSettings.options.scrollInfo.align;
				this.setScrollNode(type, position);
			},
			onClickScrollDelete: (e, idx) => {
				// 추가한 지면 클릭포인트 삭제버튼 이벤트
				const {subViewSettings} = this.props;
				if (!subViewSettings || !subViewSettings.options || !subViewSettings.options.scrollInfo) return;

				let scrollList = subViewSettings.options.scrollInfo.scrollList;

				if (!scrollList[idx]) return;

				this.props.deleteRectReferParam({
					id: scrollList[idx].originId,
					page: this.props.selectedPage,
				});

				scrollList.splice(idx, 1);

				this.changeScrollList(scrollList);

			},
		} 
	}

	initScroll () {
		const node = ReactDOM.findDOMNode(this).parentNode.parentNode.parentNode;
		const subview = node.getElementsByClassName("subview-iframe");
		subview[0].scrollLeft = 0;
		subview[0].scrollTop = 0;
	}

	setScrollNode(type, position) {
		const node = ReactDOM.findDOMNode(this).parentNode.parentNode.parentNode;
		const subview = node.getElementsByClassName("subview-iframe");

		if (type === "vertical") {
			subview[0].scrollTop = position;
		} else if (type === "horizontal") {
			subview[0].scrollLeft = position;
		}
	}

	changeScrollList(scrollList) {
		const {subViewSettings} = this.props;
		const data = {
			subViewSettings: {
				...subViewSettings,
				options: {
					...subViewSettings.options,
					scrollInfo: {
						...subViewSettings.options.scrollInfo,
						scrollList: scrollList,
					},
				},
			}
		};

		this.props.changeRectData({
			id: this.props.selectedId,
			page: this.props.selectedPage,
			data: data,
			objMode: "subView",
		});
	}

	render() {
		const {scrollInfo} = this.props;
		const scrollList = (scrollInfo && scrollInfo.scrollList) ? scrollInfo.scrollList : [];
		return (
			<SubScrollTable
				commonMenuEvent={this.props.commonMenuEvent}
				currentImage={this.props.currentImage}
				imageCss={this.props.imageCss}
				scrollInfo={scrollInfo}
				scrollList={scrollList}
				subViewObj={this.props.subViewObj}
				selectedId={this.props.selectedId}
				subScrollEvent={this.subViewSettingEvent()}
			/>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SubScroll);