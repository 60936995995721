import React, {Component} from 'react';
import {connect} from "react-redux";
import {SubMovePageTable} from "../../../components/Contents/Editor/Panels/SubviewPanel/innerSubview";
import {objectModeConst, subViewRect} from "../../../interface/constant";
import {changeRectData, changeRectDataSvg} from "../../../reducers/CombineReducers/pageData";
import {togglePanel} from "../../../reducers/CombineReducers/panel";
import {modifyRepoPath} from "../../../reducers/CombineReducers/repo";
import {randomStr} from "../../../interface/utils";

const mapStateToProps = state => {
	const {id, page, blockId} = state.current.editor.selected;
	const {editor} = state.current;
	const objMode = objectModeConst[editor.objectMode];
	let props = {};
	if (id && page) {
		let objectList = state.pageData[page].objects[objMode];
		const idx = objectList.findIndex((item) => item.originId === id);
		const subViewSettings = editor.objectMode === "subView" ? objectList[idx].data.subViewSettings : null;
		props = {
			selectedId: id,
			selectedPage: page,
			bookId: state.metaData.bookId,
			subViewSettings,
			idx,
			repoImage: state.repo.image,
			view: state.metaData.view,
			selectedBlockId: blockId
		}
	}

	return props;
};

const mapDispatchToProps = {
    changeRectData,
    changeRectDataSvg,
    togglePanel,
	modifyRepoPath,
};

const ADD_OFFSET = 10;
let subViewLinkOffsetX = ADD_OFFSET;
let subViewLinkOffsetY = ADD_OFFSET;

class SubMovePage extends Component {
	subViewSettingEvent() {
		return {
            addLink: () => {
                const {subViewSettings} = this.props;
                let subMovePage = subViewSettings.options.subMovePage || [];
                let scrollTop = 0;
                let scrollLeft = 0;
                console.log(this.props.scrollLevel);
                if (this.props.scrollLevel) {
                    let isHorizon = subViewSettings.options.scrollType === "h";
                    console.log(subViewSettings);
                    if(subViewSettings.type === 14){
                        isHorizon = true;
                    }

                    console.log(isHorizon);
                    scrollTop = isHorizon ? 0 : this.props.view.height * this.props.scrollLevel;
                    scrollLeft = isHorizon ? this.props.view.width * this.props.scrollLevel : 0;
                }
                const defaultBlock = {
                    id: "subMovePage-" + randomStr(8),
                    rectId: "",
                    width: 100,
                    height: 100,
                    left: subViewLinkOffsetX + scrollLeft,
                    top: subViewLinkOffsetY + scrollTop
                };

                if(subViewLinkOffsetY < 580){
                    subViewLinkOffsetX += ADD_OFFSET;
                    subViewLinkOffsetY += ADD_OFFSET;
                }else{
                    subViewLinkOffsetX = subViewLinkOffsetX-560;
                    subViewLinkOffsetY = subViewLinkOffsetY-580;
                }

                const data = {
                    subViewSettings: {
                        ...subViewSettings,
                        options: {
                            ...subViewSettings.options,
                            subMovePage: subMovePage.concat(defaultBlock),
                        },
                    }
                };

                this.props.changeRectData({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    data: data,
                    objMode: "subView",
                });
            },
			update: (e, idx) => {
                let subMovePage = this.props.subViewSettings.options.subMovePage;
                subMovePage[idx][e.target.name] = e.target.value;

                const data = {
                    subViewSettings: {
                        ...this.props.subViewSettings,
                        options: {
                            ...this.props.subViewSettings.options,
                            subMovePage: subMovePage,
                        },
                    }
                };

				this.props.changeRectData({
					id: this.props.selectedId,
					page: this.props.selectedPage,
					data: data,
					objMode: "subView",
				});
			}
		}
	}

	render() {
		const {subViewSettings, selectedBlockId} = this.props;
		const subMovePage = subViewSettings.options.subMovePage;
		const subViewLinkIdx = subMovePage ? subMovePage.findIndex((item) => item.id === selectedBlockId) : -1;
		let menuHide = "hide", data = {};

		if (subViewLinkIdx > -1) {
			data = subMovePage[subViewLinkIdx];
			menuHide = "";
		}

        const svgData = {
            changeRectDataSVG: this.props.changeRectDataSvg,
            togglePanel: this.props.togglePanel,
            item: subViewLinkIdx >= 0 ? subMovePage[subViewLinkIdx] : {},
            panel: this.props.panel,
            id: this.props.selectedId,
            page: this.props.selectedPage,
            blockId: selectedBlockId,
            objMode: "subview",
            mode: this.props.mode,
            svg: subViewLinkIdx >= 0 && subMovePage[subViewLinkIdx].svg ? subMovePage[subViewLinkIdx].svg.attr.points : "",
            subViewType: subViewRect.subMovePage
        };

		return (
			<SubMovePageTable
				event={this.subViewSettingEvent()}
				data={data}
				bookId={this.props.bookId}
				svgData={svgData}
				menuHide={menuHide}
				subViewLinkIdx={subViewLinkIdx}
			/>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SubMovePage);