import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from "prop-types";
import {propertyInputLabel, propertyToolTip} from "../../../../interface/constant";
import * as Inner from "../../../../components/Contents/Editor/Panels/PropertyPanel/innerProperty";
import PosSizeProperty from "../../../../components/Contents/Editor/Panels/PropertyPanel/posSizeProperty";
import {
	changeBookData,
	changeRectId,
	changeRectPage,
	changeRectData,
	changeRectOptions,
} from '../../../../reducers/CombineReducers/pageData';
import {objectModeConst, propertyInputName} from '../../../../interface/constant';
import {setSelectItem} from "../../../../reducers/CombineReducers/current";

const mapStateToProps = (state) => {
	const objMode = objectModeConst[state.current.editor.objectMode];
	let props = {
		pages: state.current.editor.pages,
		view: state.metaData.view,
		bookId: state.metaData.bookId,
		subject: state.metaData.subject,
		direction: state.metaData.direction,
		repo: state.repo,
		objMode
	};
	let {id, page} = state.current.editor.selected;
	if (id && page) {
		let objectInfo = state.pageData[page].objects[objMode];
		props.item = objectInfo.filter((item) => item.originId === id)[0];
		props.id = id;
		props.page = page;
	}
	return props;
};

const mapDispatchToProps = {
	changeBookData,
	changeRectId,
	changeRectPage,
	changeRectData,
	changeRectOptions,
	setSelectItem,
};


const DEFAULT_STATE = {
	id: "",
	type: 0,
	propertyCss: {
		width: "",
		height: "",
		top: "",
		left: "",
	},
};

let property = {
	currentId: "",
	changedId: "",
	page: 0,
	objMode: "",
};

class RestudyTable extends Component {
	constructor(props) {
		super(props);
		this.state = DEFAULT_STATE;
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps === prevState.prevProps) return null;
		const propertyCss = RestudyTable.arrangeCss(prevState.propertyCss, nextProps.item.css);
		return {
			propertyCss,
			prevProps: nextProps,
		}
	}

	static arrangeCss(prevCss, nextCss) {
		return {
			...nextCss,
			width: prevCss.width === "" ? prevCss.width : nextCss.width,
			height: prevCss.height === "" ? prevCss.height : nextCss.height,
			top: prevCss.top === "" ? prevCss.top : nextCss.top,
			left: prevCss.left === "" ? prevCss.left : nextCss.left,
		}
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.props.item.id !== prevProps.item.id) {
			const id = this.props.item.id || "";
			const propertyCss = this.props.item.css;
			this.setState({
				id: id,
				propertyCss
			})
		}
	}

	componentWillUnmount() {
		this.setState(DEFAULT_STATE);
	}

	onChange = (e) => {
		this.setState({
			...this.state,
			propertyCss: {
				...this.state.propertyCss,
				[e.target.name]: e.target.value,
			}
		});

		const value = e.target.value || 0;

		this.props.changeBookData({
			id: this.props.id,
			page: this.props.page,
			css: {
				[e.target.name]: (e.target.name !== 'opacity' ? parseInt(value, 10) : Number(value))
			},
			objMode: this.props.objMode
		});
	};

	pageChange = (e) => {
		let page = Number(e.target.value);
		this.props.changeRectPage({
			id: this.props.id,
			page: page,
			width: this.props.view.width / 2,
			objMode: this.props.objMode,
			direction: this.props.direction,
		});
		this.props.setSelectItem({
			id: this.props.id,
			page: page
		});
	};

	dataChange = (e) => {
		let value = e.target.value;
		if (e.target.name === propertyInputName.restudyPage) value = Number(value);
		const data = {
			...this.props.item.data,
			book: {
				...this.props.item.data.book,
				[e.target.name]: value,
			}
		};
		this.props.changeRectData({
			id: this.props.id,
			page: this.props.page,
			data: data,
			objMode: this.props.objMode,
		});
	};

	optionsChange = (e) => {
		let {page, objMode, item} = property;
		const itemOptions = Object.keys(this.props.item).length !== 0 ? this.props.item.options : item.options;
		let options = this.props.setOptionsValue(e.target, itemOptions);
		this.props.changeRectOptions({
			id: this.props.id,
			page: this.props.page || page,
			options: {
				[options.name]: options.value
			},
			objMode: this.props.objMode || objMode,
		});
	};

	eventHandler() {
		return {
			onChange: this.onChange,
			pageChange: this.pageChange,
			dataChange: this.dataChange,
			optionsChange: this.optionsChange,
		}
	}

	setPropertyId(e) {
		property.currentId = this.props.id;
		property.changedId = e.target.value;
		property.page = this.props.page;
		property.objMode = this.props.objMode;
	}

	changeId(e) {
		this.setState({
			id: e.target.value
		});
		property.changedId = e.target.value;
	}

	changePageDataId() {
		let {currentId, changedId, page, objMode} = property;
		this.props.changeRectId({
			currentId: currentId,
			changedId: changedId,
			page: page,
			objMode: objMode
		});
	}

	changeRectData(data) {
		this.props.changeRectData({
			id: this.props.id,
			page: this.props.page,
			objMode: this.props.objMode,
			data,
		})
	}

	render() {
		const {id, page, pages, item} = this.props;
		const pageInfoOptions = this.props.pages ? [
			{val: pages.left, name: pages.left},
			{val: pages.right, name: pages.right}
		] : [];

		return (
			<div className="inner-table">
				<table>
					<tbody>
					<Inner.TextProperty
						label={propertyInputLabel.id}
						name={propertyInputName.id}
						val={this.state.id}
						readOnly={false}
						onFocus={this.setPropertyId.bind(this)}
						onBlur={this.changePageDataId.bind(this)}
						onChange={this.changeId.bind(this)}
						tooltipText={propertyToolTip.id}
					/>
					<Inner.SelectProperty
						label={propertyInputLabel.page}
						name={propertyInputName.page}
						options={pageInfoOptions}
						eventHandler={this.pageChange} val={page}
						tooltipText={propertyToolTip.page}
					/>
					<Inner.NumberProperty
						label={propertyInputLabel.zIndex}
						name={propertyInputName.zIndex}
						val={item.options.link ? item.options.link.zIndex || "" : ""}
						onChange={this.eventHandler().optionsChange}
						tooltipText={propertyToolTip.zIndex}
					/>
					<PosSizeProperty
						css={this.state.propertyCss}
						eventHandler={this.eventHandler()}
					/>
					<Inner.TextProperty
						label={propertyInputLabel.restudyBookName}
						name={propertyInputName.restudyBookName}
						val={item.data.book ? item.data.book.setName : ""}
						onChange={this.eventHandler().dataChange}
					/>
					<Inner.TextProperty
						label={propertyInputLabel.restudySemester}
						name={propertyInputName.restudySemester}
						val={item.data.book ? item.data.book.name : ""}
						onChange={this.eventHandler().dataChange}
					/>
					<Inner.NumberProperty
						label={propertyInputLabel.restudyPage}
						name={propertyInputName.restudyPage}
						val={item.data.book ? item.data.book.page : ""}
						onChange={this.eventHandler().dataChange}
					/>
					</tbody>
				</table>
				<div className="inner-mask" style={{display: id ? "none" : ""}}/>
			</div>
		)
	}
}

RestudyTable.defaultProps = {
	item: {
		originId: "",
		id: "",
		css: {},
		options: {},
		page: 0,
		data: {},
	},
	page: 0,
	id: "",
	repo: {},
};

RestudyTable.propTypes = {
	item: PropTypes.shape({
		originId: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		css: PropTypes.object.isRequired,
		options: PropTypes.object.isRequired,
		data: PropTypes.object.isRequired,
		page: PropTypes.number.isRequired
	}).isRequired,
	page: PropTypes.number.isRequired,
	id: PropTypes.string.isRequired,
	repo: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(RestudyTable);