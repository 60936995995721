import React, { Component } from 'react';
import { connect } from 'react-redux';

import { addRect } from "../../../../reducers/CombineReducers/pageData";
import { changeEditorMode, addLog } from "../../../../reducers/CombineReducers/current";
import { objectModeConst } from '../../../../interface/constant';
import {now, randomStr} from "../../../../interface/utils";


const mapStateToProps = (state) => {
	const editor = state.current.editor;
	const {left, right} = state.current.editor.pages;
	const objMode = objectModeConst[state.current.editor.objectMode];
	return {
		leftObjects: state.pageData[left].objects[objMode] || [],
		rightObjects: state.pageData[right].objects[objMode] || [],
		view: state.metaData.view || {width: 0, height: 0},
		pages: editor.pages,
		mode: editor.mode,
		scale:editor.scale,
		objectMode: editor.objectMode,
		pageViewType : editor.pageViewType,
		subject: state.metaData.subject,
		objMode,
		auth: state.auth,
	}
};

const mapDispatchToProps = dispatch => {
	return {
		addRect: (state) => dispatch(addRect(state)),
		addLog : (state) => dispatch(addLog(state)),
		changeEditorMode: (state) => dispatch(changeEditorMode(state)),
	}
};

class DrawLayer extends Component {
	constructor(props) {
		super(props);
		this.isDrawing = false;
		this.drawOrigin = {};
		this.layerOffset = {x: 0, y: 0};
		this.state = {
			cropArea: null,
			drawPage: 0,
		}
	}

	componentDidMount() {
		document.addEventListener(
			"keydown",
			(e) => this.keyDown(e),
			false
		);
	}

	drawStart(e, type = "subView") {
		if (this.isDrawing) {
			this.drawFinish(type);
		} else {
			const {view, pageViewType, pages} = this.props;
			this.isDrawing = true;
			this.layerOffset = e.target.getBoundingClientRect();
			const startX = e.pageX - this.layerOffset.x;
			const startY = e.pageY - this.layerOffset.y;
			this.drawOrigin = {
				x: startX,
				y: startY,
			};
			let isLeft = (startX < view.width / 2 || pageViewType === "left") && pageViewType !== "right";
			let drawPage = isLeft ? pages.left : pages.right;
			this.setState({
				...this.state,
				drawPage: drawPage,
			})
		}
	}

	drawing(e) {
		if (this.isDrawing) {
			let drawPosition = {
				x: e.pageX - this.layerOffset.x,
				y: e.pageY - this.layerOffset.y,
			};
			let cropArea = {
				left: Math.round(Math.min(this.drawOrigin.x, drawPosition.x) * 100) / 100,
				top: Math.round(Math.min(this.drawOrigin.y, drawPosition.y) * 100) / 100,
				width: Math.round(Math.abs(drawPosition.x - this.drawOrigin.x) * 100) / 100,
				height: Math.round(Math.abs(drawPosition.y - this.drawOrigin.y) * 100) / 100,
			};
			this.setState({
				...this.state,
				cropArea: cropArea,
			});
		}
	}

	drawFinish(type = "subView") {
		if (this.isDrawing) {
			const {scale, objectMode, objMode, pages, view, subject} = this.props;
			if (this.state.cropArea) {
				const {width, height, top, left} = this.state.cropArea;
				const page = this.state.drawPage;
				const cssLeft = (page === pages.right) ? view.width / 2 : 0;
				const css = {
					width: Math.round(width / scale * 100) / 100,
					height: Math.round(height / scale * 100) / 100,
					top: Math.round(top / scale * 100) / 100,
					left: Math.round(left / scale * 100) / 100 - cssLeft,
				};
				const idNumber = randomStr(8);
				const id =  `${page}-${idNumber}`;
				if (css.width > 10 && css.height > 10) this.props.addRect({css, page, objMode, objectMode, subject, id});
				this.drawExit(id, page);
			}
		}
	}

	drawExit(id, page) {
		this.isDrawing = false;
		this.drawOrigin = {};
		this.layerOffset = {
			x: 0, y: 0
		};
		this.setState({
			cropArea: null,
			drawPage: 0,
		});
		const currentTime = now();
		this.props.addLog({msg:{regDate:`${currentTime}`, userId:`${this.props.auth.info.id}`, page:`${page}`, objectId:`${id}`, action: `追加`, type: "クリックポイント"}});
		this.props.changeEditorMode({mode: "edit"});
	}

	cropArea(state) {
		return (
			<div
				className={"crop-area"}
				style={state.cropArea}
			/>
		)
	}

	keyDown(e) {
		if(this.props.mode !== "draw") return;
		if(e.keyCode === 27) {
			this.drawExit();
		}
	}

	render() {
		return (
			<div
				id="DrawLayer"
				onMouseDown={this.drawStart.bind(this)}
				onMouseMove={this.drawing.bind(this)}
				onMouseUp={this.drawFinish.bind(this)}
			>
				{this.state.cropArea ? this.cropArea(this.state) : ""}
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DrawLayer);
