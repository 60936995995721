import io from 'socket.io-client';

const PORT = window.location.port;
const HOST = window.location.host;

class SocketClient {
	constructor() {
		this.io = null;
	}

	init = (info, setUser) => {
		// connect to ws server
		const path = PORT === "4000" ?  "" : "/socket";
		this.io = io(`${HOST}`, {transports: ['websocket'], secure: true, path});

		this.io.on("getUserList", userList => {
			setUser(userList);
		});

		this.io.on("reconnect", () => {
			this.io.emit("userConnect", info ? info.id : "");
		});

		this.io.emit("userConnect", info ? info.id : "");
	};

	getBookList = (setBook) => {
		this.io.on("getBookList", (bookList) => {
			setBook(bookList);
		});
		this.io.emit("getBookList");
	};

	editStartBook = (bookId, user, lockPageList) => {
		this.io.emit("editStartBook", bookId, user, lockPageList);
	};

	setEditingPage = (userId, page, bookId) => {
		this.io.emit("setEditingPage", userId, page);

		this.io.off("reconnect").on("reconnect", () => {
			this.io.emit("userConnect", userId || "");
			this.io.emit("reconnectSetBookList", userId, page, bookId);
		});
	};

	setRepoSocket = (currentBookId, removeFromRepo, modifyRepoPath, modifyRepoMulti) => {
		this.io.on("updateRepo", (bookId, kind, repoData, btnType, userId) => {
			if (currentBookId === bookId) {
				if (btnType === "modifyMulti") {
					modifyRepoMulti({
						kind: kind,
						data: repoData,
						userId: userId,
					});
				} else if (btnType === "modify") {
					modifyRepoPath({
						...repoData,
						userId: userId,
					});
				} else if (btnType === "remove") {
					removeFromRepo({
						...repoData,
						userId: userId,
					});
				}
			}
		});
	};

	updateRepo = (bookId, kind, repoData, btnType, userId) => {
		this.io.emit("updateRepo", bookId, kind, repoData, btnType, userId);
	};
}

export default SocketClient;