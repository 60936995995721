import React, {Component} from 'react';
import {connect} from "react-redux";
import {SansuRestudyTable} from "../../../components/Contents/Editor/Panels/SubviewPanel/innerSubview";
import {changeRectData, changeRectDataBlock} from "../../../reducers/CombineReducers/pageData";
import {objectModeConst, subViewRect} from "../../../interface/constant";
import {randomStr} from "../../../interface/utils";

const mapStateToProps = state => {
    const {id, page, blockId} = state.current.editor.selected;
    const {editor} = state.current;
    const objMode = objectModeConst[editor.objectMode];
    let props = {};
    if (id && page) {
        let objectList = state.pageData[page].objects[objMode];
        const idx = objectList.findIndex((item) => item.originId === id);
        const subViewSettings = editor.objectMode === "subView" ? objectList[idx].data.subViewSettings : null;
        props = {
            selectedId: id,
            selectedPage: page,
            selectedBlockId: blockId,
            subViewSettings,
        }

    }
    return props;
};

const mapDispatchToProps = {
    changeRectData,
    changeRectDataBlock,
};

const ADD_OFFSET = 10;
let rectOffsetX = ADD_OFFSET;
let rectOffsetY = ADD_OFFSET;

class RikaBlock extends Component {
    subViewSettingEvent() {
        return {
            addRestudy: () => {
                const {subViewSettings} = this.props;
                let restudy = subViewSettings.options.restudy || [];
                const defaultRestudy = {
                    id: "restudy-" + randomStr(8),
                    width: 37,
                    height: 30,
                    left: rectOffsetX,
                    top: rectOffsetY,
                };

                if(rectOffsetY < 580){
                    rectOffsetX += ADD_OFFSET;
                    rectOffsetY += ADD_OFFSET;
                }else{
                    rectOffsetX = rectOffsetX-560;
                    rectOffsetY = rectOffsetY-580;
                }

                const data = {
                    subViewSettings: {
                        ...subViewSettings,
                        options: {
                            ...subViewSettings.options,
                            restudy: restudy.concat(defaultRestudy),
                        },
                    }
                };

                this.props.changeRectData({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    data: data,
                    objMode: "subView",
                });
            },
            changeRestudyCss: (e) => {
                const value = Number(e.target.value);
                const data = {
                    [e.target.name]: value
                };

                this.props.changeRectDataBlock({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    blockId: this.props.selectedBlockId,
                    data: data,
                    subViewType: subViewRect.sansuRestudy,
                });
            },
            changeRestudyData: (e) => {
                const value = e.target.name === "page" ? Number(e.target.value) : e.target.value;
                const data = {
                    [e.target.name]: value
                };

                this.props.changeRectDataBlock({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    blockId: this.props.selectedBlockId,
                    data: data,
                    subViewType: subViewRect.sansuRestudy,
                });
            }
        }
    }

    render() {
        const {subViewSettings, selectedBlockId} = this.props;
        const restudy = subViewSettings.options.restudy;
        const restudyIdx = restudy ? restudy.findIndex((item) => item.id === selectedBlockId) : -1;
        return (
            <SansuRestudyTable
                event={this.subViewSettingEvent()}
                subViewSettingsRestudy={ restudyIdx < 0 ? null : subViewSettings.options.restudy[restudyIdx]}
                tabMenu={this.props.tabMenu}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RikaBlock);