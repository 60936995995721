import React from 'react';
import CheckSheetItem from "../CheckSheetPanel/CheckSheetItem";
import {
    subMenuTitleOptions,
    subMenuIconOptions,
    rikaAnswerType,
    subViewRect,
    answerColorCode,
    propertyInputLabel,
    propertyInputName,
} from "../../../../../interface/constant"
import Select from "react-select";
import SvgSubView from "../SubviewPanel/SvgSubView";

export function CommonTable(props) {
    const {subViewSettings, defaultHide, commonMenuEvent} = props; //공통
    const {subViewTypeOptions} = props; //타입선택 selectBox props
    const {imageUpload, selectedId, bookId} = props; //일반 업로드 props
    const {setSubViewImageData, repoKey} = props; //레포지토리에서 업로드 props
    const {subViewImages, currentImageIdx} = props; //이미지 List props
    const {imageCss} = props; //이미지 css(width, height, left, top, margin) props
    const {isLargeView} = props;

    return (
        <tbody>
            <tr className={"subview-select-area"}>
                <td className={"input-area"}>
                    <div className={"input-text"}>タイプ</div>
                    <select className={"subview-type-select"}
                            value={subViewSettings ? subViewSettings.type : 0}
                            onChange={commonMenuEvent.subViewTypeChange}>
                        {subViewTypeOptions.map((options) => {
                            return (<option value={options.value} key={options.value}>{options.name}</option>);
                        })}
                    </select>
                </td>
            </tr>
            <tr>
                <td className={"subview-upload-area"}>
                    <label htmlFor={"upload"}>
                        <div className={"subview-buttons default-buttons " + defaultHide}>
                            <span className={"button-text"}>アップロード</span>
                        </div>
                    </label>
                </td>
            </tr>
            <tr>
                <td className={"subview-repo-area " + defaultHide}>
                    <div className={"subview-repo"}>
                        <input
                            type={"text"}
                            value={repoKey || ""}
                            onChange={commonMenuEvent.changeRepoKey}
                            className={"repoKey-inputText"}
                            placeholder={"画像ID"}
                        />
                    </div>
                    <div className={"subview-buttons default-buttons"}
                         onClick={() => setSubViewImageData(repoKey, -1)}
                    >
                        <span className={"button-text"}>レポジトリIDを登録</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td className={defaultHide}>
                    <div className={"image-list"}>
                        {subViewImages.map((item, idx) => {
                            return (
                                <p key={idx}
                                    className={currentImageIdx === idx ? "scroll-image-selected scroll-list-item" : "scroll-list-item"}
                                    onClick={() => commonMenuEvent.changeCurrentSubViewImages(idx)}>
                                    {item.imgPath}
                                    <label htmlFor={"change"}>
                                        <span
                                            className={"subview-buttons image-edit-button"}>
                                            変更
                                        </span>
                                    </label>
                                    <span
                                        className={"subview-buttons image-edit-button"}
                                        onClick={(e) => commonMenuEvent.deleteSubViewImage(e, idx)}>
                                        削除
                                    </span>
                                </p>
                            )
                        })}
                    </div>
                </td>
            </tr>
            <tr>
                <td className={"input-area " + defaultHide}>
                    <div className={"input-text"}>横</div>
                    <div className={"input-value"}>
                        <input
                            type={"number"}
                            name={"width"}
                            value={imageCss.width}
                            onChange={commonMenuEvent.subViewImageCssChange}
                        />
                    </div>
                </td>
            </tr>
            <tr>
                <td className={"input-area " + defaultHide}>
                    <div className={"input-text"}>縦</div>
                    <div className={"input-value"}>
                        <input
                            type={"number"}
                            name={"height"}
                            value={imageCss.height}
                            onChange={commonMenuEvent.subViewImageCssChange}
                        />
                    </div>
                </td>
            </tr>
            <tr>
                <td className={"input-area " + defaultHide}>
                    <div className={"input-text"}>X座標</div>
                    <div className={"input-value"}>
                        <input
                            type={"number"}
                            name={"left"}
                            value={imageCss.left}
                            onChange={commonMenuEvent.subViewImageCssChange}
                        />
                    </div>
                </td>
            </tr>
            <tr>
                <td className={"input-area " + defaultHide}>
                    <div className={"input-text"}>Y座標</div>
                    <div className={"input-value"}>
                        <input
                            type={"number"}
                            name={"top"}
                            value={imageCss.top}
                            onChange={commonMenuEvent.subViewImageCssChange}
                        />
                    </div>
                </td>
            </tr>
            <tr>
                <td className={defaultHide}>
                    <input type={"button"}
                           name={"width"}
                           value={"幅最大"}
                           onClick={commonMenuEvent.cssChecked}
                    />
                    <input type={"button"}
                           name={"height"}
                           value={"縦最大"}
                           onClick={commonMenuEvent.cssChecked}
                    />
                </td>
            </tr>
            <tr>
                <td>
                    <label>
                        <input
                            type={"checkBox"}
                            name={"isLargeView"}
                            checked={isLargeView}
                            onChange={commonMenuEvent.changeSubViewSize}
                        />
                        ラージビュー
                    </label>
                </td>
            </tr>
            <tr>
                <td className={"subview-delete-area"}>
                    <div className={"subview-buttons default-buttons"} onClick={commonMenuEvent.subViewDelete}>
                        <span className={"button-text"}>拡大画面削除</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td className={"hide"}>
                    <form method={"post"} encType={"multipart/form-data"}>
                        <input
                            type={"file"}
                            id={"upload"}
                            accept={".png"}
                            data-id={selectedId}
                            onChange={imageUpload}
                        />
                        <input type={"hidden"} name={"bookId"} defaultValue={bookId}/>
                    </form>
                    <form method={"post"} encType={"multipart/form-data"}>
                        <input
                            type={"file"}
                            id={"change"}
                            accept={".png"}
                            data-id={selectedId}
                            onChange={(e) => commonMenuEvent.changeSubViewImage(e)}
                        />
                        <input type={"hidden"} name={"bookId"} defaultValue={bookId}/>
                    </form>
                </td>
            </tr>
        </tbody>
    )
}

export function SubScrollTable(props) {
    const {currentImage, subScrollEvent, commonMenuEvent, imageCss, scrollInfo, scrollList, subViewObj, selectedId} = props;
    const colSpan = 2;

    return (
        <tbody>
        <tr>
            <td colSpan={colSpan}>
                <div className={"add-settings-text"}>余白設定</div>
            </td>
        </tr>
        <tr>
            <td className={"td-title-long"}>マージン：上</td>
            <td>
                <input
                    type={"number"}
                    name={"marginTop"}
                    value={currentImage ? currentImage.marginTop || "" : ""}
                    onChange={commonMenuEvent.subViewImageCssChange}
                />
            </td>
        </tr>
        <tr>
            <td className={"td-title-long"}>マージン：下</td>
            <td>
                <input
                    type={"number"}
                    name={"marginBottom"}
                    value={currentImage ? currentImage.marginBottom || "" : ""}
                    onChange={commonMenuEvent.subViewImageCssChange}
                />
            </td>
        </tr>
        <tr>
            <td className={"td-title-long"}>マージン：左</td>
            <td>
                <input
                    type={"number"}
                    name={"marginLeft"}
                    value={currentImage ? currentImage.marginLeft || "" : ""}
                    onChange={commonMenuEvent.subViewImageCssChange}
                />
            </td>
        </tr>
        <tr >
            <td className={"td-title-long"}>マージン：右</td>
            <td>
                <input
                    type={"number"}
                    name={"marginRight"}
                    value={currentImage ? currentImage.marginRight || "" : ""}
                    onChange={commonMenuEvent.subViewImageCssChange}
                />
            </td>
        </tr>
        <tr >
            <td className={"td-title-long"}>パディング：上</td>
            <td>
                <input
                    type={"number"}
                    name={"paddingTop"}
                    value={currentImage ? currentImage.paddingTop || "" : ""}
                    onChange={commonMenuEvent.subViewImageCssChange}
                />
            </td>
        </tr>
        <tr >
            <td className={"td-title-long"}>パディング：下</td>
            <td>
                <input
                    type={"number"}
                    name={"paddingBottom"}
                    value={currentImage ? currentImage.paddingBottom || "" : ""}
                    onChange={commonMenuEvent.subViewImageCssChange}
                />
            </td>
        </tr>
        <tr >
            <td className={"td-title-long"}>パディング：左</td>
            <td>
                <input
                    type={"number"}
                    name={"paddingLeft"}
                    value={currentImage ? currentImage.paddingLeft || "" : ""}
                    onChange={commonMenuEvent.subViewImageCssChange}
                />
            </td>
        </tr>
        <tr >
            <td className={"td-title-long"}>パディング：右</td>
            <td>
                <input
                    type={"number"}
                    name={"paddingRight"}
                    value={currentImage ? currentImage.paddingRight || "" : ""}
                    onChange={commonMenuEvent.subViewImageCssChange}
                />
            </td>
        </tr>
        <tr>
            <td colSpan={colSpan}>
                <label>
                    <input
                        type={"checkBox"}
                        name={"margin"}
                        checked={imageCss.margin}
                        onChange={currentImage ? commonMenuEvent.marginAutoCheck : () => {}}
                    />
                    中央揃え
                </label>
            </td>
        </tr>
        <tr>
            <td colSpan={colSpan}>
                <div className={"add-settings-text"}>スクロール設定</div>
            </td>
        </tr>
        <tr>
            <td className={"td-title-long"}>位置</td>
            <td>
                <label>
                    <input
                        type={"checkBox"}
                        name={"vertical"}
                        checked={scrollInfo.align === "vertical" ? true : false}
                        onChange={subScrollEvent.setScrollAlign}
                    />
                    縦
                </label>
                <label>
                    <input
                        type={"checkBox"}
                        name={"horizontal"}
                        checked={scrollInfo.align === "horizontal" ? true : false}
                        onChange={subScrollEvent.setScrollAlign}
                    />
                    横
                </label>
            </td>
        </tr>
        <tr>
            <td colSpan={colSpan}>
                <select
                    className={"rect-connect-select"}
                    onChange={subScrollEvent.selectBoxChange.bind(this)}
                >

                    <option value=''>{"紙面ID"}</option>
                    { subViewObj ? subViewObj.map((item) => {
                        return (
                            <option
                                key={item.originId}
                                disabled={item.originId === selectedId || item.refer || (item.data && item.data.param)}
                                value={item.originId}
                            >
                                {item.id}
                            </option>
                        )
                    }) : ""}
                </select>
                <input
                    type={"button"}
                    value={"追加"}
                    onClick={subScrollEvent.addScrollList.bind(this)}
                />
            </td>
        </tr>
        <tr>
            <td colSpan={colSpan}>
                <div className={"scroll-list"}>
                    {scrollList.map((item, idx) => {
                       return (
                           <p key={"scroll" + idx} className={"scroll-list-item"}>
                               {item.id}
                               <input
                                   type={"number"}
                                   className={"scroll-list-input"}
                                   value={item.position || 0}
                                   onClick={subScrollEvent.onClickScrollInput}
                                   onChange={(e) => subScrollEvent.onChangeScrollInput(e, idx)}
                               />
                               <span
                                   className={"subview-buttons scroll-image-button"}
                                   onClick={(e) => subScrollEvent.onClickScrollDelete(e, idx)}
                               >
                                   削除
                               </span>
                           </p>
                       )
                    })}
                </div>
            </td>
        </tr>
        </tbody>
    )
}

export function SubMovePageTable(props) {
    const {event, svgData} = props;
    const {subViewLinkIdx, data, menuHide} = props;
    const colSpan = 2;

    return (
        <tbody>
        <tr>
            <td colSpan={colSpan}>
                <input type={"button"} value={"ページ移動追加"} className={"block_btn"} onClick={event.addLink}/>
            </td>
        </tr>
        <tr>
            <td colSpan={colSpan}>
                <div className={"add-settings-text"}>ページ移動設定</div>
            </td>
        </tr>
        <tr className={menuHide}>
            <td className={"td-title-long"}>ページ番号</td>
            <td>
                <input
                    type={"text"}
                    name={"page"}
                    value={data.page ? data.page : ""}
                    onChange={(e) => event.update(e, subViewLinkIdx)}
                />
            </td>
        </tr>
        <SvgSubView
            changeRectDataSVG={svgData.changeRectDataSVG}
            togglePanel={svgData.togglePanel}
            item={svgData.item}
            panel={svgData.panel}
            id={svgData.id}
            page={svgData.page}
            blockId={svgData.blockId}
            objMode={svgData.objMode}
            mode={svgData.mode}
            svg={svgData.svg}
            subViewType={svgData.subViewType}
        />
        </tbody>
    )
}

export function SubPopupTable(props) {
    const {event, svgData, imageUpload} = props;
    const {subViewLinkIdx, selectedId, bookId, data, menuHide} = props;
    const colSpan = 2;

    return (
        <tbody>
        <tr>
            <td colSpan={colSpan}>
                <input type={"button"} value={"ポップアップ追加"} className={"block_btn"} onClick={event.addLink}/>
            </td>
        </tr>
        <tr>
            <td colSpan={colSpan}>
                <div className={"add-settings-text"}>ポップアップ設定</div>
            </td>
        </tr>
        <tr className={menuHide}>
            <td className={"subview-upload-area"} colSpan={colSpan}>
                <label htmlFor={"upload"}>
                    <div className={"subview-buttons default-buttons"}>
                        <span className={"button-text"}>アップロード</span>
                    </div>
                </label>
            </td>
        </tr>
        <tr className={menuHide}>
            <td className={"td-title-long"}>サムネールID</td>
            <td>
                <input
                    type={"text"}
                    name={"popupKey"}
                    value={data.popupKey ? data.popupKey : ""}
                    onChange={(e) => event.update(e, subViewLinkIdx)}
                />
            </td>
        </tr>
        <tr>
            <td className={"hide"}>
                <form method={"post"} encType={"multipart/form-data"}>
                    <input
                        type={"file"}
                        id={"upload"}
                        accept={".png"}
                        data-id={selectedId}
                        onChange={(e) => imageUpload(e, subViewLinkIdx)}
                    />
                    <input type={"hidden"} name={"bookId"} defaultValue={bookId}/>
                </form>
            </td>
        </tr>
        <SvgSubView
            changeRectDataSVG={svgData.changeRectDataSVG}
            togglePanel={svgData.togglePanel}
            item={svgData.item}
            panel={svgData.panel}
            id={svgData.id}
            page={svgData.page}
            blockId={svgData.blockId}
            objMode={svgData.objMode}
            mode={svgData.mode}
            svg={svgData.svg}
            subViewType={svgData.subViewType}
        />
        </tbody>
    )
}

export function ShakaiDescTable(props) {
    const {text, options, textInput, toggled, event} = props;
    const {shakaiDescObjectMode, rectObj, selectedRectId, subViewSettingsRect} = props;
    const {maskColorOptions, colorSelectValue, colorTextValue, color, maskSettingsHide, redLineSettingsHide} = props;
    const colspan = 2;
    if (props.tabMenu.setting) {
        return (
            <tbody>
            <tr>
                <td colSpan={colspan}>番号</td>
            </tr>
            <tr>
                <td colSpan={colspan}>
                    <input type={"text"}
                           name={"num"}
                           value={options.num || ""}
                           onChange={event.textUpdate}
                           autoComplete={"off"}
                    />
                </td>
            </tr>
            <tr>
                <td colSpan={colspan}>タイトル</td>
            </tr>
            <tr>
                <td colSpan={colspan}>
                    <textarea
                        className={"textarea_title"}
                        name={"title"}
                        value={options.title || ""}
                        onChange={event.textUpdate}
                        autoComplete={"off"}
                        ref={textInput.title}
                    />
                </td>
            </tr>
            <tr>
                <td colSpan={colspan}>
                    <input type={"button"}
                           value={"フリガナ編集"}
                           onClick={() => event.huriganaClick("title")}
                    />
                </td>
            </tr>
            <tr>
                <td colSpan={colspan}>説明</td>
            </tr>
            <tr>
                <td colSpan={colspan}>
                    <textarea
                        className={"textarea_desc"}
                        name={"desc"}
                        value={options.desc || ""}
                        onChange={event.textUpdate}
                        autoComplete={"off"}
                        ref={textInput.desc}
                    />
                </td>
            </tr>
            <tr>
                <td colSpan={colspan}>
                    <input type={"button"}
                           value={"フリガナ編集"}
                           onClick={() => event.huriganaClick("desc")}
                    />
                </td>
            </tr>
            <tr className={toggled}>
                <td>漢字</td>
                <td>フリガナ</td>
            </tr>
            <tr className={toggled}>
                <td>
                    <input type={"text"}
                           value={text.kanji}
                           readOnly={true}
                    />
                </td>
                <td>
                    <input type={"text"}
                           name={"hurigana"}
                           value={text.hurigana}
                           onChange={event.textChange}
                           onBlur={event.huriganaChange}
                           ref={textInput.hurigana}
                    />
                </td>
            </tr>
            <tr>
                <td colSpan={colspan} className={"subscribe_title"}>
                    ＊フリガナ編集方法＊
                </td>
            </tr>
            <tr>
                <td colSpan={colspan} className={"subscribe_contents"}>
                    ① 編集したい漢字テキストをドラッグしてください。
                </td>
            </tr>
            <tr>
                <td colSpan={colspan} className={"subscribe_contents"}>
                    ② 「フリガナ編集」ボタンを押してください。
                </td>
            </tr>
            <tr>
                <td colSpan={colspan} className={"subscribe_contents"}>
                    ③ フリガナを入力した後、キーボード「Tab」キーを押してください。
                </td>
            </tr>
            </tbody>
        )
    } else if (props.tabMenu.setting2) {
        return (
            <tbody>
            <tr>
                <td colSpan={colspan}>
                    <select
                        value={shakaiDescObjectMode}
                        onChange={event.shakaiDescObjectModeChange}
                        className={"scroll-select-rect"}
                    >
                        <option value={"block"}>マスク</option>
                        <option value={"redLine"}>赤線</option>
                    </select>
                    <input type={"button"} value={"追加"} className={"small-btn"} onClick={event.addObject}/>
                </td>
            </tr>
            <tr className={selectedRectId ? "": "hide"}>
                <td colSpan={colspan}>
                    紙面クリックポイント連動
                </td>
            </tr>
            <tr className={selectedRectId ? "": "hide"}>
                <td colSpan={colspan}>
                    <select
                        value={subViewSettingsRect ? subViewSettingsRect.rectId || "" : ""}
                        onChange={event.objectConnectChange}
                        className={"rect-connect-select"}
                    >

                        <option value=''>{"連動なし"}</option>
                        { selectedRectId ? rectObj.map((item) => {
                            return (
                                <option
                                    key={item.originId}
                                    value={item.originId}>{item.id}
                                </option>
                            )
                        }) : ""}
                    </select>
                </td>
            </tr>
            <SelectInputColor
                label={propertyInputLabel.maskColorSelect}
                options={maskColorOptions}
                val={colorSelectValue}
                eventHandler={event.colorSelect}
                hide={maskSettingsHide}
            />
            <TextInputColor
                label={propertyInputLabel.maskColorText}
                name={propertyInputName.maskColorText}
                val={colorTextValue || ""}
                onChange={event.colorTextChange}
                color={color || ""}
                hide={maskSettingsHide}
            />
            <tr className={redLineSettingsHide}>
                <td className={"input-area"}>
                    <div className={"input-text"}>グループ</div>
                    <div className={"input-value"}>
                        <input
                            type={"number"}
                            name={"group"}
                            value={subViewSettingsRect ? subViewSettingsRect.group : 0}
                            onChange={event.redLineInputChange}
                            min={0}/>
                    </div>
                </td>
            </tr>
            </tbody>
        )
    } else if (props.tabMenu.setting3) {
        return (
            <tbody>
            {posSizeInput(subViewSettingsRect, event.changeRectCss)}
            </tbody>
        )
    } else {
        return (<></>);
    }
}

export function ShakaiRedLineTable(props) {
    const {event, rectRedLines, subViewSettingsRedLine, selectedBlockId} = props;
    if (props.tabMenu.setting) {
        return (
            <tbody>
            <tr>
                <td>
                    <input type={"button"} value={"赤線追加"} className={"block_btn"} onClick={event.addRedLine}/>
                </td>
            </tr>
            <tr>
                <td>
                    紙面赤線連動
                </td>
            </tr>
            <tr>
                <td>
                    <select
                        value={subViewSettingsRedLine ? subViewSettingsRedLine.rectId || "" : ""}
                        onChange={event.redLineInputChange}
                        name={"rectId"}
                    >

                        <option value=''>{"選択してください。"}</option>
                        {selectedBlockId ? rectRedLines.map((item) => {
                            return (
                                <option
                                    key={item.originId}
                                    value={item.originId}>{item.id}
                                </option>
                            )
                        }) : ""}
                    </select>
                </td>
            </tr>
            <tr>
                <td className={"input-area"}>
                    <div className={"input-text"}>グループ</div>
                    <div className={"input-value"}>
                        <input
                            type={"number"}
                            name={"group"}
                            value={subViewSettingsRedLine ? subViewSettingsRedLine.group : 0}
                            onChange={event.redLineInputChange}
                            min={0}/>
                    </div>
                </td>
            </tr>
            </tbody>
        )
    } else if (props.tabMenu.setting2) {
        return (
            <tbody>
                {posSizeInput(subViewSettingsRedLine, event.changeRedLineCss)}
            </tbody>
        );
    } else {
        return (<></>);
    }
}

export function RikaBlockTable(props) {
    const {event, rectBlocks, subViewSettingsBlock, selectedBlockId, menuHide} = props;
    if (props.tabMenu.setting) {
        return (
            <tbody>
            <tr>
                <td>
                    <input type={"button"} value={"ブロック追加"} className={"block_btn"} onClick={event.addBlock}/>
                </td>
            </tr>
            <tr>
                <td>
                    紙面ブロック連動
                </td>
            </tr>
            <tr>
                <td>
                    <select
                        name={"rectId"}
                        value={subViewSettingsBlock ? subViewSettingsBlock.rectId || "" : ""}
                        onChange={event.blockInputChange}
                    >

                        <option value=''>{"選択してください。"}</option>
                        {selectedBlockId ? rectBlocks.map((item) => {
                            return (
                                <option
                                    key={item.originId}
                                    value={item.originId}>{item.id}
                                </option>
                            )
                        }) : ""}
                    </select>
                </td>
            </tr>
            <tr className={menuHide}>
                <td className={"input-area"}>
                    <div className={"input-text"}>グループ</div>
                    <div className={"input-value"}>
                        <input
                            type={"number"}
                            name={"group"}
                            value={subViewSettingsBlock ? subViewSettingsBlock.group || "" : ""}
                            onChange={event.blockInputChange}
                            min={0}/>
                    </div>
                </td>
            </tr>
            </tbody>
        )
    } else if (props.tabMenu.setting2) {
        return (
            <tbody>
                {posSizeInput(subViewSettingsBlock, event.changeBlockCss)}
            </tbody>
        )
    } else {
        return (<></>);
    }
}


export function RikaMaskTable(props) {
    const {event, rectMask, subViewSettingsMask, selectedMaskId, maskIdx, imageDeleteHide,
            maskColorOptions, colorSelectValue, colorTextValue, color, maskSettingsHide, svgData, upload, bookId} = props;
    const colSpan = 2;
    if (props.tabMenu.setting) {
        return (
            <tbody>
            <tr>
                <td colSpan={colSpan}>
                    <input type={"button"} value={"追加"} className={"block_btn_small"} onClick={event.addMask}/>
                </td>
            </tr>
            <tr>
                <td colSpan={colSpan}>
                    紙面クリックポイント連動
                </td>
            </tr>
            <tr>
                <td colSpan={colSpan}>
                    <select
                        value={subViewSettingsMask ? subViewSettingsMask.rectId || "" : ""}
                        onChange={event.maskConnectChange}
                        className={"rect-connect-select"}
                    >

                        <option value=''>{"連動なし"}</option>
                        {selectedMaskId ? rectMask.map((item) => {
                            return (
                                <option
                                    key={item.originId}
                                    value={item.originId}>{item.id}
                                </option>
                            )
                        }) : ""}
                    </select>
                    <input type={"button"} value={"SVGコピー"} className={"small-btn"}
                           onClick={() => event.svgCopy(subViewSettingsMask)}/>
                </td>
            </tr>
            <SelectInputColor
                label={propertyInputLabel.maskColorSelect}
                options={maskColorOptions}
                val={colorSelectValue}
                eventHandler={event.colorSelect}
                hide={maskSettingsHide}
            />
            <TextInputColor
                label={propertyInputLabel.maskColorText}
                name={propertyInputName.maskColorText}
                val={colorTextValue || ""}
                onChange={event.colorTextChange}
                color={color || ""}
                hide={maskSettingsHide}
            />
            <tr className={maskSettingsHide}>
                <td className="table-key">マスク画像</td>
                <td className="table-value">
                    <label htmlFor={"uploadMask"}>
                        <div className={"subview-buttons small float-left"}>
                            <span className={"button-text"}>追加/変更</span>
                        </div>
                    </label>
                    <div className={"subview-buttons small float-left " + imageDeleteHide}
                         onClick={() => event.deleteMaskImage(maskIdx)}>
                        <span className={"button-text"}>削除</span>
                    </div>
                </td>
            </tr>
            <SvgSubView
                changeRectDataSVG={svgData.changeRectDataSVG}
                togglePanel={svgData.togglePanel}
                item={svgData.item}
                panel={svgData.panel}
                id={svgData.id}
                page={svgData.page}
                blockId={svgData.blockId}
                objMode={svgData.objMode}
                mode={svgData.mode}
                svg={svgData.svg}
                subViewType={svgData.subViewType}
            />
            <tr>
                <td>
                    <div className={"hide"}>
                        <form method={"post"} encType={"multipart/form-data"}>
                            <input
                                type={"file"}
                                id={"uploadMask"}
                                accept={".png"}
                                onChange={(e) => upload(e, maskIdx)}
                            />
                            <input type={"hidden"} name={"bookId"} defaultValue={bookId}/>
                        </form>
                    </div>
                </td>
            </tr>
            </tbody>
        )
    } else if (props.tabMenu.setting2) {
        return (
            <tbody>
                {posSizeInput(subViewSettingsMask, event.changeMaskCss)}
            </tbody>
        )
    } else {
        return (<></>);
    }
}

export function RikaCheckTable(props) {
    const {event, rectMask, subViewSettingsCheck, selectedCheckId, useConnect} = props;

    if (props.tabMenu.setting) {
        return (
            <tbody>
            <tr>
                <td>
                    チェックボックス追加
                </td>
            </tr>
            <tr>
                <td>
                    <CheckSheetItem
                        onClick={event.checkSheetSelectEvent}
                    />
                </td>
            </tr>
            <tr className={useConnect ? "" : "hide"}>
                <td>
                    紙面チェックボックス連動
                </td>
            </tr>
            <tr className={useConnect ? "" : "hide"}>
                <td>
                    <select
                        value={subViewSettingsCheck ? subViewSettingsCheck.rectId || "" : ""}
                        onChange={event.checkConnectChange}
                    >
                        <option value=''>{"選択してください。"}</option>
                        { selectedCheckId ? rectMask.map((item) => {
                            return (
                                <option
                                    key={item.originId}
                                    value={item.originId}>{item.id}
                                </option>
                            )
                        }) : ""}
                    </select>
                </td>
            </tr>
            </tbody>
        )
    } else if (props.tabMenu.setting2) {
        return (
            <tbody>
                {posSizeInput(subViewSettingsCheck, event.changeCheckCss)}
            </tbody>
        )
    } else {
        return (<></>);
    }
}


export function RikaScrollTable(props) {
    const {event, selectedId, bookId, upload, titleImage, scrollImage,
        scrollTitleEdit, currentIdx, subViewObj, optionDisabled, inActive} = props;
    const {rectObj, subViewSettingsRect, selectedMaskId, scrollObjectMode, optionsName, svgData} = props;
    const colspan = 2;
    const cssDisabled = scrollImage[currentIdx] ? "" : "hide";
    const headerMenuDisabled = scrollTitleEdit ? `${inActive}` : `${inActive} ${cssDisabled}`;
    const width = !scrollTitleEdit ? scrollImage[currentIdx] ? scrollImage[currentIdx].width : 0 : titleImage.width;
    const height = !scrollTitleEdit ? scrollImage[currentIdx] ? scrollImage[currentIdx].height : 0 : titleImage.height;
    const left = !scrollTitleEdit ? scrollImage[currentIdx] ? scrollImage[currentIdx].left : 0 : titleImage.left;

    // 스크롤 설정1 메뉴
    if (props.tabMenu.setting) {
        return (
            <tbody>
            <tr>
                <td colSpan={colspan}>
                    <label htmlFor={"uploadTitle"}>
                        <div className={"subview-buttons default-buttons"}>
                            <span className={"button-text"}>ヘッダー画像追加/変更</span>
                        </div>
                    </label>
                </td>
            </tr>
            <tr>
                <td colSpan={colspan}>
                    <label htmlFor={"uploadScroll"}>
                        <div className={"subview-buttons default-buttons"}>
                            <span className={"button-text"}>スクロール画像追加</span>
                        </div>
                    </label>
                </td>
            </tr>
            <tr>
                <td colSpan={colspan}>
                    <div className={"subview-buttons default-buttons"} onClick={() => event.scrollImageDelete(false)}>
                        <span className={"button-text"}>スクロール画像初期化</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td colSpan={colspan}>

                    { titleImage ?
                        <p className={inActive}>
                            <span className={"scroll-header-item"}>TITLE</span>
                            <span
                                className={"subview-buttons scroll-header-button"}
                                onClick={event.titleImageEdit}>
                                {scrollTitleEdit ? "編集終了" : "編集"}
                            </span>
                            <span
                                className={"subview-buttons scroll-header-button"}
                                onClick={() => event.titleImageDelete(true)}>
                                削除
                            </span>
                        </p> : ""
                    }

                    <div className={"scroll-list"}>
                        { scrollImage.map((item, idx) => {
                            return (
                                <div key={item.id}>
                                    <p
                                        className={currentIdx === idx ? "scroll-image-selected scroll-list-item" : "scroll-list-item"}
                                        onClick={() => event.scrollListClick(idx)}
                                    >
                                        <span className={"scroll-image-item"}>IMAGE-{idx+1}</span>
                                        <label htmlFor={"changeScroll" + idx}>
                                            <span className={"subview-buttons scroll-image-button"}>
                                                変更
                                            </span>
                                        </label>
                                        <span
                                            className={idx === 0 && scrollImage.length !== 1 ? "hide": "subview-buttons scroll-image-button"}
                                            onClick={(e) => event.scrollImageDeleteOne(e, idx)}>
                                            削除
                                        </span>
                                    </p>

                                    <div className={"hide"}>
                                        <form method={"post"} encType={"multipart/form-data"}>
                                            <input
                                                type={"file"}
                                                id={"changeScroll" + idx}
                                                name={"scroll"}
                                                accept={".png"}
                                                data-id={selectedId}
                                                onChange={(e) => upload(e, idx)}
                                            />
                                            <input type={"hidden"} name={"bookId"} defaultValue={bookId}/>
                                        </form>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </td>
            </tr>
            <tr className={scrollTitleEdit ?  "hide" : cssDisabled}>
                <td className={"td-title-long"}>紙面ID</td>
                <td>
                    <select
                        value={scrollImage[currentIdx] ? scrollImage[currentIdx].rectId || "" : ""}
                        onFocus={event.savePreviousRectId}
                        onChange={event.changeRectId}
                        ref={props.input.rectIdSelectBox}
                    >

                        <option value='' disabled={optionDisabled}>{"選択してください。"}</option>
                        { scrollImage[currentIdx] ? subViewObj.map((item) => {
                            return (
                                <option
                                    key={item.originId}
                                    disabled={optionDisabled || scrollImage[0].rectId === item.originId}
                                    value={item.originId}>{item.id}
                                </option>
                            )
                        }) : ""}
                    </select>
                </td>
            </tr>
            <tr className={headerMenuDisabled}>
                <td className={"td-title-long"}>幅</td>
                <td>
                    <input
                        type={"number"}
                        name={"width"}
                        value={width || 0}
                        onChange={event.changeScrollImageCss}
                    />
                </td>
            </tr>
            <tr className={headerMenuDisabled}>
                <td className={"td-title-long"}>Y座標</td>
                <td>
                    <input
                        type={"number"}
                        name={"height"}
                        value={height || 0}
                        onChange={event.changeScrollImageCss}
                    />
                </td>
            </tr>
            <tr className={headerMenuDisabled}>
                <td className={"td-title-long"}>X座標</td>
                <td>
                    <input
                        type={"number"}
                        name={"left"}
                        value={left || 0}
                        onChange={event.changeScrollImageCss}
                    />
                </td>
            </tr>
            <tr>
                <td>
                    <div className={"hide"}>
                        <form method={"post"} encType={"multipart/form-data"}>
                            <input
                                type={"file"}
                                id={"uploadTitle"}
                                name={"title"}
                                accept={".png"}
                                data-id={selectedId}
                                onChange={upload}
                            />
                            <input type={"hidden"} name={"bookId"} defaultValue={bookId}/>
                        </form>
                        <form method={"post"} encType={"multipart/form-data"}>
                            <input
                                type={"file"}
                                id={"uploadScroll"}
                                name={"scroll"}
                                accept={".png"}
                                data-id={selectedId}
                                onChange={upload}
                            />
                            <input type={"hidden"} name={"bookId"} defaultValue={bookId}/>
                        </form>
                    </div>
                </td>
            </tr>
            </tbody>
        )
    }
    // 스크롤 설정2 메뉴
    else if (props.tabMenu.setting2) {
        return (
            <tbody>
                <tr>
                    <td colSpan={colspan}>
                        <select
                            value={scrollObjectMode}
                            onChange={event.scrollObjectModeChange}
                            className={"scroll-select-rect"}
                        >
                            <option value={"mask"}>キャラクター発言</option>
                            {/*<option value={"block"}>ブロック</option>*/}
                            <option value={rikaAnswerType.answer.button.name}>解答ボタン</option>
                            <option value={rikaAnswerType.answer.contents.name}>解答マスク</option>
                        </select>
                        <input type={"button"} value={"追加"} className={"small-btn"} onClick={event.addObject}/>
                    </td>
                </tr>
                <tr className={"tr-space"}></tr>
                <tr className={optionsName === subViewRect.rikaMask ? "": "hide"}>
                    <td colSpan={colspan}>
                        紙面クリックポイント連動
                    </td>
                </tr>
                <tr className={optionsName === subViewRect.rikaMask ? "": "hide"}>
                    <td colSpan={colspan}>
                        <select
                            value={subViewSettingsRect ? subViewSettingsRect.rectId || "" : ""}
                            onChange={event.objectConnectChange}
                            className={"rect-connect-select"}
                        >

                            <option value=''>{"連動なし"}</option>
                            { selectedMaskId ? rectObj.map((item) => {
                                return (
                                    <option
                                        key={item.originId}
                                        value={item.originId}>{item.id}
                                    </option>
                                )
                            }) : ""}
                        </select>
                        <input type={"button"} value={"SVGコピー"} className={"small-btn"} onClick={() => event.svgCopy(subViewSettingsRect)}/>
                    </td>
                </tr>
                <SvgSubView
                    changeRectDataSVG={svgData.changeRectDataSVG}
                    togglePanel={svgData.togglePanel}
                    item={svgData.item}
                    panel={svgData.panel}
                    id={svgData.id}
                    page={svgData.page}
                    blockId={svgData.blockId}
                    objMode={svgData.objMode}
                    mode={svgData.mode}
                    svg={svgData.svg}
                    subViewType={svgData.subViewType}
                    hide={optionsName !== subViewRect.rikaMask}
                />
                <tr className={optionsName === subViewRect.rikaAnswer ? "": "hide"}>
                    <td className={"td-title-long"}>
                        グループ
                    </td>
                    <td>
                        <input
                            type={"number"}
                            name={"group"}
                            value={subViewSettingsRect ? subViewSettingsRect.group || 0 : 0}
                            onChange={event.changeAnswerValue}
                            min={0}
                        />
                    </td>
                </tr>
            </tbody>
        )
    } else if (props.tabMenu.setting3) {
        return (
            <tbody>
                {posSizeInput(subViewSettingsRect, event.changeScrollRectCss)}
            </tbody>
        );
    }

}

export function SansuAnswerTable(props) {
    const {event, selectedAnswer, selectedAnswerIdx, sansuAnswerType, selectExpressionOptions, selectAnswerOptions,
        bookId, rectMask, upload} = props;
    const {connectRectHide, textInputHide, maskInputHide, answerTypeHide, dataNumHide, groupInputHide, imageDeleteHide, scrollNumHide="hide"} = props;
    if (props.tabMenu.setting || props.isScroll) {
        return (
            <tbody>
                <tr>
                    <td>
                        <input
                            type={"button"}
                            value={"答えボタン追加"}
                            name={"answerButton"}
                            className={"answer_add_btn"}
                            onClick={event.addAnswer}
                        />
                    </td>
                </tr>
                <tr>
                    <td>
                        <input
                            type={"button"}
                            value={"答えマスク追加"}
                            name={"answerContents"}
                            className={"answer_add_btn"}
                            onClick={event.addAnswer}
                        />
                    </td>
                </tr>
                <tr>
                    <td>
                        <input
                            type={"button"}
                            value={"マスク追加"}
                            name={"toggle"}
                            className={"answer_add_btn"}
                            onClick={event.addToggle}
                        />
                    </td>
                </tr>
                <tr>
                    <td className={"input-area"}>
                        <div className={"input-text"}>
                            <select onChange={event.changeExpressionType}>
                                {selectExpressionOptions.map((key) => {
                                    return <option key={key} value={key}>{sansuAnswerType[key].selectBoxText}</option>
                                })}
                            </select>
                        </div>
                        <div className={"input-value"}>
                            <input
                                type={"button"}
                                name={"expression"}
                                value={"表現式追加"}
                                onClick={event.addExpression}
                                className={"answer-btn"}
                            />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className={"add-settings-text"}>追加設定</div>
                    </td>
                </tr>
                <tr className={answerTypeHide}>
                    <td className={"input-area"}>
                        <div className={"input-text"}>TYPE</div>
                        <div className={"input-value"}>
                            <select onChange={event.changeAnswerType} value={selectedAnswer ? selectedAnswer.type : ""}>
                                {selectAnswerOptions.map((key, idx) => {
                                    return <option key={key} value={selectAnswerOptions[idx]}>{idx === 0 ? "マスク" : "テキスト"}</option>
                                })}
                            </select>
                        </div>
                    </td>
                </tr>
                <tr className={maskInputHide}>
                    <td className={"input-area"}>
                        <div className={"input-text"}>背景色選択</div>
                        <div className={"input-value"}>
                            <select name={"color"} onChange={event.changeAnswerValue} value={selectedAnswer ? selectedAnswer.color : ""}>
                                {answerColorCode.map((color, idx) => {
                                    return <option key={idx} value={color.val}>{color.name}</option>
                                })}
                            </select>
                        </div>
                    </td>
                </tr>
                <tr className={maskInputHide}>
                    <td className={"input-area"}>
                        <div className={"input-text"}>背景色コード</div>
                        <div className={"input-value"}>
                            <input
                                type={"text"}
                                name={"color"}
                                value={selectedAnswer ? selectedAnswer.color || "" : ""}
                                onChange={event.changeAnswerValue}
                            />
                        </div>
                    </td>
                </tr>
                <tr className={maskInputHide}>
                    <td className={"input-area"}>
                        <div className={"input-text"}>マスク画像</div>
                        <label htmlFor={"uploadMask"}>
                            <div className={"subview-buttons small"}>
                                <span className={"button-text"}>追加/変更</span>
                            </div>
                        </label>
                        <div className={"subview-buttons small " + imageDeleteHide}
                             onClick={() => event.deleteAnswerImage(selectedAnswerIdx)}>
                            <span className={"button-text"}>削除</span>
                        </div>
                    </td>
                </tr>
                <tr className={maskInputHide}>
                    <td className={"input-area"}>
                        <div className={"input-text"}>初期マスク<br/>ON/OFF</div>
                        <div className="switch input-value">
                            <label className="switch_label">
                                <input type="checkbox" className="switch_input" name={"maskOn"}
                                       onChange={event.changeAnswerValue}
                                       checked={selectedAnswer ? selectedAnswer.maskOn || false : false}
                                />
                                <span className="switch_content"/>
                                <span className="switch_circle"/>
                            </label>
                        </div>
                    </td>
                </tr>
                <tr className={groupInputHide}>
                    <td className={"input-area"}>
                        <div className={"input-text"}>グループ</div>
                        <div className={"input-value"}>
                            <input
                                type={"number"}
                                name={"group"}
                                value={selectedAnswer ? selectedAnswer.group || "" : ""}
                                min={1}
                                onChange={event.changeAnswerValue}
                            />
                        </div>
                    </td>
                </tr>
                <tr className={textInputHide}>
                    <td className={"input-area"}>
                        <div className={"input-text"}>TEXT</div>
                        <div className={"input-value"}>
                            <input
                                type={"text"}
                                name={"text"}
                                value={selectedAnswer ? selectedAnswer.text || "" : ""}
                                onChange={event.changeAnswerValue}
                            />
                        </div>
                    </td>
                </tr>
                <tr className={textInputHide}>
                    <td className={"input-area"}>
                        <div className={"input-text"}>SIZE</div>
                        <div className={"input-value"}>
                            <input
                                type={"number"}
                                name={"fontSize"}
                                value={selectedAnswer ? selectedAnswer.fontSize || 0 : 0}
                                onChange={event.changeAnswerValue}
                            />
                        </div>
                    </td>
                </tr>
                <tr className={dataNumHide}>
                    <td className={"input-area"}>
                        <div className={"input-text"}>順番</div>
                        <div className={"input-value"}>
                            <input
                                type={"number"}
                                name={"dataNum"}
                                value={selectedAnswer ? selectedAnswer.dataNum || 0 : 0}
                                onChange={event.changeAnswerValue}
                            />
                        </div>
                    </td>
                </tr>
                <tr className={connectRectHide}>
                    <td className={"input-area"}>
                        <div className={"input-text"}>紙面マスク<br/>連動</div>
                        <div className={"input-value"}>
                        <select
                            value={selectedAnswer ? selectedAnswer.rectId || "" : ""}
                            onChange={event.maskConnectChange}
                            className={""}
                        >

                            <option value=''>{"連動なし"}</option>
                            { selectedAnswer ? rectMask.map((item) => {
                                return (
                                    <option
                                        key={item.originId}
                                        value={item.originId}>{item.id}
                                    </option>
                                )
                            }) : ""}
                        </select>
                        </div>
                    </td>
                </tr>
                <tr className={scrollNumHide}>
                    <td className={"input-area"}>
                        <div className={"input-text"}>スクロール<br/>番号</div>
                        <div className={"input-value"}>
                            <input
                                type={"number"}
                                name={"dataScroll"}
                                value={selectedAnswer ? selectedAnswer.dataScroll || 0 : 0}
                                onChange={event.changeAnswerValue}
                            />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className={"hide"}>
                            <form method={"post"} encType={"multipart/form-data"}>
                                <input
                                    type={"file"}
                                    id={"uploadMask"}
                                    accept={".png"}
                                    onChange={(e) => upload(e, selectedAnswerIdx)}
                                />
                                <input type={"hidden"} name={"bookId"} defaultValue={bookId}/>
                            </form>
                        </div>
                    </td>
                </tr>
            </tbody>
        )
    } else if (props.tabMenu.setting2 && !props.isScroll) {
        return (
            <tbody>
                {posSizeInput(selectedAnswer, event.changeAnswerCss)}
            </tbody>
        )
    } else {
        return (<></>);
    }
}


export function SubMenuTable(props) {
    const {event, values, selectedIdx, selectedMenuClass, upload, bookId} = props;

    const colspan = 2;

    return (
        <tbody>
            <tr>
                <td className={"td-title-long"}>ヘッダー</td>
                <td>
                    <select value={values.titleValue || ""} onChange={event.changeHeader}>
                        {subMenuTitleOptions.map((option) => {
                            return (
                                <option value={option.value} key={option.value}>{option.name}</option>
                            );
                        })}
                    </select>
                </td>
            </tr>
            <tr>
                <td colSpan={colspan}>
                    <input
                        type={"button"}
                        value={"メニュー追加"}
                        className={"block_btn"}
                        onClick={event.addMenuContents}
                    />
                </td>
            </tr>
            <tr className={selectedMenuClass}>
                <td colSpan={colspan}>
                    <span>メニュー編集</span>
                </td>
            </tr>
            <tr className={selectedMenuClass}>
                <td colSpan={colspan}>
                    <label htmlFor={"uploadThumb"}>
                        <div className={"subview-buttons default-buttons"}>
                            <span className={"button-text"}>サムネール画像追加</span>
                        </div>
                    </label>
                </td>
            </tr>
            <tr className={selectedMenuClass}>
                <td className={"td-title-long"}>アイコン</td>
                <td>
                    <select className={"width-100"} value={values.iconValue || ""} onChange={(e) => event.changeSubMenuIcon(e, selectedIdx)}>
                        {subMenuIconOptions.map((option) => {
                            return (
                                <option value={option.value} key={option.value}>{option.name}</option>
                            );
                        })}
                    </select>
                </td>
            </tr>
            <tr className={selectedMenuClass}>
                <td className={"td-title-long"}>テキスト</td>
                <td>
                    <textarea
                        className={"subMenu-textArea"}
                        value={values.textValue || ""}
                        onChange={(e) => event.changeSubMenuText(e, selectedIdx)}
                    />
                </td>
            </tr>
            <tr className={selectedMenuClass}>
                <td className={"td-title-long"}>紙面ID</td>
                <td>
                    <input
                        type={"text"}
                        name={"eventId"}
                        value={values.eventIdValue || ""}
                        onChange={(e) => event.changeSubMenuEvent(e, selectedIdx)}
                    />
                </td>
            </tr>
            <tr className={selectedMenuClass}>
                <td className={"td-title-long"}>紙面ページ</td>
                <td>
                    <input
                        type={"number"}
                        name={"eventPage"}
                        value={values.eventPageValue || 0}
                        onChange={(e) => event.changeSubMenuEvent(e, selectedIdx)}
                    />
                </td>
            </tr>
            <tr className={selectedMenuClass}>
                <td colSpan={colspan}>
                    <input
                        type={"button"}
                        value={"メニュー削除"}
                        className={"subview-buttons"}
                        onClick={() => event.deleteMenuContents(selectedIdx)}
                    />
                </td>
            </tr>
            <tr>
                <td>
                    <div className={"hide"}>
                        <form method={"post"} encType={"multipart/form-data"}>
                            <input
                                type={"file"}
                                id={"uploadThumb"}
                                accept={".png"}
                                onChange={(e) => upload(e, selectedIdx)}
                            />
                            <input type={"hidden"} name={"bookId"} defaultValue={bookId}/>
                        </form>
                    </div>
                </td>
            </tr>
        </tbody>
    )
}

export function RikaAnswerTable(props) {
    const {event, selectedAnswer, imageDeleteHide, answerMaskSettingHide, groupHide, selectedAnswerIdx, upload, bookId} = props;
    if (props.tabMenu.setting) {
        return (
            <tbody>
            <tr>
                <td>
                    <input
                        type={"button"}
                        value={"解答ボタン追加"}
                        name={"button"}
                        className={"answer_add_btn"}
                        onClick={event.addAnswer}
                    />
                </td>
            </tr>
            <tr>
                <td>
                    <input
                        type={"button"}
                        value={"解答マスク追加"}
                        name={"contents"}
                        className={"answer_add_btn"}
                        onClick={event.addAnswer}
                    />
                </td>
            </tr>
            <tr>
                <td>
                    <input
                      type={"button"}
                      value={"例文ボタン追加"}
                      name={"exampleButton"}
                      className={"answer_add_btn"}
                      onClick={event.addAnswer}
                    />
                </td>
            </tr>
            <tr>
                <td>
                    <input
                      type={"button"}
                      value={"例文マスク追加"}
                      name={"contents"}
                      className={"answer_add_btn"}
                      onClick={event.addAnswer}
                    />
                </td>
            </tr>
            <tr>
                <td>
                    <div className={"add-settings-text"}>追加設定</div>
                </td>
            </tr>
            <tr className={answerMaskSettingHide}>
                <td className={"input-area"}>
                    <div className={"input-text"}>背景色選択</div>
                    <div className={"input-value"}>
                        <select name={"color"} onChange={event.changeAnswerValue} value={selectedAnswer ? selectedAnswer.color || "" : ""}>
                            {answerColorCode.map((color, idx) => {
                                return <option key={idx} value={color.val}>{color.name}</option>
                            })}
                        </select>
                    </div>
                </td>
            </tr>
            <tr className={answerMaskSettingHide}>
                <td className={"input-area"}>
                    <div className={"input-text"}>背景色コード</div>
                    <div className={"input-value"}>
                        <input
                            type={"text"}
                            name={"color"}
                            value={selectedAnswer ? selectedAnswer.color || "" : ""}
                            onChange={event.changeAnswerValue}
                        />
                    </div>
                </td>
            </tr>
            <tr className={answerMaskSettingHide}>
                <td className={"input-area"}>
                    <div className={"input-text"}>マスク画像</div>
                    <label htmlFor={"uploadMask"}>
                        <div className={"subview-buttons small"}>
                            <span className={"button-text"}>追加/変更</span>
                        </div>
                    </label>
                    <div className={"subview-buttons small " + imageDeleteHide}
                         onClick={() => event.deleteAnswerImage(selectedAnswerIdx)}>
                        <span className={"button-text"}>削除</span>
                    </div>
                </td>
            </tr>
            <tr className={answerMaskSettingHide}>
                <td className={"input-area"}>
                    <div className={"input-text"}>初期マスク<br/>ON/OFF</div>
                    <div className="switch input-value">
                        <label className="switch_label">
                            <input type="checkbox" className="switch_input" name={"maskOn"}
                                   onChange={event.changeAnswerValue}
                                   checked={selectedAnswer ? selectedAnswer.maskOn || false : false}
                            />
                            <span className="switch_content"/>
                            <span className="switch_circle"/>
                        </label>
                    </div>
                </td>
            </tr>
            <tr className={groupHide}>
                <td className={"input-area"}>
                    <div className={"input-text"}>グループ</div>
                    <div className={"input-value"}>
                        <input
                            type={"number"}
                            name={"group"}
                            value={selectedAnswer ? selectedAnswer.group || "" : ""}
                            min={1}
                            onChange={event.changeAnswerValue}
                        />
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div className={"hide"}>
                        <form method={"post"} encType={"multipart/form-data"}>
                            <input
                                type={"file"}
                                id={"uploadMask"}
                                accept={".png"}
                                onChange={(e) => upload(e, selectedAnswerIdx)}
                            />
                            <input type={"hidden"} name={"bookId"} defaultValue={bookId}/>
                        </form>
                    </div>
                </td>
            </tr>
            </tbody>
        )
    } else if (props.tabMenu.setting2) {
        return (
            <tbody>
                {posSizeInput(selectedAnswer, event.changeAnswerCss)}
            </tbody>
        )
    } else {
        return (<></>);
    }
}

export function SubViewInSubViewTable(props) {
    const {event, selectedSubViewLink, subViewLinkIdx, menuHide, svgData} = props;
    const colSpan = 2;
    return (
        <tbody>
        <tr>
            <td colSpan={colSpan}>
                <input type={"button"} value={"拡大画面リンク追加"} className={"block_btn"} onClick={event.addLink}/>
            </td>
        </tr>
        <tr className={"tr-space"}></tr>
        <tr>
            <td colSpan={colSpan}>
                <div className={"add-settings-text"}>拡大画面リンク設定</div>
            </td>
        </tr>
        <tr className={menuHide}>
            <td className={"td-title-long"}>紙面ID</td>
            <td>
                <input
                    type={"text"}
                    name={"eventId"}
                    value={selectedSubViewLink ? selectedSubViewLink.eventId || "" : ""}
                    onChange={(e) => event.changeSubViewLinkEvent(e, subViewLinkIdx)}
                />
            </td>
        </tr>
        <tr className={menuHide}>
            <td className={"td-title-long"}>紙面ページ</td>
            <td>
                <input
                    type={"number"}
                    name={"eventPage"}
                    value={selectedSubViewLink ? selectedSubViewLink.eventPage || 0 : 0}
                    onChange={(e) => event.changeSubViewLinkEvent(e, subViewLinkIdx)}
                />
            </td>
        </tr>
        <tr className={menuHide}>
            <td className={"td-title-long"}>優先度</td>
            <td>
                <input
                    type={"number"}
                    name={"zIndex"}
                    value={selectedSubViewLink ? selectedSubViewLink.zIndex || 0 : 0}
                    onChange={(e) => event.changeSubViewLinkEvent(e, subViewLinkIdx)}
                />
            </td>
        </tr>
        <SvgSubView
            changeRectDataSVG={svgData.changeRectDataSVG}
            togglePanel={svgData.togglePanel}
            item={svgData.item}
            panel={svgData.panel}
            id={svgData.id}
            page={svgData.page}
            blockId={svgData.blockId}
            objMode={svgData.objMode}
            mode={svgData.mode}
            svg={svgData.svg}
            subViewType={svgData.subViewType}
        />
        </tbody>
    )
}

export function RikaSlideTable(props) {
    const {event, selectedId, bookId, upload, titleImage, scrollImage,
        scrollTitleEdit, currentIdx, subViewObj, optionDisabled, inActive, scrollType, scrollLoop} = props;
    const {rectObj, subViewSettingsRect, selectedMaskId, scrollObjectMode, optionsName, svgData} = props;
    const {maskColorOptions, colorSelectValue, maskSettingsHide, colorTextValue, color, scrollRectIdx, imageDeleteHide, scrollRectImageUpload} = props;
    const {answerSettingsHide, connectHide, blockSettingsHide} = props;

    const colspan = 2;
    const cssDisabled = scrollImage[currentIdx] ? "" : "hide";
    const headerMenuDisabled = scrollTitleEdit ? `${inActive}` : `${inActive} ${cssDisabled}`;
    const width = !scrollTitleEdit ? scrollImage[currentIdx] ? scrollImage[currentIdx].width : 0 : titleImage.width;
    const imageHeight = !scrollTitleEdit ? scrollImage[currentIdx] ? scrollImage[currentIdx].imageHeight : 0 : titleImage.imageHeight;
    const height = !scrollTitleEdit ? scrollImage[currentIdx] ? scrollImage[currentIdx].height : 0 : titleImage.height;
    const left = !scrollTitleEdit ? scrollImage[currentIdx] ? scrollImage[currentIdx].left : 0 : titleImage.left;

    // 스크롤 설정1 메뉴
    if (props.tabMenu.setting) {
        return (
            <tbody>
            <tr>
                <td colSpan={colspan}>
                    <label htmlFor={"uploadTitle"}>
                        <div className={"subview-buttons default-buttons"}>
                            <span className={"button-text"}>ヘッダー画像追加/変更</span>
                        </div>
                    </label>
                </td>
            </tr>
            <tr>
                <td colSpan={colspan}>
                    <label htmlFor={"uploadScroll"}>
                        <div className={"subview-buttons default-buttons"}>
                            <span className={"button-text"}>スライド画像追加</span>
                        </div>
                    </label>
                </td>
            </tr>
            <tr>
                <td colSpan={colspan}>
                    <div className={"subview-buttons default-buttons"} onClick={() => event.scrollImageDelete(false)}>
                        <span className={"button-text"}>スライド画像初期化</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td className={"td-title"}>方向</td>
                <td className={"td-value"}>
                    <select name={"scrollType"} value={scrollType} onChange={event.changeScrollType}>
                        <option value={"v"}>縦</option>
                        <option value={"h"}>横</option>
                    </select>
                </td>
            </tr>
            <tr>
                <td className={"td-title"}>ループ</td>
                <td className={"td-value"}>
                    <div className="switch">
                        <label className="switch_label">
                            <input type="checkbox" className="switch_input" name={"scrollLoop"}
                                   onChange={event.changeScrollLoop}
                                   checked={scrollLoop}
                            />
                            <span className="switch_content"/>
                            <span className="switch_circle"/>
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td colSpan={colspan}>

                    { titleImage ?
                        <p className={inActive}>
                            <span className={"scroll-header-item"}>TITLE</span>
                            <span
                                className={"subview-buttons scroll-header-button"}
                                onClick={event.titleImageEdit}>
                                {scrollTitleEdit ? "編集終了" : "編集"}
                            </span>
                            <span
                                className={"subview-buttons scroll-header-button"}
                                onClick={() => event.titleImageDelete(true)}>
                                削除
                            </span>
                        </p> : ""
                    }

                    <div className={"scroll-list"}>
                        { scrollImage.map((item, idx) => {
                            return (
                                <div key={item.id}>
                                    <p
                                        className={currentIdx === idx ? "scroll-image-selected scroll-list-item" : "scroll-list-item"}
                                        onClick={() => event.scrollListClick(idx)}
                                    >
                                        <span className={"scroll-image-item"}>IMAGE-{idx+1}</span>
                                        <label htmlFor={"changeScroll" + idx}>
                                            <span className={"subview-buttons scroll-image-button"}>
                                                変更
                                            </span>
                                        </label>
                                        <span
                                            className={idx === 0 && scrollImage.length !== 1 ? "hide": "subview-buttons scroll-image-button"}
                                            onClick={(e) => event.scrollImageDeleteOne(e, idx)}>
                                            削除
                                        </span>
                                    </p>

                                    <div className={"hide"}>
                                        <form method={"post"} encType={"multipart/form-data"}>
                                            <input
                                                type={"file"}
                                                id={"changeScroll" + idx}
                                                name={"scroll"}
                                                accept={".png"}
                                                data-id={selectedId}
                                                onChange={(e) => upload(e, idx)}
                                            />
                                            <input type={"hidden"} name={"bookId"} defaultValue={bookId}/>
                                        </form>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </td>
            </tr>
            <tr className={scrollTitleEdit ?  "hide" : cssDisabled}>
                <td className={"td-title-long"}>紙面ID</td>
                <td>
                    <select
                        value={scrollImage[currentIdx] ? scrollImage[currentIdx].rectId || "" : ""}
                        onFocus={event.savePreviousRectId}
                        onChange={event.changeRectId}
                        ref={props.input.rectIdSelectBox}
                    >

                        <option value='' disabled={optionDisabled}>{"選択してください。"}</option>
                        { scrollImage[currentIdx] ? subViewObj.map((item) => {
                            return (
                                <option
                                    key={item.originId}
                                    disabled={optionDisabled || scrollImage[0].rectId === item.originId}
                                    value={item.originId}>{item.id}
                                </option>
                            )
                        }) : ""}
                    </select>
                </td>
            </tr>
            <tr className={headerMenuDisabled}>
                <td className={"td-title-long"}>幅</td>
                <td>
                    <input
                        type={"number"}
                        name={"width"}
                        value={width || ""}
                        onChange={event.changeScrollImageCss}
                    />
                </td>
            </tr>
            <tr className={scrollTitleEdit ?  "hide" : ""}>
                <td className={"td-title-long"}>縦</td>
                <td>
                    <input
                        type={"number"}
                        name={"imageHeight"}
                        value={imageHeight || ""}
                        onChange={event.changeScrollImageCss}
                    />
                </td>
            </tr>
            <tr className={headerMenuDisabled}>
                <td className={"td-title-long"}>Y座標</td>
                <td>
                    <input
                        type={"number"}
                        name={"height"}
                        value={height || 0}
                        onChange={event.changeScrollImageCss}
                    />
                </td>
            </tr>
            <tr className={headerMenuDisabled}>
                <td className={"td-title-long"}>X座標</td>
                <td>
                    <input
                        type={"number"}
                        name={"left"}
                        value={left || 0}
                        onChange={event.changeScrollImageCss}
                    />
                </td>
            </tr>
            <tr>
                <td>
                    <div className={"hide"}>
                        <form method={"post"} encType={"multipart/form-data"}>
                            <input
                                type={"file"}
                                id={"uploadTitle"}
                                name={"title"}
                                accept={".png"}
                                data-id={selectedId}
                                onChange={upload}
                            />
                            <input type={"hidden"} name={"bookId"} defaultValue={bookId}/>
                        </form>
                        <form method={"post"} encType={"multipart/form-data"}>
                            <input
                                type={"file"}
                                id={"uploadScroll"}
                                name={"scroll"}
                                accept={".png"}
                                data-id={selectedId}
                                onChange={upload}
                            />
                            <input type={"hidden"} name={"bookId"} defaultValue={bookId}/>
                        </form>
                    </div>
                </td>
            </tr>
            </tbody>
        )
    }
    // 스크롤 설정2 메뉴
    else if (props.tabMenu.setting2) {
        return (
            <tbody>
            <tr>
                <td colSpan={colspan}>
                    <select
                        value={scrollObjectMode}
                        onChange={event.scrollObjectModeChange}
                        className={"scroll-select-rect"}
                    >
                        <option value={"mask"}>キャラクター発言</option>
                        <option value={"block"}>ブロック</option>
                        <option value={rikaAnswerType.answer.button.name}>解答ボタン</option>
                        <option value={rikaAnswerType.answer.contents.name}>解答マスク</option>
                    </select>
                    <input type={"button"} value={"追加"} className={"small-btn"} onClick={event.addObject}/>
                </td>
            </tr>

            <tr className={connectHide}>
                <td colSpan={colspan}>
                    紙面クリックポイント連動
                </td>
            </tr>
            <tr className={connectHide}>
                <td colSpan={colspan}>
                    <select
                        value={subViewSettingsRect ? subViewSettingsRect.rectId || "" : ""}
                        onChange={event.objectConnectChange}
                        className={"rect-connect-select"}
                    >

                        <option value=''>{"連動なし"}</option>
                        { selectedMaskId ? rectObj.map((item) => {
                            return (
                                <option
                                    key={item.originId}
                                    value={item.originId}>{item.id}
                                </option>
                            )
                        }) : ""}
                    </select>
                    <input type={"button"} value={"SVGコピー"} className={"small-btn " + maskSettingsHide} onClick={() => event.svgCopy(subViewSettingsRect)}/>
                </td>
            </tr>
            <tr className={blockSettingsHide}>
                <td className={"input-area"}>
                    <div className={"input-text"}>グループ</div>
                    <div className={"input-value"}>
                        <input
                            type={"number"}
                            name={"group"}
                            value={subViewSettingsRect ? subViewSettingsRect.group || "" : ""}
                            onChange={event.changeBlockValue}
                            min={0}/>
                    </div>
                </td>
            </tr>
            <SelectInputColor
                label={propertyInputLabel.maskColorSelect}
                options={maskColorOptions}
                val={colorSelectValue}
                eventHandler={event.colorSelect}
                hide={maskSettingsHide}
            />
            <TextInputColor
                label={propertyInputLabel.maskColorText}
                name={propertyInputName.maskColorText}
                val={colorTextValue || ""}
                onChange={event.colorTextChange}
                color={color || ""}
                hide={maskSettingsHide}
            />
            <tr className={maskSettingsHide}>
                <td className="table-key">マスク画像</td>
                <td className="table-value">
                    <label htmlFor={"uploadMask"}>
                        <div className={"subview-buttons small float-left"}>
                            <span className={"button-text"}>追加/変更</span>
                        </div>
                    </label>
                    <div className={"subview-buttons small float-left " + imageDeleteHide}
                         onClick={() => event.deleteScrollRectImage(scrollRectIdx)}>
                        <span className={"button-text"}>削除</span>
                    </div>
                </td>
            </tr>
            <SvgSubView
                changeRectDataSVG={svgData.changeRectDataSVG}
                togglePanel={svgData.togglePanel}
                item={svgData.item}
                panel={svgData.panel}
                id={svgData.id}
                page={svgData.page}
                blockId={svgData.blockId}
                objMode={svgData.objMode}
                mode={svgData.mode}
                svg={svgData.svg}
                subViewType={svgData.subViewType}
                hide={optionsName !== subViewRect.rikaMask}
            />
            <tr className={"tr-space " + answerSettingsHide}></tr>
            <tr className={answerSettingsHide}>
                <td className={"input-area"}>
                    <div className={"input-text"}>グループ</div>
                    <div className={"input-value"}>
                    <input
                        type={"number"}
                        name={"group"}
                        value={subViewSettingsRect ? subViewSettingsRect.group || 0 : 0}
                        onChange={event.changeAnswerValue}
                        min={0}
                    />
                    </div>
                </td>
            </tr>
            <tr className={answerSettingsHide}>
                <td className={"input-area"}>
                    <div className={"input-text"}>背景色選択</div>
                    <div className={"input-value"}>
                        <select name={"color"} onChange={event.changeAnswerValue} value={subViewSettingsRect ? subViewSettingsRect.color || "" : ""}>
                            {answerColorCode.map((color, idx) => {
                                return <option key={idx} value={color.val}>{color.name}</option>
                            })}
                        </select>
                    </div>
                </td>
            </tr>
            <tr className={answerSettingsHide}>
                <td className={"input-area"}>
                    <div className={"input-text"}>背景色コード</div>
                    <div className={"input-value"}>
                        <input
                            type={"text"}
                            name={"color"}
                            value={subViewSettingsRect ? subViewSettingsRect.color || "" : ""}
                            onChange={event.changeAnswerValue}
                        />
                    </div>
                </td>
            </tr>
            <tr className={answerSettingsHide}>
                <td className={"input-area"}>
                    <div className={"input-text"}>マスク画像</div>
                    <label htmlFor={"uploadMask"}>
                        <div className={"subview-buttons small"}>
                            <span className={"button-text"}>追加/変更</span>
                        </div>
                    </label>
                    <div className={"subview-buttons small " + imageDeleteHide}
                         onClick={() => event.deleteScrollRectImage(scrollRectIdx)}>
                        <span className={"button-text"}>削除</span>
                    </div>
                </td>
            </tr>
            <tr className={answerSettingsHide}>
                <td className={"input-area"}>
                    <div className={"input-text"}>初期マスク<br/>ON/OFF</div>
                    <div className="switch input-value">
                        <label className="switch_label">
                            <input type="checkbox" className="switch_input" name={"maskOn"}
                                   onChange={event.changeAnswerValue}
                                   checked={subViewSettingsRect ? subViewSettingsRect.maskOn || false : false}
                            />
                            <span className="switch_content"/>
                            <span className="switch_circle"/>
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div className={"hide"}>
                        <form method={"post"} encType={"multipart/form-data"}>
                            <input
                                type={"file"}
                                id={"uploadMask"}
                                accept={".png"}
                                onChange={(e) => scrollRectImageUpload(e, scrollRectIdx)}
                            />
                            <input type={"hidden"} name={"bookId"} defaultValue={bookId}/>
                        </form>
                    </div>
                </td>
            </tr>
            </tbody>
        )
    } else if (props.tabMenu.setting3) {
        return (
            <tbody>
                {posSizeInput(subViewSettingsRect, event.changeScrollRectCss)}
            </tbody>
        );
    } else {
        return (<></>);
    }

}

export function ShakaiMaskTable(props) {
    const {event, rectMask, subViewSettingsMask, selectedMaskId,
        maskColorOptions, colorSelectValue, colorTextValue, color, maskSettingsHide} = props;
    const colSpan = 2;
    if (props.tabMenu.setting) {
        return (
            <tbody>
            <tr>
                <td colSpan={colSpan}>
                    <input type={"button"} value={"追加"} className={"block_btn"} onClick={event.addMask}/>
                </td>
            </tr>
            <tr>
                <td colSpan={colSpan}>
                    紙面クリックポイント連動
                </td>
            </tr>
            <tr>
                <td colSpan={colSpan}>
                    <select
                        value={subViewSettingsMask ? subViewSettingsMask.rectId || "" : ""}
                        onChange={event.maskConnectChange}
                        className={"rect-connect-select"}
                    >

                        <option value=''>{"連動なし"}</option>
                        { selectedMaskId ? rectMask.map((item) => {
                            return (
                                <option
                                    key={item.originId}
                                    value={item.originId}>{item.id}
                                </option>
                            )
                        }) : ""}
                    </select>
                </td>
            </tr>
            <SelectInputColor
                label={propertyInputLabel.maskColorSelect}
                options={maskColorOptions}
                val={colorSelectValue}
                eventHandler={event.colorSelect}
                hide={maskSettingsHide}
            />
            <TextInputColor
                label={propertyInputLabel.maskColorText}
                name={propertyInputName.maskColorText}
                val={colorTextValue || ""}
                onChange={event.colorTextChange}
                color={color || ""}
                hide={maskSettingsHide}
            />
            </tbody>
        )
    } else if (props.tabMenu.setting2) {
        return (
            <tbody>
                {posSizeInput(subViewSettingsMask, event.changeMaskCss)}
            </tbody>
        );
    } else {
        return (<></>);
    }

}

export function SansuSlideTable(props) {
    const {event, selectedId, bookId, upload, titleImage, scrollImage,
        scrollTitleEdit, currentIdx, inActive} = props;
    const {answerProps} = props;
    const colspan = 2;
    const cssDisabled = scrollImage[currentIdx] ? "" : "hide";
    const headerMenuDisabled = scrollTitleEdit ? `${inActive}` : `${inActive} ${cssDisabled}`;
    const width = !scrollTitleEdit ? scrollImage[currentIdx] ? scrollImage[currentIdx].width : 0 : titleImage.width;
    const height = !scrollTitleEdit ? scrollImage[currentIdx] ? scrollImage[currentIdx].height : 0 : titleImage.height;
    const top = !scrollTitleEdit ? scrollImage[currentIdx] ? scrollImage[currentIdx].top : 0 : titleImage.top;
    const left = !scrollTitleEdit ? scrollImage[currentIdx] ? scrollImage[currentIdx].left : 0 : titleImage.left;

    // 스크롤 설정1 메뉴
    if (props.tabMenu.setting) {
        return (
            <tbody>
            <tr>
                <td colSpan={colspan}>
                    <label htmlFor={"uploadScroll"}>
                        <div className={"subview-buttons default-buttons"}>
                            <span className={"button-text"}>スライド画像追加</span>
                        </div>
                    </label>
                </td>
            </tr>
            <tr>
                <td colSpan={colspan}>
                    <div className={"subview-buttons default-buttons"} onClick={() => event.scrollImageDelete(false)}>
                        <span className={"button-text"}>スライド画像初期化</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td colSpan={colspan}>
                    <div className={"scroll-list"}>
                        { scrollImage.map((item, idx) => {
                            return (
                                <div key={item.id}>
                                    <p
                                        className={currentIdx === idx ? "scroll-image-selected scroll-list-item" : "scroll-list-item"}
                                        onClick={() => event.scrollListClick(idx)}
                                    >
                                        <span className={"scroll-image-item"}>IMAGE-{idx+1}</span>
                                        <label htmlFor={"changeScroll" + idx}>
                                            <span className={"subview-buttons scroll-image-button"}>
                                                変更
                                            </span>
                                        </label>
                                        <span
                                            className={idx === 0 && scrollImage.length !== 1 ? "hide": "subview-buttons scroll-image-button"}
                                            onClick={(e) => event.scrollImageDeleteOne(e, idx)}>
                                            削除
                                        </span>
                                    </p>

                                    <div className={"hide"}>
                                        <form method={"post"} encType={"multipart/form-data"}>
                                            <input
                                                type={"file"}
                                                id={"changeScroll" + idx}
                                                name={"scroll"}
                                                accept={".png"}
                                                data-id={selectedId}
                                                onChange={(e) => upload(e, idx)}
                                            />
                                            <input type={"hidden"} name={"bookId"} defaultValue={bookId}/>
                                        </form>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </td>
            </tr>
            <tr className={headerMenuDisabled}>
                <td className={"td-title-long"}>幅</td>
                <td>
                    <input
                        type={"number"}
                        name={"width"}
                        value={width || ""}
                        onChange={event.changeScrollImageCss}
                    />
                </td>
            </tr>
            <tr className={headerMenuDisabled}>
                <td className={"td-title-long"}>縦</td>
                <td>
                    <input
                        type={"number"}
                        name={"height"}
                        value={height || ""}
                        onChange={event.changeScrollImageCss}
                    />
                </td>
            </tr>
            <tr className={headerMenuDisabled}>
                <td className={"td-title-long"}>Y座標</td>
                <td>
                    <input
                        type={"number"}
                        name={"top"}
                        value={top || 0}
                        onChange={event.changeScrollImageCss}
                    />
                </td>
            </tr>
            <tr className={headerMenuDisabled}>
                <td className={"td-title-long"}>X座標</td>
                <td>
                    <input
                        type={"number"}
                        name={"left"}
                        value={left || 0}
                        onChange={event.changeScrollImageCss}
                    />
                </td>
            </tr>
            <tr>
                <td>
                    <div className={"hide"}>
                        <form method={"post"} encType={"multipart/form-data"}>
                            <input
                                type={"file"}
                                id={"uploadTitle"}
                                name={"title"}
                                accept={".png"}
                                data-id={selectedId}
                                onChange={upload}
                            />
                            <input type={"hidden"} name={"bookId"} defaultValue={bookId}/>
                        </form>
                        <form method={"post"} encType={"multipart/form-data"}>
                            <input
                                type={"file"}
                                id={"uploadScroll"}
                                name={"scroll"}
                                accept={".png"}
                                data-id={selectedId}
                                onChange={upload}
                            />
                            <input type={"hidden"} name={"bookId"} defaultValue={bookId}/>
                        </form>
                    </div>
                </td>
            </tr>
            </tbody>
        )
    }
    // 스크롤 설정2 메뉴
    else if (props.tabMenu.setting2) {
        return (
            SansuAnswerTable(answerProps)
        );
    } else {
        return (<></>);
    }

}

export function SansuRestudyTable(props) {
    const {event, subViewSettingsRestudy} = props;
    if (props.tabMenu.setting) {
        return (
            <tbody>
            <tr>
                <td>
                    <input type={"button"} value={"ふり返り追加"} className={"block_btn"} onClick={event.addRestudy}/>
                </td>
            </tr>
            <tr>
                <td className={"input-area"}>
                    <div className={"input-text"}>ページ</div>
                    <div className={"input-value"}>
                        <input
                            type={"number"}
                            name={"dataPage"}
                            value={subViewSettingsRestudy ? subViewSettingsRestudy.dataPage || 0 : 0}
                            onChange={event.changeRestudyData}/>
                    </div>
                </td>
            </tr>
            <tr>
                <td className={"input-area"}>
                    <div className={"input-text"}>ふり返りID</div>
                    <div className={"input-value"}>
                        <input
                            type={"text"}
                            name={"dataId"}
                            value={subViewSettingsRestudy ? subViewSettingsRestudy.dataId || "" : ""}
                            onChange={event.changeRestudyData}
                        />
                    </div>
                </td>
            </tr>
            </tbody>
        )
    } else if (props.tabMenu.setting2) {
        return (
            <tbody>
            {posSizeInput(subViewSettingsRestudy, event.changeRestudyCss)}
            </tbody>
        )
    } else {
        return (<></>);
    }
}

function SelectInputColor(props) {
    const {label, eventHandler, options, hide} = props;

    const styles = {
        // selectBox
        control: styles => ({
            ...styles,
            backgroundColor: "#454545",
            border: "1px solid #676767",
            width: "100%",
        }),
        // select 영역과 화살표 영역 분리해주는 style 선
        indicatorSeparator : () => ({
            display: "none",
        }),
        // select 메뉴영역
        menu: styles => ({
            ...styles,
            width: 100,
            backgroundColor: "#454545",
            border: "1px solid #676767",
            // position: "relative",
        }),
        // select 메뉴아이템(?) 영역
        menuList: styles => ({
            ...styles,
            maxHeight: 170,
        }),
    };

    return (
        <tr className={hide}>
            <td className="table-key">{label}</td>
            <td className="table-value">
                <label>
                    <Select
                        options={options}
                        onChange={eventHandler}
                        value={props.val}
                        styles={styles}
                    />
                </label>
            </td>
        </tr>
    );
}

function TextInputColor(props) {
    const {label, name, val, onChange, color="", hide=""} = props;
    return (
        <tr className={hide}>
            <td className="table-key">{label}</td>
            <td className="table-value">
                <label>
                    <input type="text" name={name} value={val} onChange={onChange}/>
                </label>
                <span className={color ? "color-sample" : "hide"} style={{backgroundColor: color}}/>
            </td>
        </tr>
    )
}

function posSizeInput(selectedItem, onChange) {
    return (
        <>
            <tr>
                <td className={"input-area"}>
                    <div className={"input-text"}>横</div>
                    <div className={"input-value"}>
                        <input
                            type={"number"}
                            name={"width"}
                            value={selectedItem ? selectedItem.width || 0 : 0}
                            onChange={onChange}
                        />
                    </div>
                </td>
            </tr>
            <tr>
                <td className={"input-area"}>
                    <div className={"input-text"}>縦</div>
                    <div className={"input-value"}>
                        <input
                            type={"number"}
                            name={"height"}
                            value={selectedItem ? selectedItem.height || 0 : 0}
                            onChange={onChange}
                        />
                    </div>
                </td>
            </tr>
            <tr>
                <td className={"input-area"}>
                    <div className={"input-text"}>X座標</div>
                    <div className={"input-value"}>
                        <input
                            type={"number"}
                            name={"left"}
                            value={selectedItem ? selectedItem.left || 0 : 0}
                            onChange={onChange}
                        />
                    </div>
                </td>
            </tr>
            <tr>
                <td className={"input-area"}>
                    <div className={"input-text"}>Y座標</div>
                    <div className={"input-value"}>
                        <input
                            type={"number"}
                            name={"top"}
                            value={selectedItem ? selectedItem.top || 0 : 0}
                            onChange={onChange}
                        />
                    </div>
                </td>
            </tr>
        </>
    )
}