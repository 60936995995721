export function getMetaDataModel() {
	return {
		bookId: "",
		coverPath: "",
		subject: "",
		semester: 0,
		grade: 0,
		lastModified: "",
		author: "",
		totalPages: 0,
		view: {
			width: 0,
			height: 0,
		},
		direction: "",
		saved: 0,
		status: "ready",
		_id: "meta_data",
		chapter: [],
		bookIndex: {},
		dummyList: [],
		tooltip: "use",
	}
}

export function getPageDataModel(_id = "") {
	return {
		objects: {
			subView: [],
			pageLink: [],
			videoLink: [],
		},
		_id,
	}
}

export function getImageMapModel() {
	return {
		page: {
			"1": "",
			"2": "",
		},
		repo: {},
		thumb: {},
		video: {},
		audio: {},
		popup: {},
		_id: "image_map",
	}
}

export function getStorageModel() {
	return {
		page: {},
		image: {},
		thumb: {},
		video: {},
		audio: {},
		pdf: {},
		html: {},
		contents: {},
		srt: {},
		popup: {},
		_id: "repo",
	}
}

export function getPageObjectModel() {
	return {
		objects: {
			subView: [],
			pageLink:
				[],
			videoLink:
				[],
		},
		_id: "",
	}
}

export function getSubViewModel() {
	return {
		id: "",
		options: {
			link: {
				level: 0,
				zIndex: 0,
			},
			iFrame : false,
		},
		css: {
			top: 0,
			left: 0,
			position: "absolute",
			height: 0,
			width: 0
		},
		data: {
			path: "",
			class: "",
		},
		editor: {
			myEditorImage_bg: "",
			myEditorImage: "",
			ignore: false,
		}
	}
}

export function getVideoLinkModel() {
	return {
		id: "",
		options: {
			link: {
				level: 3,
				zIndex: 1
			}
		},
		css: {
			top: 0,
			left: 0,
			position: "absolute",
			height: 0,
			width: 0
		},
		data: {
			file: "",
			class: "icon video",
			tooltip: "",
			title: "",
		},
		editor: {
			ignore: 1
		}
	}
}

export function getAudioLinkModel() {
	return {
		id: "",
		options: {
			link: {
				level: 2,
				zIndex: 0
			},
			iFrame: false
		},
		css: {
			top: 0,
			left: 0,
			position: "absolute",
			height: 0,
			width: 0
		},
		data: {
			index: 0,
			file: ""
		}
	}
}

export function getEditorDataModel() {
	const subViewDataModel = getSubViewModel();
	return {
		...subViewDataModel,
		data: {
			...subViewDataModel.data,
			class: "editor-only",
		}
	}
}

export function getIconDataModel() {
	const subViewDataModel = getSubViewModel();
	if (subViewDataModel.hasOwnProperty('editor')) delete subViewDataModel.editor;
	return {
		...subViewDataModel,
		options: {
			...subViewDataModel.options,
			link: {
				level: 3,
				zIndex: 1,
			},
			iFrame: true,
		},
		css: {
			top: 0,
			left: 0,
			position: "absolute",
			height: 0,
			width: 0
		},
	}
}

export function getPageLinkModel() {
	return  {
		id: "",
		options: {
			link: {
				level: 2,
			}
		},
		css: {
			top: 0,
			left: 0,
			position: "absolute",
			height: 0,
			width: 0
		},
		data: {
			page: 1,
		},
	}
}

export function getMeaningModel() {
	return {
		id: "",
		options: {
			link: {
				level: 1
			}
		},
		css: {
			top: 0,
			left: 0,
			position: "absolute",
			height: 0,
			width: 0
		},
		data: {
			class: ""
		}
	}
}

export function getFormModel() {
	return {
		id: "",
		options: {
			link: {
				level: 1
			}
		},
		css: {
			top: 0,
			left: 0,
			position: "absolute",
		},
		data: {
			descr: 1,
		}
	}
}

export function getRedLineModel() {
	return  {
		id: "",
		options: {
			link: {
				level: 0
			}
		},
		css: {
			top: 0,
			left: 0,
			position: "absolute",
			height: 0,
			width: 0
		},
		data: {
			visible: "hidden"
		}
	}
}

export function getBlockModel() {
	return  {
		id: "",
		options: {
			link: {
				level: 0
			}
		},
		css: {
			top: 0,
			left: 0,
			position: "absolute",
			height: 0,
			width: 0,
			outline: ".2em solid black"
		},
		data: {
			show: false,
			color: "#FFF",
			class: "blocked"
		}
	}
}

export function getMaskModel() {
	return  {
		id: "",
		options: {
			link: {
				level: 3
			}
		},
		css: {
			top: 0,
			left: 0,
			position: "absolute",
			height: 0,
			width: 0,
		},
		data: {
			class: "unmasked"
		}
	}
}

export function getPostItModel() {
	return {
		id: "",
		options: {
			link: {
				level: 0
			}
		},
		css: {
			top: 0,
			left: 0,
			position: "absolute",
			height: 0,
			width: 0
		}
	}
}

export function getCheckModel() {
	return  {
		id: "",
		options: {
			link: {
				level: 1
			}
		},
		css: {
			top: 0,
			left: 0,
			position: "absolute",
			height: 0,
			width: 0,
		},
		data: {
			class: "check1 invisible"
		}
	}
}

export function getPopupModel() {
	return  {
		id: "",
		options: {
			link: {
				level: 2
			}
		},
		css: {
			top: 0,
			left: 0,
			position: "absolute",
			height: 0,
			width: 0,
		},
		data: {
			popup: {
				position: "",
				type: "",
				title: "",
				desc: "",
			},
		}
	}
}

export function getDefaultDataModel() {
	return {
		id: "",
		options: {
			link: {
				level: 0
			}
		},
		css: {
			top: 0,
			left: 0,
			position: "absolute",
			height: 0,
			width: 0
		}
	}
}

export function getImportAudioDataModel() {
	return {
		originId: "",
		id: "",
		page: 0,
		css: {
			width: 0,
			height: 0,
			top: 0,
			left: 0
		},
		data: {
			index: 0,
			fileKey: "",
			file: "",
			playAt: 0,
			moveAt: 0,
		}
	}
}

export function getImportAudioTextDataModel() {
	return {
		css: {
			top: 0,
			left: 0,
			width: 0,
			height: 0,
			position: "absolute"
		},
		val: 0,
		time: 0,
		start: 0
	}
}

export function getSVGModel() {
	return {
		type: "polygon",
		attr: {
			points: "0,0 100,0 100,100 0,100"
		}
	}
}


export function getRestudyModel() {
	return {
		id: "",
		options: {
			link: {
				level: 3
			}
		},
		css: {
			top: 0,
			left: 0,
			position: "absolute",
			height: 0,
			width: 0
		},
		data: {
			class: "icon looking_back",
			book: {
				page: 0,
				name: "",
				subject: "sansu",
				setName: "",
				option: "soft"
			}
		},
	}
}


export function getGuideModeModel() {
	return {
		id: "",
		options: {
			link: {
				level: 0
			},
			group: {
				index: 0,
				name: "guideMode"
			}
		},
		css: {},
		data: {
			path: ""
		},
	}
}
