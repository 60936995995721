import React, {Component} from 'react';
import ReactTooltip from "react-tooltip";

class InitToolTip extends Component {
    render() {
        return (
            <>
                <ReactTooltip id={"right"} place={"right"} class={"tooltip-right"} effect="solid" multiline={true}/>
                <ReactTooltip id={"left"} place={"left"} class={"tooltip-left"} effect="solid" multiline={true}/>
                <ReactTooltip id={"top"} place={"top"} class={"tooltip-top"} effect="solid" multiline={true}/>
                <ReactTooltip id={"bottom"} place={"bottom"} class={"tooltip-bottom"} effect="solid" multiline={true}/>
            </>
        )
    }
}

export default InitToolTip;