import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { dragType } from "../../../../../interface/constant";

const GroupItem = ({id, originId, groupIndex, index, moveItem, moveEnd, deleteItem, deleteBtn, groupDrag, groupCSS}) => {
	const ref = useRef(null);
	const [, drop] = useDrop({
		accept: dragType.GROUP_ITEM + groupIndex,
		hover: (item, monitor) => {
			if (!ref.current) return;

			const dragIndex = item.index;
			const hoverIndex = index;
			if (dragIndex === hoverIndex) return;

			const hoverBoundingRect = ref.current.getBoundingClientRect();
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
			const clientOffset = monitor.getClientOffset();
			const hoverClientY = clientOffset.y - hoverBoundingRect.top;

			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;
			if(groupDrag !== false){
				moveItem(dragIndex, hoverIndex);
				item.index = hoverIndex;
			}
		},
	});

	const deleteFunc = () => {
		deleteItem(originId);
	};

	const [{isDragging}, drag] = useDrag({
		item: {type: dragType.GROUP_ITEM + groupIndex, id, originId, index},
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
		canDrag: groupDrag
	});
	const opacity = isDragging ? 0 : 1;
	drag(drop(ref));

	return (
		<div className={groupCSS} ref={ref} style={{opacity}}>
			<span>{index + 1}. {id}</span>
			<div className={"delete-group-item " +deleteBtn} onClick={deleteFunc}>X</div>
		</div>
	)
};
export default GroupItem
