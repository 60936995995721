import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './SelectBoxWithLabel.scss';

class SelectBoxWithLabel extends Component {
	render() {
		return (
			<div className="select-box-with-label">
				<div className="label">{this.props.label}</div>
				<div className="select-box-container">
					<select
						className="select-box"
						value={this.props.value}
						name={this.props.name}
						onChange={this.props.onChange || null}
					>
						{Object.entries(this.props.option).map(([key, val]) => {
							return (<option key={`${key}_${val}`} value={key}>{val}</option>)
						})}
					</select>
				</div>
			</div>
		)
	}
}

SelectBoxWithLabel.propTypes = {
	label: 	PropTypes.string.isRequired,

};

export default SelectBoxWithLabel;