import React, {Component} from 'react';
import {connect} from 'react-redux';
import Finder from '../../../components/Common/Finder';
import TypeSelect from '../../../components/Contents/Editor/Panels/RepoPanel/TypeSelect';
import {addRepo, addMultiFileRepo, deleteRepo, uploadMulti} from "../../../interface/api";
import {removeFromRepo, modifyRepoPath, modifyRepoMulti} from "../../../reducers/CombineReducers/repo";
import {now} from "../../../interface/utils";
import {modalMsg, modalTitle, repoUpload} from "../../../interface/constant"
import Contents from "../../../components/Contents/Editor/Panels/RepoPanel/Contents/Contents";
import Modal from "../../../components/Common/Modal";

const MAX_FILE_SIZE_MB = 1024;  // please write same value of LIMIT_CAPACITY of server.js

const mapStateToProps = (state) => {
	return {
		bookId: state.metaData.bookId,
		author: state.metaData.author,
		auth: state.auth,
		repo: state.repo,
		...state.repo
	};
};

const mapDispatchToProps = {
	removeFromRepo: removeFromRepo,
	modifyRepoPath: modifyRepoPath,
	modifyRepoMulti: modifyRepoMulti,
};

const typeConst = {
	page: "ページ",
	image: "画像",
	video: "動画",
	audio: "音声",
	thumb: "サムネール",
	pdf: "PDF",
	html: "HTMLコンテンツ",
	srt: "字幕",
	popup: "ポップアップ",
	kakezu: "掛図タイトル",
};

const typeUploadConst = repoUpload;

class RepoMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			type: "page",
			loading: false,
			showModalOverMaxCapacity: false,
			showModalFileUploadError: false,
			uploadFileKey: "",
		}
		this.errorProcess = null;
	}

	//id検索
	onClickSubmit = (e) => {
		e.preventDefault();
		console.log("e.currentTarget.value: ", e.currentTarget.value);
		console.log(e.target);
	};

	//type
	onChangeType = (e) => {
		const type = e.currentTarget.value;
		this.setState({
			...this.state,
			type,
		});
	};

	//ファイル登録
	async uploadMulti(e) {
		const files = e.currentTarget.files;
		const form = e.target.parentElement;
		const id = e.currentTarget.id;
		try {
			let fileSizeSum = 0;
			for (let file of files) fileSizeSum += file.size;
			let fileSizeMB = ((fileSizeSum / 1024) / 1024).toFixed(2);
			if (fileSizeMB > MAX_FILE_SIZE_MB) {
				this.toggleModal(true);
				return;
			}

			this.setState({loading: true});
			const {type} = this.state;
			let pathInfo = await uploadMulti(form);

			let repoData = {};
			let isChange = false;
			if (id !== "uploadMulti") {
				let repo = this.props[this.state.type];
				for (let item of Object.values(pathInfo)) {
					let keyArr = Object.keys(repo).filter(repoKey => repo[repoKey].originalname === item.originalname);
					if (keyArr.length > 0) {
						for (let key of keyArr) {
							repoData[key] = {
								...item,
								regDate: now(),
							};
						}
					}
				}
				isChange = true;
			} else {
				repoData = RepoMenu.pathInfoToStorage(pathInfo);
			}

			const result = await addMultiFileRepo(this.props.bookId, type, repoData, isChange);

			this.setState({
				loading: false,
			});

			if (!result.fileUpload) {
				this.showErrorModal(result.fileKey);
			} else {
				/*
				this.props.modifyRepoMulti({
					kind: type,
					data: repoData,
				});
				*/
				this.props.socket.updateRepo(this.props.bookId, type, repoData, "modifyMulti", this.props.auth.info.id);
			}
		} catch (err) {
			console.log(err);
		} finally {
			form.reset();
		}
	};

	static pathInfoToStorage(pathInfo) {
		let repoData = {};
		for (let key of Object.keys(pathInfo)) {
			let item = pathInfo[key];
			repoData[key] = {
				path: item.path,
				originalname: item.originalname,
				regDate: now(),
			}
		}
		return repoData;
	}

	onChange = async (kind, key, path, originalname) => {
		let repoData = {key, path, originalname, kind};
		this.props.modifyRepoPath(repoData);
		repoData.regDate = now();
		await addRepo(this.props.bookId, repoData, true);
		this.props.socket.updateRepo(this.props.bookId, "", repoData, "modify", this.props.auth.info.id);
	};

	onDelete = async (kind, key) => {
		let repoData = {key, kind};
		await this.props.removeFromRepo(repoData);

		await deleteRepo(this.props.bookId, kind, key);
		this.props.socket.updateRepo(this.props.bookId, "", repoData, "remove", this.props.auth.info.id);
	};

	showErrorModal(uploadFileKey) {
		this.setState({
			uploadFileKey,
		})
		this.errorProcess = (e) => {
			e.stopPropagation();
			this.toggleModal("FileUploadError", false);
			this.errorProcess = () => {};
		};
		this.toggleModal("FileUploadError", true);
	}

	toggleModal(type = "OverMaxCapacity", show = true) {
		let modalType = `showModal${type}`;
		this.setState({
			...this.state,
			[modalType]: show,
		})
	}

	render() {
		return (
			<>
				{this.state.loading && <div className={"loading-area"}>
					<div className="loading-spinner"/>
				</div>}
				<div className="finder-sort-area">
					<Finder onClickSubmit={this.onClickSubmit}/>
					<TypeSelect typeInfo={typeConst} onChangeType={this.onChangeType}/>
				</div>
				<div className="main-content-area">
					<Contents
						type={this.state.type}
						bookId={this.props.bookId}
						data={this.props[this.state.type]}
						hideDelete={this.props.author !== this.props.auth.info.id ? "hide" : ""}
						onDelete={this.onDelete}
						onChange={this.onChange}
					/>
				</div>
				<div className="repo-button-area">
					<div className={"repo-file-button"}>
						<label htmlFor={"uploadMulti"}>複数のファイルを登録</label>
					</div>
					<div className={"repo-file-button"}>
						<label htmlFor={"uploadOverWrite"}>複数のファイルを差し替え</label>
					</div>
				</div>
				<div className={"hidden-forms hide"}>
					<form method={"post"} encType={"multipart/form-data"}>
						<input type="hidden" value={this.props.bookId} name={"bookId"}/>
						<input type="hidden" value={this.state.type} name={"type"}/>
						<input
							type="file"
							multiple={true}
							name="multi"
							id="uploadMulti"
							accept={typeUploadConst[this.state.type]}
							onChange={this.uploadMulti.bind(this)}
						/>
						<input
							type="file"
							multiple={true}
							name="multi"
							id="uploadOverWrite"
							accept={typeUploadConst[this.state.type]}
							onChange={this.uploadMulti.bind(this)}
						/>
					</form>
				</div>
				{this.state.showModalOverMaxCapacity && <Modal
					onClickConfirm={() => this.toggleModal(false)}
					msg={modalMsg.OVER_CAPACITY.replace('{MAX}', `${MAX_FILE_SIZE_MB}`)}
					title={modalTitle.ERROR}
				/>}
				{this.state.showModalFileUploadError && <Modal
					onClickConfirm={(e) => this.errorProcess(e)}
					msg={modalMsg.FILE_EXIST.replace('{FILENAME}', `${this.state.uploadFileKey}`)}
					title={modalTitle.ERROR}
				/>}
			</>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(RepoMenu);