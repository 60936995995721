import React, {Component} from 'react';
import {connect} from 'react-redux';
import './Log.scss';
import Title from '../../../components/Common/Title';
import {componentTitle, userLevel} from "../../../interface/constant";
import EditBookLog from "../../../components/Contents/Log/EditBookLog";
import EditAdminLog from "../../../components/Contents/Log/EditAdminLog";
import LoginLog from "../../../components/Contents/Log/LoginLog";

const mapStateToProps = ({current, auth}) => ({current, auth});
const mapDispatchToProps = {};

class Log extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: '1',
        };
    }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.props.current.contents === "log" || nextProps.current.contents === "log";
  }

    LogChange(e) {
        this.setState({
            selected: e.target.value
        });


    }

  logElem() {
    return (
        <div className={"log-content"}>
          <div className="log-content-center">
            <select className={"select-box"} onChange={(e) => this.LogChange(e)}>
                <option value='1'> 操作</option>
                <option value='2'> 管理者</option>
                <option value='3'> ログイン</option>
            </select>
              {this.contentsElem()}

          </div>
        </div>
    );
  }

  contentsElem() {
        const {selected} = this.state;

        if (selected === '1') {
            return (<EditBookLog/>)
        }else if (selected === '2') {
            return (<EditAdminLog/>);
        }else if (selected === '3') {
            return (<LoginLog/>);
        }
  }

  render() {
    const {level} = this.props.auth.info;
    return (
      <div id={"Log"} className={this.props.className + " log-component-area"}>
        <Title
          titleName={componentTitle.LOG}
          onclickX={()=>{}}
        />
        { level === userLevel.admin ? this.logElem() : ""}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Log);