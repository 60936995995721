import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";

import {
	TextProperty, SelectProperty, NumberProperty, SelectImageProperty,
} from "../../../../components/Contents/Editor/Panels/PropertyPanel/innerProperty";
import {
	changeBookData,
	changeRectId,
	changeRectPage,
	changeRectOptions,
	changeRectSVG,
	changeRectData,
} from '../../../../reducers/CombineReducers/pageData';
import {
	objectModeConst,
	propertyInputLabel,
	propertyInputName,
	propertyToolTip,
	maskColorOptions,
} from '../../../../interface/constant';
import { setSelectItem, changeEditorMode } from "../../../../reducers/CombineReducers/current";
import PosSizeProperty from "../../../../components/Contents/Editor/Panels/PropertyPanel/posSizeProperty";
import {togglePanel} from "../../../../reducers/CombineReducers/panel";
import {checkNested} from "../../../../interface/utils";
import SvgProperty from "../../../../components/Contents/Editor/Panels/PropertyPanel/SvgProperty";

const mapStateToProps = (state) => {
	const objMode = objectModeConst[state.current.editor.objectMode];
	let prop = {
		objMode,
		pages: state.current.editor.pages,
		mode: state.current.editor.mode,
		view: state.metaData.view,
		bookId: state.metaData.bookId,
		panel: state.panel,
		direction: state.metaData.direction,
		bookChanged: state.current.book.changed,
	};
	let {id, page} = state.current.editor.selected;
	if (id && page) {
		let objectInfo = state.pageData[page].objects[objMode];
		prop.item =objectInfo.filter((item) => item.originId === id)[0];
		prop.id = id;
		prop.page = page;
	}
	return prop;
};

const mapDispatchToProps = dispatch => {
	return {
		changeBookData: (state) => dispatch(changeBookData(state)),
		changeRectId: (state) => dispatch(changeRectId(state)),
		changeRectPage: (state) => dispatch(changeRectPage(state)),
		setSelectItem: (state) => dispatch(setSelectItem(state)),
		togglePanel: (state) => dispatch(togglePanel(state)),
		changeEditorMode: (state) => dispatch(changeEditorMode(state)),
		changeRectOptions: (state) => dispatch(changeRectOptions(state)),
		changeRectSVG: (state) => dispatch(changeRectSVG(state)),
		changeRectData: (state) => dispatch(changeRectData(state)),
	}
};

let property = {
	currentId: "",
	changedId: "",
	page: 0,
	objMode: "",
	item: {}
};

class MaskTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: "",
			propertyCss: {
				width: "",
				height: "",
				top: "",
				left: "",
			},
			zIndex: 0,
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps === prevState.prevProps) return null;
		const propertyCss = MaskTable.arrangeCss(prevState.propertyCss, nextProps.item.css);
		const svg = checkNested(nextProps, "item.svg.attr.points") ? nextProps.item.svg.attr.points : "";
		return {propertyCss, svg}
	}

	static arrangeCss(prevCss, nextCss) {
		return {
			...nextCss,
			width: nextCss ? prevCss.width === "" ? prevCss.width : nextCss.width : "",
			height: nextCss ? prevCss.height === "" ? prevCss.height : nextCss.height: "",
			top: nextCss ? prevCss.top === "" ? prevCss.top : nextCss.top: "",
			left: nextCss ? prevCss.left === "" ? prevCss.left : nextCss.left: "",
		}
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.props.item.id !== prevProps.item.id) {
			const id = this.props.item.id || "";
			const propertyCss = this.props.item.css;
			const zIndex = checkNested(this.props.item, "options.link.zIndex") ? this.props.item.options.link.zIndex : 0;
			this.setState({
				id: id,
				propertyCss,
				zIndex,
			})
		}
	}

	eventHandler() {
		return {
			onChange: (e) => {
				this.setState({
					...this.state,
					propertyCss: {
						...this.state.propertyCss,
						[e.target.name]: e.target.value,
					}
				});

				const value = e.target.value || 0;
				this.props.changeBookData({
					id: this.props.id,
					page: this.props.page,
					css: {
						[e.target.name]: (e.target.name !== 'opacity' ? parseInt(value, 10) : Number(value))
					},
					objMode: this.props.objMode
				});
			},
			pageChange: (e) => {
				let page = Number(e.target.value);
				this.props.changeRectPage({
					id: this.props.id,
					page: page,
					width: this.props.view.width / 2,
					objMode: this.props.objMode,
					direction: this.props.direction,
				});
				this.props.setSelectItem({
					id: this.props.id,
					page: page
				});
			},
			optionsChange: (e) => {
				let {page, objMode, item} = property;
				const itemOptions = Object.keys(this.props.item).length !== 0 ? this.props.item.options : item.options;
				let options = this.props.setOptionsValue(e.target, itemOptions);
				this.props.changeRectOptions({
					id: this.props.id,
					page: this.props.page || page,
					options: {
						[options.name]: options.value
					},
					objMode: this.props.objMode || objMode,
				});
			},
			dataChange: (e) => {
				let value = e.target.value ? e.target.value[0] !== "#" ? "#" + e.target.value : e.target.value : "";
				if (e.target.name === propertyInputName.maskColorText) value = value.toUpperCase();
				const data = {
					[e.target.name]: value,
				};
				this.props.changeRectData({
					id: this.props.id,
					page: this.props.page,
					data: data,
					objMode: this.props.objMode,
				});
			},
			colorSelect: (color) => {
				const value = color.value;
				const data = {
					fill: value,
				};
				this.props.changeRectData({
					id: this.props.id,
					page: this.props.page,
					data: data,
					objMode: this.props.objMode,
				});
			},
		}
	}

	setPropertyId(e) {
		property.currentId = this.props.id;
		property.changedId = e.target.value;
		property.page = this.props.page;
		property.objMode = this.props.objMode;
		property.item = this.props.item;
	}

	changeId(e) {
		this.setState({
			id: e.target.value
		});
		property.changedId = e.target.value;
	}

	changePageDataId() {
		let {currentId, changedId, page, objMode} = property;
		this.props.changeRectId({
			currentId: currentId,
			changedId: changedId || currentId,
			page: page,
			objMode: objMode
		});
	}

	setLabel(color) {
		if(color.value === "") {
			return (
				<div>
					<div className={"mask-color-select-contents"}>
						<span className={"mask-color-select-text"}>{color.name}</span>
					</div>
				</div>
			);
		}
		return (
			<div>
				<div className={"mask-color-select-contents"} style={{backgroundColor: color.value}}>
					<span className={"mask-color-select-text"}>{color.value}</span>
				</div>
			</div>
		)
	}

	render() {
		const {id, page, pages, item} = this.props;
		const pageInfoOptions = this.props.pages ? [
			{val: pages.left, name: pages.left},
			{val: pages.right, name: pages.right}
		] : [];

		maskColorOptions.map((color) => {
			color.label = this.setLabel(color);
			return color;
		});

		const selectedColorValue = maskColorOptions.filter((icon) => icon.value === item.data.fill);
		const colorValue = selectedColorValue.length <= 0 ? maskColorOptions[0] : selectedColorValue;

		const colorCodeValue = item.data.fill || "";
		const colorCheck = /^#[0-9a-f]{3,6}$/i;	// 컬러코드 유효성 체크 정규식
		const isValidColor = colorCheck.test(colorCodeValue) ? colorCodeValue : "";

		return (
			<div className="inner-table">
				<table>
					<tbody>
					<TextProperty
						label={propertyInputLabel.id}
						name={propertyInputName.id} val={this.state.id}
						readOnly={false}
						onFocus={this.setPropertyId.bind(this)}
						onBlur={this.changePageDataId.bind(this)}
						onChange={this.changeId.bind(this)}
						tooltipText={propertyToolTip.id}
					/>
					<SelectProperty
						label={propertyInputLabel.page}
						name={propertyInputName.page} options={pageInfoOptions}
						eventHandler={this.eventHandler().pageChange} val={page}
						tooltipText={propertyToolTip.page}
					/>
					<NumberProperty
						label={propertyInputLabel.zIndex}
						name={propertyInputName.zIndex}
						val={item.options.link ? item.options.link.zIndex || "" : ""}
						onChange={this.eventHandler().optionsChange}
						tooltipText={propertyToolTip.zIndex}
					/>
					<PosSizeProperty css={this.state.propertyCss} eventHandler={this.eventHandler()}/>

					<SelectImageProperty
						label={propertyInputLabel.maskColorSelect}
						name={propertyInputName.maskColorSelect}
						options={maskColorOptions}
						val={colorValue}
						eventHandler={this.eventHandler().colorSelect}
					/>
					<TextProperty
						label={propertyInputLabel.maskColorText}
						name={propertyInputName.maskColorText}
						val={colorCodeValue || ""}
						color={isValidColor || ""}
						onChange={this.eventHandler().dataChange}
					/>
					<SvgProperty
						changeRectSVG={this.props.changeRectSVG}
						changeEditorMode={this.props.changeEditorMode}
						togglePanel={this.props.togglePanel}
						item={item}
						panel={this.props.panel}
						id={this.props.id}
						page={this.props.page}
						objMode={this.props.objMode}
						mode={this.props.mode}
						svg={this.state.svg}
					/>
					</tbody>
				</table>
				<div className="inner-mask" style={{display: id ? "none" : ""}}/>
			</div>
		)
	}
}

MaskTable.defaultProps = {
	item: {
		originId: "",
		id: "",
		css: {},
		options: {},
		page: 0,
		data: {},
	},
	page: 0,
	id: ""
};

MaskTable.propTypes = {
	item: PropTypes.shape({
		originId: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		css: PropTypes.object.isRequired,
		options: PropTypes.object.isRequired,
		data: PropTypes.object.isRequired,
		page: PropTypes.number.isRequired
	}).isRequired,
	page: PropTypes.number.isRequired,
	id: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(MaskTable);