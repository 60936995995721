import React, { Component } from 'react';
import PropTypes from "prop-types";
import DropZone from "../DropZone";
import GuideModeItem from "./GuideModeItem";
import {dragType} from "../../../../../interface/constant";

export default class GuideModeEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hide: false,
		};
	}

	getObjectElem() {
		const item = this.props.item;
		return (
			<DropZone
				accept={dragType.GROUP}
				className={this.props.hide ? "" : "hide"}
				onDrop={this.onDrop.bind(this)}
			>   {item.length <= 0 ? (<span>ドラッグしてください。</span>) : ""}
				{item.map((item, idx) => (
					<GuideModeItem
						key={item.id}
						index={idx}
						groupIndex={this.props.index}
						originId={item.originId}
						id={item.itemId}
						moveItem={this.moveItem.bind(this)}
						moveEnd={this.moveEnd.bind(this)}
						deleteItem={this.deleteItem.bind(this)}
					>
						{item.id}
					</GuideModeItem>
				))}
			</DropZone>
		)
	}

	moveItem(dragIndex, hoverIndex) {
		console.log("tearDrops");
		this.props.swapGroup(this.props.id, dragIndex, hoverIndex);
	}

	moveEnd() {
		console.log("tearDropsEnd");
		this.props.swapEnd(this.props.id);
	}

	deleteItem(originId) {
		this.props.deleteItem(this.props.id, originId);
	}

	onDrop(item) {
		let groupItem = {
			originId: item.originId,
			id: item.id,
			page: item.page,
		};
		this.props.addGroupOnList(this.props.id, groupItem)
	}

	groupToggle() {
		this.setState({hide: !this.state.hide})
	}

	render() {
		return (
			<div className="group-edit-container">
				<div className={"group-header"}>
					<div className={"group-toggle"} onClick={this.groupToggle.bind(this)}>
						{this.state.hide ? "▼" : "▶"}
					</div>
					<div className={"group-name"}>
						<input
							type={"text"}
							name={"groupName"}
							value={this.props.id}
							readOnly={true}
						/>
					</div>
				</div>
				<div className={"group-contents" + (this.state.hide ? " hide" : "")}>
					{this.getObjectElem()}
				</div>
			</div>
		)
	}
}


GuideModeEdit.defaultProps = {
	id: "",
	item: [],
};
GuideModeEdit.propTypes = {
	id: PropTypes.string.isRequired,
	item: PropTypes.array.isRequired,
	addGroupOnList: PropTypes.func.isRequired,
	swapGroup: PropTypes.func.isRequired,
	swapEnd: PropTypes.func.isRequired,
	deleteItem: PropTypes.func.isRequired,
};