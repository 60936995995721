import React, { Component } from 'react';
import './TopPanel.scss';

import TopPanelMenu from "../../../../../containers/Contents/Editor/TopPanelMenu";

class TopPanel extends Component {
	render() {
		return (
			<div id={"TopPanel"} className={"panel-top"}>
				<TopPanelMenu/>
			</div>
		)
	}
}

export default TopPanel;