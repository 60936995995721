import React, { Component } from "react";
import { connect } from 'react-redux';
import {
	topPanelPageViewType,
	topPanelMyEditorType,
	subjectKeyName,
	topPanelMyEditorTextType,
	topPanelObjIdToggle,
	topPanelBgToggle,
} from "../../../interface/constant";
import {
	setZoom,
	changeEditorMode,
	unsetSelectItem,
	changeObjectMode,
	changePageViewType,
	changeIsImageBg,
	changeIsTextMode,
	toggleObjectIdShow,
	toggleBgShow,
} from "../../../reducers/CombineReducers/current";
import { togglePanel } from "../../../reducers/CombineReducers/panel";
import AddToolTip from "../../../components/Common/AddToolTip";

const mapStateToProps = (state) => {
	return {
		panel: state.panel,
		zoom: state.current.editor.scale,
		editorMode: state.current.editor.mode,
		selectedId: state.current.editor.selected.id,
		pageViewType: state.current.editor.pageViewType,
		objectMode: state.current.editor.objectMode,
		isImageBg: state.current.editor.isImageBg,
		isIdOn: state.current.editor.isIdOn,
		isBgOn: state.current.editor.isBgOn,
		subject: state.metaData.subject,
		mode: state.current.editor.mode,
		isTextMode: state.current.editor.isTextMode,
	}
};

const mapDispatchToProps = {
	setZoom,
	changeEditorMode,
	unsetSelectItem,
	changeObjectMode,
	changePageViewType,
	changeIsImageBg,
	changeIsTextMode,
	togglePanel,
	toggleObjectIdShow,
	toggleBgShow,
};

class TopPanelMenu extends Component {
	toggle(e) {
		const dataId = e.currentTarget.getAttribute("data-id");
		this.props.togglePanel({
			name: dataId,
			isOpen: !this.props.panel[dataId],
		});
	}

	zoom(e) {
		this.props.setZoom({
			scale: parseFloat(e.target.value)
		})
	}

	zoomWheel(e) {
		const {zoom} = this.props;
		const scale = zoom.toFixed(1);
		const up = e.deltaY < 0;
		const down = e.deltaY > 0;

		if (down && scale <= e.target.min) return;
		if (up && scale >= e.target.max) return;

		let addZoom = up ? Number(e.target.step) : Number(e.target.step) * -1;

		this.props.setZoom({
			scale: zoom + addZoom
		})
	}

	toggleEditorMode(e) {
		const dataId = e.currentTarget.getAttribute("data-id");
		const allowUseGroupMember = ["subView", "icon"]; // 그룹이 열리는걸 허용하는 대상
		const useGroup = (allowUseGroupMember.includes(this.props.objectMode));
		if (dataId === "edit" && (["icon", "videoLink"].includes(this.props.objectMode))) return;
		if (dataId === "group" && !useGroup) return;
		if (this.props.selectedId) this.props.unsetSelectItem();
		this.toggle(e);

		const mode = (this.props.editorMode !== dataId) ? dataId : "edit";
		this.props.changeEditorMode({mode});
	}

	toggleObjIdShow() {
		this.props.toggleObjectIdShow();
	}

	toggleBgShow() {
		this.props.toggleBgShow();
	}

	getBtnClassName = (dataId) => {
		const isActive = this.checkBtnActive(dataId);
		const toggled = this.props.panel[dataId];
		return "toggle-icon" + (toggled ? " toggled" : "") + (isActive ? "" : " de-active");
	};

	checkBtnActive(dataId) {
		if (dataId === "draw") {
			const isTumazuki = this.props.objectMode === "popupLink" && (this.props.subject === subjectKeyName.sansu || this.props.subject === subjectKeyName.rika);
			if (isTumazuki) return false;
			if (["icon", "videoLink", "form", "huri"].includes(this.props.objectMode)) return false;
			if (["group", "linkPages", "guideMode"].includes(this.props.mode)) return false;
		} else if (dataId === "group") {
			if (["draw", "linkPages", "guideMode"].includes(this.props.mode)) return false;
		}
		return true;
	}

	objectModeChange(e) {
		const mode = e.target.value;
		if (mode === "guideMode") {
			this.props.changeEditorMode({mode: "guideMode"});
			this.props.togglePanel({
				name: "guideMode",
				isOpen: !this.props.panel["guideMode"],
			});
		} else {
			if (this.props.mode === "guideMode") this.props.changeEditorMode({mode: "edit"});
			if (this.props.panel.guideMode) {
				this.props.togglePanel({
					name: "guideMode",
					isOpen: false,
				});
			}
		}
		this.props.changeObjectMode({mode});
		if (this.props.selectedId) this.props.unsetSelectItem();
	}

	pageViewTypeChange() {
		const {pageViewType} = this.props;
		const {all, left, right} = topPanelPageViewType;
		let text = "";
		let currentType = topPanelPageViewType[pageViewType];
		if (currentType === all) text = "left";
		else if (currentType === left) text = "right";
		else if (currentType === right) text = "all";
		this.props.changePageViewType({pageViewType: text});
		if (this.props.selectedId) this.props.unsetSelectItem();
	}

	changeIsImageBg() {
		this.props.changeIsImageBg();
	}

	getToggleBtnElem() {
		const btnList = {
			property: "属性",
			page: "ページ",
			list: "リスト",
			repo: "レポジトリ",
			screen: "拡大画面",
		};
		return Object.keys(btnList).map(key => {
			return (
				<div
					key={key}
					className={this.getBtnClassName(key)}
					onClick={this.toggle.bind(this)}
					data-id={key}
				>
					<AddToolTip text={btnList[key]} direction={"bottom"} isQuestion={false}/>
					{btnList[key]}
				</div>
			)
		})
	}

	changeIsTextMode() {
		this.props.changeIsTextMode();
	}

	myEditorBtnContainer() {
		return (
			<div className={"myEditor-mode-btn-container " + (this.props.objectMode === "myEditor" ? "show" : "hide")}>
				<div
					className={"toggle-icon toggle-id-btn" + (this.props.isBgOn ? " toggled" : "")}
					onClick={this.toggleBgShow.bind(this)}
				>
					{topPanelBgToggle[this.props.isBgOn ? 1 : 0]}
				</div>
				<div
					className="toggle-icon"
					onClick={this.changeIsImageBg.bind(this)}
				>
					{topPanelMyEditorType[this.props.isImageBg ? 1 : 0]}
				</div>
				<div
					className="toggle-icon"
					onClick={this.changeIsTextMode.bind(this)}
				>
					{topPanelMyEditorTextType[this.props.isTextMode ? 1 : 0]}
				</div>
			</div>
		)
	}

	subViewBtnContainer() {
		return (
			<div className={"subView-mode-btn-container " + (this.props.objectMode === "myEditor" ? "hide" : "show")}>
				<div
					className={"toggle-icon toggle-id-btn" + (this.props.isBgOn ? " toggled" : "")}
					onClick={this.toggleBgShow.bind(this)}
				>
					{topPanelBgToggle[this.props.isBgOn ? 1 : 0]}
				</div>
				<div
					className={"toggle-icon toggle-bg-btn " + (this.props.isIdOn ? " toggled" : "")}
					onClick={this.toggleObjIdShow.bind(this)}
				>
					{topPanelObjIdToggle[this.props.isIdOn ? 1 : 0]}
				</div>
				<div
					className="toggle-icon page-view-type toggled"
					onClick={this.pageViewTypeChange.bind(this)}
				>
					{topPanelPageViewType[this.props.pageViewType]}
				</div>
				<div
					className={this.getBtnClassName("draw")}
					onClick={this.toggleEditorMode.bind(this)}
					data-id="draw"
				>
					<span>ドラッグ</span>
				</div>
				<div
					className={this.getBtnClassName("group")}
					onClick={this.toggleEditorMode.bind(this)}
					data-id="group"
				>
					<span>グループ</span>
				</div>
			</div>
		)
	}

	render() {
		const zoomText = Math.floor(this.props.zoom * 100) + "%";
		return (
			<React.Fragment>
				<div className={"toggle-wrapper"}>{this.getToggleBtnElem()}</div>

				<div className={"zoom-wrapper"}>
					<span className={"zoom-minus"}>-</span>
					<input
						type={"range"}
						value={this.props.zoom}
						min={0.5} max={1.5} step={0.1}
						className={"zoom-slider"}
						onChange={(e) => this.zoom(e)}
						onWheel={(e) => this.zoomWheel(e)}
					/>
					<span className={"zoom-plus"}>+</span>
					<span className={"zoom-text"}>{zoomText}</span>
				</div>
				<div className="mode-wrapper">
					<select className={"mode-select"} onChange={(e) => this.objectModeChange(e)}>
						<option value={"subView"}>標準モード</option>
						<option value={"myEditor"}>MY教科書エディター</option>
						<option value={"icon"}>アイコン</option>
						<option value={"videoLink"}>動画</option>
						<option value={"pageLink"}>ページリンク</option>
						{(this.props.subject === subjectKeyName.kokugo || this.props.subject === subjectKeyName.doutoku) ?
							<option value={"audioLink"}>音声読み上げ</option> : ""}
						{(this.props.subject === subjectKeyName.kokugo) ?
							<option value={"meaning"}>意味段落</option> : ""}
						{(this.props.subject === subjectKeyName.kokugo) ?
							<option value={"form"}>形式段落</option> : ""}
						{(this.props.subject === subjectKeyName.kokugo) ?
							<option value={"popupLink"}>ポップアップ</option> : ""}
						{(this.props.subject === subjectKeyName.shakai) ?
							<option value={"redLine"}>赤線</option> : ""}
						{(this.props.subject === subjectKeyName.shakai) ?
							<option value={"block"}>マスク</option> : ""}
						{(this.props.subject === subjectKeyName.shakai) ?
							<option value={"guideMode"}>指導支援モード</option> : ""}
						{(this.props.subject === subjectKeyName.rika) ?
							<option value={"mask"}>キャラクター発言</option> : ""}
						{(this.props.subject === subjectKeyName.rika) ?
							<option value={"block"}>ブロック</option> : ""}
						{(this.props.subject === subjectKeyName.rika) ?
							<option value={"check"}>チェックボックス</option> : ""}
						{(this.props.subject === subjectKeyName.rika) ?
							<option value={"popupLink"}>つまずきポイント</option> : ""}
						{(this.props.subject === subjectKeyName.sansu) ?
							<option value={"mask"}>めくり紙</option> : ""}
						{(this.props.subject === subjectKeyName.sansu) ?
							<option value={"restudy"}>ふり返り</option> : ""}
						{(this.props.subject === subjectKeyName.sansu) ?
							<option value={"popupLink"}>つまずきポイント</option> : ""}
					</select>
				</div>
				<div className="etc-wrapper">
					{(this.props.objectMode === "myEditor") ? this.myEditorBtnContainer() : this.subViewBtnContainer()}
				</div>
			</React.Fragment>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TopPanelMenu);