// import produce from "immer";
const TOGGLE_PANEL = "TOGGLE_PANEL";

const initialState = {
	property: true,
	list: true,
	page: true,
	draw: true,
	repo: false,
	screen: false,
	group: false,
	linkPages: false,
	svg: false,
	subviewSvg: false,
	popup: false,
	guideMode: false,
};

//Reducer
export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case TOGGLE_PANEL: {
			return {
				...state,
				[action.name]: action.isOpen,
			};
		}
		default: {
			return { ...state }
		}
	}
}

//Action Generators
export function togglePanel(state) {
	return {
		type: TOGGLE_PANEL,
		name: state.name,
		isOpen: state.isOpen,
	}
}