import React, {Component} from 'react';
import {connect} from 'react-redux';
import './SubviewMenu.scss';
import {
    changeRectDataBlock,
    changeRectDataBlockKey,
    removeRectDataBlock,
    changeRectDataSvg,
    changeRectData
} from "../../../reducers/CombineReducers/pageData";
import {
    objectModeConst,
    subViewType,
    keyModel,
    keyAction,
    rikaScroll,
    subViewRect,
    sansuAnswerType,
    rikaAnswerType,
    dataModeConst,
    subViewSize
} from "../../../interface/constant";
import {setSelectSubBlockId, unsetSelectSubBlockId} from "../../../reducers/CombineReducers/current";
import Rect from "../../../components/Contents/Editor/Panels/EditorPanel/Rect";
import Draggable from "react-draggable";
import {clamp, makeColorCodeHSL, getSubViewSettings, findSubViewType} from "../../../interface/utils";

const mapStateToProps = state => {
    const {id, page, blockId} = state.current.editor.selected;
    const {editor} = state.current;
    const objMode = objectModeConst[editor.objectMode];
    let props = {};
    if (id && page) {
        let objectList = state.pageData[page].objects[objMode];
        const idx = objectList.findIndex((item) => item.originId === id);
        const subViewSettings = editor.objectMode === "subView" ? objectList[idx].data.subViewSettings : null;
        props = {
            selectedId: id,
            selectedPage: page,
            selectedBlockId: blockId,
            repoImage: state.repo.image,
            screen: state.panel.screen,
            scale: state.current.editor.scale,
            grade: state.metaData.grade,
            subViewSettings,
        }
    }
    props.view = state.metaData.view;
    props.panel = state.panel;
    props.subject = state.metaData.subject;
    props.direction = state.metaData.direction;

    return props;
};
const mapDispatchToProps = {
    setSelectSubBlockId,
    unsetSelectSubBlockId,
    changeRectDataBlock,
    changeRectDataBlockKey,
    removeRectDataBlock,
    changeRectDataSvg,
    changeRectData
};

const {ORDER_CHANGE, ORDER_DELETE} = keyAction;

const defaultScale = 0.7;
let subViewScale = defaultScale;

class SubviewContents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isResizing: false,
            scrollTop: 0,
        };

        const isLarge = getSubViewSettings(props.subViewSettings, "isLargeView", false);

        const widht = isLarge ? subViewSize.large.width : subViewSize.default.width;
        const height = isLarge ? subViewSize.large.height : subViewSize.default.height;

        const widthScale = widht * defaultScale / this.props.view.width;
        const heightScale = height * defaultScale / this.props.view.height;
        subViewScale = widthScale > heightScale ? heightScale : widthScale;

        this.subviewIframeRef = React.createRef();
    }

    getWidth() {
        return getSubViewSettings(this.props.subViewSettings, "isLargeView", false) ? subViewSize.large.width : this.props.view.width;
    }

    getHeight() {
        return getSubViewSettings(this.props.subViewSettings, "isLargeView", false) ? subViewSize.large.height : this.props.view.height;
    }

    getImage() {
        const {subViewSettings, screen} = this.props;
        if (!subViewSettings || !subViewSettings.options || (!subViewSettings.options.imgPath && !subViewSettings.options.subViewImages)) return "";
        let image = "";
        if (screen && subViewSettings.options.imgPath) {
            const src = this.props.repoImage[subViewSettings.options.imgPath].path;
            let addStyle = {
                width: subViewSettings.options.widthMax ? this.getWidth() : subViewSettings.options.width,
                height: subViewSettings.options.heightMax ? this.getHeight() : subViewSettings.options.height,
                top: subViewSettings.options.top || 0,
                left: subViewSettings.options.left || 0,
                opacity: 1,
            };
            if (subViewSettings.options.margin) addStyle.margin = subViewSettings.options.margin;
            image = src ? <img src={src} alt={"subView"} style={addStyle}/> : "";
        } else if (screen && subViewSettings.options.subViewImages) {
            image = subViewSettings.options.subViewImages.map((subViewImage, idx) => {
                const src = this.props.repoImage[subViewImage.imgPath].path;
                let addStyle = {
                    width: subViewImage.widthMax ? this.getWidth() : subViewImage.width,
                    height: subViewImage.heightMax ? this.getHeight() : subViewImage.height,
                    top: subViewImage.top || 0,
                    left: subViewImage.left || 0,
                    opacity: 1,
                };
                this.addMargin(addStyle, subViewImage);
                this.addPadding(addStyle, subViewImage);
                if (subViewImage.margin) addStyle.margin = subViewImage.margin;
                return src ? <img key={idx} src={src} alt={"subView"} style={addStyle}/> : "";
            });
        }

        return image;
    }

    addMargin(addStyle, subViewImage) {
        if (subViewImage.marginTop || subViewImage.marginBottom || subViewImage.marginLeft || subViewImage.marginRight) {
            addStyle.marginTop = subViewImage.marginTop || 0;
            addStyle.marginBottom = subViewImage.marginBottom || 0;
            addStyle.marginLeft = subViewImage.marginLeft || 0;
            addStyle.marginRight = subViewImage.marginRight || 0;
            delete subViewImage.margin;
        }
    }

    addPadding(addStyle, subViewImage) {
        if (subViewImage.paddingTop || subViewImage.paddingBottom || subViewImage.paddingLeft || subViewImage.paddingRight) {
            addStyle.paddingTop = subViewImage.paddingTop || 0;
            addStyle.paddingBottom = subViewImage.paddingBottom || 0;
            addStyle.paddingLeft = subViewImage.paddingLeft || 0;
            addStyle.paddingRight = subViewImage.paddingRight || 0;
            delete subViewImage.padding;
        }
    }

    getSize(e, idx = -1) {
        const {subViewSettings} = this.props;
        if (idx < 0) {
            subViewSettings.options.width = e.target.width;
            subViewSettings.options.height = e.target.height;
        } else {
            subViewSettings.options.subViewImages[idx].width = e.target.width;
            subViewSettings.options.subViewImages[idx].height = e.target.height;
        }
    }

    getContents() {
        const {subViewSettings, screen} = this.props;
        if (!screen) return;
        const type = subViewSettings ? subViewSettings.type : 0;
        switch (type) {
            case subViewType.shakaiDesc:
                return this.descContents();
            case subViewType.shakaiRedLine:
                const redLine = subViewSettings.options ? subViewSettings.options.redLine || [] : [];
                return redLine.map((item) => this.redLineContents(item));
            case subViewType.rikaBlock:
                const block = subViewSettings.options ? subViewSettings.options.block || [] : [];
                return block.map((item) => this.blockContents(item));
            case subViewType.rikaMask:
                const mask = subViewSettings.options ? subViewSettings.options.mask || [] : [];
                return mask.map((item) => this.maskContents(item));
            case subViewType.rikaCheck:
                const check = subViewSettings.options ? subViewSettings.options.check || [] : [];
                return check.map((item) => this.checkContents(item));
            case subViewType.rikaScroll:
                return this.rikaScrollContents();
            case subViewType.sansuAnswer:
                const answer = subViewSettings.options ? subViewSettings.options.answer || [] : [];
                return answer.map((item) => this.sansuAnswerContents(item));
            case subViewType.kokugoPageLink:
                return;
            case subViewType.subMenu:
                return this.subMenuContents();
            case subViewType.rikaAnswer:
                const rikaAnswer = subViewSettings.options ? subViewSettings.options.answer || [] : [];
                return rikaAnswer.map((item) => this.rikaAnswerContents(item));
            case subViewType.subViewInSubView:
                const subViewLink = subViewSettings.options ? subViewSettings.options.subViewLink || [] : [];
                return subViewLink.map((item) => this.subViewInSubViewContents(item));
            case subViewType.rikaScrollNew:
                return this.rikaScrollNewContents();
            case subViewType.shakaiMask:
                const shakaiBlock = subViewSettings.options ? subViewSettings.options.block || [] : [];
                return shakaiBlock.map((item) => this.blockContents(item));
            case subViewType.sansuScroll:
                return this.sansuScrollContents();
            case subViewType.sansuRestudy:
                const restudy = subViewSettings.options ? subViewSettings.options.restudy || [] : [];
                return restudy.map((item) => this.sansuRestudyContents(item));
            case subViewType.subPopup:
                const subPopup = subViewSettings.options ? subViewSettings.options.subPopup || [] : [];
                return subPopup.map((item) => this.getSubPopupContents(item));
            case subViewType.subMovePage:
                const subMovePage = subViewSettings.options ? subViewSettings.options.subMovePage || [] : [];
                return subMovePage.map((item) => this.getSubMovePageContents(item));
            default:
                return;
        }
    }

    /**
     * 확대화면 링크를 가져오는 메서드
     * @returns {*}
     */
    getSubViewInSubViewContents() {
        const {subViewSettings} = this.props;
        const type = subViewSettings ? subViewSettings.type : 0;

        switch (type) {
            case subViewType.common:
            case subViewType.shakaiDesc:
            case subViewType.shakaiRedLine:
            case subViewType.shakaiMask:
            case subViewType.rikaAnswer:
            case subViewType.rikaCheck:
            case subViewType.rikaMask:
            case subViewType.rikaBlock:
            case subViewType.subMovePage:
                const subViewLink = getSubViewSettings(subViewSettings, "subViewLink", []);
                return subViewLink.map((item) => this.subViewInSubViewContents(item));
            default:
                return;
        }
    }

    /**
     * 페이지 이동 오브젝트를 가져오는 메서드
     * 이과 마스크인 경우 페이지 이동을 그리고
     * 페이지 이동인 경우 헤답 컨텐츠를 그림
     * @returns {null|objects} - 화면에 그릴 rect오브젝트
     */
    getMovePageContents() {
        const {subViewSettings} = this.props;
        const type = subViewSettings ? subViewSettings.type : 0;

        switch (type) {
            case subViewType.rikaMask:
                //case subViewType.rikaScrollNew:
                //case subViewType.sansuScroll:
                const subViewLink = getSubViewSettings(subViewSettings, "subMovePage", []);
                return subViewLink.map((item) => this.getSubMovePageContents(item));
            default:
                return;
        }
    }

    getAnswerContents() {
        const {subViewSettings} = this.props;
        const type = subViewSettings ? subViewSettings.type : 0;
        let subViewLink;
        switch (type) {
            case subViewType.subMovePage: //17
                subViewLink = getSubViewSettings(subViewSettings, "answer", []);
                return subViewLink.map((item) => this.rikaAnswerContents(item));
            default:
                return;
        }
    }

    /**
     * 확대화면 링크 오브젝트를 가져오는 메서드
     *
     * @param item
     * @returns {*}
     */
    getSubMovePageContents(item) {
        const scale = 1;
        const selected = (this.props.selectedBlockId === item.id) ? " selected" : "";
        const type = "subMovePage";
        const canResizing = !this.props.panel[dataModeConst.EDIT_SUBVIEW_SVG] && !!selected;
        return (
            <Rect
                key={item.id}
                dragEvent={this.dragEvent(item.id, scale, type)}
                resizeEvent={this.resizeEvent(item.id, scale, type)}
                item={item}
                bounds={".subview-iframe"}
                isRectInActivePage={true}
                position={{
                    x: item.left * scale,
                    y: item.top * scale,
                }}
                size={{
                    width: item.width * scale,
                    height: item.height * scale
                }}
                scale={1}
                subViewScale={subViewScale}
                canDragging={true}
                canResizing={canResizing}
                addClass={""}
                selected={selected}
                zIndex={1}
                isResizing={this.state.isResizing}
            />
        );
    }

    getSubPopupContents(item) {
        const scale = 1;
        const selected = (this.props.selectedBlockId === item.id) ? " selected" : "";
        const type = "subPopup";
        const canResizing = !this.props.panel[dataModeConst.EDIT_SUBVIEW_SVG] && !!selected;
        console.log(item.left);
        return (
            <Rect
                key={item.id}
                dragEvent={this.dragEvent(item.id, scale, type)}
                resizeEvent={this.resizeEvent(item.id, scale, type)}
                item={item}
                bounds={".subview-iframe"}
                isRectInActivePage={true}
                position={{
                    x: item.left * scale,
                    y: item.top * scale,
                }}
                size={{
                    width: item.width * scale,
                    height: item.height * scale
                }}
                scale={1}
                subViewScale={subViewScale}
                canDragging={true}
                canResizing={canResizing}
                addClass={""}
                selected={selected}
                zIndex={1}
                isResizing={this.state.isResizing}
            />
        );
    }

    descContents() {
        const {subViewSettings} = this.props;
        const shakaiDescObjectMode = this.props.shakaiDescObjectMode;
        const rectObj = subViewSettings.options ? subViewSettings.options[shakaiDescObjectMode] || [] : [];
        let divStyle = {};
        let descStyle = {};
        if (subViewSettings.options && subViewSettings.options.isScroll) {
            divStyle = {height: "100%", overflowY: "auto", overflowX: "hidden", position: "relative"};
            descStyle = {position: "fixed"};
        }
        return (
            <div style={divStyle}>
                {this.getImage()}
                {rectObj.map((item) => {
                    let contents = item;

                    if (shakaiDescObjectMode === subViewRect.shakaiMask) contents = this.blockContents(item);
                    else if (shakaiDescObjectMode === subViewRect.shakaiRedLine) contents = this.redLineContents(item);

                    return contents;
                })}
                <div className={"subPage-inner-textLine"} style={descStyle}>
                    <span className={subViewSettings.options.num ? "" : "hide"}
                          style={{border: "solid black 1px", borderRadius: "5px"}}>
                        {subViewSettings.options.num || ""}
                    </span>
                    <span dangerouslySetInnerHTML={{__html: subViewSettings.options.title || ""}}/>
                    <span style={{fontWeight: "normal", paddingLeft: "20px"}}
                          dangerouslySetInnerHTML={{__html: subViewSettings.options.desc || ""}}/>
                </div>
                <div className={"subPage-inner-button"} style={descStyle}>
                    <span>説明</span>
                </div>
            </div>
        );
    }

    redLineContents(item) {
        const scale = 1;
        const selected = (this.props.selectedBlockId === item.id) ? " selected" : "";
        const type = "redLine";
        const rectInText = item.group ? item.group !== "0" ? `${item.group}-group` : "" : "";
        const canResizing = !!selected;
        return (
            <Rect
                key={item.id}
                dragEvent={this.dragEvent(item.id, scale, type)}
                resizeEvent={this.resizeEvent(item.id, scale, type)}
                item={item}
                bounds={".subview-iframe"}
                isRectInActivePage={true}
                position={{
                    x: item.left * scale,
                    y: item.top * scale,
                }}
                size={{
                    width: item.width * scale,
                    height: item.height * scale
                }}
                scale={1}
                subViewScale={subViewScale}
                canDragging={true}
                canResizing={canResizing}
                addClass={" redLine"}
                selected={selected}
                zIndex={1}
                rectInText={rectInText}
                rectInTextClass={"group-num"}
                subViewRectTextHide={""}
                isResizing={this.state.isResizing}
            />
        );
    }

    maskContents(item) {
        const {subViewSettings, repoImage} = this.props;
        const scale = 1;
        const selected = (this.props.selectedBlockId === item.id) ? " selected" : "";
        const type = "mask";
        // 이과 스크롤에서 mask rect 쓸때만 드래그 제한 범위 해제, zIndex 10으로 상승 (스크롤 헤더 이미지가 9라서...)
        const bounds = subViewSettings ? subViewSettings.type === 4 || subViewSettings.type === 12 ? "" : ".subview-iframe" : ".subview-iframe";
        const zIndex = subViewSettings ? subViewSettings.type === 4 || subViewSettings.type === 12 ? 10 : 1 : 1;
        let svgColor = "";
        if (item.fill && !item.image) svgColor = item.fill;

        const subViewAnswerRectCss = {
            backgroundImage: item.image ? repoImage[item.image] : "",
            maskOn: true,
        };

        const canResizing = !this.props.panel[dataModeConst.EDIT_SUBVIEW_SVG] && !!selected;
        return (
            <Rect
                key={item.id}
                dragEvent={this.dragEvent(item.id, scale, type)}
                resizeEvent={this.resizeEvent(item.id, scale, type)}
                item={item}
                bounds={bounds}
                isRectInActivePage={true}
                position={{
                    x: item.left * scale,
                    y: item.top * scale,
                }}
                size={{
                    width: item.width * scale,
                    height: item.height * scale
                }}
                scale={1}
                subViewScale={subViewScale}
                canDragging={!this.props.panel[dataModeConst.EDIT_SUBVIEW_SVG]}
                canResizing={canResizing}
                addClass={" mask"}
                selected={selected}
                zIndex={zIndex}
                svgColor={svgColor}
                subViewAnswerRectCss={subViewAnswerRectCss}
            />
        );
    }

    checkContents(item) {
        const {selectedBlockId} = this.props;
        const scale = 1;
        const selected = (selectedBlockId === item.id) ? " selected" : "";
        const type = "check";
        const isConnect = selectedBlockId ? item.connectId ? item.connectId === selectedBlockId || false : false : false;
        const connectRect = isConnect ? "connected " : "";
        const canResizing = !!selected;
        return (
            <Rect
                key={item.id}
                dragEvent={this.dragEvent(item.id, scale, type)}
                resizeEvent={this.resizeEvent(item.id, scale, type)}
                item={item}
                bounds={".subview-iframe"}
                isRectInActivePage={true}
                position={{
                    x: item.left * scale,
                    y: item.top * scale,
                }}
                size={{
                    width: item.width * scale,
                    height: item.height * scale
                }}
                scale={1}
                subViewScale={subViewScale}
                canDragging={true}
                canResizing={canResizing}
                addClass={` ${item.cls}`}
                selected={selected}
                subViewRectClass={connectRect}
                zIndex={1}
            />
        );
    }

    blockContents(item) {
        const {subViewSettings} = this.props;
        const scale = 1;
        const selected = (this.props.selectedBlockId === item.id) ? " selected" : "";
        const type = "block";
        const addClass = this.props.subViewSettings.type === 13 || this.props.subViewSettings.type === 1 ? "" : " block";
        // 이과 스크롤에서 mask rect 쓸때만 드래그 제한 범위 해제, zIndex 10으로 상승 (스크롤 헤더 이미지가 9라서...)
        const zIndex = subViewSettings ? subViewSettings.type === 4 || subViewSettings.type === 12 ? 10 : 1 : 1;
        let svgColor = "";
        if (item.fill) svgColor = item.fill;
        const canResizing = !!selected;
        return (
            <Rect
                key={item.id}
                dragEvent={this.dragEvent(item.id, scale, type)}
                resizeEvent={this.resizeEvent(item.id, scale, type)}
                item={item}
                bounds={".subview-iframe"}
                isRectInActivePage={true}
                position={{
                    x: item.left * scale,
                    y: item.top * scale,
                }}
                size={{
                    width: item.width * scale,
                    height: item.height * scale
                }}
                scale={1}
                subViewScale={subViewScale}
                canDragging={true}
                canResizing={canResizing}
                addClass={addClass}
                selected={selected}
                zIndex={zIndex}
                svgColor={svgColor}
            />
        );
    }

    rikaScrollContents() {
        const {subViewSettings} = this.props;
        let scrollObjectMode = this.props.scrollObjectMode;
        if (!subViewSettings.options.titleImage && !subViewSettings.options.scrollImage) return "";
        const titleImage = subViewSettings.options.titleImage || null;
        const scrollImage = subViewSettings.options.scrollImage || [];
        const scrollTop = this.props.scrollLevel * rikaScroll.scrollTop;

        if (scrollObjectMode === rikaAnswerType.answer.button.name || scrollObjectMode === rikaAnswerType.answer.contents.name) {
            scrollObjectMode = "answer";
        }

        const scrollObj = subViewSettings.options ? subViewSettings.options[scrollObjectMode] || [] : [];
        return (
            <div>
                {titleImage ? this.getTitleImageElem(titleImage) : ""}
                <div className={"inner-button-up"}>
                    <div className={"subView-inner-button inner-button-left-up"}
                         onClick={() => this.rikaScrollEvent("up")}/>
                    <div className={"subView-inner-button inner-button-right-up"}
                         onClick={() => this.rikaScrollEvent("up")}/>
                </div>
                <div className={"inner-button-down"}>
                    <div className={"subView-inner-button inner-button-left-down"}
                         onClick={() => this.rikaScrollEvent("down")}/>
                    <div className={"subView-inner-button inner-button-right-down"}
                         onClick={() => this.rikaScrollEvent("down")}/>
                </div>
                <div className={"subView-inner-scroll-movable"}
                     style={{position: "absolute", top: scrollTop, left: 0, width: "100%", height: "100%"}}>

                    {scrollObj.map((item) => {
                        let contents = item;

                        if (scrollObjectMode === subViewRect.rikaMask) contents = this.maskContents(item);
                        else if (scrollObjectMode === subViewRect.rikaBlock) contents = this.blockContents(item);
                        else if (scrollObjectMode === subViewRect.rikaAnswer) contents = this.rikaAnswerContents(item);

                        return contents;
                    })}

                    {scrollImage.map((item) => this.getScrollImageElem(item))}

                </div>

            </div>
        );
    }

    sansuAnswerContents(item) {
        const {selectedBlockId, repoImage, subViewSettings} = this.props;
        const scale = 1;
        const selected = (selectedBlockId === item.id) ? " selected" : "";
        const type = "answer";
        const isConnect = selectedBlockId ? item.connectId ? item.connectId === selectedBlockId || false : false : false;
        const connectRect = isConnect ? "connected " : "";
        const answerText = item.text || "";
        const answerGroupText = !answerText && item.group ? `${item.group}-group` : "";
        const rectInTextClass = answerGroupText && !answerText ? "group-num" : "";
        const maskButton = item.type === sansuAnswerType.mask.button ? "ボタン" : "";
        const maskContents = item.type === sansuAnswerType.mask.contents ? "マスク" : "";
        const rectInText = answerText + maskButton + maskContents + answerGroupText;
        let subViewAnswerRectCss = null;
        const bounds = subViewSettings ? subViewSettings.type === 14 ? "" : ".subview-iframe" : ".subview-iframe";
        if (item.type !== "toggleText" && item.type !== "answerText") {
            subViewAnswerRectCss = {
                backgroundImage: item.image ? repoImage[item.image] : "",
                backgroundColor: item.color || "",
                maskOn: item.maskOn || false,
            };
        }

        const canResizing = !!selected;
        return (
            <Rect
                key={item.id}
                dragEvent={this.dragEvent(item.id, scale, type)}
                resizeEvent={this.resizeEvent(item.id, scale, type)}
                item={item}
                bounds={bounds}
                isRectInActivePage={true}
                position={{
                    x: item.left * scale,
                    y: item.top * scale,
                }}
                size={{
                    width: item.width * scale,
                    height: item.height * scale
                }}
                scale={1}
                subViewScale={subViewScale}
                canDragging={true}
                canResizing={canResizing}
                addClass={` answer ${item.type} grade-${this.props.grade}`}
                subViewRectClass={connectRect}
                selected={selected}
                zIndex={1}
                rectInText={rectInText}
                rectInTextClass={rectInTextClass}
                subViewRectTextHide={""}
                isResizing={this.state.isResizing}
                subViewAnswerRectCss={subViewAnswerRectCss}
            />
        );
    }

    rikaAnswerContents(item) {
        const {selectedBlockId, subViewSettings, repoImage} = this.props;
        const scale = 1;
        const selected = (selectedBlockId === item.id) ? " selected" : "";
        const type = "answer";
        const rectInText = item.group ? item.group !== "0" ? `${item.group}-group` : "" : "";

        // 이과 스크롤에서 rect 쓸때만 드래그 제한 범위 해제, zIndex 10으로 상승 (스크롤 헤더 이미지가 9라서...)
        const bounds = subViewSettings ? subViewSettings.type === 4 || subViewSettings.type === 12 ? "" : ".subview-iframe" : ".subview-iframe";
        const zIndex = subViewSettings ? subViewSettings.type === 4 || subViewSettings.type === 12 ? 10 : 1 : 1;

        const subViewAnswerRectCss = {
            backgroundImage: item.image ? repoImage[item.image] : "",
            backgroundColor: item.color || "",
            maskOn: item.maskOn || false,
        };

        const canResizing = !!selected;
        return (
            <Rect
                key={item.id}
                dragEvent={this.dragEvent(item.id, scale, type)}
                resizeEvent={this.resizeEvent(item.id, scale, type)}
                item={item}
                bounds={bounds}
                isRectInActivePage={true}
                position={{
                    x: item.left * scale,
                    y: item.top * scale,
                }}
                size={{
                    width: item.width * scale,
                    height: item.height * scale
                }}
                scale={1}
                subViewScale={subViewScale}
                canDragging={true}
                canResizing={canResizing}
                addClass={` rikaAnswer ${item.type}`}
                selected={selected}
                zIndex={zIndex}
                rectInText={rectInText}
                rectInTextClass={"group-num"}
                subViewRectTextHide={""}
                isResizing={this.state.isResizing}
                subViewAnswerRectCss={subViewAnswerRectCss}
            />
        );
    }

    subMenuContents() {
        const {subViewSettings, repoImage, selectSubMenuContentsIdx} = this.props;
        const title = subViewSettings.options.subMenuTitle || "";
        const contents = subViewSettings.options.subMenuContents || [];
        return (
            <div className={"sub-menu"} onClick={(e) => this.selectSubMenuContents(e, -1)}>
                <div className={"sub-menu-container"}>

                    <div className={"sub-menu-title"}>
                        <div className={"sub-menu-title-img"}>
                            <div className={"sub-menu-img " + title}/>
                        </div>
                    </div>

                    <div className={"sub-menu-contents"}>
                        <div className={"sub-menu-grid"}>
                            {contents.map((item, idx) => {
                                const url = repoImage[item.thumb] ? repoImage[item.thumb].path : "";
                                const selected = selectSubMenuContentsIdx === idx ? "selected" : "";
                                return (
                                    <div
                                        className={"sub-menu-item-box " + selected}
                                        key={"item" + idx}
                                        onClick={(e) => this.selectSubMenuContents(e, idx)}
                                    >
                                        <div className={"sub-menu-item-img-frame " + item.icon}>
                                            <div className={"sub-menu-item-img"}
                                                 style={{backgroundImage: `url("${url}")`}}/>
                                        </div>
                                        <div className={"sub-menu-item-label"}>
                                            <span>{item.text}</span>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                </div>
            </div>
        );
    }

    subViewInSubViewContents(item) {
        const scale = 1;
        const selected = (this.props.selectedBlockId === item.id) ? " selected" : "";
        const type = "subViewLink";
        // const canResizing = !!selected;
        const canResizing = !this.props.panel[dataModeConst.EDIT_SUBVIEW_SVG] && !!selected;
        return (
            <Rect
                key={item.id}
                dragEvent={this.dragEvent(item.id, scale, type)}
                resizeEvent={this.resizeEvent(item.id, scale, type)}
                item={item}
                bounds={".subview-iframe"}
                isRectInActivePage={true}
                position={{
                    x: item.left * scale,
                    y: item.top * scale,
                }}
                size={{
                    width: item.width * scale,
                    height: item.height * scale
                }}
                scale={1}
                subViewScale={subViewScale}
                canDragging={true}
                canResizing={canResizing}
                addClass={""}
                selected={selected}
                zIndex={10}
                isResizing={this.state.isResizing}
            />
        );
    }

    rikaScrollNewContents() {
        const {subViewSettings} = this.props;
        let scrollObjectMode = this.props.scrollObjectMode;
        if (!subViewSettings.options.titleImage && !subViewSettings.options.scrollImage) return "";
        const titleImage = subViewSettings.options.titleImage || null;
        const scrollImage = subViewSettings.options.scrollImage || [];

        if (scrollObjectMode === rikaAnswerType.answer.button.name || scrollObjectMode === rikaAnswerType.answer.contents.name) {
            scrollObjectMode = "answer";
        }

        const scrollObj = subViewSettings.options ? subViewSettings.options[scrollObjectMode] || [] : [];

        const isHorizon = subViewSettings.options && subViewSettings.options.scrollType === "h";
        const upBtnClass = isHorizon ? "left " : "up ";
        const downBtnClass = isHorizon ? "right " : "down ";
        // const scrollMove = isHorizon ? this.getWidth() : this.getHeight();
        const scrollTop = isHorizon ? 0 : this.props.scrollLevel * (this.getHeight() * -1);
        const scrollLeft = isHorizon ? this.props.scrollLevel * (this.getWidth() * -1) : 0;
        const btnHide = scrollImage.length <= 1 ? "hide" : "";

        const subViewLink = getSubViewSettings(subViewSettings, "subViewLink", []);
        const subMovPage = getSubViewSettings(subViewSettings, "subMovePage", []);
        return (
            <div>
                <div className={"inner-button-up " + btnHide}>
                    <div className={"subView-inner-button inner-button-left-" + upBtnClass}
                         onClick={() => this.rikaScrollEvent("up")}/>
                    <div className={"subView-inner-button inner-button-right-" + upBtnClass}
                         onClick={() => this.rikaScrollEvent("up")}/>
                </div>
                <div className={"inner-button-down " + btnHide}>
                    <div className={"subView-inner-button inner-button-left-" + downBtnClass}
                         onClick={() => this.rikaScrollEvent("down")}/>
                    <div className={"subView-inner-button inner-button-right-" + downBtnClass}
                         onClick={() => this.rikaScrollEvent("down")}/>
                </div>
                <div className={"subView-inner-scroll-movable"}
                     style={{position: "absolute", top: scrollTop, left: scrollLeft, width: "100%", height: "100%"}}>

                    {scrollObj.map((item) => {
                        let contents = item;

                        if (scrollObjectMode === subViewRect.rikaMask) contents = this.maskContents(item);
                        else if (scrollObjectMode === subViewRect.rikaBlock) contents = this.blockContents(item);
                        else if (scrollObjectMode === subViewRect.rikaAnswer) contents = this.rikaAnswerContents(item);

                        return contents;
                    })}

                    {scrollImage.map((item, idx) => this.getScrollNewImageElem(item, idx, isHorizon))}

                    {subViewLink.map((item) => this.subViewInSubViewContents(item))}
                    {subMovPage.map((item) => this.getSubMovePageContents(item))}
                </div>
                {titleImage ? this.getTitleImageElem(titleImage) : ""}
            </div>
        );
    }

    sansuScrollContents() {
        const {subViewSettings} = this.props;
        if (!subViewSettings.options.scrollImage) return "";
        const scrollImage = subViewSettings.options.scrollImage || [];
        let scrollObjectMode = "answer";

        const scrollObj = subViewSettings.options ? subViewSettings.options[scrollObjectMode] || [] : [];

        const scrollLeft = this.props.scrollLevel * (this.getWidth() * -1);
        const subMovPage = getSubViewSettings(subViewSettings, "subMovePage", []);
        return (
            <div>
                <div className={"inner-button-up"}>
                    <div className={"sansu-subView-inner-button sansu-inner-button-left"}
                         onClick={() => this.rikaScrollEvent("up")}/>
                </div>
                <div className={"inner-button-down"}>
                    <div className={"sansu-subView-inner-button sansu-inner-button-right"}
                         onClick={() => this.rikaScrollEvent("down")}/>
                </div>
                <div className={"subView-inner-scroll-movable"}
                     style={{position: "absolute", top: 0, left: scrollLeft, width: "100%", height: "100%"}}>

                    {scrollObj.map((item) => {
                        let contents = item;

                        if (scrollObjectMode === subViewRect.sansuAnswer) contents = this.sansuAnswerContents(item);

                        return contents;
                    })}

                    {scrollImage.map((item, idx) => this.getSansuScrollImageElem(item, idx))}
                    {subMovPage.map((item) => this.getSubMovePageContents(item))}

                </div>

            </div>
        );
    }

    sansuRestudyContents(item) {
        const scale = 1;
        const selected = (this.props.selectedBlockId === item.id) ? " selected" : "";
        const type = "restudy";
        const addClass = " icon looking_back";
        const canResizing = !!selected;
        return (
            <Rect
                key={item.id}
                dragEvent={this.dragEvent(item.id, scale, type)}
                resizeEvent={this.resizeEvent(item.id, scale, type)}
                item={item}
                bounds={""}
                isRectInActivePage={true}
                position={{
                    x: item.left * scale,
                    y: item.top * scale,
                }}
                size={{
                    width: item.width * scale,
                    height: item.height * scale
                }}
                scale={1}
                subViewScale={subViewScale}
                canDragging={true}
                canResizing={canResizing}
                addClass={addClass}
                selected={selected}
                zIndex={1}
                isResizing={this.state.isResizing}
            />
        );
    }

    selectSubMenuContents(e, idx) {
        e.stopPropagation();
        this.props.stateChange("selectSubMenuContentsIdx", idx);
    }

    getTitleImageElem(titleImage) {
        const titleSrc = this.props.repoImage[titleImage.path].path;
        const titleStyle = {
            width: titleImage.width,
            top: titleImage.height,
            left: titleImage.left,
        };
        return (
            <img src={titleSrc} alt={""} style={titleStyle}/>
        )
    }

    getScrollImageElem(scrollImage) {
        const scrollSrc = this.props.repoImage[scrollImage.path].path;
        const scrollStyle = {
            width: scrollImage.width,
            left: scrollImage.left,
            position: "relative",
        };
        return (
            <div key={scrollImage.id}>
                <div style={{height: scrollImage.height}}></div>
                <img src={scrollSrc} style={scrollStyle} alt={""}/>
            </div>
        )
    }

    getScrollNewImageElem(scrollImage, idx, isHorizon) {
        const {width, height} = this.props.view;
        const scrollSrc = this.props.repoImage[scrollImage.path].path;
        const scrollStyle = {
            width: scrollImage.width,
            height: scrollImage.imageHeight,
            top: scrollImage.height,
            left: scrollImage.left,
            position: "relative",
        };
        let divStyle = {
            width: width,
            height: height,
            position: "absolute"
        };
        const scrollMove = isHorizon ? this.getWidth() : this.getHeight();
        if (isHorizon) divStyle.left = scrollMove * idx;
        else divStyle.top = scrollMove * idx;
        return (
            <div key={scrollImage.id} style={divStyle}>
                <img src={scrollSrc} style={scrollStyle} alt={""}/>
            </div>
        )
    }

    getSansuScrollImageElem(scrollImage, idx) {
        const {width, height} = this.props.view;
        const scrollSrc = this.props.repoImage[scrollImage.path].path;
        const scrollStyle = {
            top: scrollImage.top,
            left: scrollImage.left,
            position: "relative",
        };
        if (scrollImage.width) scrollStyle.width = scrollImage.width;
        if (scrollImage.height) scrollStyle.height = scrollImage.height;
        let divStyle = {
            width: width,
            height: height,
            position: "absolute"
        };
        const scrollMove = this.getWidth();
        divStyle.left = scrollMove * idx;
        return (
            <div key={scrollImage.id} style={divStyle}>
                <img src={scrollSrc} style={scrollStyle} alt={""}/>
            </div>
        )
    }

    rikaScrollEvent(direction) {
        const {subViewSettings, scrollLevel, stateChange} = this.props;
        if (!subViewSettings.options.scrollImage) return;
        let addLevel = 0;

        if (direction === "up") {
            addLevel = scrollLevel === 0 ? 0 : -1;
        } else if (direction === "down") {
            addLevel = scrollLevel === subViewSettings.options.scrollImage.length - 1 ? 0 : 1;
        }

        stateChange("scrollLevel", scrollLevel + addLevel);
    }

    dragEvent(id, scale, type) {
        return {
            onDragStart: (e, data) => {
                e.stopPropagation();
                if (this.props.panel[dataModeConst.EDIT_SUBVIEW_SVG]) return;
                this.dragStartData = data;
                if (id !== this.props.selectedBlockId) this.props.setSelectSubBlockId({blockId: id});
            },
            onDrag: (e, data) => {
                e.stopPropagation();
                if (this.props.panel[dataModeConst.EDIT_SUBVIEW_SVG]) return;
            },
            onDragStop: (e, data) => {
                e.stopPropagation();
                if (this.props.panel[dataModeConst.EDIT_SUBVIEW_SVG]) return;

                const {subViewSettings} = this.props;
                const scrollInfo = subViewSettings.options.scrollInfo;

                if (scrollInfo) {
                    if (scrollInfo.align === "horizontal") {
                        data.x += scrollInfo.position * subViewScale;
                    } else if (scrollInfo.align === "vertical") {
                        data.y += scrollInfo.position * subViewScale;
                    }
                }

                const xDiff = Math.abs(this.dragStartData.x - data.x);
                const yDiff = Math.abs(this.dragStartData.y - data.y);
                if (xDiff < 1 && yDiff < 1) return;
                let css = {
                    top: Math.round((data.y / scale * 100) / 100),
                    left: Math.round((data.x / scale * 100) / 100),
                };

                // if (scrollInfo) this.setBounds(data, css);

                this.props.changeRectDataBlock({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    blockId: this.props.selectedBlockId,
                    data: css,
                    subViewType: type,
                });

                this.dragStartData = {};
            },
        }
    };

    setBounds(data, css) {
        const rectElm = data.node;
        const subViewElm = rectElm.parentNode;
        const imageElm = subViewElm.getElementsByTagName("img")[0];

        const scroll = {
            y: subViewElm.scrollTop,
            x: subViewElm.scrollLeft,
            width: 2,
            height: 2
        };

        const rect = {
            x: css.left,
            y: css.top,
            width: rectElm.offsetWidth,
            height: rectElm.offsetHeight
        };

        const image = {
            width: imageElm.offsetWidth,
            height: imageElm.offsetHeight
        };

        let scrollAlign = "";

        if (subViewElm.style.overflowX) scrollAlign = "H";
        else if (subViewElm.style.overflowY) scrollAlign = "V";

        let limitX = subViewElm.offsetWidth, limitY = subViewElm.offsetHeight;

        if (scrollAlign === "H") {
            limitX = image.width > subViewElm.offsetWidth ? image.width : subViewElm.offsetWidth;
            scroll.height = subViewElm.offsetHeight - subViewElm.clientHeight;
        } else if (scrollAlign === "V") {
            limitY = image.height > subViewElm.offsetHeight ? image.height : subViewElm.offsetHeight;
            scroll.width = subViewElm.offsetWidth - subViewElm.clientWidth;
        }

        const currentX = rect.x + rect.width;
        const currentY = rect.y + scroll.y + rect.height;

        if (currentX > limitX) rect.x = limitX - rect.width - scroll.width;
        if (currentY > limitY) rect.y = limitY - rect.height - scroll.y - scroll.height;

        rect.x = rect.x < 0 ? 0 : rect.x;
        rect.y = rect.y < 0 ? 0 : rect.y;

        css.left = rect.x;
        css.top = rect.y;
    }

    resizeEvent(id, scale, type) {
        return {
            onResizeStart: e => {
                e.stopPropagation();
                if (this.props.panel[dataModeConst.EDIT_SUBVIEW_SVG]) return;
                this.setState({
                    ...this.state,
                    isResizing: true,
                });
            },
            onResize: (e, dir, ref) => {
                e.stopPropagation();
            },
            onResizeStop: (e, dir, ref, delta, position) => {
                e.stopPropagation();
                if (this.props.panel[dataModeConst.EDIT_SUBVIEW_SVG]) return;
                let {width, height} = ref.style;
                let css = {
                    width: Math.round(parseFloat(width) / scale * 100) / 100,
                    height: Math.round(parseFloat(height) / scale * 100) / 100,
                    top: Math.round(parseFloat(position.y) / scale * 100 / 100),
                    left: Math.round(parseFloat(position.x) / scale * 100 / 100),
                };

                this.props.changeRectDataBlock({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    blockId: this.props.selectedBlockId,
                    data: css,
                    subViewType: type,
                });

                this.setState({
                    ...this.state,
                    isResizing: false,
                });
            },
        };
    }

    onKeyDown(e) {
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
        e.stopPropagation();
        let which = e.keyCode + (e.shiftKey ? "+shift" : (e.ctrlKey ? "+ctrl" : (e.altKey ? "+alt" : "")));
        if (!Object.keys(keyModel).includes(which)) return;
        let {order, target, val} = keyModel[which];
        let subViewRectType = "";

        // subViewType와 subViewRect에 등록한 확대화면만 편집기에서 rect 위치조정 키보드이벤트를 실행하도록 함
        for (let type in subViewType) {
            if (this.props.subViewSettings.type === 4 || this.props.subViewSettings.type === 12) {
                const isAnswer = this.props.scrollObjectMode === rikaAnswerType.answer.button.name || this.props.scrollObjectMode === rikaAnswerType.answer.contents.name;
                subViewRectType = isAnswer ? "answer" : this.props.scrollObjectMode;
            } else if (this.props.subViewSettings.type === 1) {
                subViewRectType = this.props.shakaiDescObjectMode;
            } else if (this.props.subViewSettings.type === subViewType[type]) {
                if (subViewType[type] !== 0 && !subViewRect[type]) return;
                //subViewType이 answer 혹은 submovepage일 때 여기 들어옴
                subViewRectType = subViewRect[type];
            }
        }

        // subViewType이 answer일 땐 안 들어옴. subViewLink 혹은 submovepage일 때 들어옴
        if (this.props.selectedBlockId) {
            const rectType = this.props.selectedBlockId.split("-")[0];
            if (rectType === "subViewLink" || rectType === "subMovePage" || rectType === "answer") {
                subViewRectType = rectType;
            }
        }

        if (order === ORDER_DELETE) {
            this.props.removeRectDataBlock({
                id: this.props.selectedId,
                page: this.props.selectedPage,
                blockId: this.props.selectedBlockId,
                subViewType: subViewRectType,
            })
        } else if (order === ORDER_CHANGE) {
            this.props.changeRectDataBlockKey({
                id: this.props.selectedId,
                page: this.props.selectedPage,
                target: target,
                val: val,
                blockId: this.props.selectedBlockId,
                subViewType: subViewRectType,
            });
        }
    }

    alertElem() {
        return (
            <div className={"subview-alert"}>スライド機能に連動された拡大画面です。</div>
        )
    }

    svgEditElem() {
        const {subViewSettings, selectedBlockId} = this.props;
        let {type, index} = findSubViewType(subViewSettings, selectedBlockId);
        if (type === "") {
            console.error("no svg info");
            return (<div/>);
        }
        if (subViewSettings.options && subViewSettings.options[type] && subViewSettings.options[type][index]) {
            const selectedRectObj = subViewSettings.options[type][index];
            // const scrollTop = subViewSettings.type === 4 ? this.props.scrollLevel * rikaScroll.scrollTop : 0;
            let scrollTop = 0;
            let scrollLeft = 0;
            if (subViewSettings.type === 12) {
                const isHorizon = subViewSettings.options && subViewSettings.options.scrollType === "h";
                scrollTop = isHorizon ? 0 : this.props.scrollLevel * (this.getHeight() * -1);
                scrollLeft = isHorizon ? this.props.scrollLevel * (this.getWidth() * -1) : 0;
            } else if (subViewSettings.type === 4) scrollTop = this.props.scrollLevel * rikaScroll.scrollTop;
            const style = {
                width: selectedRectObj ? selectedRectObj.width : "",
                height: selectedRectObj ? selectedRectObj.height : "",
                left: selectedRectObj ? selectedRectObj.left + scrollLeft : "",
                top: selectedRectObj ? selectedRectObj.top + scrollTop : "",
                direction: "ltr",
            };
            if (selectedRectObj.svg) {
                const svgType = selectedRectObj.svg.type;
                let pointElem = "";
                if (svgType === "polygon") {
                    pointElem = this.svgPolygonElem(subViewSettings, selectedRectObj, type, style);
                } else {
                    pointElem = this.svgPathElem(subViewSettings, selectedRectObj, type, style);
                }
                return (
                    <div className={"selected-object"} style={style}>
                        {pointElem}
                        <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
                            <path stroke="#00000021" fillOpacity="0" vectorEffect="non-scaling-stroke" d="
							M10 0 L10 100 M20 0 L20 100 M30 0 L30 100 M40 0 L40 100 M50 0 L50 100
							M60 0 L60 100 M70 0 L70 100 M80 0 L80 100 M90 0 L90 100"
                            />
                            <path stroke="#00000021" fillOpacity="0" vectorEffect="non-scaling-stroke" d="
							M0 10 L100 10 M0 20 L100 20 M0 30 L100 30 M0 40 L100 40 M0 50 L100 50
							M0 60 L100 60 M0 70 L100 70 M0 80 L100 80 M0 90 L100 90"
                            />
                        </svg>
                    </div>
                )
            }
        }
    }

    svgPolygonElem(subViewSettings, selectedRectObj, type, style) {
        const svgPoints = selectedRectObj.svg.attr.points;    // 현재 선택된 rect의 svg points
        const points = svgPoints.split(" ");
        if (!Array.isArray(points)) return "";
        return points.map((point, idx) => {
            let [top, left] = point.split(",").map(i => parseInt(i));
            return (
                <Draggable
                    position={{
                        x: style.width * top / 100,
                        y: style.height * left / 100,
                    }}
                    onStart={e => e.stopPropagation()}
                    onDrag={e => e.stopPropagation()}
                    onStop={(e, data) => this.handleStop(e, data, points, style, selectedRectObj, type, "points")}
                    key={idx}
                    bounds="parent"
                >
                    <div
                        className="svg-points"
                        data-idx={idx}
                        style={{backgroundColor: makeColorCodeHSL(idx)}}
                    />
                </Draggable>
            )
        });
    }

    svgPathElem(subViewSettings, selectedRectObj, type, style) {
        const svgPathD = selectedRectObj.svg.attr.d;
        let regexp = /([a-yA-Y]+\s?[-]?\d+(\.\d+)?\s?[-]?\d+(\.\d+)?)/g;
        const d = svgPathD.match(regexp);
        if (!Array.isArray(d)) return "";
        return d.map((item, idx) => {
            const value = item.replace(/[a-zA-Z]\s?/g, '');
            let [top, left] = value.split(" ").map(i => parseInt(i));
            return (
                <Draggable
                    position={{
                        x: style.width * top / 100,
                        y: style.height * left / 100,
                    }}
                    onStart={e => e.stopPropagation()}
                    onDrag={e => e.stopPropagation()}
                    onStop={(e, data) => this.handleStop(e, data, d, style, selectedRectObj, type, "d")}
                    key={idx}
                    bounds="parent"
                >
                    <div
                        className="svg-points"
                        data-idx={idx}
                        style={{backgroundColor: makeColorCodeHSL(idx)}}
                    />
                </Draggable>
            )
        });

    }

    handleStop(e, data, points, style, selectedRectObj, subViewRectType, key) {
        e.stopPropagation();
        const idx = parseInt(data.node.getAttribute("data-idx"));
        if (idx >= 0) {
            let x = clamp(1, 99, Math.floor((data.x) / style.width * 100));
            let y = clamp(1, 99, Math.floor((data.y) / style.height * 100));

            let newPoints = points;
            if (key === "d") {
                const command = newPoints[idx].replace(/\s?[-]?\d+(\.\d+)?\s?/g, '');
                newPoints[idx] = `${command} ${x} ${y}`;
                newPoints.push("Z");
            } else {
                newPoints[idx] = `${x},${y}`;
            }
            this.changeRectPoint(newPoints, selectedRectObj, subViewRectType, key);
        }
    }

    changeRectPoint(newPoints, selectedRectObj, subViewRectType, key) {
        const data = {
            svg: {
                ...selectedRectObj.svg,
                attr: {
                    [key]: newPoints.join(" "),
                }
            }
        };

        this.props.changeRectDataSvg({
            id: this.props.selectedId,
            page: this.props.selectedPage,
            blockId: this.props.selectedBlockId,
            data: data,
            subViewType: subViewRectType,
        });
    }

    onScroll(e) {
        const {subViewSettings} = this.props;
        if (!subViewSettings || !subViewSettings.options) return;
        const scrollInfo = subViewSettings.options.scrollInfo;

        if (!scrollInfo) return;

        if (scrollInfo.align === "horizontal") {
            scrollInfo.position = e.target.scrollLeft;
        } else {
            scrollInfo.position = e.target.scrollTop;
        }

        const data = {
            subViewSettings: {
                ...subViewSettings,
                options: {
                    ...subViewSettings.options,
                    scrollInfo: scrollInfo,
                },
            }
        };
        this.props.changeRectData({
            id: this.props.selectedId,
            page: this.props.selectedPage,
            data: data,
            objMode: "subView",
        });
    }

    setScrollPosition() {
        const {subViewSettings} = this.props;

        if (!subViewSettings) return;

        const scrollInfo = subViewSettings.options.scrollInfo;

        if (scrollInfo) {
            const position = scrollInfo.position ? scrollInfo.position : 0;
            if (scrollInfo.align === "horizontal") {
                this.subviewIframeRef.current.scrollLeft = position;
            } else {
                this.subviewIframeRef.current.scrollTop = position;
            }
        }
    }

    render() {
        //공통 탭에서 추가하는 이미지를 사용하지 않는 확대화면 번호(이과스크롤, 서브메뉴, 이과스크롤(신), 산수스크롤) * 사회 설명은 예외
        const noUseCommonImage = [1, 4, 9, 12, 14];
        const {subViewSettings, isScrollMain} = this.props;
        const useOnlyOneImage = subViewSettings ? !noUseCommonImage.includes(subViewSettings.type) : false;
        const isImage = useOnlyOneImage && isScrollMain;
        const isContents = isScrollMain;
        const divStyle = {width: this.getWidth(), height: this.getHeight(), transform: `scale(${subViewScale})`};

        if (subViewSettings && subViewSettings.options && subViewSettings.options.scrollInfo) {
            const noUseScroll = [4, 5, 9, 12, 14, 15];
            if (!noUseScroll.includes(subViewSettings.type)) {
                delete divStyle.overflowX;
                delete divStyle.overflowY;

                if (subViewSettings.options.scrollInfo.align === "horizontal") {
                    divStyle.overflowX = "auto";
                    if (this.props.direction === "rtl") {
                        divStyle.direction = this.props.direction;
                    }
                } else if (subViewSettings.options.scrollInfo.align === "vertical") {
                    divStyle.overflowY = "auto";
                    if (this.props.direction === "rtl") {
                        divStyle.direction = "ltr";
                    }
                }
                divStyle.position = "relative";
            }
        }

        return (
            <div className="subview-iframe" title="subview-screen"
                 ref={this.subviewIframeRef}
                 onMouseDown={!this.props.panel[dataModeConst.EDIT_SUBVIEW_SVG] ? this.props.unsetSelectSubBlockId : null}
                 onKeyDown={this.onKeyDown.bind(this)}
                 onScroll={this.onScroll.bind(this)}
                 style={divStyle}
                 onLoad={this.setScrollPosition.bind(this)}
            >
                {this.props.panel[dataModeConst.EDIT_SUBVIEW_SVG] ? this.svgEditElem() : ""}
                {isImage ? this.getImage() : ""}
                {isContents ? this.getContents() : ""}
                {isScrollMain ? "" : this.alertElem()}
                {this.getSubViewInSubViewContents()}
                {this.getAnswerContents()}
                {this.getMovePageContents()}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubviewContents);