import {
	editorProp,
	csvConst,
	editorIgnore,
	editorStr,
	defaultTextCss,
	editorPropEx, csvModalMsg, cssUnit, csvColumnsName,
} from "../../../interface/csvConstant";
import {
	checkNested,
	flatObject,
	makeErrorMsg,
	randomStr,
	stringParser
} from "../../../interface/utils";
import {
	defaultEditorTextObj,
	getImportEditorDataModel,
	getImportEditorTextDataModel
} from "../../../interface/csvModel";
import {
	checkPropsKeyExist,
	onlyNumberCheck,
	pageInvalidCheck,
	propNameChange,
	unnecessaryInputCheck,
	yesOrNoCheck
} from "./csvUtils";
import {myEditorSuffix} from "../../../interface/constant";

/**
 * redux pageData 를 받아서 editor 정보를 가진 객체 속성과 contents 배열의 객체 속성을 가진 객체들의 배열을 반환하는 함수
 * @param {object} clonedData - redux pageData
 * @returns {array} - type 속성이 editor 또는 text 인 객체들의 배열
 */
export const getOnlyEditorData = (clonedData) => {
	const newArr = [];
	for (const page of Object.keys(clonedData)) {
		const subViewArr = clonedData[page][csvConst.objects][csvConst.subView]; // subView 객체 배열에서
		const editorObjArr = subViewArr.filter(obj => obj.hasOwnProperty(editorStr.editor)); // editor 객체만 필터
		const onlyEditorArr = propsControl(editorObjArr);
		const arrays = contentMatching(onlyEditorArr);
		newArr.push(...arrays);
	}
	return newArr;
};

/**
 * editor 객체에 속성을 추가하거나 삭제 하기 위한 함수
 * @param {array} arr - editor 객체로 이루어진 배열
 * @returns {array} - arr 의 각 editor 객체에 flag 2 설정해주고 불필요한 속성을 제거한 editor 객체들의 배열
 */
const propsControl = (arr) => {
	return arr.map(obj => {
		const newObj = Object.assign({}, obj);
		const flagObj = setDefaultFlag(newObj);
		return cutEditorProps(flagObj);
	})
};

/**
 * flagObj 객체에서 불필요한 객체 속성을 제거한 객체를 반환하는 함수
 * @param {object} flagObj - flag 속성에 값 2를 갖는 editor 객체
 * @returns {object} - 불필요한 속성을 제거한 editor 객체
 */
const cutEditorProps = (flagObj) => {
	const cutObj = Object.assign({}, flagObj);
	for (const target of ['css', 'options', 'data']) {
		if (cutObj.hasOwnProperty(target)) delete cutObj[target];
	}
	return cutObj;
};

/**
 * export 시 기본 flag 2를 주기 위해 newObj 의 flag 에 2를 대입하는 함수
 * @param {object} newObj - editor 객체
 * * @returns {object} - flag 2를 추가한 editor 객체
 */
const setDefaultFlag = (newObj) => {
	const flagObj = Object.assign({}, newObj);
	flagObj.flag = 2;
	return flagObj;
};

/**
 * 타입검사와 사용자가 알아보기 쉽게 editor 와 text 분류
 * import 된 editor csv 로 받은 Editor 객체에서 contents 배열안의 text 객체를 꺼내서
 * 배열에 한번에 담으면서 객체의 type 속성으로 각각 editor 와 text 를 대입 시킨 객체들의 배열
 * @param {array} onlyEditorArr - editor 속성을 가진 객체들의 배열
 * @returns {array} - type 속성이 editor 또는 text 인 객체들의 배열
 */
const contentMatching = (onlyEditorArr) => {
	const newArr = [];
	for (const obj of onlyEditorArr) {
		obj.type = 'editor';
		newArr.push(obj);
		const hasProp = checkNested(obj, 'editor.text.contents');
		if (hasProp) {
			const contents = obj.editor.text.contents;
			for (const content of contents) {
				content.type = 'text';
				newArr.push(content)
			}
		}
	}
	return newArr;
};

/**
 * 인자로 영문 컬럼 배열을 받아서 일본어 컬럼 배열을 리턴해주는 함수
 * @param {array} exportSubViewColumns - 영문 속성 이름으로 이루어진 컬럼 배열
 * @returns {array} - csv 에서 보여질 일본어 배열
 */
export const getEditorColumns = (exportSubViewColumns) => {
	return exportSubViewColumns.map(devColumns => {
		Object.entries(editorProp).forEach(columnConstant => {
			const key = columnConstant[0];
			const val = columnConstant[1];
			if (devColumns === key) devColumns = val;
		});
		return devColumns;
	});
};

/**
 * export 하기전에 속성값들을 사용자가 알아보기 쉽게 하기 위해 객체의 속성명 또는 속성값을 바꾸는 함수
 * @param {array} editorData - type 속성 값이 editor 또는 text 인 Editor 객체들의 배열
 * @returns {array} - 속성들이 flat 한 객체들의 배열
 */
export const getChangedPropObjArr = (editorData) => {
	return editorData.map(obj => {
		const flatObj = flatObject(obj); // 속성명 flat 하게 만듬
		const delPositionObj = delProps(flatObj, 'editor.text.css.position', 'editor'); // prop 삭제
		const delDefaultFontSizeObj = delProps(delPositionObj, 'editor.text.defaultCss.fontSize', 'editor'); // prop 삭제
		const delDefaultPositionObj = delProps(delDefaultFontSizeObj, 'editor.text.defaultCss.position', 'editor'); // prop 삭제
		const textIdObj = propNameChange(delDefaultPositionObj, 'id', 'textId', 'text');
		const ignoreObj = editorIgnoreParse(textIdObj); // editor.ignore 속성 값을 true / false 를 N / Y 로 변경
		const positionObj = editorPositionParse(ignoreObj);	// editor.position 속성 값이 있으면 Y, 아니면 빈칸으로 변경
		const fontWeightObj = editorFontWeightParse(positionObj); // editor.fontWeight 속성 값이 있으면 면 Y, 아니면 빈칸으로 변경
		const fontSizeObj = removePropValueUnit(fontWeightObj, editorPropEx.fontSize, 'em'); // 속성 값에서 em 단위 제거
		const lineHeightObj = removePropValueUnit(fontSizeObj, editorPropEx.lineHeight, '%'); // 속성 값에서 % 단위 제거
		const marginLeftObj = removePropValueUnit(lineHeightObj, editorPropEx.marginLeft, '%'); // 속성 값에서 % 단위 제거
		const marginTopObj = removePropValueUnit(marginLeftObj, editorPropEx.marginTop, '%'); // 속성 값에서 % 단위 제거
		const topObj = removePropValueUnit(marginTopObj, editorPropEx.top, '%'); // 속성 값에서 % 단위 제거
		const leftObj = removePropValueUnit(topObj, editorPropEx.left, '%'); // 속성 값에서 % 단위 제거
		const widthObj = removePropValueUnit(leftObj, editorPropEx.width, '%'); // 속성 값에서 % 단위 제거
		return widthObj;
	});
};

/**
 * 객체 내에서 불필요한 속성을 제거하기 위한 함수
 * @param {object} flatObj - 객체의 구조가 flat 한 객체
 * @param {string} prop - 제거할 속성명
 * @param {string} type - 제거할 대상의 type
 * @returns {object} - flatObj 의 type 속성값과 인자 type 값이 일치할 경우 flatObj 의 prop 속성을 제거한 객체
 */
const delProps = (flatObj, prop, type) => {
	const newObj = Object.assign({}, flatObj);
	const isTypeMatch = newObj.type === type;
	if (newObj.hasOwnProperty(prop) && isTypeMatch) delete newObj[prop];
	return newObj;
};


/**
 * flatObj 의 obj.editor.ignore 속성 값 true 를 有 로, false 를 無 로 바꾼 객체를 리턴하는 함수
 * @param{object} flatObj - flat 한 속성을 가진 editor 객체
 * @returns {object} - obj.editor.ignore 속성 값을 無, 有 로 바꾸는 함수
 */
export const editorIgnoreParse = (flatObj) => {
	const newObj = Object.assign({}, flatObj);
	if (newObj.hasOwnProperty(editorPropEx.ignore)) {
		newObj[editorPropEx.ignore] = editorIgnore[newObj[editorPropEx.ignore]];
	}
	return newObj;
};

/**
 * flatObj 의 obj.editor.css.position 속성 값 absolute 를 Y로 그 외는 빈칸으로
 * @param{object} flatObj - flat 한 속성을 가진 editor 객체
 * @returns {object} - obj.editor.css.position 속성 값을 Y 또는 빈칸으로 바꾸는 함수
 */
export const editorPositionParse = (flatObj) => {
	const newObj = Object.assign({}, flatObj);
	if (newObj.hasOwnProperty(editorPropEx.position)) {
		const position = newObj[editorPropEx.position];
		newObj[editorPropEx.position] = position ? "Y" : "";
	}
	return newObj;
};

/**
 * flatObj 의 obj.editor.css.fontWeight 속성 값이 있으면 Y로 그 외는 빈칸으로
 * @param{object} flatObj - flat 한 속성을 가진 editor 객체
 * @returns {object} - obj.editor.css.fontWeight 속성 값을 Y 또는 빈칸으로 바꾸는 함수
 */
export const editorFontWeightParse = (flatObj) => {
	const newObj = Object.assign({}, flatObj);
	if (newObj.hasOwnProperty(editorPropEx.fontWeight)) {
		const fontWeight = newObj[editorPropEx.fontWeight];
		newObj[editorPropEx.fontWeight] = fontWeight ? "Y" : "";
	}
	return newObj;
};

/**
 * 객체의 속성 값에서 em, % 등 단위를 제거하는데 쓰이는 함수
 * @param {object} obj - flat 한 속성은 가진 subView 객체
 * @param {string} prop - 객채의 속성 이름
 * @param {string} unit - prop 속성에 해당하는 값에서 replace 할 대상
 * @returns {object} - obj 객체의 prop 속성의 값에서 unit 를 제거한 값을 가지는 obj 객체
 */
export const removePropValueUnit = (obj, prop, unit) => {
	const newObj = Object.assign({}, obj);
	if (newObj.hasOwnProperty(prop)) {
		const fontSize = newObj[prop].replace(unit, '');
		newObj[prop] = fontSize;
	}
	return newObj;
};

/**
 * 읽어들인 csv 파일의 컬럼이름 배열과 지정해둔 컬럼 배열을 비교하여
 * 일치하지 않으면 컬럼의 적절하지 못한 변형이 일어났으므로 Error 를 던지는 함수
 * @param {string[]} csvUserColumns - 읽어들인 csv 파일의 컬럼 이름들의 배열
 */
export const editorColumnsErrorCheck = (csvUserColumns) => {
	const editorColumn = ['種類','元ID','ID','ページ','MY教科書エディタ登録','文字ボックス変更',
		'テキスト','テキストサイズ','X座標','Y座標','行間隔', "色", "太字", "横",
		'R-ID(文字・背景画像)','R-ID(背景画像)','編集'];
	if (csvUserColumns.toString() !== editorColumn.toString()) {
		console.log(`csvUserColumns.toString(): `, csvUserColumns.toString());
		console.log(`editorColumn.toString(): `, editorColumn.toString());
		throw new Error(makeErrorMsg('unmatchedColumn', csvModalMsg.editor));
	}
};

/**
 * 배열과 객체를 받아 객체의 index 를 구해서 2를 더하고 주석의 라인 수를 더해서
 * 현재 객체의 index 를 반환해주는 함수
 * @param {array} arr - 객체가 포함되어 있는 배열
 * @param {object} ele - 배열안에 있는 객체
 * @param {number} comLength - 주석의 라인 수
 * @returns {number} - 현재 문제가 발생한 csv 의 index
 */
const getCsvIndex = (arr, ele, comLength) => {
	return arr.indexOf(ele) + 2 + comLength;
};

/**
 * flag 1이면 ignore, myEditorImage, myEditorImage_bg 속성값 을 변경해주고 기존 텍스트에 csv 텍스트를 추가
 * flag 2이면 기존 text 를 초기화하고 csv 텍스트를 추가하여 csv 의 텍스트로 변경
 * flag 3이면 기존 텍스트를 초기화해서 전부 제거
 * @param {array} EditorArr - redux 형태의 속성 구조와 csv 속성 값을 가진 Editor 객체들의 배열
 * @param [object} clonedData - deepClone 한 pageData
 */
export const EditorImport = (EditorArr, clonedData) => {
	for (const obj of EditorArr) {
		const target = clonedData[obj.page]['objects']['subView'].filter(editor => editor.originId === obj.originId)[0];
		if (obj.flag === 1) {
			delete obj.flag; // 객체의 flag 속성 제거
			target.editor.ignore = obj.editor.ignore; // ignore 속성값 변경
			target.editor.myEditorImage = obj.editor.myEditorImage; //  myEditorImage 속성값 변경
			target.editor.myEditorImage_bg = obj.editor.myEditorImage_bg; //myEditorImage_bg 속성값 변경
			if (!checkNested(target, 'editor.text')) target.editor.text = defaultEditorTextObj(); // editor 객체인데 text 속성이 없으면 추가
			if (obj.editor.text.contents && obj.editor.text.contents.length > 0) {
				// Text 새로 추가 할때 X,Y좌표를 지정하지않았다면 자동으로 밑으로 나오도록 정렬
				let offsetX = 0;
				let offsetY = 0;
				const textCount = 10;
				obj.editor.text.contents.map((item, idx) => {
					const emptyLeft = item.css.left === "0%" || item.css.left === "%";
					const emptyTop = item.css.top === "0%" || item.css.top === "%";
					let leftMoveLevel = Math.floor(idx / textCount);
					if (item.css.position && emptyLeft && emptyTop) {
						// 추가하는 text가 10개모다 많으면 다음 text는 left 20%만큼 이동 후 top값 초기화
						offsetX = 20 * leftMoveLevel;
						item.css.left = offsetX + myEditorSuffix.left;
						item.css.top = offsetY + myEditorSuffix.top;
						offsetY += 10;
						if (leftMoveLevel < Math.floor((idx+1) / textCount)) offsetY = 0;
					}
					return item;
				});
			}
			target.editor.text.contents = [...target.editor.text.contents, ...obj.editor.text.contents]; // 텍스트 추가
			if (target.editor.text.contents.length === 0) delete target.editor.text;
		} else if (obj.flag === 2) {
			delete obj.flag; // 객체의 flag 속성 제거
			target.editor.ignore = obj.editor.ignore; // ignore 속성값 변경
			target.editor.myEditorImage = obj.editor.myEditorImage; //  myEditorImage 속성값 변경
			target.editor.myEditorImage_bg = obj.editor.myEditorImage_bg; //myEditorImage_bg 속성값 변경
			if(target.editor.text && target.editor.text.contents) {
				target.editor.text.contents = [...obj.editor.text.contents]; // 텍스트 수정
			}
		} else { // 3
			delete obj.flag; // 객체의 flag 속성 제거
			delete target.editor.text; // 텍스트 삭제
		}
	}
};

/**
 * 검증된 csv 의 속성값을 redux 속성 구조와 비슷한 Model 을 가져와서 값을 대입 시킨후 Model 객체들의 배열을 반환하는 함수
 * @param {array} arr - 속성명과 속성값에 대한 검사와 파싱을 끝낸 객체들의 배열
 * @returns {array} - csv 의 속성값과 redux 의 속성 구조를 가진 객체들의 배열
 */
export const getEditorModelArr = (arr) => {
	return arr.map(obj => {
		if (obj.type === "editor") {
			return getEditorModelObject(obj, getImportEditorDataModel);
		} else {
			return getEditorModelObject(obj, getImportEditorTextDataModel);
		}
	});
};

/**
 * 에러검사와 속성명과 속성값에 대한 파싱이 끝났으므로 이 값을 이용해 redux 객체와 유사한
 * import Model 을 불러와서 값을 대입시켜준후 Model 객체를 리턴한다.
 * @param {object} obj - model 에 속성값에 넣어줄 값을 가지고 있는 flat 한 속성의 csv 객체
 * @param {function} createModel - model 객체 생성함수
 * @returns {object} - csv 의 속성값이 적용된 model 객체
 */
const getEditorModelObject = (obj, createModel) => {
	const editorModel = createModel();
	for (const [prop, value] of Object.entries(obj)) {
		const step = prop.split('.');
		if (checkNested(editorModel, prop)) {
			if (step.length === 1) { // 에디터 텍스트로 들어온 값은 String 문자열로 변환해서 받도록 수정
				editorModel[step[0]] = (step[0] === 'value') ? String(value) : value;
			} else if (step.length === 2) {
				editorModel[step[0]][step[1]] = value;
			}
		}
	}
	return editorModel;
};

/**
 * 상위 객체인 editor 객체의 text.contents 배열 속성에 text 객체들을 push 하고 editor 객체만 담은 배열을 반환하는 함수
 * @param {Array} modelArr - type 속성이 editor 또는 text 인 redux 속성 구조를 가진 Model 객체들의 배열
 * @returns {Array} - editor 모델 객체의 배열 속성 text.contents 에 text 객체를 push 한 editor 모델 객체들의 배열
 */
export const textIntoEditor = (modelArr) => {
	const editorArr = [];
	for (const obj of modelArr) {
		if (obj.type === 'editor') {
			delete obj.type;
			editorArr.push(obj);
		} else {
			delete obj.type;
			delete obj.flag;
			if(editorArr[editorArr.length-1].editor.text)
				editorArr[editorArr.length-1].editor.text.contents.push(obj);
		}
	}
	return editorArr;
};

/**
 * 에러검사 통과후 Redux 객체 구조로 바꾸기 전에 속성값을 Redux 객체 속성값과 맞춰주고 빈 속성값은 자동으로 채워주는 함수
 * @param {Array} arr - 속성값 에러검사를 통과한 배열
 * @returns {Array} - 속성값을 원래 타입 형태로 파싱하고 속성값이 빈값일 때 기본값을 넣어준 객체들의 배열
 */
export const parseAfterSetDefault = (arr) => {
	const pageArr = []; // text 의 Id를 만드는 과정에서 editor type 의 page 속성값을 얻기 위한 배열
	return arr.map(obj => {
		const parsedObj = getParsedPropertyValueObj(obj); // 객체의 속성값 파싱
		const defaultObj = getDefaultValueObj(parsedObj, pageArr); // 빈값일 때 자동으로 채워지는 기본값 적용
		return defaultObj;
	});
};

/**
 * 객체를 받아서 기본값을 넣어준 객체를 반환하는 함수
 * @param {object} obj - flat 한 속성을 가진 type 속성이 editor 또는 text 인 객체
 * @param {array} pageArr - text type 이 page 가 없어서 editor type 의 page 를 얻기 위한 배열
 * @returns {object} - 기본값이 설정된 obj 객체
 */
const getDefaultValueObj = (obj, pageArr) => {
	if(obj.type === 'editor') pageArr[0] = obj.page; // text 상위 객체인 editor type 객체의 page
	const setObj1 = setDefaultValue(obj, 'editor.ignore', defaultTextCss.FALSE, 'editor'); // false
	const setObj2 = setDefaultValue(setObj1, 'css.fontSize', defaultTextCss.FONT_SIZE, 'text'); // 1em
	const setObj3 = setDefaultValue(setObj2, 'css.lineHeight', defaultTextCss.LINE_HEIGHT, 'text'); // 135%
	const setObj4 = setDefaultValue(setObj3, 'css.marginLeft', defaultTextCss.MARGIN_LEFT, 'text'); // 0%
	const setObj5 = setDefaultValue(setObj4, 'css.marginTop', defaultTextCss.MARGIN_TOP, 'text'); // 0%
	const setObj6 = setDefaultValue(setObj5, 'id', getTextId(pageArr[0]), 'text'); // 1-zp1jc4db, 4-3rb1o0ai
	return setObj6;
};

/**
 * editor type 의 page 속성 값을 받아서 page-랜덤문자열 형태로 쓰이는 text 객체의 id를 반환하는 함수
 * @param {number} page - 현재 text Type 객체가 속한 페이지
 * @returns {string} - page 1이면 1-zp1jc4db, page 4이면 4-3rb1o0ai 페이지-랜덤문자열 형태의 문자열 반환
 */
const getTextId = (page) => {
	return `${page}-${randomStr(8)}`;
};

/**
 * obj.prop 속성값이 "" 빈값이면 deValue 를 대입시켜서 기본값을 갖게 하는 함수
 * @param {object} obj - prop 속성값이 빈값이면 deValue 를 대입할 객체
 * @param {string} prop - obj 에 접근할 속성 이름
 * @param {string} type - object 의 타입 속성, 객체중에서 접근할 타입을 지정
 * @param {string|boolean|number} deValue - obj 의 속성이름으로 접근했을 때 넣어줄 속성 값
 * @returns {object} - 기본값을 가진 객체
 */
const setDefaultValue = (obj, prop, deValue, type) => {
	const newObj = Object.assign({}, obj);
	if (newObj.hasOwnProperty(prop) && newObj[prop] === "" && obj.type === type) {
		newObj[prop] = deValue;
	}
	return newObj;
};

/**
 * redux 의 속성값 타입에 맞춰서 객체의 속성값들을 파싱하는 함수
 * @param {object} obj - 속성값 검사를 통과한 type 이 editor 혹은 text 인 Editor csv 객체
 * @returns {object} - 문자열 속성값들이 원래의 타입에 맞게 파싱된 속성값을 가진 객체
 */
const getParsedPropertyValueObj = (obj) => {
	const newObj = Object.assign({}, obj);
	for (const [property, value] of Object.entries(newObj)) {
		const parsedValue = propValueChange(property, value, newObj.type); // 속성값 변경 Y/N 을 true/false 로 fontSize 에 em 단위 붙이고
		if (newObj["css.position"] === "Y") {
			newObj["css.top"] = newObj["css.marginTop"] + "%";
			newObj["css.left"] = newObj["css.marginLeft"] + "%";
		}
		newObj[property] = stringParser(parsedValue);
	}
	return newObj;
};

/**
 * 속성과 객체의 type 에 따라서 속성값을 바꾸는 함수
 * 입력값이 있고 해당 속성과 타입에 따라서 바꿔줄 속성값을 정한다.
 * @param {string} property - 속성 이름
 * @param {string} value - 속성 값
 * @param {string} type - editor 또는 text, 속성 값을 바꿀 객체 대상을 선택하기 위한 타입
 * @returns {string|boolean|*}
 */
const propValueChange = (property, value, type) => {
	if (value !== "" && property === "editor.ignore" && type === 'editor') {
		return (value === "Y") ? false : true;
	} else if (value !== "" && property === "css.position" && type === 'text') {
		return (value === "Y") ? "absolute" : "";
	} else if (value !== "" && property === "css.fontSize" && type === 'text') {
		return value + cssUnit.EM;
	} else if (value !== "" && property === "css.lineHeight" && type === 'text') {
		return value + cssUnit.PERCENT;
	} else if (value !== "" && property === "css.marginLeft" && type === 'text') {
		return value + cssUnit.PERCENT;
	} else if (value !== "" && property === "css.marginTop" && type === 'text') {
		return value + cssUnit.PERCENT;
	} else if (value !== "" && property === "css.fontWeight" && type === 'text') {
		return (value === "Y") ? "bold" : "";
	} else if (value !== "" && property === "css.width" && type === 'text') {
		return value + cssUnit.PERCENT;
	} else { // 변경할 속성이 아니면 그대로 값을 리턴한다.
		return value;
	}
};

/**
 * 객체의 flag 속성의 값이 range 배열 안의 요소중 하나라도 일치하지 않으면 에러를 던지는 함수
 * @param {object} obj - 객체의 type 속성이 editor 인 객체
 * @param {number} idx - csv 상에서 obj 객체가 위치한 라인 넘버
 */
export const inputWrongFlagCheck = (obj, idx) => {
	const range = ["1", "2", "3"];
	const match = range.some(flag => flag === obj.flag);
	if (!match) { // ["1", "2", "3"] 요소 중에 하나라도 obj.flag 와 일치하는게 없는 경우
		throw new Error(makeErrorMsg('chooseOne', idx, editorProp.type, range));
	}
};

/**
 * csv 의 row(객체) 가 한 줄이라도 들어갔다면 첫 번째로 들어오는 객체의 type 은 editor 여야 한다.
 * 그 밑의 row 에 text 객체가 들어가는 경우 text 객체들은 상위의 editor 객체의 flag 속성값을 받는다.
 * @param {array} csvData - import 받은 csv 를 바탕으로 만들어진 객체들의 배열
 */
export const firstRowOnlyEditor = (csvData) => {
	if (csvData.length > 0) {
		if (csvData[0].type !== 'editor') {
			throw new Error(makeErrorMsg('firstRow', editorProp.type));
		}
	}
};


/**
 * csv import 시, editor 의 originId 는 기존에 존재하는 originId 여야 하므로
 * redux 내의 모든 editor 객체의 originId 값을 담은 배열을 반환하여 검사시 사용하기 위한 함수
 * @param {object} clonedData - this.props.pageData 정보를 deepClone 한 객체
 * @returns {Array} - clonedData 내에서 editor 속성을 가진 모든 subView 객체의 originId 를 담은 배열
 */
export const getAllOriginIdArr = (clonedData) => {
	const idArr = [];
	for (const objects of Object.values(clonedData)) {
		for (const obj of objects['objects']['subView']) {
			if (obj.hasOwnProperty('editor')) idArr.push(obj.originId);
		}
	}
	return idArr;
};

export const EditorCheck = (csvEditorArr, comLength, originIdArr, metaData, clonedRepo) => {
	const { totalPages } = metaData;
	for (const obj of csvEditorArr) {
		const idx = getCsvIndex(csvEditorArr, obj, comLength);
		inputTypeCheck(obj, idx, ['editor', 'text']); // type 은 editor 와 text 둘다 갖고 있으므로 공통 체크

		if (obj.type === 'editor') { // type editor 일 때만 区分、元ID、ページ 필수값 체크
			inputWrongFlagCheck(obj, idx); // flag 가 1,2,3 셋중 하나여야 한다.
			inputOriginIdExistCheck(obj, idx, originIdArr); // 元ID는 이미 존재하는 editor 속성을 가진 subView 객체의 값이어야 한다.
			pageInvalidCheck(obj, idx, totalPages); // page 필수입력 , 숫자만, 0보다 크고 totalPage 보다 작은 수
			yesOrNoCheck(obj, idx, 'editor.ignore'); // 에디터 자동등록 Y / N
			unnecessaryInputCheck(obj, idx, 'value', 'css.fontSize', 'css.lineHeight', 'css.marginLeft', 'css.marginTop');
			checkPropsKeyExist(obj, 'editor.myEditorImage', idx, clonedRepo, 'image'); // repo key 체크
			checkPropsKeyExist(obj, 'editor.myEditorImage_bg', idx, clonedRepo, 'image'); // repo key 체크
		} else { // type text 일때만
			const onlyNumberCheckArray = ['css.fontSize', 'css.lineHeight', 'css.marginLeft', 'css.marginTop'];
			onlyNumberCheck(obj, idx, onlyNumberCheckArray, csvColumnsName);
			unnecessaryInputCheck(obj, idx, 'id', 'originId', 'page', 'editor.ignore', 'editor.myEditorImage', 'editor.myEditorImage_bg');
		}
	}
};

/**
 * editor 객체의 originId는 필수 입력값이고 기존의 존재하는 것을 입력해야 한다.
 * 입력한 값이 존재하지 않는 originId 를 입력했다면 존재하지 않는 곳에 editorText 를 추가하는 격이다.
 * @param {object} obj - type 속성의 값이 editor 인 객체
 * @param {number} idx - csv 상에서 obj 객체가 위치한 라인넘버
 * @param {Array} originIdArr - redux 상에 존재하는 모든 객체의 originId를 담은 배열
 */
const inputOriginIdExistCheck = (obj, idx, originIdArr) => {
	const exist = originIdArr.some(originId => originId === obj.originId);
	if (!exist) { // 존재하지 않는 originId를 csv 상에서 입력한 경우
		throw new Error(makeErrorMsg('isNotExists', idx, editorProp.originId));
	}
};

/**
 *
 * @param {object} obj - type 속성의 값이 editor 인 객체
 * @param {number} idx - csv 상에서 obj 객체가 위치한 라인넘버
 * @param {Array} rangeArr [editor|text] type 이 배열의 요소중 하나라도 일치하지 않는지 검사하기 위한 배열
 */
const inputTypeCheck = (obj, idx, rangeArr) => {
	const match = rangeArr.some(type => type === obj.type);
	if (!match) {
		throw new Error(makeErrorMsg('chooseOne', idx, editorProp.type, rangeArr));
	}
};

/**
 * import 받은 데이터에서 excel 자동변환방지 문자가 붙어있는 경우 제거해준다.
 * @param {array} csvArr - import 받은 csv 파일로 받은 object 로 이루어진 배열
 * @returns {array} - 자동변환방지 문자가 제거된 객체들의 배열
 */
export const propChange = (csvArr) => {
	const flagArr = [];
	return csvArr.map(obj => {
		const autoFixObj = removeAutoFixString(obj); // obj 객체에서 자동변환방지 문자를 제거한다.
		const textFlagObj = setTextFlag(autoFixObj, flagArr); // type 속성이 text 인 객체들에게 editor 객체의 flag 속성을 전달한다.
		return textFlagObj;
	});
};

/**
 * 객체의 type 속성이 text 면 flag 속성값이 없으므로 상위에 속하는 editor 객체의 flag 속성을 받는다.
 * @param {object} obj - type 속성값이 text 인 객체
 * @param {array} flagArr - 상위에 속하는 editor 객체의 flag 정보가 담긴 배열
 * @returns {object} - flag 속성을 갖은 type 이 text 인 객체와 type 이 editor 인 객체
 */
const setTextFlag = (obj, flagArr) => {
	if (obj.type === 'editor') {
		flagArr[0] = obj.flag;
	} else {
		obj.flag = flagArr[0];
	}
	return obj;
};

/**
 * 객체내에서 originId, id, textId 등의 속성을 가진 경우 속성 값에서 자동변환방지 문자를 제거한다.
 * @param {object} obj - csv import 받은 데이터의 객체
 * @returns {object} - originId, id, textId 속성의 값에서 자동변환방지 문자를 제거한 객체
 */
const removeAutoFixString = (obj) => {
	const originIdObj = removeAutoStr(obj, 'originId');
	const idObj = removeAutoStr(originIdObj, 'id');
	const textIdObj = removeAutoStr(idObj, 'textId');
	return textIdObj;
};

/**
 * 객체와 속성명을 인자로 받아서 객체 내의 해당 속성의 값에 자동변환방지 문자열이 들어간 경우 그 문자열만 제거한다.
 * @param {object} obj - 자동변환방지 문자를 제거할 객체
 * @param {string} target - 객체 내에 접근할 속성 이름
 * @returns {object} - obj 의 target 속성에서 자동변환방지문자를 제거한 객체
 */
const removeAutoStr = (obj, target) => {
	const newObj = Object.assign({}, obj);
	if (newObj.hasOwnProperty(target)) {
		let csvId = newObj[target];
		if (csvId.substring(0, 4) === `"=""`) {
			csvId = csvId.substring(4, csvId.length - 3);
			newObj[target] = csvId;
		}
	}
	return newObj;
};

/**
 *  csv 데이터 정보가 담긴 객체들의 배열을 읽어서 flag 에 따라서
 *  追加, 修正, 削除의 갯수와 전체갯수 알려주는 문자열을 반환하는 함수
 * @param {array} changedObjData - csv 에서 읽어들인 데이터 배열
 * @returns {string} - 객체 속성에서 obj.flag 속성에 따라 追加, 修正, 削除의 갯수와 전체갯수 알려주는 문자열
 */
export const getRequestCountMsg = (changedObjData) => {
	let countCreate, countUpdate, countDelete;
	countCreate = countUpdate = countDelete = 0;
	for (const row of changedObjData) {
		const flag = parseInt(row.flag);
		if (flag === 1) {
			countCreate++;
		} else if (flag === 2) {
			countUpdate++;
		} else if (flag === 3) {
			countDelete++;
		}
	}
	const allCount = countCreate + countUpdate + countDelete;
	let message = `全体件数 ${allCount}件`;

	if (countCreate > 0) message += `　追加${countCreate}件`;
	if (countUpdate > 0) message += `　修正${countUpdate}件`;
	if (countDelete > 0) message += `　削除${countDelete}件`;
	return message;
};