import React, {Component} from 'react';
import {connect} from "react-redux";
import {RikaScrollTable} from "../../../components/Contents/Editor/Panels/SubviewPanel/innerSubview";
import {
    changeRectData,
    removeScrollImage,
    changeScrollImageCss,
    changeScrollRectId,
    changeRectOptions,
    changeRectReferParam,
    changeRectDataBlock,
    changeRectDataSvg,
} from "../../../reducers/CombineReducers/pageData";
import {togglePanel} from "../../../reducers/CombineReducers/panel";
import {modalMsg, modalTitle, objectModeConst, subViewRect, rikaScroll, rikaAnswerType} from "../../../interface/constant";
import {randomStr} from "../../../interface/utils";
import {modifyRepoPath} from "../../../reducers/CombineReducers/repo";
import Modal from "../../../components/Common/Modal";
import {getObjects} from "../Editor/EditorLayers/ObjectLayer";
import {fileUpload} from "../../../components/Common/Upload";

const mapStateToProps = state => {
    const {id, page, blockId} = state.current.editor.selected;
    const {editor} = state.current;
    const objMode = objectModeConst[editor.objectMode];
    let props = {};
    if (id && page) {
        let objectList = state.pageData[page].objects[objMode];
        const idx = objectList.findIndex((item) => item.originId === id);
        const subViewSettings = editor.objectMode === "subView" ? objectList[idx].data.subViewSettings : null;
        const options = editor.objectMode === "subView" ? objectList[idx].options : null;
        const htmlPath = editor.objectMode === "subView" ? objectList[idx].data.path : null;
        props = {
            selectedId: id,
            selectedPage: page,
            selectedBlockId: blockId,
            bookId: state.metaData.bookId,
            subViewObj: state.pageData[page].objects["subView"],
            maskObj: state.pageData[page].objects["mask"],
            blockObj: state.pageData[page].objects["block"],
            panel: state.panel,
            repo: state.repo,
            subViewSettings,
            options,
            htmlPath,
            idx,
        }

    }
    return props;
};

const mapDispatchToProps = {
    changeRectData,
	modifyRepoPath,
    removeScrollImage,
    changeScrollImageCss,
    changeScrollRectId,
    changeRectOptions,
    changeRectReferParam,
    changeRectDataBlock,
    changeRectDataSvg,
    togglePanel,
};

let previousRectId = "";

const ADD_OFFSET = 10;
let maskOffsetX = ADD_OFFSET;
let maskOffsetY = ADD_OFFSET;

class RikaScroll extends Component {
    constructor(props) {
        super(props);
        this.input = {
            rectIdSelectBox: React.createRef()
        };
        this.state = {
            showModalDelete: false,
        };
        this.deleteProcess = null;
    }

    async RikaScrollImageUpload(e, scrollIdx = -1) {
        const imageType = e.target.name;
        const result = await fileUpload(e, this.props.modifyRepoPath, this.props.bookId, "image", this.props.repo);
        if (!result || !result.fileUpload) {
            this.props.showErrorModal();
            return;
        }

        let data = this.setScrollData(result.data.key, imageType, scrollIdx);

        this.props.changeRectData({
            id: this.props.selectedId,
            page: this.props.selectedPage,
            data: data,
            objMode: "subView",
        });
    }

    setScrollData(key, imageType, scrollIdx) {
        const {subViewSettings} = this.props;
        let data = {};
        if(imageType === "title") {
            data = {
                path: `/html/${this.props.idx}/`,
                subViewSettings: {
                    ...subViewSettings,
                    options: {
                        ...subViewSettings.options,
                        titleImage: {
                            path: key,
                            width: 400,
                        }
                    }
                }
            };
        }
        else if(imageType === "scroll") {
            let scrollImage = subViewSettings.options.scrollImage || [];
            const rectId = scrollImage.length > 0 ? "" : this.props.selectedId;
            const defaultScrollImage = {
                id: "scroll-" + randomStr(8),
                rectId: rectId,
                path: key,
                width: 400,
                height: 350,
                left: 0,
            };
            if (scrollIdx >= 0) {
                scrollImage[scrollIdx].path = key;
                data = {
                    path: `/html/${this.props.idx}/`,
                    param: 1,
                    subViewSettings: {
                        ...subViewSettings,
                        options: {
                            ...subViewSettings.options,
                            scrollImage: scrollImage,
                        }
                    }
                };
            }
            else {
                data = {
                    path: `/html/${this.props.idx}/`,
                    param: 1,
                    subViewSettings: {
                        ...subViewSettings,
                        options: {
                            ...subViewSettings.options,
                            scrollImage: scrollImage.concat(defaultScrollImage),
                        }
                    }
                };
            }
        }

        return data;
    };

    subViewSettingEvent() {
        return {
            titleImageEdit: (e) => {
                this.props.stateChange("scrollTitleEdit", !this.props.scrollTitleEdit);
            },
            titleImageDelete: (isTitle) => {
                this.props.removeScrollImage({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    isTitle : isTitle,
                });
            },
            scrollImageDeleteOne: (e, scrollIdx) => {
                e.stopPropagation();
                this.props.removeScrollImage({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    isTitle: false,
                    scrollIdx: scrollIdx,
                });

                this.paramChange(scrollIdx);

                this.props.stateChange("scrollLevel", 0);
            },
            scrollImageDelete: (isTitle) => {
                this.deleteProcess = (e) => {
                    e.stopPropagation();
                    this.props.removeScrollImage({
                        id: this.props.selectedId,
                        page: this.props.selectedPage,
                        isTitle : isTitle || false,
                    });
                    this.props.stateChange("scrollLevel", 0);
                    this.toggleModal("Delete", false);
                    this.deleteProcess = () => {};
                };
                this.toggleModal("Delete", true);
            },
            changeScrollImageCss: (e) => {
                this.props.changeScrollImageCss({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    scrollIdx: this.props.scrollLevel,
                    isTitle: this.props.scrollTitleEdit,
                    target: e.target.name,
                    value: Number(e.target.value),
                });
            },
            scrollListClick: (idx) => {
                this.props.stateChange("scrollLevel", idx);
            },
            savePreviousRectId: (e) => {
                previousRectId = e.target.value;
            },
            changeRectId: (e) => {
                this.props.changeScrollRectId({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    scrollIdx: this.props.scrollLevel,
                    rectId: e.target.value,
                });
                this.props.changeRectReferParam({
                    id: e.target.value,
                    page: this.props.selectedPage,
                    referId: this.props.selectedId,
                    param: this.props.scrollLevel + 1,
                    htmlPath: this.props.htmlPath,
                    previousRectId: previousRectId,
                });
                this.input.rectIdSelectBox.current.blur();
            },

            scrollObjectModeChange: (e) => {
                this.props.stateChange("scrollObjectMode", e.target.value);
            },
            addObject: () => {
                const {subViewSettings} = this.props;
                const {button, contents} = rikaAnswerType.answer;
                const scrollObjectMode = this.props.scrollObjectMode;
                let optionsName = scrollObjectMode;
                if (scrollObjectMode === button.name || scrollObjectMode === contents.name) {
                    optionsName = "answer";
                }
                let obj = subViewSettings.options[optionsName] || [];

                let defaultObj = this.setDefaultObject(scrollObjectMode, optionsName);

                maskOffsetX += ADD_OFFSET;
                maskOffsetY += ADD_OFFSET;

                const data = {
                    subViewSettings: {
                        ...subViewSettings,
                        options: {
                            ...subViewSettings.options,
                            [optionsName]: obj.concat(defaultObj),
                        },
                    }
                };

                this.props.changeRectData({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    data: data,
                    objMode: "subView",
                });
            },
            objectConnectChange: (e) => {
                const data = {
                    rectId: e.target.value,
                };

                this.props.changeRectDataBlock({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    blockId: this.props.selectedBlockId,
                    data: data,
                    subViewType: this.props.scrollObjectMode,
                });
            },
            changeScrollRectCss: (e) => {
                const value = Number(e.target.value);
                const {button, contents} = rikaAnswerType.answer;
                const scrollObjectMode = this.props.scrollObjectMode;
                let type = scrollObjectMode;
                if (scrollObjectMode === button.name || scrollObjectMode === contents.name) {
                    type = "answer";
                }

                const data = {
                    [e.target.name]: value
                };

                this.props.changeRectDataBlock({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    blockId: this.props.selectedBlockId,
                    data: data,
                    subViewType: type,
                });
            },
            changeAnswerValue: (e) => {
                const data = {
                    [e.target.name]: e.target.value
                };

                this.props.changeRectDataBlock({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    blockId: this.props.selectedBlockId,
                    data: data,
                    subViewType: subViewRect.rikaAnswer,
                });
            },
            svgCopy: (selectedRect) => {
                if (!selectedRect) return;
                const rectId = selectedRect.rectId;
                const data = {};

                if (rectId) {
                    const idx = this.props.maskObj.findIndex((mask) => mask.originId === rectId);
                    if (idx >= 0 && this.props.maskObj[idx] && this.props.maskObj[idx].svg) {
                        data.svg = this.props.maskObj[idx].svg;
                    }
                }

                this.props.changeRectDataSvg({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    blockId: this.props.selectedBlockId,
                    data: data,
                    subViewType: subViewRect.rikaMask,
                });
            },
        }
    }

    setDefaultObject(scrollObjectMode, optionsName) {
        let defaultObj = {};
        if (optionsName === "mask") {
            defaultObj = {
                id: scrollObjectMode + "-" + randomStr(8),
                rectId: "",
                width: 80,
                height: 30,
                left: maskOffsetX,
                top: maskOffsetY - (rikaScroll.scrollTop * this.props.scrollLevel),
            }
        } else if (optionsName === "answer") {
            defaultObj = {
                id: optionsName + "-" + randomStr(8),
                group: 1,
                type: scrollObjectMode,
                width: rikaAnswerType.answer.button.width,
                height: rikaAnswerType.answer.button.height,
                left: maskOffsetX,
                top: maskOffsetY - (rikaScroll.scrollTop * this.props.scrollLevel),
            }
        }
        return defaultObj;
    }

    // 스크롤이미지 삭제 후 남은 스크롤 이미지들과 연동된 지면의 data.param값(scroll순서) 변경
    paramChange(scrollIdx) {
        const {subViewSettings} = this.props;
        const scrollImage = subViewSettings.options.scrollImage.filter((item, idx) => idx > scrollIdx);

        scrollImage.map((scroll, idx) => {
            if (!scroll.rectId) return scroll;
            const data = {
                param: scrollIdx + (idx + 1),
            };

            this.props.changeRectData({
                id: scroll.rectId,
                page: this.props.selectedPage,
                data: data,
                objMode: "subView",
            });
            return scroll;
        });
    }

    toggleModal(type = "Delete", show = true) {
        let modalType = `showModal${type}`;
        this.setState({
            ...this.state,
            [modalType]: show,
        })
    }

    modalCancel() {
        this.toggleModal("Delete", false);
        this.deleteProcess = () => {};
    }

    render() {
        const {subViewSettings, subViewObj} = this.props;
        const {maskObj, blockObj, selectedBlockId} = this.props;
        const {button, contents} = rikaAnswerType.answer;

        const scrollObjectMode = this.props.scrollObjectMode;
        let optionsName = scrollObjectMode;
        if (scrollObjectMode === button.name || scrollObjectMode === contents.name) {
            optionsName = "answer";
        }
        const scrollObj = subViewSettings.options[optionsName];
        const idx = scrollObj ? scrollObj.findIndex((item) => item.id === selectedBlockId) : -1;

        let rectObj = [];
        if(scrollObjectMode === subViewRect.rikaMask) rectObj = getObjects(maskObj, scrollObjectMode) || [];
        else if(scrollObjectMode === subViewRect.rikaBlock) rectObj = getObjects(blockObj, scrollObjectMode) || [];

        const mask = subViewSettings.options.mask;
        const maskIdx = mask ? mask.findIndex((item) => item.id === selectedBlockId) : -1;

        const svgData = {
            changeRectDataSVG: this.props.changeRectDataSvg,
            togglePanel: this.props.togglePanel,
            item: maskIdx >= 0 ? mask[maskIdx] : {},
            panel: this.props.panel,
            id: this.props.selectedId,
            page: this.props.selectedPage,
            blockId: this.props.selectedBlockId,
            objMode: "subview",
            mode: this.props.mode,
            svg: maskIdx >= 0 && mask[maskIdx].svg ? mask[maskIdx].svg.attr.points : "",
            subViewType: subViewRect.rikaMask
        };
        return (
            <>
                <RikaScrollTable
                    event={this.subViewSettingEvent()}
                    upload={(e, scrollIdx) => this.RikaScrollImageUpload(e, scrollIdx)}
                    bookId={this.props.bookId}
                    titleImage={subViewSettings.options.titleImage || ""}
                    scrollImage={subViewSettings.options.scrollImage || []}
                    currentIdx={this.props.scrollLevel}
                    scrollTitleEdit={this.props.scrollTitleEdit}
                    inActive={this.props.scrollTitleEdit ? "in-active" : ""}
                    subViewObj={subViewObj}
                    input={this.input}
                    optionDisabled={this.props.scrollLevel === 0}
                    scrollObjectMode={this.props.scrollObjectMode}
                    optionsName={optionsName}
                    rectObj={rectObj}
                    subViewSettingsRect={ idx < 0 ? null : subViewSettings.options[optionsName][idx]}
                    selectedMaskId={selectedBlockId}
                    svgData={svgData}
                    tabMenu={this.props.tabMenu}
                />
                {this.state.showModalDelete && <Modal
                    onClickCancel={this.modalCancel.bind(this)}
                    onClickConfirm={(e) => this.deleteProcess(e)}
                    msg={modalMsg.INIT}
                    title={modalTitle.WARNING}
                />}
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RikaScroll);