import React  from 'react';
import { connect } from 'react-redux'
import CheckSheetItem from "../../../components/Contents/Editor/Panels/CheckSheetPanel/CheckSheetItem";
import {
	changeRectClassAndCss,
} from "../../../reducers/CombineReducers/pageData";
import { objectModeConst } from "../../../interface/constant";


const mapStateToProps = state => {
	const objMode = objectModeConst[state.current.editor.objectMode];
	let {id, page} = state.current.editor.selected;
	let prop = {};
	if (id && page) {
		let objectInfo = state.pageData[page].objects[objMode];
		let objIndex = objectInfo.findIndex(item => item.originId === id);
		prop = {
			item: objectInfo[objIndex] || {},
			index: objIndex,
			mode: state.current.editor.mode,
		};
	}
	return prop;
};

const mapDispatchToProps = {
	changeRectClassAndCss
};

const CheckSheet = (props) => {
	const { item, mode } = props;
	const { changeRectClassAndCss } = props;


	const removeClassAndCss = (selectedItemClass, objMode, css = {}) => {
		return new Promise((resolve) => {
			for (const cssKey of Object.keys(css)) {
				delete item.css[cssKey];
			}
			changeRectClassAndCss({
				id: item.id,
				page: item.page,
				newClass: selectedItemClass,
				newCss: item.css,
				objMode
			});
			resolve(item.css);
		})
	};

	const restoreClassAndCss = (objMode, afterCss, restoreCss, selectedItemClass) => {
		restoreCss = { ...afterCss, ...restoreCss };
		const restoreClass = selectedItemClass.split(' ').filter(cls => !(cls.includes('check')));
		changeRectClassAndCss({
			id: item.id,
			page: item.page,
			newClass: restoreClass.join(' '),
			newCss: restoreCss,
			objMode
		});
	};

	const addClassAndCss = (afterCss, userSelectClass, objMode, selectedItemClass) => {
		const addCss = {
			width: 22,
			height: 22,
			background: "url('lib/images/icon/natural_sciences/check-red.png') no-repeat",
			backgroundSize: "contain",
		};
		const newCss = { ...afterCss, ...addCss };
		const selectClassArr = selectedItemClass.split(' ').filter(cls => !(cls.includes('check')));
		selectClassArr.push(userSelectClass);
		selectClassArr.push('check-noClick');
		changeRectClassAndCss({
			id: item.id,
			page: item.page,
			newClass: selectClassArr.join(' '),
			newCss: newCss,
			objMode
		});
	};


	const onClickCheckSheet = async (e) => {
		if (!item) return; // 선택된게 없으면 동작하지 않음
		const userSelectClass = e.target.getAttribute("name"); // 선택한 checkSheet class
		const objMode = (mode === 'checkSheet') ? 'mask' : mode;
		const selectedItemClass = item.data.class; // 현재 선택한 mask 의 class
		const isToggle = selectedItemClass.includes(userSelectClass); // 이미 check1 인데 한번 더 check1 누르면 토글

		const maskCss = {
			width: 100,
			height: 100,
		};
		const afterCss = await removeClassAndCss(selectedItemClass, objMode, maskCss);
		if (isToggle) {
			restoreClassAndCss(objMode, afterCss, maskCss, selectedItemClass);
		} else {
			addClassAndCss(afterCss, userSelectClass, objMode, selectedItemClass);
		}
	};

	return (<CheckSheetItem onClick={onClickCheckSheet}/>)
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckSheet);
