import React, { Component } from 'react';
import { connect } from 'react-redux';
import {csvImporting, setBookSaved, setContents, startLoading, endLoading, unsetSelectItem, removeLog } from '../../reducers/CombineReducers/current';
import { chkValidate, doLogout, saveBook, getBookData, saveLogFile, addEditBookLog } from "../../interface/api";
import { getCookie } from "../../interface/utils";
import { setData } from "../../interface/db";
import "./Menu.scss";
import {
	keyAction,
	keyModel,
	loadingMsg,
	modalMsg,
	modalTitle,
	userLevel,
} from "../../interface/constant";
import Modal from "../../components/Common/Modal";
import AddToolTip from "../../components/Common/AddToolTip";
import InitToolTip from "../../components/Common/InitToolTip";

const {ORDER_SAVE} = keyAction;

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		contents: state.current.contents,
		bookReady: state.current.book.ready,
		log: state.current.log,
		bookChanged: state.current.book.changed,
		bookId: state.metaData.bookId,
		meta: state.metaData,
		pageData: state.pageData,
		repo: state.repo,
		pages: state.current.editor.pages,
	};
};

const mapDispatchToProps = {
	setContents,
	chkValidate,
	setData,
	setBookSaved,
	csvImporting,
	startLoading,
	endLoading,
	unsetSelectItem,
	removeLog,
};

let keyDownFunc = null;

class Menu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModalError: false,
			showModalConflict: false,
			conflictMsg: "",
			userList: null,
			bookList: null,
		};
		this.overwriteProcess = null;
		this.discardProcess = null;
	}

	componentDidMount() {
		const token = getCookie("token");
		if (token) this.props.chkValidate();

		keyDownFunc = (e) => this.keyEvent().onKeyDown(e);
		document.addEventListener(
			"keydown",
			keyDownFunc,
			false
		);
	}

	componentWillUnmount() {
		document.removeEventListener(
			"keydown",
			keyDownFunc,
			false
		);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const prevInfo = prevProps.auth.info;
		const currentInfo = this.props.auth.info;
		if (prevInfo !== currentInfo && currentInfo && !this.state.userList) {
			this.props.socket.init(currentInfo, this.setStateUserList.bind(this))
		}
	}

	keyEvent() {
		return {
			onKeyDown: (e) => {
				if (!e.ctrlKey || e.keyCode !== 83) return;	//ctrl+sだけ実行
				e.preventDefault();
				let which = e.keyCode + (e.shiftKey ? "+shift" : (e.ctrlKey ? "+ctrl" : (e.altKey ? "+alt" : "")));
				if (!Object.keys(keyModel).includes(which)) return;
				let {order} = keyModel[which];
				if (order === ORDER_SAVE) {
					this.onClickSave();
				}
			}
		}
	}

	onClick(e) {
		const token = getCookie("token");
		(token) ? this.props.chkValidate() : doLogout();
		let dataId = e.currentTarget.getAttribute("data-id");
		if (dataId === 'editor') this.props.csvImporting(false);
		this.props.setContents({
			contents: dataId
		});
	}

	async onClickSave() {
		if (!this.props.bookChanged) return;
		const {left, right} = this.props.pages;
		this.props.startLoading({msg: loadingMsg.LOADING});
		let savePageData = {};
		savePageData[left] = this.props.pageData[left];
		savePageData[right] = this.props.pageData[right];
		let data = {
			pageData: savePageData,
			metaData: this.props.meta,
			repo: this.props.repo,
		};

		const result = await saveBook(this.props.bookId, data, [left, right]);
		if (result.success) {
			if (result.conflictInfo) {
				this.props.endLoading();
				this.setConflictMsg(result.conflictInfo);
				this.setConflictProcess([left, right], data);
				this.toggleModal("Conflict");
				return;
			}
			let resultData = result.resultData;
			resultData.repo = this.props.repo;
			await this.props.setData(resultData);
			this.props.setBookSaved();
			await this.saveLog();
			this.props.endLoading();
		} else {
			this.props.endLoading();
			this.toggleModal();
		}
	}

/*	async saveLog() {
		const log = this.props.log || [];
		let contents = "";
		for(let logMsg of log) {
			contents += logMsg;
		}
		const result = await saveLogFile(this.props.bookId, contents);
		if(result && result.success){
			this.props.removeLog();
		}
		console.log(result);
	}*/

	async saveLog() {
		const log = this.props.log || [];
		const addResult = await addEditBookLog(this.props.bookId, log);
		if(addResult && addResult.success){
			this.props.removeLog();
		}
	}

	setConflictMsg(conflictInfo) {
		const msg1 = `CONFLICTが発生しました。上書きしますか？<br>`;
		const msg2 = `　対象：${conflictInfo.page}.json<br>`;
		const msg3 = `revision:<br>`;
		const msg4 = `　${conflictInfo.page}.json: ${conflictInfo.myRev}(自分)/ ${conflictInfo.dbRev}(${conflictInfo.modifyUser})<br>`;
		this.setState({
			conflictMsg: msg1 + msg2 + msg3 + msg4,
		});

	}

	toggleModal(type = "Error", show = true) {
		let modalType = `showModal${type}`;
		this.setState({
			...this.state,
			[modalType]: show,
		})
	}

	getClassName(id) {
		let defaultId = `menu-btn ${id}`;
		if (this.props.contents === id) defaultId += " selected";
		if (!this.props.bookReady && id !== "home" && id !== "account" && id !== "adminSetting" && id !== "receipt" && id !== "log") defaultId += "  hide";
		return defaultId;
	}

	pageReceiptElem() {
		return (
			<div className={this.getClassName("receipt")} data-id={"receipt"}
				 onClick={this.onClick.bind(this)}>
				<div className={"menu_icon"}>
					<p className={"h-space icon_account"}/>
					発行履歴
				</div>
			</div>
		)
	}

	bookSettingElem() {
		return (
			<div className={this.getClassName("setting")} data-id={"setting"}
				 onClick={this.onClick.bind(this)}>
				<div className={"menu_icon"}>
					<p className={"h-space icon_setting"}/>
					設定
				</div>
			</div>
		);
	}

	adminSettingElem() {
		return (
			<div className={this.getClassName("adminSetting")} data-id={"adminSetting"}
					 onClick={this.onClick.bind(this)}>
				<div className={"menu_icon"}>
					<p className={"h-space icon_account"}/>
					管理者設定
				</div>
			</div>
		)
	}

	logElem() {
		return (
			<div className={this.getClassName("log")} data-id={"log"}
				 onClick={this.onClick.bind(this)}>
				<div className={"menu_icon"}>
					<p className={"h-space icon_account"}/>
					ログ
				</div>
			</div>
		)
	}

	userListElem() {
		if (!this.state.userList) {
			return (
				<div style={{color:"#FFFFFF"}}>
					Connecting...
				</div>
			);
		} else {
			return (
				<div style={{color:"#FFFFFF"}}>
					{Object.keys(this.state.userList).map((userId, idx) => {
						const icon = this.state.userList[userId].icon;
						return (
							<div key={idx} className={"user-icon " + icon}>
								<AddToolTip text={userId} direction={"right"} isQuestion={false}/>
								<InitToolTip/>
							</div>
						)
					})}
				</div>
			)
		}
	}

	setStateUserList(userList) {
		this.setState({
			userList
		});
	}

	modalCancel(type) {
		this.toggleModal(type, false);
		this.overwriteProcess = () => {};
		this.discardProcess = () => {};
	}

	//저장 시 충돌났을때 버튼 이벤트 생성
	setConflictProcess(pages, data) {
		this.overwriteProcess = async () => {
			// 덮어쓰기
			this.props.unsetSelectItem();
			this.toggleModal("Conflict", false);
			this.props.startLoading({msg: loadingMsg.LOADING});

			const result = await saveBook(this.props.bookId, data, pages, true);
			let resultData = result.resultData;
			resultData.repo = this.props.repo;
			await this.props.setData(resultData);
			this.props.setBookSaved();

			this.props.endLoading();
			this.overwriteProcess = () => {};
			this.discardProcess = () => {};
		};
		this.discardProcess = async () => {
			// 파기
			this.props.unsetSelectItem();
			this.toggleModal("Conflict", false);
			this.props.startLoading({msg: loadingMsg.LOADING});

			const bookData = await getBookData(this.props.bookId, pages);
			this.props.setData(bookData.data);
			this.props.setBookSaved();

			this.props.endLoading();
			this.overwriteProcess = () => {};
			this.discardProcess = () => {};
		};
	}


	render() {
		let addClass = this.props.auth.info ? "" : " hide";
		let saveBtnActive = this.props.bookChanged ? "" : " de-active";
		let info = this.props.auth.info;
		return (
			<div id={"Menu"} className={"menu menu-left"}>
				<div className={"menu-container menu-btn-container" + addClass}>
					<div className={this.getClassName("home")} data-id={"home"}
					     onClick={this.onClick.bind(this)}>
						<div className={"menu_icon"}>
							<p className={"h-space icon_home"}/>
							ホーム
						</div>
					</div>
					<div className={this.getClassName("editor")} data-id={"editor"}
					     onClick={this.onClick.bind(this)}>
						<div className={"menu_icon"}>
							<p className={"h-space icon_editor"}/>
							編集
						</div>
					</div>
					<div className={this.getClassName("import")} data-id={"import"}
					     onClick={this.onClick.bind(this)}>
						<div className={"menu_icon"}>
							<p className={"h-space icon_import"}/>
							CSV
						</div>
					</div>
					{info && info.id === this.props.meta.author ? this.bookSettingElem() : ""}
				</div>
				<div className={"menu-container menu-icon-container" + addClass}>
					{info ? this.userListElem() : ""}
					{info && info.level === userLevel.admin ? this.logElem() : ""}
					{info && info.level === userLevel.admin ? this.adminSettingElem() : ""}

					{this.pageReceiptElem()}
					<div className={this.getClassName("account")} data-id={"account"}
					     onClick={this.onClick.bind(this)}>
						<div className={"menu_icon"}>
							<p className={"h-space icon_account"}/>
							アカウント
						</div>
					</div>
					<div className={this.getClassName("save") + saveBtnActive} data-id={"save"}
					     onClick={this.onClickSave.bind(this)}>
						<div className={"menu_icon"}>
							<p className={"h-space icon_save"}/>
							保存
						</div>
					</div>
				</div>
				{this.state.showModalError && <Modal
					onClickConfirm={() => this.toggleModal("Error", false)}
					msg={modalMsg.ERROR}
					title={modalTitle.ERROR}
				/>}
				{this.state.showModalConflict && <Modal
					conflictEvent={{ overwrite: () => this.overwriteProcess(), discard: () => this.discardProcess() }}
					onClickCancel={() => this.modalCancel("Conflict", false)}
					msg={this.state.conflictMsg}
					title={modalTitle.WARNING}
				/>}
			</div>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);