import React, { Component } from 'react';
import { connect } from 'react-redux'
import { changeGuideModeOnMultiItems, addGuideMode } from "../../../reducers/CombineReducers/pageData";
import { randomStr } from "../../../interface/utils";
import PropTypes from "prop-types";
import GuideModeEdit from "../../../components/Contents/Editor/Panels/GuideModePanel/GuideModeEdit";

const mapStateToProps = state => {

	const {left, right} = state.current.editor.pages;

	let pagesData = [left, right];

	let guideModeObject = [];
	for(let page of pagesData) {
		let pageObj = state.pageData[page].objects.guideMode;
		if (pageObj) {
			guideModeObject = guideModeObject.concat(getObject(pageObj));
		}
	}
	let subViewObject = [];
	for(let page of pagesData) {
		let pageObj = state.pageData[page].objects.subView;
		if (pageObj) {
			subViewObject = subViewObject.concat(getObject(pageObj));
		}
	}
	return {
		groupName: `guideMode${left}-${right}`,
		pageData: state.pageData,
		guideModeObject,
		subViewObject,
	}
};

const mapDispatchToProps = {
	changeGuideModeOnMultiItems,
	addGuideMode
};

const getObject = (pageObj) => {
	let objArr = [];
	pageObj.map((item) => {
		objArr = objArr.concat(item);
		return item;
	});
	return objArr;
};
class GuideModeList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			groupList: {},
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if(nextProps === prevState.prevProps) return null; // for avoid react bug
		let groupList = {
			[nextProps.groupName]: [],
		};
		for (let item of nextProps.guideModeObject) {
			let group = item.options.group;
			if (!group.hasOwnProperty("name") || !group.hasOwnProperty("index")) continue;
			if (!groupList[group.name]) groupList[group.name] = [];
			const idx = nextProps.subViewObject.findIndex((subView) => subView.originId === item.subViewId);
			const itemId = nextProps.subViewObject[idx].id;
			groupList[group.name].push({
				originId: item.originId,
				id: item.id,
				name: group.name,
				idx: group.index,
				page: item.page,

				itemId: itemId,
			});
			groupList[group.name].sort((a, b) => {
				if (a.idx > b.idx) {
					return 1;
				} else if (a.idx < b.idx) {
					return -1;
				}
				return 0;
			});
		}
		return {groupList, prevProps: nextProps};
	}

	groupEditElem() {
		const groupListKey = Object.keys(this.state.groupList);
		if (groupListKey.length <= 0) {
			return (<div className={"no-group-list"}>追加してください。</div>)
		} else {
			return groupListKey.map((itemKey, idx) => {
				return (
					<GuideModeEdit
						key={idx}
						id={itemKey}
						index={idx}
						item={this.state.groupList[itemKey]}
						addGroupOnList={this.addGroupOnList.bind(this)}
						swapGroup={this.swapGroup.bind(this)}
						swapEnd={this.swapEnd.bind(this)}
						deleteItem={this.deleteItem.bind(this)}
					/>
				)
			})
		}
	}

	swapGroup(key, dragIdx, hoverIdx) {
		const dragItemList = [...this.state.groupList[key]];
		[dragItemList[dragIdx], dragItemList[hoverIdx]] = [dragItemList[hoverIdx], dragItemList[dragIdx]];
		this.setState({
			groupList: {
				...this.state.groupList,
				[key]: dragItemList,
			}
		});

		this.props.changeGuideModeOnMultiItems({
			updated: this.getFlatGroup(key, dragItemList),
			deleted: [],
		})
	}

	swapEnd(key) {
		// this.props.changeGuideModeOnMultiItems({
		// 	updated: this.getFlatGroup(key, this.state.groupList[key]),
		// 	deleted: [],
		// })
	}

	getFlatGroup(key, group) {
		let groupArr = [];
		for (let i = 0; i < group.length; i++) {
			groupArr.push({
				groupName: key,
				groupIndex: i + 1,
				originId: group[i].originId,
				id: group[i].id,
				page: group[i].page,
			})
		}
		return groupArr;
	}

	findItem(key, originId) {
		return this.state.groupList[key].find(item => item.originId === originId);
	}

	addGroupOnList(key, item) {
		const groupList = this.state.groupList;
		const isItemExist = Object.values(groupList).flat().some((obj) => obj.originId === item.originId);
		if (isItemExist) return;

		const id = `${item.page}-${randomStr(8)}`;

		let subViewObj = this.props.pageData[item.page].objects.subView;
		let objIdx = subViewObj.findIndex((obj) => item.originId === obj.originId);
		// let subViewOptions = {...subViewObj[objIdx].options};
		const options = {
			// ...subViewOptions,
			link: {
				level: 5
			},
			group: {
				name: key,
				index: groupList[this.props.groupName].length + 1,
			}
		};
		this.props.addGuideMode({
			id,
			page: item.page,
			options,
			subViewId: subViewObj[objIdx].originId,
		});
	}

	deleteItem(key, originId) {
		let filtered = this.state.groupList[key].filter(item => item.originId !== originId);
		const item = this.findItem(key, originId);
		let groupList = this.getFlatGroup(key, filtered);
		this.props.changeGuideModeOnMultiItems({
			updated: groupList,
			deleted: [{
				originId: item.originId,
				page: item.page,
			}]
		})
	}

	render() {
		return (
			<div className={"inner-group-list"}>
				<div className={"group-list"}>
					{this.groupEditElem()}
				</div>
			</div>
		);
	}
}

GuideModeList.defaultProps = {
	pageData: {},
	groupObject: [],
};
GuideModeList.propTypes = {
	pageData: PropTypes.object.isRequired,
	groupObject: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(GuideModeList);
