import React, { Component } from "react";
import { connect } from 'react-redux';
import { setPages, setBookSaved, startLoading, endLoading, unsetSelectItem } from "../../../../reducers/CombineReducers/current";
import {directionName, loadingMsg, modalMsg, modalTitle} from "../../../../interface/constant";
import {getBookData, saveBook} from "../../../../interface/api";
import {setData} from "../../../../interface/db";
import Modal from "../../../../components/Common/Modal";

const mapStateToProps = (state) => {
	return {
		meta: state.metaData,
		direction: state.metaData.direction,
		total: state.metaData.totalPages,
		pages: state.current.editor.pages,
		scale: state.current.editor.scale,
		bookChanged: state.current.book.changed,
		auth: state.auth,
		pageData: state.pageData,
		repo: state.repo,
	}
};

const mapDispatchToProps = {setPages, setData, setBookSaved, startLoading, endLoading, unsetSelectItem};

const DIRECTION_LEFT = 0;
const DIRECTION_RIGHT = 1;
const NAVIGATION_HEIGHT = 50;

class EditorNavigation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editingUserList: [],
			showModalSave: false,
			showModalMoveError: false,
			showModalConflict: false,
		};
		this.saveProcess = null;
		this.overwriteProcess = null;
		this.discardProcess = null;
		this.props.socket.getBookList(this.setStateEditingUserList.bind(this));
	}

	// 페이지이동 버튼 클릭 이벤트
	async onClick(e) {
		if (this.props.bookChanged) {
			this.showSaveModal();
			return;
		}

		let direction = parseInt(e.currentTarget.getAttribute("data-direction"), 10);
		const {low, high} = this.getHighLowEvenOdd();
		let pages = [this.props.pages.left, this.props.pages.right];
		if (high === direction) {
			pages = [pages[0] + 2, pages[1] + 2];
		} else if (low === direction) {
			pages = [pages[0] - 2, pages[1] - 2];
		}

		if (!this.pageEditingCheck(pages)) return;

		let bookData = await getBookData(this.props.meta.bookId, pages);
		this.props.setData(bookData.data);
		this.props.setPages({pages});

		const editingPage = this.props.direction === directionName.rtl ? pages[1] : pages[0];
		this.props.socket.setEditingPage(this.props.auth.info.id, editingPage, this.props.meta.bookId);
		this.props.setBookSaved();
	}

	// 이동하려는 페이지가 편집중이거나 잠겨있는 페이지인지 체크
	pageEditingCheck(pages) {
		if (this.state.editingUserList && this.state.editingUserList.length > 0) {
			const isEditing = this.state.editingUserList.find(userInfo => userInfo.page === pages[0] || userInfo.page === pages[1]);
			if (isEditing) {
				this.toggleModal("MoveError");
				return false;
			}
		}

		const lockUser = this.props.pageData[pages[0]].pageLockUser;
		if (lockUser && lockUser !== this.props.auth.info.id) {
			this.toggleModal("MoveError");
			return false;
		}

		return true;
	}

	// 책 저장 이벤트
	async saveFunc() {
		if (!this.props.bookChanged) return;
		const {left, right} = this.props.pages;
		this.props.startLoading({msg: loadingMsg.LOADING});
		let savePageData = {};
		savePageData[left] = this.props.pageData[left];
		savePageData[right] = this.props.pageData[right];
		let data = {
			pageData: savePageData,
			metaData: this.props.meta,
		};

		const result = await saveBook(this.props.meta.bookId, data, [left, right]);
		if (result.success) {
			if (result.conflictInfo) {
				this.props.endLoading();
				this.setConflictMsg(result.conflictInfo);
				this.setConflictProcess([left, right], data);
				this.toggleModal("Conflict");
				return;
			}
			let resultData = result.resultData;
			resultData.repo = this.props.repo;
			await this.props.setData(resultData);
		}
		this.props.setBookSaved();
		this.props.endLoading();
	}

	// 저장 시 충돌났을 경우 충돌메시지 내용 설정
	setConflictMsg(conflictInfo) {
		const msg1 = `CONFLICTが発生しました。上書きしますか？<br>`;
		const msg2 = `　対象：${conflictInfo.page}.json<br>`;
		const msg3 = `revision:<br>`;
		const msg4 = `　${conflictInfo.page}.json: ${conflictInfo.myRev}(自分）/ ${conflictInfo.dbRev}(他人)<br>`;
		this.setState({
			conflictMsg: msg1 + msg2 + msg3 + msg4,
		});

	}

	// 저장 시 충돌 버튼 이벤트(덮어쓰기, 파기)
	setConflictProcess(pages, data) {
		this.overwriteProcess = async () => {
			this.props.unsetSelectItem();
			this.toggleModal("Conflict", false);
			this.props.startLoading({msg: loadingMsg.LOADING});

			const result = await saveBook(this.props.meta.bookId, data, pages, true);
			let resultData = result.resultData;
			resultData.repo = this.props.repo;
			await this.props.setData(resultData);
			this.props.setBookSaved();

			this.props.endLoading();
			this.overwriteProcess = () => {};
			this.discardProcess = () => {};
		};
		this.discardProcess = async () => {
			this.props.unsetSelectItem();
			this.toggleModal("Conflict", false);
			this.props.startLoading({msg: loadingMsg.LOADING});

			const bookData = await getBookData(this.props.meta.bookId, pages);
			this.props.setData(bookData.data);
			this.props.setBookSaved();

			this.props.endLoading();
			this.overwriteProcess = () => {};
			this.discardProcess = () => {};
		};
	}

	// 편집 중인 책의 유저정보를 socket 에서 받아와서 state 로 저장
	setStateEditingUserList(editingBookList) {
		const key = Object.keys(editingBookList).find(bookId => bookId === this.props.meta.bookId);
		if (editingBookList.hasOwnProperty(key)) {
			this.setState({
				editingUserList: editingBookList[key],
			});
		} else {
			console.error(editingBookList);
			console.error(this.props.meta.bookId);
		}
	}

	addClassHide(direction) {
		const {even, odd, low, high} = this.getHighLowEvenOdd();
		let isFirstPage = (direction === low && odd === 1);
		let isLastPage = (direction === high && even === this.props.total);
		if (isFirstPage || isLastPage) return " hide";
		return "";
	}

	getHighLowEvenOdd() {
		let low = DIRECTION_LEFT;
		let high = DIRECTION_RIGHT;
		let odd = this.props.pages.left;
		let even = this.props.pages.right;
		if (this.props.direction === "rtl") { //swap;
			[even, odd] = [odd, even];
			[low, high] = [high, low];
		}
		return {even, odd, low, high};
	}

	getStyle() {
		return {
			width: "100%",
			height: (NAVIGATION_HEIGHT * this.props.scale),
			top: `calc(50% - ${NAVIGATION_HEIGHT/2}px`,
		}
	}

	toggleModal(type, show = true) {
		let modalType = `showModal${type}`;
		this.setState({
			...this.state,
			[modalType]: show,
		})
	}

	showSaveModal() {
		this.toggleModal("Save", true);
		this.saveProcess = (e) => {
			this.saveFunc();
			this.toggleModal("Save", false);
			this.saveProcess = () => {};
		}
	}

	modalCancel(type) {
		this.toggleModal(type, false);
		this.saveProcess = () => {};
		this.overwriteProcess = () => {};
		this.discardProcess = () => {};
	}

	render() {
		return (
			<div id={"EditorNavigation"} style={this.getStyle()}>
				<div
					className={"left-btn-container" + this.addClassHide(DIRECTION_LEFT)}
					onClick={this.onClick.bind(this)}
					data-direction={DIRECTION_LEFT}
				>
					<span>◀</span>
				</div>
				<div
					className={"right-btn-container" + this.addClassHide(DIRECTION_RIGHT)}
					onClick={this.onClick.bind(this)}
					data-direction={DIRECTION_RIGHT}
				>
					<span>▶</span>
				</div>
				{this.state.showModalSave && <Modal
					onClickCancel={() => this.modalCancel("Save")}
					onClickConfirm={(e) => this.saveProcess(e)}
					msg={modalMsg.SAVE}
					title={modalTitle.ALERT}
				/>}
				{this.state.showModalMoveError && <Modal
					onClickConfirm={() => this.modalCancel("MoveError")}
					msg={modalMsg.PAGE_MOVE_ERROR}
					title={modalTitle.ERROR}
				/>}
				{this.state.showModalConflict && <Modal
					conflictEvent={{ overwrite: () => this.overwriteProcess(), discard: () => this.discardProcess() }}
					onClickCancel={() => this.modalCancel("Conflict", false)}
					msg={this.state.conflictMsg}
					title={modalTitle.WARNING}
				/>}
			</div>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EditorNavigation);