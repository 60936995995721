import {directionName, metaDataConst, subject} from "../../../interface/constant";
import {csvMeta, csvModalMsg, metaProp, metaStr} from "../../../interface/csvConstant";
import {makeErrorMsg, stringParser} from "../../../interface/utils";

/**
 * flatMeta 객체에서 불필요한 속성을 제거한 객체를 반환하는 함수
 * @param {object} flatMeta - flat 한 일본어 속성이름을 가진 this.props.metaData 객체
 * @returns {object} - removeTargetArr 배열 안의 속성들을 제거한 flatMeta 객체
 */
export const removeMetaDataProp = flatMeta => {
	const tempMeta = Object.assign({}, flatMeta);
	const removeTargetArr = ['lastModified', 'coverPath', 'author', 'totalPages',
		'saved', 'status', '_id', '_rev', 'nowEditing'];
	for (const target of removeTargetArr) {
		if (tempMeta.hasOwnProperty(target)) delete tempMeta[target];
	}
	return tempMeta;
};


/**
 * ブック設定 관련 meta 정보를 이용해서 컬럼과 로우를 생성하는 함수
 * @param {object} meta - clone 한 meta 데이터
 * @param {array} columns - 출력할 순서대로 정렬된 영문 컬럼 배열
 * @returns {string} - meta csv 에서 보여줄 컬럼 과 로우
 */
export const getMetaCsvData = (meta, columns) => {
	const columnsArr = [...columns];
	const valuesArr = [...columns];
	for (const [key, val] of Object.entries(meta)) {
		const idx = columnsArr.indexOf(key);
		columnsArr[idx] = csvMeta[key];
		valuesArr[idx] = val;
	}
	return columnsArr + '\r\n' + valuesArr;
};

/**
 * meta 의 일본어 컬럼명을 영문 컬럼명으로 바꾼 객체를 리턴하는 함수
 * @param {object} meta - import 된 metaData csv 데이터 객체
 */
export const getDevColumnsMeta = (meta) => {
	const newObj = {};
	for (const [key, val] of Object.entries(meta)) {
		for (const [devKey, userKey] of Object.entries(csvMeta)) {
			if (key === userKey) {
				newObj[devKey] = val;
			}
		}
	}
	return newObj;
};

/**
 * reduxMeta 의 width, height 속성을 View 객체 속성의 속성으로 만든 객체를 반환하는 함수
 * @param {object} reduxMeta - flat 한 영문 속성을 가진 metaData 객체
 * @returns {object} - { ... , view: { width: 111, height: 222 } } View 객체 속성을 갖는 meta 데이터 객체
 */
export const setViewObj = (reduxMeta) => {
	const newObj = Object.assign({}, reduxMeta);
	const view = {};
	for (const [k, v] of Object.entries(newObj)) {
		if (k === 'view.height') {
			view[metaStr.height] = parseInt(v);
			delete newObj[k];
		}
		if (k === 'view.width') {
			view[metaStr.width] = parseInt(v);
			delete newObj[k];
		}
	}
	newObj.view = view;
	return newObj;
};

/**
 * setViewObjData 객체의 속성을 순회하면서 속성 값을 파싱한 객체를 리턴하는 함수
 * @param {object} setViewObjData - height, width 속성이 포함된 view 속성을 갖는 metaData 객체
 * @returns {object} 속성값이 string "1" 에서 number 1로 바뀐 객체
 */
export const setReduxMetaData = (setViewObjData) => {
	const newObj = Object.assign({}, setViewObjData);
	for (const [k, v] of Object.entries(setViewObjData)) {
		newObj[k] = stringParser(v);
	}
	return newObj;
};

/**
 * csvUserColumns 과 임의로 정해둔 컬럼명 bookDevColumns 가 서로 다른 경우 에러를 던지는 함수
 * @param {array} csvUserColumns - import 하면서 받은 csv 상의 컬럼 이름 배열
 */
export const metaDataUserColumnsCheck = (csvUserColumns) => {
	const bookDevColumns = [
		csvMeta[metaDataConst.bookId],
		csvMeta[metaDataConst.subject],
		csvMeta[metaDataConst.semester],
		csvMeta[metaDataConst.grade],
		csvMeta[metaDataConst['view.height']],
		csvMeta[metaDataConst['view.width']],
		csvMeta[metaDataConst.direction],
	];

	if (csvUserColumns.toString() !== bookDevColumns.toString()) {
		throw new Error(makeErrorMsg('unmatchedColumn', csvModalMsg.book));
	}
};


/**
 * reduxMeta 객체를 속성을 순회하면서 속성값에 문제가 있는 경우 에러를 던지는 함수
 * @param {object} reduxMeta - import 되어 들어온 metaData 객체를 Redux 형태로 맞춘 객체
 * @param {string} currentBookId - 현재 편집중인 책의 教科書ID
 */
export const rowInvalidCheck = (reduxMeta, currentBookId) => {
	for (const [key, val] of Object.entries(reduxMeta)) {
		switch (key) {
			case metaDataConst.bookId: {
				if (reduxMeta.bookId !== currentBookId) {
					throw new Error(makeErrorMsg('noIdxIncorrect', metaProp[metaDataConst.bookId]));
				}
				break;
			}
			case metaDataConst.grade: {
				const gradeRangeArr = [1,2,3,4,5,6]; // 学年 컬럼의 값으로 올 수 있는 요소들을 담은 배열
				const result = gradeRangeArr.some(ele => ele === val); // 学年 컬럼에 입력한 값이 gradeRangeArr 배열의 요소중 하나인가?
				if (!result) throw new Error(makeErrorMsg('noIdxChooseOne', metaProp[metaDataConst.grade], gradeRangeArr));
				break;
			}
			case metaDataConst.semester: {
				if (Number(val) !== 1 && Number(val) !== 2) {
					throw new Error(makeErrorMsg('noIdxChooseOne', metaProp[metaDataConst.semester], ['1, 2']));
				}
				break;
			}
			case metaDataConst.subject: {
				const subjectArr = Object.keys(subject);
				const result = subjectArr.some(ele => ele === val);
				if (!result) throw new Error(makeErrorMsg('noIdxIncorrect', metaProp[metaDataConst.subject]));
				break;
			}
			case metaDataConst.view: {
				for (const [k,v] of Object.entries(val)) {
					if (v < 0 || v > 1500) { // サイズ(縦)	サイズ(横) 의 입력값을 0 ~ 1500 으로 제한
						throw new Error(makeErrorMsg('noIdxIncorrect', metaProp[k]));
					}
				}
				break;
			}
			case metaDataConst.direction: {
				const directions = [directionName.rtl, directionName.ltr];
				const result = directions.some(ele => ele === val);
				if (!result) {
					throw new Error(makeErrorMsg('noIdxIncorrect', metaProp[metaDataConst.direction]));
				}
				break;
			}
			default:
				break;
		}
	}
};
