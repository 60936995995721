import React, { Component } from 'react';
import PropTypes from "prop-types";

class SVGColumn extends Component {
	render() {
		return (
			<div className="svg-pointer-container">
				<div className="svg-pointer-input">
					<div className="svg-point-color" style={{
						backgroundColor: this.props.color
					}}/>
					<input
						type="text"
						className="svg-column-input"
						data-idx={this.props.index}
						value={this.props.value}
						onChange={this.props.onChangePoint}
					/>
				</div>
				<div className="svg-pointer-btn-container">
					{this.props.length <= 25 && (
						<div className="svg-btn btn-add"
						     data-idx={this.props.index}
						     onClick={this.props.addPoint}
						>+</div>
					)}
					<div className="svg-btn btn-delete"
					     data-idx={this.props.index}
					     onClick={this.props.deletePoint}
					>X</div>
				</div>
			</div>
		)
	}
}

SVGColumn.defaultProps = {
	color: "#ffffff",
	value: "",
	index: 0,
	length: 0,
	onChangePoint: null,
	addPoint: null,
	deletePoint: null,
};

SVGColumn.propTypes = {
	color: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	index: PropTypes.number.isRequired,
	length: PropTypes.number.isRequired,
	onChangePoint: PropTypes.func.isRequired,
	addPoint: PropTypes.func.isRequired,
	deletePoint: PropTypes.func.isRequired,
};

export default SVGColumn;