import {
	csvConst,
	csvModalMsg,
	defaultTextCss,
	meaningBlueKey,
	meaningKeyName,
	meaningRedKey,
	svColumn,
} from "../../../interface/csvConstant";
import {defaultIcon} from "../../../interface/constant";
import {checkNested, flatObject, getNumberLine, makeErrorMsg, merge, randomStr} from "../../../interface/utils";
import {
	flagChecker,
	idNullCheck,
	idSubString,
	inputDuplicateCheck,
	leftInfoCheck,
	originIdChecker,
	overRangeDetailCheck,
	pageInvalidCheck,
	propValueParsing,
	subViewTypeChecker
} from "./csvUtils";
import {
	getAddIconModel,
	getAddSubViewModel,
	getImportBlockModel,
	getImportMaskModel,
	getImportMeaningModel,
	getImportPageLinkModel,
	getImportPostItModel,
	getImportRedLineModel,
	getUpdateIconModel,
	getUpdateSubViewModel
} from "../../../interface/csvModel";


/**
 * originId의 경우 csv 로 부터 "=""1-inj7g0xe""" 를 받아서 1-inj7g0xe 로 바꿔주는 함수
 * @param {array} devColumnData - subView 객체들의 배열
 * @returns {array} - 각 객체의 id, originId 에 넣은 Excel 자동 변환 방지 문자열을 제거한 객체들의 배열
 */
export const protectionStringRemove = (devColumnData) => {
	return devColumnData.map(obj => {
		const idObject = idSubString(obj, 'id');
		return idSubString(idObject, 'originId');
	});
};

/**
 * 검사할 subView 객체 배열을 받아서 flag, type, page , originId 입력값이 올바른지 검사하는 함수
 * @param {array} arr - 검사할 subView 객체들을 담은 배열
 * @param {number} comLength - CSV 파일의 주석 길이
 * @param {number} totalPages - 현재 책의 최대 페이지 수
 * @param {string} bookSubject - 현재 책의 교과 종류
 * @param {array} originIdList - 모든 리덕스 객체의 originId (문자열) 을 담은 배열
 */
export const csvRequireChecker = (arr, comLength, totalPages, bookSubject, originIdList) => {
	for (const obj of arr) {
		const idx = getNumberLine(arr, obj, comLength); // csv 에서 현재 객체가 위치한 라인넘버
		flagChecker(obj, idx, svColumn); // flag 編集는 1,2,3 셋중 하나만 입력 받는지 검사
		subViewTypeChecker(obj, idx, bookSubject, svColumn); // 현재 교과서에 따른 表示モード 입력값이 유효한지 검사
		pageInvalidCheck(obj, idx, totalPages, svColumn); // 현재 객체의 page 정보가 올바른지 검사
		originIdChecker(obj, idx, originIdList);
	}
};

/**
 * objs 의 특정 속성에 속성 값을 넣은 객체를 리턴하는 함수
 * @param {object} objs - flat 한 속성에 문자열 속성값을 갖는 subView 객체
 * @returns {object} - css.position 속성 에 'absolute' 속성값이 들어간 객체
 */
const addProperty = (objs) => {
	const newObj = Object.assign({}, objs);
	if (!newObj.hasOwnProperty(csvConst.cssPosition)) newObj[csvConst.cssPosition] = csvConst.absolute;
	return newObj;
};


/**
 * iconObj 객체가 options.iFrame 속성을 가지고 있고 속성값이 有 면 true 로 無면 false 로 바꾼 객체를 반환하는 함수
 * @param {object} iconObj - flat 한 속성에 문자열 속성값을 갖는 subView 객체
 * @returns {object} - options.Iframe 의 속성 값이 redux 의 형태로 바뀐 객체
 */
const optionsIframeParse = (iconObj) => {
	const newObj = Object.assign({}, iconObj);
	if (newObj.hasOwnProperty('options.iFrame')) {
		if (newObj['options.iFrame'] === "Y") {
			newObj['options.iFrame'] = true;
		} else if (newObj['options.iFrame'] === "N") {
			newObj['options.iFrame'] = false;
		}
	}
	return newObj;
};


/**
 * 객체의 type 속성이 icon 이며 data.class 속성 값이 존재하면 data.class 속성 값 앞에 icon 을 붙이는 함수
 * @param {object} obj - flat 한 속성에 문자열 속성값을 갖는 subView 객체
 * @returns {object} - data.class 속성값 앞에 문자열 icon 이 붙은 icon type 의 subView 객체
 */
const iconTypeAddClass = (obj) => {
	const newObj = Object.assign({}, obj);
	if (newObj.hasOwnProperty(csvConst.type) && newObj[csvConst.type] === csvConst.icon) {
		let iconClass;
		if (newObj["data.class"] === '' || newObj["data.class"] === 0) {
			iconClass = `${csvConst.icon}`;
		} else {
			iconClass = `${csvConst.icon}`.concat(` `).concat(`${newObj["data.class"]}`);
		}
		newObj[csvConst.dataClass] = iconClass;
	}
	return newObj;
};

/**
 * 객체에 값이 없는 경우 기본값을 지정해주고, Redux 객체 구조와 CSV 속성값을 가진 객체를 반환 하는 함수
 * @param {array} changedObjData - import 받은 CSV 객체의 속성 값을 리덕스의 속성 이름과 속성 값으로 되돌린 객체들의 배열
 * @param {string} subject - 현재 import 를 하려는 대상 교과서 이름
 * @returns {array} - redux 객체 구조를 가지고 CSV 입력값을 속성 값으로 갖거나 값이 없으면 기본값이 지정된 객체들의 배열
 */
export const getDefaultSetModelObject = (changedObjData, subject) => {
	return changedObjData.map(obj => {
		const idSetObj = idDefaultSetting(obj); // id, originId 기본값 지정
		const cssSetObj = cssDefaultSetting(idSetObj); // width, height, opacity, left, top 기본값 지정
		const defaultObj = csvAddDefaultSetting(cssSetObj, subject); // 객체의 type 에 따른 기본 값 지정
		return getModelObject(defaultObj);
	});
};

/**
 * 기본값이 지정된 flat 객체를 받아서 각 type/flag 에 따라서
 * 원상태의 depth 가진 객체로 되돌려서 반환하는 함수
 * @param {object} obj - 객체의 type 에 따른 기본값이 지정된 flat 한 객체
 * @returns {object} - redux 에 넣을 수 있는 속성 구조를 가진 객체
 */
const getModelObject = (obj) => {
	if (obj.type === 'subView') {
		const subViewModel = (obj.flag === 1) ? getAddSubViewModel() : getUpdateSubViewModel();
		return getSubViewObject(subViewModel, obj);
	} else if (obj.type === 'icon') {
		const iconModel = (obj.flag === 1) ? getAddIconModel() : getUpdateIconModel();
		const iconModelObject = getSubViewObject(iconModel, obj);
		iconModelObject.type = 'subView'; // icon 타입을 subView 로 변경
		return iconModelObject;
	} else if (obj.type === 'meaning') {
		const meaningModel = getImportMeaningModel();
		return getSubViewObject(meaningModel, obj);
	} else if (obj.type === 'pageLink') {
		const pageLinkModel = getImportPageLinkModel();
		return getSubViewObject(pageLinkModel, obj);
	} else if (obj.type === 'redLine') {
		const redLineModel = getImportRedLineModel();
		return getSubViewObject(redLineModel, obj);
	} else if (obj.type === 'mask') {
		const maskModel = getImportMaskModel();
		return getSubViewObject(maskModel, obj);
	} else if (obj.type === 'block') {
		const blockModel = getImportBlockModel();
		return getSubViewObject(blockModel, obj);
	} else if (obj.type === 'postIt') {
		const postItModel = getImportPostItModel();
		return getSubViewObject(postItModel, obj);
	}
};


/**
 * redux 에 반영할 객체를 만들기 위해 Model 에 CSV 객체의 값을 반영한 객체를 반환하는 함수
 * @param {object} model - redux 에 반영할 객체 구조를 가진 모델
 * @param {object} object - redux 에 반영할 객체 값을 가진 flat 한 객체
 * @returns {object} - model 의 속성 구조에 , object 의 속성 값을 가진 객체
 */
const getSubViewObject = (model, object) => {
	for (const [prop, value] of Object.entries(object)) {
		if (prop === 'originId' && checkNested(model, 'originId')) {
			model.originId = value;
		} else if (prop === 'id' && checkNested(model, 'id')) {
			model.id = value;
		} else if (prop === 'type' && checkNested(model, 'type')) {
			model.type = value;
		} else if (prop === 'page' && checkNested(model, 'page')) {
			model.page = value;
		} else if (prop === 'flag' && checkNested(model, 'flag')) {
			model.flag = value;
		} else if (prop === 'css.height' && checkNested(model, 'css.height')) {
			model.css.height = value;
		} else if (prop === 'css.width' && checkNested(model, 'css.width')) {
			model.css.width = value;
		} else if (prop === 'css.left' && checkNested(model, 'css.left')) {
			model.css.left = value;
		} else if (prop === 'css.top' && checkNested(model, 'css.top')) {
			model.css.top = value;
		} else if (prop === 'css.opacity' && checkNested(model, 'css.opacity')) {
			model.css.opacity = value;
		} else if (prop === 'data.page' && checkNested(model, 'data.page')) {
			if (object.type === 'pageLink') model.data.page = value;
		} else if (prop === 'data.class' && checkNested(model, 'data.class')) {
			model.data.class = (value === "") ? model.data.class : value;
		} else if (prop === 'data.type' && checkNested(model, 'data.type')) {
			model.data.type = value;
		} else if (prop === 'data.kakezu' && checkNested(model, 'data.type')) {
			model.data.kakezu = value + "";
		} else if (prop === 'data.title' && checkNested(model, 'data.title')) {
			model.data.title = value;
		} else if (prop === 'options.link.level' && checkNested(model, 'options.link.level')) {
			model.options.link.level = value;
		} else if (prop === 'options.link.zIndex' && checkNested(model, 'options.link.zIndex')) {
			model.options.link.zIndex = value;
		} else if (prop === 'options.iFrame' && checkNested(model, 'options.iFrame')) {
			model.options.iFrame = value;
		}
	}
	return model;
};

/**
 * subview 객체의 type 에 따라서 default 속성 값을 지정한 객체를 반환하는 함수
 * @param {object} cssSetObj - subView 객체
 * @returns {object} - 객체의 type 별로 속성의 기본값이 지정된 객체
 */
const csvAddDefaultSetting = (cssSetObj) => {
	const setObj = Object.assign({}, cssSetObj);
	if (setObj['css.height'] === "") setObj['css.height'] = 100;
	if (setObj['css.width'] === "") setObj['css.width'] = 100;
	if (setObj['css.top'] === "") setObj['css.top'] = 0;
	if (setObj['css.left'] === "") setObj['css.left'] = 0;

	if (setObj.type === 'subView') {
		// if (setObj['css.opacity'] === "") setObj['css.opacity'] = 0.5;
		if (setObj['options.iFrame'] === "") setObj['options.iFrame'] = false;
		if (setObj['options.link.level'] === "") setObj['options.link.level'] = 2;
		if (setObj['options.link.zIndex'] === "") setObj['options.link.zIndex'] = 1;
	} else if (setObj.type === 'icon') {
		if (setObj['options.iFrame'] === "") setObj['options.iFrame'] = true;
		if (setObj['options.link.level'] === "") setObj['options.link.level'] = 3;
		if (setObj['options.link.zIndex'] === "") setObj['options.link.zIndex'] = 1;
		if (setObj['data.class'] === 'icon') {
			setObj['data.class'] = setObj['data.class'].concat(' ').concat(defaultIcon.className);
		}
		if (setObj['css.height'] === "" || setObj['css.height'] === 100) setObj['css.height'] = defaultIcon.height;
		if (setObj['css.width'] === "" || setObj['css.width'] === 100) setObj['css.width'] = defaultIcon.width;
	} else if (setObj.type === 'meaning') {
		// if (setObj['css.opacity'] === "") setObj['css.opacity'] = 0.5;
		if (setObj['options.link.level'] === "") setObj['options.link.level'] = 1;
	} else if (setObj.type === 'pageLink') {
		if (setObj[csvConst.data] === "") setObj[csvConst.data] = 1;
		if (setObj['options.link.level'] === "") setObj['options.link.level'] = 2;
	} else if (setObj.type === 'redLine') {
		if (setObj['options.link.level'] === "") setObj['options.link.level'] = 1;
	} else if (setObj.type === 'mask') {
		if (setObj['options.link.level'] === "") setObj['options.link.level'] = 3;
	} else if (setObj.type === 'block') {
		if (setObj['options.link.level'] === "") setObj['options.link.level'] = 1;
	} else if (setObj.type === 'postIt') {
		if (setObj['options.link.level'] === "") setObj['options.link.level'] = 0;
	}
	return setObj;
};

/**
 * css height, left, opacity, top, width 값이 지정되지 않은 경우 기본값을 넣어주는 함수
 * @param {object} idSetObj - import 시 flag 1인 Subview 객체
 * @returns {object} - 기본값이 지정된 subView 객체
 */
const cssDefaultSetting = (idSetObj) => {
	const newObj = Object.assign(idSetObj);
	if (newObj.hasOwnProperty(csvConst.css)) {
		const cssProp = newObj[csvConst.css];
		if (cssProp[csvConst.height] === "" || cssProp[csvConst.height] === 0) cssProp[csvConst.height] = defaultTextCss.SUBVIEW_HEIGHT;
		if (cssProp[csvConst.left] === "" || cssProp[csvConst.left] === 0) cssProp[csvConst.left] = 0;
		// if (cssProp[csvConst.opacity] === "" || cssProp[csvConst.opacity] === 0) cssProp[csvConst.opacity] = defaultTextCss.SUBVIEW_OPACITY;
		if (cssProp[csvConst.top] === "" || cssProp[csvConst.top] === 0) cssProp[csvConst.top] = 0;
		if (cssProp[csvConst.width] === "" || cssProp[csvConst.width] === 0) cssProp[csvConst.width] = defaultTextCss.SUBVIEW_WIDTH;
	}
	return newObj;
};


/**
 * originId가 빈값일 경우 default 값을 대입한 객체를 반환하는 함수
 * @param {object} csvObj - import 시 flag 1인 Subview 객체
 * @returns {object} - id 속성과 originId 속성의 값이 default 생성된 객체
 */
const idDefaultSetting = (csvObj) => {
	const newObj = Object.assign({}, csvObj);
	const idNumber = randomStr(8);
	const id = `${csvObj.page}-${idNumber}`;
	const originId = `${csvObj.page}-${idNumber}`;
	if (newObj[csvConst.id] === "" || newObj[csvConst.id] === 0) newObj[csvConst.id] = id;
	if (newObj[csvConst.originId] === "" || newObj[csvConst.originId] === 0) newObj[csvConst.originId] = originId;
	return newObj;
};

/**
 * subView 객체들의 속성을 변경하기 위한 함수
 * @param subViewData - redux 내에서 subView 객체들이 담긴 배열
 * ['subView', 'myEditor', 'icon', 'meaning', 'pageLink', 'redLine', 'mask', 'block', 'postIt'] 등의 객체들이 담긴 배열
 * @returns {array} - 속성이 변경된 subView 객체들의 배열
 */
export const propertyChange = (subViewData) => {
	return subViewData.map(obj => {
		const newObj = Object.assign({}, obj);
		const iconTypeObj = iconLogicalTypeAssertion(newObj); // subView type 에 속해있는 icon 을 icon Type 으로 재분류
		const flatObj = flatObject(iconTypeObj);    // 객체 속성 flat 하게 변경
		const optionFrameObj = optionsIFrameChange(flatObj); // HTML 콘텐츠 TRUE / FALSE 를 T / S 로 돌리는 작업
		const dataTypeObj = dataTypeSetting(optionFrameObj); // data.type 속성의 값을 지정해주는 작업
		return lineRedBlueChange(dataTypeObj); // meaning 의 적선 청선 속성값 한자로 돌리는 작업
	});
};

/**
 * class 에 pdf 가 있는 경우, data.type 을 pdf 로 options.IFrame 이 Y 인 경우 data.type 이 contents 로 지정하는 함수
 * @param {object} obj - subView 객체
 * @returns {object} icon 중에서 class 가 pdf 인 경우 data.type 이 pdf,
 * options.iFrame 이 Y인 경우 data.type 이 contents 인 객체
 */
const dataTypeSetting = (obj) => {
	if (checkNested(obj, 'options.iFrame')) {
		const isContents = (obj['options.iFrame'] === "Y");
		if (isContents) obj['data.type'] = "contents";
	}
	if (checkNested(obj, 'data.class')) {
		const isPdf = obj['data.class'].includes('pdf');
		if (isPdf) obj['data.type'] = "pdf";
	}
	return obj;
};


/**
 * subView 객체 중에서 icon 의 경우 현재 type 이 subView 이기에 icon 으로 변경해주는 함수
 * @param {object} obj - subView 객체
 * @returns {object} - subView 객체 ( icon 의 경우 type 속성의 값이 subView 에서 icon 으로 변경된 객체 )
 */
const iconLogicalTypeAssertion = (obj) => {
	if (obj.type === 'subView' && checkNested(obj, 'data.class')) {
		const classValue = obj.data.class; // data.class 속성 教科書別(アイコン種類）에 icon 이 있으면
		const iconClass = classValue.split(' ');
		const isIcon = iconClass.includes('icon');
		if (isIcon) { // class 에 icon 이 있으면
			obj.type = 'icon'; // subView 에 속하지만 type 을 icon 으로 바꿔준다.
			obj.data.class = iconClass.filter(cls => cls !== 'icon').join(' '); // class 에서 icon 제거
		}
		return obj;
	} else {
		return obj;
	}
};

/**
 * options.iFrame 속성을 가진 객체의 경우 , 속성값 true 를 Y 로 false 를 N 로 변경해주는 함수
 * @param {object} obj - subView 객체
 * @returns {object} - options.iFrame 속성의 값이 Y/N 으로 변경된 객체
 */
const optionsIFrameChange = (obj) => {
	if (obj.hasOwnProperty('options.iFrame')) {
		obj['options.iFrame'] = (obj['options.iFrame']) ? "Y" : "N";
		return obj;
	} else {
		return obj;
	}
};

/**
 * type 이 meaning 인 경우 data.class 속성 값에 따라서 meaning-red-top 같은 긴 이름을 上 처럼 짧고 알기 쉽게 바꿔주고
 * meaningBlue 意味段落青線, meaningRed 意味段落赤線 컬럼으로 값을 넣어주고 data.class 값을 초기화하는 함수
 * @param {object} obj - subView 객체
 * @returns {object} - 길고 복잡한 속성 이름을 유저가 보기 편하게 바꾼 meaning 객체
 *
 */
const lineRedBlueChange = (obj) => {
	if(obj.type === "meaning" && obj.hasOwnProperty('data.class')) {
		const classValue = obj['data.class'];
		const blueRange = Object.values(meaningBlueKey);
		const redRange = Object.values(meaningRedKey);
		const blueClass = [];
		const redClass = [];
		const lineClassArr = classValue.split(' ').filter(cls => cls !== "");
		for (const cls  of lineClassArr) {
			if(blueRange.some(e => e === cls)) blueClass.push(meaningKeyName[cls]);
			if(redRange.some(e => e === cls)) redClass.push(meaningKeyName[cls]);
		}
		obj.meaningBlue = blueClass.join('');
		obj.meaningRed = redClass.join('');
		obj['data.class'] = "";
		return obj;
	} else {
		return obj;
	}
};

/**
 * 입력 받은 컬럼과 기준으로 잡은 column 배열을 비교해서 서로 다르면 에러를 던지는 함수
 * @param {array} csvUserColumns - 사용자로 부터 import 받은 CSV 파일의 컬럼 이름이 담긴 배열
 */
export const subViewColumnErrChecker = (csvUserColumns) => {
	const subViewColumn = [
		'元ID', 'ID', '表示モード', 'ページ番号', 'サイズ(縦)', 'サイズ(横)', 'X座標', 'Y座標', '透明度',
		'リンク先ページ', 'レイヤー番号', 'レイヤー優先度', '意味段落赤線', '意味段落青線', '教科書別(アイコン種類）',
		'HTMLコンテンツ', 'イベント', '掛図モード順番', 'タイトル', '編集'
	];
	if (csvUserColumns.toString() !== subViewColumn.toString()) {
		throw new Error(makeErrorMsg('unmatchedColumn', csvModalMsg.subView));
	}
};

/**
 * 사용자 편의를 위해 export 시 바꿔놨던 속성명, 속성값들을 , 리덕스의 속성명, 속성값으로 되돌리는 함수
 * @param {array} devColumnData - CSV 로 받은 객체의 속성명을 영어로 되돌린 객체들의 배열
 * @returns {array} - 속성이름과 속성값이 redux 에 넣을 수 있게 바뀐 객체들의 배열
 */
export const importDataParse = (devColumnData) => {
	return devColumnData.map(obj => {
		const iconObj = iconTypeAddClass(obj); // 아이콘 class
		const iFrameObj = optionsIframeParse(iconObj); // HTMLコンテンツ
		const cssPositionObj = addProperty(iFrameObj); // absolute 속성 고정값 주기
		const meaningObj = meaningClassChange(cssPositionObj); // meaning 클래스 원래 형태로 되돌림
		return propValueParsing(meaningObj); // 객체의 모든 문자열 속성값을 본연의 타입으로 파싱
	});
};

/**
 * meaning 객체에 임의로 만든 meaningRed, meaningBlue 의 속성 값을 합쳐서
 * data.class 속성으로 다시 넣어주고 불필요한 meaningRed, meaningBlue 속성을 제거한 객체를 돌려주는 함수
 * @param {object} cssPositionObj - subView 객체
 * @returns {object} meaning 객체의 경우 , data.class 속성을 원래대로 되돌린 객체를 반환
 */
const meaningClassChange = (cssPositionObj) => {
	if (cssPositionObj.type === 'meaning') {
		const dataClassArr = [];
		createMeaningClass(cssPositionObj, 'meaningRed', dataClassArr);
		createMeaningClass(cssPositionObj, 'meaningBlue', dataClassArr);
		cssPositionObj['data.class'] = Array.from(new Set(dataClassArr)).join(' '); // 상하좌우 1개씩 만 입력받게 변경
		delete cssPositionObj.meaningBlue;
		delete cssPositionObj.meaningRed;
		return cssPositionObj;
	} else {
		return cssPositionObj;
	}
};

/**
 * meaning 객체의 meaningRed, meaningBlue 속성에서 값을 추출해서 data.class 속성으로 넣어주기 위한 함수
 * @param {object} cssPositionObj - type 이 meaning 인 객체
 * @param {string} prop - 객체에 접근할 속성 이름, 가져올 상수 객체를 정하는 플래그
 * @param {array} dataClassArr - meaning 객체의 data.class 속성으로 다시 넣어줄 값들을 가진 배열
 */
const createMeaningClass = (cssPositionObj, prop, dataClassArr) => {
	const targetKey = (prop === 'meaningRed') ? meaningRedKey : meaningBlueKey;
	const targetClass = cssPositionObj[prop];
	for (const red of targetClass) {
		dataClassArr.push(targetKey[red]);
	}
};

/**
 * flag1 에 기본값 설정 이전에 CSV 입력값이 있는 경우 유효성 검사
 * @param {array} arr - 속성값을 파싱한 객체들의 배열
 * @param {number} commentLength - 주석의 길이
 * @param {object} metaData - clone 된 meta 정보가 담긴 객체
 */
export const subViewCheckerByFlag = (arr, commentLength, metaData) => {
	const dupleCheckObj = { originId: [] };
	for (const obj of arr) {
		const idx = getNumberLine(arr, obj, commentLength);
		if (obj.flag === 1) {
			inputValueChecker(obj, idx, metaData); // CSV 입력값 검사
		} else if (obj.flag === 2) {
			inputDuplicateCheck(obj, idx, dupleCheckObj); // originId 중복 입력 방지
			idNullCheck(obj, idx, 'id', svColumn); // 수정시 ID가 없으면 에러
			inputValueChecker(obj, idx, metaData); // CSV 입력값 검사
		} else {} // 3
	}
};

/**
 * flag, type 등에 따라서 입력값에 대한 검사를 하는 함수
 * @param {object} obj - subView csv import 시에 들어올 객체 정보
 * @param {number} idx - CSV 파일에서 현재 검사 객체가 위치한 라인 넘버
 * @param {object} metaData - clone 된 meta 정보가 담긴 객체
 */
const inputValueChecker = (obj, idx, metaData) => {
	const { view, direction } = metaData;
	cssInfoCheck(obj, idx, view, direction); // height, width, left, top, opacity 검사
	// subViewPageLinkChecker(obj, idx, totalPages, svColumn); // pageLink 의 リンク先ページ 검사(상,하 이동 pageLink엔 page가 없으므로 체크안하도록 변경)
	// iconClassChecker(obj, idx, subject, svColumn); // icon 의 class 검사(19/11/27수정 - 아이콘 class가 변경되서 우선 체크안함)
	iFrameContentCheck(obj, idx); // iFrame [true/false] 체크
	zIndexCheck(obj,idx); // レイヤー優先度
	// optionLinkLevelCheck(obj, idx); // レイヤー番号 [0,1,2,3](없으면 기본값 넣어주니까 체크 안함)
	// dataTypeChecker(obj, idx);// イベント ..contents, pdf 만 입력 받음(type 속성이 증가함에 따라 우선 체크 안함(link 라던가...))
	meaningClassChecker(obj, idx) // meaning Class 적선 청선 검사
};

/**
 * csv 意味段落赤線	意味段落青線 값이 올바른지 검사하는 함수
 * @param {object} obj - subView csv import 시에 들어올 객체 정보
 * @param {number} idx - CSV 파일에서 현재 검사 객체가 위치한 라인 넘버
 */
const meaningClassChecker = (obj, idx) => {
	const meaningClassRange = Object.keys(meaningKeyName);
	if (obj.type === 'meaning') {
		const meaningClass = obj['data.class'];
		if (meaningClass !== "") { // 값이 있는 경우에는
			const meaningClassArr = meaningClass.split(' ');
			for (const cls of meaningClassArr) {
				const match = meaningClassRange.some(range => range === cls);
				if (!match) {
					throw new Error(makeErrorMsg('incorrect', idx, svColumn['data.class']));
				}
			}
		}
	}
};

/**
 * obj 의 height, width, left, top, opacity 속성에 대한 검사를 하는 함수
 * @param {object} obj - type 이 audio 인 검사 대상 객체
 * @param {number} idx - csv 상에서 obj 가 위치한 라인 넘버
 * @param {object} view - this.props.metaData.view  ex) { width: 952, height: 674 }
 * @param {string} direction - [rtl | ltr] 책의 읽는 방향
 */
const cssInfoCheck = (obj, idx, view, direction) => {
	const { width, height } = view;
	/*if (obj.type === 'subView' || obj.type === "meaning") { // subView, meaning 인 경우에만 투명도 체크
		overRangeDetailCheck(obj, 'css.opacity', idx, 0, 1, svColumn); // opacity check ( 투명도 check )
	}*/
	overRangeDetailCheck(obj, 'css.height', idx, 0, height, svColumn); // height check ( 높이 check )
	overRangeDetailCheck(obj, 'css.width', idx, 0, width, svColumn); // width check ( 너비 check )
	leftInfoCheck(obj, idx, width, direction, svColumn, 100); // left check ( x check )
	overRangeDetailCheck(obj, 'css.top', idx, 0, (height - obj['css.height']), svColumn); // height check ( y check )
};

/**
 * subView 랑 icon 일 때  options.iFrame 속성의 값이 true 또는 false가 아닌 경우 에러를 던지는 함수
 * @param {object} csvObj - import 대상 객체
 * @param {number} idx - csv 상에서 import 할 대상 객체의 라인 넘버수
 */
const iFrameContentCheck = (csvObj, idx) => {
	const targetArr = ['subView', 'icon'];
	for (const target of targetArr) {
		if (csvObj.type === target) {
			const value = csvObj['options.iFrame']; // HTMLコンテンツ
			const match = [true, false].some(e => e === value); // true 또는 false 둘중 하나여야 한다.
			if (!match) { // true 도 false 도 아니라면
				throw new Error(makeErrorMsg('incorrect', idx, svColumn['options.iFrame']));
			}
		}
	}
};

/**
 * csvObj 객체의 レイヤー優先度 options.link.zIndex 속성이 숫자가 아니면 에러를 던지는 함수
 * @param {object} csvObj - 에러 검사를 진행할 대상 객체
 * @param {number} idx - csv 상에서 객체의 라인 넘버수
 */
const zIndexCheck = (csvObj, idx) => {
	const targetArr = ['subView', 'icon']; // subView, icon 만
	for (const target of targetArr) {
		if (csvObj.type === target) {
			const value = csvObj['options.link.zIndex'];
			if (value === "") { // 값이 없으면
				// if(csvObj.flag === 2) throw new Error(makeErrorMsg('requiredUpdate', idx, svColumn['options.link.zIndex']));
			} else {
				if (typeof value !== 'number') { // 숫자가 아니면 에러
					throw new Error(makeErrorMsg('incorrect', idx, svColumn['options.link.zIndex']));
				}
			}
		}
	}
};

/**
 * 각 객체의 flag 값에 따라서 리덕스에 반영하는 cloneData 에 접근해서 객체를 추가/수정/삭제하는 함수
 * @param {array} subViewArr - CSV 속성 값과 , Redux 속성 구조를 가진 객체들의 배열
 * @param {object} clonedData - redux 에 적용할 가상의 리덕스 데이터
 * @param {number} commentLength - import 받은 CSV 파일의 주석길이
 * @param {object} allObjectArr - 모든 리덕스 객체들이 들어있는 배열
 * @param {object} typeChainObject - objectId에 해당하는 객체의 type 이 무엇인지 담고 있는 객체
 */
export const subViewImporting = (subViewArr, clonedData, commentLength, allObjectArr, typeChainObject) => {
	for (const obj of subViewArr) {
		const idx = getNumberLine(subViewArr, obj, commentLength);
		if (obj.flag === 1) {
			addSubViewObject(clonedData, obj); // 추가
		} else if (obj.flag === 2) {
			updateSubViewObject(clonedData, obj, allObjectArr, typeChainObject); // 수정
		} else { // 3
			deleteSubViewObject(clonedData, obj, idx, allObjectArr, typeChainObject); // 삭제
		}
	}
};


/**
 * import csv 의 값 검사가 끝나고 데이터가 신뢰할 수 있을 때, clonedData 에
 * 기존에 존재하는 originId 에 해당하는 객체의 정보를 변경하는 함수
 * @param {object} clonedData - redux 에 적용할 가상의 리덕스 데이터
 * @param {object} obj - redux 에 넣기 알맞게 가공된 csv 의 입력값과 redux 의 구조를 가진 객체
 * @param {object} allObjectArr - 모든 리덕스 객체들이 들어있는 배열
 * @param {object} typeChainObject - prevStateObj 의 originId 를 키로 삼아 밸류값에 type 을 저장해둔 객체
 */
const updateSubViewObject = (clonedData, obj, allObjectArr, typeChainObject) => {
	const { page , type , originId} = obj;
	const prevType = typeChainObject[obj.originId];
	// csv 로 입력받은 originId 로 리덕스에서 변경하기 전의 객체를 찾아옴
	// 변경하기전 객체의 정보로 clonedData 에 접근해서 해당 객체를 제거함
	const prevStateObj = allObjectArr.find(reduxObj => reduxObj.originId === originId);
	const prevStateArr = clonedData[prevStateObj.page]['objects'][prevType];
	clonedData[prevStateObj.page]['objects'][prevType] = prevStateArr.filter(obj => obj.originId !== prevStateObj.originId);
	const addObject = merge(prevStateObj, obj); // 이전 상태에 csv 로 입력받은 객체를 덮어씌움
	delete addObject.type;
	delete addObject.flag;
	if (addObject.data && addObject.data.hasOwnProperty("kakezu") && addObject.data.kakezu === "") {
		// CSV에서 카케즈모드번호 설정한게 없으면 제거(빈값이라도 있으면 체크박스가 체크되어있기 때문에)
		delete addObject.data.kakezu;
	}
	if (addObject.data && addObject.data.hasOwnProperty("title") && addObject.data.title === "") {
		delete addObject.data.title;
	}
	if (obj.css && obj.css.opacity === "") delete obj.css.opacity;
	if (!clonedData[page]['objects'][type]) clonedData[page]['objects'][type] = []; // 객체 배열 없으면 생성
	clonedData[page]['objects'][type].push(addObject); // 추가
};


/**
 * import csv 의 값 검사가 끝나고 데이터가 신뢰할 수 있을 때, clonedData 에 csv 객체의 값을 반영하여 추가하는 함수
 * @param {object} clonedData - redux 에 적용할 가상의 리덕스 데이터
 * @param {object} obj - redux 에 넣기 알맞게 가공된 csv 의 입력값과 redux 의 구조를 가진 객체
 */
const addSubViewObject = (clonedData, obj) => {
	const { type, page } = obj; // 사용자가 import 한 정보
	delete obj.type;
	delete obj.flag;
	if (obj.data && obj.data.hasOwnProperty("kakezu") && obj.data.kakezu === "") {
		// CSV에서 카케즈모드번호 설정한게 없으면 제거(빈값이라도 있으면 체크박스가 체크되어있기 때문에)
		delete obj.data.kakezu;
	}
	if (obj.data && obj.data.hasOwnProperty("title") && obj.data.title === "") {
		delete obj.data.title;
	}
	if (obj.css && obj.css.opacity === "") delete obj.css.opacity;
	if (!clonedData[page]['objects'][type]) clonedData[page]['objects'][type] = []; // 객체 배열 없으면 생성
	clonedData[page]['objects'][type].push(obj); // 추가
} ;

/**
 * csv 編集(flag)가 3인 경우 객체를 제거하기 위해 clonedData 사용자가 에게 입력받은 page, type, originId를 이용하여
 * 객체를 빠르게 찾고 가상 리덕스 데이터에서 객체를 제거하는 함수
 * @param {object} clonedData - redux 에 적용할 가상의 리덕스 데이터
 * @param {object} obj - redux 에 넣기 알맞게 가공된 csv 의 입력값과 redux 의 구조를 가진 객체
 * @param {number} idx - CSV 파일 에서 obj 객체 정보가 적힌 row 의 라인넘버
 * @param {object} allObjectArr - 모든 리덕스 객체들이 들어있는 배열
 * @param {object} typeChainObject - prevStateObj 의 originId 를 키로 삼아 밸류값에 type 을 저장해둔 객체
 */
const deleteSubViewObject = (clonedData, obj, idx, allObjectArr, typeChainObject) => {
	const prevType = typeChainObject[obj.originId];
	const prevStateObj = allObjectArr.find(reduxObj => reduxObj.originId === obj.originId);
	const prevStateArr = clonedData[prevStateObj.page]['objects'][prevType];
	clonedData[prevStateObj.page]['objects'][prevType] = prevStateArr.filter(obj => obj.originId !== prevStateObj.originId);
};