import React, { Component } from 'react';

class TypeSelect extends Component {
	makeOption = () => (
		Object.keys(this.props.typeInfo).map((key) => (
			<option value={key} key={key}>{this.props.typeInfo[key]}</option>
		))
	);

	render() {
		return (
			<div className="type-select-area">
				<div className="type-select-title-name">タイプ</div>
				<div className="type-select-select-area">
					<select className="type-select-select-tag" onChange={this.props.onChangeType}>
						{this.makeOption()}
					</select>
				</div>
			</div>
		)
	}
}

export default TypeSelect;