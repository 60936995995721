import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './InputWithLabel.scss';

class InputWithLabel extends Component {
	fileObj() {
		return (
			<div>
				<label htmlFor={this.props.name}>
					<div className={"btn_new_upload label-btn"}>
						アップロード
					</div>
				</label>
				<form method={"post"} encType={"multipart/form-data"}>
					<input
						className={"hide"}
						type={"file"}
						id={this.props.name}
						name={this.props.name}
						accept={".png"}
						onChange={this.props.onChange}
					/>
					<input type={"hidden"} name={this.props.pathName} value={this.props.path}/>
				</form>
			</div>
		)
	}

	numberObj() {
		return (<input
			type={"number"}
			onClick={this.props.onClick || null}
			onChange={this.props.onChange || null}
			name={this.props.name}
			step={this.props.step}
			min={this.props.min}
			max={this.props.max}
			defaultValue={this.props.defaultValue}
			readOnly={this.props.readOnly}
		/>);
	}

	textObj() {
		return (
			<input
				type={"text"}
				onClick={this.props.onClick}
				onChange={this.props.onChange || null}
				maxLength={this.props.max}
				name={this.props.name}
				defaultValue={this.props.defaultValue}
				readOnly={this.props.readOnly}
			/>
		)
	}

	getFormElem() {
		const objElem = {
			text: this.textObj.bind(this),
			number: this.numberObj.bind(this),
			file: this.fileObj.bind(this),
		};
		return (objElem[this.props.type]());
	}

	render() {
		return (
			<div className="input-form-with-label">
				<div className="label">{this.props.label}</div>
				<div className="input-form">
					{this.getFormElem()}
				</div>
			</div>
		)
	}
}

InputWithLabel.propTypes = {
	type: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	onChange: PropTypes.func,
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	name: PropTypes.string.isRequired,
	min: PropTypes.number,
	max: PropTypes.number,
	step: PropTypes.number,
	readOnly: PropTypes.bool,
	path: PropTypes.string,
	pathName: PropTypes.string,
};

InputWithLabel.defaultProps = {
	readOnly: false,
};

export default InputWithLabel;