import React, {Component} from 'react';
import './Finder.scss';

class Finder extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchText: ''
		};
	}

	onChange = (e) => {
		console.log(e.target.value);
		console.log(e.currentTarget.value);
		this.setState({
			searchText: e.target.value
		});
	};

	render() {
		return (
			<div className="finder-area">
				<div className="finder-search-input">
					<form className="finder-search-form"
					      onSubmit={this.props.onClickSubmit}>
						<label>
							<input
								className="input-finder"
								type="text"
								value={this.state.searchText}
								placeholder="IDを入力してください。"
								onChange={this.onChange}
							/>
						</label>
						<div className="btn_search">
							<input
							type="submit"
							value=""
							className="finder-search-icon"
						>
							</input>
						</div>
					</form>
				</div>
			</div>
		)
	}
}

export default Finder;