import React, {Component} from 'react';
import './Title.scss';

class Title extends Component {
	render() {
		return (
			<div className="title-area">
				<div className="title-name">{this.props.titleName}</div>
				<input type="button" className="title-x-btn icon_close" onClick={this.props.onclickX}/>
			</div>
		)
	}
}

export default Title;