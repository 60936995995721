import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";

import {
	TextProperty,
	SelectProperty,
	SelectImageProperty,
	NumberProperty,
} from "../../../../components/Contents/Editor/Panels/PropertyPanel/innerProperty";
import {
	changeBookData,
	changeRectId,
	changeRectPage,
	changeRectOptions,
} from '../../../../reducers/CombineReducers/pageData';
import {
	maskColorOptions,
	objectModeConst,
	propertyInputLabel,
	propertyInputName,
	propertyToolTip, subjectKeyName
} from '../../../../interface/constant';
import { setSelectItem } from "../../../../reducers/CombineReducers/current";
import PosSizeProperty from "../../../../components/Contents/Editor/Panels/PropertyPanel/posSizeProperty";

const mapStateToProps = (state) => {
	const objMode = objectModeConst[state.current.editor.objectMode];
	let {id, page} = state.current.editor.selected;
	let prop = {};
	if (id && page) {
		let objectInfo = state.pageData[page].objects[objMode];
		let item = objectInfo.filter((item) => item.originId === id)[0];
		prop = {
			item,
			css: item.css,
			pages: state.current.editor.pages,
			bookChanged: state.current.book.changed,
			view: state.metaData.view,
			direction: state.metaData.direction,
			id,
			page,
			objMode
		};
	}
	prop.subject = state.metaData.subject;
	return prop;
};

const mapDispatchToProps ={
	changeBookData,
	changeRectId,
	changeRectPage,
	setSelectItem,
	changeRectOptions,
};

let property = {
	currentId: "",
	changedId: "",
	page: 0,
	objMode: "",
};

class BlockTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: "",
			propertyCss: {
				width: "",
				height: "",
				top: "",
				left: "",
			},
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps === prevState.prevProps) return null;
		const propertyCss = BlockTable.arrangeCss(prevState.propertyCss, nextProps.item.css);
		return {propertyCss}
	}

	static arrangeCss(prevCss, nextCss) {
		return {
			...nextCss,
			width: prevCss.width === "" ? prevCss.width : nextCss.width,
			height: prevCss.height === "" ? prevCss.height : nextCss.height,
			top: prevCss.top === "" ? prevCss.top : nextCss.top,
			left: prevCss.left === "" ? prevCss.left : nextCss.left,
		}
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.props.item.id !== prevProps.item.id) {
			const id = this.props.item.id || "";
			const propertyCss = this.props.item.css;
			this.setState({
				id: id,
				propertyCss,
			})
		}
	}

	eventHandler() {
		return {
			onChange: (e) => {
				this.setState({
					...this.state,
					propertyCss: {
						...this.state.propertyCss,
						[e.target.name]: e.target.value,
					}
				});

				const value = e.target.value || 0;
				this.props.changeBookData({
					id: this.props.id,
					page: this.props.page,
					css: {
						[e.target.name]: (e.target.name !== 'opacity' ? parseInt(value, 10) : Number(value))
					},
					objMode: this.props.objMode
				});
			},
			pageChange: (e) => {
				let page = Number(e.target.value);
				this.props.changeRectPage({
					id: this.props.id,
					page: page,
					width: this.props.view.width / 2,
					objMode: this.props.objMode,
					direction: this.props.direction,
				});
				this.props.setSelectItem({
					id: this.props.id,
					page: page
				});
			},
			optionsChange: (e) => {
				let {page, objMode, item} = property;
				const itemOptions = Object.keys(this.props.item).length !== 0 ? this.props.item.options : item.options;
				let options = this.props.setOptionsValue(e.target, itemOptions);
				this.props.changeRectOptions({
					id: this.props.id,
					page: this.props.page || page,
					options: {
						[options.name]: options.value
					},
					objMode: this.props.objMode || objMode,
				});
			},
			colorTextChange: (e) => {
				let value = e.target.value ? e.target.value[0] !== "#" ? "#" + e.target.value : e.target.value : "";
				if (e.target.name === propertyInputName.blockColorText) value = value.toUpperCase();

				this.props.changeBookData({
					id: this.props.id,
					page: this.props.page,
					css: {
						[e.target.name]: value,
					},
					objMode: this.props.objMode
				});
			},
			colorSelect: (color) => {
				const value = color.value;

				this.props.changeBookData({
					id: this.props.id,
					page: this.props.page,
					css: {
						backgroundColor: value,
					},
					objMode: this.props.objMode
				});
			},
		}
	}

	setPropertyId(e) {
		property.currentId = this.props.id;
		property.changedId = e.target.value;
		property.page = this.props.page;
		property.objMode = this.props.objMode;
	}

	changeId(e) {
		this.setState({
			id: e.target.value
		});
		property.changedId = e.target.value;
	}

	changePageDataId() {
		let {currentId, changedId, page, objMode} = property;
		this.props.changeRectId({
			currentId: currentId,
			changedId: changedId,
			page: page,
			objMode: objMode
		});
	}

	setLabel(color) {
		if(color.value === "") {
			return (
				<div>
					<div className={"mask-color-select-contents"}>
						<span className={"mask-color-select-text"}>{color.name}</span>
					</div>
				</div>
			);
		}
		return (
			<div>
				<div className={"mask-color-select-contents"} style={{backgroundColor: color.value}}>
					<span className={"mask-color-select-text"}>{color.value}</span>
				</div>
			</div>
		)
	}

	colorEditElem() {
		if (this.props.subject === subjectKeyName.shakai) {
			const {item} = this.props;
			maskColorOptions.map((color) => {
				color.label = this.setLabel(color);
				return color;
			});

			const selectedColorValue = maskColorOptions.filter((icon) => icon.value === item.css.backgroundColor);
			const colorValue = selectedColorValue.length <= 0 ? maskColorOptions[0] : selectedColorValue;

			const colorCodeValue = item.css.backgroundColor || "";
			const colorCheck = /^#[0-9a-f]{3,6}$/i;	// 컬러코드 유효성 체크 정규식
			const isValidColor = colorCheck.test(colorCodeValue) ? colorCodeValue : "";

			return (
				<>
					<SelectImageProperty
						label={propertyInputLabel.blockColorSelect}
						name={propertyInputName.blockColorSelect}
						options={maskColorOptions}
						val={colorValue}
						eventHandler={this.eventHandler().colorSelect}
					/>
					<TextProperty
						label={propertyInputLabel.blockColorText}
						name={propertyInputName.blockColorText}
						val={colorCodeValue || ""}
						color={isValidColor || ""}
						onChange={this.eventHandler().colorTextChange}
					/>
				</>
			)
		}
	}

	render() {
		const {id, page, pages, item} = this.props;
		const pageInfoOptions = this.props.pages ? [
			{val: pages.left, name: pages.left},
			{val: pages.right, name: pages.right}
		] : [];

		return (

			<div className="inner-table">
				<table>
					<tbody>
					<TextProperty
						label={propertyInputLabel.id}
						name={propertyInputName.id} val={this.state.id}
						readOnly={false}
						onFocus={this.setPropertyId.bind(this)}
						onBlur={this.changePageDataId.bind(this)}
						onChange={this.changeId.bind(this)}
						tooltipText={propertyToolTip.id}
					/>
					<SelectProperty
						label={propertyInputLabel.page}
						name={propertyInputName.page} options={pageInfoOptions}
						eventHandler={this.eventHandler().pageChange} val={page}
						tooltipText={propertyToolTip.page}
					/>
					<NumberProperty
						label={propertyInputLabel.zIndex}
						name={propertyInputName.zIndex}
						val={item.options.link ? item.options.link.zIndex || "" : ""}
						onChange={this.eventHandler().optionsChange}
						tooltipText={propertyToolTip.zIndex}
					/>
					<PosSizeProperty css={this.state.propertyCss} eventHandler={this.eventHandler()}/>
					{this.colorEditElem()}
					</tbody>
				</table>
				<div className="inner-mask" style={{display: id ? "none" : ""}}/>
			</div>

		)
	}
}

BlockTable.defaultProps = {
	item: {
		originId: "",
		id: "",
		css: {},
		options: {},
		page: 0,
		data: {},
	},
	page: 0,
	id: ""
};

BlockTable.propTypes = {
	item: PropTypes.shape({
		originId: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		css: PropTypes.object.isRequired,
		options: PropTypes.object.isRequired,
		page: PropTypes.number.isRequired
	}).isRequired,
	page: PropTypes.number.isRequired,
	id: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockTable);