import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import "./Home.scss";

import BookList from "../../../components/Contents/Home/BookList";
import EditingBook from "../../../components/Contents/Home/EditingBook";
import CreateNewBook from "../../../components/Contents/Home/CreateNewBook";
import Modal from "../../../components/Common/Modal";
import HomeButtons from "../../../components/Contents/Home/HomeButtons";

import { setData } from "../../../interface/db";
import {
	getBookList,
	getGroupBookList,
	putNewBookInfo,
	deleteBook,
	imageUpload,
	bookInfo,
	makeBook,
	makeZip,
	clean,
	chkMakeBook,
	authorChange,
	getBookData,
	makeBookDB,
	getBookInfo,
} from "../../../interface/api";

import {otherMsg, modalTitle, modalMsg, componentTitle, directionName, loadingMsg} from "./../../../interface/constant";
import { setMetadata } from "../../../reducers/CombineReducers/metaData";
import { bookReady, bookNotReady, setPages, startLoading, endLoading } from "../../../reducers/CombineReducers/current";
import {removeFromRepo, modifyRepoPath, modifyRepoMulti} from "../../../reducers/CombineReducers/repo";
import { getStorageModel, getPageDataModel } from "../../../interface/dataModel";
import { downloadFile, unixTimeNow } from "../../../interface/utils";

const mapStateToProps = ({auth, current, metaData}) => ({auth, current, metaData});

const mapDispatchToProps = {
	setMetadata, bookReady, bookNotReady,
	setPages, setData, startLoading, endLoading,
	removeFromRepo, modifyRepoPath, modifyRepoMulti,
};

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: {},
			bookList: [],
			groupBookList: [],
			editingBook: [],
			editingBookList: null,
			author: null,
			showModalUpload: false,
			showModalCompress: false,
			showModalDiscard: false,
			showModalComplete: false,
			showModalNewBook: false,
			showModalDelete: false,
			showModalMaxBook: false,
			showModalFullEditing: false,
		};
		this.syncBookToServer = null;
		this.compressProcess= null;
		this.discardProcess = null;
		this.deleteProcess = null;

		this.eventSource = null;
		this.getBookInfo().then(() => this.sseEventStart());
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const prevCont = prevProps.current.contents;
		const currentCont = this.props.current.contents;
		if (prevCont !== "home" && currentCont === "home") this.sseEventStart();
		if (prevCont === "home" && currentCont !== "home") this.sseEventEnd();
		if (prevState.selected._id !== this.state.selected._id) {
			this.setState({
				author: null,
			});
		}
		if (!this.state.editingBookList) {
			this.setStateEditingBookList({});
			this.props.socket.getBookList(this.setStateEditingBookList.bind(this));

		}
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		return this.props.current.contents === "home" || nextProps.current.contents === "home" || nextState !== this.state;
	}

	sseEventStart() {
		console.log("SSE EVENT START");
		if (this.eventSource !== null) this.eventSource.close();
		this.eventSource = new EventSource('/sse/bookInfo');
		this.eventSource.onopen = () => console.log("SSE stream opened");
		this.eventSource.onmessage = e => {
			try {
				if(!e.data) {
					console.error(e);
					throw new Error('cannot get data');
				}
				let parsedData = JSON.parse(e.data);
				this.setListStatus(parsedData.data);
			} catch (e) {
			    console.log("JSON parse ERROR!");
				console.log(e);
			}
		}
	}

	sseEventEnd() {
		console.log("SSE EVENT END");
		this.eventSource.close();
	}

	getBookInfo = async () => {
		try {
			let myBook = await getBookList();
			let groupBook = await getGroupBookList();
			if(!myBook.data && !groupBook.data) {
				console.error(myBook);
				console.error(groupBook);
				throw new Error("cannot get book data");
			}
			this.setListStatus({
				myBook: myBook.data,
				groupBook: groupBook.data
			});
		} catch (e) {
			console.log("getBook ERROR!");
			console.log(e);
		}
	};

	setListStatus(data) {
		if(data.myBook && data.groupBook) {
			this.setState({
				...this.state,
				bookList: data.myBook,
				groupBookList: data.groupBook,
			})
		}
	};

	onClickNew() {
		if (this.state.editingBook.length > 0) return null;
		return async () => {
			let result = await chkMakeBook();
			let modalType = (result.allow) ? "NewBook" : "MaxBook";
			this.toggleModal(modalType, true);
		}
	}

	onClickRead() {
		if (!this.state.selected._id || this.state.editingBook.includes(this.state.selected)) return null;
		return this.openBook.bind(this);
	}

	onClickOpen() {
		if (!this.state.selected._id || !this.state.editingBook.includes(this.state.selected)) return null;
		return this.openBook.bind(this);
	}

	async openBook() {
		const selected = this.state.selected;
		if (Object.keys(selected).length !== 0) {
			const bookId = selected.bookId;
			let bookData = await getBookData(bookId);

			this.props.startLoading({msg: loadingMsg.READING});
			await this.setBookData(selected, bookId, bookData);
		}
	}

	async setBookData(selected, bookId, bookData) {
		const {editingBookList} = this.state;
		if (!editingBookList[bookId] || !editingBookList[bookId].find(item => item.id === this.props.auth.info.id)) {
			const {removeFromRepo, modifyRepoPath, modifyRepoMulti} = this.props;
			const lockPageData = bookData.data.pageData.filter(item => item.pageLockUser && (item.pageLockUser !== this.props.auth.info.id));
			const lockPageList = lockPageData.map(item => Number(item._id));

			this.props.socket.editStartBook(this.state.selected.bookId, this.props.auth.info.id, lockPageList);
			this.props.socket.setRepoSocket(this.state.selected.bookId, removeFromRepo, modifyRepoPath, modifyRepoMulti);
			setTimeout(() => this.setBookData(selected, bookId, bookData), 1000);
		} else {
			/*if (selected.author === this.props.auth.info.id && selected.status !== "waiting" && selected.status !== "converting") {
				await bookInfo(bookId, {
					status: "editing",
				}).then(this.getBookInfo);
			}*/

			const editingUserInfo = editingBookList[bookId].find(item => item.id === this.props.auth.info.id);
			if (!editingUserInfo || !editingUserInfo.hasOwnProperty("page")) {
				console.log("setBookData error");
				console.log(editingUserInfo);
				return;
			}

			if (bookData.data.pageData.length < editingUserInfo.page) {
				this.props.endLoading();
				this.toggleModal("FullEditing");
				return;
			}

			this.props.socket.setEditingPage(this.props.auth.info.id, editingUserInfo.page, bookId);

			this.props.setData(bookData.data);
			let pages = [editingUserInfo.page, editingUserInfo.page + 1];
			if (this.props.metaData.direction === directionName.rtl) pages = [editingUserInfo.page + 1, editingUserInfo.page];
			this.props.setPages({pages});
			this.props.bookReady();
			let result = await getBookInfo(bookData.data.metaData.bookId) || [];
			if (result.success && result.resultData) {
				this.setState({
					editingBook: [result.resultData]
				});
			}
			this.props.endLoading();
		}
	}

	async uploadBook(e) {
		e.stopPropagation();
		const bookId = (e.currentTarget.getAttribute("data-id"));
		if (!bookId) return;

		this.syncBookToServer = async () => {
			this.toggleModal("Upload", false);
			this.props.bookNotReady();

			await bookInfo(bookId, {status: "waiting", makeTime: unixTimeNow()});
			this.selectBook(true);
			this.getBookInfo();
			await makeBook(bookId);
			window.location.reload();
		};
		this.toggleModal("Upload", true);
	}

	onClickMakeZip() {
		if (!this.state.selected._id) return null;
		if (this.isBookInEditingList()) return null;
		if (this.state.selected.zipPath) return null;
		if (this.state.selected.status !== 'ready') return null;
		const bookId = (this.state.selected.bookId);
		if (!bookId) return;

		//console.log(this.compressProcess);
		return () => {
			this.compressProcess = async () => {
				this.toggleModal("Compress", false);
				await bookInfo(bookId, {status: "compressing"});
				this.selectBook(true);
				await makeZip(bookId);
				window.location.reload();
			};
			this.toggleModal("Compress", true);
		}
	}

	async changeCover(e) {
		e.stopPropagation();
		const bookId = (e.currentTarget.getAttribute("data-id"));
		if (!bookId) return;

		let form = e.target.parentElement;
		let file = e.target.files[0];
		e.target.value = "";
		let {path} = await imageUpload(form, file);

		let editingBook = this.state.editingBook.map((item) => {
			if (item._id === bookId) {
				item.coverPath = path;
			}
			return {...item};
		});
		this.setState({
			...this.state,
			editingBook,
		});
		await bookInfo(bookId, {
			coverPath: path,
		});
	}

	isBookInEditingList() {
		if (this.state.editingBook.length < 1) return false;
		return this.state.editingBook.find((book) => book._id === this.state.selected._id) !== null;
	}

	isLevelBetween(higher, less) {
		return (this.props.auth.info.level > less) && (this.props.auth.info.level < higher);
	}

	onClickDownload() {
		if (!this.state.selected._id) return null;
		if (this.isBookInEditingList()) return null;
		if (!this.state.selected.zipPath) return null;
		if (this.state.selected.status !== 'ready') return null;
		return () => downloadFile(this.state.selected.zipPath);
	}

	onClickDiscard() {
		const bookId = this.state.selected._id;
		if (!bookId) return null;
		if (!this.isBookInEditingList()) return null;

		return () => {
			const bookId = this.state.selected._id;
			if (!bookId) return;
			this.toggleModal("Discard", true);
			this.discardProcess = async () => {
				this.toggleModal("Discard", false);

				/*await bookInfo(bookId, {
					status: "ready",
				}).then(() => {window.location.reload()});*/

				window.location.reload();
			};
		}
	}

	onClickDelete() {
		const bookId = this.state.selected._id;
		if (!bookId) return null;
		if (this.state.selected.author !== this.props.auth.info.id) return;
		if (this.isBookInEditingList()) return null;
		if (this.isLevelBetween(8, 10)) return null;

		return () => {
			this.toggleModal("Delete", true);
			this.deleteProcess = async () => {
				this.toggleModal("Delete", false);
				await deleteBook(bookId).then(this.getBookInfo);
				this.selectBook(true);
			};
		}
	}

	onClickMake() {
		const bookId = this.state.selected._id;
		if (!bookId) return null;
		if (this.isBookInEditingList()) return null;
		if (this.props.auth.info.level < 9) return null;

		return () => makeBook(bookId);
	}

	onClickPreview() {
		const bookId = this.state.selected._id;
		if (!bookId) return null;
		// if (this.isBookInEditingList()) return null;
		if (!this.state.selected.previewPath) return null;

		return () => {
			const url = this.state.selected.previewPath;
			window.open(url, '_blank', 'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,width=1920,height=1080')
		}
	}

	selectBookEvent(e) {
		const type = e.currentTarget.getAttribute("data-id");
		if (this.props.current.book.ready) return;

		const bookId = (e.currentTarget.id);
		const isSelected = ` ${e.currentTarget.className} `.indexOf(` selected `) > 0;
		const bookItem = this.state[type].find(book => book.bookId === bookId);
		this.selectBook(isSelected, bookItem)
	}

	selectBook(isSelected, item) {
		this.setState({
			...this.state,
			selected: isSelected ? {} : item
		})
	}

	toggleModal(type = "Upload", show = true) {
		let modalType = `showModal${type}`;
		this.setState({
			...this.state,
			[modalType]: show,
		});
		if (!show) {
			this.syncBookToServer = () => {};
			this.compressProcess= () => {};
			this.discardProcess = () => {};
			this.deleteProcess = () => {};
		}
	}

	async makeBook(meta) {
		this.toggleModal("NewBook", false);
		let info = {
			...meta,
			_id: meta.bookId,
			status: "editing",
		};

		let result = await putNewBookInfo(info);
		if (!result.success) return;

		let bulk = [meta, getStorageModel()];
		for (let page = 1; page < meta.totalPages + 1; page++) {
			result = bulk.push(getPageDataModel(`${page}`));
		}
		await makeBookDB(meta.bookId, bulk);
		this.getBookInfo();
		this.selectBook(false, meta);
	};

	static titleElem(title) {
		return (
			<div className={"border-bottom home-title"}>
				<span>{title}</span>
			</div>
		)
	}

	static noBookElem(title) {
		return (
			<div className={"book-component"}>
				{Home.titleElem(title)}
				<div className={"list-books"}>
					<span>{otherMsg.NO_BOOK_MSG}</span>
				</div>
			</div>
		);
	}

	editingBookElem(title) {
		if (this.state.editingBook.length <= 0) return Home.noBookElem(title);
		return (
			<div className={"book-component"}>
				{Home.titleElem(title)}
				<div className={"list-books"}>
					{this.state.editingBook.map(book => {
						const isMyBook = book.author === this.props.auth.info.id;
						const isMaking = book.status === "waiting" || book.status === "converting" || book.status === "compressing";
						return (
							<EditingBook
								info={book}
								key={book.bookId}
								selectedBookId={this.state.selected.bookId}
								selectBook={this.selectBookEvent.bind(this)}
								uploadBook={this.uploadBook.bind(this)}
								changeCover={this.changeCover.bind(this)}
								metaData={this.props.metaData}
								getBookDataAll={() => getBookData(book.bookId, [1,2])}
								isMyBook={isMyBook}
								isMaking={isMaking}
							/>
						)
					})}
				</div>
			</div>
		)
	}

	bookListElem(title) {
		if (this.state.bookList.length <= 0) return Home.noBookElem(title);
		const editingBookList = this.state.editingBookList || {};
		return (
			<div className={"book-component"}>
				{Home.titleElem(title)}
				<div className={"list-books"}>
					{this.state.bookList.map((item) => {
						return (
							<BookList
								item={item}
								key={item.bookId}
								selectedBookId={this.state.selected.bookId}
								selectBook={this.selectBookEvent.bind(this)}
								type={"bookList"}
								editingUser={editingBookList[item.bookId] || []}
							/>
						);
					})}
				</div>
			</div>
		)
	}

	groupBookListElem(title) {
		if (this.props.auth.info.level === 9) return (<div/>);
		if (this.state.groupBookList.length <= 0) return Home.noBookElem(title);
		const editingBookList = this.state.editingBookList || {};
		return (
			<div className={"book-component"}>
				{Home.titleElem(title)}
				<div className={"list-books"}>
					{this.state.groupBookList.map((item) => {
						return (
							<BookList
								item={item}
								key={item.bookId}
								selectedBookId={this.state.selected.bookId}
								selectBook={this.selectBookEvent.bind(this)}
								type={"groupBookList"}
								editingUser={editingBookList[item.bookId] || []}
							/>
						);
					})}
				</div>
			</div>
		)
	}

	onClickClean() {
		if (this.isLevelBetween(8, 0)) return null;
		return () => clean();
	}

	onClickAuthorChange() {
		const bookId = this.state.selected._id;
		if (!bookId) return null;
		if (this.isBookInEditingList()) return null;
		if (this.props.auth.info.level < 9) return null;

		if (this.state.author !== null) {
			return () => this.authorDBChange(bookId);
		} else {
			return () => {
				this.setState({
					author: this.state.selected.author || "",
				})
			};

		}
	}

	authorTextChange(e) {
		this.setState({
			author: e.target.value,
		})
	}

	async authorDBChange(bookId) {
		if (!this.state.author) return;
		this.props.startLoading({msg: loadingMsg.CHANGE});
		const response = await authorChange(bookId, this.state.author);
		this.setState({
			author: null,
		});
		if (response.success && response.permission) window.location.reload();
		else console.log(response);
	}

	setStateEditingBookList(editingBookList) {
		this.setState({
			editingBookList
		});
	}

	render() {
		return (
			<div id={"Home"} className={this.props.className}>
				<HomeButtons
					onClickNew={this.onClickNew()}
					onClickOpen={this.onClickOpen()}
					onClickRead={this.onClickRead()}
					onClickDelete={this.onClickDelete()}
					onClickDownload={this.onClickDownload()}
					onClickMakeZip={this.onClickMakeZip()}
					onClickDiscard={this.onClickDiscard()}
					onClickMake={this.onClickMake()}
					onClickPreview={this.onClickPreview()}
					onClickClean={this.onClickClean()}
					onClickAuthorChange={this.onClickAuthorChange()}
					authorTextChange={this.authorTextChange.bind(this)}
					author={this.state.author}
				/>
				<div className={"box-container"}>
					{this.editingBookElem(componentTitle.EDITING_BOOKS)}
					{this.bookListElem(componentTitle.BOOK_LISTS)}
					{this.groupBookListElem(componentTitle.GROUP_BOOK_LISTS)}
				</div>

				{this.state.showModalUpload && <Modal
					onClickCancel={() => this.toggleModal("Upload", false)}
					onClickConfirm={() => this.syncBookToServer()}
					msg={modalMsg.UPLOAD}
					title={modalTitle.ALERT}
				/>}
				{this.state.showModalCompress && <Modal
					onClickCancel={() => this.toggleModal("Compress", false)}
					onClickConfirm={() => this.compressProcess()}
					msg={modalMsg.COMPRESS}
					title={modalTitle.ALERT}
				/>}
				{this.state.showModalDiscard && <Modal
					onClickCancel={() => this.toggleModal("Discard", false)}
					onClickConfirm={() => this.discardProcess()}
					msg={modalMsg.DISCARD}
					title={modalTitle.WARNING}
				/>}
				{this.state.showModalDelete && <Modal
					onClickCancel={() => this.toggleModal("Delete", false)}
					onClickConfirm={() => this.deleteProcess()}
					msg={modalMsg.DELETE}
					title={modalTitle.WARNING}
				/>}
				{this.state.showModalNewBook && <CreateNewBook
					onClickX={() => this.toggleModal("NewBook", false)}
					makeBook={this.makeBook.bind(this)}
					author={this.props.auth.info.id}
				/>}
				{this.state.showModalMaxBook && <Modal
					onClickConfirm={() => this.toggleModal("MaxBook", false)}
					msg={modalMsg.MAX_BOOK}
					title={modalTitle.ERROR}
				/>}
				{this.state.showModalFullEditing && <Modal
					onClickConfirm={() => {window.location.reload()}}
					msg={modalMsg.FULL_EDITING}
					title={modalTitle.ERROR}
				/>}
			</div>
		)
	}
}

Home.propTypes = {
	auth: PropTypes.object,
	current: PropTypes.object,
	metaData: PropTypes.object,
	className: PropTypes.string,
	setMetadata: PropTypes.func,
	addPageAll: PropTypes.func,
	bookReady: PropTypes.func,
	uploadBook: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);