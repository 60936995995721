import {checkNested, flatObject, getNumberLine, makeErrorMsg} from "../../../interface/utils";
import {csvColumnsName, csvConst, csvModalMsg} from "../../../interface/csvConstant";
import {objectModeConst} from "../../../interface/constant";
import {checkPropsKeyExist, idSubString, inputDuplicateCheck} from "./csvUtils";

/**
 * clonedData 객체의 objects 객체의 subView 배열을 순회하면서
 * 각 객체가 options.iFrame 속성을 가지고 있고 속성값이 true 인
 * 객체들의 배열을 반환하는 함수
 * @param clonedData - this.props.pageData 딥클론한 데이터
 * @param isExport - true 면 export 할 때, false 면 import 할 때
 * @returns {Array} -  options.iFrame 속성값이 true 인 subView 객체들 (subView, icon, editor)의 배열
 */

export const getIframeData = (clonedData) => {
	const tempArr = [];
	for (const objects of Object.values(clonedData)) {
		const pageData = objects['objects'];
		const subViewArr = pageData['subView'];
		for (const obj of subViewArr) {
			if (checkNested(obj, 'options.iFrame') && obj.options.iFrame) {
				tempArr.push(obj);
			}
		}
	}
	return tempArr;
};

/**
 * iFrameArr 배열을 순회하면서 객체의 editor 속성을 제거한 객체를
 * flat 하게 만든 객체들의 배열을 리턴하는 함수
 * @param iFrameArr - iFrame 속성값이 true 인 객체들의 배열
 * @returns {*} - flat 한 속성이름을 가진 iFrame 객체들의 배열
 */
export const getDeleteFlatPropObj = (iFrameArr) => {
	return iFrameArr.map(obj => {
		if (obj.hasOwnProperty('editor')) delete obj.editor;
		return flatObject(obj);
	});
};

/**
 * csvColumns 컬럼과 함수 내부에서 지정해놓은 컬럼이 불일치 할 경우,
 * csv 칼럼이 올바르지 않다고 에러를 던지는 함수
 * @param csvColumns - import 된 csv 파일에서 읽어들인 컬럼
 */
export const importColumnMatchedCheck = (csvColumns) => {
	const importVideoColumn = ["元ID", "ID", "R-ID（HTMLコンテンツ)", "HTMLファイル名", "吹き出し", "サムネイルID",];
	if (csvColumns.toString() !== importVideoColumn.toString()) {
		throw new Error(makeErrorMsg('unmatchedColumn', csvModalMsg.html));
	}
};

/**
 * import 대상 subView 객체의 originId 에 해당하는 redux pageData 에 위치한 객체를 가져오는 함수
 * @param reduxPageData - pageData 를 deepClone 한 객체
 * @param reduxObj - csv 에 입력한 originId 와 일치하는 flat 한 속성명을 갖는 redux Object
 * @param csvObj - flag 2인 import 대상 subView 객체
 * @returns {*} - csv 의 originId 에 해당하는 redux 저장소의 객체
 */
export const getOriginSubViewObject = (reduxPageData, reduxObj, csvObj) => {
	const csvOriginId = csvObj.originId;
	const reduxPage = reduxObj.page;
	const type = objectModeConst.subView;
	return reduxPageData[reduxPage][csvConst.objects][type].filter(e => e.originId === csvOriginId)[0];
};

/**
 * csvObj 객체의 flat 한 속성을 redux 와 마찬가지로 depth 를 가진 객체를 리턴하는 함수
 * @param csvObj - flat 한 속성 명을 가진 csv 객체
 */
export const getUnFlatObject = (csvObj) => {
	const newObj = {};
	for (const [key, value] of Object.entries(csvObj)) {
		if (key === 'data.zipKey') {
			newObj.data = {};
			newObj.data.zipKey = value;
		} else if (key === 'data.htmlFileName') {
			newObj.data.htmlFileName = value;
		} else if (key === 'data.tooltip') {
			newObj.data.tooltip = value;
		} else {
			newObj[key] = value;
		}
	}
	return newObj;
};

/**
 * set Redux 로 리덕스에 최종 반영할 cloneData 를 가공하는 함수
 * @param clonedData - set Redux 로 최종적으로 반영할 대상
 * @param reduxObj - csvObj 의 originId 에 해당하는 redux 상의 객체
 * @param csvObj - csv 로부터 입력받은 객체
 * @param mergedObj - csv 로 입력받은 객체와 redux 상의 원본 객체를 합병한 객체
 */
export const updateHtmlData = (clonedData, reduxObj, csvObj, mergedObj) => {
	const type = objectModeConst.subView;
	const subViewArr = clonedData[reduxObj.page][csvConst.objects][type];
	const removedData = subViewArr.filter(e => e.originId !== csvObj.originId); // remove

	clonedData[reduxObj.page][csvConst.objects][type] = removedData;
	if (!clonedData[mergedObj.page][csvConst.objects][type]) { // if not exists arr then create arr
		clonedData[mergedObj.page][csvConst.objects][type] = []
	}
	clonedData[mergedObj.page].objects[type].push(mergedObj); // add merged object
};

/**
 * html csv 에러 검사를 하는 함수
 * @param {array} parsedData - flat 한 속성이름을 갖는 CSV 객체 배열
 * @param {number} comLength - 주석의 길이
 * @param {array} flatReduxClone - redux 객체를 클론해서 flat 한 속성을 갖게한 객체들의 배열
 * @param {object} repo - imageMap repo - 모든 key가 보관된 객체
 */
export const htmlErrorCheck = (parsedData, comLength, flatReduxClone, repo) => {
	const reduxOriginIdArr = flatReduxClone.map(e => e.originId);
	const dupleCheckObj = { originId: [] };
	for (const obj of parsedData) {
		const idx = getNumberLine(parsedData, obj, comLength);
		inputDuplicateCheck(obj, idx, dupleCheckObj); // 입력 중복값 체크
		uniqueCheck(reduxOriginIdArr, obj, idx, 'originId'); // redux 고유 값 유지 체크
		checkPropsKeyExist(obj, 'data.zipKey', idx, repo, 'html'); // repo key 체크
	}
};

/**
 * allReduxPropValuesArr 의 값과 obj[prop] 값이 일치하지 않는 경우
 * 존재하지 아니하는 속성값이므로 에러를 던지는 함수
 * @param allReduxPropValuesArr - redux 전체에서 사용되고 있는 prop 속성의 값
 * @param obj - 검사 대상 객체
 * @param idx - 검사 중인 obj 의 csv 상에서의 라인넘버
 * @param prop - 검사 대상 속성
 */
const uniqueCheck = (allReduxPropValuesArr, obj, idx, prop) => {
	const result = allReduxPropValuesArr.some(e => e === obj[prop]);
	if (!result) {
		throw new Error(makeErrorMsg('isNotExists', idx, csvColumnsName[prop]));
	}
};

/**
 * csv 로 입력받은 html 객체 배열을 순회하면서 각 객체의 전체 갯수와 data.zipKey 속성이 빈값인지 아닌지 판단해서
 * html import 성공시 출력되는 모달의 안내 문자열을 생성하는 함수
 * @param parsedData - options.iFrame 속성 값이 true 인 subView 객체들의 배열
 * @returns {string} - html import 성공시 모달에 출력될 문자열
 */
export const getImportMassage = (parsedData) => {
	const count = parsedData.length;
	let exist, none;
	exist = none = 0;
	for (const obj of parsedData) {
		if (obj['data.zipKey'] === "") {
			none++;
		} else {
			exist++;
		}
	}
	const countStr = `${count}件のHTML情報が修正できました。<br>`;
	const zipCountStr = `${csvColumnsName['data.zipKey']}指定が${exist}件、不指定が${none}件です。`;
	return `${countStr}${zipCountStr}`;
};

/**
 * rowData 를 map 하면서 originId의 자동변환 방지문자열을 제거한 객체 배열을 반환하는 함수
 * @param rowData - csv 에서 import 받은 데이터 객체 배열
 * @returns {*} - originId의 값에서 excel 자동변환 방지 문자열을 제거한 데이터 객체 배열
 */
export const filteringIdCover  = (rowData) => {
	return rowData.map(obj => {
		const originIdObj = idSubString(obj, csvColumnsName.originId);
		const idObj = idSubString(originIdObj, csvColumnsName.id);
		return idObj;
	});
};
