import React from 'react'
import { DragPreviewImage, useDrag } from 'react-dnd'
import boxImage from "../../../../../images/icon-add.png";

const DraggableBox = props => {
	const {id, originId, page, style, type, thumbKey, canDrag} = props;

	const [{isDragging}, drag, preview] = useDrag({
		item: { type, id, page, originId, style, thumbKey },
		collect: monitor => ({isDragging: monitor.isDragging()}),
		canDrag: canDrag,
	});
	const className = isDragging ? " dragging" : "";
	return (
		<>
			<DragPreviewImage connect={preview} src={boxImage}/>
			<div className={"draggable-box" + className} ref={drag} style={style}>
				<div className={"drag-item-id"}>{id}</div>
			</div>
		</>
	)
};
export default DraggableBox
