import React, {Component} from 'react';
import {connect} from 'react-redux';
import './AdminSetting.scss';
import {setContents, startLoading, endLoading} from "../../../reducers/CombineReducers/current";
import UserList from '../../../components/Contents/AdminSetting/UserList';
import ServerFileInfo from '../../../components/Contents/AdminSetting/ServerFileInfo';
import Title from '../../../components/Common/Title';
import {componentTitle, userLevel} from "../../../interface/constant";
import GroupSetting from "../../../components/Contents/AdminSetting/GroupSetting";

const mapStateToProps = ({current, auth}) => ({current, auth});
const mapDispatchToProps = {
  setContents,
  startLoading,
  endLoading,
};

class AdminSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      autosaveTimes: ['10分', '20分', '30分', '40分', '50分', '60分'],
      showModalPWChange: false,
      showModalPWNotMatched: false,
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.props.current.contents === "adminSetting" || nextProps.current.contents === "adminSetting";
  }

  onClickX = () => {
    this.props.setContents({
      contents: "editor"
    });
  };

  loadingStart(msg) {
    this.props.startLoading({msg});
  }

  loadingEnd() {
    this.props.endLoading();
  }

  adminElem() {
    return (
      <div className={"admin-content"}>
        <div className="admin-content-center">
          <div className={"user-list-title"}>ユーザー管理</div>
          <UserList/>
          <div className={"user-list-title"}>グループ設定</div>
          <GroupSetting/>
          <div className={"user-list-title"}>ファイル管理</div>
          <ServerFileInfo
            loadingStart={this.loadingStart.bind(this)}
            loadingEnd={this.loadingEnd.bind(this)}
          />
        </div>
      </div>
    );
  }

  render() {
    const {level} = this.props.auth.info;
    return (
      <div id={"AdminSetting"} className={this.props.className + " admin-component-area"}>
        <Title
          titleName={componentTitle.ADMIN_SETTING}
          onclickX={this.onClickX}
        />
        { level === userLevel.admin ? this.adminElem() : ""}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminSetting);