import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { now, randomInt } from "../../../interface/utils";
import './CreateNewBook.scss';
import { metaLabel } from "../../../interface/constant";
import { getMetaDataModel } from "../../../interface/dataModel";

import InputWithLabel from "../../Common/InputWithLabel";
import SelectBoxWithLabel from "../../Common/SelectBoxWithLabel";

import { subject, grade, semester, bookType, subjectKeyName, directionName } from '../../../interface/constant';
import GrayScreen from "../../Common/GrayScreen";
import { randomStr } from "../../../interface/utils";
import { imageUpload } from "../../../interface/api";

const {kokugo, doutoku, shosha} = subjectKeyName;
const rtlBooks = [kokugo, doutoku, shosha];

class CreateNewBook extends Component {
	constructor(props) {
		super(props);
		this.state = {
			bookId: `tf${randomStr(4)}_${randomInt(4)}`,
			subject: "kokugo",
			semester: 1,
			grade: 1,
			bookType: "t",
			totalPages: 10,
			width: 952,
			height: 674,
			coverPath: "",
			direction: directionName.rtl,
		};
	}

	onChange(e) {
		const name = e.target.getAttribute("name");
		let val = e.target.value;
		if (name !== "subject" && name !== "bookId" && name !== "bookType") val = parseInt(val);
		if (name === "bookId") {

		}
		this.setState({
			...this.state,
			[name]: val,
			direction: (name === "subject") ? this.setDirection(val) : this.state.direction,
		});
		console.log(this.setDirection(val));
	};

	setDirection(val) {
		const {rtl, ltr} = directionName;
		return rtlBooks.includes(val) ? rtl : ltr;
	}

	onBlur(e) {
		const name = e.target.getAttribute("name");
		let val = e.target.value;
		this.setState({
			...this.state,
			[name]: val,
		});
	}

	async onChangeCover(e) {
		let form = e.target.parentElement;
		let file = e.target.files[0];
		e.target.value = "";
		let {path} = await imageUpload(form, file);
		this.setState({
			...this.state,
			coverPath: path,
		});
	}

	makeBook() {
		const meta = {
			...getMetaDataModel(),
			...this.state,
			lastModified: now(),
			author: this.props.author,
			view: {
				width: this.state.width,
				height: this.state.height,
			},
		};
		delete meta.width;
		delete meta.height;
		this.props.makeBook(meta);
	};

	activeBtn() {
		const bookId = this.state.bookId;
		return (/^[a-z][a-z0-9_$()+/-]*$/.test(bookId) && bookId.length <= 20) ? "" : " de-active";
	}

	render() {
		return (
			<GrayScreen>
				<div id={"CreateNewBook"} className={"message-box"}>
					<div className="title-container">
						<div className="title-text">新しい教科書を作成</div>
						<div className="title-x-btn icon_close" onClick={this.props.onClickX}></div>
					</div>
					<div className={"contents-area"}>
						<div className="contents-container">
							<SelectBoxWithLabel
								name={"subject"} label={metaLabel.subject} option={subject}
								value={this.state.subject} onChange={this.onChange.bind(this)}
							/>
							<SelectBoxWithLabel
								name={"grade"} label={metaLabel.grade} option={grade}
								value={this.state.grade} onChange={this.onChange.bind(this)}
							/>
							<SelectBoxWithLabel
								name={"semester"} label={metaLabel.semester} option={semester}
								value={this.state.semester} onChange={this.onChange.bind(this)}
							/>
							<SelectBoxWithLabel
								name={"bookType"} label={metaLabel.bookType} option={bookType}
								value={this.state.bookType} onChange={this.onChange.bind(this)}
							/>
							<InputWithLabel
								type={"number"}
								name={"totalPages"} label={metaLabel.totalPages}
								value={this.state.totalPages}
								defaultValue={10} min={1} max={500} step={1}
								onChange={this.onChange.bind(this)}
							/>
							<InputWithLabel
								type={"number"}
								name={"width"} label={metaLabel.width}
								value={this.state.width}
								defaultValue={952}
								onChange={this.onChange.bind(this)}
							/>
							<InputWithLabel
								type={"number"}
								name={"height"} label={metaLabel.height}
								value={this.state.height}
								defaultValue={674}
								onChange={this.onChange.bind(this)}
							/>
							<InputWithLabel
								type={"file"}
								label={"表紙画像"}
								name={"coverImage"}
								pathName={"bookId"}
								path={"cover"}
								onChange={this.onChangeCover.bind(this)}
							/>
							{this.state.coverPath && <div className={"cover-preview-container"}>
								<div className="cover-preview" style={{
									backgroundImage: `url("${this.state.coverPath || ""}")`
								}}/>
							</div>}
						</div>
						<div className={"button-container"}>
							<input
								type={"button"} value={"作成"}
								className={"btn confirm btn_blue" + this.activeBtn()}
								onClick={this.makeBook.bind(this)}
							/>
						</div>
					</div>
				</div>
			</GrayScreen>
		)
	}
}

CreateNewBook.propTypes = {
	author: PropTypes.string.isRequired,
	makeBook: PropTypes.func.isRequired,
	onClickX: PropTypes.func.isRequired,
};

export default CreateNewBook;
