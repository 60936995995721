import React, {Component} from 'react';
import {connect} from 'react-redux';

import "./Setting.scss";
import {setContents, setPages} from '../../../reducers/CombineReducers/current';
import {setMetadata, setChapter, setBookIndex, changeDummy} from '../../../reducers/CombineReducers/metaData';

import Title from '../../../components/Common/Title';
import SelectBoxWithLabel from '../../../components/Common/SelectBoxWithLabel';
import SetChapter from "../../../components/Contents/Setting/SetChapter";
import SetBookIndex from "../../../components/Contents/Setting/SetPageIndex";

import {componentTitle, metaLabel} from "../../../interface/constant";
import {subject, grade, semester, tooltip, direction, indexEnd} from '../../../interface/constant';

const mapStateToProps = ({current, metaData, auth}) => ({current, metaData, auth});
const mapDispatchToProps = {setContents, setMetadata, setPages, setChapter, setBookIndex, changeDummy};

class Setting extends Component {
	shouldComponentUpdate(nextProps, nextState, nextContext) {
		return this.props.current.contents === "setting" || nextProps.current.contents === "setting";
	}

	onClickX = () => {
		this.props.setContents({
			contents: "editor"
		});
	};

	onChange(e) {
		const name = e.target.getAttribute("name");
		const val = e.target.value;

		this.props.setMetadata({
			meta: {
				...this.props.metaData,
				[name]: val,
			}
		});

		if (name === "direction") {
			let {left, right} = this.props.current.editor.pages;
			let pages = [right, left];
			this.props.setPages({pages});
		}
	};

	render() {
		return (
			<div id={"Setting"} className={this.props.className}>
				<Title
					titleName={componentTitle.BOOK_SETTING}
					onclickX={this.onClickX}
				/>
				<div className="setting-content">
					<div className="setting-content-center">
						<div className="component-column-area">
							<SelectBoxWithLabel
								name={"subject"}
								label={metaLabel.subject}
								option={subject}
								value={this.props.metaData.subject}
								onChange={this.onChange.bind(this)}/>
							<SelectBoxWithLabel
								name={"grade"}
								label={metaLabel.grade}
								option={grade}
								value={this.props.metaData.grade}
								onChange={this.onChange.bind(this)}/>
							<SelectBoxWithLabel
								name={"semester"}
								label={metaLabel.semester}
								option={semester}
								value={this.props.metaData.semester}
								onChange={this.onChange.bind(this)}/>
							<SelectBoxWithLabel
								name={"tooltip"}
								label={metaLabel.tooltip}
								option={tooltip}
								value={this.props.metaData.tooltip}
								onChange={this.onChange.bind(this)}/>
							<SelectBoxWithLabel
								name={"direction"}
								label={metaLabel.direction}
								option={direction}
								value={this.props.metaData.direction}
								onChange={this.onChange.bind(this)}/>
							<SelectBoxWithLabel
								name={"indexEnd"}
								label={metaLabel.indexEnd}
								option={indexEnd}
								value={this.props.metaData.indexEnd || 2}
								onChange={this.onChange.bind(this)}
							/>
							<SetChapter
								chapter={this.props.metaData.chapter}
								totalPages={this.props.metaData.totalPages}
								setChapter={this.props.setChapter}
							/>
							<SetBookIndex
								bookIndex={this.props.metaData.bookIndex}
								totalPages={this.props.metaData.totalPages}
								setBookIndex={this.props.setBookIndex}
								dummyList={this.props.metaData.dummyList}
								changeDummy={this.props.changeDummy}
							/>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Setting);