import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";

import {
	TextProperty,
	SelectProperty,
	SelectImageProperty,
	NumberProperty,
} from "../../../../components/Contents/Editor/Panels/PropertyPanel/innerProperty";
import {
	changeBookData,
	changeRectId,
	changeRectPage,
	changeRectData,
	changeRectOptions,
	changeRectSVG,
} from '../../../../reducers/CombineReducers/pageData';
import {
	pageLinkIconOptions,
	objectModeConst,
	propertyInputLabel,
	propertyInputName,
	propertyToolTip,
} from '../../../../interface/constant';
import { setSelectItem, changeEditorMode } from "../../../../reducers/CombineReducers/current";
import {togglePanel} from "../../../../reducers/CombineReducers/panel";
import PosSizeProperty from "../../../../components/Contents/Editor/Panels/PropertyPanel/posSizeProperty";
import SvgProperty from "../../../../components/Contents/Editor/Panels/PropertyPanel/SvgProperty";
import {checkNested} from "../../../../interface/utils";


const mapStateToProps = (state) => {
	const objMode = objectModeConst[state.current.editor.objectMode];
	let {id, page} = state.current.editor.selected;
	let prop = {};
	if (id && page) {
		let objectInfo = state.pageData[page].objects[objMode];
		let item = objectInfo.filter((item) => item.originId === id)[0];
		prop = {
			item,
			pages: state.current.editor.pages,
			bookChanged: state.current.book.changed,
			view: state.metaData.view,
			direction: state.metaData.direction,
			panel: state.panel,
			mode: state.current.editor.mode,
			id,
			page,
			objMode
		};
	}
	return prop;
};

const mapDispatchToProps = {
	changeBookData,
	changeRectId,
	changeRectPage,
	changeRectData,
	setSelectItem,
	changeRectOptions,
	changeRectSVG,
	changeEditorMode,
	togglePanel,
};

let property = {
	currentId: "",
	changedId: "",
	page: 0,
	objMode: "",
};

class PageLinkTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: "",
			propertyCss: {
				width: "",
				height: "",
				top: "",
				left: "",
			},
			type: 0,
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps === prevState.prevProps) return null;
		const propertyCss = PageLinkTable.arrangeCss(prevState.propertyCss, nextProps.item.css);
		const svg = checkNested(nextProps, "item.svg.attr.points") ? nextProps.item.svg.attr.points : "";
		return {propertyCss, svg}
	}

	static arrangeCss(prevCss, nextCss) {
		return {
			...nextCss,
			width: prevCss.width === "" ? prevCss.width : nextCss.width,
			height: prevCss.height === "" ? prevCss.height : nextCss.height,
			top: prevCss.top === "" ? prevCss.top : nextCss.top,
			left: prevCss.left === "" ? prevCss.left : nextCss.left,
		}
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.props.item.id !== prevProps.item.id) {
			const id = this.props.item.id || "";
			const propertyCss = this.props.item.css;
			const type = this.iconTypeValue(this.props.item);
			this.setState({
				id: id,
				propertyCss,
				type,
			})
		}
	}

	eventHandler() {
		return {
			onChange: (e) => {
				this.setState({
					...this.state,
					propertyCss: {
						...this.state.propertyCss,
						[e.target.name]: e.target.value,
					}
				});

				const value = e.target.value || 0;
				this.props.changeBookData({
					id: this.props.id,
					page: this.props.page,
					css: {
						[e.target.name]: (e.target.name !== 'opacity' ? parseInt(value, 10) : Number(value))
					},
					objMode: this.props.objMode
				});
			},
			pageChange: (e) => {
				let page = Number(e.target.value);
				this.props.changeRectPage({
					id: this.props.id,
					page: page,
					width: this.props.view.width / 2,
					objMode: this.props.objMode,
					direction: this.props.direction,
				});
				this.props.setSelectItem({
					id: this.props.id,
					page: page
				});
			},
			optionsChange: (e) => {
				let {page, objMode, item} = property;
				const itemOptions = Object.keys(this.props.item).length !== 0 ? this.props.item.options : item.options;
				let options = this.props.setOptionsValue(e.target, itemOptions);
				this.props.changeRectOptions({
					id: this.props.id,
					page: this.props.page || page,
					options: {
						[options.name]: options.value
					},
					objMode: this.props.objMode || objMode,
				});
			},
			dataChange: (e) => {
				let data = Number(e.target.value);
				if (isNaN(data)) return;
				this.props.changeRectData({
					id: this.props.id,
					page: this.props.page,
					data: {
						page: data,
					},
					objMode: this.props.objMode,
				});
			},
		}
	}

	setPropertyId(e) {
		property.currentId = this.props.id;
		property.changedId = e.target.value;
		property.page = this.props.page;
		property.objMode = this.props.objMode;
	}

	changeId(e) {
		this.setState({
			id: e.target.value
		});
		property.changedId = e.target.value;
	}

	changePageDataId() {
		let {currentId, changedId, page, objMode} = property;
		this.props.changeRectId({
			currentId: currentId,
			changedId: changedId,
			page: page,
			objMode: objMode
		});
	}

	changeRectData(data) {
		this.props.changeRectData({
			id: this.props.id,
			page: this.props.page,
			objMode: this.props.objMode,
			data,
		})
	}

	iconTypeValue(item) {
		if (item.data && item.data.class) {
			for (let iconType of pageLinkIconOptions) {
				const regExp = `${iconType.className}$`; // 문자열 끝에 iconType.className 이 들어가는지 체크하는 정규식
				if (item.data.class.match(new RegExp(regExp))) {
					return iconType.value;
				}
			}
		}
		return 0;
	}

	iconChange = (options) => {
		let type = Number(options.value);
		// if (type === 0) return;
		this.setState({
			...this.state,
			type: type
		});
		let idx = pageLinkIconOptions.findIndex((item) => item.value === type);
		let className = "icon " + pageLinkIconOptions[idx].className;

		if (type === 0) className = "";

		this.changeRectData({
			...this.props.item.data,
			class: className,
		});
		this.props.changeBookData({
			id: this.props.id,
			page: this.props.page,
			css: {
				width: pageLinkIconOptions[idx].width,
				height: pageLinkIconOptions[idx].height,
			},
			objMode: this.props.objMode
		});
		this.props.changeRectOptions({
			id: this.props.id,
			page: this.props.page,
			options: {
				link: {
					...this.props.item.options.link,
					level: pageLinkIconOptions[idx].level,
				}
			},
			objMode: this.props.objMode,
		});
	};

	setLabel(icon) {
		if(icon.value === 0) {
			return (
				<div className={"no-icon"}>
					{icon.name}
				</div>
			);
		}
		return (
			<div>
				<div className={"icon " + icon.className}/>
			</div>
		)
	}

	render() {
		const {id, page, item, pages} = this.props;
		const pageInfoOptions = this.props.pages ? [
			{val: pages.left, name: pages.left},
			{val: pages.right, name: pages.right}
		] : [];

		let iconType = pageLinkIconOptions;
		iconType.map((icon) => {
			icon.label = this.setLabel(icon);
			return icon;
		});

		const iconValue = iconType.filter((icon) => icon.value === this.state.type);

		return (
			<div className="inner-table">
				<table>
					<tbody>
					<TextProperty
						label={propertyInputLabel.id}
						name={propertyInputName.id} val={this.state.id}
						readOnly={false}
						onFocus={this.setPropertyId.bind(this)}
						onBlur={this.changePageDataId.bind(this)}
						onChange={this.changeId.bind(this)}
						tooltipText={propertyToolTip.id}
					/>
					<SelectProperty
						label={propertyInputLabel.page}
						name={propertyInputName.page} options={pageInfoOptions}
						eventHandler={this.eventHandler().pageChange} val={page}
						tooltipText={propertyToolTip.page}
					/>
					<NumberProperty
						label={propertyInputLabel.zIndex}
						name={propertyInputName.zIndex}
						val={item.options.link ? item.options.link.zIndex || "" : ""}
						onChange={this.eventHandler().optionsChange}
						tooltipText={propertyToolTip.zIndex}
					/>
					<TextProperty
						label={propertyInputLabel.linkPageData}
						name={propertyInputName.linkPageData} val={item.data.page || 0}
						onChange={this.eventHandler().dataChange}
					/>
					<PosSizeProperty css={this.state.propertyCss} eventHandler={this.eventHandler()}/>
					<SelectImageProperty
						label={propertyInputLabel.icon}
						name={propertyInputName.icon}
						options={iconType}
						val={iconValue}
						eventHandler={this.iconChange}
					/>
					<SvgProperty
						changeRectSVG={this.props.changeRectSVG}
						changeEditorMode={this.props.changeEditorMode}
						togglePanel={this.props.togglePanel}
						item={item}
						panel={this.props.panel}
						id={this.props.id}
						page={this.props.page}
						objMode={this.props.objMode}
						mode={this.props.mode}
						svg={this.state.svg}
					/>
					</tbody>
				</table>
				<div className="inner-mask" style={{display: id ? "none" : ""}}/>
			</div>
		)
	}
}

PageLinkTable.defaultProps = {
	item: {
		originId: "",
		id: "",
		css: {},
		options: {},
		page: 0,
		data: 0,
	},
	page: 0,
	id: ""
};

PageLinkTable.propTypes = {
	item: PropTypes.shape({
		originId: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		css: PropTypes.object.isRequired,
		options: PropTypes.object.isRequired,
		data: PropTypes.oneOfType([PropTypes.number, PropTypes.object]).isRequired,
		page: PropTypes.number.isRequired
	}).isRequired,
	page: PropTypes.number.isRequired,
	id: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(PageLinkTable);