import React, {Component} from 'react';
import {connect} from 'react-redux'
import {objectModeConst} from "../../../interface/constant";
import SVGColumn from "../../../components/Contents/Editor/Panels/SVGPanel/SVGColumn";
import SVGPathColumn from "../../../components/Contents/Editor/Panels/SVGPanel/SVGPathColumn";
import {checkNested, makeColorCodeHSL, isPointValidate, isPathDValidate} from "../../../interface/utils";
import {changeRectSVG} from "../../../reducers/CombineReducers/pageData";

const mapStateToProps = state => {
	const {id, page} = state.current.editor.selected;
	const {editor} = state.current;
	const objMode = objectModeConst[editor.objectMode];
	let props = {
		objMode: objMode,
	};
	if (id && page) {
		let objectInfo = state.pageData[page].objects[objMode];
		props.item = objectInfo.filter((item) => item.originId === id)[0];
		props.id = id;
		props.page = page;
	}
	return props;
};

const mapDispatchToProps = {
	changeRectSVG,
};

class EditSVG extends Component {
	constructor(props) {
		super(props);
		this.state = {
			points: [],
			d: [],
			type: props.item.svg.type,
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps === prevState.prevProps) return null;
		if (checkNested(nextProps, "item.svg.attr.points")) {
			const points = nextProps.item.svg.attr.points;
			return {
				points: points.split(" "),
				prevProps: nextProps,
				type: nextProps.item.svg.type,
			};
		} else if(checkNested(nextProps, "item.svg.attr.d")) {
			const d = nextProps.item.svg.attr.d;
			let regexp = /([a-yA-Y]+\s?[-]?\d+(\.\d+)?\s?[-]?\d+(\.\d+)?)/g;
			let found = d.match(regexp);
			return {
				d: found,
				prevProps: nextProps,
				type: nextProps.item.svg.type,
			}
		}
		return null;
	}

	changeRectPoint(newPoints) {
		let attr;
		if(this.state.type === "polygon") {
			attr = { points : newPoints.join(" ") };
		} else {
			attr = { d : newPoints.join(" ") + " Z" };
		}
		let options = {
			id: this.props.id,
			page: this.props.page,
			svg: {
				...this.props.item.svg,
				attr: attr
			},
			objMode: this.props.objMode,
		};
		this.props.changeRectSVG(options);
	}

	onChangePoint(e) {
		const idx = e.currentTarget.getAttribute("data-idx");
		const value = e.currentTarget.value;
		const newPoints = [...this.state.points];
		newPoints[idx] = value;
		if (isPointValidate(value)) {
			this.changeRectPoint(newPoints);
		} else {
			this.setState({points: newPoints})
		}
	};

	onChangePathD(e) {
		const value = e.currentTarget.value;
		const commandValue = e.currentTarget.previousSibling.value;
		const idx = e.currentTarget.getAttribute("data-idx");
		this.onChangePath(idx, value, commandValue);
	}

	onChangePathCommand(e) {
		const idx = e.currentTarget.getAttribute("data-idx");
		const commandValue = e.currentTarget.value;
		const value = e.currentTarget.nextSibling.value;
		this.onChangePath(idx, value, commandValue);
	}

	onChangePath(idx, value, commandValue) {
		const newD = [...this.state.d];
		newD[idx] = commandValue + " " + value;
		if (isPathDValidate(newD[idx])) {
			this.changeRectPoint(newD);
		} else {
			this.setState({d: newD})
		}
	}

	addPoint(e) {
		let x1, y1, x2, y2;
		const points = this.state.points;
		const idx = parseInt(e.currentTarget.getAttribute("data-idx"));
		if (typeof idx === "number") {
			[x1, y1] = points[idx].split(",").map(i => parseInt(i));
			let idx2 = this.getNextPoints(idx, true);
			[x2, y2] = points[idx2].split(",").map(i => parseInt(i));

			const middle = this.getMiddlePoints(x1, y1, x2, y2).join(",");
			const newPoints = [...points.slice(0, idx + 1), middle, ...points.slice(idx + 1)];
			this.changeRectPoint(newPoints);
		}
	};

	addPathD(e) {
		let x1, y1, x2, y2;
		const d = this.state.d;
		const idx = parseInt(e.currentTarget.getAttribute("data-idx"));
		if (typeof idx === "number") {
			let itemArr = d[idx].replace(/[a-zA-Z]\s?/g, '');
			[x1, y1] = itemArr.split(" ").map(i => parseInt(i));
			let idx2 = this.getNextPoints(idx, false);
			let itemArr2 = d[idx2].replace(/[a-zA-Z]\s?/g, '');
			[x2, y2] = itemArr2.split(" ").map(i => parseInt(i));

			const middle = "L " + (this.getMiddlePoints(x1, y1, x2, y2).join(" "));
			const newPoints = [...d.slice(0, idx + 1), middle, ...d.slice(idx + 1)];
			this.changeRectPoint(newPoints);
		}
	}

	getNextPoints(idx, isPolygon) {
		const points = isPolygon ? this.state.points : this.state.d;
		let idx2 = idx + 1;
		if (idx === (points.length - 1)) idx2 = 0;
		return idx2;
	}

	getMiddlePoints(x1, y1, x2, y2) {
		return [Math.floor((x1 + x2) / 2), Math.floor((y1 + y2) / 2)];
	}

	deletePoint(e) {
		const points = this.state.points;
		const idx = parseInt(e.currentTarget.getAttribute("data-idx"));
		if (typeof idx === "number") {
			const newPoints = [...points.slice(0, idx), ...points.slice(idx + 1)];
			this.changeRectPoint(newPoints);
		}
	};

	deletePathD(e) {
		const d = this.state.d;
		const idx = parseInt(e.currentTarget.getAttribute("data-idx"));
		if (typeof idx === "number") {
			const newD = [...d.slice(0, idx), ...d.slice(idx + 1)];
			this.changeRectPoint(newD);
		}
	}

	svgColumnMap() {
		return this.state.points.map((item, idx) => {
			return (
				<SVGColumn
					color={makeColorCodeHSL(idx)}
					key={idx}
					index={idx}
					length={this.state.points.length}
					onChangePoint={this.onChangePoint.bind(this)}
					addPoint={this.addPoint.bind(this)}
					deletePoint={this.deletePoint.bind(this)}
					value={item}
				/>
			)
		})
	}

	svgPathColumnMap() {
		return this.state.d.map((item, idx) => {
			return (
				<SVGPathColumn
					color={makeColorCodeHSL(idx)}
					key={idx}
					index={idx}
					length={this.state.d.length}
					onChangePathD={this.onChangePathD.bind(this)}
					onChangePathCommand={this.onChangePathCommand.bind(this)}
					addPathD={this.addPathD.bind(this)}
					deletePathD={this.deletePathD.bind(this)}
					value={item.replace(/[a-zA-Z]\s?/g, '')}
					command={item.replace(/\s?[-]?\d+(\.\d+)?\s?/g, '')}
				/>
			)
		});
	}

	render() {
		return (
			<div>
				{this.state.type === "polygon" ? this.svgColumnMap() : this.svgPathColumnMap()}
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EditSVG);