import React, { Component } from 'react';

import "./UserList.scss";
import {getUserList, changeUserActive, changeUserExpireDate, addEditAdminLog} from "../../../interface/api";
import Modal from "../../Common/Modal";
import {modalMsg, modalTitle} from "../../../interface/constant";
import {now} from "../../../interface/utils";
import {addAdminLog} from "../../../reducers/CombineReducers/current";
import {connect} from "react-redux";

const inputDateLimit = {
    year: {min: 1000, max: 9999, step: 1},
    month: {min: 1, max: 12, step: 1},
    day: {min: 1, max: 31, step: 1},
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        addAdminLog : (state) => dispatch(addAdminLog(state))
    }
};

class UserList extends Component{
    constructor(props) {
        super(props);
        this.state = {
            userList: [],
            editIdx: -1,
            currentExpireDate: {
                year: 0,
                month: 0,
                day: 0,
            },
            showModalUserInfo: false,
        };
        this.changeUserInfoProcess = null;
        this.userList();
    }

    // DB에서 유저목록 불러오기
    userList = async () =>{
        const users = await getUserList() || [];
        return this.setUserListToState(users);
    };

    // DB에서 불러온 유저목록 현재 state에 저장
    setUserListToState(users) {
        const userList = this.setUserListDateFormat(users.data);
        this.setState({
            ...this.state,
            userList: userList,
        })
    }

    // userList에서 유효기간 yy-mm-dd 형식으로 만들기
    setUserListDateFormat(userList) {
        if (userList.length > 0) {
            userList.map((userInfo) => {
                if (userInfo.expireDate && userInfo.expireDate.indexOf(" ") >= 0) {
                    userInfo.expireDate = userInfo.expireDate.substring(0, userInfo.expireDate.indexOf(" "));
                }
                return userInfo;
            });
        }
        return userList
    }

    // 유효기간 변경 버튼 함수
    editExpireDate(idx, id) {
        const editIdx = this.state.editIdx >= 0 ? -1 : idx;
        const eIdx = this.state.editIdx;
        let year = inputDateLimit.year.min;
        let month = inputDateLimit.month.min;
        let day = inputDateLimit.day.min;
        const currentTime = now();

        const currentExpireDate = this.state.userList[idx].expireDate;
        if(currentExpireDate) {
            const expireDateArr = currentExpireDate.split("-");
            year = expireDateArr[0];
            month = expireDateArr[1];
            day = expireDateArr[2].substring( 0, expireDateArr[2].indexOf(" ") ) || expireDateArr[2];
        }

        if (eIdx >= 0) {
            this.setState({showModalUserInfo: true});
            this.changeUserInfoProcess = async () => {

                const expireDate = this.state.currentExpireDate;
                let response = await changeUserExpireDate(id, expireDate);

                if (response.dateCheckFail) {
                    console.log(response.msg);
                }
                this.setUserListState(response);
                await addEditAdminLog({regDate:`${currentTime}`, userId:`${id}`, groupId:``, action: `有効期限変更`});

                this.setState({
                    ...this.state,
                    editIdx: editIdx,
                })

            };
        } else {
            this.setState({
                ...this.state,
                editIdx: editIdx,
                currentExpireDate: {
                    year: year,
                    month: month,
                    day: day,
                },
            })
        }
    }

    // 유효기간 text input 변경 함수
    changeCurrentExpireDateState(e) {
        const value = e.target.value;
        this.setState({
            ...this.state,
            currentExpireDate: {
                ...this.state.currentExpireDate,
                [e.target.name] : value,
            },
        })
    }

    // 유효기간 text input 유효성 검사해서 다시 셋팅
    dateCheck(e) {
        let value = e.target.value;
        if (value > inputDateLimit[e.target.name].max) value = inputDateLimit[e.target.name].max;
        if (value < inputDateLimit[e.target.name].min) value = inputDateLimit[e.target.name].min;

        if( (e.target.name === "month" || e.target.name === "day") && value.length === 1) {
            value = "0" + value;
        }

        this.setState({
            ...this.state,
            currentExpireDate: {
                ...this.state.currentExpireDate,
                [e.target.name] : value,
            },
        })
    }

    // 활성화 변경 함수
    changeActive(e, id) {
        this.setState({showModalUserInfo: true});
        const active = e.target.checked;
        const currentTime = now();
        let action = '活性化';
        if(active === false){
            action = '非活性化';
        }
        
        this.changeUserInfoProcess = async () => {
            let response = await changeUserActive(id, active);
            this.setUserListState(response);
            await addEditAdminLog({regDate:`${currentTime}`, userId:`${id}`, groupId:``, action: `${action}`});
        };
    }

    // DB에서 변경 된 유저정보를 현재 state에 다시 셋팅하는 함수( +모달제거)
    setUserListState(response) {
        if (!response.permission) {
            this.closeModal();
            return;
        }

        let userList = this.state.userList;
        const idx = userList.findIndex((userInfo) => (response.userInfo["_id"] === userInfo["_id"]));
        userList[idx] = response.userInfo;

        this.setState({
            ...this.state,
            userList: this.setUserListDateFormat(userList),
        });

        this.closeModal();
    }

    // 모달 ㅂㅂ
    closeModal = () => {
        this.setState({
            showModalUserInfo: false,
        })
    };

    render() {
        const {userList} = this.state;
        return (
            <table className={"user-list-table"}>
                <tbody>
                    <tr>
                        <th className={"idx"}></th>
                        <th className={"id"}>ID</th>
                        <th className={"company"}>会社名</th>
                        <th className={"expireDate"}>有効期限</th>
                        <th className={"active"}>活性化</th>
                    </tr>
                    {userList.map((userInfo, idx) => {
                        const isEdit = this.state.editIdx === idx ? "" : "hide";
                        const expireDateHide = this.state.editIdx === idx ? "hide" : "";
                        const editBtnHide = this.state.editIdx >= 0 ? this.state.editIdx === idx ? "" : "hide" : "";
                        return (
                            <tr key={idx}>
                                <td>{idx}</td>
                                <td>{userInfo["_id"]}</td>
                                <td>{userInfo["company"]}</td>
                                <td>
                                    <span className={"expireDate-value " + expireDateHide}>{userInfo["expireDate"]}</span>
                                    <span className={"expireDate-value " + isEdit}>
                                        <input
                                            type={"number"}
                                            name={"year"}
                                            className={"expireDate-input year"}
                                            value={this.state.currentExpireDate.year}
                                            min={inputDateLimit.year.min}
                                            max={inputDateLimit.year.max}
                                            step={inputDateLimit.year.step}
                                            onChange={this.changeCurrentExpireDateState.bind(this)}
                                            onBlur={this.dateCheck.bind(this)}
                                        />
                                        <span className={"year-text"}>年</span>
                                        <input
                                            type={"number"}
                                            name={"month"}
                                            className={"expireDate-input month"}
                                            value={this.state.currentExpireDate.month}
                                            min={inputDateLimit.month.min}
                                            max={inputDateLimit.month.max}
                                            step={inputDateLimit.month.step}
                                            onChange={this.changeCurrentExpireDateState.bind(this)}
                                            onBlur={this.dateCheck.bind(this)}
                                        />
                                        <span className={"month-text"}>月</span>
                                        <input
                                            type={"number"}
                                            name={"day"}
                                            className={"expireDate-input day"}
                                            value={this.state.currentExpireDate.day}
                                            min={inputDateLimit.day.min}
                                            max={inputDateLimit.day.max}
                                            step={inputDateLimit.day.step}
                                            onChange={this.changeCurrentExpireDateState.bind(this)}
                                            onBlur={this.dateCheck.bind(this)}
                                        />
                                        <span className={"day-text"}>日</span>
                                    </span>
                                    <input
                                        type={"button"}
                                        className={"btn_gray " + editBtnHide}
                                        name={"edit"}
                                        onClick={() => this.editExpireDate(idx, userInfo["_id"])}
                                        value={"編集"}
                                    />
                                </td>
                                <td className="table-value">
                                    <div className="switch">
                                        <label className="switch_label">
                                            <input type="checkbox" className="switch_input"
                                                   checked={userInfo["active"]}
                                                   onChange={(e) => this.changeActive(e, userInfo["_id"])}
                                            />
                                            <span className="switch_content"/>
                                            <span className="switch_circle"/>
                                        </label>
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
                {this.state.showModalUserInfo &&
                <Modal
                    onClickConfirm={this.changeUserInfoProcess}
                    onClickCancel={this.closeModal}
                    msg={modalMsg.USER_INFO_CHANGE}
                    title={modalTitle.WARNING}
                />
                }
            </table>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList);