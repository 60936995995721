import React from 'react'
import { useDrop } from 'react-dnd'

const DropZone = ({onDrop, children, accept}) => {
	const [{canDrop, isOver}, drop] = useDrop({
		accept,
		drop: onDrop,
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
	});
	const isActive = canDrop && isOver;
	let className = ' zone-empty';
	if (isActive) {
		className = ' zone-hovered';
	} else if (canDrop) {
		className = ' zone-dragging';
	}
	return (
		<div className={"drop-zone" + className} ref={drop}>{children}</div>
	)
};
export default DropZone
