import React, { Component } from 'react';
import { NumberProperty } from './innerProperty';
import { propertyInputLabel, propertyInputName, propertyToolTip } from "../../../../../interface/constant";
import PropTypes from "prop-types";

class PosSizeProperty extends Component {
	sizeElem() {
		const width = this.isEmpty(this.props.css.width) ? "" : this.props.css.width;
		const height = this.isEmpty(this.props.css.height) ? "" : this.props.css.height;
		return (
			<>
				<NumberProperty
					label={propertyInputLabel.width}
					name={propertyInputName.width} suffix={"px"}
					val={width} min={1}
					eventHandler={this.props.eventHandler}
				/>
				<NumberProperty
					label={propertyInputLabel.height}
					name={propertyInputName.height} suffix={"px"}
					val={height} min={1}
					eventHandler={this.props.eventHandler}
				/>
			</>);
	}

	posElem() {
		const left = this.isEmpty(this.props.css.left) ? "" : this.props.css.left;
		const top = this.isEmpty(this.props.css.top) ? "" : this.props.css.top;
		return (
			<>
				<NumberProperty
					label={propertyInputLabel.left}
					name={propertyInputName.left} suffix={"px"}
					val={left}
					eventHandler={this.props.eventHandler}
					tooltipText={propertyToolTip.left}
				/>
				<NumberProperty
					label={propertyInputLabel.top}
					name={propertyInputName.top} suffix={"px"}
					val={top}
					eventHandler={this.props.eventHandler}
					tooltipText={propertyToolTip.top}
				/>
			</>
		)
	}

	isEmpty(value) {
		return (typeof value === "undefined" || value === null)
	}

	render() {
		return (
			<>
				{this.props.useSize && this.sizeElem()}
				{this.props.usePos && this.posElem()}
			</>
		)
	}
}

PosSizeProperty.defaultProps = {
	useSize: true,
	usePos: true,
	css: {
		width: 0,
		height: 0,
		top: 0,
		left: 0,
	},
	eventHandler: null
};

PosSizeProperty.propTypes = {
	useSize: PropTypes.bool,
	usePos: PropTypes.bool,
	css: PropTypes.shape({
		width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		opacity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	}).isRequired,
	eventHandler: PropTypes.object.isRequired,
};


export default PosSizeProperty;