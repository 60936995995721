import React, {Component} from 'react';
import Panel from "../Panel";
import "./SVGPanel.scss";
import SubviewEditSVG from "../../../../../containers/Contents/Editor/SubviewEditSVG";

const panelState = {
	id: "SubViewSVGPanel",
	label: "拡大画面SVG編集",
	name: "subviewSvg",
	style: {
		width: 300,
		top: 100,
		right: 110,
	}
};

class SubViewSVGPanel extends Component {
	keyDown(e) {
		// ObjectLayerのkeydownイベントを実行しない
		e.nativeEvent.stopImmediatePropagation();
	}

	render() {
		return (
			<div className="svg-panel" onKeyDown={this.keyDown.bind(this)}>
				<SubviewEditSVG/>
			</div>
		)
	}
}

export default Panel(SubViewSVGPanel, panelState);