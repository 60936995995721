import React, { Component } from 'react';
import { connect } from 'react-redux';

import EditorPanel from "../../../components/Contents/Editor/Panels/EditorPanel/EditorPanel";
import PropertyPanel from "../../../components/Contents/Editor/Panels/PropertyPanel/PropertyPanel";
import ListPanel from "../../../components/Contents/Editor/Panels/ListPanel/ListPanel";
import PagePanel from "../../../components/Contents/Editor/Panels/PagePanel/PagePanel";
import TopPanel from "../../../components/Contents/Editor/Panels/TopPanel/TopPanel";
import StoragePanel from '../../../components/Contents/Editor/Panels/RepoPanel/RepoPanel';
import SubviewPanel from '../../../components/Contents/Editor/Panels/SubviewPanel/SubviewPanel';
import GroupPanel from "../../../components/Contents/Editor/Panels/GroupPanel/GroupPanel";
import LinkPagePanel from "../../../components/Contents/Editor/Panels/LinkPagePanel/LinkPagePanel";
import CheckSheetPanel from "../../../components/Contents/Editor/Panels/CheckSheetPanel/CheckSheetPanel";

import "./Editor.scss";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { togglePanel } from "../../../reducers/CombineReducers/panel";
import { changeEditorMode } from "../../../reducers/CombineReducers/current";
import SVGPanel from "../../../components/Contents/Editor/Panels/SVGPanel/SVGPanel";
import PopupPanel from "../../../components/Contents/Editor/Panels/PopupPanel/PopupPanel";
import SubViewSVGPanel from "../../../components/Contents/Editor/Panels/SVGPanel/SubViewSVGPanel";
import GuideModePanel from "../../../components/Contents/Editor/Panels/GuideModePanel/GuideModePanel";

const mapStateToProps = (state) => {
	const editorSize = {
		width: state.metaData.view.width * state.current.editor.scale,
		height: state.metaData.view.height * state.current.editor.scale,
	};
	return {
		objMode: state.current.editor.objectMode,
		mode: state.current.editor.mode,
		panel: state.panel,
		editorSize,
	}
};

const mapDispatchToProps = {
	togglePanel: togglePanel,
	changeEditorMode: changeEditorMode,
};

class Editor extends Component {
	render() {
		const panel = this.props.panel;
		return (
			<DndProvider backend={HTML5Backend}>
				<div id={"Editor"} className={this.props.className}>
					<EditorPanel
						className={"editor-panel"}
						objMode={this.props.objMode}
						mode={this.props.mode}
						editorSize={this.props.editorSize}
						scale={this.props.scale}
						socket={this.props.socket}
					/>
					{panel.property && <PropertyPanel objMode={this.props.objMode} togglePanel={this.props.togglePanel}/>}
					{panel.list && <ListPanel togglePanel={this.props.togglePanel}/>}
					{panel.page && <PagePanel togglePanel={this.props.togglePanel} socket={this.props.socket}/>}
					{panel.repo && <StoragePanel togglePanel={this.props.togglePanel} socket={this.props.socket}/>}
					{panel.screen && <SubviewPanel togglePanel={this.props.togglePanel}/>}
					{panel.group && <GroupPanel togglePanel={this.props.togglePanel} changeEditorMode={this.props.changeEditorMode}/>}
					{panel.linkPages && <LinkPagePanel togglePanel={this.props.togglePanel} changeEditorMode={this.props.changeEditorMode}/>}
					{panel.checkSheet && <CheckSheetPanel togglePanel={this.props.togglePanel} changeEditorMode={this.props.changeEditorMode}/>}
					{panel.svg && <SVGPanel togglePanel={this.props.togglePanel} changeEditorMode={this.props.changeEditorMode}/>}
					{panel.subviewSvg && <SubViewSVGPanel togglePanel={this.props.togglePanel} changeEditorMode={this.props.changeEditorMode}/>}
					{panel.popup && <PopupPanel togglePanel={this.props.togglePanel} changeEditorMode={this.props.changeEditorMode}/>}
					{panel.guideMode && <GuideModePanel togglePanel={this.props.togglePanel} changeEditorMode={this.props.changeEditorMode}/>}
					<TopPanel/>
				</div>
			</DndProvider>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Editor);