import React, {Component} from 'react';
import {connect} from "react-redux";
import {ShakaiMaskTable} from "../../../components/Contents/Editor/Panels/SubviewPanel/innerSubview";
import {changeRectData, changeRectDataBlock} from "../../../reducers/CombineReducers/pageData";
import {togglePanel} from "../../../reducers/CombineReducers/panel";
import {maskColorOptions, objectModeConst, subViewRect} from "../../../interface/constant";
import {randomStr} from "../../../interface/utils";
import {getObjects} from "../Editor/EditorLayers/ObjectLayer";

const mapStateToProps = state => {
	const {id, page, blockId} = state.current.editor.selected;
	const {editor} = state.current;
	const objMode = objectModeConst[editor.objectMode];
	let props = {};
	if (id && page) {
		let objectList = state.pageData[page].objects[objMode];
		const idx = objectList.findIndex((item) => item.originId === id);
		const subViewSettings = editor.objectMode === "subView" ? objectList[idx].data.subViewSettings : null;
		props = {
			selectedId: id,
			selectedPage: page,
			selectedBlockId: blockId,
			blockObj: state.pageData[page].objects["block"],
			subject: state.metaData.subject,
			mode: state.current.editor.mode,
			panel: state.panel,
			subViewSettings,
		}
	}
	return props;
};

const mapDispatchToProps = {
	changeRectData,
	changeRectDataBlock,
	togglePanel,
};

const ADD_OFFSET = 10;
let blockOffsetX = ADD_OFFSET;
let blockOffsetY = ADD_OFFSET;

class ShakaiMask extends Component {
	subViewSettingEvent() {
		return {
			addMask: () => {
				const {subViewSettings} = this.props;
				let block = subViewSettings.options.block || [];
				const fill = "";
				const defaultBlock = {
					id: "block-" + randomStr(8),
					rectId: "",
					width: 200,
					height: 100,
					left: blockOffsetX,
					top: blockOffsetY,
					fill: fill,
				};

				if(blockOffsetY < 580){
					blockOffsetX += ADD_OFFSET;
					blockOffsetY += ADD_OFFSET;
				}else{
					blockOffsetX = blockOffsetX-560;
					blockOffsetY = blockOffsetY-580;
				}

				const data = {
					subViewSettings: {
						...subViewSettings,
						options: {
							...subViewSettings.options,
							block: block.concat(defaultBlock),
						},
					}
				};

				this.props.changeRectData({
					id: this.props.selectedId,
					page: this.props.selectedPage,
					data: data,
					objMode: "subView",
				});
			},
			maskConnectChange: (e) => {
				const rectId = e.target.value;
				const data = {
					rectId: rectId,
				};

				this.props.changeRectDataBlock({
					id: this.props.selectedId,
					page: this.props.selectedPage,
					blockId: this.props.selectedBlockId,
					data: data,
					subViewType: subViewRect.shakaiMask,
				});
			},
			colorTextChange: (e) => {
				const {subViewSettings, selectedBlockId} = this.props;
				// 컬러코드 맨앞에 # 넣어주기
				const value = e.target.value ? e.target.value[0] !== "#" ? "#" + e.target.value : e.target.value : "";

				const block = subViewSettings.options.block;
				const blockIdx = block ? block.findIndex((item) => item.id === selectedBlockId) : -1;
				block[blockIdx].fill = value;

				const data = {
					subViewSettings: {
						...subViewSettings,
						options: {
							...subViewSettings.options,
							block: block,
						},
					}
				};
				this.props.changeRectData({
					id: this.props.selectedId,
					page: this.props.selectedPage,
					data: data,
					objMode: "subView",
				});
			},
			colorSelect: (color) => {
				const {subViewSettings, selectedBlockId} = this.props;
				const value = color.value;

				const block = subViewSettings.options.block;
				const blockIdx = block ? block.findIndex((item) => item.id === selectedBlockId) : -1;
				block[blockIdx].fill = value;

				const data = {
					subViewSettings: {
						...subViewSettings,
						options: {
							...subViewSettings.options,
							block: block,
						},
					}
				};
				this.props.changeRectData({
					id: this.props.selectedId,
					page: this.props.selectedPage,
					data: data,
					objMode: "subView",
				});
			},
			changeMaskCss: (e) => {
				const value = Number(e.target.value);
				const data = {
					[e.target.name]: value
				};

				this.props.changeRectDataBlock({
					id: this.props.selectedId,
					page: this.props.selectedPage,
					blockId: this.props.selectedBlockId,
					data: data,
					subViewType: subViewRect.shakaiMask,
				});
			},
		}
	}

	setLabel(color) {
		if(color.value === "") {
			return (
				<div>
					<div className={"mask-color-select-contents"}>
						<span className={"mask-color-select-text"}>{color.name}</span>
					</div>
				</div>
			);
		}
		return (
			<div>
				<div className={"mask-color-select-contents"} style={{backgroundColor: color.value}}>
					<span className={"mask-color-select-text"}>{color.value}</span>
				</div>
			</div>
		)
	}

	render() {
		const {subViewSettings, blockObj, selectedBlockId} = this.props;
		const block = subViewSettings.options.block;
		const blockIdx = block ? block.findIndex((item) => item.id === selectedBlockId) : -1;
		const rectBlock = getObjects(blockObj, "block") || [];


		maskColorOptions.map((color) => {
			color.label = this.setLabel(color);
			return color;
		});

		const existBlock = blockIdx >= 0 && block[blockIdx];
		const selectedColorValue = existBlock ? maskColorOptions.filter((color) => color.value === block[blockIdx].fill) : null;
		const colorSelectValue = selectedColorValue && selectedColorValue.length <= 0 ? maskColorOptions[0] : selectedColorValue;

		const colorTextValue = (existBlock && block[blockIdx].fill) ? block[blockIdx].fill : "";
		const colorCheck = /^#[0-9a-f]{3,6}$/i;	// 컬러코드 유효성 체크 정규식
		const isValidColor = colorCheck.test(colorTextValue) ? colorTextValue : "";

		const blockSettingsHide = existBlock ? "" : "hide";
		return (
			<ShakaiMaskTable
				event={this.subViewSettingEvent()}
				rectMask={rectBlock}
				subViewSettingsMask={ blockIdx < 0 ? null : subViewSettings.options.block[blockIdx]}
				selectedMaskId={selectedBlockId}

				maskColorOptions={maskColorOptions}
				colorSelectValue={colorSelectValue}
				colorTextValue={colorTextValue || ""}
				color={isValidColor || ""}
				maskSettingsHide={blockSettingsHide}
				tabMenu={this.props.tabMenu}
			/>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ShakaiMask);