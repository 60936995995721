import React, { Component } from 'react';
import { connect } from 'react-redux';
import './SubviewMenu.scss';
import { getSubViewSettings } from "../../../interface/utils";
import { modifyRepoPath } from "../../../reducers/CombineReducers/repo";
import { changeRectData } from "../../../reducers/CombineReducers/pageData";
import {
	modalMsg,
	modalTitle,
	objectModeConst,
	subjectKeyName,
	subMenuTitleOptions,
	subViewType
} from "../../../interface/constant";
import {CommonTable} from "../../../components/Contents/Editor/Panels/SubviewPanel/innerSubview";
import Modal from "../../../components/Common/Modal";
import {fileUpload} from "../../../components/Common/Upload";

import SubviewContents from "./SubviewContents";
import ShakaiDesc from "../SubViewMenu/ShakaiDesc";
import RikaBlock from "../SubViewMenu/RikaBlock";
import RikaScroll from "../SubViewMenu/RikaScroll";
import SansuAnswer from "../SubViewMenu/SansuAnswer";
import ShakaiRedLine from "../SubViewMenu/ShakaiRedLine";
import RikaMask from "../SubViewMenu/RikaMask";
import RikaCheck from "../SubViewMenu/RikaCheck";
import SubMenu from "../SubViewMenu/SubMenu";
import RikaAnswer from "../SubViewMenu/RikaAnswer";
import SubViewInSubView from "../SubViewMenu/SubViewInSubView";
import RikaScrollNew from "../SubViewMenu/RikaScrollNew";
import ShakaiMask from "../SubViewMenu/ShakaiMask";
import SansuScroll from "../SubViewMenu/SansuScroll";
import SansuRestudy from "../SubViewMenu/SansuRestudy";
import SubPopup from "../SubViewMenu/SubPopup";
import SubScroll from "../SubViewMenu/SubScroll";
import SubMovePage from "../SubViewMenu/SubMovePage";

const mapStateToProps = state => {
	const {id, page} = state.current.editor.selected;
	const {editor} = state.current;
	const objMode = objectModeConst[editor.objectMode];
	let props = {};
	if (id && page) {
		let objectList = state.pageData[page].objects[objMode];
		const idx = objectList.findIndex((item) => item.originId === id);
		const subViewSettings = editor.objectMode === "subView" ? objectList[idx].data.subViewSettings : null;
		const data = editor.objectMode === "subView" ? objectList[idx].data : null;
		props = {
			selectedId: id,
			selectedPage: page,
			bookId: state.metaData.bookId,
			subject: state.metaData.subject,
			repoImage: state.repo.image,
			objectMode: state.current.editor.objectMode,
			view: state.metaData.view,
			repo: state.repo,
			idx,
			subViewSettings,
			data,
			objectList,
		}

	}
	return props;
};
const mapDispatchToProps = {
	modifyRepoPath,
	changeRectData,
};

const subViewTypeOptions = [
	{value: 0, subject: "all", name: "共通"},
	{value: 9, subject: "all", name: "サブメニュー"},
	// {value: 11, subject: "all", name: "拡大画面に拡大画面"},
	{value: 1, subject: subjectKeyName.shakai, name: "社会①（説明）"},
	{value: 2, subject: subjectKeyName.shakai, name: "社会②（赤線）"},
	{value: 3, subject: subjectKeyName.rika, name: "理科①（ブロック）"},
	{value: 4, subject: subjectKeyName.rika, name: "理科②（【旧】スクロール）"},
	{value: 5, subject: subjectKeyName.sansu, name: "算数①（問題）"},
	// {value: 6, subject: subjectKeyName.kokugo, name: "国語①（下リンク）"},
	{value: 7, subject: subjectKeyName.rika, name: "理科③（キャラクター発言）"},
	{value: 8, subject: subjectKeyName.rika, name: "理科④（チェックボックス）"},
	{value: 10, subject: subjectKeyName.rika, name: "理科⑤（問題）"},
	{value: 7, subject: subjectKeyName.sansu, name: "算数②（マスク）"},
	{value: 12, subject: subjectKeyName.rika, name: "理科②（スライド）"},
	{value: 13, subject: subjectKeyName.shakai, name: "社会③（マスク）"},
	{value: 14, subject: subjectKeyName.sansu, name: "算数③（スライド）"},
	{value: 15, subject: subjectKeyName.sansu, name: "算数④（ふり返り）"},
	{value: 16, subject: subjectKeyName.kokugo, name: "ポップアップ"},
	{value: 17, subject: "all", name: "ページ移動"},
];

class SubviewMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			subViewImagesLevel: 0,
			scrollLevel: 0,
			scrollTitleEdit: false,
			scrollObjectMode: "mask",
			shakaiDescObjectMode: "block",
			repoKey: "",
			selectSubMenuContentsIdx: -1,
			showModalError: false,
			showModalDelete: false,
			showModalImageNotFound: false,
			modalMsg: "",
			tabMenu: {
				common: "active",
				setting: "",
				setting2: "",
			},
			marginValue: 0,
		};
		this.errorProcess = null;
		this.deleteProcess = null;
		this.imageNotFoundProcess = null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.selectedId !== prevProps.selectedId) {
			const {subViewSettings} = this.props;
			this.setState({
				...this.state,
				subViewImagesLevel: 0,
				scrollLevel: 0,
				scrollTitleEdit: false,
				scrollObjectMode: "mask",
				shakaiDescObjectMode: "block",
				repoKey: subViewSettings ? subViewSettings.options.imgPath : "",
				selectSubMenuContentsIdx: -1,
				tabMenu: {
					common: "active",
					setting: "",
					setting2: "",
					setting3: "",
				}
			})
		}
	}

	async imageUpload(e, idx = -1) {
		const result = await fileUpload(e, this.props.modifyRepoPath, this.props.bookId, "image", this.props.repo);
		if (!result || !result.fileUpload) {
			this.showErrorModal();
			return;
		}
		this.setSubViewImageData(result.data.key, idx);
	}

	setSubViewImageData(key, idx) {
		const {repoImage} = this.props;
		const keyIdx = Object.keys(repoImage).findIndex((item) => item === key);
		if(keyIdx < 0) {
			this.showErrorModal("ImageNotFound");
			return;
		}

		const data = this.setData(key, idx);

		this.props.changeRectData({
			id: this.props.selectedId,
			page: this.props.selectedPage,
			data: data,
			objMode: "subView",
		});

		this.setState({
			...this.state,
			repoKey: "",
		})
	}

	setData(key, idx) {
		const {subViewSettings} = this.props;
		const subViewImage = {
			imgPath: key,
			margin: "auto",
			marginTop: "",
			marginBottom: "",
			marginLeft: "",
			marginRight: ""
		};

		let data = {
			subViewSettings: {
				type: 0,
				options: {
					subViewImages: [subViewImage],
				}
			}
		};

		if (subViewSettings && subViewSettings.options) {
			const {subViewImages} = subViewSettings.options;
			data = {
				subViewSettings: {
					...subViewSettings,
					options: {
						...subViewSettings.options,
						subViewImages: subViewImages ? subViewImages.concat(subViewImage) : [subViewImage],
					}
				}
			};
		}

		if (idx >= 0) {
			const {subViewImages} = subViewSettings.options;
			subViewImages[idx].imgPath = key;
			data = {
				subViewSettings: {
					...subViewSettings,
					options: {
						...subViewSettings.options,
						subViewImages: subViewImages,
					}
				}
			};
		}

		return data;
	}

	subViewStateChange(name, value) {
		this.setState({
			...this.state,
			[name]: value,
		})
	}

	// 이과 스크롤 확대화면 제거함수
	// 이과 스크롤 확대화면은 여러개의 지면이 하나의 html을 보고있기 때문에 연동된 지면들을 모두 초기화해줘야 함
	scrollSubViewDelete(subViewSettings, objectList) {
		const scrollImage = subViewSettings.options.scrollImage;
		for(let scroll of scrollImage) {
			const idx = objectList.findIndex((item) => item.originId === scroll.rectId);
			if(idx >= 0) {
				const scrollData = {
					path: "",
					param: 0,
				};
				this.props.changeRectData({
					id: objectList[idx].originId,
					page: this.props.selectedPage,
					data: scrollData,
					objMode: "subView",
				});
			}
		}

	}

	subViewSettingElem() {
		const defaultHideSubView = [4, 9, 12, 14];
		const {subViewSettings, subject} = this.props;
		const type = subViewSettings ? subViewSettings.type : 0;

		const options = subViewTypeOptions.filter((item) => item.subject === subject || item.subject === "all");
		const defaultHide = (subViewSettings && defaultHideSubView.includes(subViewSettings.type)) ? "hide" : "";

		const subViewImages = subViewSettings ? subViewSettings.options ? subViewSettings.options.subViewImages || [] : [] : [];
		const currentImage = subViewImages.length > 0 ? subViewImages[this.state.subViewImagesLevel] : null;
		const imageCss = this.setImageCss(currentImage);

		if(this.state.tabMenu.common) {
			const useScroll = subViewSettings && (this.props.subject === subjectKeyName.shakai || this.props.subject === subjectKeyName.rika);
			const scrollCheckHide = useScroll ? "" : "hide";
			const isScroll = subViewSettings ? subViewSettings.options ? subViewSettings.options.isScroll || false : false : false;
			const isLargeView = getSubViewSettings(subViewSettings, "isLargeView", false);
			return (
				<CommonTable
					subViewSettings={subViewSettings}
					subViewTypeOptions={options}
					imageUpload={this.imageUpload.bind(this)}
					selectedId={this.props.selectedId}
					bookId={this.props.bookId}
					defaultHide={defaultHide}
					setSubViewImageData={this.setSubViewImageData.bind(this)}
					repoKey={this.state.repoKey}
					subViewImages={subViewImages}
					currentImageIdx={this.state.subViewImagesLevel}
					imageCss={imageCss}
					marginValue={this.state.marginValue}
					commonMenuEvent={this.commonMenuEvent()}
					scrollCheckHide={scrollCheckHide}
					isScroll={isScroll}
					isLargeView={isLargeView}
				/>
			);
		}

		switch (type) {
			case subViewType.common:
				if (!subViewSettings) return;

				if (this.state.tabMenu.setting) {
					return (<SubViewInSubView/>);
				} else if (this.state.tabMenu.setting2) {
					return this.openSubScroll(subViewSettings, currentImage, imageCss);
				} else {
					return;
				}
			case subViewType.subPopup:
				if (this.state.tabMenu.setting2) {
					return this.openSubScroll(subViewSettings, currentImage, imageCss);
				}else{
					return (<SubPopup showErrorModal={this.showErrorModal.bind(this)}/>);
				}
			case subViewType.subMenu:
				return (
					<SubMenu
						stateChange={this.subViewStateChange.bind(this)}
						selectedIdx={this.state.selectSubMenuContentsIdx}
						showErrorModal={this.showErrorModal.bind(this)}
					/>
				);
			case subViewType.shakaiDesc:
				if (this.state.tabMenu.setting4) {
					return (<SubViewInSubView/>);
				} else if (this.state.tabMenu.setting5) {
					return this.openSubScroll(subViewSettings, currentImage, imageCss);
				} else {
					return (
						<ShakaiDesc
							tabMenu={this.state.tabMenu}
							shakaiDescObjectMode={this.state.shakaiDescObjectMode}
							stateChange={this.subViewStateChange.bind(this)}
						/>
					);
				}
			case subViewType.shakaiRedLine:
				if (this.state.tabMenu.setting3) {
					return (<SubViewInSubView/>);
				} else if (this.state.tabMenu.setting4) {
					return this.openSubScroll(subViewSettings, currentImage, imageCss);
				} else {
					return (<ShakaiRedLine tabMenu={this.state.tabMenu}/>);
				}
			case subViewType.rikaBlock:
				if (this.state.tabMenu.setting3) {
					return (<SubViewInSubView/>);
				} else if (this.state.tabMenu.setting4) {
					return this.openSubScroll(subViewSettings, currentImage, imageCss);
				} else {
					return (<RikaBlock tabMenu={this.state.tabMenu}/>);
				}
			case subViewType.rikaMask:
				if (this.state.tabMenu.setting3) {
					return (<SubViewInSubView/>);
				} else if (this.state.tabMenu.setting4) {
					return this.openSubScroll(subViewSettings, currentImage, imageCss);
				} else if (this.state.tabMenu.setting5) {
					return (<SubMovePage/>);
				} else {
					return (
						<RikaMask
							tabMenu={this.state.tabMenu}
							showErrorModal={this.showErrorModal.bind(this)}
						/>
						);
				}
			case subViewType.rikaCheck:
				if (this.state.tabMenu.setting3) {
					return (<SubViewInSubView/>);
				} else if (this.state.tabMenu.setting4) {
					return this.openSubScroll(subViewSettings, currentImage, imageCss);
				} else {
					return (<RikaCheck tabMenu={this.state.tabMenu}/>);
				}
			case subViewType.rikaScroll:
				return (
					<RikaScroll
						scrollLevel={this.state.scrollLevel}
						scrollTitleEdit={this.state.scrollTitleEdit}
						scrollObjectMode={this.state.scrollObjectMode}
						stateChange={this.subViewStateChange.bind(this)}
						tabMenu={this.state.tabMenu}
						showErrorModal={this.showErrorModal.bind(this)}
					/>
				);
			case subViewType.sansuAnswer:
				return (
					<SansuAnswer
						tabMenu={this.state.tabMenu}
						showErrorModal={this.showErrorModal.bind(this)}
					/>
				);
			case subViewType.kokugoPageLink:
				return;
			case subViewType.rikaAnswer:
				if (this.state.tabMenu.setting3) {
					return (<SubViewInSubView/>);
				} else if (this.state.tabMenu.setting4) {
					return this.openSubScroll(subViewSettings, currentImage, imageCss);
				} else {
					return (
						<RikaAnswer
							tabMenu={this.state.tabMenu}
							showErrorModal={this.showErrorModal.bind(this)}
						/>
						);
				}
			case subViewType.subViewInSubView:
				return (<SubViewInSubView/>);
			case subViewType.rikaScrollNew:
				if (this.state.tabMenu.setting4) {
					return (<SubViewInSubView scrollLevel={this.state.scrollLevel}/>);
				}else if (this.state.tabMenu.setting5) {
					return (<SubMovePage scrollLevel={this.state.scrollLevel}/>);
				} else {
					return (
						<RikaScrollNew
							scrollLevel={this.state.scrollLevel}
							scrollTitleEdit={this.state.scrollTitleEdit}
							scrollObjectMode={this.state.scrollObjectMode}
							stateChange={this.subViewStateChange.bind(this)}
							tabMenu={this.state.tabMenu}
							showErrorModal={this.showErrorModal.bind(this)}
						/>
					);
				}
			case subViewType.shakaiMask:
				if (this.state.tabMenu.setting3) {
					return (<SubViewInSubView/>);
				} else if (this.state.tabMenu.setting4) {
					return this.openSubScroll(subViewSettings, currentImage, imageCss);
				} else {
					return (<ShakaiMask tabMenu={this.state.tabMenu}/>);
				}
			case subViewType.sansuScroll:
				if (this.state.tabMenu.setting3) {
					return (<SubMovePage scrollLevel={this.state.scrollLevel}/>);
				}else{
					return (
						<SansuScroll
							scrollLevel={this.state.scrollLevel}
							scrollTitleEdit={this.state.scrollTitleEdit}
							scrollObjectMode={this.state.scrollObjectMode}
							stateChange={this.subViewStateChange.bind(this)}
							tabMenu={this.state.tabMenu}
							showErrorModal={this.showErrorModal.bind(this)}
						/>
					);
				}
			case subViewType.sansuRestudy:
				return (<SansuRestudy tabMenu={this.state.tabMenu}/>);
			case subViewType.subMovePage:
				if (this.state.tabMenu.setting2) {
					return this.openSubScroll(subViewSettings, currentImage, imageCss);
				} else if (this.state.tabMenu.setting3) {
					return (<SubMovePage/>);
				} else if (this.state.tabMenu.setting4) {
					return (<SubViewInSubView/>);
				} else {
					return (<RikaAnswer tabMenu={this.state.tabMenu}/>);
				}
			default:
				return;
		}
	}

	openSubScroll (subViewSettings, currentImage, imageCss) {
		const scrollInfo = subViewSettings.options.scrollInfo || {"align": "", "position": 0};
		return (
			<SubScroll
				commonMenuEvent={this.commonMenuEvent()}
				currentImage={currentImage}
				imageCss={imageCss}
				scrollInfo={scrollInfo}
			/>
		);
	}

	setImageCss(currentImage) {
		const {subViewSettings} = this.props;
		let imageCss = {
			width: 0,
			height: 0,
			left: 0,
			top: 0,
			margin: "",
		};
		if(currentImage) {
			imageCss.width = currentImage.width || "";
			imageCss.height = currentImage.height || "";
			imageCss.left = currentImage.left || "";
			imageCss.top = currentImage.top || "";
			imageCss.margin = currentImage.margin || "";
		} else {
			imageCss.width = subViewSettings ? subViewSettings.options.width || "" : "";
			imageCss.height = subViewSettings ? subViewSettings.options.height || "" : "";
			imageCss.left = subViewSettings ? subViewSettings.options.left || "" : "";
			imageCss.top = subViewSettings ? subViewSettings.options.top || "" : "";
			imageCss.margin = subViewSettings ? subViewSettings.options.margin || "" : "";
		}

		return imageCss;
	}

	changeTabMenu(menuName) {
		this.setState({
			...this.state,
			tabMenu: {
				[menuName]: "active"
			}
		})
	}

	commonMenuEvent() {
		return {
			subViewTypeChange: (e) => {
				const {subViewSettings} = this.props;
				const value = Number(e.target.value);
				let data = {};

				if (subViewSettings) {
					data = {
						subViewSettings: {
							...subViewSettings,
							type: value,
						}
					};
				} else {
					data = {
						subViewSettings: {
							type: value,
							options: {},
						}
					};
				}

				if (value === 9) {
					data.subViewSettings.options = {
						...data.subViewSettings.options,
						subMenuTitle: data.subViewSettings.options.subMenuTitle || subMenuTitleOptions[0].value,
					}
				}
				this.props.changeRectData({
					id: this.props.selectedId,
					page: this.props.selectedPage,
					data: data,
					objMode: "subView",
				});
			},
			cssChecked: (e) => {
				const {subViewSettings, view} = this.props;
				if (!subViewSettings) return;
				if (subViewSettings.options.hasOwnProperty("widthMax")) delete subViewSettings.options.widthMax;
				if (subViewSettings.options.hasOwnProperty("heightMax")) delete subViewSettings.options.heightMax;
				const value = view[e.target.name];
				let data = {
					subViewSettings: {
						...subViewSettings,
						options: {
							...subViewSettings.options,
							[e.target.name]: value,
						}
					}
				};
				if (subViewSettings.options && subViewSettings.options.subViewImages) {
					const idx = this.state.subViewImagesLevel;
					const subViewImages = Object.assign([], subViewSettings.options.subViewImages);

					if (subViewImages[idx].hasOwnProperty("widthMax")) delete subViewImages[idx].widthMax;
					if (subViewImages[idx].hasOwnProperty("heightMax")) delete subViewImages[idx].heightMax;
					subViewImages[idx][e.target.name] = value;
					data = {
						subViewSettings: {
							...subViewSettings,
							options: {
								...subViewSettings.options,
								subViewImages : subViewImages,
							}
						}
					}
				}
				this.props.changeRectData({
					id: this.props.selectedId,
					page: this.props.selectedPage,
					data: data,
					objMode: "subView",
				});
			},
			changeRepoKey: (e) => {
				this.setState({
					...this.state,
					repoKey: e.target.value,
				})
			},
			subViewDelete: () => {
				const {subViewSettings, objectList} = this.props;
				if(!subViewSettings) return;

				const data = {
					path: "",
					subViewSettings: {
						...subViewSettings,
						options: {}
					}
				};

				this.deleteProcess = (e) => {
					e.stopPropagation();

					if( (subViewSettings.type === 4 || subViewSettings.type === 12) && subViewSettings.options.scrollImage) {
						// 이과 스크롤 확대화면이라면 추가적으로 함수실행
						this.scrollSubViewDelete(subViewSettings, objectList);
					}

					if(subViewSettings.type === 9) {
						this.subViewStateChange("selectSubMenuContentsIdx", -1);
					}

					this.props.changeRectData({
						id: this.props.selectedId,
						page: this.props.selectedPage,
						data: data,
						objMode: "subView",
					});
					this.toggleModal("Delete", false);
					this.deleteProcess = () => {};
				};
				this.toggleModal("Delete", true);
			},
			subViewImageCssChange: (e) => {
				const {subViewSettings} = this.props;
				if (!subViewSettings || !subViewSettings.options || !subViewSettings.options.subViewImages) return;
				const value = Number(e.target.value);
				let data = {
					subViewSettings: {
						...subViewSettings,
						options: {
							...subViewSettings.options,
							[e.target.name]: value,
						},
					}
				};
				if ( (e.target.name === "width" || e.target.name === "height") && value <= 0) {
					delete data.subViewSettings.options[e.target.name];
				}
				if (subViewSettings.options && subViewSettings.options.subViewImages) {
					const idx = this.state.subViewImagesLevel;
					const subViewImages = Object.assign([], subViewSettings.options.subViewImages);

					if ( (e.target.name === "width" || e.target.name === "height") && value <= 0) {
						delete subViewImages[idx][e.target.name];
					} else {
						subViewImages[idx][e.target.name] = value;
					}
					data = {
						subViewSettings: {
							...subViewSettings,
							options: {
								...subViewSettings.options,
								subViewImages : subViewImages,
							}
						}
					}
				}
				this.props.changeRectData({
					id: this.props.selectedId,
					page: this.props.selectedPage,
					data: data,
					objMode: "subView",
				});
			},
			changeCurrentSubViewImages: (idx) => {
				this.setState({
					...this.state,
					subViewImagesLevel: idx,
				})
			},
			deleteSubViewImage: (e, idx) => {
				e.stopPropagation();
				const {subViewSettings} = this.props;
				let subViewImages = Object.assign([], subViewSettings.options.subViewImages);
				subViewImages.splice(idx, 1);

				const data = {
					subViewSettings: {
						...subViewSettings,
						options: {
							...subViewSettings.options,
							subViewImages : subViewImages,
						}
					}
				};

				if (subViewImages.length === 0) delete data.subViewSettings.options.subViewImages;

				this.props.changeRectData({
					id: this.props.selectedId,
					page: this.props.selectedPage,
					data: data,
					objMode: "subView",
				});

				this.setState({
					...this.state,
					subViewImagesLevel: 0,
				});
			},
			changeSubViewImage: (e) => {
				this.imageUpload(e, this.state.subViewImagesLevel);
			},
			marginAutoCheck: (e) => {
				const {subViewSettings} = this.props;
				let data = {};
				if (subViewSettings.options && subViewSettings.options.subViewImages) {
					const idx = this.state.subViewImagesLevel;
					const subViewImages = Object.assign([], subViewSettings.options.subViewImages);

					subViewImages[idx][e.target.name] = e.target.checked ? "auto" : "";

					subViewImages[idx].marginTop = "";
					subViewImages[idx].marginBottom = "";
					subViewImages[idx].marginLeft = "";
					subViewImages[idx].marginRight = "";

					data = {
						subViewSettings: {
							...subViewSettings,
							options: {
								...subViewSettings.options,
								subViewImages: subViewImages,
							}
						}
					}
				} else {
					const options = subViewSettings.options;
					options[e.target.name] = e.target.checked ? "auto" : "";
					data = {
						subViewSettings: {
							...subViewSettings,
							options: options,
						}
					}
				}

				this.props.changeRectData({
					id: this.props.selectedId,
					page: this.props.selectedPage,
					data: data,
					objMode: "subView",
				});
			},
			marginValueChange: (e) => {
				const value = Number(e.target.value);
				this.setState({
					...this.state,
					[e.target.name]: value,
				})
			},
			applyMarginValue: (e) => {
				const {subViewSettings} = this.props;
				const value = this.state.marginValue;
				// subViewSettings.options.subViewImages[this.state.subViewImagesLevel]
				let data = {};
				if (subViewSettings.options && subViewSettings.options.subViewImages) {
					const idx = this.state.subViewImagesLevel;
					const subViewImages = Object.assign([], subViewSettings.options.subViewImages);

					subViewImages[idx] = this.setMargin(subViewImages[idx], value);

					data = {
						subViewSettings: {
							...subViewSettings,
							options: {
								...subViewSettings.options,
								subViewImages: subViewImages,
							}
						}
					}
				} else {
					const options = this.setMargin(subViewSettings.options, value);
					data = {
						subViewSettings: {
							...subViewSettings,
							options: options,
						}
					}
				}

				this.props.changeRectData({
					id: this.props.selectedId,
					page: this.props.selectedPage,
					data: data,
					objMode: "subView",
				});

				this.setState({
					...this.state,
					marginValue: 0,
				})
			},
			isScrollChange: (e) => {
				const {subViewSettings} = this.props;
				const data = {
					subViewSettings: {
						...subViewSettings,
						options: {
							...subViewSettings.options,
							isScroll: e.target.checked,
						},
					}
				};
				this.props.changeRectData({
					id: this.props.selectedId,
					page: this.props.selectedPage,
					data: data,
					objMode: "subView",
				});
			},
			changeSubViewSize: (e) => {
				const {subViewSettings} = this.props;
				if (!subViewSettings || !subViewSettings.options) return;
				const data = {
					subViewSettings: {
						...subViewSettings,
						options: {
							...subViewSettings.options,
							isLargeView: subViewSettings.options.isLargeView ? false : true,
						},
					}
				};
				this.props.changeRectData({
					id: this.props.selectedId,
					page: this.props.selectedPage,
					data: data,
					objMode: "subView",
				});
			}
		}
	};

	setMargin(css, marginValue) {
		if (css.width) css.width = css.width - marginValue * 2;
		if (css.height) css.height = css.height - marginValue * 2;
		if (!css.margin) {
			css.left = (css.left || 0) + marginValue;
			css.top = (css.top || 0) + marginValue;
		}
		return css;
	}

	showErrorModal(type = "Error") {
		if (type === "ImageNotFound") {
			this.imageNotFoundProcess = (e) => {
				e.stopPropagation();
				this.toggleModal("ImageNotFound", false);
				this.imageNotFoundProcess = () => {};
			};
		} else {
			this.errorProcess = (e) => {
				e.stopPropagation();
				this.toggleModal("Error", false);
				this.errorProcess = () => {};
			};
		}
		this.toggleModal(type, true);
	}

	toggleModal(type = "Error", show = true) {
		let modalType = `showModal${type}`;
		this.setState({
			...this.state,
			[modalType]: show,
		})
	}

	modalCancel() {
		this.toggleModal("Delete", false);
		this.deleteProcess = () => {};
	}

	getCommonMenuStatus (subject, subViewSettings) {
		const subjects1 = [subjectKeyName.rika, subjectKeyName.shakai, subjectKeyName.kokugo, subjectKeyName.sansu];
		const subjects2 = [subjectKeyName.rika, subjectKeyName.shakai, subjectKeyName.kokugo, subjectKeyName.sansu, subjectKeyName.eigo];
		return {
			addMenuHide: subjects1.includes(subject) ? "" : " hide",
			addMenuHide2: subjects2.includes(subject) ? "" : " hide"
		};
	}

	render() {
		const useSetting2 = [1, 2, 3, 4, 5, 7, 8, 10, 12, 13, 14, 15, 16, 17]; // 설정2를 사용하는 확대화면의 번호
		const useSetting3 = [1, 2, 3, 4, 7, 8, 10, 12, 13, 17, 14];
		const useSetting4 = [1, 2, 3, 7, 8, 10, 12, 13, 17];
		const useSetting5 = [1, 7, 12];
		const {common, setting, setting2, setting3, setting4, setting5} = this.state.tabMenu;
		const {subViewSettings, selectedId, data, subject} = this.props;
		const isScrollMain = data ? data.param ? data.param === 1 || false : true : true;
		const isActive = selectedId && isScrollMain && this.props.objectMode === "subView";
		const subViewSettingAreaHide = selectedId && isActive ?  "" : " hide";
		let addMenuHide = subViewSettings ? subViewSettings.type !== 0 ? "" : " hide" : " hide";
		let addMenuHide2 = subViewSettings ? useSetting2.includes(subViewSettings.type) ? "" : " hide" : " hide";
		const addMenuHide3 = subViewSettings ? useSetting3.includes(subViewSettings.type) ? "" : " hide" : " hide";
		const addMenuHide4 = subViewSettings ? useSetting4.includes(subViewSettings.type) ? "" : " hide" : " hide";
		const addMenuHide5 = subViewSettings ? useSetting5.includes(subViewSettings.type) ? "" : " hide" : " hide";

		if (subViewSettings && subViewSettings.type === subViewType.common) {
			const menuStatus = this.getCommonMenuStatus(subject, subViewSettings);
			addMenuHide = menuStatus.addMenuHide;
			addMenuHide2 = menuStatus.addMenuHide2;
		}

		return (
			<div id={"Subview"} onKeyDown={this.props.keyDown}>

				<div className={"subview-default-area"}>
					<div className={"subview-center"}>
						<SubviewContents
							scrollLevel={this.state.scrollLevel}
							stateChange={this.subViewStateChange.bind(this)}
							scrollObjectMode={this.state.scrollObjectMode}
							shakaiDescObjectMode={this.state.shakaiDescObjectMode}
							isScrollMain={isScrollMain}
							selectSubMenuContentsIdx={this.state.selectSubMenuContentsIdx}
						/>
					</div>
				</div>

				<div className={"subview-setting-area " + subViewSettingAreaHide}>
					<div className={"subview-tab"}>
						<div
							className={"tab-menu " + common}
							onClick={() => this.changeTabMenu("common")}>
							共通
						</div>
						<div
							className={"tab-menu " + setting + addMenuHide}
							onClick={() => this.changeTabMenu("setting")}>
							設定
						</div>
						<div
								className={"tab-menu large " + setting2 + addMenuHide2}
								onClick={() => this.changeTabMenu("setting2")}>
								設定Ⅱ
						</div>
						<div
							className={"tab-menu large " + setting3 + addMenuHide3}
							onClick={() => this.changeTabMenu("setting3")}>
							設定Ⅲ
						</div>
						<div
							className={"tab-menu large " + setting4 + addMenuHide4}
							onClick={() => this.changeTabMenu("setting4")}>
							設定Ⅳ
						</div>
						<div
							className={"tab-menu large " + setting5 + addMenuHide5}
							onClick={() => this.changeTabMenu("setting5")}>
							設定Ⅴ
						</div>
					</div>
					<table className={"subview-menu-area"}>
						{this.subViewSettingElem()}
					</table>
				</div>

				<div className="inner-mask" style={{display: isActive ? "none" : ""}}/>

				{this.state.showModalError && <Modal
					onClickConfirm={(e) => this.errorProcess(e)}
					msg={modalMsg.IMAGE_EXIST}
					title={modalTitle.ERROR}
				/>}
				{this.state.showModalImageNotFound && <Modal
					onClickConfirm={(e) => this.imageNotFoundProcess(e)}
					msg={modalMsg.IMAGE_FIND_FAIL}
					title={modalTitle.ERROR}
				/>}
				{this.state.showModalDelete && <Modal
					onClickCancel={this.modalCancel.bind(this)}
					onClickConfirm={(e) => this.deleteProcess(e)}
					msg={modalMsg.DELETE}
					title={modalTitle.WARNING}
				/>}
			</div>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SubviewMenu);