import React, {Component} from 'react';
import Panel from "../Panel";
import './RepoPanel.scss';
import RepoMenu from '../../../../../containers/Contents/Editor/RepoMenu';

const panelState = {
	id: "RepoPanel",
	name: "repo",
	label: "レポジトリ",
	style: {
		width: 800,
		height: 600,
		top: 70,
		left: 10,
	}
};

class RepoPanel extends Component {
	keyDown(e) {
		// ObjectLayerのkeydownイベントを実行しない
		e.nativeEvent.stopImmediatePropagation();
	}

	render() {
		return (
			<div className="repo-content-area" onKeyDown={this.keyDown.bind(this)}>
				<RepoMenu socket={this.props.socket}/>
			</div>)
	}
}

export default Panel(RepoPanel, panelState);