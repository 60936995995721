import React, { Component } from 'react';
import './ImportButtons.scss';
import {csvToolTip} from "../../../interface/constant";
import AddToolTip from "../../Common/AddToolTip";

class ImportButtons extends Component {
	render() {
		return (
			<div className="csv-btn-group">
				<div className="csv-group-name">
					{this.props.title}
					<AddToolTip text={csvToolTip[this.props.name]} direction={"right"}/>
				</div>
				<label
					className={"csv-import-btn " + this.props.btnHide}
					htmlFor={`csvImport-${this.props.name}`}>CSVインポート
				</label>
				<label
					className="csv-export-btn"
					htmlFor={`csvExport-${this.props.name}`}>CSVエクスポート
				</label>
				<label
					className="csv-template-btn"
					htmlFor={`csvTemplate-${this.props.name}`}>雛形ダウンロード
				</label>
				<div className={"hide hidden-input-files"}>
					<input
						type={"file"}
						id={`csvImport-${this.props.name}`}
						data-type={'csvImport'}
						name={this.props.name}
						title={this.props.title}
						accept={".csv"}
						onChange={this.props.clickEvent.import}
					/>
					<input
						type={"button"}
						id={`csvExport-${this.props.name}`}
						data-type={'csvExport'}
						name={this.props.name}
						onClick={this.props.clickEvent.export}
					/>
					<input
						type={"button"}
						id={`csvTemplate-${this.props.name}`}
						data-type={'csvTemplate'}
						name={this.props.name}
						onClick={this.props.clickEvent.template}
					/>
				</div>
			</div>
		)
	}
}

export default ImportButtons;