import React, { Component } from 'react';
import './App.scss';
import Menu from "../containers/Menu/Menu";
import Contents from "../containers/Contents/Contents";
import Loading from "../containers/Common/Loading";
import SocketClient from "./Common/SocketClient";

const socket = new SocketClient();

class App extends Component {
	render() {
		return (
			<>
				<Menu socket={socket}/>
				<Contents socket={socket}/>
				<Loading/>
			</>
		);
	}
}

export default App;