import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import defaultPageImagePath from "../../../../images/no-image.jpg";

const mapStateToProps = (state) => {
	const {editor} = state.current;
	return {
		scale: editor.scale,
		pages: editor.pages,
		view: state.metaData.view,
		repoPage: state.repo.page,
		isBgOn: state.current.editor.isBgOn,
		dummyList: state.metaData.dummyList,
	}
};

const mapDispatchToProps = {};

class BackgroundLayer extends Component {
	getPageImagePath(pageNum) {
		const pageInfo = this.props.repoPage[pageNum];
		return (pageInfo && pageInfo.hasOwnProperty("path")) ? "/image/page" + pageInfo.path : defaultPageImagePath;
	};

	render() {
		const width = this.props.view.width * this.props.scale;
		const height = this.props.view.height * this.props.scale;
		return (
			<div id="BackgroundLayer" className={this.props.isBgOn ? "" : "hide"}>
				<div className={"background-half"} style={{width: width/2, height}}>
					<img className={"left-bg"} src={this.getPageImagePath(this.props.pages.left)} alt="left" />
				</div>
				<div className={"background-half"} style={{width: width/2, height}}>
					<img className={"right-bg"} src={this.getPageImagePath(this.props.pages.right)} alt="right"/>
				</div>
			</div>
		)
	}
}

BackgroundLayer.defaultProps = {
	scale: 1,
	pages: {
		left: 0,
		right: 0,
	},
	view: {
		width: 0,
		height: 0,
	},
	imageMap: {},
};

BackgroundLayer.propTypes = {
	scale: PropTypes.number.isRequired,
	pages: PropTypes.shape({
		left: PropTypes.number.isRequired,
		right: PropTypes.number.isRequired,
	}),
	view: PropTypes.shape({
		width: PropTypes.number.isRequired,
		height: PropTypes.number.isRequired,
	}),
	imageMap: PropTypes.object
};
export default connect(mapStateToProps, mapDispatchToProps)(BackgroundLayer);
