import React, { Component } from 'react';
import { connect } from 'react-redux'

import { changeRectData } from '../../../../reducers/CombineReducers/pageData';
import { setSelectPopupTarget, unsetSelectPopupTarget } from '../../../../reducers/CombineReducers/current';

import Rect from '../../../../components/Contents/Editor/Panels/EditorPanel/Rect';
import { keyModel, keyAction } from "../../../../interface/constant";
import { objectModeConst } from '../../../../interface/constant';

const mapStateToProps = (state) => {
	const {editor} = state.current;
	const objMode = objectModeConst[editor.objectMode];
	let {id, page} = editor.selected;
	let prop = {};
	if (id && page) {
		let objectInfo = state.pageData[page].objects[objMode];
		let item = objectInfo.filter((item) => item.originId === id)[0];
		prop = {
			item,
			pages: state.current.editor.pages,
			view: state.metaData.view,
			selectedId: id,
			selectedPage: page,
			selectedPopupId: editor.selected.popupId,
			scale: editor.scale,
			leftObj: state.pageData[editor.pages.left].objects[objMode] || [],
			rightObj: state.pageData[editor.pages.right].objects[objMode] || [],
			objectMode: editor.objectMode,
			pageViewType: state.current.editor.pageViewType,
			objMode,
		};
	}
	return prop;
};

const mapDispatchToProps = {
	changeRectData,
	setSelectPopupTarget,
	unsetSelectPopupTarget,
};

const {ORDER_CHANGE, ORDER_CANCEL, ORDER_DELETE} = keyAction;

let keyDownFunc = null;

class PopupLayer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModalDelete: false,
			isResizing: false,
		};
		this.dragStartData = null;
		this.deleteProcess = null;
		this.rerender = false;
	}

	componentDidMount() {
		keyDownFunc = (e) => this.keyEvent().onKeyDown(e);
		document.addEventListener(
			"keydown",
			keyDownFunc,
			false
		);
	}

	componentWillUnmount() {
		document.removeEventListener(
			"keydown",
			keyDownFunc,
			false
		);
	}

	keyEvent() {
		return {
			onKeyDown: (e) => {
				const {selectedPopupId} = this.props;
				if (e.keyCode >= 112 && e.keyCode <= 123) return;	//「F1～F12」KeyならEventを実行しない
				e.preventDefault();
				let which = e.keyCode + (e.shiftKey ? "+shift" : (e.ctrlKey ? "+ctrl" : (e.altKey ? "+alt" : "")));
				if (!Object.keys(keyModel).includes(which)) return;
				let {order, target, val} = keyModel[which];

				if (!selectedPopupId) return;

				if (order === ORDER_CHANGE) {
					this.cssChangeKey(target, val);
				} else if (order === ORDER_CANCEL) {
					this.props.unsetSelectPopupTarget();
				} else if (order === ORDER_DELETE) {
					this.deletePopupTarget();
				}
			}
		}
	}

	cssChangeKey(target, val) {
		const idx = this.props.item.data.popup.css.findIndex((item) => item.id === this.props.selectedPopupId);
		const popupCss = this.props.item.data.popup.css;
		popupCss[idx] = {
			...popupCss[idx],
			[target]: popupCss[idx][target] + val,
		};
		let popupData = {
			popup: {
				...this.props.item.data.popup,
				css: popupCss,
			}
		};
		this.props.changeRectData({
			id: this.props.selectedId,
			page: this.props.selectedPage,
			data: popupData,
			objMode: this.props.objMode,
		});
	}

	deletePopupTarget() {
		const idx = this.props.item.data.popup.css.findIndex((item) => item.id === this.props.selectedPopupId);
		const popupCss = Object.assign([], this.props.item.data.popup.css);
		popupCss.splice(idx, 1);
		let popupData = {
			popup: {
				...this.props.item.data.popup,
				css: popupCss,
			}
		};

		if (popupCss.length === 0) delete popupData.popup.css;

		this.props.changeRectData({
			id: this.props.selectedId,
			page: this.props.selectedPage,
			data: popupData,
			objMode: this.props.objMode,
		});

		this.props.unsetSelectPopupTarget();
	}

	dragEvent(page, id, width, scale) {
		return {
			onDragStart: (e, data) => {
				e.stopPropagation();
				this.dragStartData = data;
				if (id !== this.props.selectedId) this.props.setSelectPopupTarget({page, popupId: id});
			},
			onDrag: (e, data) => {
				e.stopPropagation();
			},
			onDragStop: (e, data) => {
				e.stopPropagation();
				const xDiff = Math.abs(this.dragStartData.x - data.x);
				const yDiff = Math.abs(this.dragStartData.y - data.y);
				if (xDiff < 1 && yDiff < 1) return;
				let css = {
					top: Math.round(data.y / scale * 100) / 100,
					left: Math.round((data.x - width) / scale * 100) / 100,
				};

				const idx = this.props.item.data.popup.css.findIndex((item) => item.id === this.props.selectedPopupId);
				const popupCss = this.props.item.data.popup.css;
				popupCss[idx] = {
					...popupCss[idx],
					top: css.top,
					left: css.left,
				};
				let popupData = {
					popup: {
						...this.props.item.data.popup,
						css: popupCss,
					}
				};
				this.props.changeRectData({
					id: this.props.selectedId,
					page: this.props.selectedPage,
					data: popupData,
					objMode: this.props.objMode,
				});

				this.dragStartData = {};
			},
		}
	};

	resizeEvent(page, id, scale) {
		return {
			onResizeStart: e => {
				e.stopPropagation();
				this.setState({
					...this.state,
					isResizing: true,
				});
			},
			onResize: (e, dir, ref) => {
				e.stopPropagation();
			},
			onResizeStop: (e, dir, ref, delta, position) => {
				e.stopPropagation();
				let {width, height} = ref.style;
				const isRightPage = this.props.selectedPage === this.props.pages.right;
				const addWidth = isRightPage ? this.props.view.width / 2 : 0;
				let css = {
					width: Math.round(parseFloat(width) / scale * 100) / 100,
					height: Math.round(parseFloat(height) / scale * 100) / 100,
					top: Math.round(parseFloat(position.y) / scale * 100 / 100),
					left: Math.round(parseFloat(position.x) / scale * 100 / 100) - addWidth,
				};
				const idx = this.props.item.data.popup.css.findIndex((item) => item.id === this.props.selectedPopupId);
				const popupCss = this.props.item.data.popup.css;
				popupCss[idx] = {
					...popupCss[idx],
					width: css.width,
					height: css.height,
					top: css.top,
					left: css.left,
				};
				let popupData = {
					popup: {
						...this.props.item.data.popup,
						css: popupCss,
					}
				};
				this.props.changeRectData({
					id: this.props.selectedId,
					page: this.props.selectedPage,
					data: popupData,
					objMode: this.props.objMode,
				});

				this.setState({
					...this.state,
					isResizing: false,
				});
			},
		};
	}

	rectElem(width, page, item) {
		const scale = this.props.scale;
		const selected = (this.props.selectedPopupId === item.id) ? " selected" : "";
		let zIndex = 0;
		return (
			<Rect
				key={item.id}
				dragEvent={this.dragEvent(page, item.id, width, scale)}
				resizeEvent={this.resizeEvent(page, item.id, scale)}
				item={item}
				bounds={"#PopupLayer"}
				isRectInActivePage={true}
				position={{
					x: item.left * scale + width,
					y: item.top * scale,
				}}
				size={{
					width: item.width * scale,
					height: item.height * scale
				}}
				scale={scale}
				canDragging={true}
				canResizing={true}
				addClass={" popup"}
				selected={selected}
				zIndex={zIndex}
				isResizing={this.state.isResizing}
			/>
		);
	}

	onMouseDown() {
		this.props.unsetSelectPopupTarget();
	}

	getObjects(obj) {
		const idx = obj.findIndex((item) => item.originId === this.props.selectedId);
		return (idx >= 0 && obj[idx].data.popup.css) ? obj[idx].data.popup.css : [];
	}

	render() {
		const {leftObj, rightObj, pageViewType} = this.props;
		const widthHalf = this.props.view.width * this.props.scale / 2;

		const {left: leftPage, right: rightPage} = this.props.pages;
		const leftObjects = this.getObjects(leftObj);
		const rightObjects = this.getObjects(rightObj);

		return (
			<div
				id="PopupLayer"
				onMouseDown={this.onMouseDown.bind(this)}
			>
				<div className={"leftHideLayer" + (pageViewType !== "right" ? " hide" : "")}/>
				<div className={"rightHideLayer" + (pageViewType !== "left" ? " hide" : "")}/>
				{leftObjects.map(item => this.rectElem(0, leftPage, item))}
				{rightObjects.map(item => this.rectElem(widthHalf, rightPage, item))}
			</div>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupLayer);
