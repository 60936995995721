import React, { Component } from 'react';
import { connect } from 'react-redux'
import { changeRectOptions, changeGroupOnMultiItem } from "../../../reducers/CombineReducers/pageData";
import { randomStr } from "../../../interface/utils";
import GroupEdit from "../../../components/Contents/Editor/Panels/GroupPanel/GroupEdit";
import PropTypes from "prop-types";

const mapStateToProps = state => {

	const {left, right} = state.current.editor.pages;
	const getGroupObj = (item) => item.options.hasOwnProperty("group");

	let chapter = Object.values(state.metaData.chapter) || [];
	let chapterArr = chapter.map((item) => item.pages);
	let chapterData = chapterArr.find((item) => item.includes(left) || item.includes(right));
	if(!chapterData) chapterData = [left, right];

	let groupObject = [];
	for(let page of chapterData) {
		let pageObj = state.pageData[page].objects.subView;
		groupObject = groupObject.concat(pageObj.filter(getGroupObj));
	}
	return {
		pageData: state.pageData,
		page: state.current.editor.pages,
		groupObject,
	}
};

const mapDispatchToProps = {
	changeRectOptions,
	changeGroupOnMultiItem
};

class GroupList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			groupList: {},
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if(nextProps === prevState.prevProps) return null; // for avoid react bug
		let groupList = {};

		for (let key in prevState.groupList) {
			groupList[key] = [];
		}
		for (let item of nextProps.groupObject) {
			let group = item.options.group;
			if (!group.hasOwnProperty("name") || !group.hasOwnProperty("index")) continue;
			if (!groupList[group.name]) groupList[group.name] = [];
			groupList[group.name].push({
				originId: item.originId,
				id: item.id,
				name: group.name,
				idx: group.index,
				page: item.page,
			});
			groupList[group.name].sort((a, b) => {
				if (a.idx > b.idx) {
					return 1;
				} else if (a.idx < b.idx) {
					return -1;
				}
				return 0;
			});
		}
		return {groupList, prevProps: nextProps};
	}

	addNewGroup() {
		this.setState({
			groupList: {
				...this.state.groupList,
				[`group_${randomStr(6)}`]: [],
			}
		})
	}

	groupEditElem() {
		const groupListKey = Object.keys(this.state.groupList);
		if (groupListKey.length <= 0) {
			return (<div className={"no-group-list"}>追加してください。</div>)
		} else {
			return groupListKey.map((itemKey, idx) => {
				return (
					<GroupEdit
						key={idx}
						id={itemKey}
						index={idx}
						item={this.state.groupList[itemKey]}
						page={this.props.page}
						addGroupOnList={this.addGroupOnList.bind(this)}
						swapGroup={this.swapGroup.bind(this)}
						deleteGroup={this.deleteGroup.bind(this)}
						deleteItem={this.deleteItem.bind(this)}
						changingGroupName={this.changeGroupName.bind(this)}
						changedGroupName={this.changedGroupName.bind(this)}
					/>
				)
			})
		}
	}

	changeGroupName(before, after) {
		let key = Object.keys(this.state.groupList);
		if (after === "" || key.includes(after)) return;

		let idx = key.findIndex(item => item === before);
		key[idx] = after;

		const groupValue = Object.values(this.state.groupList);
		let newGroupList = {};
		for (let i = 0; i < key.length; i++) {
			newGroupList[key[i]] = groupValue[i];
		}
		this.setState({groupList: newGroupList});
	}

	changedGroupName(key) {
		this.props.changeGroupOnMultiItem({
			updated: this.getFlatGroup(key, this.state.groupList[key]),
			deleted: [],
		})
	}

	swapGroup(key, dragIdx, hoverIdx) {
		const dragItemList = [...this.state.groupList[key]];
		[dragItemList[dragIdx], dragItemList[hoverIdx]] = [dragItemList[hoverIdx], dragItemList[dragIdx]];
		this.setState({
			groupList: {
				...this.state.groupList,
				[key]: dragItemList,
			}
		});
		this.props.changeGroupOnMultiItem({
			updated: this.getFlatGroup(key, this.state.groupList[key]),
			deleted: [],
		})
	}

	getFlatGroup(key, group) {
		let groupArr = [];
		for (let i = 0; i < group.length; i++) {
			groupArr.push({
				groupName: key,
				groupIndex: i + 1,
				originId: group[i].originId,
				id: group[i].id,
				page: group[i].page,
			})
		}
		return groupArr;
	}

	findItem(key, originId) {
		return this.state.groupList[key].find(item => item.originId === originId);
	}

	addGroupOnList(key, item) {
		const groupList = this.state.groupList;
		const isItemExist = Object.values(groupList).flat().some((obj) => obj.originId === item.originId);
		if (isItemExist) return;

		let subViewObj = this.props.pageData[item.page].objects.subView;
		let objIdx = subViewObj.findIndex((obj) => item.originId === obj.originId);

		let options = {...subViewObj[objIdx].options};
		this.props.changeRectOptions({
			id: item.originId,
			page: item.page,
			objMode: "subView",
			options: {
				...options,
				group: {
					name: key,
					index: groupList[key].length + 1,
				}
			}
		});
	}

	deleteGroup(key) {
		let groupList = this.getFlatGroup(key, this.state.groupList[key]);
		this.props.changeGroupOnMultiItem({
			updated: [],
			deleted: groupList,
		});
		const stateGroupList = this.state.groupList;
		delete stateGroupList[key];
		this.setState({
			groupList: stateGroupList,
		})
	}

	deleteItem(key, originId) {
		let filtered = this.state.groupList[key].filter(item => item.originId !== originId);
		const item = this.findItem(key, originId);
		let groupList = this.getFlatGroup(key, filtered);
		this.props.changeGroupOnMultiItem({
			updated: groupList,
			deleted: [{
				originId: item.originId,
				page: item.page,
			}]
		})
	}

	render() {
		return (
			<div className={"inner-group-list"}>
				<div className={"group-list"}>
					{this.groupEditElem()}
				</div>
				<div className={"btn-container"}>
					<div className={"btn-gray add-btn"} onClick={this.addNewGroup.bind(this)}>追加</div>
				</div>
			</div>
		);
	}
}

GroupList.defaultProps = {
	pageData: {},
	groupObject: [],
};
GroupList.propTypes = {
	pageData: PropTypes.object.isRequired,
	groupObject: PropTypes.array.isRequired,
	changeRectOptions: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupList);
