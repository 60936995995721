import React, { Component } from 'react';

import "./EditBookLog.scss";
import {getLog} from "../../../interface/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class EditBookLog extends Component{
	constructor(props) {
		super(props);
		let today = new Date();
		let year = today.getFullYear();
		let month = today.getMonth();
		let dates = today.getDate()+1;
		this.state = {
			bookLogTables: [],
			logList: [],
			bookName: '',
			count: 0,
			page: 0,
			StartDate: "",
			EndDate: new Date(),
			selectedStartDate: "",
			selectedEndDate: new Date(year, month, dates, 8,59,59),
			work: '',
		};
	}

	componentDidMount() {
		this.getLogList();
	}

	getLogList = async (bookName = "", page = 0, selectedStartDate = this.state.selectedStartDate, selectedEndDate = this.state.selectedEndDate, work=this.state.work) =>{
		this.setState({
			page,
		});
		const logList = await getLog(bookName, page, selectedStartDate, selectedEndDate, work) || [];
		if (logList && logList.data  && logList.bookLogTables) {
			return this.setLogListToState(logList.data, logList.bookLogTables, bookName, logList.count);
		} else {
			return this.setLogListToState([], [], bookName, 0);
		}
	};

	// DB에서 불러온 유저목록 현재 state에 저장
	setLogListToState(logList, bookLogTables, bookName, count) {
		this.setState({
			...this.state,
			bookLogTables: bookLogTables,
			logList: logList,
			bookName: bookName,
			count: count
		})
	}

	bookChange(e) {
		this.getLogList(e.target.value);
	}

	changePage(page) {
		this.getLogList(this.state.bookName,page);
	}

	pageElem () {
		const PAGE_PER_COUNT = 10; // 한번에 나타낼 최대 페이지 수
		const totalData = this.state.count;
		const totalPage = Math.ceil(totalData / 100); // 총 페이지 수

		const currentMaxPage = (Math.floor(this.state.page / PAGE_PER_COUNT) + 1) * PAGE_PER_COUNT; // 현재페이지 기준에서 최대 페이지 번호(ex: 3페이지일경우 10, 27페이지일경우 30)
		const pageCount = currentMaxPage <= totalPage ? PAGE_PER_COUNT : totalPage % PAGE_PER_COUNT; // 현재페이지 기준에서 표시할 페이지의 개수(ex: 총 페이지수가 33일때, 현재페이지가 3페이지일경우 10, 31페이지일경우 3)

		const prevHidden = this.state.page === 0 ? "hidden" : "";
		const nextHidden = this.state.page >= totalPage - 1 ? "hidden" : "";

		return (
			<tr className={"page-tr"}>
				<td colSpan={7} className={"page-td"}>
					<span
						className={"page-item " + prevHidden}
						onClick={() => this.changePage(0)}
					>
						&lt;&lt;
					</span>
					<span
						className={"page-item " + prevHidden}
						onClick={() => this.changePage(this.state.page - 1)}
					>
						&lt;
					</span>
				{[...Array(pageCount)].map((page, idx) => {
					const pageNum = idx + (currentMaxPage - PAGE_PER_COUNT);
					const selectedPage = this.state.page === pageNum ? "selected" : "";
					return (
						<span
							key={"page" + pageNum}
							className={"page-item " + selectedPage}
							onClick={() => this.changePage(pageNum)}
						>
							{pageNum + 1}
						</span>
					)
				})}
					<span
						className={"page-item " + nextHidden}
						onClick={() => this.changePage(this.state.page + 1)}
					>
						&gt;
					</span>
					<span
						className={"page-item " + nextHidden}
						onClick={() => this.changePage(totalPage - 1)}
					>
						&gt;&gt;
					</span>
				</td>
			</tr>
		)
	}

	startDate(date) {
		const year = date.getFullYear();
		const month = date.getMonth();
		const dates = date.getDate();
		this.setState({
			StartDate: new Date(year, month, dates),
			selectedStartDate: new Date(year, month, dates, 9)
		});
		this.getLogList(this.state.bookName, 0, new Date(year, month, dates,9), this.state.selectedEndDate);
	}

	endDate(date) {
		const year = date.getFullYear();
		const month = date.getMonth();
		const dates = date.getDate()+1;
		this.setState({
			EndDate: new Date(year, month, dates-1, 0),
			selectedEndDate: new Date(year, month, dates, 8,59,59)
		});
		this.getLogList(this.state.bookName, 0, this.state.selectedStartDate, new Date(year, month, dates, 8,59,59));
	}


	workChange(e) {
		this.setState({
			work: e.target.value
		});
		this.getLogList(this.state.bookName, 0, this.state.selectedStartDate, this.state.selectedEndDate,e.target.value);
	}



	render() {
		const {logList, bookLogTables} = this.state;
		return (
			<div>
				<br/>
				<select className={"select-box margin-right-10"} onChange={(e) => this.bookChange(e)}>
					{bookLogTables.map((table, idx) => {
						const tableName = table.TABLE_NAME;
						return (
							<option key={tableName} value={tableName}>{tableName}</option>
						);
					})}

				</select>

				<select className={"select-box"} onChange={(e) => this.workChange(e)}>
					<option key="" value="">全て</option>
					<option key="クリックポイント" value="クリックポイント">クリックポイント</option>
					<option key="レポジトリ" value="レポジトリ">レポジトリ</option>
					<option key="ノンブル" value="ノンブル">ノンブル</option>
					<option key="チャプタ" value="チャプタ">チャプタ</option>
					<option key="ページ" value="ページ">ページ</option>
					<option key="CSV" value="CSV">CSV</option>
				</select><br/><br/>

				<DatePicker style = {{display: "inline-block"}}
					selected={this.state.StartDate}
					dateFormat="yyyy-MM-dd"
					onChange={(date) => this.startDate(date)}
				/>
				<span className={"between_date"}>～</span>
				<DatePicker style = {{display: "inline-block"}}
					selected={this.state.EndDate}
					dateFormat="yyyy-MM-dd"
					onChange={(date) => this.endDate(date)}
				/>

				<table className={"log-list-table"}>
					<tbody>
					<tr>
						<th className={"idx"}>No</th>
						<th className={"userId"}>ユーザー</th>
						<th className={"page"}>ページ</th>
						<th className={"type"}>操作</th>
						<th className={"action"}>アクション</th>
						<th className={"objectId"}>オブジェクトID</th>
						<th className={"regDate"}>日付</th>
					</tr>
					{logList.map((logData, idx) => {
						return (
							<tr key={idx}>
								<td>{logData.idx}</td>
								<td>{logData.userId}</td>
								<td>{logData.page}</td>
								<td>{logData.type}</td>
								<td>{logData.action}</td>
								<td>{logData.objectId}</td>
								<td>{logData.regDate}</td>
							</tr>
						);
					})}
					{this.pageElem()}
					</tbody>
				</table>

			</div>
		)
	}
}

export default EditBookLog;