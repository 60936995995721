import * as React from "react";
// @ts-ignore
import Modal from "../../../../../Common/Modal"
// @ts-ignore
import {upload} from "../../../../../../interface/api";
// @ts-ignore
import {modalTitle, modalMsg, repoUpload} from "../../../../../../interface/constant";

interface Props {
	type: string,

	id: string,

	bookId: string,

	hideDelete: string,

	onChange(type: string, id: string, path: string, originalname: string): void,

	onDelete(type: string, id: string): void,
}

interface State {
	showChangeModal: boolean,
	showDeleteModal: boolean,
}

export default class ColumnBtn extends React.Component<Props, State> {

	modalConfirm: (e: React.MouseEvent<HTMLInputElement>) => void;
	modalCancel: (e: React.MouseEvent<HTMLInputElement>) => void;

	constructor(props: Props) {
		super(props);

		this.state = {
			showChangeModal: false,
			showDeleteModal: false,
		};
		this.modalConfirm = () => {
		};
	}

	onClickUpdate(e: React.ChangeEvent<HTMLInputElement>) {
		const target = e.currentTarget;
		const form = e.target.parentElement;
		this.modalConfirm = async (e: React.MouseEvent<HTMLInputElement>) => {
			let pathInfo = await upload(form);
			const { path, originalname } = pathInfo;
			this.props.onChange(this.props.type, this.props.id, path, originalname);
			target.value = "";
			this.setState({showChangeModal: false})
		};
		this.modalCancel = (e: React.MouseEvent<HTMLInputElement>) => {
			target.value = "";
			this.setState({showChangeModal: false})
		};
		this.setState({
			showChangeModal: true,
		});
	}

	onClickDelete(e: React.MouseEvent<HTMLInputElement>) {
		this.modalConfirm = async (e: React.MouseEvent<HTMLInputElement>) => {
			this.props.onDelete(this.props.type, this.props.id);
			this.setState({showDeleteModal: false})
		};
		this.modalCancel = (e: React.MouseEvent<HTMLInputElement>) => {
			this.setState({showDeleteModal: false})
		};
		this.setState({
			showDeleteModal: true,
		});
	}

	render(): React.ReactElement<React.JSXElementConstructor<any>> {
		return (
			<div className="repo-column-buttons-area">

				<div className={"repo-column-button"}>
					<label htmlFor={"overwrite_" + this.props.id}>差し替え</label>
				</div>

				<input
					className={"repo-column-button delete-button " + this.props.hideDelete}
					data-id="delete" type="button" value="削除"
					onClick={this.onClickDelete.bind(this)}
				/>

				<div className={"hidden-forms hide"}>
					<form method={"post"} encType={"multipart/form-data"}>
						<input type="hidden" value={this.props.bookId} name="bookId"/>
						<input
							type="file" name="file" id={"overwrite_" + this.props.id}
							accept={repoUpload[this.props.type]}
							onChange={this.onClickUpdate.bind(this)}
						/>
					</form>
				</div>

				{this.state.showChangeModal && <Modal
					onClickCancel={this.modalCancel}
					onClickConfirm={this.modalConfirm}
					msg={modalMsg.OVERWRITE}
					title={modalTitle.WARNING}
				/>}
				{this.state.showDeleteModal && <Modal
					onClickCancel={this.modalCancel}
					onClickConfirm={this.modalConfirm}
					msg={modalMsg.DELETE}
					title={modalTitle.WARNING}
				/>}
			</div>
		)
	}
}