import React, {Component} from 'react';
import PropTypes from "prop-types";

import {
    dataModeConst,
    propertyInputLabel,
    propertyInputName,
    svgPreset,
} from '../../../../../interface/constant';
import {getSVGModel} from "../../../../../interface/dataModel";

class SvgSubView extends Component {
    svgChange(e) {
        const value = e.target.value;
        // this.setState({svg: value});
        let svg = this.props.item.svg;
        if (this.checkSVGPointsValid(value)) {
            this.props.changeRectDataSVG({
                id: this.props.id,
                page: this.props.page,
                blockId: this.props.blockId,
                subViewType: this.props.subViewType,
                data: {
                    svg: {
                        type: svg.type,
                        attr: {points: value}
                    }
                }
            });
        }
    }

    svgTypeChange(e) {
        const type = e.target.value;
        let typeBefore = this.props.item.svg.type;
        let attr = this.props.item.svg.attr;
        if (type !== typeBefore) {
            let newAttr = (typeBefore === "polygon") ? this.svgPolygonToPath(attr) : this.svgPathToPolygon(attr);
            this.props.changeRectDataSVG({
                id: this.props.id,
                page: this.props.page,
                blockId: this.props.blockId,
                subViewType: this.props.subViewType,
                data: {
                    svg: {
                        type: type,
                        attr: newAttr
                    }
                }
            });
        }
    }

    svgPathToPolygon(attr) {
        let regexp = /([a-yA-Y]+\s?[-]?\d+(\.\d+)?\s?[-]?\d+(\.\d+)?)/g;
        let found = attr.d.match(regexp);
        let newPoints = found.map((item) => {
            return item.replace(/[a-zA-Z]\s?/g, '').replace(" ", ",");
        }).join(" ");
        return {
            points: this.isValidPolygonPoints(newPoints),
        }
    }

    svgPolygonToPath(attr) {
        let points = attr.points.split(' ');
        let newD = points.map((p, index) => {
            let com = (index === 0) ? "M" : "L";
            return `${com} ${p.replace(",", " ")}`;
        }).join(" ") + " L " + points[0].replace(",", " ") + " Z";
        return {
            d: this.isValidPathD(newD),
        }
    }

    isValidPolygonPoints(points) {
        let def = "0,0 100,0 100,100 0,100";
        return this.checkSVGPointsValid(points) ? points : def;
    }

    isValidPathD(d) {
        let def = "M 0 0 L 100 0 L 100 100 L 0 100 L 0 0 Z";
        return this.checkSVGPathDValid(d) ? d : def;
    }

    changeSVGtoPreset(e) {
        let points = e.currentTarget.getAttribute("data-points");
        const data = {
            svg: {
                type: "polygon",
                attr: {points},
            }
        };

        this.props.changeRectDataSVG({
            id: this.props.id,
            page: this.props.page,
            blockId: this.props.blockId,
            data: data,
            subViewType: this.props.subViewType,
        });
    }

    svgPolygonElem() {
        const item = this.props.item;
        if (item.hasOwnProperty("svg")) {
            return (
                <>
                    <tr>
                        <td className="table-key"/>
                        <td className="table-value">
                            <div className="svg-preset-container">
                                {svgPreset.map((points, idx) => {
                                    return (
                                        <div className="svg-preset-btn"
                                             data-points={points}
                                             onClick={this.changeSVGtoPreset.bind(this)}
                                             key={`svg-preset-${idx}`}
                                        >
                                            <svg
                                                width="80%"
                                                height="80%"
                                                viewBox="0 0 100 100"
                                                preserveAspectRatio="none"
                                            >
                                                <polygon points={points}/>
                                            </svg>
                                        </div>
                                    )
                                })}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="table-key">{propertyInputLabel.svgType}</td>
                        <td className="table-value">
                            <label>
                                <select id="linkLevel"
                                        name={propertyInputName.svgType}
                                        onChange={this.svgTypeChange.bind(this)}
                                        value={item.svg.type}
                                        size={1}
                                >
                                    <option value="polygon">Polygon</option>
                                    <option value="path">Path</option>
                                </select>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td className="table-key">{propertyInputLabel.points}</td>
                        <td className="table-value">
                            <label>
					            <textarea
                                    className={"subview-svg-textarea"}
                                    name={propertyInputName.points}
                                    value={item.svg.attr.points}
                                    onChange={this.svgChange.bind(this)}/>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td className="table-key"/>
                        <td className="table-value">
                            <label className={"btn-label"}>
                                <input type={"button"} className={"upload-btn"} onClick={this.editSVG.bind(this)} value={"編集"}/>
                            </label>
                        </td>
                    </tr>
                </>
            )
        }
    }

    svgPathElem() {
        const item = this.props.item;
        if (item.hasOwnProperty("svg")) {
            return (
                <>
                    <tr>
                        <td className="table-key">{propertyInputLabel.svgType}</td>
                        <td className="table-value">
                            <label>
                                <select id="linkLevel"
                                        name={propertyInputName.svgType}
                                        onChange={this.svgTypeChange.bind(this)}
                                        value={item.svg.type}
                                        size={1}
                                >
                                    <option value="polygon">Polygon</option>
                                    <option value="path">Path</option>
                                </select>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td className="table-key">{propertyInputLabel.d}</td>
                        <td className="table-value">
                            <label>
					            <textarea
                                    className={"subview-svg-textarea"}
                                    name={propertyInputName.d}
                                    value={item.svg.attr.d}
                                    onChange={this.svgChange.bind(this)}/>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td className="table-key"/>
                        <td className="table-value">
                            <label className={"btn-label"}>
                                <input type={"button"} className={"upload-btn"} onClick={this.editSVG.bind(this)} value={"編集"}/>
                            </label>
                        </td>
                    </tr>
                </>
            )
        }
    }

    editSVG() {
        this.props.togglePanel({
            name: dataModeConst.EDIT_SUBVIEW_SVG,
            isOpen: !this.props.panel[dataModeConst.EDIT_SUBVIEW_SVG],
        });
    }

    onChangeSVG(e) {
        let svg = null;
        if (e.target.checked) svg = getSVGModel();

        const data = svg ? {svg: svg} : {};

        this.props.changeRectDataSVG({
            id: this.props.id,
            page: this.props.page,
            blockId: this.props.blockId,
            data: data,
            subViewType: this.props.subViewType,
        });
    }

    /**
     * check string type matched svg polygon. e.g "0,0 1,99 99,25 ..."
     * @param {string} points - inputted svg points
     * @returns {boolean} - is matched
     */
    checkSVGPointsValid(points) {
        return /^([-]?\d+,[-]?\d+\s)+([-]?\d+,[-]?\d+)$/.test(points);
    }

    checkSVGPathDValid(d) {
        return /^([a-zA-Z]+\s?[-]?\d+(\.\d+)?\s[-]?\d+(\.\d+)?\s?)*Z$/.test(d);
    }

    render() {
        const {item} = this.props;
        return (
            <>
                <tr className={this.props.hide ? "hide" : ""}>
                    <td className="table-key">{propertyInputLabel.svg}</td>
                    <td className="table-value">
                        <div className="switch">
                            <label className="switch_label">
                                <input type="checkbox" className="switch_input" name={propertyInputName.svg}
                                       onChange={this.onChangeSVG.bind(this)}
                                       checked={item.hasOwnProperty("svg")}
                                />
                                <span className="switch_content"/>
                                <span className="switch_circle"/>
                            </label>
                        </div>
                    </td>
                </tr>
                {(item.svg && item.svg.type !== "path") ? this.svgPolygonElem() : this.svgPathElem()}
            </>
        )
    }
}

SvgSubView.defaultProps = {
    item: {},
    changeRectDataSVG: null,
    togglePanel: null,
    id: "",
    page: 0,
    objMode: "",
    panel: {},
    mode: "",
    svg: "",
};

SvgSubView.propTypes = {
    item: PropTypes.object.isRequired,
    changeRectDataSVG: PropTypes.func.isRequired,
    togglePanel: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    page: PropTypes.number.isRequired,
    objMode: PropTypes.string.isRequired,
    panel: PropTypes.object.isRequired,
    mode: PropTypes.string.isRequired,
    svg: PropTypes.string.isRequired,
};

export default SvgSubView;