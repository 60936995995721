import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";

import {
	TextProperty,
	SelectProperty,
} from "../../../../components/Contents/Editor/Panels/PropertyPanel/innerProperty";
import { changeBookData, changeRectId, changeRectPage, } from '../../../../reducers/CombineReducers/pageData';
import {objectModeConst, propertyInputLabel, propertyInputName, propertyToolTip} from '../../../../interface/constant';
import { setSelectItem } from "../../../../reducers/CombineReducers/current";
import PosSizeProperty from "../../../../components/Contents/Editor/Panels/PropertyPanel/posSizeProperty";

const mapStateToProps = (state) => {
	try {
		const objMode = objectModeConst[state.current.editor.objectMode];
		let {id, page} = state.current.editor.selected;
		let objectInfo = state.pageData[page].objects[objMode];
		let item = objectInfo.filter((item) => item.originId === id)[0];
		return {
			item,
			css: item.css,
			pages: state.current.editor.pages,
			bookChanged: state.current.book.changed,
			view: state.metaData.view,
			direction: state.metaData.direction,
			id,
			page,
			objMode
		};
	} catch (e) {
		return {item: {}, css: {}, page: "", id: ""};
	}
};

const mapDispatchToProps = dispatch => {
	return {
		changeBookData: (state) => dispatch(changeBookData(state)),
		changeRectId: (state) => dispatch(changeRectId(state)),
		changeRectPage: (state) => dispatch(changeRectPage(state)),
		setSelectItem: (state) => dispatch(setSelectItem(state)),
	}
};

let property = {
	currentId: "",
	changedId: "",
	page: 0,
	objMode: "",
};

class RedLineTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: "",
			propertyCss: {
				width: "",
				height: "",
				top: "",
				left: "",
			},
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps === prevState.prevProps) return null;
		const propertyCss = RedLineTable.arrangeCss(prevState.propertyCss, nextProps.item.css);
		return {propertyCss}
	}

	static arrangeCss(prevCss, nextCss) {
		return {
			...nextCss,
			width: nextCss ? prevCss.width === "" ? prevCss.width : nextCss.width : "",
			height: nextCss ? prevCss.height === "" ? prevCss.height : nextCss.height: "",
			top: nextCss ? prevCss.top === "" ? prevCss.top : nextCss.top: "",
			left: nextCss ? prevCss.left === "" ? prevCss.left : nextCss.left: "",
		}
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.props.item.id !== prevProps.item.id) {
			const id = this.props.item.id || "";
			const propertyCss = this.props.item.css;
			this.setState({
				id: id,
				propertyCss,
			})
		}
	}

	eventHandler() {
		return {
			onChange: (e) => {
				this.setState({
					...this.state,
					propertyCss: {
						...this.state.propertyCss,
						[e.target.name]: e.target.value,
					}
				});

				const value = e.target.value || 0;
				this.props.changeBookData({
					id: this.props.id,
					page: this.props.page,
					css: {
						[e.target.name]: (e.target.name !== 'opacity' ? parseInt(value, 10) : Number(value))
					},
					objMode: this.props.objMode
				});
			},
			pageChange: (e) => {
				let page = Number(e.target.value);
				this.props.changeRectPage({
					id: this.props.id,
					page: page,
					width: this.props.view.width / 2,
					objMode: this.props.objMode,
					direction: this.props.direction,
				});
				this.props.setSelectItem({
					id: this.props.id,
					page: page
				});
			},
		}
	}

	setPropertyId(e) {
		property.currentId = this.props.id;
		property.changedId = e.target.value;
		property.page = this.props.page;
		property.objMode = this.props.objMode;
	}

	changeId(e) {
		this.setState({
			id: e.target.value
		});
		property.changedId = e.target.value;
	}

	changePageDataId() {
		let {currentId, changedId, page, objMode} = property;
		this.props.changeRectId({
			currentId: currentId,
			changedId: changedId,
			page: page,
			objMode: objMode
		});
	}

	render() {
		const {id, page, pages} = this.props;
		const pageInfoOptions = this.props.pages ? [
			{val: pages.left, name: pages.left},
			{val: pages.right, name: pages.right}
		] : [];

		return (
			<div className="inner-table">
				<table>
					<tbody>
					<TextProperty
						label={propertyInputLabel.id}
						name={propertyInputName.id} val={this.state.id}
						readOnly={false}
						onFocus={this.setPropertyId.bind(this)}
						onBlur={this.changePageDataId.bind(this)}
						onChange={this.changeId.bind(this)}
						tooltipText={propertyToolTip.id}
					/>
					<SelectProperty
						label={propertyInputLabel.page}
						name={propertyInputName.page} options={pageInfoOptions}
						eventHandler={this.eventHandler().pageChange} val={page}
						tooltipText={propertyToolTip.page}
					/>
					<PosSizeProperty css={this.state.propertyCss} eventHandler={this.eventHandler()}/>
					</tbody>
				</table>
				<div className="inner-mask" style={{display: id ? "none" : ""}}/>
			</div>
		)
	}
}

RedLineTable.propTypes = {
	id: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(RedLineTable);