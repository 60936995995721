import React, {Component} from 'react';
import {connect} from "react-redux";
import {SubMenuTable} from "../../../components/Contents/Editor/Panels/SubviewPanel/innerSubview";
import {objectModeConst, subMenuIconOptions} from "../../../interface/constant";
import {changeRectData} from "../../../reducers/CombineReducers/pageData";
import {modifyRepoPath} from "../../../reducers/CombineReducers/repo";
import {fileUpload} from "../../../components/Common/Upload";

const mapStateToProps = state => {
	const {id, page} = state.current.editor.selected;
	const {editor} = state.current;
	const objMode = objectModeConst[editor.objectMode];
	let props = {};
	if (id && page) {
		let objectList = state.pageData[page].objects[objMode];
		const idx = objectList.findIndex((item) => item.originId === id);
		const subViewSettings = editor.objectMode === "subView" ? objectList[idx].data.subViewSettings : null;
		props = {
			selectedId: id,
			selectedPage: page,
			bookId: state.metaData.bookId,
			repo: state.repo,
			subViewSettings,
			idx,
		}
	}
	return props;
};

const mapDispatchToProps = {
	changeRectData,
	modifyRepoPath,
};


class SubMenu extends Component {
	subViewSettingEvent() {
		return {
			changeHeader: (e) => {
				// 헤더 변경
				const data = {
					path: `/html/${this.props.idx}`,
					subViewSettings: {
						...this.props.subViewSettings,
						options: {
							...this.props.subViewSettings.options,
							subMenuTitle: e.target.value,
						},
					}
				};

				this.props.changeRectData({
					id: this.props.selectedId,
					page: this.props.selectedPage,
					data: data,
					objMode: "subView",
				});
			},
			addMenuContents: () => {
				// 메뉴항목 추가
				const {options} = this.props.subViewSettings;
				const contents = {
					thumb: "",
					icon: subMenuIconOptions[0].value, // movie
					text: "テキストを入力してください",
					eventId: "",
					eventPage: 0,
				};

				const data = {
					subViewSettings: {
						...this.props.subViewSettings,
						options: {
							...options,
							subMenuContents: options.subMenuContents ? options.subMenuContents.concat(contents) : [contents],
						},
					}
				};

				this.props.changeRectData({
					id: this.props.selectedId,
					page: this.props.selectedPage,
					data: data,
					objMode: "subView",
				});
			},
			changeSubMenuIcon: (e, idx) => {
				// 아이콘 변경
				let contents = this.props.subViewSettings.options.subMenuContents;
				contents[idx].icon = e.target.value;

				const data = {
					subViewSettings: {
						...this.props.subViewSettings,
						options: {
							...this.props.subViewSettings.options,
							subMenuContents: contents,
						},
					}
				};

				this.props.changeRectData({
					id: this.props.selectedId,
					page: this.props.selectedPage,
					data: data,
					objMode: "subView",
				});
			},
			changeSubMenuText: (e, idx) => {
				// text 변경
				let contents = this.props.subViewSettings.options.subMenuContents;
				contents[idx].text = e.target.value;

				const data = {
					subViewSettings: {
						...this.props.subViewSettings,
						options: {
							...this.props.subViewSettings.options,
							subMenuContents: contents,
						},
					}
				};

				this.props.changeRectData({
					id: this.props.selectedId,
					page: this.props.selectedPage,
					data: data,
					objMode: "subView",
				});
			},
			changeSubMenuEvent: (e, idx) => {
				// event 변경
				let contents = this.props.subViewSettings.options.subMenuContents;
				contents[idx][e.target.name] = e.target.value;

				const data = {
					subViewSettings: {
						...this.props.subViewSettings,
						options: {
							...this.props.subViewSettings.options,
							subMenuContents: contents,
						},
					}
				};

				this.props.changeRectData({
					id: this.props.selectedId,
					page: this.props.selectedPage,
					data: data,
					objMode: "subView",
				});
			},
			deleteMenuContents: (idx) => {
				// 메뉴항목 삭제
				const {options} = this.props.subViewSettings;
				let contents = this.props.subViewSettings.options.subMenuContents;
				contents.splice(idx, 1);

				const data = {
					subViewSettings: {
						...this.props.subViewSettings,
						options: {
							...options,
							subMenuContents: contents,
						},
					}
				};

				this.props.changeRectData({
					id: this.props.selectedId,
					page: this.props.selectedPage,
					data: data,
					objMode: "subView",
				});

				this.props.stateChange("selectSubMenuContentsIdx", -1);
			},
		}
	}

	async SubMenuThumbImageUpload(e, idx) {
		const result = await fileUpload(e, this.props.modifyRepoPath, this.props.bookId, "image", this.props.repo);
		if (!result || !result.fileUpload) {
			this.props.showErrorModal();
			return;
		}

		const {subViewSettings} = this.props;
		let subMenuContents = subViewSettings.options.subMenuContents || [];
		if (subMenuContents.length === 0) return;

		subMenuContents[idx].thumb = result.data.key;

		let data = {
			subViewSettings: {
				...subViewSettings,
				options: {
					...subViewSettings.options,
					subMenuContents: subMenuContents,
				}
			}
		};

		this.props.changeRectData({
			id: this.props.selectedId,
			page: this.props.selectedPage,
			data: data,
			objMode: "subView",
		});
	}

	render() {
		const {subViewSettings, selectedIdx} = this.props;
		const values = {
			titleValue: subViewSettings.options ? subViewSettings.options.subMenuTitle : "",
			iconValue: selectedIdx >= 0 ? subViewSettings.options.subMenuContents[selectedIdx].icon : "",
			textValue: selectedIdx >= 0 ? subViewSettings.options.subMenuContents[selectedIdx].text : "",
			eventIdValue: selectedIdx >= 0 ? subViewSettings.options.subMenuContents[selectedIdx].eventId : "",
			eventPageValue: selectedIdx >= 0 ? subViewSettings.options.subMenuContents[selectedIdx].eventPage : "",
		};
		const selectedMenuClass = selectedIdx >= 0 ? "" : "hide";
		return (
			<SubMenuTable
				event={this.subViewSettingEvent()}
				values={values}
				selectedIdx={selectedIdx}
				selectedMenuClass={selectedMenuClass}
				upload={(e, idx) => this.SubMenuThumbImageUpload(e, idx)}
				bookId={this.props.bookId}
			/>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SubMenu);