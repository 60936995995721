import React, { Component } from 'react';
import propType from 'prop-types';

import "./Rect.scss";
import { Rnd } from 'react-rnd';

export default class Rect extends Component {
	shouldComponentUpdate(nextProps, nextState) {
		return nextProps.selected !== this.props.selected
			|| nextProps.selected === " selected"
			|| nextProps.contents !== this.props.contents
			|| nextProps.scale !== this.props.scale
			|| nextProps.isIdOn !== this.props.isIdOn
			|| this.props.csvImport
			|| this.props.rerender
			|| nextProps.isRectInActivePage !== this.props.isRectInActivePage
			|| nextProps.editorImage !== this.props.editorImage
			|| nextProps.subViewRectClass !== this.props.subViewRectClass;
	}

	setEditorStyle(editorImage) {
		let style = {
			fontSize: "20px",
		};
		if (editorImage && editorImage.path) {
			return style = {
				...style,
				backgroundImage: `url(${editorImage.path})`,
				backgroundRepeat: "no-repeat",
				backgroundSize: "contain",
			};
		}
		return style;
	}

	setSubViewAnswerRectStyle(subViewAnswerRectCss) {
		let style = {};
		if (subViewAnswerRectCss.backgroundImage) {
			style = {
				backgroundImage: `url(${subViewAnswerRectCss.backgroundImage.path})`,
				backgroundRepeat: "no-repeat",
				backgroundSize: "contain",
			}
		} else if (subViewAnswerRectCss.backgroundColor) {
			style = {
				backgroundColor: subViewAnswerRectCss.backgroundColor,
			}
		}
		return style;
	}
	setRndStyle(zIndex) {
		if (!this.props.selected) {
			return {zIndex: zIndex, direction: 'ltr' };
		}
		return {direction: 'ltr'};
	}

	setHandleObject(value) {
		return {
			bottomRight: value,
			bottomLeft: value,
			topRight: value,
			topLeft: value,
		}
	}

	setPolygon(addRectClass, svgColor) {
		const item = this.props.item;
		let style = {};
		const colorCheck = /^#[0-9a-f]{3,6}$/i;	// 컬러코드 유효성 체크 정규식
		if (svgColor && colorCheck.test(svgColor)) {
			style = {
				fill: svgColor,
				fillOpacity: 1,
				opacity: 1,
			};
		}
		if(item.hasOwnProperty("svg")) {
			if(item.svg.type === "polygon") {
				return (
					<polygon vectorEffect="non-scaling-stroke" points={item.svg.attr.points} className={addRectClass} style={style}/>
				);
			} else if(item.svg.type === "path") {
				return (
					<path vectorEffect="non-scaling-stroke" d={item.svg.attr.d} className={addRectClass} style={style}/>
				);
			}
		} else {
			return (
				<polygon vectorEffect="non-scaling-stroke" points="0,0 0,100 100,100 100,0" className={addRectClass} style={style}/>
			);
		}
	}

	componentDidUpdate () {
		const {bounds} = this.props;
		if (bounds !== ".subview-iframe") return;
		this.rnd.updatePosition({ x: this.rnd.props.default.x, y: this.rnd.props.default.y });
	}

	render() {
		const {resizeEvent, dragEvent, editTextEvent} = this.props;
		const {item, selected, editorImage, isRectInActivePage, isForm, defaultFormSize, formValue} = this.props;
		const {position, size, addClass, zIndex, editTextClass=""} = this.props;
		const {canDragging, canResizing} = this.props;
		const {isTextDragMode, dragText} = this.props; // MyEditor Text drag용
		const {subViewScale, subViewRectClass="", subViewRectTextHide=" hide", subViewAnswerRectCss, svgColor=""} = this.props;
		let {bounds, isResizing} = this.props;
		let addRectClass = isRectInActivePage ? "current-page-rect " : "";
		addRectClass += subViewRectClass;
		let style = this.setEditorStyle(editorImage);
		if(isForm) style.fontSize = defaultFormSize * this.props.scale + "px";
		if (subViewAnswerRectCss) {
			style = subViewAnswerRectCss.maskOn ? this.setSubViewAnswerRectStyle(subViewAnswerRectCss) : {};
		}

		if (bounds === ".subview-iframe") {
			bounds = false;
			isResizing = true;
		}

		return (
			<Rnd
				ref={c => { this.rnd = c; }}
				default={Object.assign(position, size)}
				position={isResizing ? null : position}
				size={size}

				onResizeStart={resizeEvent.onResizeStart}
				onResizeStop={resizeEvent.onResizeStop}
				onDragStart={dragEvent.onDragStart}
				onDragStop={dragEvent.onDragStop}
				resizeHandleClasses={this.setHandleObject( this.props.selected ? "handle" : "" )}
				bounds={bounds}
				enableResizing={this.setHandleObject(canResizing)}
				disableDragging={!canDragging}
				style={this.setRndStyle(zIndex)}
				className={selected}
				scale={subViewScale || 1}
			>
				<div
					tabIndex={"0"}
					data-page={item.page}
					data-id={item.id}
					className={"rect object-item" + addClass + selected}
					data-descr={formValue || ""}
					style={style}
				>
					<div
						className={"edit-text " + editTextClass}
						contentEditable={this.props.isTextMode && !isTextDragMode}
						onKeyDown={editTextEvent ? this.props.keyDown : null}
						onKeyUp={editTextEvent ? this.props.keyUp : null}
						onMouseDown={editTextEvent ? editTextEvent.mouseDown : null}
						onClick={editTextEvent ? editTextEvent.editTextSelect : null}
						onBlur={editTextEvent && !isTextDragMode ? editTextEvent.editText : null}
						dangerouslySetInnerHTML={{__html: this.props.text}}
					>
					</div>
					<div className={isTextDragMode && selected ? "edit-text" : "hide"}>
						{dragText ? dragText.map((contents) => {
							return contents;
						}) : ""}
					</div>
					<div className={"subView-rect-in-text" + subViewRectTextHide} style={{fontSize: item.fontSize + "px"}}>
						<div className={item.type}>
							<span className={"data-num"}>{item.dataNum || ""}</span>
							<span className={this.props.rectInTextClass || ""}>{this.props.rectInText || ""}</span>
						</div>
					</div>
					<div className={`object-id ${this.props.isIdOn ? "show" : "hide"}`}>
						<span>{item.id}</span>
					</div>
					<svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
						{this.setPolygon(addRectClass, svgColor)}
					</svg>
				</div>
			</Rnd>
		)
	}
}

Rect.propType = {
	dragEvent: propType.object.isRequired,
	resizeEvent: propType.object.isRequired,
	keyEvent: propType.object,
	item: propType.object.isRequired,
	bound: propType.string.isRequired,
	contents: propType.string,
	isRectInActivePage: propType.bool.isRequired,
	canDragging: propType.bool.isRequired,
	canResizing: propType.bool.isRequired,
	addClass: propType.string,
	selected: propType.oneOf([' selected', '']).isRequired,
	zIndex: propType.number.isRequired,
	editorImage: propType.string,
	isTextMode: propType.bool,
	text: propType.string,
	subViewRectClass: propType.string,
	rectInTextClass: propType.string,
	rectInText: propType.string,
};
