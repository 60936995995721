import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { modalTitle } from "./../../interface/constant";
import "./Modal.scss";
import GrayScreen from "./GrayScreen";

const ModalPortal = ({children}) => {
	const el = document.getElementById('modal');
	return ReactDOM.createPortal(children, el);
};

class Modal extends Component {
	componentDidMount() {
		document.addEventListener(
			"keydown",
			this.keyDownFunc.bind(this),
			false
		);
	}

	componentWillUnmount() {
		document.removeEventListener(
			"keydown",
			this.keyDownFunc.bind(this),
			false
		);
	}

	keyDownFunc(e) {
		e.stopPropagation();
		if(e.keyCode === 27) { // esc
			this.props.onClickCancel ? this.props.onClickCancel(e) : this.props.onClickConfirm(e);
		} else if (e.keyCode === 13 && this.props.onClickConfirm) { // enter
			this.props.onClickConfirm(e);
		}
	}

	confirmBtn() {
		return (
			<input
				type={"button"} value={"確認"}
				className={"btn confirm"}
				onClick={this.props.onClickConfirm}
			/>
		)
	}

	cancelBtn() {
		return (
			<input
				type={"button"}
				value={"キャンセル"}
				className={"btn cancel"}
				onClick={this.props.onClickCancel}
			/>
		)
	};

	keyDown(e) {
		e.nativeEvent.stopImmediatePropagation();
	}

	setPageNumElem() {
		return (
			<>
				<input
					type={"number"}
					name={"startPage"}
					className={"input-page"}
					placeholder={"奇数入力"}
					min={1}
					step={2}
					onKeyDown={this.keyDown.bind(this)}
					onChange={(e) => this.props.setPageNum.changeInput(e)}
				/>
				　～　
				<input
					type={"number"}
					name={"endPage"}
					className={"input-page"}
					placeholder={"偶数入力"}
					min={2}
					step={2}
					onKeyDown={this.keyDown.bind(this)}
					onChange={(e) => this.props.setPageNum.changeInput(e)}
				/>
			</>
		)
	}

	conflictButton() {
		const {overwrite, discard} = this.props.conflictEvent;
		return (
			<>
				<input
					type={"button"}
					value={"破棄"}
					className={"btn"}
					onClick={discard}
				/>
				<input
					type={"button"}
					value={"上書き"}
					className={"btn"}
					onClick={overwrite}
				/>
			</>
		)
	}

	render() {
		return (
			<ModalPortal>
				<GrayScreen>
					<div className={"message-box"}>
						<div className="title-container">
							<div className="title-text">{this.props.title || "タイトール"}</div>
							<div
								className="title-x-btn icon_close"
								onClick={this.props.onClickCancel || this.props.onClickConfirm}>
							</div>
							
						</div>
						<div className="content-container">
							<div className="content-message">
								{this.props.msg.split('<br>').map((it, i) => <div key={`msg_${i}`}>{it}</div>)}
								{this.props.hasOwnProperty("setPageNum") ? this.setPageNumElem() : ""}
							</div>
						</div>
						<div className={"button-container"}>
							{this.props.hasOwnProperty("onClickCancel") ? this.cancelBtn() : ""}
							{this.props.hasOwnProperty("onClickConfirm") ? this.confirmBtn() : ""}
							{this.props.hasOwnProperty("conflictEvent") ? this.conflictButton() : ""}
						</div>
					</div>
				</GrayScreen>
			</ModalPortal>
		);
	}
}

Modal.propTypes = {
	onClickConfirm: PropTypes.func,
	onClickCancel: PropTypes.func,
	title: PropTypes.oneOf(Object.values(modalTitle)),
	msg: PropTypes.string.isRequired,
};

export default Modal;