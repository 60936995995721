import React, { Component } from 'react';
import { connect } from 'react-redux'
import { setSelectItem, unsetSelectItem, addLog  } from '../../../reducers/CombineReducers/current';
import { addRect, removeRect, changeTwoRectOrder } from "../../../reducers/CombineReducers/pageData";
import { modalMsg, modalTitle, objectModeConst, defaultIcon } from '../../../interface/constant';
import { getObjects } from './EditorLayers/ObjectLayer';
import Modal from "../../../components/Common/Modal";
import {now, randomStr} from "../../../interface/utils";

const formSize = {
	width: 17,
	height: 17,
};

const mapStateToProps = state => {
	const {left, right} = state.current.editor.pages;
	const objMode = objectModeConst[state.current.editor.objectMode];
	return {
		mode: state.current.editor.mode,
		leftObjects: state.pageData[left].objects[objMode] || [],
		rightObjects: state.pageData[right].objects[objMode] || [],
		selected: state.current.editor.selected,
		pages: state.current.editor.pages,
		bookChanged: state.current.book.changed,
		objectMode: state.current.editor.objectMode,
		pageViewType: state.current.editor.pageViewType,
		subject: state.metaData.subject,
		objMode,
		auth: state.auth,
	}
};

const mapDispatchToProps = dispatch => {
	return {
		setSelectItem: (state) => dispatch(setSelectItem(state)),
		unsetSelectItem: (state) => dispatch(unsetSelectItem(state)),
		addRect: (state) => dispatch(addRect(state)),
		removeRect: (state) => dispatch(removeRect(state)),
		changeTwoRectOrder: (state) => dispatch(changeTwoRectOrder(state)),
		addLog : (state) => dispatch(addLog(state)),
	}
};

class ListLabel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModalDelete: false,
		};
		this.deleteProcess = null;
	}

	onChange = (e) => {
		const page = Number(e.target[e.target.selectedIndex].getAttribute('data-page'));
		const id = e.target.value;
		if (id !== this.props.selected.id) this.props.setSelectItem({page, id});
	};

	onClick = (e) => {
		const {selected, addRect, objMode, objectMode, pages, subject, pageType} = this.props;
		const name = e.target.getAttribute("name");
		const addPage = pageType === "left" ? pages.left : pages.right;
		const idNumber = randomStr(8);
		const id =  `${addPage}-${idNumber}`;

		let css = null;
		if (["icon", "videoLink"].includes(objectMode)) {
			css = {
				width: defaultIcon.width,
				height: defaultIcon.height
			}
		}
		else if(objectMode === "form") {
			css = {
				width: formSize.width,
				height: formSize.height,
			}
		}

		const isCurrentPage = pages[pageType] === selected.page;
		const currentTime = now();
		switch (name) {
			case "addObject":
				addRect({page: addPage, id, objMode, objectMode, css, subject});
				this.props.addLog({msg:{regDate:`${currentTime}`, userId:`${this.props.auth.info.id}`, page:`${addPage}`, objectId:`${id}`, action: `追加`, type: "クリックポイント"}});
				return;
			case "removeObject":
				if (!selected.id || !isCurrentPage) return;
				this.showDeleteModal(selected.id, selected.page, objMode);
				return;
			case "upObject":
				if (!selected.id || !isCurrentPage) return;
				this.props.changeTwoRectOrder({
					id: selected.id,
					page: selected.page,
					objMode: objMode,
					changeType: "up",
				});
				return;
			case "downObject":
				if (!selected.id || !isCurrentPage) return;
				this.props.changeTwoRectOrder({
					id: selected.id,
					page: selected.page,
					objMode: objMode,
					changeType: "down",
				});
				return;
			default:
				return;
		}
	};

	toggleModal(type = "Delete", show = true) {
		let modalType = `showModal${type}`;
		this.setState({
			...this.state,
			[modalType]: show,
		})
	}

	showDeleteModal(id, page, objMode) {
		this.deleteProcess = () => {
			this.props.unsetSelectItem();
			this.props.removeRect({id, page, objMode});
			this.props.addLog({msg:{regDate:`${now()}`, userId:`${this.props.auth.info.id}`, page:`${page}`, objectId:`${id}`, action: `削除`, type: "クリックポイント"}});
			this.toggleModal("Delete", false);
			this.deleteProcess = () => {};
		};
		this.toggleModal("Delete", true);
	}

	modalCancel() {
		this.toggleModal("Delete", false);
		this.deleteProcess = () => {};
	}

	render() {
		const {left, right} = this.props.pages;
		const {leftObjects, rightObjects, objectMode, pageType} = this.props;
		const objs = pageType === "left" ? leftObjects : rightObjects;
		const page = pageType === "left" ? left : right;
		return (
			<React.Fragment>
				<div className="inner-list">
					<div className="inner-page-num">{this.props.pages[pageType] + "ページリスト"}</div>
					<label>
						<select
							value={this.props.selected.id || ""}
							onChange={this.onChange.bind(this)}
							className="object-list-box" id="objectList" name="objectList"
							size="7">
							<option value=''>{"選択してください。"}</option>
							{getObjects(objs, objectMode).map((item) => {
								return (
									<option
										key={page + "_" + item.id}
										data-page={page}
										value={item.originId}>{item.id}
									</option>
								)
							})}
						</select>
					</label>
				</div>
				<div className="inner-bottom">
					<input type="button" value="追加" className="btnlink_box box_rightspace" name="addObject"
					       onClick={this.onClick}/>
					<input type="button" value="削除" className="btnlink_box" name="removeObject" onClick={this.onClick}/>
					<input type="button" value="▲" className="btnlink_box small_width_btn" name="upObject"
					       onClick={this.onClick}/>
					<input type="button" value="▼" className="btnlink_box small_width_btn" name="downObject"
					       onClick={this.onClick}/>
				</div>
				{this.state.showModalDelete && <Modal
					onClickCancel={this.modalCancel.bind(this)}
					onClickConfirm={(e) => this.deleteProcess(e)}
					msg={modalMsg.DELETE}
					title={modalTitle.WARNING}
				/>}
				<div className={"inner-mask" + (this.props.mode === "edit" ? " hide" : "")}/>
			</React.Fragment>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ListLabel);
