import React, {Component} from 'react';
import {connect} from "react-redux";
import {SansuSlideTable} from "../../../components/Contents/Editor/Panels/SubviewPanel/innerSubview";
import {
    changeRectData,
    removeScrollImage,
    changeScrollImageCss,
    changeRectDataBlock,
} from "../../../reducers/CombineReducers/pageData";
import {
    modalMsg,
    modalTitle,
    objectModeConst,
    subViewRect,
    sansuAnswerType,
    defaultSubViewAnswerCss,
} from "../../../interface/constant";
import {randomStr} from "../../../interface/utils";
import {modifyRepoPath} from "../../../reducers/CombineReducers/repo";
import Modal from "../../../components/Common/Modal";
import {getObjects} from "../Editor/EditorLayers/ObjectLayer";
import {fileUpload} from "../../../components/Common/Upload";

const mapStateToProps = state => {
    const {id, page, blockId} = state.current.editor.selected;
    const {editor} = state.current;
    const objMode = objectModeConst[editor.objectMode];
    let props = {};
    if (id && page) {
        let objectList = state.pageData[page].objects[objMode];
        const idx = objectList.findIndex((item) => item.originId === id);
        const subViewSettings = editor.objectMode === "subView" ? objectList[idx].data.subViewSettings : null;
        const options = editor.objectMode === "subView" ? objectList[idx].options : null;
        const htmlPath = editor.objectMode === "subView" ? objectList[idx].data.path : null;
        props = {
            selectedId: id,
            selectedPage: page,
            selectedBlockId: blockId,
            bookId: state.metaData.bookId,
            subViewObj: state.pageData[page].objects["subView"],
            maskObj: state.pageData[page].objects["mask"],
            blockObj: state.pageData[page].objects["block"],
            panel: state.panel,
            view: state.metaData.view,
            grade: state.metaData.grade,
            repo: state.repo,
            subViewSettings,
            options,
            htmlPath,
            idx,
        }

    }
    return props;
};

const mapDispatchToProps = {
    changeRectData,
	modifyRepoPath,
    removeScrollImage,
    changeScrollImageCss,
    changeRectDataBlock,
};

const ADD_OFFSET = 10;
let answerOffsetX = ADD_OFFSET;
let answerOffsetY = ADD_OFFSET;

const useTextInput = [
    sansuAnswerType.answer.contents[1],
    sansuAnswerType.toggle.contents[1],
];

const useMaskInput = [
    sansuAnswerType.answer.contents[0],
    sansuAnswerType.toggle.contents[0],
];

const useGroupInput = [
    sansuAnswerType.answer.button,
    sansuAnswerType.answer.contents[0],
];

const noUseDataNumInput = [
    sansuAnswerType.answer.button,
    sansuAnswerType.answer.contents[0],
    sansuAnswerType.answer.contents[1],
    sansuAnswerType.toggle.contents[0],
    sansuAnswerType.toggle.contents[1],
];

class SansuScroll extends Component {
    constructor(props) {
        super(props);
        this.input = {
            rectIdSelectBox: React.createRef()
        };
        this.state = {
            showModalDelete: false,
            expressionType: "expression",
        };
        this.deleteProcess = null;
    }

    async SansuScrollImageUpload(e, scrollIdx = -1) {
        const imageType = e.target.name;
        const result = await fileUpload(e, this.props.modifyRepoPath, this.props.bookId, "image", this.props.repo);
        if (!result || !result.fileUpload) {
            this.props.showErrorModal();
            return;
        }

        let data = this.setScrollData(result.data.key, imageType, scrollIdx);

        this.props.changeRectData({
            id: this.props.selectedId,
            page: this.props.selectedPage,
            data: data,
            objMode: "subView",
        });
    }

    async AnswerMaskImageUpload(e, idx) {
        const result = await fileUpload(e, this.props.modifyRepoPath, this.props.bookId, "image", this.props.repo);
        if (!result || !result.fileUpload) {
            this.props.showErrorModal();
            return;
        }

        const {subViewSettings} = this.props;
        let subViewAnswer = subViewSettings.options.answer || [];
        if (subViewAnswer.length === 0) return;

        subViewAnswer[idx].image = result.data.key;

        let data = {
            subViewSettings: {
                ...subViewSettings,
                options: {
                    ...subViewSettings.options,
                    answer: subViewAnswer,
                }
            }
        };

        this.props.changeRectData({
            id: this.props.selectedId,
            page: this.props.selectedPage,
            data: data,
            objMode: "subView",
        });
    }

    setScrollData(key, imageType, scrollIdx) {
        const {subViewSettings} = this.props;
        let data = {};
        if(imageType === "scroll") {
            let scrollImage = subViewSettings.options.scrollImage || [];
            const rectId = scrollImage.length > 0 ? "" : this.props.selectedId;
            const defaultScrollImage = {
                id: "scroll-" + randomStr(8),
                rectId: rectId,
                path: key,
                width: this.props.view.width,
                height: this.props.view.height,
                top: 0,
                left: 0,
            };
            if (scrollIdx >= 0) {
                scrollImage[scrollIdx].path = key;
                data = {
                    path: `/html/${this.props.idx}/`,
                    param: 1,
                    subViewSettings: {
                        ...subViewSettings,
                        options: {
                            ...subViewSettings.options,
                            scrollImage: scrollImage,
                        }
                    }
                };
            }
            else {
                data = {
                    path: `/html/${this.props.idx}/`,
                    param: 1,
                    subViewSettings: {
                        ...subViewSettings,
                        options: {
                            ...subViewSettings.options,
                            scrollImage: scrollImage.concat(defaultScrollImage),
                        }
                    }
                };
            }
        }

        return data;
    };

    subViewSettingEvent() {
        return {
            scrollImageDeleteOne: (e, scrollIdx) => {
                e.stopPropagation();
                this.props.removeScrollImage({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    isTitle: false,
                    scrollIdx: scrollIdx,
                });

                this.paramChange(scrollIdx);

                this.props.stateChange("scrollLevel", 0);
            },
            scrollImageDelete: (isTitle) => {
                this.deleteProcess = (e) => {
                    e.stopPropagation();
                    this.props.removeScrollImage({
                        id: this.props.selectedId,
                        page: this.props.selectedPage,
                        isTitle : isTitle || false,
                    });
                    this.props.stateChange("scrollLevel", 0);
                    this.toggleModal("Delete", false);
                    this.deleteProcess = () => {};
                };
                this.toggleModal("Delete", true);
            },
            changeScrollImageCss: (e) => {
                this.props.changeScrollImageCss({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    scrollIdx: this.props.scrollLevel,
                    isTitle: this.props.scrollTitleEdit,
                    target: e.target.name,
                    value: Number(e.target.value),
                });
            },
            scrollListClick: (idx) => {
                this.props.stateChange("scrollLevel", idx);
            },
        }
    }

    // 스크롤이미지 삭제 후 남은 스크롤 이미지들과 연동된 지면의 data.param값(scroll순서) 변경
    paramChange(scrollIdx) {
        const {subViewSettings} = this.props;
        const scrollImage = subViewSettings.options.scrollImage.filter((item, idx) => idx > scrollIdx);

        scrollImage.map((scroll, idx) => {
            if (!scroll.rectId) return scroll;
            const data = {
                param: scrollIdx + (idx + 1),
            };

            this.props.changeRectData({
                id: scroll.rectId,
                page: this.props.selectedPage,
                data: data,
                objMode: "subView",
            });
            return scroll;
        });
    }

    toggleModal(type = "Delete", show = true) {
        let modalType = `showModal${type}`;
        this.setState({
            ...this.state,
            [modalType]: show,
        })
    }

    modalCancel() {
        this.toggleModal("Delete", false);
        this.deleteProcess = () => {};
    }

    // 정답 버튼 관련함수이벤트
    anwerSettingEvent() {
        return {
            addAnswer: (e) => {
                const type = e.target.name;
                this.answerTypeAdd(type);
            },
            addToggle: () => {
                this.toggleTypeAdd();
            },
            addExpression: () => {
                this.expressionTypeAdd();
            },
            changeAnswerCss: (e) => {
                const value = Number(e.target.value);
                const data = {
                    [e.target.name]: value
                };

                this.props.changeRectDataBlock({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    blockId: this.props.selectedBlockId,
                    data: data,
                    subViewType: subViewRect.sansuAnswer,
                });
            },
            changeAnswerValue: (e) => {
                const value = e.target.name === "maskOn" ? e.target.checked : e.target.value;
                const data = {
                    [e.target.name]: value
                };

                this.props.changeRectDataBlock({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    blockId: this.props.selectedBlockId,
                    data: data,
                    subViewType: subViewRect.sansuAnswer,
                });
            },
            changeAnswerType: (e) => {
                const {subViewSettings, selectedBlockId} = this.props;
                let answer = subViewSettings.options.answer || [];
                const idx = answer.findIndex((item) => item.id === selectedBlockId);
                answer[idx].type = e.target.value;
                answer[idx].text = "";

                const data = {
                    subViewSettings: {
                        ...subViewSettings,
                        options: {
                            ...subViewSettings.options,
                            answer: answer,
                        },
                    }
                };

                this.props.changeRectData({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    data: data,
                    objMode: "subView",
                });

            },
            deleteAnswerImage: (idx) => {
                if (idx < 0) return;
                const {subViewSettings} = this.props;
                let subViewAnswer = subViewSettings.options.answer || [];
                if (subViewAnswer.length === 0) return;
                if (!subViewAnswer[idx].image) return;

                delete subViewAnswer[idx].image;

                let data = {
                    subViewSettings: {
                        ...subViewSettings,
                        options: {
                            ...subViewSettings.options,
                            answer: subViewAnswer,
                        }
                    }
                };

                this.props.changeRectData({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    data: data,
                    objMode: "subView",
                });
            },
            changeExpressionType: (e) => {
                this.setState({
                    ...this.state,
                    expressionType: e.target.value,
                })
            },
            maskConnectChange: (e) => {
                const rectId = e.target.value;
                const data = {
                    rectId: rectId,
                };

                this.props.changeRectDataBlock({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    blockId: this.props.selectedBlockId,
                    data: data,
                    subViewType: subViewRect.sansuAnswer,
                });
            },
        }
    }

    // 정답버튼+정답영역 생성
    answerTypeAdd(type) {
        const {grade} = this.props;
        const addScroll = this.props.scrollLevel * this.props.view.width;
        const answerId = "answer-" + randomStr(8);
        let defaultAnswer = [];
        if (type === "answerButton") {
            defaultAnswer = [{
                id: answerId,
                group: 1,
                type: sansuAnswerType.answer.button,
                width: grade > 1 ? defaultSubViewAnswerCss.width.answerButton : defaultSubViewAnswerCss.width.answerButton_1,
                height: grade > 1 ? defaultSubViewAnswerCss.height.answerButton : defaultSubViewAnswerCss.height.answerButton_1,
                left: answerOffsetX + addScroll,
                top: answerOffsetY,
                sizeChanged: true,
            }];
        } else if (type === "answerContents") {
            defaultAnswer = [{
                id: answerId,
                group: 1,
                type: sansuAnswerType.answer.contents[0],
                width: 110,
                height: 47,
                left: answerOffsetX + 200 + addScroll,
                top: answerOffsetY,
                text: "",
                fontSize: 20,
                color: "#FFFFFF",
                maskOn: true,
            }];
        }

        this.addRect(defaultAnswer);
    }

    // 클릭시 정답 보이기/숨기기 영역 생성
    toggleTypeAdd() {
        const addScroll = this.props.scrollLevel * this.props.view.width;
        const defaultAnswer = [
            {
                id: "toggle-" + randomStr(8),
                type: sansuAnswerType.toggle.contents[0],
                width: defaultSubViewAnswerCss.width.toggleContents,
                height: defaultSubViewAnswerCss.height.toggleContents,
                left: answerOffsetX + addScroll,
                top: answerOffsetY,
                text: "",
                fontSize: 20,
                color: "#FFFFFF",
                maskOn: true,
            },
        ];

        this.addRect(defaultAnswer);
    }

    // 표현식 생성
    expressionTypeAdd() {
        const addScroll = this.props.scrollLevel * this.props.view.width;
        const buttonId = "expression-" + randomStr(8);
        const contentsId = "expression-" + randomStr(8);
        const defaultAnswer = [
            {
                id: buttonId,
                connectId: contentsId,
                type: sansuAnswerType[this.state.expressionType].button,
                width: defaultSubViewAnswerCss.width.expressionButton,
                height: defaultSubViewAnswerCss.height.expressionButton,
                left: answerOffsetX + addScroll,
                top: answerOffsetY,
                dataScroll: this.props.scrollLevel + 1,
            },
            {
                id: contentsId,
                connectId: buttonId,
                type: sansuAnswerType[this.state.expressionType].contents,
                width: defaultSubViewAnswerCss.width.expressionContents,
                height: defaultSubViewAnswerCss.height.expressionContents,
                left: answerOffsetX + addScroll,
                top: answerOffsetY + 100,
                dataScroll: this.props.scrollLevel + 1,
            }
        ];

        this.addRect(defaultAnswer);
    }

    addRect(defaultAnswer) {
        const {subViewSettings} = this.props;
        let answer = subViewSettings.options.answer || [];
        const data = {
            subViewSettings: {
                ...subViewSettings,
                options: {
                    ...subViewSettings.options,
                    answer: answer.concat(defaultAnswer),
                },
            }
        };

        this.props.changeRectData({
            id: this.props.selectedId,
            page: this.props.selectedPage,
            data: data,
            objMode: "subView",
        });

        if(answerOffsetY < 580){
            answerOffsetX += ADD_OFFSET;
            answerOffsetY += ADD_OFFSET;
        }else{
            answerOffsetX = answerOffsetX-560;
            answerOffsetY = answerOffsetY-580;
        }

    }

    getSansuAnswerProps() {
        const {subViewSettings, selectedBlockId, maskObj} = this.props;
        const answers = subViewSettings.options.answer || [];
        const selectedAnswerIdx = answers.findIndex((item) => item.id === selectedBlockId);
        const selectedAnswer = selectedAnswerIdx < 0 ? null : answers[selectedAnswerIdx];
        const textInputHide = selectedAnswer ? useTextInput.findIndex((item) => item === selectedAnswer.type) >= 0 ? "" : "hide" : "hide";
        const maskInputHide = selectedAnswer ? useMaskInput.findIndex((item) => item === selectedAnswer.type) >= 0 ? "" : "hide" : "hide";
        const dataNumHide = selectedAnswer ? noUseDataNumInput.findIndex((item) => item === selectedAnswer.type) < 0 ? "" : "hide" : "hide";
        const groupInputHide = selectedAnswer ? useGroupInput.findIndex((item) => item === selectedAnswer.type) >= 0 ? "" : "hide" : "hide";
        const connectRectHide = selectedAnswer ? sansuAnswerType.toggle.contents.findIndex((item) => item === selectedAnswer.type) >= 0 ? "" : "hide" : "hide";
        const selectExpressionOptions = Object.keys(sansuAnswerType).filter((key) => sansuAnswerType[key].selectBoxText);
        let selectAnswerOptions = [];

        if (selectedAnswer && sansuAnswerType.answer.contents.includes(selectedAnswer.type)) {
            selectAnswerOptions = sansuAnswerType.answer.contents;
        } else if (selectedAnswer && sansuAnswerType.toggle.contents.includes(selectedAnswer.type)) {
            selectAnswerOptions = sansuAnswerType.toggle.contents;
        }

        const answerTypeHide = selectAnswerOptions.length > 0 ? "" : "hide";
        const imageDeleteHide = selectedAnswer ? selectedAnswer.image ? "" : "hide" : "hide";

        const rectMask = getObjects(maskObj, "mask") || [];
        return {
            event: this.anwerSettingEvent(),
            selectedAnswer: selectedAnswer,
            selectedAnswerIdx: selectedAnswerIdx,
            textInputHide: textInputHide,
            maskInputHide: maskInputHide,
            imageDeleteHide: imageDeleteHide,
            answerTypeHide: answerTypeHide,
            dataNumHide: dataNumHide,
            groupInputHide: groupInputHide,
            connectRectHide: connectRectHide,
            scrollNumHide: dataNumHide,
            sansuAnswerType: sansuAnswerType,
            selectExpressionOptions: selectExpressionOptions,
            selectAnswerOptions: selectAnswerOptions,
            upload: (e, idx) => this.AnswerMaskImageUpload(e, idx),
            bookId: this.props.bookId,
            rectMask: rectMask,
            tabMenu: this.props.tabMenu,
            isScroll: true,
        };
    }

    render() {
        const {subViewSettings, subViewObj} = this.props;
        const answerProps = this.getSansuAnswerProps();
        return (
            <>
                <SansuSlideTable
                    event={this.subViewSettingEvent()}
                    upload={(e, scrollIdx) => this.SansuScrollImageUpload(e, scrollIdx)}
                    bookId={this.props.bookId}
                    scrollImage={subViewSettings.options.scrollImage || []}
                    currentIdx={this.props.scrollLevel}
                    subViewObj={subViewObj}
                    tabMenu={this.props.tabMenu}
                    answerProps={answerProps}
                />
                {this.state.showModalDelete && <Modal
                    onClickCancel={this.modalCancel.bind(this)}
                    onClickConfirm={(e) => this.deleteProcess(e)}
                    msg={modalMsg.INIT}
                    title={modalTitle.WARNING}
                />}
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SansuScroll);