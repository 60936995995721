import { createStore, applyMiddleware, compose } from "redux";
import { chkBookChanged } from "./interface/middleWare";
import rootReducer from "./reducers";
import ReduxThunk from 'redux-thunk';

const middleWares = [ReduxThunk, chkBookChanged];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // redux devtools

let store;
if(process.env.NODE_ENV === "production") {
	store = createStore(rootReducer, applyMiddleware(...middleWares))
} else {
	store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleWares)));
}

export default store;