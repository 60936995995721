export function getImportEditorDataModel() {
	return {
		originId: "",
		id: "",
		page: 0,
		type: "",
		flag: 2,
		editor: {
			myEditorImage: "",
			myEditorImage_bg: "",
			ignore: false,
			text: {
				contents: []
			}
		},
	}
}

export function getImportEditorTextDataModel() {
	return {
		value: "",
		id: "",
		type: "",
		flag: 0,
		css: {
			fontSize: "",
			lineHeight: "",
			marginLeft: "",
			marginTop: "",
			position: "",
			top: "",
			left: "",
			color: "",
			fontWeight: "",
			width: "",
		}
	}
}

export function defaultEditorTextObj() {
	return  {
		css: {
			position: "absolute"
		},
		defaultCss: {
			fontSize: "1em",
			position: "absolute"
		},
		contents: []
	}
}

export function getImportVideoModel() {
	return {
		id: "",
		options: {
			link: {
				level: 3,
			}
		},
		css: {
			top: 0,
			left: 0,
			position: "absolute",
			height: 30,
			width: 37,
		},
		data: {
			file: "",
			class: "icon movie",
			tooltip: "",
			title: "",
			subtitleKey: "",
			fileKey: "",
		},
		editor: {
			ignore: 1
		},
		originId: "",
		page: "",
		thumbKey: "",
	}
}

export function getAddSubViewModel() {
	return {
		id: "",
		options: {
			link: {
				level: 2,
				zIndex: 0
			},
			iFrame: false
		},
		css: {
			top: 0,
			left: 0,
			opacity: 0.5,
			position: 'absolute',
			height: 100,
			width: 100
		},
		data: {
			path: "",
			class: "",
			type: "",
			kakezu: "",
			title: "",
		},
		editor: {
			myEditorImage_bg: "",
			myEditorImage: "",
			ignore: false,
		},
		originId: "",
		page: 0,
		flag: 0,
		type: ""
	}
}

export function getUpdateSubViewModel() {
	return {
		id: "",
		options: {
			link: {
				level: 2,
				zIndex: 0
			},
			iFrame: false
		},
		css: {
			top: 0,
			left: 0,
			opacity: 0.5,
			position: 'absolute',
			height: 100,
			width: 100
		},
		data: {
			class: "",
			type: "",
			kakezu: "",
			title: "",
		},
		originId: "",
		page: 0,
		flag: 0,
		type: ""
	}
}


export function getAddIconModel() {
	return {
		id: "",
		options: {
			link: {
				level: 3,
				zIndex: 1
			},
			iFrame: true,
			isLargeView: true
		},
		css: {
			top: 0,
			left: 0,
			position: 'absolute',
			height: 41,
			width: 48
		},
		data: {
			path: "",
			class: "",
			kakezu: "",
			title: "",
		},
		originId: "",
		page: 0,
		flag: 0,
		type: "",
	}
}

export function getUpdateIconModel() {
	return {
		id: "",
		options: {
			link: {
				level: 3,
				zIndex: 1
			},
			iFrame: true
		},
		css: {
			top: 0,
			left: 0,
			position: 'absolute',
			height: 41,
			width: 48
		},
		data: {
			class: "",
			type: "",
			kakezu: "",
			title: "",
		},
		originId: "",
		page: 0,
		flag: 0,
		type: "",
	}
}

export function getImportMeaningModel() {
	return {
		id: "",
		options: {
			link: {
				level: 1,
			}
		},
		css: {
			top: 0,
			left: 0,
			position: 'absolute',
			height: 100,
			width: 100
		},
		data: {
			class: ""
		},
		originId: "",
		page: 0,
		flag: 0,
		type: "",
	}
}

export function getImportPageLinkModel() {
	return {
		id: "",
		options: {
			link: {
				level: 2
			}
		},
		css: {
			top: 0,
			left: 0,
			position: 'absolute',
			height: 100,
			width: 100
		},
		data: {
			page: 0,
		},
		originId: "",
		page: 0,
		flag: 0,
		type: "",
	}
}

export function getImportMaskModel() {
	return {
		id: "",
		options: {
			link: {
				level: 2
			}
		},
		css: {
			top: 0,
			left: 0,
			position: 'absolute',
			height: 100,
			width: 100
		},
		originId: "",
		page: 0,
		flag: 0,
		type: "",
	}
}

export function getImportBlockModel() {
	return {
		id: "",
		options: {
			link: {
				level: 1
			}
		},
		css: {
			top: 0,
			left: 0,
			position: 'absolute',
			height: 100,
			width: 100
		},
		data: {
			show: false,
			color: "#fff",
			class: "blocked"
		},
		originId: "",
		page: 0,
		flag: 0,
		type: "",
	}
}


export function getImportRedLineModel() {
	return {
		id: "",
		options: {
			link: {
				level: 1
			}
		},
		css: {
			top: 0,
			left: 0,
			position: 'absolute',
			height: 100,
			width: 100
		},
		data: {
			visible: "hidden"
		},
		originId: "",
		page: 0,
		flag: 0,
		type: "",
	}
}

export function getImportPostItModel() {
	return {
		id: "",
		options: {
			link: {
				level: 0
			}
		},
		css: {
			top: 0,
			left: 0,
			position: 'absolute',
			height: 100,
			width: 100
		},
		originId: "",
		page: 0,
		flag: 0,
		type: "",
	}
}