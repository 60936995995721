// import produce from "immer";
import { getMetaDataModel } from "../../interface/dataModel";

const SET_METADATA = "SET_METADATA";
const ADD_TOTAL_PAGE = "ADD_TOTAL_PAGE";
const REMOVE_TOTAL_PAGE = "REMOVE_TOTAL_PAGE";
const SET_CHAPTER = "SET_CHAPTER";
const SET_BOOK_INDEX = "SET_BOOK_INDEX";
const CHANGE_DUMMY = "CHANGE_DUMMY";

const initialState = getMetaDataModel();

//Reducer
export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case SET_METADATA: {
			return {
				...state,
				...action.meta,
			};
		}
		case ADD_TOTAL_PAGE: {
			return {
				...state,
				totalPages: state.totalPages + 2
			}
		}
		case REMOVE_TOTAL_PAGE: {
			return {
				...state,
				totalPages: state.totalPages - 2
			}
		}
		case SET_CHAPTER: {
			return {
				...state,
				chapter: action.chapter,
			}
		}
		case SET_BOOK_INDEX: {
			return {
				...state,
				bookIndex: action.bookIndex,
			}
		}
		case CHANGE_DUMMY: {
			const {page, value} = action;
			let dummyList = [];
			if (value) {
				dummyList = state.dummyList.concat(page);
			} else {
				state.dummyList.splice(state.dummyList.indexOf(page), 1);
				dummyList = state.dummyList;
			}

			return {
				...state,
				dummyList: dummyList,
			}
		}
		default: {
			return state;
		}
	}
}

//Action Generators
export function setMetadata(state) {
	return {
		type: SET_METADATA,
		meta: state.meta
	}
}

export function addTotalPage(state) {
	return {
		type: ADD_TOTAL_PAGE
	}
}

export function removeTotalPage(state) {
	return {
		type: REMOVE_TOTAL_PAGE
	}
}

export function setChapter(state) {
	return {
		type: SET_CHAPTER,
		chapter: state.chapter,
	}
}

export function setBookIndex(state) {
	return {
		type: SET_BOOK_INDEX,
		bookIndex: state.bookIndex,
	}
}

export function changeDummy(state) {
	return {
		type: CHANGE_DUMMY,
		page: state.page,
		value: state.value,
	}
}