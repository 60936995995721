import React, { Component } from 'react';
import Panel from "../Panel";
import "./GuideModePanel.scss";
import GuideModeList from "../../../../../containers/Contents/Editor/GuideModeList";

const panelState = {
	id:  "GuideModePanel",
	label: "指導支援モード",
	name: "guideMode",
	style: {
		width: 220,
		height: 400,
		top: 10,
		left: 100,
	}
};

class GuideModePanel extends Component {
	keyDown(e) {
		// ObjectLayerのkeydownイベントを実行しない
		e.nativeEvent.stopImmediatePropagation();
	}

	render() {
		return (
			<div className="panel-object-list-inner inner-contents" onKeyDown={this.keyDown.bind(this)}>
				<GuideModeList/>
			</div>
		)
	}
}

export default Panel(GuideModePanel, panelState);