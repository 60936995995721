import {csvConst, csvModalMsg, svColumn, vColumn,} from "../../../interface/csvConstant";
import {getNumberLine, makeErrorMsg, merge, randomStr, stringParser} from "../../../interface/utils";
import {objectModeConst} from "../../../interface/constant";
import {
	checkPropsKeyExist,
	emptyRowChecker,
	flagChecker, inputDuplicateCheck,
	leftInfoCheck,
	originIdChecker, overRangeDetailCheck,
	pageInvalidCheck, propValueParsing,
	setDefaultValue
} from "./csvUtils";
import {getImportVideoModel} from "../../../interface/csvModel";

/**
 * ProcessData 를 map 하면서 각 객체의 속성 값을 문자열을 숫자로 parse 한 객체들의 배열을 반환하는 함수
 * @param {array} ProcessData - flat 한 속성명을 갖는 video 객체들의 배열
 * @returns {array} - 각 video 객체의 속성값이 parse 된 객체들의 배열
 */
export const dataParse = (ProcessData) => {
	return ProcessData.map(obj => {
		for (const [k, v] of Object.entries(obj)) {
			obj[k] = stringParser(v);
		}
		return obj;
	})
};

/**
 * clonedData 에 csv 에서 입력받은 페이지의 objects 객체의 videoLink 배열에 addObj 를 추가하는 함수
 * @param {object} clonedData - cloned pageData
 * @param {object} addObj - csv 에서 입력받은 정보로 만든 추가할 객체
 */
export const addVideoData = (clonedData, addObj) => {
	const page = addObj.page;
	const type = objectModeConst.videoLink;
	if (!clonedData[page][csvConst.objects][type]) {
		clonedData[page][csvConst.objects][type] = [];
	}
	clonedData[page][csvConst.objects][type].push(addObj);
};

/**
 * editor csv import 시 importVideoColumn 배열과 비교해서 틀리면 에러를 던지는 함수
 * @param {array} csvColumns - csv 에서 입력받은 컬럼들의 이름 배열
 */
export const importColumnMatchedCheck = (csvColumns) => {
	const importVideoColumn = ['元ID', 'ID', 'ページ', 'X座標', 'Y座標',
		'R-ID(動画)', 'R-ID(字幕)', '吹き出し', 'タイトル', 'サムネイルID','編集'];
	if (csvColumns.toString() !==  importVideoColumn.toString()) {
		throw new Error(makeErrorMsg('unmatchedColumn', csvModalMsg.video));
	}
};

/**
 * flag 1로 최소한 page 정보를 받았다면 id, originId, css.left, css.top 속성 값이 없으면
 * 임의로 기본값을 지정해서 넣어주어 객체 생성시 기본값을 갖게 만들어주는 함수
 * @param {object} csvObj - flag 1로 들어온 , 최소한 page 속성값은 입력된 video 객체
 * @returns {object} - id, originId, css.left, css.top 속성들의 값이 "" 없을 때 기본값을 지정한 video 객체
 */
export const flagOneDefaultValueSetting = (csvObj) => {
	const ids =  `${csvObj.page}-${randomStr(8)}`;
	const newObj = Object.assign({}, csvObj);
	setDefaultValue(newObj, 'id', ids);
	setDefaultValue(newObj, 'originId', ids);
	setDefaultValue(newObj, 'css.left', 0);
	setDefaultValue(newObj, 'css.top', 0);
	return newObj;
};


/**
 * CSV Import 받은 객체들 속성값을 검사하는 함수
 * @param {array} videoData - 動画 객체들의 배열
 * @param {object} metaData - 클론한 metaData
 * @param {number} commentLength - 주석의 길이
 * @param {array} originIdArr - redux 내의 모든 객체들의 originId 를 담은 배열
 * @param {object} repo - 클론한 repo
 */
export const videoChecker = (videoData, metaData, commentLength, originIdArr, repo) => {
	const { totalPages, direction, view}  = metaData;
	const videoIcon = { width: 38, height: 32 };
	const dupleCheckObj = {originId: []};
	for (const video of videoData) {
		const idx = getNumberLine(videoData, video, commentLength);
		inputDuplicateCheck(video, idx, dupleCheckObj);         // csv 에 똑같은 originId를 입력하면 에러
		emptyRowChecker(video, idx);                            // CSV 빈 로우는 입력받지 않는다.
		flagChecker(video, idx, vColumn);                       // flag(編集) 는 1, 2, 3 셋 중 하나만 입력 받는다.
		pageInvalidCheck(video, idx, totalPages, vColumn);      // 추가/수정시 page(ページ) 유효값 검사
		originIdChecker(video, idx, originIdArr);               // originId(元ID) 중복/존재유무
		if (stringParser(video.flag) !== 3) {
			leftInfoCheck(video, idx, view.width, direction, vColumn, videoIcon.width); // X座標 검사
			overRangeDetailCheck(video, 'css.top', idx, 0, (view.height - videoIcon.height), svColumn); //Y座標
		}
		checkPropsKeyExist(video, 'data.fileKey', idx, repo, 'video');  　　// R-ID(動画) 검사
		checkPropsKeyExist(video, 'data.subtitleKey', idx, repo, 'srt'); 　// R-ID(字幕) 검사
	}
};

/**
 * 각 객체에 특정 속성의 경우 속성값이 빈값이면 임의로 넣어주는 함수
 * @param {array} videoData - 속성 값 검사가 끝난 video 객체가 담긴 배열
 * @returns {array} 조건에 따라 속성에 기본값이 대입된 video 객체들의 배열
 */
export const videoDefaultValueSetting = (videoData) => {
	return videoData.map(obj => {
		const flag = stringParser(obj.flag);
		const ids =  `${obj.page}-${randomStr(8)}`;
		if (flag === 3) return obj;
		if (flag === 1) {
			setDefaultValue(obj, 'originId', ids);
		}
		setDefaultValue(obj, 'id', ids);
		setDefaultValue(obj, 'css.left', 0);
		setDefaultValue(obj, 'css.top', 0);
		return obj;
	});
};

/**
 * video 객체들의 속성값을 파싱하고 리덕스 형태의 속성 구조로 되돌린 객체들의 배열을 반환하는 함수
 * @param {array} videoData - 유효성 검사가 끝난 video 객체들이 담긴 배열
 * @returns {array} 속성값들이 파싱되고 객체 구조가 원래의 리덕스 형태로 되돌려진 video 객체
 */
export const getVideoModelObject = (videoData) => {
	return videoData.map(obj => {
		const parsedVideo = propValueParsing(obj); // 속성값이 파싱된 Video 객체
		const videoModel = getImportVideoModel();
		if (obj.flag === 2) {
			// author 편집항목엔 있지만 CSV에서 편집할 수 없는 항목을 기본값으로 초기화 하지 않도록 제거해줌
			delete videoModel.css.height;
			delete videoModel.css.width;
			delete videoModel.data.class;
		}
		const videoModelObject = getVideoModel(videoModel, parsedVideo);
		return videoModelObject;
	});
};


/**
 * model 에 object 의 속성값을 넣어서 model 속성 구조와 csv 객체의 속성 값을 갖는 객체를 반환하는 함수
 * @param model - redux 구조를 갖고 있는 video 객체 모델
 * @param object - csv 정보로 만들어진 flat 한 구조를 갖는 video 객체
 * @returns {object} - redux 구조를 갖고 csv 의 입력값을 속성으로 갖는 video 객체
 */
const getVideoModel = (model, object) => {
	for (const [prop, value] of Object.entries(object)) {
		if (prop === 'originId') {
			model.originId = value;
		} else if (prop === 'id') {
			model.id = value;
		} else if (prop === 'page') {
			model.page = value;
		} else if (prop === 'css.left') {
			model.css.left = value;
		} else if (prop === 'css.top') {
			model.css.top = value;
		} else if (prop === 'data.fileKey') {
			model.data.fileKey = value;
		} else if (prop === 'data.subtitleKey') {
			model.data.subtitleKey = value;
		} else if (prop === 'data.tooltip') {
			model.data.tooltip = value;
		} else if (prop === 'data.title') {
			model.data.title = value;
		} else if (prop === 'thumbKey') {
			model.thumbKey = value;
		} else if (prop === 'flag') {
			model.flag = value;
		}
	}
	return model;
};

/**
 * CSV 에서 입력받은 編集에 따라서 클론한 pageData 에 객체를 추가/수정/삭제 하는 함수
 * @param videoModelData - CSV 로 받은 video 객체들이 담긴 배열
 * @param {object} pageData - redux 에 반영할 클론한 pageData
 * @param allObjectArr - 모든 redux 객체들을 담은 배열
 */
export const videoImporting = (videoModelData, pageData, allObjectArr) => {
	for (const video of videoModelData) {
		if (video.flag === 1) {
			addVideoPageData(pageData, video)
		} else if (video.flag === 2) {
			const prevStateObj = allObjectArr.find(reduxObj => reduxObj.originId === video.originId);
			const prevStateArr = pageData[prevStateObj.page]['objects']['videoLink'];
			const newStateArr = prevStateArr.filter(obj => obj.originId !== prevStateObj.originId);
			pageData[prevStateObj.page]['objects']['videoLink'] = newStateArr; // 이전 객체 제거
			const mergedObject = merge(prevStateObj, video); // 이전 객체 정보랑 csv 로 받은 객체 정보랑 합침
			delete mergedObject.flag;
			const videoLink = pageData[video.page]['objects']['videoLink'];
			if (!videoLink) pageData[video.page]['objects']['videoLink'] = []; // 객체 배열 없으면 생성
			pageData[video.page]['objects']['videoLink'].push(mergedObject); // 추가
		} else {
			deleteVideoPageData(pageData, video, allObjectArr);
		}
	}
};

/**
 * 객체를 pageData 에 추가하는 함수, pageData 는 Redux 에 import 완료시 반영될 객체
 * @param {object} pageData - redux 에 반영할 클론한 pageData
 * @param {object} video - 編集이 1(追加)인 video 객체
 */
const addVideoPageData = (pageData, video) => {
	const videoLink = pageData[video.page]['objects']['videoLink'];
	if (!videoLink) pageData[video.page]['objects']['videoLink'] = [];
	delete video.flag;
	videoLink.push(video);
};

/**
 * 객체를 pageData 에서 제거하는 함수, pageData 는 Redux 에 import 완료시 반영될 객체
 * @param {object} pageData - redux 에 반영할 클론한 pageData
 * @param {object} video - 編集이 3(削除)인 video 객체
 * @param {object} allObjectArr - 모든 redux 객체들을 담은 배열
 */
const deleteVideoPageData = (pageData, video, allObjectArr) => {
	const prevStateObj = allObjectArr.find(obj => obj.originId === video.originId);
	const videoLink = pageData[prevStateObj.page]['objects']['videoLink'];
	const idx = videoLink.findIndex(obj => obj.originId === video.originId);
	pageData[prevStateObj.page]['objects']['videoLink'].splice(idx, 1);
};