import React, { Component } from 'react';
import Draggable from "react-draggable";
import { connect } from 'react-redux';
import { getSubViewSettings } from "../../../../interface/utils";
import { objectModeConst } from "../../../../interface/constant";
import './Panel.scss';
const DEFAULT_Z_INDEX = 100;

let zIndex = DEFAULT_Z_INDEX;
let panelId = "";

export default (ComposedComponent, panelProp) => {

	const mapStateToProps = state => {
		const {id, page} = state.current.editor.selected;
		const {editor} = state.current;
		const objMode = objectModeConst[editor.objectMode];
		let props = {};
		if (id && page) {
			let objectList = state.pageData[page].objects[objMode];
			const idx = objectList.findIndex((item) => item.originId === id);
			const subViewSettings = editor.objectMode === "subView" ? objectList[idx].data.subViewSettings : null;
			props = {
				subViewSettings
			}

		}
		return props;
	};

	class Panel extends Component {
		constructor(props) {
			super(props);
			this.state = {zIndex}
		}

		onStart() {
			if(this.state.zIndex === DEFAULT_Z_INDEX || this.state.zIndex !== zIndex || panelProp.id !== panelId) {
				panelId = panelProp.id;
				zIndex++;
				this.setState({zIndex});
			}
		}

		togglePanel() {
			const togglePanelList =  ['checkSheet', 'group', 'linkPages', 'svg', 'popup'];
			const isTogglePanel = togglePanelList.some(panel => panel === panelProp.name);
			if(isTogglePanel) this.props.changeEditorMode({mode: "edit"});
			this.props.togglePanel({
				name: panelProp.name,
				isOpen: false,
			})
		}
		
		getPanelStyle() {
			if (panelProp.id !== "SubViewPanel") return panelProp.style;

			const isLarge = getSubViewSettings(this.props.subViewSettings, "isLargeView", false);

			return {
				...panelProp.style,
				...panelProp.style.panelSize[isLarge ? "large" : "default"]
			};
		}

		render() {
			const panelStyle = this.getPanelStyle();
			const closeHide = panelProp.name === "guideMode" ? "hide" : "";
			return (
				<Draggable
					handle={".inner-handle"}
					disableDragging={!panelProp.draggable}
					enableResizing={{bottomRight: panelProp.resizable}}
					lockAspectRatio={true}
					bounds={"parent"}
					onStart={this.onStart.bind(this)}
				>
					<div
						id={panelProp.id}
						className="panel editor-panel"
						style={{
							...panelStyle,
							zIndex: this.state.zIndex,
						}}
						onClick={this.onStart.bind(this)}
					>
						<div className="panel-page-view-inner inner-handle">
							<div className="label">{panelProp.label}</div>
							<div className={"icon_close " + closeHide} onClick={this.togglePanel.bind(this)}/>
						</div>
						<ComposedComponent objMode={this.props.objMode} socket={this.props.socket || null}/>
					</div>
				</Draggable>
			)
		}
	}
	return connect(mapStateToProps)(Panel);
};
