import React, { Component } from 'react';
import { connect } from 'react-redux';
import {objectModeConst, propertyInputLabel} from "../../interface/constant";
import { changeRectData } from '../../reducers/CombineReducers/pageData';
import { unsetSelectPopupTarget } from '../../reducers/CombineReducers/current';
import { randomStr } from "../../interface/utils";

const mapStateToProps = state => {
    const objMode = objectModeConst[state.current.editor.objectMode];
    let {id, page} = state.current.editor.selected;
    let prop = {};
    if (id && page) {
        let objectInfo = state.pageData[page].objects[objMode];
        let item = objectInfo.filter((item) => item.originId === id)[0];
        prop = {
            item,
            mode: state.current.editor.mode,
            pages: state.current.editor.pages,
            panel: state.panel,
            id,
            page,
            selectedPopupId: state.current.editor.selected.popupId,
            objMode
        };
    }
    return prop;
};

const mapDispatchToProps =  {
    changeRectData,
    unsetSelectPopupTarget,
};

class PopupTargetEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            targetId: "",
        }
    }

    addTarget() {
        if (!this.props.item) return;
        const target = this.props.item.data.popup.css;
        let data = {};
        if (target) {
            data = {
                popup: {
                    ...this.props.item.data.popup,
                    css: target.concat({
                        id: "popup-" + randomStr(8),
                        width: 30,
                        height: 100,
                        top: 0,
                        left: 0,
                        position: "absolute",
                    })
                }
            };
        } else {
            data = {
                popup: {
                    ...this.props.item.data.popup,
                    css: [{
                        id: "popup-" + randomStr(8),
                        width: 30,
                        height: 100,
                        top: 0,
                        left: 0,
                        position: "absolute",
                    }]
                }
            };
        }

        this.props.changeRectData({
            id: this.props.id,
            page: this.props.page,
            data: data,
            objMode: this.props.objMode,
        });
    }

    deleteTarget() {
        if (!this.props.selectedPopupId) return;
        const idx = this.props.item.data.popup.css.findIndex((item) => item.id === this.props.selectedPopupId);
        const popupCss = Object.assign([], this.props.item.data.popup.css);
        popupCss.splice(idx, 1);
        let popupData = {
            popup: {
                ...this.props.item.data.popup,
                css: popupCss,
            }
        };

        if (popupCss.length === 0) delete popupData.popup.css;

        this.props.changeRectData({
            id: this.props.id,
            page: this.props.page,
            data: popupData,
            objMode: this.props.objMode,
        });

        this.props.unsetSelectPopupTarget();
    }

    onChange(e) {
        if (!this.props.selectedPopupId) return;
        const value = Number(e.target.value);

        const idx = this.props.item.data.popup.css.findIndex((item) => item.id === this.props.selectedPopupId);
        const popupCss = this.props.item.data.popup.css;
        popupCss[idx] = {
            ...popupCss[idx],
            [e.target.name]: value,
        };
        let popupData = {
            popup: {
                ...this.props.item.data.popup,
                css: popupCss,
            }
        };

        this.props.changeRectData({
            id: this.props.id,
            page: this.props.page,
            data: popupData,
            objMode: this.props.objMode,
        });
    }

	render() {
        const {item, selectedPopupId} = this.props;
        const popupCss = item.data.popup.css;
        const idx = (selectedPopupId && popupCss) ? popupCss.findIndex((target) => target.id === selectedPopupId) : -1;
        const css = idx >= 0 ? popupCss[idx] : null;
		return (
			<React.Fragment>
				<table className={"popupTargetTable"}>
                    <tbody>
                        <tr>
                            <td colSpan={2}>
                                <input
                                    type={"button"}
                                    value={"追加"}
                                    className={"popupBtn"}
                                    onClick={this.addTarget.bind(this)}
                                />
                                <input
                                    type={"button"}
                                    value={"削除"}
                                    className={"popupBtn"}
                                    onClick={this.deleteTarget.bind(this)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={"td-name"}>
                                <span>{propertyInputLabel.width}</span>
                            </td>
                            <td className={"td-value"}>
                                <input
                                    type={"number"}
                                    name={"width"}
                                    value={css ? css.width : ""}
                                    onChange={this.onChange.bind(this)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={"td-name"}>
                                <span>{propertyInputLabel.height}</span>
                            </td>
                            <td className={"td-value"}>
                                <input
                                    type={"number"}
                                    name={"height"}
                                    value={css ? css.height : ""}
                                    onChange={this.onChange.bind(this)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={"td-name"}>
                                <span>{propertyInputLabel.left}</span>
                            </td>
                            <td className={"td-value"}>
                                <input
                                    type={"number"}
                                    name={"left"}
                                    value={css ? css.left : ""}
                                    onChange={this.onChange.bind(this)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={"td-name"}>
                                <span>{propertyInputLabel.top}</span>
                            </td>
                            <td className={"td-value"}>
                                <input
                                    type={"number"}
                                    name={"top"}
                                    value={css ? css.top : ""}
                                    onChange={this.onChange.bind(this)}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
			</React.Fragment>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupTargetEdit);
