import React, {Component} from 'react';
import './Password.scss';
import Modal from "../../Common/Modal";
import {modalMsg} from "../../../interface/constant";
import {addAdminLog} from "../../../reducers/CombineReducers/current";
import {connect} from "react-redux";
import {addEditAdminLog} from "../../../interface/api";
import {now} from "../../../interface/utils";

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	}
};

const mapDispatchToProps = dispatch => {
	return {
		addAdminLog : (state) => dispatch(addAdminLog(state))
	}
};

class Password extends Component {
	constructor(props) {
		super(props);
		this.state = {
			passwordOld: "",
			passwordNew: "",
			passwordRe: "",
			modalNotMatched: false,
			modalMessage: "",
		}
	}

	setPassOnState = (e) => {
		const value = e.target.value;
		const name = e.target.getAttribute("name");
		this.setState({
			[name]: value,
		})
	};

	changePassword = () => {
		if ([this.state.passwordOld, this.state.passwordNew, this.state.passwordRe].some((pass) => pass.length === 0)) {
			this.showModal(modalMsg.PASSWORD_EMPTY);
		} else if (this.state.passwordNew !== this.state.passwordRe) {
			this.showModal(modalMsg.PASSWORD_NOT_MATCHED);
		} else {
			this.props.changePassword({
				old: this.state.passwordOld,
				new: this.state.passwordNew,
			})
			const currentTime = now();
			addEditAdminLog({regDate:`${currentTime}`, userId:`${this.props.auth.info.id}`, groupId:``, action: `パスワード変更`});
		}
	};

	showModal = (msg) => {
		this.setState({
			modalNotMatched: true,
			passwordOld: "",
			passwordNew: "",
			passwordRe: "",
			modalMessage: msg,
		})
	};

	closeModal = () => {
		this.setState({
			modalNotMatched: false,
		})
	};

	render() {
		return (
			<div className="book-type-password">
				<div className="book-type-title">パスワード変更</div>
				<div className="book-password">
					<form>
						<div className="password-input-area">
							<input
								type="password"
								name="passwordOld"
								placeholder="現在パスワード"
								value={this.state.passwordOld}
								onChange={this.setPassOnState}
								className="book-password-input"
							/>
							<input
								type="password"
								name="passwordNew"
								placeholder="新しいパスワード"
								value={this.state.passwordNew}
								onChange={this.setPassOnState}
								className="book-password-input"
							/>
							<input
								type="password"
								name="passwordRe"
								placeholder="新しいパスワード(再入力)"
								value={this.state.passwordRe}
								onChange={this.setPassOnState}
								className="book-password-input"
							/>
						</div>
						<div className="password-save">
							<input className="account-btn-save" type="button" value="保存" onClick={this.changePassword}/>
						</div>
						{this.state.modalNotMatched && <Modal onClickConfirm={this.closeModal} msg={this.state.modalMessage}/>}
					</form>

				</div>
			</div>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Password);