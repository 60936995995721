import React, { Component } from 'react';
import PropTypes from "prop-types";

class SetBookIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {
			bookIndex: {},
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps === prevState.prevProps) return null;
		const bookIndexProp = nextProps.bookIndex;
		const bookIndex = SetBookIndex.arrangeChapterToState(bookIndexProp, nextProps.totalPages);
		return {bookIndex, prevProps: nextProps}
	}

	static arrangeChapterToState(bookIndexProp, totalPage) {
		let bookIndex = Object.assign({}, bookIndexProp);
		for (let i = 1; i <= totalPage; i++) {
			if (!bookIndex.hasOwnProperty(i)) {
				bookIndex[i] = i;
			}
		}
		return bookIndex;
	}

	onChange(e) {
		let page = e.target.getAttribute("data-page");
		const val = !e.target.value || isNaN(e.target.value) ? e.target.value : parseInt(e.target.value);
		// let val = parseInt(e.target.value);
		// if(isNaN(val) || typeof val !== "number") val = "";

		let bookIndex = {...this.state.bookIndex};
		bookIndex[page] = val;
		this.setState({bookIndex});

	}

	onBlur(e) {
		const isValid = e.target.validity.valid;
		if (isValid) {
			let bookIndex = {...this.state.bookIndex};
			this.props.setBookIndex({bookIndex});
		} else {
			let bookIndex = SetBookIndex.arrangeChapterToState(this.props.bookIndex, this.props.totalPages);
			this.setState({bookIndex});
		}
	}

	fillAuto(e) {
		let page = parseInt(e.target.getAttribute("data-page"));
		let bookIndex = {...this.state.bookIndex};
		let indexVal = bookIndex[page];
		const length = Object.keys(bookIndex).length;
		for (let i = page + 1; i <= length; i++) {
			indexVal++;
			bookIndex[i] = indexVal;
		}
		this.props.setBookIndex({bookIndex});
	}

	changeDummy(e, page) {
		this.props.changeDummy({
			page,
			value: e.target.checked,
		})
	}

	getRowElem() {
		const bookIndex = this.state.bookIndex;
		return Object.keys(bookIndex).map((item, idx) => {
			const pageNum = Number(item);
			return (
				<tr key={`chapter_${idx}`}>
					<td className="page">
						<span>{item}</span>
					</td>
					<td className="bookIndex">
						<input
							data-page={item}
							type="text"
							value={bookIndex[item]}
							onChange={this.onChange.bind(this)}
							onBlur={this.onBlur.bind(this)}
							// pattern="[0-9]\d{0,2}"
							// max="999"
						/>
					</td>
					<td className={"btn"}>
						<input
							data-page={item}
							type="button"
							className="btn_gray"
							name="auto-fill-index"
							value="自動"
							onClick={this.fillAuto.bind(this)}
						/>
					</td>
					<td className={"dummy"}>
						<div className={"switch"}>
							<label className={"switch_label"}>
								<input type={"checkbox"} className={"switch_input"}
									   checked={this.props.dummyList.includes(pageNum) || false}
									   onChange={(e) => this.changeDummy(e, pageNum)}
								/>
								<span className={"switch_content"}/>
								<span className={"switch_circle"}/>
							</label>
						</div>
					</td>
				</tr>
			)
		});
	}

	render() {
		return (
			<div className="bookIndex-setting-container">
				<div className="chapter-label">
					<span>ノンブル設定</span>
				</div>
				<table className="chapter-table">
					<tbody>
					<tr>
						<th className="page">ページ番号</th>
						<th className="bookIndex">ノンブル</th>
						<th className="btn">操作</th>
						<th className="dummy">調整用ページ</th>
					</tr>
					{this.getRowElem()}
					</tbody>
				</table>
			</div>
		)
	}
}

SetBookIndex.defaultProps = {
	bookIndex: {}
};
SetBookIndex.propTypes = {
	bookIndex: PropTypes.object.isRequired,
	totalPages: PropTypes.number.isRequired,
};

export default SetBookIndex;