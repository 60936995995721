import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from "prop-types";

import * as Inner from "../../../../components/Contents/Editor/Panels/PropertyPanel/innerProperty";
import PosSizeProperty from "../../../../components/Contents/Editor/Panels/PropertyPanel/posSizeProperty";
import {
	changeBookData,
	changeRectId,
	changeRectPage,
	changeRectOptions,
	changeRectEditor,
	changeRectData,
	changeRectSVG,
	changeRectThumbKey,
} from '../../../../reducers/CombineReducers/pageData';
import {
	objectModeConst,
	propertyInputName,
	propertyInputLabel,
	propertyToolTip,
	dataModeConst,
	iconTypeOptions,
	editorOptions,
	linkLevelOptions, modalMsg, modalTitle,
} from '../../../../interface/constant';
import {setSelectItem, changeEditorMode} from "../../../../reducers/CombineReducers/current";
import {modifyRepoPath} from "../../../../reducers/CombineReducers/repo";
import {togglePanel} from "../../../../reducers/CombineReducers/panel";
import {checkNested} from "../../../../interface/utils";
import SvgProperty from "../../../../components/Contents/Editor/Panels/PropertyPanel/SvgProperty";
import {fileUpload} from "../../../../components/Common/Upload";
import Modal from "../../../../components/Common/Modal";

const mapStateToProps = (state) => {
	const objMode = objectModeConst[state.current.editor.objectMode];
	let {id, page} = state.current.editor.selected;
	let props = {
		objMode,
		pages: state.current.editor.pages,
		mode: state.current.editor.mode,
		view: state.metaData.view,
		bookId: state.metaData.bookId,
		panel: state.panel,
		contentsRepo: state.repo.contents,
		direction: state.metaData.direction,
		repo: state.repo,
		subject: state.metaData.subject,
	};
	if (id && page) {
		let objectInfo = state.pageData[page].objects[objMode];
		props.item = objectInfo.filter((item) => item.originId === id)[0];
		props.id = id;
		props.page = page;
	}
	return props;
};

const mapDispatchToProps = {
	changeBookData,
	changeRectId,
	changeRectPage,
	changeRectOptions,
	changeRectEditor,
	changeRectData,
	changeRectSVG,
	setSelectItem,
	modifyRepoPath,
	togglePanel,
	changeEditorMode,
	changeRectThumbKey,
};

let property = {
	currentId: "",
	changedId: "",
	page: 0,
	objMode: "",
	item: {}
};

const DEFAULT_STATE = {
	id: "",
	editorUse: 0,
	zIndex: 0,
	type: 0,
	propertyCss: {
		width: "",
		height: "",
		top: "",
		left: "",
	},
	points: "",
	zipKey: "",
	htmlFileName: "",
	exLinkPath: "",
	showModalError: false,
};

class PropertyTable extends Component {
	constructor(props) {
		super(props);
		this.state = DEFAULT_STATE;
		this.errorProcess = null;
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps === prevState.prevProps) return null;
		const propertyCss = PropertyTable.arrangeCss(prevState.propertyCss, nextProps.item.css);
		const zipKey = checkNested(nextProps, "item.data.zipKey") ? nextProps.item.data.zipKey : "";
		const htmlFileName = checkNested(nextProps, "item.data.htmlFileName") ? nextProps.item.data.htmlFileName : "";
		const pdfKey = checkNested(nextProps, "item.data.pdfKey") ? nextProps.item.data.pdfKey : "";
		const exLinkPath = checkNested(nextProps, "item.data.type") && (nextProps.item.data.type === "link" || nextProps.item.data.type === 'popUpLink') ? nextProps.item.data.path : "";
		const svg = checkNested(nextProps, "item.svg.attr.points") ? nextProps.item.svg.attr.points : "";
		return {propertyCss, zipKey, htmlFileName, pdfKey, exLinkPath, svg, prevProps: nextProps}
	}

	static arrangeCss(prevCss, nextCss) {
		return {
			...nextCss,
			width: prevCss.width === "" ? prevCss.width : nextCss.width,
			height: prevCss.height === "" ? prevCss.height : nextCss.height,
			top: prevCss.top === "" ? prevCss.top : nextCss.top,
			left: prevCss.left === "" ? prevCss.left : nextCss.left,
		}
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.item.id !== prevProps.item.id) {
			const {item} = this.props;
			const id = item.id || "";
			const propertyCss = item.css;
			const zIndex = checkNested(item, "options.link.zIndex") ? item.options.link.zIndex : 0;
			const type = this.iconTypeValue(this.props.item);

			let editorUse = 1;
			if (item.editor) {
				editorUse = 0;
				if (item.data && item.data.class) {
					editorUse = item.data.class.includes("editor-only") ? 2 : 0;
				}
			}
			this.setState({id, editorUse, zIndex, propertyCss, type})
		}
	}

	componentWillUnmount() {
		this.setState(DEFAULT_STATE);
	}

	eventHandler() {
		return {
			onChange: (e) => {
				this.setState({
					...this.state,
					propertyCss: {
						...this.state.propertyCss,
						[e.target.name]: e.target.value,
					}
				});

				let value = e.target.value || 0;
				if (e.target.name === 'opacity') {
					value = e.target.value === "" ? e.target.value : Number(e.target.value);
				} else {
					value = parseInt(value, 10);
				}

				this.props.changeBookData({
					id: this.props.id,
					page: this.props.page,
					css: {
						[e.target.name]: value
					},
					objMode: this.props.objMode
				});
			},
			pageChange: (e) => {
				let page = Number(e.target.value);
				this.props.changeRectPage({
					id: this.props.id,
					page: page,
					width: this.props.view.width / 2,
					objMode: this.props.objMode,
					direction: this.props.direction,
				});
				this.props.setSelectItem({
					id: this.props.id,
					page: page
				});
			},
			optionsChange: (e) => {
				let {page, objMode, item} = property;
				const itemOptions = Object.keys(this.props.item).length !== 0 ? this.props.item.options : item.options;
				let options = this.props.setOptionsValue(e.target, itemOptions);
				this.props.changeRectOptions({
					id: this.props.id,
					page: this.props.page || page,
					options: {
						[options.name]: options.value
					},
					objMode: this.props.objMode || objMode,
				});
			},
			editorUseChange: (e) => {
				this.setState({
					...this.state,
					editorUse: e.target.value,
				});
				this.props.changeRectEditor({
					id: this.props.id,
					page: this.props.page,
					editorUse: Number(e.target.value),
					objMode: this.props.objMode
				})
			},
			filePathChange: (e) => {
				this.setState({
					[e.target.name]: e.target.value,
				});
			},
			htmlFileNameChange: (e) => {
				this.setState({
					htmlFileKey: e.target.value,
				});
				this.props.changeRectData({
					id: this.props.id,
					page: this.props.page,
					data: {
						[e.target.name]: e.target.value,
					},
					objMode: this.props.objMode,
				});
			},
			exLinkChange: (e) => {
				this.setState({exLinkPath: e.target.value})
				let data = e.target.value;
				this.props.changeRectData({
					id: this.props.id,
					page: this.props.page,
					data: {
						[e.target.name]: data
					},
					objMode: this.props.objMode,
				});
			},
			dataChange: (e) => {
				let data = e.target.value;
				this.props.changeRectData({
					id: this.props.id,
					page: this.props.page,
					data: {
						[e.target.name]: data
					},
					objMode: this.props.objMode,
				});
			},
			StateDataChange: (e) => {
				let data = e.target.value;
				this.setState({
					[e.target.name]: e.target.value,
				});
				this.props.changeRectData({
					id: this.props.id,
					page: this.props.page,
					data: {
						[e.target.name]: data
					},
					objMode: this.props.objMode,
				});
			},
			identifyChange: (e) => {
				this.props.changeRectOptions({
					id: this.props.id,
					page: this.props.page,
					options: {
						identify: e.target.value
					},
					objMode: this.props.objMode,
				});
			},
			thumbKeyChange: (e) => {
				this.props.changeRectThumbKey({
					id: this.props.id,
					page: this.props.page,
					objMode: this.props.objMode,
					thumbKey: e.target.value,
				})
			},
		}
	}

	setPropertyId(e) {
		property.currentId = this.props.id;
		property.changedId = e.target.value;
		property.page = this.props.page;
		property.objMode = this.props.objMode;
		property.item = this.props.item;
	}

	changeId(e) {
		this.setState({
			...this.state,
			id: e.target.value
		});
		property.changedId = e.target.value;
	}

	changePageDataId() {
		let {currentId, changedId, page, objMode} = property;
		this.props.changeRectId({
			currentId: currentId,
			changedId: changedId,
			page: page,
			objMode: objMode
		});
	}

	async zipUpload(e) {
		const result = await fileUpload(e, this.props.modifyRepoPath, this.props.bookId, "html", this.props.repo);
		if (!result || !result.fileUpload) {
			this.showErrorModal();
			return;
		}
		this.updateAfterUpload("html", result.data.key, "zipKey");
	}

	zipFileSave() {
		const originPath = checkNested(this.props, "item.data.zipKey") ? this.props.item.data.zipKey : "";
		const zipPath = this.state.zipPath;
		if (this.props.contentsRepo.hasOwnProperty(zipPath)) {
			this.props.changeRectData({
				id: this.props.id,
				page: this.props.page,
				objMode: this.props.objMode,
				data: {zipPath}
			});
		} else {
			this.setState({zipPath: originPath})
		}
	}

	async pdfUpload(e) {
		const result = await fileUpload(e, this.props.modifyRepoPath, this.props.bookId, "pdf", this.props.repo);
		if (!result || !result.fileUpload) {
			this.showErrorModal();
			return;
		}
		await this.updateAfterUpload("pdf", result.data.key,"pdfKey");
	}

	async updateAfterUpload(kind, key, dataKey) {
		this.props.changeRectData({
			id: this.props.id,
			page: this.props.page,
			objMode: this.props.objMode,
			data: {
				[dataKey]: key
			}
		});
	}

	showErrorModal() {
		this.errorProcess = (e) => {
			e.stopPropagation();
			this.toggleModal("Error", false);
			this.errorProcess = () => {};
		};
		this.toggleModal("Error", true);
	}

	toggleModal(type = "Error", show = true) {
		let modalType = `showModal${type}`;
		this.setState({
			...this.state,
			[modalType]: show,
		})
	}

	contentsElem() {
		const item = this.props.item;
		if (item.data && item.data.type === "contents") {
			return (
				<>
					<Inner.TextProperty
						label={propertyInputLabel.htmlContent}
						name={propertyInputName.htmlContent}
						val={this.state.zipKey}
						readOnly={false}
						onChange={this.eventHandler().StateDataChange}
					/>
					<Inner.FileProperty
						label={""}
						htmlFor={"uploadZipFile"}
						btnName={"アップロード"}
						accept={".zip"}
						dataId={this.props.id}
						bookId={this.props.bookId}
						onChange={this.zipUpload.bind(this)}
					/>
					<Inner.TextProperty
						label={propertyInputLabel.htmlFileName}
						name={propertyInputName.htmlFileName}
						val={this.state.htmlFileName}
						readOnly={false}
						onChange={this.eventHandler().htmlFileNameChange}
					/>
					<Inner.CheckBoxProperty
						label={propertyInputLabel.fullScreen}
						name={propertyInputName.fullScreen}
						checked={item.data.fullScreen ? item.data.fullScreen : false}
						eventHandler={this.fullScreenCheck.bind(this)}
					/>
				</>
			)
		}
	}

	pdfElem() {
		const item = this.props.item;
		if (item.data && item.data.type === "pdf") {
			return (
				<>
					<Inner.TextProperty
						label={propertyInputLabel.pdf}
						name={propertyInputName.pdf}
						val={this.state.pdfKey}
						readOnly={false}
						onChange={this.eventHandler().StateDataChange}
					/>
					<Inner.FileProperty
						label={""}
						htmlFor={"uploadPdfFile"}
						btnName={"アップロード"}
						accept={".pdf"}
						dataId={this.props.id}
						bookId={this.props.bookId}
						onChange={this.pdfUpload.bind(this)}
					/>
				</>
			)
		}
	}

	onChangeDataType(e) {
		let data = this.props.item.data;
		if (e.target.checked) {
			data.type = e.target.name;
		} else {
			delete data.type;
		}

		if (e.target.name === propertyInputName.htmlCheck) {
			this.eventHandler().optionsChange(e);
		}

		this.props.changeRectData({
			id: this.props.id,
			page: this.props.page,
			data: {
				...data,
			},
			objMode: this.props.objMode,
		});
	}

	externalLinkElem() {
		const item = this.props.item;
		if (item.data && (item.data.type === "link" || item.data.type === 'popUpLink')) {
			return (
				<Inner.TextProperty
					label={propertyInputLabel.exLink}
					name={propertyInputName.exLink}
					val={this.state.exLinkPath}
					readOnly={false}
					onChange={this.eventHandler().exLinkChange}
					onBlur={this.eventHandler().dataChange}
				/>
			)
		}
	}

	editLinkPages() {
		this.props.togglePanel({
			name: dataModeConst.LINK_PAGES,
			isOpen: !this.props.panel[dataModeConst.LINK_PAGES],
		});
		let mode = this.props.mode === dataModeConst.LINK_PAGES ? dataModeConst.EDITOR : dataModeConst.LINK_PAGES;
		this.props.changeEditorMode({mode});
	}

	getGroupItem(item) {
		if (checkNested(item, "options.group.id") && checkNested(item, "options.group.index")) {
			return (
				<>
					<Inner.TextProperty
						label={propertyInputLabel.groupName}
						name={propertyInputName.groupName} readOnly={true}
						val={item.options.group.id}
						onChange={this.eventHandler().optionsChange}
					/>
					<Inner.TextProperty
						label={propertyInputLabel.groupIndex}
						name={propertyInputName.groupIndex} readOnly={true}
						val={item.options.group.index}
						onChange={this.eventHandler().optionsChange}
					/>
				</>
			)
		}
	}

	changeRectData(data) {
		this.props.changeRectData({
			id: this.props.id,
			page: this.props.page,
			objMode: this.props.objMode,
			data,
		})
	}

	iconTypeValue(item) {
		if (item.data && item.data.class) {
			for (let iconType of iconTypeOptions) {
				const regExp = `${iconType.className}$`; // 문자열 끝에 iconType.className 이 들어가는지 체크하는 정규식
				if (item.data.class.match(new RegExp(regExp))) {
					return iconType.value;
				}
			}
		}
		return 0;
	}

	iconChange = (options) => {
		let type = Number(options.value);
		// if (type === 0) return;
		this.setState({
			...this.state,
			type: type
		});
		let idx = iconTypeOptions.findIndex((item) => item.value === type);
		let className = "icon " + iconTypeOptions[idx].className;

		if (type === 0) className = "";

		this.changeRectData({
			...this.props.item.data,
			class: className,
		});
		this.props.changeBookData({
			id: this.props.id,
			page: this.props.page,
			css: {
				width: iconTypeOptions[idx].width,
				height: iconTypeOptions[idx].height,
			},
			objMode: this.props.objMode
		});
		this.props.changeRectOptions({
			id: this.props.id,
			page: this.props.page,
			options: {
				link: {
					...this.props.item.options.link,
					level: iconTypeOptions[idx].level,
				}
			},
			objMode: this.props.objMode,
		});
	};

	setLabel(icon) {
		if(icon.value === 0) {
			return (
				<div className={"no-icon"}>
					{icon.name}
				</div>
			);
		}
		return (
			<div>
				<div className={`icon ${this.props.subject} ${icon.className}`}/>
			</div>
		)
	}

	kakezuCheck(e) {
		let data = this.props.item.data;
		if (e.target.checked) {
			data.kakezu = "";
		} else {
			delete data.kakezu;
		}
		this.changeRectData({...data});
	}

	fullScreenCheck(e) {
		let data = this.props.item.data;
		if (e.target.checked) {
			data.fullScreen = true;
		} else {
			data.fullScreen = false;
		}
		this.changeRectData({...data});
	}

	kakezuElem() {
		const item = this.props.item;
		if (item.data && item.data.hasOwnProperty("kakezu")) {
			return (
				<>
					<Inner.NumberProperty
						label={propertyInputLabel.kakezu}
						name={propertyInputName.kakezu}
						val={item.data.kakezu || ""}
						onChange={this.eventHandler().dataChange}
					/>
					<Inner.TextProperty
						label={propertyInputLabel.title}
						name={propertyInputName.title}
						val={item.data.title || ""}
						onChange={this.eventHandler().dataChange}
					/>
				</>
			)
		}
	}

	isEmpty(value) {
		return (typeof value === "undefined" || value === null)
	}

	render() {
		const {id, item, page, pages} = this.props;
		const css = item.css;
		const pageInfoOptions = this.props.pages ? [
			{val: pages.left, name: pages.left},
			{val: pages.right, name: pages.right}
		] : [];
		const myEditorOptions = this.props.id ? editorOptions : [];

		let iconType = iconTypeOptions;
		iconType.map((icon) => {
			icon.label = this.setLabel(icon);
			return icon;
		});

		const iconValue = iconType.filter((icon) => icon.value === this.state.type);

		return (
			<div className="inner-table">
				<table>
					<tbody>
					<Inner.TextProperty
						label={propertyInputLabel.id}
						name={propertyInputName.id} val={this.state.id}
						readOnly={false}
						onFocus={this.setPropertyId.bind(this)}
						onBlur={this.changePageDataId.bind(this)}
						onChange={this.changeId.bind(this)}
						tooltipText={propertyToolTip.id}
					/>
					<Inner.SelectProperty
						label={propertyInputLabel.page}
						name={propertyInputName.page} options={pageInfoOptions}
						eventHandler={this.eventHandler().pageChange} val={page}
						tooltipText={propertyToolTip.page}
					/>
					<Inner.NumberProperty
						label={propertyInputLabel.zIndex}
						name={propertyInputName.zIndex}
						val={item.options.link ? item.options.link.zIndex || "" : ""}
						onChange={this.eventHandler().optionsChange}
						tooltipText={propertyToolTip.zIndex}
					/>
					<Inner.SelectProperty
						label={propertyInputLabel.linkLevel}
						name={propertyInputName.linkLevel}
						options={linkLevelOptions}
						eventHandler={this.eventHandler().optionsChange}
						val={item.options.link ? item.options.link.level || "" : ""}
						tooltipText={propertyToolTip.linkLevel}
					/>
					<Inner.SelectImageProperty
						label={propertyInputLabel.icon}
						name={propertyInputName.icon}
						options={iconType}
						val={iconValue}
						eventHandler={this.iconChange}
					/>
					<Inner.CheckBoxProperty
						label={propertyInputLabel.htmlCheck}
						name={propertyInputName.htmlCheck} suffix={""}
						checked={item.data && item.data.type === "contents"}
						eventHandler={this.onChangeDataType.bind(this)}
						tooltipText={propertyToolTip.iFrame}
					/>
					{this.contentsElem()}

					<Inner.CheckBoxProperty
						label={propertyInputLabel.pdfCheck}
						name={propertyInputName.pdfCheck} suffix={""}
						checked={item.data && item.data.type === "pdf"}
						eventHandler={this.onChangeDataType.bind(this)}
					/>
					{this.pdfElem()}

					<Inner.CheckBoxProperty
						label={propertyInputLabel.exLinkCheck}
						name={propertyInputName.exLinkCheck} suffix={""}
						checked={item.data && item.data.type === "link"}
						eventHandler={this.onChangeDataType.bind(this)}
						tooltipText={propertyToolTip.exLink}
					/>
					{this.externalLinkElem()}
					<Inner.CheckBoxProperty
						label={propertyInputLabel.popupLinkCheck}
						name={propertyInputName.popupLinkCheck} suffix={""}
						checked={item.data && item.data.type === "popUpLink"}
						eventHandler={this.onChangeDataType.bind(this)}
						tooltipText={propertyToolTip.popupLinkCheck}
					/>

					<PosSizeProperty css={this.state.propertyCss} eventHandler={this.eventHandler()}/>

					<Inner.NumberProperty
						label={propertyInputLabel.opacity}
						name={propertyInputName.opacity} suffix={"(0~1)"}
						min={0} step={0.1}
						max={1} val={this.isEmpty(css.opacity)  ? "" : css.opacity}
						eventHandler={this.eventHandler()}
					/>

					{this.getGroupItem(item)}

					<Inner.ButtonProperty
						label={propertyInputLabel.linkPages}
						name={propertyInputName.linkPages}
						btnName={"編集"}
						hello={"hey"}
						onClick={this.editLinkPages.bind(this)}
						tooltipText={propertyToolTip.linkPages}
					/>
					<Inner.TextProperty
						label={propertyInputLabel.thumbKey}
						name={propertyInputName.thumbKey}
						val={item.thumbKey || ""}
						onChange={this.eventHandler().thumbKeyChange}
						tooltipText={propertyToolTip.thumbKey}
					/>
					<Inner.SelectProperty
						label={propertyInputLabel.editor}
						name={propertyInputName.editor} options={myEditorOptions}
						val={this.state.editorUse}
						eventHandler={this.eventHandler().editorUseChange}
						tooltipText={propertyToolTip.editor}
					/>
					<Inner.TextProperty
						label={propertyInputLabel.tooltip}
						name={propertyInputName.tooltip}
						val={item.data ? (item.data.tooltip || "") : ""}
						onChange={this.eventHandler().dataChange}
					/>
					<Inner.TextProperty
						label={propertyInputLabel.identify}
						name={propertyInputName.identify}
						val={item.options.identify || ""}
						onChange={this.eventHandler().identifyChange}
					/>

					<Inner.CheckBoxProperty
						label={propertyInputLabel.kakezuCheck}
						name={propertyInputName.kakezuCheck} suffix={""}
						checked={item.data && item.data.hasOwnProperty("kakezu")}
						eventHandler={this.kakezuCheck.bind(this)}
						tooltipText={propertyToolTip.kakezuCheck}
					/>
					{this.kakezuElem()}

					<SvgProperty
						changeRectSVG={this.props.changeRectSVG}
						changeEditorMode={this.props.changeEditorMode}
						togglePanel={this.props.togglePanel}
						item={item}
						panel={this.props.panel}
						id={this.props.id}
						page={this.props.page}
						objMode={this.props.objMode}
						mode={this.props.mode}
						svg={this.state.svg}
					/>
					</tbody>
				</table>
				<div className="inner-mask" style={{display: id ? "none" : ""}}/>
				{this.state.showModalError && <Modal
					onClickConfirm={(e) => this.errorProcess(e)}
					msg={modalMsg.IMAGE_EXIST}
					title={modalTitle.ERROR}
				/>}
			</div>
		)
	}
}

PropertyTable.defaultProps = {
	item: {
		originId: "",
		id: "",
		css: {},
		options: {},
		page: 0,
		data: {},
	},
	page: 0,
	id: ""
};

PropertyTable.propTypes = {
	item: PropTypes.shape({
		originId: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		css: PropTypes.object.isRequired,
		options: PropTypes.object.isRequired,
		data: PropTypes.object.isRequired,
		page: PropTypes.number.isRequired
	}).isRequired,
	page: PropTypes.number.isRequired,
	id: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyTable);