import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DraggableBox from "../../../../components/Contents/Editor/Panels/EditorPanel/DraggableBox";
import { dragType } from "../../../../interface/constant";

const mapStateToProps = (state) => {
	const { editor } = state.current;
	return {
		scale: editor.scale,
		mode: editor.mode,
		pages: editor.pages,
		view: state.metaData.view,
		leftSubView: state.pageData[editor.pages.left].objects.subView || [],
		rightSubView: state.pageData[editor.pages.right].objects.subView || [],
		leftVideo: state.pageData[editor.pages.left].objects.videoLink || [],
		rightVideo: state.pageData[editor.pages.right].objects.videoLink || [],
		repo: state.repo.page,
		selected: state.current.editor.selected,
	}
};

const mapDispatchToProps = {};

class DragLayer extends Component {
	shouldComponentUpdate(nextProps, nextState, nextContext) {
		let listArr = ["group", "linkPages", "guideMode"];
		return (listArr.includes(this.props.mode) || listArr.includes(nextProps.mode))
	}

	groupElem(width, page, item) {
		const scale = this.props.scale;
		if(item.hasOwnProperty("data")) {
			let classNameArr = item.data.class.split(" ");
			const hasEditorOnly = classNameArr.includes("editor-only");
			if(hasEditorOnly) return;
		}
		if(this.props.selected.id === item.originId) return;
		const thumbKey = item.thumbKey || "";
		const type = this.props.mode === "group" || this.props.mode === "guideMode" ? dragType.GROUP : dragType.LINK_PAGES;
		const currentPages = [this.props.pages.left, this.props.pages.right];
		let canDrag = true;
		if (type === dragType.LINK_PAGES) {
			// 링크페이지 편집일때는 현재페이지만 드래그가 가능하도록 함
			canDrag = this.props.selected.page ? currentPages.includes(this.props.selected.page) : false;
		}
		return (
			<DraggableBox
				type={type}
				key={item.id}
				id={item.id}
				originId={item.originId}
				page={page}
				style={{
					width: item.css.width * scale,
					height: item.css.height * scale,
					top:item.css.top * scale,
					left: item.css.left * scale + width,
				}}
				thumbKey={thumbKey}
				canDrag={canDrag}
			/>
		)
	}

	render() {
		const scale = this.props.scale;
		const widthHalf =  this.props.view.width * scale / 2;

		const {leftSubView, rightSubView, leftVideo, rightVideo} = this.props;
		const {left: leftPage, right: rightPage} = this.props.pages;

		const leftObj = this.props.mode === "group" || this.props.mode === "guideMode" ? leftSubView : leftSubView.concat(leftVideo);
		const rightObj = this.props.mode === "group" || this.props.mode === "guideMode" ? rightSubView : rightSubView.concat(rightVideo);

		return (
			<div id="DragLayer">
				{leftObj.map((item) => this.groupElem(0, leftPage, item))}
				{rightObj.map((item) => this.groupElem(widthHalf, rightPage, item))}
			</div>
		)
	}
}

DragLayer.defaultProps = {
	scale: 1,
	mode: "group",
	pages: PropTypes.shape({
		left: 0,
		right: 0,
	}).isRequired,
	view:  PropTypes.shape({
		width: 0,
		height: 0,
	}).isRequired,
	leftSubView: [],
	rightSubView: [],
	repo: {},
};

DragLayer.propTypes = {
	scale: PropTypes.number.isRequired,
	mode: PropTypes.string.isRequired,
	pages: PropTypes.shape({
		left: PropTypes.number,
		right: PropTypes.number,
	}).isRequired,
	view:  PropTypes.shape({
		width: PropTypes.number,
		height: PropTypes.number,
	}).isRequired,
	leftSubView: PropTypes.array.isRequired,
	rightSubView: PropTypes.array.isRequired,
	repo: PropTypes.object.isRequired,
};
export default connect(mapStateToProps, mapDispatchToProps)(DragLayer);
