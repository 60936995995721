import { combineReducers } from "redux";
import pageData from "./CombineReducers/pageData";
import metaData from "./CombineReducers/metaData";
import current from "./CombineReducers/current";
import auth from "./CombineReducers/auth";
import panel from "./CombineReducers/panel";
import repo from "./CombineReducers/repo";

export default combineReducers({
	pageData: pageData,
	metaData: metaData,
	current: current,
	auth: auth,
	panel: panel,
	repo: repo,
});