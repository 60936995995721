import React, {Component} from 'react';
import {connect} from 'react-redux';
import './Receipt.scss';
import {componentTitle, subject, grade, semester, bookType} from "../../../interface/constant";
import {getBookMetaDataList} from "../../../interface/api";
import Title from "../../../components/Common/Title";
import {downloadCsv, now} from "../../../interface/utils";

const mapStateToProps = ({current, auth}) => ({current, auth});
const mapDispatchToProps = {};

const ITEM_PER_PAGE = 30; // 1페이지당 나타낼 갯수

class Receipt extends Component {
	constructor(props) {
		super(props);
		this.state = {
			bookMetaDataList: [],
			totalPageCount: 0,
			selectedBookType: "t",
			page: 1,
		};

		this.getBookList();
	}

	// 교과서 목록 DB에서 불러오기 및 state에 셋팅
	getBookList = async () =>{
		const bookMetaDataList = await getBookMetaDataList() || [];
		if(bookMetaDataList.hasOwnProperty("data") && bookMetaDataList.hasOwnProperty("totalPageCount")) {
			this.setBookMetaDataListToState(bookMetaDataList.data, bookMetaDataList.totalPageCount);
		}
	};

	// state에 셋팅
	setBookMetaDataListToState(bookMetaDataList, totalPageCount) {
		this.setState({
			...this.state,
			bookMetaDataList,
			totalPageCount,
		})
	}

	// CSV익스포트
	csvExport(bookList) {
		const {totalPageCount} = this.state;

		const fileName = "発行履歴_" + now();
		let title = ", ID, 教科名, 種類, 修正日時, ページ数\r\n";
		let contents = "";
		let total = `,,,,合計,${totalPageCount[this.state.selectedBookType]}`;

		for (let [idx, meta] of bookList.entries()) {
			contents += `${idx+1},`;
			contents += `${meta.author},`;
			contents += `${subject[meta.subject]} ${grade[meta.grade]} ${semester[meta.semester]},`;
			contents += meta.bookType ? `${bookType[meta.bookType]},` : `,`;
			contents += `${meta.lastModified},`;
			contents += `${meta.totalPages}`;
			contents += `\r\n`;
		}

		const csvData = title + contents + total;
		downloadCsv(csvData, fileName);
	}

	// 탭 변경
	changeTab(type) {
		this.setState({
			selectedBookType: type,
			page: 1,
		});
	}

	// 페이징 변경
	changePage(page) {
		this.setState({
			page,
		});
	}

	// 페이징 뷰
	pageElem(allPage) {
		if (allPage > 0) {
			return (
				<tr className={"page-tr"}>
					<td colSpan={6} className={"page-td"}>
						{[...Array(allPage)].map((page, idx) => {
							const pageNum = idx + 1;
							const selectedPage = this.state.page === pageNum ? "selected" : "";
							return (
								<span
									key={"page" + idx}
									className={"page-item " + selectedPage}
									onClick={() => this.changePage(pageNum)}
								>
									{idx + 1}
								</span>
							)
						})}
					</td>
				</tr>
			)
		} else {
			return (
				<tr className={"page-tr"}>
					<td colSpan={6} className={"page-td"}>
						<span className={"page-item selected"}>1</span>
					</td>
				</tr>
			)
		}

	}

	// 교과서 목록 뷰
	bookListElem() {
		const selectedTab1 = this.state.selectedBookType === "t" ? "selected" : "";
		const selectedTab2 = this.state.selectedBookType === "b" ? "selected" : "";

		if(!Array.isArray(this.state.bookMetaDataList)) return (<div/>);
		const bookList = this.state.bookMetaDataList.filter(item => item.bookType === this.state.selectedBookType);

		const allPage = Math.ceil(bookList.length / ITEM_PER_PAGE);
		const minIdx = (this.state.page - 1) * ITEM_PER_PAGE;
		const maxIdx = (this.state.page * ITEM_PER_PAGE) - 1;
		return (
			<>
				<Title
					titleName={componentTitle.PAGE_RECEIPT}
					onclickX={()=>{}}
				/>

				<div className={"receipt-content"}>
					<div className={"receipt-tab"}>
						<span className={"tab-item " + selectedTab1} onClick={() => this.changeTab("t")}>デジタル教科書</span>
						<span className={"tab-item " + selectedTab2} onClick={() => this.changeTab("b")}>デジタルブック</span>
					</div>

					<div className="receipt-content-center">
						<div className={"list-title"}>
							<div className={"list-left"}>発行履歴</div>
							<div className={"list-right"}>
								<input type={"button"} value={"CSVエクスポート"} className={"btn-csv"} onClick={() => this.csvExport(bookList)}/>
							</div>
						</div>
						<table className={"list-table"}>
							<tbody>
								<tr>
									<th className={"idx"}></th>
									<th className={"id"}>名前（ID）</th>
									<th className={"subject"}>教科名</th>
									<th className={"type"}>種類</th>
									<th className={"date"}>日時</th>
									<th className={"totalPage"}>ページ数</th>
								</tr>
								{bookList.map((bookInfo, idx) => {
									if (minIdx <= idx && maxIdx >= idx) {
										return (
											<tr key={"book" + idx}>
												<td>{idx+1}</td>
												<td>{bookInfo.userName}（{bookInfo.author}）</td>
												<td>{subject[bookInfo.subject]} {grade[bookInfo.grade]} {semester[bookInfo.semester]}</td>
												<td>{bookInfo.bookType ? bookType[bookInfo.bookType] : ""}</td>
												<td>{bookInfo.lastModified}</td>
												<td>{bookInfo.totalPages}</td>
											</tr>
										)
									} else {
										return (<></>);
									}
								})}

								<tr className={"total-tr"}>
									<td colSpan={5}>合計</td>
									<td>{this.state.totalPageCount[this.state.selectedBookType]}</td>
								</tr>

								{this.pageElem(allPage)}

							</tbody>
						</table>
					</div>
				</div>
			</>
		);
	}

	render() {
		return (
			<div id={"Receipt"} className={this.props.className + " receipt-component-area"}>
				{this.bookListElem()}
			</div>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Receipt);