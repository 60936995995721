import React, { Component } from 'react';

import "./EditBookLog.scss";
import {getAdminLog} from "../../../interface/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class EditAdminLog extends Component{
	constructor(props) {
		super(props);
		let today = new Date();
		let year = today.getFullYear();
		let month = today.getMonth();
		let dates = today.getDate()+1;
		this.state = {
			logList: [],
			count: 0,
			page: 0,
			StartDate: "",
			EndDate: new Date(),
			selectedStartDate: "",
			selectedEndDate: new Date(year, month, dates, 8,59,59),
		};
		this.getLogList();
	}

	getLogList = async (page = 0, selectedStartDate = this.state.selectedStartDate, selectedEndDate = this.state.selectedEndDate) =>{
		this.setState({
			page,
		});
		const logList = await getAdminLog("admin_log", page, selectedStartDate, selectedEndDate) || [];
		if (logList && logList.data) {
			return this.setLogListToState(logList.data, logList.count);
		} else {
			return this.setLogListToState([], 0);
		}
	};

	setLogListToState(logList, count) {
		this.setState({
			...this.state,
			logList: logList,
			count: count
		})
	}




	startDate(date) {
		const year = date.getFullYear();
		const month = date.getMonth();
		const dates = date.getDate();
		this.setState({
			StartDate: new Date(year, month, dates),
			selectedStartDate: new Date(year, month, dates, 9)
		});
		this.getLogList(0, new Date(year, month, dates,9), this.state.selectedEndDate);
	}

	endDate(date) {
		const year = date.getFullYear();
		const month = date.getMonth();
		const dates = date.getDate()+1;
		this.setState({
			EndDate: new Date(year, month, dates-1, 0),
			selectedEndDate: new Date(year, month, dates, 8,59,59)
		});
		this.getLogList(0, this.state.selectedStartDate, new Date(year, month, dates, 8,59,59));
	}

	changePage(page) {
		this.getLogList(page);
	}

	pageElem () {
		const PAGE_PER_COUNT = 10; // 한번에 나타낼 최대 페이지 수
		const totalData = this.state.count;
		const totalPage = Math.ceil(totalData / 100); // 총 페이지 수

		const currentMaxPage = (Math.floor(this.state.page / PAGE_PER_COUNT) + 1) * PAGE_PER_COUNT; // 현재페이지 기준에서 최대 페이지 번호(ex: 3페이지일경우 10, 27페이지일경우 30)
		const pageCount = currentMaxPage <= totalPage ? PAGE_PER_COUNT : totalPage % PAGE_PER_COUNT; // 현재페이지 기준에서 표시할 페이지의 개수(ex: 총 페이지수가 33일때, 현재페이지가 3페이지일경우 10, 31페이지일경우 3)

		const prevHidden = this.state.page === 0 ? "hidden" : "";
		const nextHidden = this.state.page >= totalPage - 1 ? "hidden" : "";

		return (
			<tr className={"page-tr"}>
				<td colSpan={5} className={"page-td"}>
					<span
						className={"page-item " + prevHidden}
						onClick={() => this.changePage(0)}
					>
						&lt;&lt;
					</span>
					<span
						className={"page-item " + prevHidden}
						onClick={() => this.changePage(this.state.page - 1)}
					>
						&lt;
					</span>
					{[...Array(pageCount)].map((page, idx) => {
						const pageNum = idx + (currentMaxPage - PAGE_PER_COUNT);
						const selectedPage = this.state.page === pageNum ? "selected" : "";
						return (
							<span
								key={"page" + pageNum}
								className={"page-item " + selectedPage}
								onClick={() => this.changePage(pageNum)}
							>
							{pageNum + 1}
						</span>
						)
					})}
					<span
						className={"page-item " + nextHidden}
						onClick={() => this.changePage(this.state.page + 1)}
					>
						&gt;
					</span>
					<span
						className={"page-item " + nextHidden}
						onClick={() => this.changePage(totalPage - 1)}
					>
						&gt;&gt;
					</span>
				</td>
			</tr>
		)
	}



	render() {
		const {logList} = this.state;

		return (
			<div>
				<br/>
				<DatePicker style = {{display: "inline-block"}}
					selected={this.state.StartDate}
					dateFormat="yyyy-MM-dd"
					onChange={(date) => this.startDate(date)}
				/>
				<span className={"between_date"}>～</span>
				<DatePicker style = {{display: "inline-block"}}
					selected={this.state.EndDate}
					dateFormat="yyyy-MM-dd"
					onChange={(date) => this.endDate(date)}
				/>

				<table className={"log-list-table"}>
					<tbody>
					<tr>
						<th className={"idx"}>No</th>
						<th className={"userId"}>ユーザーID</th>
						<th className={"groupId"}>グループID</th>
						<th className={"action"}>アクション</th>
						<th className={"regDate"}>日付</th>
					</tr>
					{logList.map((logData, idx) => {
						return (
							<tr key={idx}>
								<td>{logData.idx}</td>
								<td>{logData.userId}</td>
								<td>{logData.groupId}</td>
								<td>{logData.action}</td>
								<td>{logData.regDate}</td>
							</tr>
						);
					})}
					{this.pageElem()}
					</tbody>
				</table>



			</div>
		)
	}
}

export default EditAdminLog;