import React, { Component } from 'react';

class HomeButtons extends Component {
	render() {
		return (
			<div className={"btnlist-container"}>
				<div className={"homebtn-left"}>
					{this.props.onClickNew && <input
						className={"home-new-btn btn_openfile"}
						type={"button"}
						value={"新しい教科書を作成"}
						onClick={this.props.onClickNew}
					/>}
				</div>
				<div className={"homebtn-right"}>
					{this.props.onClickDiscard && <input
						className={"home-edit-btn btn_white_line"} type={"button"} value={"破棄"}
						onClick={this.props.onClickDiscard}
					/>}
					{this.props.onClickDelete && <input
						className={"home-edit-btn btn_white_line"} type={"button"} value={"削除"}
						onClick={this.props.onClickDelete}
					/>}
					{this.props.onClickMakeZip && <input
						className={"home-edit-btn btn_white_line"} type={"button"} value={"ファイル圧縮"}
						onClick={this.props.onClickMakeZip}
					/>}
					{this.props.onClickDownload && <input
						className={"home-edit-btn btn_white_line"} type={"button"} value={"ダウンロード"}
						onClick={this.props.onClickDownload}
					/>}
					{this.props.onClickPreview && <input
						className={"home-edit-btn btn_white_line"} type={"button"} value={"プレビュー"}
						onClick={this.props.onClickPreview}
					/>}
					{this.props.onClickMake && <input
						className={"home-edit-btn btn_white_line"} type={"button"} value={"変換"}
						onClick={this.props.onClickMake}
					/>}
					{this.props.onClickRead && <input
						className={"home-edit-btn btn_white_line"} type={"button"} value={"読み込み"}
						onClick={this.props.onClickRead}
					/>}
					{this.props.onClickOpen && <input
						className={"home-edit-btn btn_white_line"} type={"button"} value={"開く"}
						onClick={this.props.onClickOpen}
					/>}
					{this.props.onClickClean && <input
						className={"home-edit-btn btn_white_line"} type={"button"} value={"掃除"}
						onClick={this.props.onClickClean}
					/>}
					{this.props.onClickAuthorChange && <input
						className={"home-edit-btn btn_white_line"} type={"button"} value={"担当者変更"}
						onClick={this.props.onClickAuthorChange}
					/>}
					{this.props.author !== null && <input
						className={"home-edit-btn home-edit-text"} type={"text"} value={this.props.author}
						onChange={this.props.authorTextChange}
					/>}
				</div>
			</div>
		)
	}
}

export default HomeButtons;