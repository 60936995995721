import React, { Component } from 'react';
import Panel from "../Panel";
import './PagePanel.scss';

import PageList from "../../../../../containers/Contents/Editor/PageList";

const panelState = {
	id: "PagePanel",
	name: "page",
	label: "ページ",
	style: {
		width: 220,
		height: 395,
		top: 10,
		right: 10,
	}
};

class PagePanel extends Component {
	render() {
		return (
			<div className="panel-page-view-inner inner-contents">
				<PageList socket={this.props.socket}/>
			</div>
		)
	}
}

export default Panel(PagePanel, panelState);