import React, {Component} from 'react';
import {connect} from "react-redux";
import {RikaCheckTable} from "../../../components/Contents/Editor/Panels/SubviewPanel/innerSubview";
import {changeRectData, changeRectDataBlock} from "../../../reducers/CombineReducers/pageData";
import {objectModeConst, subViewRect} from "../../../interface/constant";
import {randomStr} from "../../../interface/utils";
import {getObjects} from "../Editor/EditorLayers/ObjectLayer";

const mapStateToProps = state => {
	const {id, page, blockId} = state.current.editor.selected;
	const {editor} = state.current;
	const objMode = objectModeConst[editor.objectMode];
	let props = {};
	if (id && page) {
		let objectList = state.pageData[page].objects[objMode];
		const idx = objectList.findIndex((item) => item.originId === id);
		const subViewSettings = editor.objectMode === "subView" ? objectList[idx].data.subViewSettings : null;
		props = {
			selectedId: id,
			selectedPage: page,
			selectedBlockId: blockId,
			subViewObj: state.pageData[page].objects["subView"],
			subViewSettings,
		}
	}
	return props;
};

const mapDispatchToProps = {
	changeRectData,
	changeRectDataBlock,
};

const ADD_OFFSET = 10;
let checkOffsetX = ADD_OFFSET;
let checkOffsetY = ADD_OFFSET;

class RikaCheck extends Component {
	subViewSettingEvent() {
		return {
			checkConnectChange: (e) => {
				const data = {
					rectId: e.target.value,
				};

				this.props.changeRectDataBlock({
					id: this.props.selectedId,
					page: this.props.selectedPage,
					blockId: this.props.selectedBlockId,
					data: data,
					subViewType: subViewRect.rikaCheck,
				});
			},
			checkSheetSelectEvent: (e) => {
				const cls = e.target.getAttribute("name");
				const {subViewSettings} = this.props;
				let check = subViewSettings.options.check || [];
				const c_id = randomStr(8);
				let defaultCheck = [{
					id: "check-" + c_id,
					rectId: "",
					connectId: "",
					width: 30,
					height: 30,
					left: checkOffsetX,
					top: checkOffsetY,
					cls: cls
				}];

				if (cls === "check2") {
					defaultCheck[0].connectId = "checkMask-" + c_id;
					defaultCheck.push({
						id: "checkMask-" + c_id,
						connectId: "check-" + c_id,
						width: 100,
						height: 100,
						left: checkOffsetX,
						top: checkOffsetY + 100,
						cls: "mask",
					});
				}

				if(checkOffsetY < 480){
					checkOffsetX += ADD_OFFSET;
					checkOffsetY += ADD_OFFSET;
				}else{
					checkOffsetX = checkOffsetX-460;
					checkOffsetY = checkOffsetY-480;
				}

				const data = {
					subViewSettings: {
						...subViewSettings,
						options: {
							...subViewSettings.options,
							check: check.concat(defaultCheck),
						},
					}
				};

				this.props.changeRectData({
					id: this.props.selectedId,
					page: this.props.selectedPage,
					data: data,
					objMode: "subView",
				});
			},
			changeCheckCss: (e) => {
				const value = Number(e.target.value);
				const data = {
					[e.target.name]: value
				};

				this.props.changeRectDataBlock({
					id: this.props.selectedId,
					page: this.props.selectedPage,
					blockId: this.props.selectedBlockId,
					data: data,
					subViewType: subViewRect.rikaCheck,
				});
			},
		}
	}

	render() {
		const {subViewSettings, subViewObj, selectedBlockId} = this.props;
		const check = subViewSettings.options.check;
		const checkIdx = check ? check.findIndex((item) => item.id === selectedBlockId) : -1;
		const rectMask = getObjects(subViewObj, "check") || [];
		const subViewSettingsCheck = checkIdx < 0 ? null : subViewSettings.options.check[checkIdx];
		const useConnect = subViewSettingsCheck ? subViewSettingsCheck.cls === "check2" || false : false;
		return (
			<RikaCheckTable
				event={this.subViewSettingEvent()}
				rectMask={rectMask}
				subViewSettingsCheck={subViewSettingsCheck}
				selectedCheckId={selectedBlockId}
				useConnect={useConnect}
				tabMenu={this.props.tabMenu}
			/>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(RikaCheck);