import React, {Component} from 'react';
import {connect} from "react-redux";
import {ShakaiDescTable} from "../../../components/Contents/Editor/Panels/SubviewPanel/innerSubview";
import {changeRectData, changeRectDataBlock} from "../../../reducers/CombineReducers/pageData";
import {maskColorOptions, objectModeConst, subViewRect} from "../../../interface/constant";
import {randomStr} from "../../../interface/utils";
import {getObjects} from "../Editor/EditorLayers/ObjectLayer";

const mapStateToProps = state => {
    const {id, page, blockId} = state.current.editor.selected;
    const {editor} = state.current;
    const objMode = objectModeConst[editor.objectMode];

    // 지면 redLine을 양면페이지에서 가져옴
    const page2 = page % 2 === 0 ? page -1 : page + 1;
    let pagesData = [page, page2];
    let redLineObj = [];
    for(let page of pagesData) {
        let pageObj = state.pageData[page].objects["redLine"];
        if (pageObj) {
            redLineObj = redLineObj.concat(getObjects(pageObj));
        }
    }

    let props = {};
    if (id && page) {
        let objectList = state.pageData[page].objects[objMode];
        const idx = objectList.findIndex((item) => item.originId === id);
        const subViewSettings = editor.objectMode === "subView" ? objectList[idx].data.subViewSettings : null;
        props = {
            selectedId: id,
            selectedPage: page,
            selectedBlockId: blockId,
            blockObj: state.pageData[page].objects["block"],
            redLineObj,
            subViewSettings,
        }

    }
    return props;
};

const mapDispatchToProps = {
    changeRectData,
    changeRectDataBlock,
};

const ADD_OFFSET = 10;
let rectOffsetX = ADD_OFFSET;
let rectOffsetY = ADD_OFFSET;

class ShakaiDesc extends Component {
    constructor(props) {
        super(props);
        this.textInput = {
            title: React.createRef(),
            desc: React.createRef(),
            hurigana: React.createRef(),
        };
        this.state = {
            text: {
                kanji: "",
                hurigana: "",
                type: "",
            }
        }
    }

    subViewSettingEvent() {
        return {
            textChange: (e) => {
                this.setState({
                    ...this.state,
                    text: {
                        ...this.state.text,
                        [e.target.name]: e.target.value,
                    }
                });
            },
            textUpdate: (e) => {
                const {subViewSettings} = this.props;
                const data = {
                    subViewSettings: {
                        ...subViewSettings,
                        options: {
                            ...subViewSettings.options,
                            [e.target.name]: e.target.value,
                        },
                    }
                };
                this.props.changeRectData({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    data: data,
                    objMode: "subView",
                });
            },
            huriganaClick: (type) => {
                const input = this.textInput[type].current;
                const startPos = input.selectionStart;
                const endPos = input.selectionEnd;
                const kanji = input.value.substr(startPos, endPos - startPos);
                this.setState({
                    ...this.state,
                    text: {
                        ...this.state.text,
                        kanji: kanji,
                        type: type,
                    }
                }, () => {
                    this.textInput.hurigana.current.focus();
                });
            },
            huriganaChange: (e) => {
                const {text} = this.state;
                const {subViewSettings} = this.props;

                if(!subViewSettings) return;

                let result = subViewSettings.options[text.type];
                if(e.target.value !== "") {
                    const input = this.textInput[text.type].current;
                    const startPos = input.selectionStart;
                    const endPos = input.selectionEnd;
                    result = input.value.substr(0, startPos)
                        + `<ruby><rb>${text.kanji}</rb><rt>${text.hurigana}</rt></ruby>`
                        + input.value.substr(endPos, input.value.length - 1);
                }
                this.setState({
                    ...this.state,
                    text: {
                        ...this.state.text,
                        kanji: "",
                        hurigana: "",
                        type: "",
                    }
                });

                const data = {
                    subViewSettings: {
                        ...subViewSettings,
                        options: {
                            ...subViewSettings.options,
                            [text.type]: result,
                        },
                    }
                };
                this.props.changeRectData({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    data: data,
                    objMode: "subView",
                });
            },
            shakaiDescObjectModeChange: (e) => {
                this.props.stateChange("shakaiDescObjectMode", e.target.value);
            },
            addObject: () => {
                const {subViewSettings} = this.props;
                const shakaiDescObjectMode = this.props.shakaiDescObjectMode;
                let obj = subViewSettings.options[shakaiDescObjectMode] || [];

                let defaultObj = this.setDefaultObject(shakaiDescObjectMode);

                if(rectOffsetY < 580){
                    rectOffsetX += ADD_OFFSET;
                    rectOffsetY += ADD_OFFSET;
                }else{
                    rectOffsetX = rectOffsetX-560;
                    rectOffsetY = rectOffsetY-580;
                }

                const data = {
                    subViewSettings: {
                        ...subViewSettings,
                        options: {
                            ...subViewSettings.options,
                            [shakaiDescObjectMode]: obj.concat(defaultObj),
                        },
                    }
                };

                this.props.changeRectData({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    data: data,
                    objMode: "subView",
                });
            },
            objectConnectChange: (e) => {
                const data = {
                    rectId: e.target.value,
                };

                this.props.changeRectDataBlock({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    blockId: this.props.selectedBlockId,
                    data: data,
                    subViewType: this.props.shakaiDescObjectMode,
                });
            },
            colorTextChange: (e) => {
                const {subViewSettings, selectedBlockId} = this.props;
                // 컬러코드 맨앞에 # 넣어주기
                const value = e.target.value ? e.target.value[0] !== "#" ? "#" + e.target.value : e.target.value : "";

                const block = subViewSettings.options.block;
                const blockIdx = block ? block.findIndex((item) => item.id === selectedBlockId) : -1;
                block[blockIdx].fill = value;

                const data = {
                    subViewSettings: {
                        ...subViewSettings,
                        options: {
                            ...subViewSettings.options,
                            block: block,
                        },
                    }
                };
                this.props.changeRectData({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    data: data,
                    objMode: "subView",
                });
            },
            colorSelect: (color) => {
                const {subViewSettings, selectedBlockId} = this.props;
                const value = color.value;

                const block = subViewSettings.options.block;
                const blockIdx = block ? block.findIndex((item) => item.id === selectedBlockId) : -1;
                block[blockIdx].fill = value;

                const data = {
                    subViewSettings: {
                        ...subViewSettings,
                        options: {
                            ...subViewSettings.options,
                            block: block,
                        },
                    }
                };
                this.props.changeRectData({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    data: data,
                    objMode: "subView",
                });
            },
            redLineInputChange: (e) => {
                const value = e.target.value.replace(/(^0+)/, ""); // 0으로 시작하는 값 받을시 0 제거(ex: 03 -> 3) (** 그냥 0만있어도 제거됨! **)
                const data = {
                    [e.target.name]: value || 0,
                };

                this.props.changeRectDataBlock({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    blockId: this.props.selectedBlockId,
                    data: data,
                    subViewType: subViewRect.shakaiRedLine,
                });
            },
            changeRectCss: (e) => {
                const value = Number(e.target.value);
                const data = {
                    [e.target.name]: value
                };

                this.props.changeRectDataBlock({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    blockId: this.props.selectedBlockId,
                    data: data,
                    subViewType: this.props.shakaiDescObjectMode,
                });
            },
        }
    }

    setDefaultObject(shakaiDescObjectMode) {
        let defaultObj = {};
        if (shakaiDescObjectMode === "block") {
            defaultObj = {
                id: shakaiDescObjectMode + "-" + randomStr(8),
                rectId: "",
                width: 200,
                height: 100,
                left: rectOffsetX,
                top: rectOffsetY,
                fill: "",
            }

        } else if (shakaiDescObjectMode === "redLine") {
            defaultObj = {
                id: shakaiDescObjectMode + "-" + randomStr(8),
                rectId: "",
                group: 0,
                width: 100,
                height: 50,
                left: rectOffsetX,
                top: rectOffsetY
            }
        }
        return defaultObj;
    }

    setLabel(color) {
        if(color.value === "") {
            return (
                <div>
                    <div className={"mask-color-select-contents"}>
                        <span className={"mask-color-select-text"}>{color.name}</span>
                    </div>
                </div>
            );
        }
        return (
            <div>
                <div className={"mask-color-select-contents"} style={{backgroundColor: color.value}}>
                    <span className={"mask-color-select-text"}>{color.value}</span>
                </div>
            </div>
        )
    }

    render() {
        const {subViewSettings, blockObj, redLineObj, selectedBlockId} = this.props;
        const options = subViewSettings ? subViewSettings.options || {} : {};

        let rectObj = [];
        const shakaiDescObjectMode = this.props.shakaiDescObjectMode;
        if(shakaiDescObjectMode === subViewRect.shakaiMask) rectObj = getObjects(blockObj, shakaiDescObjectMode) || [];
        else if(shakaiDescObjectMode === subViewRect.shakaiRedLine) rectObj = getObjects(redLineObj, shakaiDescObjectMode) || [];

        const descObj = subViewSettings.options[shakaiDescObjectMode];
        const idx = descObj ? descObj.findIndex((item) => item.id === selectedBlockId) : -1;

        maskColorOptions.map((color) => {
            color.label = this.setLabel(color);
            return color;
        });

        const existBlock = shakaiDescObjectMode === subViewRect.shakaiMask && idx >= 0 && descObj[idx];
        const selectedColorValue = existBlock ? maskColorOptions.filter((color) => color.value === descObj[idx].fill) : null;
        const colorSelectValue = selectedColorValue && selectedColorValue.length <= 0 ? maskColorOptions[0] : selectedColorValue;

        const colorTextValue = (existBlock && descObj[idx].fill) ? descObj[idx].fill : "";
        const colorCheck = /^#[0-9a-f]{3,6}$/i;	// 컬러코드 유효성 체크 정규식
        const isValidColor = colorCheck.test(colorTextValue) ? colorTextValue : "";

        const maskSettingsHide = existBlock ? "" : "hide";
        const redLineSettingsHide = shakaiDescObjectMode === subViewRect.shakaiRedLine && idx >= 0 && descObj[idx] ? "" : "hide";

        return (
            <ShakaiDescTable
                text={this.state.text}
                options={options}
                event={this.subViewSettingEvent()}
                textInput={this.textInput}
                toggled={this.state.text.kanji ? "" : "hide"}
                tabMenu={this.props.tabMenu}
                shakaiDescObjectMode={shakaiDescObjectMode}
                rectObj={rectObj}
                selectedRectId={selectedBlockId}
                subViewSettingsRect={ idx < 0 ? null : descObj[idx]}
                maskColorOptions={maskColorOptions}
                colorSelectValue={colorSelectValue}
                colorTextValue={colorTextValue || ""}
                color={isValidColor || ""}
                maskSettingsHide={maskSettingsHide}
                redLineSettingsHide={redLineSettingsHide}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShakaiDesc);