import React, { Component } from 'react';
import Panel from "../Panel";
import "./CheckSheetPanel.scss";
import CheckSheet from "../../../../../containers/Contents/Editor/CheckSheet";

const panelState = {
	id:  "CheckSheetPanel",
	label: "",
	name: "checkSheet",
	style: {
		height: 120,
		top: 100,
		left: 10,
	}
};

class CheckSheetPanel extends Component {
	keyDown(e) {
		// ObjectLayerのkeydownイベントを実行しない
		e.nativeEvent.stopImmediatePropagation();
	}

	render() {
		return (
			<div className="panel-object-list-inner inner-contents" onKeyDown={this.keyDown.bind(this)}>
				<CheckSheet/>
			</div>
		)
	}
}

export default Panel(CheckSheetPanel, panelState);