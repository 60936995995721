import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";

import {
	TextProperty, CheckBoxProperty, SelectProperty, NumberProperty, FileProperty, ButtonProperty,
} from "../../../../components/Contents/Editor/Panels/PropertyPanel/innerProperty";
import PosSizeProperty from "../../../../components/Contents/Editor/Panels/PropertyPanel/posSizeProperty";

import {
	changeBookData, changeRectId, changeRectPage, changeRectOptions,
	changeRectEditor, changeRectEditorIgnore, changeRectEditorBG, changeRectEditorTextCss,
	addRectEditorText, changeRectData, changeEditorTextOrder, deleteEditorText,
} from '../../../../reducers/CombineReducers/pageData';
import { setSelectItem, setSelectedTextId } from "../../../../reducers/CombineReducers/current";
import { modifyRepoPath } from "../../../../reducers/CombineReducers/repo";

import { checkNested } from "../../../../interface/utils";
import {
	objectModeConst,
	propertyInputName,
	propertyInputLabel,
	propertyToolTip,
	subjectKeyName,
	myEditorSuffix,
	fontColorCode, linkLevelOptions, modalMsg, modalTitle
} from '../../../../interface/constant';
import Modal from "../../../../components/Common/Modal";
import {fileUpload} from "../../../../components/Common/Upload";

const mapStateToProps = (state) => {
	const objMode = objectModeConst[state.current.editor.objectMode];
	let {id, page, textId} = state.current.editor.selected;
	let prop = {};
	if (id && page) {
		let objectInfo = state.pageData[page].objects[objMode];
		let item = objectInfo.filter((item) => item.originId === id)[0];
		prop = {
			item,
			pages: state.current.editor.pages,
			bookChanged: state.current.book.changed,
			isTextMode: state.current.editor.isTextMode,
			view: state.metaData.view,
			bookId: state.metaData.bookId,
			direction: state.metaData.direction,
			subject: state.metaData.subject,
			repo: state.repo,
			id,
			page,
			textId,
			objMode
		};
	}
	return prop;
};

const mapDispatchToProps = {
	changeBookData,
	changeRectId,
	changeRectPage,
	changeRectOptions,
	changeRectEditor,
	changeRectEditorIgnore,
	setSelectItem,
	modifyRepoPath,
	changeRectEditorBG,
	changeRectEditorTextCss,
	addRectEditorText,
	changeRectData,
	changeEditorTextOrder,
	deleteEditorText,
	setSelectedTextId,
};

const editorOptions = [
	{val: 0, name: "含め"},
	{val: 1, name: "除外"},
	{val: 2, name: "専用",},
];

const fontColorOptions = Object.keys(fontColorCode).map((key) => {
	return {val: fontColorCode[key], name: key}
});

let property = {
	currentId: "",
	changedId: "",
	page: 0,
	objMode: "",
	item: {}
};

const defaultTextCss = {
	fontSize: 1,
	lineHeight: 135,
	marginLeft: 0,
	marginTop: 0,
	color: fontColorCode.black,
	fontWeight: "",
	position: "",
	top: 0,
	left: 0,
	width: 0,
};

class MyEditorTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: "",
			editorUse: 0,
			zIndex: 0,
			isTextMode: false,
			css: defaultTextCss,
			selectedTextId: "",
			propertyCss: {
				width: "",
				height: "",
				top: "",
				left: "",
			},
			showModalError: false,
		}
		this.errorProcess = null;
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps === prevState.prevProps) return null;
		const propertyCss = MyEditorTable.arrangeCss(prevState.propertyCss, nextProps.item.css);
		return {propertyCss}
	}

	static arrangeCss(prevCss, nextCss) {
		return {
			...nextCss,
			width: prevCss.width === "" ? prevCss.width : nextCss.width,
			height: prevCss.height === "" ? prevCss.height : nextCss.height,
			top: prevCss.top === "" ? prevCss.top : nextCss.top,
			left: prevCss.left === "" ? prevCss.left : nextCss.left,
		}
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.item.id !== prevProps.item.id) {
			const {item} = this.props;
			const id = item.id || "";
			const propertyCss = item.css;
			const zIndex = checkNested(item, "options.link.zIndex") ? item.options.link.zIndex : 0;
			const selectedTextId = "";

			let editorUse = 1;
			if (item.editor) {
				editorUse = 0;
				if (item.data && item.data.class) {
					editorUse = item.data.class.includes("editor-only") ? 2 : 0;
				}
			}
			this.setState({id, editorUse, zIndex, propertyCss, selectedTextId})
		}

		if (this.props.textId !== prevProps.textId) {
			if(!this.props.textId) {
				this.setState({...this.state, css: defaultTextCss});
			}
			const {item, textId} = this.props;
			if (!item.editor) return;
			const idx = item.editor.text ? item.editor.text.contents.findIndex((item) => item.id === textId) : -1;
			if (idx < 0) return;
			const textCss = item.editor.text.contents[idx].css || defaultTextCss;
			let css = defaultTextCss;
			if (textCss !== defaultTextCss) {
				css = Object.assign({}, textCss, this.textCssStringToNumber(textCss));
			}
			this.setState({...this.state, css})
		}
	}

	eventHandler() {
		return {
			onChange: (e) => {
				const value = e.target.value || 0;

				this.props.changeBookData({
					id: this.props.id,
					page: this.props.page,
					css: {
						[e.target.name]: (e.target.name !== 'opacity' ? parseInt(value, 10) : Number(value))
					},
					objMode: this.props.objMode
				});
			},
			pageChange: (e) => {
				let page = Number(e.target.value);
				this.props.changeRectPage({
					id: this.props.id,
					page: page,
					width: this.props.view.width / 2,
					objMode: this.props.objMode,
					direction: this.props.direction,
				});
				this.props.setSelectItem({
					id: this.props.id,
					page: page
				});
			},
			optionsChange: (e) => {
				let {page, objMode, item} = property;
				const itemOptions = Object.keys(this.props.item).length !== 0 ? this.props.item.options : item.options;
				let options = this.props.setOptionsValue(e.target, itemOptions);
				this.props.changeRectOptions({
					id: this.props.id,
					page: this.props.page || page,
					options: {
						[options.name]: options.value
					},
					objMode: this.props.objMode || objMode,
				});
			},
			dataChange: (e) => {
				let data = e.target.value;
				this.props.changeRectData({
					id: this.props.id,
					page: this.props.page,
					data: {
						[e.target.name]: data
					},
					objMode: this.props.objMode,
				});
			},
			filePathChange: (e) => {
				this.setState({
					[e.target.name]: e.target.value,
				});
			},
			htmlFileNameChange: (e) => {
				this.props.changeRectData({
					id: this.props.id,
					page: this.props.page,
					data: {
						[e.target.name]: e.target.value,
					},
					objMode: this.props.objMode,
				});
			},
			editorImageChangeRepo: (e) => {
				this.props.changeRectEditorBG({
					id: this.props.id,
					page: this.props.page,
					objMode: this.props.objMode,
					bgType: e.target.name,
					repoKey: e.target.value,
				});
			},
			editorUseChange: (e) => {
				this.setState({
					...this.state,
					editorUse: e.target.value,
				});
				this.props.changeRectEditor({
					id: this.props.id,
					page: this.props.page,
					editorUse: Number(e.target.value),
					objMode: this.props.objMode
				});
			},
			editorIgnoreChange: (e) => {
				this.props.changeRectEditorIgnore({
					id: this.props.id,
					page: this.props.page,
					ignore: e.target.checked,
					objMode: this.props.objMode
				});
			},
			editorTextCssChange: (e) => {
				const {editor} = this.props.item;
				const value = this.setTextCssValue(e.target);
				this.setState({
					...this.state,
					css: {
						...this.state.css,
						[e.target.name]: value,
					},
				});

				const idx = editor.text ? editor.text.contents.findIndex((item) => item.id === this.props.textId) : -1;
				const suffix = myEditorSuffix[e.target.name] || "";
				const isCss = idx !== -1 && editor.text.contents[idx].css;

				let css = {};
				if (isCss) {
					css = {
						...this.props.item.editor.text.contents[idx].css,
						[e.target.name]: value + suffix,
					}
				} else {
					css = {
						fontSize: this.state.css.fontSize + "em",
						lineHeight: this.state.css.lineHeight + "%",
						marginLeft: this.state.css.marginLeft + "%",
						marginTop: this.state.css.marginTop + "%",
						[e.target.name]: value + suffix,
					}
				}
				this.props.changeRectEditorTextCss({
					id: this.props.id,
					page: this.props.page,
					css: css,
					textId: this.props.textId,
				})
			},
			addEditorTextDiv: (e) => {
				if (!this.props.item.editor.text || !this.props.item.editor.text.contents) return;
				const idx = this.props.item.editor.text.contents.findIndex((contents) => contents.id === this.props.textId);
				if (idx >= 0) {
					this.props.addRectEditorText({
						id: this.props.id,
						page: this.props.page,
					});
				}
			},
			myEditorCssCheck: (e) => {
				let data = {
					myeditorCssOn: e.target.checked,
				};
				if (!this.props.item.data || !this.props.item.data.myeditorCss) {
					data.myeditorCss = {
						width: 200,
						height: 100,
						top: 10,
						left: 10,
						position: "absolute",
					}
				}
				this.props.changeRectData({
					id: this.props.id,
					page: this.props.page,
					data,
					objMode: this.props.objMode,
				});
			},
			myEditorCssChange: (e) => {
				this.props.changeRectData({
					id: this.props.id,
					page: this.props.page,
					data: {
						myeditorCss: {
							...this.props.item.data.myeditorCss,
							[e.target.name]: Number(e.target.value),
							position: "absolute",
						},
					},
					objMode: this.props.objMode,
				});
			}
		}
	}

	setTextCssValue(target) {
		let value = target.value;
		if(target.name === propertyInputName.bold) {
			value = target.checked ? "bold" : "";
		} else if (target.name === propertyInputName.absolute) {
			value = target.checked ? "absolute" : "";
		}
		return value;
	}

	setPropertyId(e) {
		property.currentId = this.props.id;
		property.changedId = e.target.value;
		property.page = this.props.page;
		property.objMode = this.props.objMode;
		property.item = this.props.item;
	}

	changeId(e) {
		this.setState({
			...this.state,
			id: e.target.value
		});
		property.changedId = e.target.value;
	}

	changePageDataId() {
		let {currentId, changedId, page, objMode} = property;
		this.props.changeRectId({
			currentId: currentId,
			changedId: changedId,
			page: page,
			objMode: objMode
		});
	}

	async imageUpload(e) {
		const id = e.target.id;
		console.log(id);
		const result = await fileUpload(e, this.props.modifyRepoPath, this.props.bookId, "image", this.props.repo);
		if (!result || !result.fileUpload) {
			this.showErrorModal();
			return;
		}
		this.props.changeRectEditorBG({
			id: this.props.id,
			page: this.props.page,
			objMode: this.props.objMode,
			bgType: id,
			repoKey: result.data.key
		});
	}

	showErrorModal() {
		this.errorProcess = (e) => {
			e.stopPropagation();
			this.toggleModal("Error", false);
			this.errorProcess = () => {};
		};
		this.toggleModal("Error", true);
	}

	toggleModal(type = "Error", show = true) {
		let modalType = `showModal${type}`;
		this.setState({
			...this.state,
			[modalType]: show,
		})
	}

	textCssStringToNumber(css) {
		return {
			fontSize: css.fontSize.replace(/[^0-9.]/g, ""),
			lineHeight: css.lineHeight.replace(/[^0-9.]/g, ""),
			marginLeft: css.marginLeft.replace(/[^0-9.]/g, ""),
			marginTop: css.marginTop.replace(/[^0-9.]/g, ""),
			left: css.left ? css.left.replace(/[^-0-9.]/g, "") : 0,
			top: css.top ? css.top.replace(/[^-0-9.]/g, "") : 0,
			width: css.width ? css.width.replace(/[^0-9.]/g, "") : 0,
		}
	}

	contentsElem() {
		const item = this.props.item;
		if (item.data && item.data.type === "contents") {
			return (
				<>
					<TextProperty
						label={propertyInputLabel.htmlContent}
						name={propertyInputName.htmlContent}
						val={item.data ? item.data.zipKey || "" : ""}
						readOnly={false}
						onChange={this.eventHandler().dataChange}
					/>
					<FileProperty
						label={""}
						htmlFor={"uploadZipFile"}
						btnName={"アップロード"}
						accept={".zip"}
						dataId={this.props.id}
						bookId={this.props.bookId}
						onChange={this.zipUpload.bind(this)}
					/>
					<TextProperty
						label={propertyInputLabel.htmlFileName}
						name={propertyInputName.htmlFileName}
						val={item.data ? item.data.htmlFileName || "" : ""}
						readOnly={false}
						onChange={this.eventHandler().htmlFileNameChange}
					/>
				</>
			)
		}
	}

	posSizeElem() {
		if(this.props.item.data && this.props.item.data.myeditorCssOn) return;
		return (
			<PosSizeProperty css={this.state.propertyCss} eventHandler={this.eventHandler()} />
		)
	}
	onChangeDataType(e) {
		let data = this.props.item.data;
		if (e.target.checked) {
			data.type = e.target.name;
		} else {
			delete data.type;
		}

		if (e.target.name === propertyInputName.htmlCheck) {
			this.eventHandler().optionsChange(e);
		}

		this.props.changeRectData({
			id: this.props.id,
			page: this.props.page,
			data: {
				...data,
			},
			objMode: this.props.objMode,
		});
	}

	async zipUpload(e) {
		const result = await fileUpload(e, this.props.modifyRepoPath, this.props.bookId, "html", this.props.repo);
		if (!result || !result.fileUpload) {
			this.showErrorModal();
			return;
		}
		this.updateAfterUpload(result.data.key, "zipKey");
	}

	updateAfterUpload(key, dataKey) {
		this.props.changeRectData({
			id: this.props.id,
			page: this.props.page,
			objMode: this.props.objMode,
			data: {
				[dataKey]: key
			}
		})
	}

	myEditorElem(isTextMode) {
		const {page, item, pages} = this.props;
		const pageInfoOptions = this.props.pages ? [
			{val: pages.left, name: pages.left},
			{val: pages.right, name: pages.right}
		] : [];
		const myEditorOptions = this.props.id ? editorOptions : [];
		const isVertical = [subjectKeyName.kokugo, subjectKeyName.shosha, subjectKeyName.doutoku].includes(this.props.subject);
		if (!isTextMode) {
			return (
				<tbody>
				<TextProperty
					label={propertyInputLabel.id}
					name={propertyInputName.id}
					val={this.state.id}
					readOnly={false}
					onFocus={this.setPropertyId.bind(this)}
					onBlur={this.changePageDataId.bind(this)}
					onChange={this.changeId.bind(this)}
					tooltipText={propertyToolTip.id}
				/>
				<SelectProperty
					label={propertyInputLabel.page}
					name={propertyInputName.page} options={pageInfoOptions}
					eventHandler={this.eventHandler().pageChange} val={page}
					tooltipText={propertyToolTip.page}
				/>
				<NumberProperty
					label={propertyInputLabel.zIndex}
					name={propertyInputName.zIndex}
					val={item.options.link ? item.options.link.zIndex || "" : ""}
					onChange={this.eventHandler().optionsChange}
					tooltipText={propertyToolTip.zIndex}
				/>
				<SelectProperty
					label={propertyInputLabel.linkLevel}
					name={propertyInputName.linkLevel}
					options={linkLevelOptions}
					eventHandler={this.eventHandler().optionsChange}
					val={item.options.link ? item.options.link.level || "" : ""}
				/>

				<CheckBoxProperty
					label={propertyInputLabel.htmlCheck}
					name={propertyInputName.htmlCheck} suffix={""}
					checked={item.data && item.data.type === "contents"}
					eventHandler={this.onChangeDataType.bind(this)}
					tooltipText={propertyToolTip.iFrame}
				/>
				{this.contentsElem()}

				{this.posSizeElem()}
				{/*<PosSizeProperty css={this.state.propertyCss} eventHandler={this.eventHandler()} />*/}

				<SelectProperty
					label={propertyInputLabel.editor}
					name={propertyInputName.editor}
					options={myEditorOptions}
					val={this.state.editorUse}
					eventHandler={this.eventHandler().editorUseChange}
					tooltipText={propertyToolTip.editor}
				/>
				<CheckBoxProperty
					label={propertyInputLabel.ignore}
					name={propertyInputName.ignore} suffix={""}
					checked={item.editor ? item.editor.ignore ? item.editor.ignore : false : false}
					eventHandler={this.eventHandler().editorIgnoreChange}
				/>
				<TextProperty
					label={propertyInputLabel.editorImage}
					name={propertyInputName.editorImage}
					val={item.editor ? item.editor.myEditorImage || "" : ""}
					onChange={this.eventHandler().editorImageChangeRepo}
				/>
				<FileProperty
					label={propertyInputLabel.editorImage}
					htmlFor={"myEditorImage"}
					btnName={"アップロード"}
					accept={".png"}
					dataId={this.props.id}
					bookId={this.props.bookId}
					onChange={this.imageUpload.bind(this)}
				/>
				<TextProperty
					label={propertyInputLabel.editorImage_bg}
					name={propertyInputName.editorImage_bg}
					val={item.editor ? item.editor.myEditorImage_bg || "" : ""}
					onChange={this.eventHandler().editorImageChangeRepo}
				/>
				<FileProperty
					label={propertyInputLabel.editorImage_bg}
					htmlFor={"myEditorImage_bg"}
					btnName={"アップロード"}
					accept={".png"}
					dataId={this.props.id}
					bookId={this.props.bookId}
					onChange={this.imageUpload.bind(this)}
				/>
				<CheckBoxProperty
					label={propertyInputLabel.myEditorCss}
					name={propertyInputName.myEditorCss}
					checked={item.data ? item.data.myeditorCssOn : false}
					eventHandler={this.eventHandler().myEditorCssCheck}
				/>

				{this.myEditorCssProperty()}
				</tbody>
			)
		} else {
			return (
				<tbody>
				<TextProperty
					label={propertyInputLabel.id}
					name={propertyInputName.id}
					val={this.props.textId || "none"}
					readOnly={true}
				/>
				<NumberProperty
					label={propertyInputLabel.fontSize}
					name={propertyInputName.fontSize} suffix={"em"}
					val={this.state.css.fontSize || 0} min={0.1} step={0.1}
					onChange={this.eventHandler().editorTextCssChange}/>
				<NumberProperty
					label={propertyInputLabel.lineHeight}
					name={propertyInputName.lineHeight} suffix={"%"}
					val={this.state.css.lineHeight || 0} min={1}
					onChange={this.eventHandler().editorTextCssChange}/>

					{this.textDirectionToProperty(isVertical)}
				<SelectProperty
					label={propertyInputLabel.fontColor}
					name={propertyInputName.fontColor} options={fontColorOptions}
					val={this.state.css.color || fontColorCode.black}
					eventHandler={this.eventHandler().editorTextCssChange}/>
				<TextProperty
					label={propertyInputLabel.fontColor}
					name={propertyInputName.fontColor}
					val={this.state.css.color || fontColorCode.black}
					onChange={this.eventHandler().editorTextCssChange}
				/>
				<CheckBoxProperty
					label={propertyInputLabel.bold}
					name={propertyInputName.bold} suffix={""}
					checked={!!this.state.css.fontWeight}
					eventHandler={this.eventHandler().editorTextCssChange}/>

					{this.textEditTypeChangeProperty(isVertical)}
				</tbody>
			)
		}
	}

	// position: absolute on/off 편집
	textEditTypeChangeProperty(isVertical) {
		if (isVertical) return;
		return (
			<>
				<CheckBoxProperty
					label={propertyInputLabel.absolute}
					name={propertyInputName.absolute} suffix={""}
					checked={!!this.state.css.position}
					eventHandler={this.eventHandler().editorTextCssChange}
					tooltipText={propertyToolTip.absolute}/>

				{this.textEditAbsoluteProperty()}
			</>
		)
	}

	// absolute일때 사용할 left, top 편집
	textEditAbsoluteProperty() {
		if (!this.state.css.position) return;
		const textList = this.props.item.editor ? this.props.item.editor.text ? this.props.item.editor.text.contents : [] : [];
		let textOptions = [];
		textList.map((text) => {
			textOptions.push({
				val: text.id,
				name: text.value,
				key: text.id,
			});
			return text;
		});
		return (
			<>
				<NumberProperty
					label={propertyInputLabel.left}
					name={propertyInputName.left} suffix={"%"}
					val={this.state.css.left || 0}
					onChange={this.eventHandler().editorTextCssChange}/>
				<NumberProperty
					label={propertyInputLabel.top}
					name={propertyInputName.top} suffix={"%"}
					val={this.state.css.top || 0}
					onChange={this.eventHandler().editorTextCssChange}/>
				<NumberProperty
					label={propertyInputLabel.width}
					name={propertyInputName.width} suffix={"%"}
					val={this.state.css.width || 0}
					onChange={this.eventHandler().editorTextCssChange}/>
				<ButtonProperty
					label={propertyInputLabel.addEditorText}
					name={propertyInputName.addEditorText}
					btnName={"追加"}
					onClick={this.eventHandler().addEditorTextDiv}/>
				<SelectProperty
					label={propertyInputLabel.textList}
					name={propertyInputName.textList}
					options={textOptions}
					val={this.props.textId}
					eventHandler={this.textListSelect.bind(this)}
					size={7}
				/>
				<tr>
					<td colSpan={3} className={"text_change_btn_td"}>
						<input type="button" value="▼" className="btnlink_box small_width_btn" name="downObject"
							   onClick={this.textListChange.bind(this)}/>
						<input type="button" value="▲" className="btnlink_box small_width_btn" name="upObject"
							   onClick={this.textListChange.bind(this)}/>
						<input type="button" value="削除" className="btnlink_box small_width_btn" name="delete"
							   onClick={this.textDelete.bind(this)}/>
					</td>
				</tr>
			</>
		)
	}

	//textList에서 선택이벤트
	textListSelect(e) {
		// this.setState({
		// 	selectedTextId: e.target.value,
		// });
		this.props.setSelectedTextId({
			textId: e.target.value,
		})
	}

	//textList에서 text자리 바꾸기 이벤트
	textListChange(e) {
		const textList = this.props.item.editor ? this.props.item.editor.text ? this.props.item.editor.text.contents : [] : [];
		if (textList.length <= 0) return;

		let move = 0;
		if (e.target.name === "upObject") move = -1;
		else if (e.target.name === "downObject") move = 1;

		// const textIdx = textList.findIndex((item) => item.id === this.state.selectedTextId);
		const textIdx = textList.findIndex((item) => item.id === this.props.textId);
		if (textIdx < 0) return;

		this.props.changeEditorTextOrder({
			id: this.props.id,
			page: this.props.page,
			textIdx,
			addIdx: move,
		})
	}

	textDelete(e) {
		const textList = this.props.item.editor ? this.props.item.editor.text ? this.props.item.editor.text.contents : [] : [];
		const textIdx = textList.findIndex((item) => item.id === this.props.textId);
		if (textIdx < 0) return;
		this.props.deleteEditorText({
			id: this.props.id,
			page: this.props.page,
			textIdx,
		});
		this.props.setSelectedTextId({
			textId: "",
		})
	}

	textDirectionToProperty(isVertical) {
		const property = {
			label: isVertical ? propertyInputLabel.marginTop : propertyInputLabel.marginLeft,
			name: isVertical ? propertyInputName.marginTop: propertyInputName.marginLeft,
			val: isVertical ? this.state.css.marginTop : this.state.css.marginLeft,
		};
		return (
			<>
				<NumberProperty
					label={property.label}
					name={property.name}
					suffix={"%"}
					val={property.val || 0}
					onChange={this.eventHandler().editorTextCssChange}
				/>
			</>
		)
	}

	myEditorCssProperty() {
		const {item} = this.props;
		if(!item.data || !item.data.myeditorCssOn) return;
		return (
			<>
				<NumberProperty
					label={propertyInputLabel.width}
					name={propertyInputName.width} suffix={"px"}
					val={item.data.myeditorCss ? item.data.myeditorCss.width || 0 : 0}
					onChange={this.eventHandler().myEditorCssChange}/>
				<NumberProperty
					label={propertyInputLabel.height}
					name={propertyInputName.height} suffix={"px"}
					val={item.data.myeditorCss ? item.data.myeditorCss.height || 0 : 0}
					onChange={this.eventHandler().myEditorCssChange}/>
				<NumberProperty
					label={propertyInputLabel.left}
					name={propertyInputName.left} suffix={"px"}
					val={item.data.myeditorCss ? item.data.myeditorCss.left || 0 : 0}
					onChange={this.eventHandler().myEditorCssChange}/>
				<NumberProperty
					label={propertyInputLabel.top}
					name={propertyInputName.top} suffix={"px"}
					val={item.data.myeditorCss ? item.data.myeditorCss.top || 0 : 0}
					onChange={this.eventHandler().myEditorCssChange}/>
			</>
		)
	}

	render() {
		const {id, isTextMode} = this.props;
		return (
			<div className="inner-table">
				<table>
					{this.myEditorElem(isTextMode)}
				</table>
				<div className="inner-mask" style={{display: id ? "none" : ""}}/>
				{this.state.showModalError && <Modal
					onClickConfirm={(e) => this.errorProcess(e)}
					msg={modalMsg.IMAGE_EXIST}
					title={modalTitle.ERROR}
				/>}
			</div>
		)
	}
}

MyEditorTable.defaultProps = {
	item: {
		originId: "",
		id: "",
		css: {},
		options: {},
		page: 0,
		data: {},
	},
	page: 0,
	id: ""
};

MyEditorTable.propTypes = {
	item: PropTypes.shape({
		originId: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		css: PropTypes.object.isRequired,
		options: PropTypes.object.isRequired,
		data: PropTypes.object.isRequired,
		page: PropTypes.number.isRequired
	}).isRequired,
	page: PropTypes.number.isRequired,
	id: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(MyEditorTable);
