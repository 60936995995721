import React, {Component} from 'react';
import Panel from "../Panel";
import EditSVG from "../../../../../containers/Contents/Editor/EditSVG";
import "./SVGPanel.scss";

const panelState = {
	id: "SVGPanel",
	label: "SVG編集",
	name: "svg",
	style: {
		width: 300,
		top: 100,
		left: 110,
	}
};

class SVGPanel extends Component {
	keyDown(e) {
		// ObjectLayerのkeydownイベントを実行しない
		e.nativeEvent.stopImmediatePropagation();
	}

	render() {
		return (
			<div className="svg-panel" onKeyDown={this.keyDown.bind(this)}>
				<EditSVG/>
			</div>
		)
	}
}

export default Panel(SVGPanel, panelState);