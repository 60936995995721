import { setBookChanged, addLog } from "../reducers/CombineReducers/current";
import {now} from "./utils";

let undoStack = [];

export const chkBookChanged = store => next => action => {
	const bf = store.getState();
	const bookReady = bf.current.book.ready > 0;
	const run = (bookReady > 0 && bf.current.book.changed <= 0);
	const result = next(action);
	const af = store.getState();
	// if ((bf.pageData !== af.pageData) || (bf.repo !== af.repo) || (bf.metaData !== af.metaData)) {
	if ((bf.pageData !== af.pageData) || (bf.metaData !== af.metaData)) {
		if(bookReady && action.type !== "UNDO") putUndoStack(bf.pageData);
		if(run) next(setBookChanged());
	}
	//log 저장
	//console.log(action);
	const currentTime = now();
	if (action.type === "MODIFY_REPO_PATH"){
		if (action.userId && action.userId === bf.auth.info.id) {
			next(addLog({msg:{regDate:`${currentTime}`, userId:`${af.auth.info.id}`, page:`0`, objectId:`${action.key}`, action: `ファイル追加`, type: "レポジトリ"}}));
		}
	}else if(action.type === "CHANGE_RECT_ID"){
		next(addLog({msg:{regDate:`${currentTime}`, userId:`${af.auth.info.id}`, page:`${action.page}`, objectId:`${action.currentId}`, action: `${action.changedId}に変更`, type: "クリックポイント"}}));
	}else if(action.type === "MODIFY_REPO_MULTI"){
		if (action.userId && action.userId === bf.auth.info.id) {
			let data = Object.keys(action.data);
			for(let i of data){
				next(addLog({msg:{regDate:`${currentTime}`, userId:`${af.auth.info.id}`, page:`0`, objectId:`${i}`, action: `ファイル追加`, type: "レポジトリ"}}));
			}
		}
	}else if(action.type === "REMOVE_FROM_REPO"){
		if (action.userId && action.userId === bf.auth.info.id) {
			next(addLog({msg:{regDate:`${currentTime}`, userId:`${af.auth.info.id}`, page:`0`, objectId:`${action.key}`, action: `ファイル削除`, type: "レポジトリ"}}));  //
		}
	}else if(action.type === "CHANGE_RECT_EDITOR"){
		next(addLog({msg:{regDate:`${currentTime}`, userId:`${af.auth.info.id}`, page:`${action.page}`, objectId:`${action.id}`, action: `設定を変更`, type: "クリックポイント"}}));
	}else if(action.type === "CHANGE_RECT_SVG"){
		next(addLog({msg:{regDate:`${currentTime}`, userId:`${af.auth.info.id}`, page:`${action.page}`, objectId:`${action.id}`, action: `設定を変更`, type: "クリックポイント"}}));
	}else if(action.type === "CHANGE_RECT_THUMBKEY"){
		next(addLog({msg:{regDate:`${currentTime}`, userId:`${af.auth.info.id}`, page:`${action.page}`, objectId:`${action.id}`, action: `設定を変更`, type: "クリックポイント"}}));
	}else if(action.type === "CHANGE_RECT_PAGE"){
		next(addLog({msg:{regDate:`${currentTime}`, userId:`${af.auth.info.id}`, page:`${action.page}`, objectId:`${action.id}`, action: `設定を変更`, type: "クリックポイント"}}));
	}else if(action.type === "CHANGE_RECT"){
		next(addLog({msg:{regDate:`${currentTime}`, userId:`${af.auth.info.id}`, page:`${action.page}`, objectId:`${action.id}`, action: `設定を変更`, type: "クリックポイント"}}));
	}else if(action.type === "CHANGE_RECT_DATA"){
		next(addLog({msg:{regDate:`${currentTime}`, userId:`${af.auth.info.id}`, page:`${action.page}`, objectId:`${action.id}`, action: `設定を変更`, type: "クリックポイント"}}));
	}else if(action.type === "CHANGE_RECT_OPTIONS"){
		next(addLog({msg:{regDate:`${currentTime}`, userId:`${af.auth.info.id}`, page:`${action.page}`, objectId:`${action.id}`, action: `設定を変更`, type: "クリックポイント"}}));
	}else if(action.type === "CHANGE_RECT_EDITOR_TEXT"){
		next(addLog({msg:{regDate:`${currentTime}`, userId:`${af.auth.info.id}`, page:`${action.page}`, objectId:`${action.id}`, action: `設定を変更`, type: "クリックポイント"}}));
	}else if(action.type === "CHANGE_DUMMY"){
		if(action.value === true){
			next(addLog({msg:{regDate:`${currentTime}`, userId:`${af.auth.info.id}`, page:`${action.page}`, objectId:``, action: `調整用ページ設定`, type: "ノンブル"}}));
		}else if(action.value === false){
			next(addLog({msg:{regDate:`${currentTime}`, userId:`${af.auth.info.id}`, page:`${action.page}`, objectId:``, action: `調整用ページ解除`, type: "ノンブル"}}));
		}
	}else if(action.type === "SET_BOOK_INDEX"){
		next(addLog({msg:{regDate:`${currentTime}`, userId:`${af.auth.info.id}`, page:``, objectId:``, action: `ノンブル設定`, type: "ノンブル"}}));
	}


	return result;
};

function putUndoStack(state) {
	if(undoStack.length >= 20) undoStack.shift();
	undoStack.push(state);
}

export function getLastUndoStack() {
	return undoStack.pop();
}