import {flatObject, makeErrorMsg, randomStr, stringParser} from "../../../interface/utils";
import {inputDuplicateCheck, pageInvalidCheck, propNameChange, propValueFix, overRangeDetailCheck} from "./csvUtils";
import {audioType, csvColumnsName, csvModalMsg, defaultAudioCss} from "../../../interface/csvConstant";
import {checkNested} from "../../../interface/utils";

/**
 * clonedData 를 순회하면서 audioLink 객체로만 이루어진 배열을 반환하는 함수
 * @param {object} clonedData - deepClone(this.props.pageData);
 * @returns {Array} - audio 객체로 이루어진 배열
 */
export const getAudioData = (clonedData) => {
	let dataArr = Object.values(clonedData);
	let returnArr = [];
	for (let pageData of dataArr) {
		if (checkNested(pageData, "objects.audioLink")) {
			let audioLinkData = pageData.objects.audioLink;
			let flatted = audioLinkData.map((data) => {
				return {
					originId: data.originId,
					id: data.id,
					page: data.page,
					'css.left': data.css.left,
					'css.top': data.css.top,
					'css.height': data.css.height,
					'css.width': data.css.width,
					'data.fileKey': data.data.fileKey,
					'data.index': data.data.index,
					'data.moveAt': data.data.moveAt,
					'data.playAt': data.data.playAt,
				}
			});
			returnArr = returnArr.concat(flatted);
		}
	}
	return returnArr;
};

/**
 * map 한번만 돌면서 필요한 처리 과정을 거친 배열을 돌려주는 함수 ( 자동변환 방지문자 제거, 객체구분 타입지정 )
 * @param {Array} arr - audio csv import 로 받은 객체들의 배열
 * @returns {Array} - arr 돌면서 자동변환방지 문자를 제거하고 audio 객체와 audioText 객체로 나눈 객체들의 배열
 */
export const getProcessData = (arr) => {
	return arr.map(obj => {
		removeAutoFixStr(obj, 'originId', 'id'); // 자동변환 방지문자 제거
		return obj;
		//const typeSetObj = propValueChange(obj, 'type', audioType.AUDIO,'audio', 'audioText');
		//return typeSetObj;
	});
};

/**
 * audioData 를 순회하면서 audio 객체와 audioText 객체 속성 값에 대한 유효성 검사를 시행하는 함수
 * 필수 입력인데 입력하지 않았거나, 숫자만 받는데 문자를 입력했거나, 입력하지 않아도 되는데 입력했다면 유효성 검사
 * @param {Array} audioData - type 이 audio 인 객체와 audioText 인 객체로 이루어진 배열
 * @param {number} commentLength - CSV 파일 주석의 길이
 * @param {object} metaData - clone 된 this.props.metaData 객체
 */
export const audioDataInvalidCheck = (audioData, commentLength, metaData) => {
	const dupleCheckObj = {originId: []};
	const pageCheckObj = {};
	for (const obj of audioData) {
		const idx = audioData.indexOf(obj) + 2 + commentLength;
		audioTypeObjectCheck(obj, idx, dupleCheckObj, pageCheckObj, metaData);
	}
};


/**
 * arr 배열을 순회하면서 각 객체의 type 속성에 따라서 redux 상태의 객체 구조로 되돌린 객체들의 배열을 반환하는 함수
 * @param {Array} arr - type 속성이 audio 인 객체와 audioText 인 객체로 이루어진 배열
 * @returns {Array} - redux 상태의 audio 객체 속성 구조에 csv 로 입력받은 속성 값을 가진 객체들의 배열을 반환하는 함수
 */
export const getModelObject = (arr, clonedData) => {
	try {
	return arr.map(obj => {
		//const audioObj = propNameChange(obj, 'audioData', 'data', 'audio'); // 객체에 audioData 속성 이름을 data 로 변경
		//const audioModel = getImportAudioDataModel(); // audio type 객체를 redux 형태로 되돌리기 위한 모델


		let returnObj = {
			id: obj.id,
			options: {
				link: {
					level: 2,
					zIndex: 0
				},
				iFrame: false
			},
			css: {
				width: parseFloat(obj['css.width']),
				height: parseFloat(obj['css.height']),
				top: parseFloat(obj['css.top']),
				left: parseFloat(obj['css.left']),
				position: "absolute",
			},
			data: {
				index: parseInt(obj['data.index']),
				fileKey: obj['data.fileKey'],
				file: "",
				moveAt: parseInt(obj['data.moveAt']),
				playAt: parseInt(obj['data.playAt']),
			},
			originId: obj.originId,
			page: parseInt(obj.page),
		};

		let pageData = clonedData[obj.page].objects;
		if(pageData && pageData.hasOwnProperty("audioLink")) {
			let original = pageData.audioLink.find(item => item.originId === obj.originId);
			if(original) {
				returnObj = {
					...original,
					...returnObj,
				};
			}
		}

		if(!returnObj.data.playAt) delete returnObj.data.playAt;
		if(!returnObj.data.moveAt) delete returnObj.data.moveAt;

		console.log(returnObj);

		return returnObj
	});
	} catch (err) {

	}
};

/**
 * modalObjectArr 순환하면서 audio 객체의 text 배열에 audioText 객체들을 push 하고
 * type 속성을 지운 완전한 형태의 audio 객체로 이루어진 배열을 반환한다.
 * @param {array} modalObjectArr - redux 형태를 갖춘 audio 객체 와 audioText 객체로 이루어진 배열
 * @returns {Array} - 완전한 형태의 audio 객체
 */
export const audioTextPush = (modalObjectArr) => {
	const audioArr = [];
	for (const obj of modalObjectArr) {
		audioArr.push(obj);
	}
	return audioArr;
};

/**
 * オーディオ 객체의 갯수로만 카운팅해서 몇건의 audio 객체가 import 되었는지 알려주는 문자열 반환함수
 * @param {array} audioObjArr - audio 객체의 속성 배열에 audioText 객체가 들어간 완전한 audio 객체들의 배열
 * @returns {string} - audio csv import 성공시 modal 에 출력될 문자열
 */
export const audioImportSuccessMsg = (audioObjArr) => {
	const count = audioObjArr.length;
	return `${count}件の音声読み上げがインポートできました。`;
};

/**
 * clonedData 객체를 순회하며 audioLink 배열을 빈 배열로 초기화 시키는 함수
 * @param {object} clonedData - deepClone(this.props.pageData) 딥클론한 pageData
 * @returns {object} - clonedData 의 audioLink 배열을 빈 배열로 초기화
 */
export const allAudioLinkArrClear = (clonedData) => {
	for (const objects of Object.values(clonedData)) {
		objects['objects'].audioLink = [];
	}
	return clonedData;
};

/**
 * 각 객체들을 리덕스에 반영할 객체에 넣어주는 함수
 * @param {array} audioObjArr - redux 형태의 audio 객체들로 이루어진 배열
 * @param {object} setReduxData - redux 에 덮어 씌울 딥클론한 가상 데이터
 * @returns {object} - 리덕스에 반영할 pageData 객체
 */
export const importing = (audioObjArr, setReduxData) => {
	for (const audio of audioObjArr) {
		const {page} = audio;
		const audioLink = setReduxData[page]['objects']['audioLink'];
		audioLink.push(audio);
	}
	return setReduxData;
};

/**
 * audioData 을 순회하면서 각 객체의 type 에 따라서 필수값이 아닌 빈값을 자동으로 채워넣은 객체들의 배열을 반환하는 함수
 * @param {array} audioData - type 이 audio 인 객체와 audioText 인 객체로 이루어진 배열
 * @returns {array} - 속성 기본 값이 설정된 type 속성이 audio 인 객체와 audioText 인 객체들의 배열
 */
export const fillPropValue = (audioData) => {
	const pageObject = {};
	return audioData.map(obj => {
		if (!pageObject[obj.page]) pageObject[obj.page] = [];
		pageObject[obj.page].push(obj.page);
		const pageIdx = pageObject[obj.page].length;
		return getFilledPropValueAudio(obj, pageIdx);
	});
};

/**
 * obj 의 필수 입력값이 아닌 속성들의 값이 "" 빈값이면 임의의 값을 자동으로 채워넣어주는 함수
 * @param {object} obj - type 속성의 값이 audio 인 객체
 * @param {number} pageIdx - 해당 객체가 페이지 내에서 입력받는 순번
 * @returns {object} - 필수입력값이 아닌 속성이 빈값일 때 임의의 값을 설정해준 객체
 */

const getFilledPropValueAudio = (obj, pageIdx) => {
	const newObj = Object.assign({}, obj);
	if (newObj.id === "") newObj.id = newObj.originId;
	if (newObj.file === "") newObj.file = randomStr(10);
	if (newObj['css.top'] === "") newObj['css.top'] = pageIdx * 10;
	if (newObj['css.left'] === "") newObj['css.left'] = pageIdx * 10;
	if (newObj['css.width'] === "") newObj['css.width'] = defaultAudioCss.WIDTH;
	if (newObj['css.height'] === "") newObj['css.height'] = defaultAudioCss.HEIGHT;
	return newObj;
};

/**
 * targets 로 받은 인자들로 obj 속성 키값으로 접근해서 value 값에서 자동변환 방지문자가 있으면 제거하는 함수
 * @param {object} obj - audio csv import 로 받은 객체
 * @param {string} targets - obj 객체에서 자동변환 방지문자를 제거할 속성들을 문자열로 받아서 레스트 배열로 사용
 */
const removeAutoFixStr = (obj, ...targets) => {
	for (const target of targets) {
		propValueFix(target, obj, target); // 자동변환 방지문자 제거
	}
};

/**
 * arr 을 map 하면서 속성을 flat 하게 바꾸고 속성명 data 를 audioData 로 바꾼 Audio 객체와 text 객체로 이루어진 배열을 반환
 * @param {array} arr - Audio 객체의 text 배열에서 객체들을 꺼내서 Audio 객체와 text 객체가 섞인 배열
 * @returns {array} - Audio 객체와 text 객체의 속성이 flat 한 배열, Audio 객체의 경우 data 속성이 audioData 로 바뀜
 */
export const mapProcess = (arr) => {
	return arr.map(obj => {
		const flatObj = flatObject(obj);
		const audioDataObj = propNameChange(flatObj, 'data', 'audioData', 'audio');
		return propValueChange(audioDataObj, 'type', 'audio', audioType.AUDIO, audioType.TEXT);
	});
};

/**
 * 객체의 prop 속성 값이 val 이면 ifVal 로 바꾸고 아니면 elseVal 로 바꾸는 함수
 * @param {object} obj - 접근할 객체
 * @param {string} prop - 객체에 접근할 속성 이름
 * @param {string} val - obj.prop 의 속성 값과 일치하는지 비교할 문자열
 * @param {string} ifVal - 일치하는 경우 대입 시킬 속성 값
 * @param {string} elseVal - 일치하지 않을 경우 대입 시킬 속성 값
 * @returns {object} - ifVal 또는 elseVal 이 대입된 객체
 */
const propValueChange = (obj, prop, val, ifVal, elseVal) => {
	const newObj = Object.assign({}, obj);
	if (newObj[prop] === val) {
		newObj[prop] = ifVal;
	} else { // audioText
		newObj[prop] = elseVal;
	}
	return newObj;
};

/**
 * inputCsvColumns 와 지정해둔 audioColumn 배열을 비교해서 일치하지 않으면 에러를 던지는 함수
 * @param {array} inputCsvColumns - 사용자로 부터 import 받은 csv 파일의 컬럼 이름 배열
 */
export const audioColumnsErrorCheck = (inputCsvColumns) => {
	const audioColumn = ['種類', '元ID', 'ID', 'ページ', 'X座標', 'Y座標', 'サイズ(縦)', 'サイズ(横)', 'R-ID', '順番', 'ページ転換時間', '転換後再生時間'];
	if (inputCsvColumns.toString() !== audioColumn.toString()) {
		throw new Error(makeErrorMsg('unmatchedColumn', csvModalMsg.audio));
	}
};

/**
 * obj 의 속성의 유효성 검사를 하는 함수
 * @param {object} obj - type 이 audio 인 검사 대상 객체
 * @param {number} idx - csv 상에서 obj 가 위치한 라인 넘버
 * @param {object} dupleCheckObj - 사용자 originId 중복 입력 방지 체크
 * @param {object} audioDataObj - 중복 체크를 위해 반복문에서 초기화 되지 않는 객체
 * @param {object} metaData - this.metaDataClone(this.props.metaData) - cloned meta 정보
 */
const audioTypeObjectCheck = (obj, idx, dupleCheckObj, audioDataObj, metaData) => {
	const {view, totalPages, direction} = metaData;
	inputDuplicateCheck(obj, idx, dupleCheckObj); // originId 입력 중복 체크 - 고유성 체크
	pageInvalidCheck(obj, idx, totalPages); // page error check;
	audioDataCheck(obj, idx, audioDataObj); // 한 page 내에 중복되는 audioData 있으면 error
	cssInfoCheck(obj, idx, view, direction); // audio 객체의 top, left, width, height 체크
};

/**
 * obj 의 height, width, left, top 속성에 대한 검사를 하는 함수
 * @param {object} obj - type 이 audio 인 검사 대상 객체
 * @param {number} idx - csv 상에서 obj 가 위치한 라인 넘버
 * @param {object} view - this.props.metaData.view  ex) { width: 952, height: 674 }
 * @param {string} direction - [rtl | ltr] 책의 읽는 방향
 */
const cssInfoCheck = (obj, idx, view, direction) => {
	const {width, height} = view;
	overRangeDetailCheck(obj, 'css.height', idx, 0, height); // height check ( 높이 check )
	overRangeDetailCheck(obj, 'css.width', idx, 0, width); // width check ( 너비 check )
	leftInfoCheck(obj, idx, width, direction); // left check ( x check )
	overRangeDetailCheck(obj, 'css.top', idx, 0, (height - obj['css.height'])); // height check ( y check )
};

/**
 * direction 에 따른 obj.page 와 obj.width 정보에 따라서
 * obj.left 의 제한된 범위가 있는데 이를 넘어서면 에러를 던지는 함수
 * @param {object} obj - type 이 audio 인 검사 대상 객체
 * @param {number} idx - csv 상에서 obj 가 위치한 라인 넘버
 * @param {number} width - this.props.metaData.view.width  ex) width: 952
 * @param {string} direction - [rtl | ltr] - 책의 읽는 방향
 */
const leftInfoCheck = (obj, idx, width, direction) => {
	if (direction === 'rtl') { // rtl
		if (obj.page % 2 === 0) { // page 짝수
			const min = 0;
			const max = (width - obj['css.width']);
			overRangeDetailCheck(obj, 'css.left', idx, min, max);
		} else { // page 홀수
			const min = (width / 2) * -1;
			const max = (width / 2) - obj['css.width'];
			overRangeDetailCheck(obj, 'css.left', idx, min, max);
		}
	} else { // ltr
		if (obj.page % 2 === 0) { // page 짝수
			const min = (width / 2) * -1;
			const max = (width / 2) - obj['css.width'];
			overRangeDetailCheck(obj, 'css.left', idx, min, max);
		} else { // page 홀수
			const min = 0;
			const max = (width - obj['css.width']);
			overRangeDetailCheck(obj, 'css.left', idx, min, max);
		}
	}
};

/**
 * obj.page 에 따라서 audioDataObj 의 속성으로 접근해서 배열을 만들고 audioData 값을 push 하고
 * audioData 값이 든 배열에 중복되는 값이 있는지 검사해서 있으면 에러를 던지는 함수
 * @param {object} obj - type 이 audio 인 검사 대상 객체
 * @param {number} idx - csv 상에서 obj 가 위치한 라인 넘버
 * @param {object} audioDataObj - 중복 체크를 위해 반복문에서 초기화 되지 않는 객체
 */
const audioDataCheck = (obj, idx, audioDataObj) => {
	if (!audioDataObj[obj.page]) audioDataObj[obj.page] = [];
	audioDataObj[obj.page].push(stringParser(obj.audioData));
	for (const [page, audioDataArr] of Object.entries(audioDataObj)) {
		const dupleArr = audioDataArr.filter((item, idx) => audioDataArr.indexOf(item) !== idx);
		if (dupleArr.length !== 0) { // 중복값이 없으면 dupleArr.length 는 0입니다.
			throw new Error(makeErrorMsg('uniqueFromPage', idx, csvColumnsName.audioData, page));
		}
		ascendingCheck(audioDataArr, page); // 1씩 오름 차순인지 체크 아니면 에러를 던진다.
	}
};

/**
 * 한 페이지 내에서 audio 객체들의 audioData 1씩 오름차순이 아니면 에러를 던지는 함수
 * @param {number[]} arr - page 내의 object 들의 audioData 값을 담은 배열
 * @param {string} page - audioData 값이 1씩 오름차순을 유지하지 않는 audio 객체가 있는 page
 */
const ascendingCheck = (arr, page) => {
	if (arr.length !== 1) {
		for (let i = 0; i < arr.length; i++) {
			if (arr[i + 1]) {
				if (arr[i + 1] !== arr[i] + 1) {
					throw new Error(makeErrorMsg('ascendingFromPage', page, csvColumnsName.audioData));
				}
			}
		}
	}
};