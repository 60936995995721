import React, {Component} from 'react';
import {connect} from 'react-redux';
import './Account.scss';
import {changePassword} from "../../../interface/api";
import {setContents} from "../../../reducers/CombineReducers/current";
import {setCookie} from "../../../interface/utils";
import Password from '../../../components/Contents/Account/Password';
//import AutoSave from '../../../components/Contents/Account/AutoSave';
import Title from '../../../components/Common/Title';
import {componentTitle, modalMsg} from "../../../interface/constant";
import Modal from "../../../components/Common/Modal";

const mapStateToProps = ({current, auth}) => ({current, auth});
const mapDispatchToProps = {
	setContents,
	changePassword,
};

class Account extends Component {
	constructor(props) {
		super(props);
		this.state = {
			autosaveTimes: ['10分', '20分', '30分', '40分', '50分', '60分'],
			showModalPWChange: false,
			showModalPWNotMatched: false,
		}
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		return this.props.current.contents === "account" || nextProps.current.contents === "account";
	}

	doLogout = () => {
		setCookie("token", "", 0);
		window.location.reload();
	};

	onClickX = () => {
		this.props.setContents({
			contents: "editor"
		});
	};

	changePassword = async (passwordData) => {
		let result = await this.props.changePassword(passwordData.old, passwordData.new);
		if (result.changed === true) {
			this.setState({showModalPWChange: true})
		} else {
			this.setState({showModalPWNotMatched: true})
		}
	};

	closeModal = () => {
		this.setState({
			showModalPWChange: false,
			showModalPWNotMatched: false,
		})
	};

	afterChangePW = () => {
		setCookie("token", "", 0);
		window.location.reload();
	};

	onChange = (e) => {
		console.log("e: ", e.currentTarget.value);
	};

	onClickToggle = (e) => {
		console.log("e: ", e.currentTarget.value);
	};

	render() {
		return (
			<div id={"Account"} className={this.props.className + " account-component-area"}>
				<Title
					titleName={componentTitle.ACCOUNT_SETTING}
					onclickX={this.onClickX}
				/>
				<div className="account-content">
					<div className="account-content-center">
						<div className="component-column-area">
							<Password changePassword={this.changePassword}/>
							{/*<AutoSave
								onChange={this.onChange}
								autosaveTimes={this.state.autosaveTimes}
								onClickToggle={this.onClickToggle}
							/>*/}
						</div>

						<div className="component-save-area">
							<input
								className="account-save-btn btn_blue"
								type="button"
								value="ログアウト"
								onClick={this.doLogout}
							/>
						</div>
					</div>
				</div>
				{this.state.showModalPWChange &&
				<Modal onClickConfirm={this.afterChangePW} msg={modalMsg.PASSWORD_CHANGED}/>}
				{this.state.showModalPWNotMatched &&
				<Modal onClickConfirm={this.closeModal} msg={modalMsg.PASSWORD_NOT_MATCHED}/>}
			</div>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Account);