import React, { Component } from 'react';
import Panel from "../Panel";
import GroupList from "../../../../../containers/Contents/Editor/GroupList";
import "./GroupPanel.scss";

const panelState = {
	id:  "GroupPanel",
	label: "グループ",
	name: "group",
	style: {
		width: 220,
		height: 400,
		top: 10,
		left: 10,
	}
};

class GroupPanel extends Component {
	keyDown(e) {
		// ObjectLayerのkeydownイベントを実行しない
		e.nativeEvent.stopImmediatePropagation();
	}

	render() {
		return (
			<div className="panel-object-list-inner inner-contents" onKeyDown={this.keyDown.bind(this)}>
				<GroupList/>
			</div>
		)
	}
}

export default Panel(GroupPanel, panelState);