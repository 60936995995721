import React, {Component} from 'react';
import {connect} from "react-redux";
import {RikaAnswerTable} from "../../../components/Contents/Editor/Panels/SubviewPanel/innerSubview";
import {changeRectData, changeRectDataBlock} from "../../../reducers/CombineReducers/pageData";
import {modifyRepoPath} from "../../../reducers/CombineReducers/repo";
import {objectModeConst, subViewRect, rikaAnswerType} from "../../../interface/constant";
import {randomStr} from "../../../interface/utils";
import {fileUpload} from "../../../components/Common/Upload";

const mapStateToProps = state => {
    const {id, page, blockId} = state.current.editor.selected;
    const {editor} = state.current;
    const objMode = objectModeConst[editor.objectMode];
    let props = {};
    if (id && page) {
        let objectList = state.pageData[page].objects[objMode];
        const idx = objectList.findIndex((item) => item.originId === id);
        const subViewSettings = editor.objectMode === "subView" ? objectList[idx].data.subViewSettings : null;
        props = {
            selectedId: id,
            selectedPage: page,
            selectedBlockId: blockId,
            bookId: state.metaData.bookId,
            repo: state.repo,
            subViewSettings,
        }

    }
    return props;
};

const mapDispatchToProps = {
    changeRectData,
    changeRectDataBlock,
    modifyRepoPath,
};

const ADD_OFFSET = 10;
let answerOffsetX = ADD_OFFSET;
let answerOffsetY = ADD_OFFSET;


class RikaAnswer extends Component {

    async AnswerMaskImageUpload(e, idx) {
        const result = await fileUpload(e, this.props.modifyRepoPath, this.props.bookId, "image", this.props.repo);
        if (!result || !result.fileUpload) {
            this.props.showErrorModal();
            return;
        }

        const {subViewSettings} = this.props;
        let subViewAnswer = subViewSettings.options.answer || [];
        if (subViewAnswer.length === 0) return;

        subViewAnswer[idx].image = result.data.key;

        let data = {
            subViewSettings: {
                ...subViewSettings,
                options: {
                    ...subViewSettings.options,
                    answer: subViewAnswer,
                }
            }
        };

        this.props.changeRectData({
            id: this.props.selectedId,
            page: this.props.selectedPage,
            data: data,
            objMode: "subView",
        });
    }

    subViewSettingEvent() {
        return {
            addAnswer: (e) => {
                const type = e.target.name;

                this.answerTypeAdd(type);

            },
            changeAnswerCss: (e) => {
                const value = Number(e.target.value);
                const data = {
                    [e.target.name]: value
                };

                this.props.changeRectDataBlock({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    blockId: this.props.selectedBlockId,
                    data: data,
                    subViewType: subViewRect.rikaAnswer,
                });
            },
            changeAnswerValue: (e) => {
                const value = e.target.name === "maskOn" ? e.target.checked : e.target.value;
                const data = {
                    [e.target.name]: value
                };

                this.props.changeRectDataBlock({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    blockId: this.props.selectedBlockId,
                    data: data,
                    subViewType: subViewRect.rikaAnswer,
                });
            },
            deleteAnswerImage: (idx) => {
                if (idx < 0) return;
                const {subViewSettings} = this.props;
                let subViewAnswer = subViewSettings.options.answer || [];
                if (subViewAnswer.length === 0) return;
                if (!subViewAnswer[idx].image) return;

                delete subViewAnswer[idx].image;

                let data = {
                    subViewSettings: {
                        ...subViewSettings,
                        options: {
                            ...subViewSettings.options,
                            answer: subViewAnswer,
                        }
                    }
                };

                this.props.changeRectData({
                    id: this.props.selectedId,
                    page: this.props.selectedPage,
                    data: data,
                    objMode: "subView",
                });
            },
        }
    }

    // 정답버튼+정답영역 생성
    answerTypeAdd(type) {
        const id = "answer-" + randomStr(8);
        const defaultAnswer = [
            {
                id: id,
                group: 1,
                type: rikaAnswerType.answer[type].name,
                width: rikaAnswerType.answer[type].width,
                height: rikaAnswerType.answer[type].height,
                left: answerOffsetX,
                top: answerOffsetY,
            },
        ];

        if (rikaAnswerType.answer[type].name === rikaAnswerType.answer["contents"].name) {
            defaultAnswer[0].color = "#FFFFFF";
            defaultAnswer[0].maskOn = true;
        }
        this.addRect(defaultAnswer);
    }

    addRect(defaultAnswer) {
        const {subViewSettings} = this.props;
        let answer = subViewSettings.options.answer || [];
        const data = {
            subViewSettings: {
                ...subViewSettings,
                options: {
                    ...subViewSettings.options,
                    answer: answer.concat(defaultAnswer),
                },
            }
        };

        this.props.changeRectData({
            id: this.props.selectedId,
            page: this.props.selectedPage,
            data: data,
            objMode: "subView",
        });

        if(answerOffsetY < 580){
            answerOffsetX += ADD_OFFSET;
            answerOffsetY += ADD_OFFSET;
        }else{
            answerOffsetX = answerOffsetX-560;
            answerOffsetY = answerOffsetY-580;
        }

    }

    render() {
        const {subViewSettings, selectedBlockId} = this.props;
        const answers = subViewSettings.options.answer || [];
        const selectedAnswerIdx = answers.findIndex((item) => item.id === selectedBlockId);
        const selectedAnswer = selectedAnswerIdx < 0 ? null : answers[selectedAnswerIdx];

        const imageDeleteHide = selectedAnswer ? selectedAnswer.image ? "" : "hide" : "hide";
        const answerMaskSettingHide = selectedAnswer ? selectedAnswer.type === rikaAnswerType.answer["contents"].name ? "" : "hide" : "hide";
        const groupHide = selectedAnswer ? "" : "hide";

        return (
            <RikaAnswerTable
                event={this.subViewSettingEvent()}
                selectedAnswer={selectedAnswer}
                selectedAnswerIdx={selectedAnswerIdx}
                imageDeleteHide={imageDeleteHide}
                answerMaskSettingHide={answerMaskSettingHide}
                groupHide={groupHide}
                upload={(e, idx) => this.AnswerMaskImageUpload(e, idx)}
                bookId={this.props.bookId}
                tabMenu={this.props.tabMenu}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RikaAnswer);