import Encoding from 'encoding-japanese';
import {errType} from "./csvConstant";

export function getCookie(name) {
	name = new RegExp(name + '=([^;]*)');
	return name.test(document.cookie) ? unescape(RegExp.$1) : '';
}

export function setCookie(name, data, expireDay) {
	let d = new Date();
	let expires = "";
	if (expireDay !== undefined) {
		d.setTime(d.getTime() + (expireDay * 24 * 60 * 60 * 1000));
		expires = `expires=${d.toUTCString()};`;
	}
	document.cookie = `${name}=${data};${expires}path=/`;
}

export function now() {
	let date = new Date();
	let yyyy = date.getFullYear();
	let mm = zeroPadding((date.getMonth() + 1), 2);
	let dd = zeroPadding(date.getDate(), 2);

	let h = zeroPadding(date.getHours(), 2);
	let m = zeroPadding(date.getMinutes(), 2);
	let s = zeroPadding(date.getSeconds(), 2);

	return `${yyyy}-${mm}-${dd} ${h}:${m}:${s}`;
}

export function unixTimeNow() {
	return Date.now();
}

function str2Array(str) {
	let array = [],i,il=str.length;
	for(i=0;i<il;i++) array.push(str.charCodeAt(i));
	return array;
}

export function readCsvAsync(file) {
	return new Promise((resolve => {
		const reader = new FileReader();
		reader.readAsBinaryString(file);
		reader.onload = () => {
			const sjisArray = str2Array(reader.result);
			const uniArray = Encoding.convert(sjisArray, 'UNICODE', 'SJIS');
			const result = Encoding.codeToString(uniArray);
			const rowArr = result.split(/\r\n/g);
			const noCommentArr = rowArr.filter(ele => !ele.includes('<!--'));
			const commentArr = rowArr.filter(ele => ele.includes('<!--'));
			const rowArray = noCommentArr.filter(ele => ele.length > 0);
			const keys = rowArray.shift().split(",");
			let csvRows = [];
			for (let row of rowArray) {
				let pairs = row.split(",").reduce((acc, val, idx) => {
					acc[keys[idx]] = val;
					return acc;
				}, {});
				csvRows.push(pairs); // row
			}
			let resultObj = {
				rowData: csvRows,
				commentLength: commentArr.length,
				columns: keys,
			};
			resolve(resultObj);
		};
	}));
}

/**
 * 문자열로 입력받은 속성 값의 원래의 형태를 찾아서 파싱한뒤 되돌려주는 함수
 * @param {string} v - csv 로 입력받은 문자열 속성값
 * @returns {string|boolean|number|*} - 입력받은 문자열의 원래 type 으로 파싱한 값
 */
export function stringParser(v) {
	if (typeof v !== "string") return v; // 객체, 배열, undefined 되돌림
	if (v.toLowerCase() === "true" || v.toLowerCase() === "false") return (v === "true"); // boolean 파싱
	if (isNumeric(v)) { // 문자열 v 가 숫자이면
		return parseFloat(v); // 숫자로 파싱
	} else {
		return v;
	}
}

/**
 * 문자열 num을 받아서 정규표현식을 돌려서 숫자로 파싱 가능한지 불가능한지 판별하는 함수
 * @param {string} num - 파싱하면 숫자인지 문자열인지 알수 없는 문자열
 * @returns {boolean} - 숫자면 true , 숫자가 아니면 false
 */
export function isNumeric(num){
	num = String(num).replace(/^\s+|\s+$/g, ""); // 좌우 trim(공백제거)을 해준다.
	// 음수도 받을 수 있음, 소수도 받을 수 있음
	const regex = /^[-]?[0-9][0-9]*(\.[0-9]+)?$/g;
	if(regex.test(num)){
		num = num.replace(/,/g, "");
		return isNaN(num) ? false : true;
	}else{
		return false;
	}
}


export function flatObject(input) {
	function flat(res, key, val, pre = '') {
		const prefix = [pre, key].filter(v => v).join('.');
		if(Array.isArray(val)) val = [];
		if (typeof val === 'object') {
			return Object.keys(val).reduce((prev, curr) => flat(prev, curr, val[curr], prefix), res);
		} else {
			return Object.assign(res, {[prefix]: val});
		}
	}
	return Object.keys(input).reduce((prev, curr) => flat(prev, curr, input[curr]), {});
}

/**
 * data 를 받아서 SJIS로 인코딩을 하고 csv 가 다운로드 되도록하는 함수
 * @param data - export 할 csv 파일의 데이터
 * @param fileName - export 시 csv 파일의 이름
 */
export function downloadCsv(data, fileName) {
	const sjisArray = Encoding.convert(Encoding.stringToCode(data), {to: 'SJIS'});
	const blob  = new Blob([new Uint8Array(sjisArray)], {type: 'text/csv'});
	const result =  URL.createObjectURL(blob);
	const hiddenElement = document.createElement('a');
	hiddenElement.href = result;
	hiddenElement.target = '_blank';
	hiddenElement.download = `${fileName}.csv`;
	hiddenElement.click();
}

export function downloadFile(path) {
	const hiddenElement = document.createElement('a');
	hiddenElement.href = path;
	hiddenElement.target = '_blank';
	hiddenElement.click();
}

export function jsonToCsv(obj, values = [], keys = []) {
	let newObj = removeRevId(obj);
	for (const [k, v] of Object.entries(newObj)) {
		if (v.constructor === Object) {
			jsonToCsv(v, values, keys);
		} else {
			keys.push(k);
			values.push(v);
		}
	}
	return keys + '\r\n' + values;
}

function removeRevId(input) {
	let output = Object.assign({}, input);
	if (output._id) delete output._id;
	if (output._rev) delete output._rev;
	return output;
}

function zeroPadding(num, len) {
	let numStr = "";
	if (!len || len < 1) return num;
	let cnt = len;
	while (cnt > 0) {
		numStr += "0";
		cnt--;
	}
	return (numStr + num).slice(0 - Math.max(len, num.toString().length));
}

export function randomStr(len) {
	return (Math.random().toString(36)).slice(2, len + 2);
}

export function randomInt(len) {
	return Math.random().toFixed(len).split('.')[1]
}

/**
 * 객체가 nested 된 속성을 가지고 있는지 확인하는 함수
 * @param {object} target - 검사할 객체
 * @param {string, string[]} path - 검사할 객체의 속성 구조로 path 를 가지고 있는지
 * @returns {boolean} - true 또는 false
 */
export function checkNested(target, path) {
	if(typeof path === "string") path = path.split(".");
	if(path.length === 0) return true;
	let track = path.shift();
	if(!target.hasOwnProperty(track)) return false;
	return checkNested(target[track], path);
}

/**
 * source 객체에 없는 속성을 유지하면서 target 객체에 덮어씌운 객체을 리턴하는 함수
 * @param {object} target - 기존의 합칠 대상 객체
 * @param {object} source - 덮어 씌울 내용을 가진 객체
 * @returns {object} - source 에 없는 target 의 속성을 유지하면서 source 가 가진 속성을 target 의 속성에 덮어씌운 객체
 */
//  https://gist.github.com/ahtcx/0cd94e62691f539160b32ecda18af3d6
// Merge a `source` object to a `target` recursively
export const merge = (target, source) => {
	// Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
	for (let key of Object.keys(source)) {
		if (source[key] instanceof Object) Object.assign(source[key], merge(target[key], source[key]))
	}
	// Join `target` and modified `source`
	Object.assign(target || {}, source);
	return target
};

/**
 * 재귀적으로 객체를 clone 해서 deepClone 된 객체를 반환하는 함수
 * @param {object} obj - deepClone 할 객체
 * @returns {object} - deepClone 된 객체
 */
export const deepClone = obj => {
	const newObj = {};
	if (typeof obj === 'object') {
		for (let k in obj) {
			const prop = obj[k];
			const type = typeof prop;
			switch (type) {
				case 'object':
					if (Object.prototype.toString.call(prop) === '[object Array]') {
						newObj[k] = [];
						for (let item of prop) {
							newObj[k].push(deepClone(item));
						}
					} else {
						newObj[k] = deepClone(prop);
					}
					break;
				default:
					newObj[k] = prop;
					break;
			}
		}
		return newObj;
	} else {
		return obj;
	}
};

/**
 * errType 객체에서 errType 속성에 해당하는 속성값 문자열을 가져와서
 * 문자열 내의 ___ 부분을 ...arr 배열로 받은 인자들로 대체한 문자열을 리턴하는 함수
 * @param {string} type - 에러 메세지의 종류
 * @param {string|number|array} arr - 에러 관련 정보 인자들
 * @returns {string} - type 에 해당하는 상수문자열의 ___ 부분을 arr 의 인자들로 채워넣은 에러 안내 문자열
 */
export function makeErrorMsg (type, ...arr) {
	let template = errType[type];
	arr.forEach(e => (template = template.replace('___', e)));
	return template;
}

/**
 * 현재 배열에서 문제가 되는 객체의 csv 라인넘버 수를 리턴하는 함수
 * @param {Array} arr - 순회 중인 배열
 * @param {object} obj - 순회 중인 배열의 현재 객체
 * @param {number} comLength - 주석의 길이
 * @returns {number} - 현재 배열에서 문제가 되는 객체의 csv 파일의 라인넘버수를 리턴
 */
export const getNumberLine = (arr, obj, comLength) => (arr.indexOf(obj) + 2 + comLength);

export const makeColorCodeHSL = (idx) => {
	const HueRange = 360;
	const colorAdd = 30;
	const divideBy = HueRange / colorAdd;
	let h, s, l;
	h = idx % divideBy * colorAdd;
	s = 100 - (Math.floor(idx / divideBy) * 20);
	l = 50 + (Math.floor(idx / divideBy) * 20);
	return `hsl(${h}, ${s}%, ${l}%)`;
};

export const clamp = (min, max, val) => {
	return val < min ? min : val > max ? max : val;
};

export const getSubViewSettings = (settings, key, defaultValue) => {
    return settings ? settings.options ? settings.options[key] || defaultValue : defaultValue : defaultValue;
};

export const findSubViewType = (subViewSettings, blockId) => {
	let subViewOptionValue = Object.keys(subViewSettings.options);
	let result = {
		type: "",
		index: 0,
	};
	for (let subViewTypeKey of subViewOptionValue) {
		let subViewTypeValue = subViewSettings.options[subViewTypeKey];
		if (!Array.isArray(subViewTypeValue)) continue;
		for (let i = 0; i < subViewTypeValue.length; i++) {
			let item = subViewTypeValue[i];
			if (blockId === item.id) {
				return {
					type: subViewTypeKey,
					index: i,
				}
			}
		}
	}
	return result;
}

export const isPointValidate = (val) => {
	return /^[-]?\d+(\.\d+)?,[-]?\d+(\.\d+)?$/.test(val);
}

export const isPathDValidate = (val) => {
	return /^[a-zA-Z]\s[-]?\d+(\.\d+)?\s[-]?\d+(\.\d+)?$/.test(val);
}