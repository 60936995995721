import React, { Component } from 'react';
import { subject, grade, semester } from "../../../interface/constant";
import noImage from "../../../images/no-image.jpg";

class BookList extends Component {

	getClassName(item) {
		let className = "";
		if (["converting", "waiting", "compressing"].includes(item.status)) {
			className += ` de-active ${item.status}`;
		}
		className += this.props.selectedBookId === item.bookId ? " selected" : "";
		return className;
	}

	render() {
		const {item, editingUser} = this.props;
		return (
			<div className={"book-list"}>
				<div
					className={"book" + this.getClassName(item)}
					data-id={this.props.type}
					id={item.bookId}
					onClick={this.props.selectBook}
				>
					<div className={"book-image"}
					     style={{backgroundImage: `url(${item.coverPath || noImage})`}}/>
					<div className={"book-info"}>
						<span
							className={"book-title"}>{subject[item.subject]} {grade[item.grade]} {semester[item.semester]}</span>
						<br/>
						<span>{item.lastModified}</span>
						<br/>
						<span>{item.author}</span>
						<br/>
						<div className={"editing-user-title"}>
							{editingUser && Object.keys(editingUser).length > 0 ? "【編集中のユーザー】": ""}
						</div>
						<span className={"editing-user-list"}>
							{editingUser.map(user => user.id + " ")}
						</span>
					</div>
				</div>
			</div>
		);
	}
}

export default BookList;