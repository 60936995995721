import { getCookie, setCookie } from "./utils";
import { userLogin } from "../reducers/CombineReducers/auth";

export async function route(type, path, params, isForm = false) {
	let init = {
		method: type,
		headers: {
			'x-access-token': getCookie("token"),
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
	};
	if (type !== "GET") init.body = isForm ? params : JSON.stringify(params);
	if (isForm) delete init.headers['Content-Type']; // form data upload

	let res = await fetch(path, init).then((res) => res.json());
	if (!res.success) {
		throw new Error(res.message);
	}
	return res;
}

async function get(path) {
	return route("GET", path, "", false);
}

async function post(path, params, isForm) {
	return route("POST", path, params, isForm);
}

/*
async function put(path, params) {
	return route("PUT", path, params);
}
*/

async function del(path, params) {
	return route("DELETE", path, params);
}

/* ADD function to USE API */

export const doLogin = (id, password, saveLogin, userAgent, ipv4) => async dispatch => {
	try {
		let res = await post('/auth/login', {id, password, userAgent, ipv4});
		if(res.hasOwnProperty("token")) {
			let expireDay = (saveLogin) ? 30 : undefined;
			setCookie("token", res.token, expireDay);
			dispatch(userLogin(res));
		}
		return res;
	} catch (err) {
		handleError(err);
	}
};

export const changePassword = (oldPass, newPass) => async dispatch => {
	try {
		return await post('/api/changePass',  {oldPass, newPass});
	} catch(err) {
		handleError(err);
	}
};

export function doLogout() {
	setCookie("token", "", 0);
	window.location.reload();
}

export const chkValidate = () => async dispatch => {
	try {
		let res = await get('/api/validate');
		dispatch(userLogin(res));
	} catch {
		doLogout();
	}
};

export async function getBookList() {
	try {
		return await get(`/api/bookList`);
	} catch (err) {
		handleError(err);
		return [];
	}
}

export async function getGroupBookList() {
	try {
		return await get(`/api/groupBookList`);
	} catch (err) {
		handleError(err);
		return [];
	}
}

export async function chkMakeBook() {
	try {
		return await get('/api/chkMakeBook')
	} catch (err) {
		handleError(err);
		return [];
	}
}

export async function getLastIndex(id) {
	try {
		return await get(`/api/lastIndex`);
	} catch (err) {
		handleError(err);
		return [];
	}
}


export const imageUpload = async (form, file) => {
	try {
		const isImg = file['type'].split('/')[0] === 'image';
		if (!isImg) throw new Error(file['type'] + "is not image type");

		return await upload(form, file);
	} catch (err) {
		handleError(err);
	}
};

export const videoUpload = async (form, file) => {
	try {
		const isVideo = file['type'].split('/')[0] === 'video';
		if (!isVideo) throw new Error(file['type'] + "is not video type");

		return await upload(form, file);
	} catch (err) {
		handleError(err);
	}
};

export const audioUpload = async (form, file) => {
	try {
		const isAudio = file['type'].split('/')[0] === 'audio';
		if (!isAudio) throw new Error(file['type'] + "is not audio type");

		return await upload(form, file);
	} catch (err) {
		handleError(err);
	}
};

export const uploadMulti = async (form) => {
	try {
		const fd = new FormData(form);
		let data = await post("/api/uploadMulti", fd, true);
		console.log(data);
		return data.pathInfo;
	} catch (err) {
		handleError(err);
	}
};

export const upload = async (form, file) => {
	try {
		const fd = new FormData(form);
		fd.append("file", file);

		let data = await post("/api/upload", fd, true);
		return data.pathInfo;
	} catch(err) {
		handleError(err);
	}
};

export async function putNewBookInfo(info) {
	try {
		return await post(`/api/newBookInfo`, {info}, false);
	} catch (err) {
		handleError(err);
	}
}

export async function bookInfo(bookId, info) {
	try {
		return await post("api/bookInfo", {bookId, info}, false);
	} catch (err) {
		handleError(err);
	}
}

export async function getBookInfo(bookId) {
	try {
		return await post("api/getBookInfo", {bookId}, false);
	} catch (err) {
		handleError(err);
	}
}

export async function deleteBook(bookId) {
	try {
		return await del("api/book", {bookId});
	} catch (err) {
		handleError(err);
	}
}

export async function makeBook(bookId) {
	try {
		return await post("api/make", {bookId}, false);
	} catch (err) {
		handleError(err);
	}
}

export async function makeZip(bookId) {
	try {
		return await post("api/makeZip", {bookId}, false);
	} catch (err) {
		handleError(err);
	}
}

export async function clean() {
	try {
		return await get("api/clean");
	} catch (err) {
		handleError(err)
	}
}

export async function authorChange(bookId, author) {
	try {
		return await post("api/authorChange", {bookId, author}, false);
	} catch (err) {
		handleError(err);
	}
}

export async function getUserList() {
	try {
		return await get(`/api/userList`);
	} catch (err) {
		handleError(err);
		return [];
	}
}

export async function changeUserActive(id, active) {
	try {
		return await post(`/api/changeUserActive`, {id, active}, false);
	} catch (err) {
		handleError(err);
		return [];
	}
}

export async function changeUserExpireDate(id, expireDate) {
	try {
		return await post(`/api/changeUserExpireDate`, {id, expireDate}, false);
	} catch (err) {
		handleError(err);
		return [];
	}
}

export async function moveRecycleFile() {
	try {
		return await post(`/api/moveRecycleFile`, {}, false);
	} catch (err) {
		handleError(err);
		return [];
	}
}

export async function deleteRecycleFile() {
	try {
		return await post(`/api/deleteRecycleFile`, {}, false);
	} catch (err) {
		handleError(err);
		return [];
	}
}

export async function getServerFileSize() {
	try {
		return await get(`/api/getServerFileSize`);
	} catch (err) {
		handleError(err);
		return [];
	}
}

export async function addGroup(groupId) {
	try {
		return await post(`/api/addGroup`, {groupId}, false);
	} catch (err) {
		handleError(err);
		return [];
	}
}

export async function deleteGroup(groupId) {
	try {
		return await post(`/api/deleteGroup`, {groupId}, false);
	} catch (err) {
		handleError(err);
		return [];
	}
}

export async function changeGroupName(groupId, groupName) {
	try {
		return await post(`/api/changeGroupName`, {groupId, groupName}, false);
	} catch (err) {
		handleError(err);
		return [];
	}
}

export async function getGroupList() {
	try {
		return await get(`/api/groupList`);
	} catch (err) {
		handleError(err);
		return [];
	}
}

export async function changeGroupUser(id, users) {
	try {
		return await post(`/api/changeGroupUser`, {id, users}, false);
	} catch (err) {
		handleError(err);
		return [];
	}
}

export async function saveBook(bookId, data, pages, isOverwrite = false) {
	try {
		return await post(`/api/saveBook`, {bookId, data, pages, isOverwrite}, false);
	} catch (err) {
		handleError(err);
		return [];
	}
}

export async function addPage(bookId, data, pages) {
	try {
		return await post(`/api/addPage`, {bookId, data, pages}, false);
	} catch (err) {
		handleError(err);
		return [];
	}
}

export async function deletePage(bookId, metaData, pages) {
	try {
		return await post(`/api/deletePage`, {bookId, metaData, pages}, false);
	} catch (err) {
		handleError(err);
		return [];
	}
}

export async function getBookData(bookId, pages = []) {
	try {
		return await post(`/api/getBookData`, {bookId, pages}, false);
	} catch (err) {
		handleError(err);
		return [];
	}
}

export async function makeBookDB(bookId, data) {
	try {
		return await post(`/api/makeBookDB`, {bookId, data}, false);
	} catch (err) {
		handleError(err);
		return [];
	}
}

export async function addRepo(bookId, repoData, isChange = false) {
	try {
		return await post(`/api/addRepo`, {bookId, repoData, isChange}, false);
	} catch (err) {
		handleError(err);
		return [];
	}
}

export async function addMultiFileRepo(bookId, kind, repoData, isChange = false) {
	try {
		return await post(`/api/addMultiFileRepo`, {bookId, kind, repoData, isChange}, false);
	} catch (err) {
		handleError(err);
		return [];
	}
}

export async function deleteRepo(bookId, kind, key) {
	try {
		return await post(`/api/deleteRepo`, {bookId, kind, key}, false);
	} catch (err) {
		handleError(err);
		return [];
	}
}

export async function setPageLock(bookId, pages, pageLockUser) {
	try {
		return await post(`/api/setPageLock`, {bookId, pages, pageLockUser}, false);
	} catch (err) {
		handleError(err);
		return [];
	}
}

export async function saveCsvData(bookId, type, data) {
	try {
		return await post(`/api/saveCsvData`, {bookId, type, data}, false);
	} catch (err) {
		handleError(err);
		return [];
	}
}

export async function getBookMetaDataList() {
	try {
		return await get(`/api/getBookMetaDataList`);
	} catch (err) {
		handleError(err);
		return [];
	}
}

export async function saveLogFile(bookId, contents) {
	try {
		return await post(`/log/saveLogFile`, {bookId, contents}, false);
	} catch (err) {
		handleError(err);
		return [];
	}
}

export async function addEditBookLog(tableName, log) {
	try {
		return await post(`/api/addEditBookLog`, {tableName, log}, false);
	} catch (err) {
		handleError(err);
		return [];
	}
}

export async function addEditAdminLog(log) {
	try {
		return await post(`/api/addEditAdminLog`, {log}, false);
	} catch (err) {
		handleError(err);
		return [];
	}
}

export async function addLoginLog(log) {
	try {
		return await post(`/api/addLoginLog`, {log}, false);
	} catch (err) {
		handleError(err);
		return [];
	}
}

export async function getLog(tableName="", page, selectedStartDate, selectedEndDate,work) {
	try {
		return await post(`/api/getLog`, {tableName, page, selectedStartDate, selectedEndDate, work}, false);
	} catch (err) {
		handleError(err);
		return [];
	}
}

export async function getAdminLog(tableName="admin_log", page, selectedStartDate, selectedEndDate) {
	try {
		return await post(`/api/getAdminLog`, {tableName, page, selectedStartDate, selectedEndDate}, false);
	} catch (err) {
		handleError(err);
		return [];
	}
}

export async function getLoginLog(tableName="login_log", page, selectedStartDate, selectedEndDate) {
	try {
		return await post(`/api/getLoginLog`, {tableName, page, selectedStartDate, selectedEndDate}, false);
	} catch (err) {
		handleError(err);
		return [];
	}
}


function handleError(msg) {
	if (msg === "jwt expired") {
		doLogout();
	} else {
		console.error(msg);
	}
}