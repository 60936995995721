import React, { Component } from 'react';
import { doLogin, addLoginLog } from "../../../interface/api";
import "./Login.scss";
import { connect } from "react-redux";
import {now} from "../../../interface/utils";

const mapStateToProps = ({auth, current, metaData}) => ({auth, current, metaData});
const mapDispatchToProps = {doLogin};

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: "",
			password: "",
			saveLogin: false,
			errorMsg: "",
		};
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		return this.props.current.contents === "login" || nextProps.current.contents === "login" || nextState !== this.state;
	}

	handleChange = (e) => {
		const target = e.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		this.setState({[target.name]: value});
	};

	loginProc = async (e) => {
		e.preventDefault();
		const {id, password, saveLogin} = this.state;
		const currentTime = now();
		const userAgent = navigator.userAgent;
		const publicIp = require("react-public-ip");
		const ipv4 = await publicIp.v4() || "";

		if (id === "" || password === "") {
			this.setState({errorMsg: "入力してください。"});
			return;
		}
		const result = await this.props.doLogin(id, password, saveLogin, userAgent, ipv4);
		await addLoginLog({regDate:`${currentTime}`, userId:`${id}`, PC:`${userAgent}`, IP:`${ipv4}`, action: `ログイン成功`});

		if(result.message) {
			this.setState({errorMsg: result.message});
		}
	};

	render() {
		return (
			<div id="Login" className="account">
				<div className="input_login">
					<div className="logo">
						<svg className="logo_svg"/>
					</div>
					<div className="login-form-wrap">
						<form>
							<input
								type="text" name="id"
								onChange={this.handleChange}
								placeholder="E-mail"
								autoComplete="off"
								className="spacing-base"
							/>
							<input
								type="password" name="password"
								autoComplete="current-password"
								onChange={this.handleChange}
								placeholder="パスワード" className="spacing-base"
							/>
							<div className="login-error">{this.state.errorMsg}</div>
							<div className="login-remember-check spacing-base">
								<label>
									<input
										type="checkbox"
										className="checkbox_size"
										name="saveLogin"
										onChange={this.handleChange}
									/>
									<span>ログイン状態を記憶</span>
								</label>
							</div>
							<input
								type="button" value="ログイン"
								onClick={this.loginProc.bind(this)}
								className="checkbox_size"
							/>
						</form>
					</div>
				</div>
			</div>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);