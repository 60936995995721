import React, { Component } from 'react';
import Panel from "../Panel";
import "./LinkPagePanel.scss";
import LinkPage from "../../../../../containers/Contents/Editor/LinkPage";

const panelState = {
	id:  "LinkPagePanel",
	label: "",
	name: "linkPages",
	style: {
		/*width: 600,*/
		height: 210,
		top: 100,
		left: 10,
	}
};

class LinkPagePanel extends Component {
	keyDown(e) {
		// ObjectLayerのkeydownイベントを実行しない
		e.nativeEvent.stopImmediatePropagation();
	}

	render() {
		return (
			<div className="panel-object-list-inner inner-contents" onKeyDown={this.keyDown.bind(this)}>
				<LinkPage/>
			</div>
		)
	}
}

export default Panel(LinkPagePanel, panelState);