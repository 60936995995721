import React, {Component} from 'react';
import {connect} from 'react-redux';
import "./Import.css";
import ImportButtons from '../../../components/Contents/Import/ImportButtons';
import Title from '../../../components/Common/Title';
import {csvImporting, setContents, setPages, setBookSaved, startLoading, endLoading, addLog} from '../../../reducers/CombineReducers/current';
import {
	downloadCsv,
	readCsvAsync,
	flatObject,
	now,
	merge,
	deepClone,
	makeErrorMsg, getNumberLine,
} from '../../../interface/utils';
import {
	inputDuplicateCheck,
	idSubString,
	checkPropsKeyExist,
	getImportModel,
	requirePropCheck,
	pageInvalidCheck,
	modifyClonedData,
	getCommentByCsvType,
	getReduxModeObject,
	getAllOriginId,
	getClonedData,
} from "./csvUtils";
import {setMetadata,setChapter} from "../../../reducers/CombineReducers/metaData";
import {saveCsvData} from "../../../interface/api";
import {setData} from "../../../interface/db"
import {
	subject, semester, grade, componentTitle, modalTitle, loadingMsg,
} from "../../../interface/constant";
import {
	csvModalMsg,
	csvColumnsName,
	csvConst,
	videoProps, editorProp, svColumn, vColumn, changeString
} from "../../../interface/csvConstant";
import {setBookData} from "../../../reducers/CombineReducers/pageData";
import Modal from "../../../components/Common/Modal";
import * as Chapter from "./chapter.js";
import * as Video from "./video.js";
import * as Editor from "./editor.js";
import * as Book from "./book.js";
import * as SubView from './subView.js';
import * as Html from './html.js';
import * as Audio from './audio.js';
import {getImportVideoModel} from "../../../interface/csvModel";

const mapStateToProps = ({current, auth, metaData, pageData, repo}) => {
	return {current, auth, metaData, pageData, repo}
};

const mapDispatchToProps = dispatch => {
	return {
		setContents: (state) => dispatch(setContents(state)),
		setMetadata: (state) => dispatch(setMetadata(state)),
		setChapter: (state) => dispatch(setChapter(state)),
		setBookData: (state) => dispatch(setBookData(state)),
		setPages: (state) => dispatch(setPages(state)),
		csvImporting: (state) => dispatch(csvImporting(state)),
		setBookSaved: (state) => dispatch(setBookSaved(state)),
		setData: (state) => dispatch(setData(state)),
		startLoading: (state) => dispatch(startLoading(state)),
		endLoading: (state) => dispatch(endLoading(state)),
		addLog : (state) => dispatch(addLog(state)),
	}
};

class Import extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModalError: false,
			showModalSuccess: false,
			showModalNoImport: false,
			showModalMsg: "",
			editingUserList: [],
		};
		this.props.socket.getBookList(this.setStateEditingUserList.bind(this));
	}

	setStateEditingUserList(editingBookList) {
		const key = Object.keys(editingBookList).find(bookId => bookId === this.props.metaData.bookId);
		if (editingBookList.hasOwnProperty(key)) {
			this.setState({
				editingUserList: editingBookList[key],
			});
		} else {
			console.error(editingBookList);
			console.error(this.props.meta.bookId);
		}
	}

	/**
	 * Type 종류, name 에 따라서 Modal 열 때 보여줄 옵션을 정하는 함수
	 * @param {string} type - [Success|Error]
	 * @param {boolean} show - true
	 * @param {string} name - [ book, chapter, subView, editor, audio, html, video ]
	 * @param {string} message - modal 에 띄워줄 메시지
	 */
	toggleModal(type = "Error", show = true, name, message = '') {
		let modalType = `showModal${type}`;
		let msg = '';
		if (type === 'Error') {
			msg = `【${csvModalMsg[name]}】が失敗しました。<br>${message}`;
		} else {
			msg = `【${csvModalMsg[name]}】が成功しました。<br>${message}`;
		}
		this.setState({
			...this.state,
			[modalType]: show,
			showModalMsg: msg,
		})
	}

	onClickX = (e) => {
		this.props.setContents({contents: csvConst.editor});
		this.props.setContents({contents: 'editor'});
	};

	/**
	 * csv import 로 받은 subView 객체들이 있는 배열을 순회하면서
	 * 각 객체의 id와 originId의 보호 문자열을 제거한 배열을 반환하는 함수
	 * @param {array} rowData - csv import 로 받은 subView 객체 배열
	 * @returns {array} - 각 객체의 id와 originId의 보호 문자열, 엑셀 자동 변환 방지를 제거한 객체 배열
	 */
	changeIdForRedux = (rowData) => {
		return rowData.map(obj => {
			const idChangedObj = idSubString(obj, svColumn.id);
			return idSubString(idChangedObj, svColumn.originId);
		});
	};

	/**
	 * dataList 를 순회하면서 객체의 모든 속성의 값이 빈값이면 empty row 에러를 던지는 함수
	 * @param {array} dataList - import 해서 들어온 데이터 배열
	 * @param {number} comLength - 주석의 길이
	 */
	emptyRowCheck = (dataList, comLength) => {
		dataList.forEach((data, idx) => {
			const noData = Object.values(data).every((ele) => ele === "");
			const csvLineNum = idx + comLength + 2;
			if (noData) throw new Error(makeErrorMsg('incorrect', csvLineNum, 'ロー'));
		});
	};

	/**
	 * dataList 를 순회하면서 dataList 의 일본어 속성명을 영어 속성명으로 바꾼 객체 들의 배열을 반호나하는 함수
	 * @param {array} dataList - csv import 로 들어온 객체 배열 데이터
	 * @param {array} userDevColumns - 키값으로 devColumn 밸류값으로 userColumn 을 가진 배열
	 * @returns {array} - dataList 배열의 각 객체의 의 속성 값이 영문인 객체들의 배열
	 */
	ColumnsNameChange = (dataList, userDevColumns) => {
		return dataList.map(obj => {
			for (const prop of Object.keys(obj)) {
				for (const [devColumn, userColumn] of userDevColumns) {
					if (prop === userColumn) {
						obj[devColumn] = obj[userColumn];
						delete obj[userColumn]
					}
				}
			}
			return obj;
		})
	};

	/**
	 * 각 객체의 flag 속성이 1인지 2인지 3인지 카운팅해서 전체 import 성공시 출력될 메세지에
	 * 전체 import 건수와 추가, 수정, 삭제 건수를 알려주기 위한 문자열을 생성해 반환하는 함수
	 * @param {array} changedObjData - pageData 객체들이 있는 배열
	 * @returns {string} - import 전체,추가, 수정, 삭제 건수 정보 문자열
	 */
	getRequestCountMsg = (changedObjData) => {
		let countCreate, countUpdate, countDelete;
		countCreate = countUpdate = countDelete = 0;

		for (const row of changedObjData) {
			if (row.flag === 1) {
				countCreate++;
			} else if (row.flag === 2) {
				countUpdate++;
			} else if (row.flag === 3) {
				countDelete++;
			}
		}
		const allCount = countCreate + countUpdate + countDelete;
		let message = `全体件数 ${allCount}件`;

		if (countCreate > 0) message += `　追加${countCreate}件`;
		if (countUpdate > 0) message += `　修正${countUpdate}件`;
		if (countDelete > 0) message += `　削除${countDelete}件`;
		return message;
	};

	/**
	 * flatReduxClone 에서 제거할 대상 객체의 originId에 해당 하는 객체가 있는 page, type 에서 제거한 뒤
	 * clonedData 에 반영하는 함수
	 * @param {object} csvObj - import 대상 객체
	 * @param {object} flatReduxClone - cloned flat pageData
	 * @param {object} clonedData - cloned pageData
	 */
	deletePageData = (csvObj, flatReduxClone, clonedData) => {
		const csvOriginId = csvObj.originId;
		const target = flatReduxClone.filter(e => e.originId === csvOriginId)[0];
		const {originId, page, type} = target;
		const list = clonedData[page][csvConst.objects][type];
		clonedData[page][csvConst.objects][type] = list.filter(e => e.originId !== originId);
	};

	/**
	 * originId에 해당하는 객체를 수정할 건데 redux 상에 그런 아이디가 존재하지 않으면 에러를 던지는 함수
	 * @param {object} csvObj - import 대상 객체
	 * @param {object} flatReduxClone - cloned flat pageData
	 * @param {number} idx - csv 상에서 import 할 대상 객체의 라인 넘버수
	 */
	originIdExistsCheck = (csvObj, flatReduxClone, idx) => {
		const reduxIdList = flatReduxClone.map(e => e.originId);
		const find = reduxIdList.some(e => e === csvObj.originId);
		if (!find) throw new Error(makeErrorMsg('isNotExists', idx, csvColumnsName.originId));
	};

	/**
	 * export 시 불필요한 객체를 제거하기 위해 clonedData 에서 typeFilterList 배열 안의 대상 속성들을 제거한 clonedData 을 반환
	 * @param {object} clonedData - this.props.pageData 을 deepClone 한 객체
	 * @param {array} typeFilterList - export 시 불필요한 객체를 제외하기 위한 필터 대상 이름이 적힌 배열 ex) ['video', 'audio']
	 * @returns {object} - typeFilterList 배열에 포함되는 속성을 제거한 clonedData
	 */
	typeFiltering = (clonedData, typeFilterList) => {
		for (const pageObj of Object.values(clonedData)) {
			for (const target of typeFilterList) {
				const objects = pageObj[csvConst.objects];
				if (objects.hasOwnProperty(target)) delete objects[target];
			}
		}
		return clonedData;
	};

	/**
	 * 각 page 의 objects 객체의 _id 와 _rev 를 제거한 pageData 객체를 반환하는 함수
	 * @param {object} filteredPageData - _id 와 _rev 속성이 있는 pageData
	 */
	deleteRevId = (filteredPageData) => {
		const newObj = {};
		for (const [page, pageObj] of Object.entries(filteredPageData)) {
			const newPageObj = Object.assign({}, pageObj);
			if (newPageObj.hasOwnProperty('_id')) delete newPageObj['_id'];
			if (newPageObj.hasOwnProperty('_rev')) delete newPageObj['_rev'];
			newObj[page] = newPageObj;
		}
		return newObj;
	};

	/**
	 * export 시에는 cloneData 에서 typeFilterList 에 포함되는 것들을 제거한 Data 만 가져와서 _rev, _id를 제거한 객체배열을
	 * 순회하면서 각 objects 객체 안의 각 객체배열을 순회하면서 각 객체에 type, page, flag 를 부여하고 propFilterList 에
	 * 포함되는 속성들을 제거한 뒤 subView 에서 icon 의 type 을 분리해서 정해준 객체의 속성을 flat 하게 만들어서
	 * flat 한 속성을 가진 객체로 이루어진 배열을 반환하는 함수
	 * @param {object} clonedData - this.props.pageData 을 deepClone 한 객체
	 * @param {array} typeFilterList - pageData 에서 videoLink 의외의 모든것들의 이름이 담긴 배열 ex) ["subView", "pageLink" ...]
	 * @param {array} propFilterList - 각 video 객체에서 제거할 속성들의 이름이 담긴 배열
	 * @param {boolean} useExport - [true|false] export 시 함수를 사용하면 true, import 시 함수를 사용하면 false
	 * @returns {Array} - redux Page 에서 필요한 객체만 flat 하게 만든 객체들의 배열
	 */
	getFlatPagedData = (clonedData, typeFilterList, propFilterList, useExport = true) => {
		const flatArr = [];
		const filteredPageData = (useExport) ? this.typeFiltering(clonedData, typeFilterList) : clonedData;
		const noRevPageData = this.deleteRevId(filteredPageData);
		for (const page of Object.keys(noRevPageData)) {
			const objects = noRevPageData[page][csvConst.objects];
			for (const [type, typeArr] of Object.entries(objects)) {
				for (const obj of typeArr) {
					const newObj = Object.assign({}, obj);
					newObj[csvConst.type] = type;
					newObj[csvConst.page] = Number(page);
					newObj[csvConst.flag] = 2;
					for (const target of propFilterList) {
						if (newObj.hasOwnProperty(target)) delete newObj[target];
					}
					if (useExport) this.iconTypeMake(newObj); // change type subView ===> icon
					flatArr.push(flatObject(newObj))
				}
			}
		}
		return flatArr;
	};

	/**
	 * subView import 시 에러를 체크하는 함수
	 * @param {object} csvObj - flag 가 1인 subView 객체
	 * @param {number} idx - csv 상에서 현재 value 속성값을 가진 객체의 라인 넘버수
	 * @param {array} flatReduxClone - pageData 의 모든 객체를 flat 한 속성을 갖게 만든 객체들의 배열
	 * @param {number} totalPage - meta.totalPages 현재 책의 최대 페이지수
	 * @param {object} bookSize - metaData.view 객체 { height: number , width: number }
	 */
	addImportErrorCheck = (csvObj, idx, flatReduxClone, totalPage, bookSize) => {
		const {height, width} = bookSize;
		this.addOriginIdCheck(csvObj, flatReduxClone, idx); // originId 중복 체크
		this.addTopCheck(csvObj, idx, height); // top Y座標는 숫자인지 체크
		this.addLeftCheck(csvObj, idx, width); // left X座標는 숫자인지 체크
		pageInvalidCheck(csvObj, idx, totalPage); // page 입력했는지, 숫자인지, 범위 내인지 체크
	};

	/**
	 * csvObj 의 속성값의 유효성을 검사하는 함수
	 * @param {object} csvObj - flag 가 2인 subView 객체
	 * @param {number} idx - csv 상에서 현재 value 속성값을 가진 객체의 라인 넘버수
	 * @param {number} totalPage - redux 내의 존재하는 모든 페이지 가령 10페이지면 [ 1 , 2, 3, ...10 ]
	 */
	updateImportErrorCheck = (csvObj, idx, totalPage) => {
		requirePropCheck(csvObj, 'id', idx, 'video'); // video csv 에서 객체의 id 속성이 필수입력인데 빈값이면 에러
		this.cssTopLeftCheck(csvObj, videoProps["css.left"], idx); // X座標
		this.cssTopLeftCheck(csvObj, videoProps["css.top"], idx); // Y座標
		pageInvalidCheck(csvObj, idx, totalPage); // page 입력했는지, 숫자인지, 범위 내인지 체크
	};

	/**
	 * csvObj 의 originId 가 이미 사용하고 있는 originId 인지 체크해서 중복되면 에러를 던지는 함수
	 * @param {object} csvObj - flag 1인 import 대상 video 객체
	 * @param {array} flatReduxClone - flat 한 속성을 가진 redux clone 객체 배열
	 * @param {number} idx - csvObj 가 csv 상에서 위치한 라인넘버
	 */
	addOriginIdCheck = (csvObj, flatReduxClone, idx) => {
		const originId = csvObj.originId;
		const isNoInput = (originId === 0 || originId === "");
		if (!isNoInput) { // something input
			const reduxIdList = flatReduxClone.map(e => e.originId);
			const match = reduxIdList.some(e => e === originId);
			if (match) {
				throw new Error(makeErrorMsg('unique', idx, csvColumnsName.originId));
			}
		}
	};

	/**
	 * csvObj 객체의 css.top 속성이 빈값이 아니고 숫자도 아닌 경우 에러를 던지고
	 * 숫자이긴하지만  metaData.view.height 값을 초과하면 에러를 던지는 함수
	 * @param {object} csvObj - flag 1인 import 대상 video 객체
	 * @param {number} idx - csvObj 가 csv 상에서 위치한 라인넘버
	 * @param {object} bookMaxHeight - metaData.view.height
	 */
	addTopCheck = (csvObj, idx, bookMaxHeight) => {
		const top = csvObj['css.top'];
		const csvNotNull = (top !== "");
		if (csvNotNull) {
			if (typeof top !== 'number') {
				throw new Error(makeErrorMsg('onlyNumber', idx, csvColumnsName['css.top']));
			} else {
				if (top > bookMaxHeight) {
					throw new Error(makeErrorMsg('overRange', idx, csvColumnsName['css.top']));
				}
			}
		}
	};

	/**
	 * csvObj 객체의 css.left 속성이 빈값이 아니고 숫자도 아닌 경우 에러를 던지고
	 * 숫자이긴하지만  metaData.view.width 값을 초과하면 에러를 던지는 함수
	 * @param {object} csvObj - flag 1인 import 대상 video 객체
	 * @param {number} idx - csvObj 가 csv 상에서 위치한 라인넘버
	 * @param {object} bookMaxWidth - metaData.view.width
	 */
	addLeftCheck = (csvObj, idx, bookMaxWidth) => {
		const left = csvObj['css.left'];
		const csvNotNull = (left !== "");
		if (csvNotNull) {
			if (typeof left !== 'number') {
				throw new Error(makeErrorMsg('onlyNumber', idx, csvColumnsName['css.left']));
			} else {
				if (left > bookMaxWidth) {
					throw new Error(makeErrorMsg('overRange', idx, csvColumnsName['css.left']));
				}
			}
		}
	};

	/**
	 * csvObj 의 prop 속성의 값이 숫자가 아니면 에러를 던지는 함수
	 * @param {object} csvObj - flag 1인 import 대상 video 객체
	 * @param {string} prop - csvObj 의 속성
	 * @param {number} idx - csvObj 가 csv 상에서 위치한 라인넘버
	 */
	cssTopLeftCheck = (csvObj, prop, idx) => {
		const val = csvObj[prop];
		if (typeof val !== 'number') {
			throw new Error(makeErrorMsg('incorrect', idx, csvColumnsName[prop]));
		}
	};

	importingHtmlData = (parsedData, flatReduxClone, comLength, clonedData, reduxPageData) => {
		for (const csvObj of parsedData) {
			const reduxObj = flatReduxClone.find(obj => obj.originId === csvObj.originId);
			const reduxObject = Html.getOriginSubViewObject(reduxPageData, reduxObj, csvObj);
			const unFlatCsvObject = Html.getUnFlatObject(csvObj);
			const mergedObj = merge(reduxObject, unFlatCsvObject); // merge redux with csv
			Html.updateHtmlData(clonedData, reduxObj, csvObj, mergedObj);
		}
	};

	/**
	 * csv import 시 각 row 의 flag 1,2,3 에 따라서 리덕스에 반영할 clonedData 을 가공하는 함수
	 * @param {array} parsedData - flat 한 속성명에 parse 된 속성값을 가진 video 객체들의 배열
	 * @param {array} flatReduxClone - pageData 의 모든 객체를 flat 한 속성을 갖게 만든 객체들의 배열
	 * @param {number} comLength - csv 파일의 주석길이
	 * @param {object} clonedData - pageData 를 deepClone 한 객체
	 * @param {object} metaData - cloned metaData
	 * @param {object} repo - 키는 레포지토리 키, 값은 경로로 이루어진 repo 객체
	 */
	importingVideoData = (parsedData, flatReduxClone, comLength, clonedData, metaData, repo) => {
		const totalPage = metaData.totalPages;
		const bookSize = metaData.view;
		const dupleCheckObj = {originId: []};
		for (const csvObj of parsedData) {
			const idx = getNumberLine(parsedData, csvObj, comLength);
			const reduxObj = flatReduxClone.find(obj => obj.originId === csvObj.originId);
			inputDuplicateCheck(csvObj, idx, dupleCheckObj); // csv 에 똑같은 originId를 입력하면 에러
			switch (csvObj.flag) {
			case 1: {
					delete csvObj.flag; // redux 에는 flag 반영안함
					checkPropsKeyExist(csvObj, 'data.fileKey', idx, repo, 'video'); // repo key 체크
					checkPropsKeyExist(csvObj, 'data.subtitleKey', idx, repo, 'srt'); // repo key 체크
					this.addImportErrorCheck(csvObj, idx, flatReduxClone, totalPage, bookSize);
					const flatDefaultObj = Video.flagOneDefaultValueSetting(csvObj); // 기본 값 설정
					const videoModel = getImportVideoModel(); // import 용 Model 객체 가져오기
					const importModelObj = getImportModel(videoModel, flatDefaultObj); // model 에 csv 객체 값 넣기
					Video.addVideoData(clonedData, importModelObj); // 리덕스에 반영할 cloneData 가공
					break;
				}
				case 2: {
					delete csvObj.flag; // redux 에는 flag 반영안함
					checkPropsKeyExist(csvObj, 'data.fileKey', idx, repo, 'video'); // repo key 체크
					checkPropsKeyExist(csvObj, 'data.subtitleKey', idx, repo, 'srt'); // repo key 체크
					this.originIdExistsCheck(csvObj, flatReduxClone, idx); // originId가 redux 내에 존재하지 않으면 에러
					this.updateImportErrorCheck(csvObj, idx, totalPage); // flag2 일때 video 객체 에러 체크
					const videoModel = getImportVideoModel(); // import 용 Model 객체 가져오기
					const importModelObj = getImportModel(videoModel, csvObj); // model 에 csv 객체 값 넣기
					modifyClonedData(clonedData, reduxObj, importModelObj, 'videoLink'); // 수정
					break;
				}
				case 3: {
					this.originIdExistsCheck(csvObj, flatReduxClone, idx); // originId가 redux 내에 존재하지 않으면 에러
					this.deletePageData(csvObj, flatReduxClone, clonedData);
					break;
				}
				default:
					break;
			}
		}
	};

	/**
	 * newObj 에 data 속성에 class 속성을 가지고 있으면 class 에 속성값에 icon 이 있으면 type 속성 값을 icon 으로 바꿔주고
	 * class 에서 icon 을 제거한 나머지 class 속성값만 가지도록 객체를 고치는 함수
	 * @param {object} newObj - clonedData 안을 모두 순회하면서 나오는 각 객체
	 */
	iconTypeMake = (newObj) => {
		if (newObj.hasOwnProperty(csvConst.data) && newObj[csvConst.data].hasOwnProperty(csvConst.class)) {
			let dataClassObj = Object.assign({}, newObj[csvConst.data]);
			for (const dataClass of newObj[csvConst.data][csvConst.class].split(' ')) {
				if (dataClass === csvConst.icon) {
					newObj[csvConst.type] = csvConst.icon;
					let result = newObj[csvConst.data][csvConst.class].split(' ').filter(e => {
						return e !== csvConst.icon;
					});
					dataClassObj[csvConst.class] = result.toString();
				}
			}
			newObj[csvConst.data] = dataClassObj
		}
	};

	/**
	 * 역할: csv export 하기전에 가공된 clone 데이터를 순회하면서 csv row 문자열을 만듬
	 * 가공된 clone data 와 컬럼 배열을 이용해 csv 상에 보여줄 row 를 만드는 함수
	 * @param {array} csvExportArr - csv 에 추출할 데이터들이 담긴 객체들의 배열
	 * @param {array} exportSubViewColumns - csv 에서 보여줄 컬럼 이름이 담긴 배열
	 * @returns {string} - csv 의 row 부분이 될 문장열
	 */
	createCsvRows = (csvExportArr, exportSubViewColumns, newColumns = null) => {
		let text = "";
		const oldColumns = exportSubViewColumns;
		csvExportArr.forEach(csvObj => {
			const tempArr = [];
			if (newColumns) {
				// My에디터csv일때 position에 따른 Y좌표, X좌표의 데이터값 변경
				exportSubViewColumns = csvObj["css.position"] === "Y" ? newColumns : oldColumns;
			}
			Object.keys(csvObj).forEach(column => {
				let val = csvObj[column];
				const idx = exportSubViewColumns.indexOf(column);
				const target = [csvConst.id, csvConst.originId, csvConst.textId];
				const condition = target.some(e => e === column);
				if (column === "value") {
					val = val.replace(/\n/g, " "); // text내용에 \n 이 들어가 있으면 csv에선 줄넘김이 되어버리므로 제거
					changeString.map((item) => {
						// csv에서 인식 못하는 특수문자 처리
						const before = new RegExp(`${item.before}`, "g");
						const after = item.after;
						val = val.replace(before, after);
						return item;
					});
					if (val.indexOf(":") >= 0) {
						// 25:11 <- 이러한 형식으로 입력시 CSV에서 날짜로 인식하는 문제가 있어서 한칸 띄어쓰기 해줌
						val = val[0] === " " ? val : " " + val;
					}
				}
				if (!(idx === -1)) tempArr[idx] = condition ? `"=""${val}"""` : val;
			});
			text += "\r\n".concat(tempArr.toString());
		});
		return text;
	};

	/**
	 * exportSubViewColumns 영문컬럼배열을 일본어컬럼배열로 바꾼 배열을 리턴하는 함수
	 * @param {array} exportSubViewColumns - 영문 속성이름으로 된 컬럼 이름들이 있는 배열
	 * @param {object} constant - csvConstant 에서 가져온 상수 객체
	 * @returns {array} - 문자열 일본어 컬럼명으로 이루어진 배열
	 */
	changeColumnsName = (exportSubViewColumns, constant, csvType = "") => {
		return exportSubViewColumns.map(devColumns => {
			Object.entries(constant).forEach(columnConstant => {
				const key = columnConstant[0];
				const val = (csvType === "video" && key === "data.fileKey") ? csvColumnsName["videoFileKey"] : columnConstant[1];
				if (devColumns === key) devColumns = val;
			});
			return devColumns;
		});
	};

	/**
	 * csv export 시 csv file 이름으로 사용될 문자열을 리턴해주는 함수
	 * @param {object} m - metaData 를 clone 해서 flat 하게 만든 객체
	 * @param {string} name - [book|chapter|subView|editor|audio|html|video] csvModalMsg 상수객체에 접근하기 위한 속성이름
	 * @returns {string} - csv 대상 , 교과서 이름, 학년, 학기 , 현재 시각으로 이루어진 문자열
	 */
	getDownloadCsvTitle = (m, name) => {
		return `${csvModalMsg[name]} ${subject[m.subject]}  ${grade[m.grade]} ${semester[m.semester]} ${now()}`;
	};

	importMetaData(data) {
		return new Promise((resolve) => {
			const csvColumns = data.columns; // CSV 파일에서 읽어들인 컬럼 정보 배열
			const meta = data.rowData[0];    // CSV 파일에서 읽어들인 로우 정보로 만든 meta 객체
			try {
				const reduxMeta = Book.getDevColumnsMeta(meta);                     // 속성 이름을 원상태로 되돌림
				const setViewObjData = Book.setViewObj(reduxMeta);                  // 객체 구조를 원상태로 맞춰줌
				const setReduxMetaData = Book.setReduxMetaData(setViewObjData);     // 객체 속성값 파싱
				Book.metaDataUserColumnsCheck(csvColumns);                          // CSV 컬럼 검사
				Book.rowInvalidCheck(setReduxMetaData, this.props.metaData.bookId); // CSV 로우 검사
				this.props.setMetadata({meta: setReduxMetaData});
				resolve({type: "Success", msg: "本データー変更に成功しました。", saveType: "metaData"});
			} catch (e) {
				console.error(e);
				resolve({type: "Error", msg: e.message});
			}
		});
	}

	importChapterData = (data) => {
		return new Promise((resolve) => {
			try {
				const totalPage = this.props.metaData.totalPages; // 현재 책의 최대 페이지
				const csvColumns = data.columns;                  // CSV 파일에서 읽어들인 컬럼 정보 배열
				Chapter.importColumnMatchedCheck(csvColumns);     // CSV 컬럼 검사
				const commentLength = data.commentLength;         // CSV 컬럼 주석의 길이 (number)
				const rowData = data.rowData;                     // CSV 로우 객체들이 담긴 배열
				const importFormData = Chapter.getPropNameValueChange(rowData);      // chapter 객체를 원상태로 되돌림
				Chapter.csvErrorCheck(importFormData, commentLength, totalPage);     // CSV 로우 검사
				const newChapterMeta = Chapter.csvParseChapterMeta(importFormData);  // Redux 에 넣을 최종 값 ( 배열 )
				this.props.setChapter({chapter: newChapterMeta});
				resolve({type: "Success", msg: "", saveType: "metaData"});
			} catch (e) {
				console.error(e);
				resolve({type: "Error", msg: e.message});
			}
		});
	};

	importSubViewData(data) {
		return new Promise((resolve) => {
			const clonedData = deepClone(this.props.pageData);        // 리덕스 데이터 가공을 위한 딥클론
			const metaData = this.metaDataClone(this.props.metaData); // 리덕스 데이터 가공을 위한 딥클론
			const { subject, totalPages } = metaData; // 교과서 이름(string), 교과서 최대 페이지(number)
			const { columns, commentLength , rowData } = data; // CSV 컬럼(string[]) , 주석 길이(number), CSV 로우(row[])
			const [originIdArr, allObjectArr, typeChainObject] = getAllOriginId(clonedData); // 모든 객체의 originId를 담은 배열

			try {
				SubView.subViewColumnErrChecker(columns); // import 받은 CSV 컬럼의 유효성 검사
				this.emptyRowCheck(rowData, commentLength); // 텅빈 row 한줄이 사이에 끼어있는지 검사
				const userDevColumns = Object.entries(svColumn); // 컬럼 상수 객체를 키/밸류 값의 배열로 가져옴
				const devColumnData = this.ColumnsNameChange(rowData, userDevColumns); // import 받은 컬럼 속성 영어로 변경
				const subViewData = SubView.protectionStringRemove(devColumnData);// excel 자동변환 문자열 제거
				SubView.csvRequireChecker(subViewData, commentLength, totalPages, subject, originIdArr); // 필수 값 체크
				const changedObjData = SubView.importDataParse(subViewData); // import 전에 데이터 파싱, 속성 값 파싱
				SubView.subViewCheckerByFlag(changedObjData, commentLength, metaData); // flag 에 따른 속성값 검사
				const modelObjectArr = SubView.getDefaultSetModelObject(changedObjData, subject); // 기본값 설정 && Model 객체로 전환
				const modalSuccessMsg = this.getRequestCountMsg(modelObjectArr); // import 성공 메시지 생성
				SubView.subViewImporting(modelObjectArr, clonedData, commentLength, allObjectArr, typeChainObject); // clonedData 가상데이터 가공
				this.props.setBookData(clonedData); // csv 를 redux 에 반영
				this.props.csvImporting(true); // 렌더링 조건 활성화
				resolve({type: "Success", msg: modalSuccessMsg, saveType: "pageData"}); // import 성공시 모달 출력
			} catch (e) {
				console.error(e);
				resolve({type: "Error", msg: e.message});
			}
		});
	}

	importEditorData(data) {
		return new Promise((resolve) => {
			const clonedData = deepClone(this.props.pageData);
			const clonedRepo = deepClone(this.props.repo);
			const originIdArr = Editor.getAllOriginIdArr(clonedData);
			const metaData = this.metaDataClone(this.props.metaData);
			const csvUserColumns = data.columns;
			const comLength = data.commentLength;
			const rowData = data.rowData;
			try {
				Editor.editorColumnsErrorCheck(csvUserColumns); // 입력받은 csv 컬럼이 유효한지 검사
				const userDevColumns = Object.entries(editorProp); // 영문 컬럼 배열과 csv 컬럼 배열을 가져옴
				const csvData = this.ColumnsNameChange(rowData, userDevColumns); // csv 로 읽어들인 column 과 row
				Editor.firstRowOnlyEditor(csvData); // csv 첫줄은 무조건  種類 type 속성을 editor 만 받는다.
				const csvEditorArr = Editor.propChange(csvData); // 각 객체의 속성명과 속성값을 수정한다.
				Editor.EditorCheck(csvEditorArr, comLength, originIdArr, metaData, clonedRepo); // 속성 값 검사
				const defaultEditorArr = Editor.parseAfterSetDefault(csvEditorArr); // 속성값 파싱, 기본값 대입
				const modelArr = Editor.getEditorModelArr(defaultEditorArr); // csv 값을 model 객체에 대입
				const EditorArr = Editor.textIntoEditor(modelArr); // editor 객체의 text 에 text 객체를 넣는다.
				const modalSuccessMsg = Editor.getRequestCountMsg(EditorArr); // import 성공시 메세지
				Editor.EditorImport(EditorArr, clonedData); // redux 에 넣을 clonedData 을 가공
				this.props.setBookData(clonedData); // redux 에 반영
				this.props.csvImporting(true);
				resolve({type: "Success", msg: modalSuccessMsg, saveType: "pageData"});
			} catch (e) {
				console.error(e);
				resolve({type: "Error", msg: e.message});
			}
		});
	}

	importAudioData(data) {
		return new Promise((resolve) => {
			const clonedData = deepClone(this.props.pageData);
			const { columns, rowData } = data;
			try {
				Audio.audioColumnsErrorCheck(columns); // 컬럼 명 일치 검사
				const userDevColumns = Object.entries(csvColumnsName); // 참조할 컬럼 키/값 쌍

				const devColumnData = this.ColumnsNameChange(rowData, userDevColumns); // 속성 이름 복구
				const audioData = Audio.getProcessData(devColumnData); // 자동변환 방지문자 제거 && audio 객체 구분

				//Audio.audioDataInvalidCheck(audioData, commentLength, metaData); // 유효성 검사
				const defaultSetObjArr = Audio.fillPropValue(audioData); // 값이 없으면 자동입력
				const modalObjectArr = Audio.getModelObject(defaultSetObjArr,clonedData); // redux 객체 속성 구조 변경

				const setReduxData = Audio.allAudioLinkArrClear(clonedData); // cloneData 의 모든 audioLink 배열 초기화
				const audioObjArr = Audio.audioTextPush(modalObjectArr); // audioText 객체들은 audio 객체의 text 배열로 push

				const importMsg = Audio.audioImportSuccessMsg(audioObjArr);　// import 성공시 modal 출력 메세지
				const audioImportData = Audio.importing(audioObjArr, setReduxData); // 초기화된 audioLink 배열에 audioObjArr 넣기

				console.log(audioImportData);

				this.props.setBookData(audioImportData);
				this.props.csvImporting(true);
				resolve({type: "Success", msg: importMsg, saveType: "pageData"});
			} catch (e) {
				console.error(e);
				resolve({type: "Error", msg: e.message});
			}
		});
	}

	importHtmlData(data) {
		return new Promise((resolve) => {
			// clone redux !
			const clonedData = deepClone(this.props.pageData);
			const repo = Object.assign({}, this.props.repo);
			const flatReduxClone = this.getFlatPagedData(clonedData, [], [], false);
			const reduxPageData = deepClone(this.props.pageData);
			const csvColumns = data.columns;
			const comLength = data.commentLength;
			const rowData = data.rowData;

			try {
				Html.importColumnMatchedCheck(csvColumns);
				const dataList = Html.filteringIdCover(rowData);   // id parse ex) "=""1-inj7g0xe""" => 1-inj7g0xe
				this.emptyRowCheck(dataList, comLength);
				const userDevColumns = Object.entries(csvColumnsName); // get dev/user Columns names
				const devColumnData = this.ColumnsNameChange(dataList, userDevColumns); // data columns name change
				const parsedData = Video.dataParse(devColumnData);
				const htmlMessage = Html.getImportMassage(parsedData);
				Html.htmlErrorCheck(parsedData, comLength, flatReduxClone, repo); // error check
				this.importingHtmlData(parsedData, flatReduxClone, comLength, clonedData, reduxPageData);
				this.props.setBookData(clonedData);
				this.props.csvImporting(true);
				resolve({type: "Success", msg: htmlMessage, saveType: "pageData"});

			} catch (e) {
				console.error(e);
				resolve({type: "Error", msg: e.message});
			}
		});
	}

	/**
	 * meta 정보를 받아서 clone 한 metaData 객체를 반환한다.
	 * @param {object} meta - this.props.metaData
	 * @returns {object} - clone 한 metaData
	 */
	metaDataClone = (meta) => {
		const newMeta = Object.assign({}, meta);
		for (const [k, v] of Object.entries(newMeta)) {
			if (v instanceof Object) {
				if (Array.isArray(v)) {
					newMeta[k] = [...v];
				} else {
					newMeta[k] = Object.assign({}, v);
				}
			} else {
				newMeta[k] = v;
			}
		}
		return newMeta;
	};

	importVideoData(data) {
		return new Promise((resolve) => {
			const [pageData, repo, metaData] = getClonedData(this.props.pageData, this.props.repo, this.props.metaData);
			const [originIdArr, allObjectArr] = getAllOriginId(pageData); // 전체 아이디가 필요한데
			const {commentLength, columns, rowData } = data;    // 주석길이, 컬럼, 로우

			try {
				Video.importColumnMatchedCheck(columns);              // CSV 컬럼 검사
				const idParseData = this.changeIdForRedux(rowData);   // id parse pageData "=""1-inj7g0xe""" => 1-inj7g0xe
				const videoData = this.ColumnsNameChange(idParseData, Object.entries(vColumn)); // 속성이름 영문으로
				Video.videoChecker(videoData, metaData, commentLength, originIdArr, repo); // // CSV 로우 검사
				const defaultVideoData = Video.videoDefaultValueSetting(videoData);  // 빈값 일때 기본값 지정
				const videoModelData = Video.getVideoModelObject(defaultVideoData); // 리덕스 형태의 객체구조로 바꿈
				const modalSuccessMsg = this.getRequestCountMsg(videoModelData);
				Video.videoImporting(videoModelData, pageData, allObjectArr);
				this.props.setBookData(pageData);
				this.props.csvImporting(true);
				resolve({type: "Success", msg: modalSuccessMsg, saveType: "pageData"});
			} catch (e) {
				console.error(e);
				resolve({type: "Error", msg: e.message});
			}
		});
	}

	exportMetaData = (name, clonedMeta) => {
		const cloneMeta = this.metaDataClone(this.props.metaData);
		const flatMeta = flatObject(cloneMeta);
		const meta = Book.removeMetaDataProp(flatMeta); // remove _id, _rev
		const columns = ['bookId', 'subject', 'semester', 'grade', 'view.height', 'view.width', 'direction'];
		const csvData = Book.getMetaCsvData(meta, columns);
		const comment = getCommentByCsvType(clonedMeta, name);
		const fileName = this.getDownloadCsvTitle(clonedMeta, name);
		downloadCsv(comment + csvData, fileName);
	};

	exportChapterData = (name, clonedMeta) => {
		const cloneChapterArr = Chapter.DeepCloneChapter(this.props.metaData.chapter); // 리덕스 보호
		const chapterObjArr = Chapter.getProcessedData(cloneChapterArr);               // chapter 데이터 가공
		const chapterComment = getCommentByCsvType(clonedMeta, name);                  // CSV 주석 생성
		const chapterRow = Chapter.getRows(chapterObjArr);                             // CSV 로우 생성
		const chapterColumns = ['タイトル',　'始め',　'終わり'].toString() + "\r\n";    // CSV 컬럼 생성
		const chapterExportCsvData = chapterComment + chapterColumns + chapterRow;     // CSV 파일 내용 생성
		const fileName = this.getDownloadCsvTitle(clonedMeta, name);                   // CSV 파일 이름 생성
		downloadCsv(chapterExportCsvData, fileName);                                   // CSV 다운로드
	};

	exportSubViewData(name, clonedMeta) {
		const exportSubViewColumns = [
			'originId', 'id', 'type', 'page',
			'css.height', 'css.width', 'css.left', 'css.top' , 'css.opacity',
			'data.page', 'options.link.level', 'options.link.zIndex',
			'meaningRed','meaningBlue','data.class', 'options.iFrame', 'data.type',
			'data.kakezu', 'data.title', 'flag'
		];
		const accessMode = ['subView', 'myEditor', 'icon', 'meaning', 'pageLink', 'redLine', 'mask', 'block', 'postIt'];
		const clonedData = deepClone(this.props.pageData); // 데이터 가공을 위한 딥클론
		const subViewData = getReduxModeObject(clonedData, accessMode); // subView 객체들의 정보를 배열로 가져옴
		const typeSubViewData = SubView.propertyChange(subViewData); // CSV 출력을 위해 객체의 속성들을 수정하는 함수
		const csvComment = getCommentByCsvType(clonedMeta, name);                  // subView CSV 주석 생성
		const csvColumns = this.changeColumnsName(exportSubViewColumns, svColumn); // subView CSV 컬럼 생성
		const csvRows = this.createCsvRows(typeSubViewData, exportSubViewColumns); // subView CSV 로우 생성
		const editorExportCsvData =  csvComment + csvColumns + csvRows;            // subView CSV export 최종 데이터
		const fileName = this.getDownloadCsvTitle(clonedMeta, name);               // 다운로드 받을 때 CSV 파일 이름
		downloadCsv(editorExportCsvData, fileName); // CSV 다운로드
	}

	exportEditorData = (name, clonedMeta) => {
		const exportSubViewColumns = [
			"type", "originId", "id", "page", "editor.ignore", "css.position", "value", "css.fontSize",
			"css.marginLeft", "css.marginTop","css.lineHeight", "css.color", "css.fontWeight", "css.width",
			"editor.myEditorImage", "editor.myEditorImage_bg","flag"
		];
		const newColumns = [
			"type", "originId", "id", "page", "editor.ignore", "css.position", "value", "css.fontSize",
			"css.left", "css.top","css.lineHeight", "css.color", "css.fontWeight", "css.width",
			"editor.myEditorImage", "editor.myEditorImage_bg","flag"
		];
		const userColumns = Editor.getEditorColumns(exportSubViewColumns); // 영문 컬럼을 사용자가 알기 쉽게 바꿈
		const editorComment = getCommentByCsvType(clonedMeta, name);
		const clonedData = deepClone(this.props.pageData); // 리덕스 안의 데이터를 가공하기 위한 딥클론 데이터
		const editorData = Editor.getOnlyEditorData(clonedData); // editor 객체와 editorText 객체로 이루어진 배열 가져옴
		const flatEditorData = Editor.getChangedPropObjArr(editorData);
		const csvRows = this.createCsvRows(flatEditorData, exportSubViewColumns, newColumns); // rows
		const editorExportCsvData =  editorComment + userColumns + csvRows; // data
		const fileName = this.getDownloadCsvTitle(clonedMeta, name); // csv download file name
		downloadCsv(editorExportCsvData, fileName);
	};

	exportAudioData = (name, clonedMeta) => {
		const exportAudioColumns = [
			'type', 'originId', 'id', 'page',
			'css.left', 'css.top', 'css.height', 'css.width', 'data.fileKey', 'data.index', 'data.moveAt', 'data.playAt',
		];
		const clonedData = deepClone(this.props.pageData);
		const audioLinkData = Audio.getAudioData(clonedData);
		const csvRows = this.createCsvRows(audioLinkData, exportAudioColumns);
		const csvColumns = this.changeColumnsName(exportAudioColumns, csvColumnsName);
		const comment = getCommentByCsvType(clonedMeta, name);
		const csvData =  comment + csvColumns + csvRows ;
		const fileName = this.getDownloadCsvTitle(clonedMeta, name);
		downloadCsv(csvData, fileName);
	};

	exportHtmlData = (name, clonedMeta) => {
		let exportSubViewColumns = ['originId', 'id', 'data.zipKey', 'data.htmlFileName', 'data.tooltip', 'thumbKey'];
		const clonedData = deepClone(this.props.pageData);
		const iFrameArr = Html.getIframeData(clonedData);
		const flatObjArr = Html.getDeleteFlatPropObj(iFrameArr);
		const csvRows = this.createCsvRows(flatObjArr, exportSubViewColumns);
		const userColumns = this.changeColumnsName(exportSubViewColumns, csvColumnsName); // ex) css.opacity ===> 透明度
		const htmlComment = getCommentByCsvType(clonedMeta, name);
		const htmlExportCsvData = htmlComment + userColumns + csvRows;
		const fileName = this.getDownloadCsvTitle(clonedMeta, name); // csv download file name
		downloadCsv(htmlExportCsvData, fileName);
	};

	exportVideoData = (name, clonedMeta) => {
		const exportSubViewColumns = ['originId', 'id', 'page', 'css.left', 'css.top', 'data.fileKey', 'data.subtitleKey',
			'data.tooltip', 'data.title', 'thumbKey', 'flag'];  // CSV 에 출력될 컬럼의 이름 순서
		const clonedData = deepClone(this.props.pageData);                                  // 리덕스 가공을 위한 클론
		const videoData = getReduxModeObject(clonedData, ['videoLink']);       // video[]
		const flatVideoData = videoData.map(obj => flatObject(obj));              // flat video[]
		const videoComment = getCommentByCsvType(clonedMeta, name);                         // CSV 주석 생성
		const videoColumns = this.changeColumnsName(exportSubViewColumns, csvColumnsName, "video");  // CSV 컬럼 생성
		const videoRow = this.createCsvRows(flatVideoData, exportSubViewColumns);           // CSV 로우 생성
		const videoExportCsvData =  videoComment + videoColumns + videoRow;                 // CSV 내용 생성
		const fileName = this.getDownloadCsvTitle(clonedMeta, name);                        // CSV 파일 이름 생성
		downloadCsv(videoExportCsvData, fileName);                                          // CSV 파일 다운로드
	};

	importEvent(e) {
		if (!this.state.editingUserList || this.state.editingUserList.length !== 1) {
			this.toggleModal("NoImport");
			e.target.value = null;
			return;
		}
		const target = e.target;
		const file = target.files[0];
		if (!file) return;
		const name = target.getAttribute("name");
		const title = target.getAttribute("title");
		const afterFunc = {
			book: (data) => (this.importMetaData(data)),
			chapter: (data) => (this.importChapterData(data)),
			subView: (data) => (this.importSubViewData(data)),
			editor: (data) => (this.importEditorData(data)),
			audio: (data) => (this.importAudioData(data)),
			html: (data) => (this.importHtmlData(data)),
			video: (data) => (this.importVideoData(data)),
		};
		const isBookChanged = this.props.current.book.changed;
		readCsvAsync(file).then(afterFunc[name]).then(async (data) => {
			if (data.type && data.type === "Success") {
				this.props.startLoading({msg: loadingMsg.CSV_IMPORT});
				let saveData = {};
				if (data.saveType === "metaData") {
					saveData = this.props.metaData;
				} else if (data.saveType === "pageData") {
					saveData = this.props.pageData;
				}
				const result = await saveCsvData(this.props.metaData.bookId, data.saveType, saveData);
				if (result && result.success) {
					result.resultData.repo = this.props.repo;
					this.props.setData(result.resultData);
				}
				if (!isBookChanged) this.props.setBookSaved();
				const currentTime = now();
				this.props.addLog({msg:{regDate:`${currentTime}`, userId:`${this.props.auth.info.id}`, page:``, objectId:`${title}`, action: `インポート`, type: "CSV"}});
				this.props.endLoading();
			}
			this.modalOpen(target, data);
		});
		e.target.value = null; // input same file allow
	};

	/**
	 * import 함수들의 promise 의 resolve 의 결과를 받아서 modal 을 여는 함수
	 * @param target - e.target
	 * @param data - import 성공 / 실패시 import 함수 들의 promise resolve 의 data
	 */
	modalOpen = (target, data) => {
		const { type , msg } = data;
		const name = target.getAttribute("name");
		this.toggleModal(type, true, name, msg);
	};

	async exportEvent(e) {
		const name = e.target.getAttribute("name");
		const clonedMeta = this.metaDataClone(this.props.metaData); // 리덕스에 side effect 방지용 metaData 딥클론

		switch (name) {
			case 'book': {
				this.exportMetaData(name, clonedMeta);
				break;
			}
			case 'chapter': {
				this.exportChapterData(name, clonedMeta);
				break;
			}
			case 'subView': {
				this.exportSubViewData(name, clonedMeta);
				break;
			}
			case 'editor': {
				this.exportEditorData(name, clonedMeta);
				break;
			}
			case 'audio': {
				this.exportAudioData(name, clonedMeta);
				break;
			}
			case 'html': {
				this.exportHtmlData(name, clonedMeta);
				break;
			}
			case 'video': {
				this.exportVideoData(name, clonedMeta);
				break;
			}
			default:
				break;
		}
	}

	templateEvent(e) {
		console.log(this, e);
	}

	clickEvent() {
		return {
			import: this.importEvent.bind(this),
			export: this.exportEvent.bind(this),
			template: this.templateEvent.bind(this)
		}
	};

	render() {
		const meta = this.props.metaData;
		const title = `${subject[meta.subject]} ${grade[meta.grade]} ${semester[meta.semester]} ${componentTitle.CSV_IMPORT}`;
		const btnHide = meta.author !== this.props.auth.info.id ? "hide" : "";
		return (
			<div id={"Import"} className={this.props.className}>
				<Title
					titleName={title}
					onclickX={this.onClickX}
				/>
				<div id={"ImportContainer"} className={"ImportContainer"}>
					<div className={"ImportTable"}>
						<ImportButtons name={"book"} title={"ブック設定"} clickEvent={this.clickEvent()} btnHide={btnHide}/>
						<ImportButtons name={"chapter"} title={"チャプター設定"} clickEvent={this.clickEvent()} btnHide={btnHide}/>
						<ImportButtons name={"subView"} title={"拡大画面設定"} clickEvent={this.clickEvent()} btnHide={btnHide}/>
						<ImportButtons name={"editor"} title={"MY教科書エディタ設定"} uploadBtn={true} clickEvent={this.clickEvent()} btnHide={btnHide}/>
						<ImportButtons name={"audio"} title={"音声読み上げ設定(国語のみ)"} clickEvent={this.clickEvent()} btnHide={btnHide}/>
						<ImportButtons name={"html"} title={"HTMLコンテンツ設定"} uploadBtn={true} clickEvent={this.clickEvent()} btnHide={btnHide}/>
						<ImportButtons name={"video"} title={"動画設定"} uploadBtn={true} clickEvent={this.clickEvent()} btnHide={btnHide}/>
					</div>
				</div>
				{this.state.showModalError && <Modal
					onClickConfirm={() => this.toggleModal("Error", false)}
					msg={this.state.showModalMsg}
					title={modalTitle.ERROR}
				/>}
				{this.state.showModalSuccess && <Modal
					onClickConfirm={() => this.toggleModal("Success", false)}
					msg={this.state.showModalMsg}
					title={modalTitle.SUCCESS}
				/>}
				{this.state.showModalNoImport && <Modal
					onClickConfirm={() => this.toggleModal("NoImport", false)}
					msg={"この教科書を編集中の共同編集者がいます。"}
					title={modalTitle.ERROR}
				/>}
			</div>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Import);