import { getStorageModel } from "../../interface/dataModel";
import {now} from "../../interface/utils";

const SET_REPO = "SET_REPO";
const MODIFY_REPO_MULTI = "MODIFY_REPO_MULTI";
const MODIFY_REPO_PATH = "MODIFY_REPO_PATH";
const REMOVE_FROM_REPO = "REMOVE_FROM_REPO";
const initialState = getStorageModel();

//Reducer
export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case SET_REPO: {
			return action.data;
		}
		case MODIFY_REPO_MULTI: {
			return {
				...state,
				[action.kind]: {
					...state[action.kind],
					...action.data,
				},
			}
		}
		case MODIFY_REPO_PATH: {
			let kind = Object.assign({}, state[action.kind]);
			kind[action.key] = {
				path: action.path,
				originalname: action.originalname,
				regDate: now()
			};
			return {
				...state,
				[action.kind]: kind,
			};
		}
		case REMOVE_FROM_REPO: {
			let kind = Object.assign({}, state[action.kind]);
			delete kind[action.key];
			return {
				...state,
				[action.kind]: kind,
			}
		}
		default: {
			return state;
		}
	}
}

//Action Generators
export function setRepo(state) {
	return {
		type: SET_REPO,
		data: state.data,
	}
}

export function modifyRepoPath(state) {
	return {
		type: MODIFY_REPO_PATH,
		key: state.key,
		path: state.path,
		originalname: state.originalname,
		kind: state.kind,
		userId: state.userId || ""
	}
}

export function removeFromRepo(state) {
	return {
		type: REMOVE_FROM_REPO,
		key: state.key,
		kind: state.kind,
		userId: state.userId || ""
	}
}

export function modifyRepoMulti(state) {
	return {
		type: MODIFY_REPO_MULTI,
		data: state.data,
		kind: state.kind,
		userId: state.userId || ""
	}
}