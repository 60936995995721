import React, {Component} from 'react';
import PropTypes from "prop-types";

import {
    CheckBoxProperty,
    TextBoxProperty,
    ButtonProperty,
    SelectProperty,
} from "./innerProperty";
import {
    dataModeConst,
    propertyInputLabel,
    propertyInputName,
    propertyToolTip,
    svgPreset,
} from '../../../../../interface/constant';
import {getSVGModel} from "../../../../../interface/dataModel";

class SvgProperty extends Component {
    eventHandler() {
        return {
            svgPolygonChange: (e) => {
                const value = e.target.value;
                this.setState({svg: value});
                let svg = this.props.item.css.svg;
                if (this.checkSVGPointsValid(value)) {
                    this.props.changeRectSVG({
                        id: this.props.id,
                        page: this.props.page,
                        svg: {
                            ...svg,
                            attr: {
                                points: value,
                            }
                        },
                        objMode: this.props.objMode,
                    })
                }
            },
            svgPathChange: (e) => {
                const value = e.target.value;
                this.setState({svg: value});
                let svg = this.props.item.css.svg;
                if(this.checkSVGPathDValid(value)) {
                    this.props.changeRectSVG({
                        id: this.props.id,
                        page: this.props.page,
                        svg: {
                            ...svg,
                            attr: {
                                d: value,
                            }
                        },
                        objMode: this.props.objMode,
                    })
                }
            },
        }
    }

    changeSVGtoPreset(e) {
        let points = e.currentTarget.getAttribute("data-points");
        this.props.changeRectSVG({
            id: this.props.id,
            page: this.props.page,
            svg: {
                type: "polygon",
                attr: {points}
            },
            objMode: this.props.objMode,
        });
    }

    changeSVGType(e) {
        let type = e.target.value;
        let typeBefore = this.props.item.svg.type;
        let attr = this.props.item.svg.attr;
        if (type !== typeBefore) {
            let newAttr = (typeBefore === "polygon") ? this.svgPolygonToPath(attr) : this.svgPathToPolygon(attr);
            this.props.changeRectSVG({
                id: this.props.id,
                page: this.props.page,
                svg: {
                    type: type,
                    attr: newAttr
                },
                objMode: this.props.objMode,
            });
        }
    }

    svgPathToPolygon(attr) {
        let regexp = /([a-yA-Y]+\s?[-]?\d+(\.\d+)?\s?[-]?\d+(\.\d+)?)/g;
        let found = attr.d.match(regexp);
        let newPoints = found.map((item) => {
            return item.replace(/[a-zA-Z]\s?/g, '').replace(" ", ",");
        }).join(" ");
        return {
            points: this.isValidPolygonPoints(newPoints),
        }
    }

    svgPolygonToPath(attr) {
        let points = attr.points.split(' ');
        let newD = points.map((p, index) => {
            let com = (index === 0) ? "M" : "L";
            return `${com} ${p.replace(",", " ")}`;
        }).join(" ") + " L " + points[0].replace(",", " ") + " Z";
        return {
            d: this.isValidPathD(newD),
        }
    }

    isValidPolygonPoints(points) {
        let def = "0,0 100,0 100,100 0,100";
        return this.checkSVGPointsValid(points) ? points : def;
    }

    isValidPathD(d) {
        let def = "M 0 0 L 100 0 L 100 100 L 0 100 L 0 0 Z";
        return this.checkSVGPathDValid(d) ? d : def;
    }

    svgPolygonElem() {
        const item = this.props.item;
        if (item.hasOwnProperty("svg")) {
            const type = item.svg.type;
            const svgTypeOptions = [
                {val: "polygon", name: "Polygon"},
                {val: "path", name: "Path"}
            ];
            return (
                <>
                    <tr>
                        <td className="table-key"/>
                        <td className="table-question"/>
                        <td className="table-value">
                            <div className="svg-preset-container">
                                {svgPreset.map((points, idx) => {
                                    return (
                                        <div className="svg-preset-btn"
                                             data-points={points}
                                             onClick={this.changeSVGtoPreset.bind(this)}
                                             key={`svg-preset-${idx}`}
                                        >
                                            <svg
                                                width="80%"
                                                height="80%"
                                                viewBox="0 0 100 100"
                                                preserveAspectRatio="none"
                                            >
                                                <polygon points={points}/>
                                            </svg>
                                        </div>
                                    )
                                })}
                            </div>
                        </td>
                    </tr>
                    <SelectProperty
                        label={propertyInputLabel.svgType}
                        name={propertyInputName.svgType}
                        options={svgTypeOptions}
                        val={type}
                        eventHandler={this.changeSVGType.bind(this)}
                    />
                    <TextBoxProperty
                        label={propertyInputLabel.points}
                        name={propertyInputName.points}
                        val={this.props.svg}
                        readOnly={false}
                        onChange={this.eventHandler().svgPolygonChange}
                    />
                    <ButtonProperty
                        btnName="編集"
                        onClick={this.editSVG.bind(this)}
                    />
                </>
            )
        }
    }

    svgPathElem() {
        const item = this.props.item;

        if (item.hasOwnProperty("svg")) {
            const type = item.svg.type;
            const svgTypeOptions = [
                {val: "polygon", name: "Polygon"},
                {val: "path", name: "Path"}
            ];
            return (
                <>
                    <SelectProperty
                        label={propertyInputLabel.svgType}
                        name={propertyInputName.svgType}
                        options={svgTypeOptions}
                        val={type}
                        eventHandler={this.changeSVGType.bind(this)}
                    />
                    <TextBoxProperty
                        label={propertyInputLabel.d}
                        name={propertyInputName.d}
                        val={item.svg.attr.d}
                        readOnly={false}
                        onChange={this.eventHandler().svgPathChange}
                    />
                    <ButtonProperty
                        btnName="編集"
                        onClick={this.editSVG.bind(this)}
                    />
                </>
            )
        }
    }

    editSVG() {
        this.props.togglePanel({
            name: dataModeConst.EDIT_SVG,
            isOpen: !this.props.panel[dataModeConst.EDIT_SVG],
        });
        let mode = this.props.mode === dataModeConst.EDIT_SVG ? dataModeConst.EDITOR : dataModeConst.EDIT_SVG;
        this.props.changeEditorMode({mode});
    }

    onChangeSVG(e) {
        let svg = null;
        if (e.target.checked) svg = getSVGModel();
        this.props.changeRectSVG({
            id: this.props.id,
            page: this.props.page,
            svg: svg,
            objMode: this.props.objMode,
        });
    }

    /**
     * check string type matched svg polygon. e.g "0,0 1,99 99,25 ..."
     * @param {string} points - inputted svg points
     * @returns {boolean} - is matched
     */
    checkSVGPointsValid(points) {
        return /^([-]?\d+(\.\d+)?,[-]?\d+(\.\d+)?\s)+([-]?\d+(\.\d+)?,[-]?\d+(\.\d+)?)$/.test(points);
    }

    checkSVGPathDValid(d) {
        return /^([a-zA-Z]+\s?[-]?\d+(\.\d+)?\s[-]?\d+(\.\d+)?\s?)*Z$/.test(d);
    }

    render() {
        const {item} = this.props;
        return (
            <>
                <CheckBoxProperty
                    label={propertyInputLabel.svg}
                    name={propertyInputName.svg} suffix={""}
                    checked={item.hasOwnProperty("svg")}
                    eventHandler={this.onChangeSVG.bind(this)}
                    tooltipText={propertyToolTip.svg}
                />
                {(item.svg && item.svg.type !== "path") ? this.svgPolygonElem() : this.svgPathElem()}
            </>
        )
    }
}

SvgProperty.defaultProps = {
    item: {
        originId: "",
        id: "",
        css: {},
        options: {},
        page: 0,
        data: {},
    },
    changeRectSVG: null,
    changeEditorMode: null,
    togglePanel: null,
    id: "",
    page: 0,
    objMode: "",
    panel: {},
    mode: "",
    svg: "",
};

SvgProperty.propTypes = {
    item: PropTypes.shape({
        originId: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        css: PropTypes.object.isRequired,
        options: PropTypes.object.isRequired,
        data: PropTypes.object.isRequired,
        page: PropTypes.number.isRequired
    }).isRequired,
    changeRectSVG: PropTypes.func.isRequired,
    changeEditorMode: PropTypes.func.isRequired,
    togglePanel: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    page: PropTypes.number.isRequired,
    objMode: PropTypes.string.isRequired,
    panel: PropTypes.object.isRequired,
    mode: PropTypes.string.isRequired,
    svg: PropTypes.string.isRequired,
};

export default SvgProperty;