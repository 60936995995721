import React, { Component } from 'react';
import PropTypes from "prop-types";
import AddToolTip from "../../Common/AddToolTip";

import {settingsToolTip} from "../../../interface/constant";
import {addLog} from "../../../reducers/CombineReducers/current";
import {now} from "../../../interface/utils";
import {connect} from "react-redux";

const mapStateToProps = (state) => {
	return {
		chapter : state.metaData.chapter,
		auth: state.auth,
	}
};

const mapDispatchToProps = dispatch => {
	return {
		addLog : (state) => dispatch(addLog(state))
	}
};

class SetChapter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			chapter: [],
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps === prevState.prevProps) return null;
		const chapterProp = nextProps.chapter;
		const chapter = SetChapter.arrangeChapterToState(chapterProp);
		return {chapter, prevProps: nextProps}
	}

	static arrangeChapterToState(chapterProp) {
		return chapterProp.map((item) => {
			return {
				name: item.name,
				start: Math.min(...item.pages),
				end: Math.max(...item.pages),
			}
		});
	}

	arrangeChapterToProps(chapterState) {
		return chapterState.map(chapter => {
			const {name, start, end} = chapter;
			let pages = Array(end - start + 1).fill(start).map((val, idx) => val + idx);
			return {name, pages}
		});
	}

	addChapter() {
		const currentTime = now();
		const chapter = [...this.state.chapter];
		let start = (chapter.length <= 0) ? 1 : chapter[chapter.length - 1].end + 1;
		let end = start + 1;
		if (end > this.props.totalPages) end = start; // 챕터추가시 최대 페이지 초과하면 종료 페이지를 개시페이지로 맞춤
		chapter.push({name: "", start, end});
		this.props.addLog({msg:{regDate:`${currentTime}`, userId:`${this.props.auth.info.id}`, page:``, objectId:``, action: `チャプタ追加`, type: "チャプタ"}});
		this.setState({chapter});
	}

	deleteChapter(e, idx) {
		const chapter = [...this.props.chapter];
		chapter.splice(idx, 1);
		const currentTime = now();
		this.props.addLog({msg:{regDate:`${currentTime}`, userId:`${this.props.auth.info.id}`, page:``, objectId:``, action: `削除`, type: "チャプタ"}});
		this.props.setChapter({chapter});
	}

	onChangeName(e, idx) {
		const chapter = [...this.state.chapter];
		chapter[idx].name = e.target.value;
		this.setState({
			chapter: chapter,
		})
	}

	onBlurName(e, idx) {
		const val = this.state.chapter[idx].name;
		let chapter;
		if (val === "") {
			chapter = SetChapter.arrangeChapterToState(this.props.chapter);
			this.setState({chapter});
		} else {
			chapter = this.arrangeChapterToProps(this.state.chapter);
			const currentTime = now();
			this.props.setChapter({chapter});
			if(this.props.chapter[idx]){
				if(val !== this.props.chapter[idx].name){
					this.props.addLog({msg:{regDate:`${currentTime}`, userId:`${this.props.auth.info.id}`, page:``, objectId:``, action: `チャプタ名変更`, type: "チャプタ"}});
				}
			}
		}
	}

	onChange(e, type, idx) {
		let chapter = [...this.state.chapter];
		chapter[idx][type] = parseInt(e.target.value, 10) || "";
		this.setState({chapter});
	}

	onBlur(e, type, idx) {
		let chapter = [...this.state.chapter];              // state 에서 가져온 chapter 정보
		const isValid = e.target.validity.valid;            // 입력값의 유효 범위 boolean
		const start = this.state.chapter[idx].start;        // 개시 페이지
		const end = this.state.chapter[idx].end;            // 종료 페이지
		const val = (type === "start") ? start : end;       // type 인자에 따라서 개시페이지/ 종료페이지 인지 결정
		const nullInput = (val === "");                     // 입력값이 "" 인 경우
		const chapterRangeArr = [];                         // 입력하는 챕터를 제외한 모든 챕터에서 사용하는 페이지


		const isUsedVal = this.isUsedVal(chapter, chapterRangeArr, idx, val); // 입력값이 사용중인가?
		// 이미 사용중인 페이지를 입력하거나, 개시 페이지가 종료 페이지보다 낮거나, 범위가 맞지 않거나, 입력값이 없으면
		if (isUsedVal || start > end || !isValid || nullInput) {
			chapter = SetChapter.arrangeChapterToState(this.props.chapter); // 예전 상태로 되돌림
			this.setState({chapter});
		} else {
			const sortedChapter = this.getSortedChapter(chapter, start); // 유효하면 정렬된 챕터를 가져옴
			const newChapter = this.arrangeChapterToProps(sortedChapter);
			const currentTime = now();

			if(this.props.chapter[idx]){
				let endIDX = this.props.chapter[idx].pages.length-1;
				if(start !== this.props.chapter[idx].pages[0] || end !== this.props.chapter[idx].pages[endIDX]){
					this.props.addLog({msg:{regDate:`${currentTime}`, userId:`${this.props.auth.info.id}`, page:``, objectId:``, action: `設定を変更`, type: "チャプタ"}});
				}
			}

			this.props.setChapter({chapter: newChapter});
		}
	}

	getSortedChapter(chapter, start) {
		const compareNumbers = (a, b) => (a.start - b.start);   // chapter 정렬 함수의 조건인자 함수, 개시페이지 기준정렬
		const sortedChapter = chapter.sort(compareNumbers);     // 챕터의 개시페이지를 오름차순 정렬
		const movedChapterIdx = sortedChapter.findIndex(chap => chap.start === start); // 정렬 된 후의 인덱스
		const movedChapter = sortedChapter[movedChapterIdx];            // 정렬되서 이동된 챕터
		const movedNextChapter = sortedChapter[movedChapterIdx + 1];    // 이동된 챕터의 다음 챕터
		if (movedChapter && movedNextChapter) {                         // 이동된 챕터 존재시
			if(movedChapter.end > movedNextChapter.start) {             // 이동된 챕터 종료가 다음챕터 개시보다 높은 경우
				const endPage = movedChapter.start +1; // 이동된 챕터의 개시 페이지에 1 더한 값
				movedChapter.end = (endPage >= movedNextChapter.start) ? movedChapter.start : endPage;
			}
		}
		return sortedChapter;
	}

	isUsedVal(chapter, chapterRangeArr, idx, val) {
		const everyChapterExceptIdx = chapter.filter((obj, index) => index !== idx); // idx 해당 챕터를 제외한 모든 챕터
		for (const obj of everyChapterExceptIdx) {
			const interval = obj.end - obj.start + 1;
			for (let i = 0; i < interval; i++) {
				chapterRangeArr.push(obj.start+i);          // 존재하는 모든 챕터 페이지를 chapterRangeArr 에 넣음
			}
		}
		return chapterRangeArr.some(nums => nums === val); // 입력값이 이미 사용중인 페이지인가?
	}

	btnDeActive() {
		const chapter = this.state.chapter;
		if (chapter.length <= 0) return "";
		const lastPageNum = chapter[chapter.length - 1].end;
		return (lastPageNum < this.props.totalPages ? "" : " de-active");
	}

	getRowElem() {
		const chapter = this.state.chapter;
		return chapter.map((item, idx) => {
			return (
				<tr key={`chapter_${idx}`}>
					<td className={"idx"}>{idx + 1}</td>
					<td className={"name"}>
						<input
							type={"text"}
							name={"chapterName"}
							className={"chapter-name"}
							placeholder={"入力してください。"}
							value={item.name || ""}
							onChange={(e) => this.onChangeName(e, idx)}
							onBlur={(e) => this.onBlurName(e, idx)}
						/>
					</td>
					<td className={"start"}>
						<input
							type={"number"}
							name={"startPage"}
							className={"start-page"}
							value={item.start}
							pattern="[0-9]*"
							min={0}
							step={1}
							max={this.props.totalPages}
							onChange={(e) => this.onChange(e, "start", idx)}
							onBlur={(e) => this.onBlur(e, "start", idx)}
						/>
					</td>
					<td className={"end"}>
						<input
							type={"number"}
							name={"startNumber"}
							className={"end-page"}
							value={item.end}
							pattern="[0-9]*"
							min={0}
							step={1}
							max={this.props.totalPages}
							onChange={(e) => this.onChange(e, "end", idx)}
							onBlur={(e) => this.onBlur(e, "end", idx)}
						/>
					</td>
					<td className={"btn"}>
						{<input
							type={"button"}
							className={"btn_gray"}
							name={"delete"}
							value={"削除"}
							onClick={(e) => this.deleteChapter(e, idx)}
						/>}
					</td>
				</tr>
			)
		});
	}

	render() {
		return (
			<div className={"chapter-setting-container"}>
				<div className={"chapter-label"}>
					<span>チャプター設定</span>
					<AddToolTip text={settingsToolTip.chapter} direction={"bottom"}/>
				</div>
				<div className={"control-box"}>
					<span>全{this.props.totalPages}ページ</span>
					<input
						type={"button"}
						className={"add-chapter-btn btn_gray" + this.btnDeActive()}
						name={"add-chapter"}
						value={"追加"}
						onClick={this.addChapter.bind(this)}
					/>

				</div>
				<table className={"chapter-table"}>
					<tbody>
					<tr>
						<th className={"idx"}>順番</th>
						<th className={"name"}>名前</th>
						<th className={"start"}>始め</th>
						<th className={"end"}>終わり</th>
						<th className={"btn"}>操作</th>
					</tr>
					{this.getRowElem()}
					</tbody>
				</table>
			</div>
		)
	}
}

SetChapter.defaultProps = {
	chapter: [{
		name: "",
		pages: [1, 2]
	}]
};
SetChapter.propTypes = {
	chapter: PropTypes.array.isRequired,
	totalPages: PropTypes.number.isRequired,
	setChapter: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SetChapter);